import { post, put } from "../../../helpers/apiHelper";

import { Api } from "../../../constants";
import { get, remove } from "../../../helpers/apiHelper";

export async function createReminderEmailFrequency(data: {
  values: any;
  orgId: number;
}) {
  const payload = { ...data.values, enabled: true };
  const result = await post(
    Api.salesInvoice +
      `reminder-email-settings?current_organization=${data.orgId}`,
    payload
  );
  return result;
}
export async function checkIfReminderExists(data: {
  values: any;
  orgId: number;
}) {
  const payload = { ...data.values, enabled: true };
  const result = await post(
    Api.salesInvoice +
      `check-reminder-exist?current_organization=${data.orgId}`,
    payload,
  );
  return result;
}
export async function updateReminderEmailFrequencies(data: {
  values: any;
  orgId: number;
  id: number;
}) {
  const payload = {
    ...data.values,
    enabled: data.values.enabled,
  };
  const result = await put(
    Api.salesInvoice +
      `reminder-email-settings/${data.id}?current_organization=${data.orgId}`,
    payload
  );
  return result;
}

export async function deleteReminderEmailFrequencies(data: {
  orgId: number;
  id: number;
}) {
  const result = await remove(
    Api.salesInvoice +
      `reminder-email-settings/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

export async function reminderEmialFrequencyList(data: { orgId: number, page: number, itemsPerPage: number }) {
  const result = await get(
    Api.salesInvoice +
      `reminder-email-settings?current_organization=${data.orgId}&page_num=${data.page}&page_size=${data.itemsPerPage}`
  );
  return result;
}

export async function configureReminderEmailFrequencies(data: {
  orgId: number;
  values: any;
}) {
  const result = await put(
    Api.salesInvoice + `reminder-end-action?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

export async function getReminderEmailConfig(data: { orgId: number }) {
  const result = await get(
    Api.salesInvoice + `reminder-end-action?current_organization=${data.orgId}`
  );
  return result;
}
