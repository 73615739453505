import {
  CreateContactValues,
  CreateAddressValues,
  EditVendorValues,
} from "./../../../types";
import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import { CreateVendorValues } from "../../../types";

/**
 * API call for creating a new vendor
 */
export async function createNewVendor(data: {
  values: CreateVendorValues;
  orgId: number;
}) {
  const result = await post(
    Api.vendorManagement +
      Api.vendor +
      `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API for checking the vendor short code is already exist or not
 */
export async function shortCodeExist(data: {
  vendorName: string;
  shortCode: string;
  orgId: number;
}) {
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      "/short-code-exist?vendor_display_name=" +
      data.vendorName +
      "&short_code=" +
      data.shortCode +
      `&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for Editing a vendor
 */
export async function editVendor(data: {
  id: number;
  editVendorInputs: EditVendorValues;
  orgId: number;
}) {
  const result = await put(
    Api.vendorManagement +
      Api.vendor +
      "/edit" +
      `/${data.id}?current_organization=${data.orgId}`,
    data.editVendorInputs
  );
  return result;
}

/**
 * API call for getting all vendors
 */
export async function getVendorsList(data: {
  page: number;
  itemsPerPage: number;
  vendorName: string;
  orgId: number;
  orgIds: number[];
}) {
  const orgIds = data.orgIds.map((orgId) => {
    return `&org_ids=${orgId}`;
  });
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      "/list" +
      `?page_num=${data.page}&page_size=${data.itemsPerPage}&vendor_name=${
        data.vendorName
      }&current_organization=${data.orgId} ${orgIds.join("")}`
  );
  return result;
}

/**
 * API call for getting vendor search suggestions
 */
export async function getSearchSuggestions(data: {
  vendorName: string;
  orgId: number;
}) {
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      "/suggestion?vendor_name=" +
      data.vendorName +
      `&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting Vendor details
 */
export async function getVendorDetails(data: { id: number; orgId: number }) {
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      "/detail" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting vendor details for other transactions
 */
export async function getVendorDetailsForTransactions(data: {
  vendorId: number;
  orgId: number;
}) {
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      `/transaction-detail/${data.vendorId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for deleting Vendor
 */
export async function deleteVendor(data: { id: number; orgId: number }) {
  const result = await remove(
    Api.vendorManagement +
      Api.vendor +
      "/delete" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for creating a Vendor Address
 */
export async function createNewVendorAddress(data: {
  id: number;
  addressInputs: CreateAddressValues;
  orgId: number;
}) {
  const result = await post(
    Api.vendorManagement +
      Api.vendorAddress +
      "/create" +
      `?vendor_id=${data.id}&current_organization=${data.orgId}`,
    data.addressInputs
  );
  return result;
}

/**
 * API call for editing VendorCombined address
 */
export async function editVendorCombinedAddress(data: {
  vendorId: number;
  editCombinedInput: CreateAddressValues;
  orgId: number;
}) {
  const result = await put(
    Api.vendorManagement +
      Api.vendorCombinedAddress +
      "/edit" +
      `/${data.vendorId}?current_organization=${data.orgId}`,
    data.editCombinedInput
  );
  return result;
}

/**
 * API call for editing Vendor Billing Address
 */
export async function editVendorBillingAddress(data: {
  vendorId: number;
  editBillingInput: CreateAddressValues;
  orgId: number;
}) {
  const result = await put(
    Api.vendorManagement +
      Api.vendorBillingAddress +
      "/edit" +
      `/${data.vendorId}?current_organization=${data.orgId}`,
    data.editBillingInput
  );
  return result;
}

/**
 * API call for editing Vendor Shipping Address
 */
export async function editVendorShippingAddress(data: {
  vendorId: number;
  editShippingInput: CreateAddressValues;
  orgId: number;
}) {
  const result = await put(
    Api.vendorManagement +
      Api.vendorShippingAddress +
      "/edit" +
      `/${data.vendorId}?current_organization=${data.orgId}`,
    data.editShippingInput
  );
  return result;
}

/**
 * API call for creating new Vendor Contact
 */
export async function createNewVendorContact(data: {
  id: number;
  contactInputs: CreateContactValues;
  orgId: number;
}) {
  const result = await post(
    Api.vendorManagement +
      Api.vendorContact +
      "/create" +
      `?vendor_id=${data.id}&current_organization=${data.orgId}`,
    data.contactInputs
  );
  return result;
}

/**
 * API call for edit vendor contact
 */
export async function editVendorContact(data: {
  vendorId: number;
  contactId: number;
  editContactInputs: CreateContactValues;
  orgId: number;
}) {
  const result = await put(
    Api.vendorManagement +
      Api.vendorContact +
      "/edit" +
      `/${data.vendorId}/${data.contactId}?current_organization=${data.orgId}`,
    data.editContactInputs
  );
  return result;
}

/**
 * API call for Deleting Vendor Contact
 */

export async function deleteVendorContact(data: {
  vendorId: number;
  contactId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.vendorManagement +
      Api.vendorContact +
      "/delete" +
      `/${data.vendorId}/${data.contactId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting all GST Types list
 */
export async function getGstTypesList(orgId: number) {
  const result = await get(
    Api.vendorManagement + Api.gstTypes + `/list?current_organization=${orgId}`
  );
  return result;
}
/**
 * API call for getting vendor drop down list
 */
export async function getVendorDropdownList(orgId: number) {
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      `/dropdown-list?current_organization=${orgId}`
  );
  return result;
}
/**
 * API call for getting vendor list for filter
 */
export async function getVendorFilterList(data: {
  orgId: number;
  organizationIds: number[];
}) {
  const orgIds = data.organizationIds.map((orgId) => {
    return `&organization_ids=${orgId}`;
  });
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      `/transaction-filter-dropdown?current_organization=${
        data.orgId
      } ${orgIds.join("")}`
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.vendorManagement +
      `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
/**
 * API call for getting vendor list for soa
 */
export async function getVendorListForSoa(data: {
  orgId: number;
  currentOrgId: number;
  isIntegrated?: boolean;
}) {
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      `/dropdown-list-for-soa?organization_id=${data.orgId}&is_integrated=${
        data.isIntegrated ? data.isIntegrated : false
      }&current_organization=${data.currentOrgId}`
  );
  return result;
}

/**
 * API call for getting vendor drop down list padinated
 */
export async function getVendorsDropdownList(data: {
  orgId: number;
  pageNum: number;
  pageSize: number;
  searchQuery: string;
  vendorId?: number | null;
  signal?: AbortSignal;
}) {
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      `/dropdown-list?current_organization=${data.orgId}${
        data.pageNum ? "&page_num=" + data.pageNum : "&page_num=1"
      }${data.pageSize ? "&page_size=" + data.pageSize : "&page_size=10"}${
        data.searchQuery ? "&vendor_name=" + data.searchQuery : ""
      }${data.vendorId ? "&vendor_id=" + data.vendorId : ""}`,
    data.signal
  );
  return result;
}

/**
 * API call for getting vendor list for filter with pagination
 */
export async function getVendorsFilterList(data: {
  orgId: number;
  organizationIds: number[];
  pageNum: number;
  pageSize: number;
  searchQuery: string;
  signal?: AbortSignal;
}) {
  const orgIds: any = [];
  // data.organizationIds.map((orgId) => {
  //   return `&organization_ids=${orgId}`;
  // });
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      `/transaction-filter-dropdown?current_organization=${
        data.orgId
      } ${orgIds.join("")}${
        data.pageNum ? "&page_num=" + data.pageNum : "&page_num=1"
      }${data.pageSize ? "&page_size=" + data.pageSize : "&page_size=10"}${
        data.searchQuery ? "&vendor_name=" + data.searchQuery : ""
      }`,
    data.signal
  );
  return result;
}

/**
 * API call for getting vendor list for soa with pagination
 */
export async function getVendorsListForSoa(data: {
  orgId: number;
  currentOrgId: number;
  isIntegrated?: boolean;
  searchQuery?: string;
  pageNum: number;
  pageSize: number;
  vendorId: number | null;
  signal?: AbortSignal;
}) {
  const result = await get(
    Api.vendorManagement +
      Api.vendor +
      `/dropdown-list-for-soa?organization_id=${data.orgId}&is_integrated=${
        data.isIntegrated ? data.isIntegrated : false
      }&current_organization=${data.currentOrgId}${
        data.pageNum ? "&page_num=" + data.pageNum : "&page_num=1"
      }${data.pageSize ? "&page_size=" + data.pageSize : "&page_size=10"}${
        data.searchQuery ? "&vendor_name=" + data.searchQuery : ""
      }`
  );
  return result;
}