import { FormikProps } from "formik";
import Select from "react-select";
import { fiscalYearOptions } from "../../../constants/constants";
import { customFiscalSelectStyle } from "../SelectCustomFiscalSrtyle";
import { Container } from "../htmlTags/Container";
type Props = {
  handleFiscalYearChange: (fiscalYear: string) => void;
  fiscalYear: string;
  isDisabled: boolean;
  formik?: FormikProps<any>;
  name?: string;
  fiscalYearError?: string;
};
export const OrgFiscalYearSelect = (props: Props) => {
  return (
    <Container className="col ">
      <Container>
        <Select
          name="fiscal_year"
          id="fiscal_year"
          isDisabled={props.isDisabled}
          menuPlacement={"bottom"}
          menuShouldScrollIntoView={true}
          options={
            fiscalYearOptions?.length > 0
              ? fiscalYearOptions.map((year) => {
                  return {
                    label: year.value,
                    value: year.label,
                  };
                })
              : []
          }
          className={`form-select custom-select fiscal-year-select mt-0 w-100 ${
            props.fiscalYearError ? "error" : ""
          } ${props.isDisabled ? "disabled" : ""}`}
          value={
            props.fiscalYear
              ? {
                  value: props.fiscalYear,
                  label: props.fiscalYear,
                }
              : ""
          }
          placeholder="Choose fiscal year"
          onChange={(e: any) => {
            props.handleFiscalYearChange(e?.value || "");
            props.formik &&
              props.formik.setFieldValue(props.name || "", e?.value);
          }}
          styles={customFiscalSelectStyle}
          isSearchable={false}
          isClearable={true}
        />
        {props.fiscalYearError && (
          <span className="error w-100">{props.fiscalYearError}</span>
        )}
      </Container>
    </Container>
  );
};
