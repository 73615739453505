import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../../app/hooks";
import { ErrorToaster } from "../../../../../../helpers/toastHelper";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { PurchaseOrderList, Transactionlimit } from "../../../../../../types";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import {
  filterAppliedState,
  listFilterSelector,
} from "../../../../../../appSlice";
import {
  fiscalYearFilterSelector,
  setLoaderState,
  dateListFilterSelector,
  dueDateListFilterSelector,
} from "../../../../../common/commonSlice";
import {
  getPurchaseOrderPdfLink,
  purchaseOrderList,
  sendPurchaseOrderData,
} from "../../../purchaseOrderSlice";
import {
  DateFormatHandler,
  StatusColor,
  downloadUsingURL,
  getProfileFormatedDate,
} from "../../../../../../helpers/helper";
import {
  purchaseOrderColumnValues,
  purchaseOrderDefaultValues,
  purchaseOrderMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import useCommonData from "../../../../../hooks/useCommon";
import CustomTooltips from "../../../../../common/components/Tooltip";
import TableSetup from "../../../../../common/components/table/TableSetup";
import useListData from "../../../../../hooks/useListData";
import SendEmailModal from "../../../../../common/components/SendEmailModal";
import PurchaseOrderTable from "./purchaseOrderTable";
import ListActions from "../ListActions";

type Props = {
  transactionLimit: Transactionlimit;
  isFilterApplied: boolean;
  initializeFilter: () => void;
  deleteListData: (id: number, name: string) => void;
};
function PurchaseOrderTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setDateSortOrder,
    setTotalList,
  } = usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } =
    useListData();
  const { purchaseOrderRolePermission } = usePermissionHandler();
  const selectedFilterData = useAppSelector(listFilterSelector);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const [purchaseOrderData, setPurchaseOrderData] = useState<
    PurchaseOrderList[]
  >([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "Tags",
    "Terms",
  ]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const [purchaseOrderNumber, setPurchaseOrderId] = useState(0);
  const [emailList, setEmailList] = useState<string[] | []>([]);

  useImperativeHandle(
    ref,
    () => ({
      fetchPurchaseOrderList: fetchPurchaseOrderList,
    }),
    []
  );

  useEffect(() => {
    fetchTableCustomizationFields(
      "Purchase Orders",
      purchaseOrderDefaultValues,
      purchaseOrderMandatoryValues
    );
    fetchPurchaseOrderList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
    dateListFilter,
    dueDateListFilter,
  ]);

  useEffect(() => {
    if (isFilterApplied) fetchPurchaseOrderList();
  }, [selectedFilterData, isFilterApplied]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    purchaseOrderColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  }, [purchaseOrderData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  /**
   * Fetch all purchase order
   */
  const fetchPurchaseOrderList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const vendorList = selectedFilterData.hasOwnProperty("Vendors")
      ? selectedFilterData?.Vendors
      : [];
    const responseAction = await dispatch(
      purchaseOrderList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        vendorList: vendorList,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        startDate: dateListFilter.date.startDate || "",
        endDate: dateListFilter.date.endDate || "",
        startDueDate: dueDateListFilter.dueDate.startDate || "",
        endDueDate: dueDateListFilter.dueDate.endDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);

      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setPurchaseOrderData(response.purchase_orders);
        let length = response.purchase_orders.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    selectedFilterData,
    currentUserInfo.organization_id,
    dueDateListFilter,
    dateListFilter,
  ]);
  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  const getPurchaseOrderColumnValues = () => {
    let newPurchaseOrderColumnValues = purchaseOrderColumnValues;
    return newPurchaseOrderColumnValues;
  };
  /**
   * set table column headings
   */
  const columns = useMemo(() => {
    let columns = [
      {
        Header: "Issue Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "PO Number",
        accessor: "show.purchaseOrderNumber",
        sort: false,
        sortOrder: "",
        className: "bill_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Vendor Name",
        accessor: "show.vendorName",
        sort: false,
        sortOrder: "",
        className: "vend_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement="bottom"
              title={value?.length > 15 ? value : ""}
            >
              <span className="d-block ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "bill_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Delivery Date",
        accessor: selectedColumns.includes("Delivery Date")
          ? "show.deliveryDate"
          : "Delivery Date",
        sort: false,
        sortOrder: "",
        className: "due_date",
        Cell: ({ cell: { value } }: any) => {
          const today = new Date();
          const [day, month, year] = value.split("-");
          const deliveryDate = new Date(+year, +month - 1, +day);
          const isPast = deliveryDate < today;
          return <span className={isPast ? "s-red" : ""}>{value}</span>;
        },
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Total",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Tax Deducted(TDS)",
        accessor: selectedColumns.includes("Tax Deducted(TDS)")
          ? "show.tds"
          : "Tax Deducted(TDS)",
        sort: false,
        sortOrder: "",
        className: "tds",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Purchase Orders"
            tableColumns={selectedColumns}
            customizeColumnValues={getPurchaseOrderColumnValues()}
            mandatoryColumns={purchaseOrderMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Purchase Orders",
                purchaseOrderDefaultValues,
                purchaseOrderMandatoryValues
              );
              fetchPurchaseOrderList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!purchaseOrderRolePermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ||
            value.status === "Cancelled" ? null : (
            <ListActions
              value={value}
              onClickSendMail={() => fetchContactEmail(value.id, value.emailTo)}
              onClickDownloadPdf={() => downloadPdf(value.id, value.poName)}
              onClickDelete={() => {
                props.deleteListData(value.id, value.listName);
              }}
              fetchPurchaseOrderList={fetchPurchaseOrderList}
            />
          );
        },
      },
    ];
    return columns;
  }, [
    dateSortOrder,
    selectedColumns,
    hiddenColumns,
    currentUserInfo.organization_id,
  ]);
  /**
   * set table row data
   */
  const data = purchaseOrderData
    ? purchaseOrderData.map((purchase_orders, index) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            purchase_orders.issue_date
          ),
          purchaseOrderNumber: joinTransactionNumber(
            purchase_orders.purchase_order_number
          ),
          referenceNumber: purchase_orders.reference_number,
          vendorName: purchase_orders.vendor_name,
          status: purchase_orders.purchase_order_status,
          deliveryDate: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            purchase_orders.delivery_date
          ),
          totalTaxableAmount:
            getCurrencyCode(purchase_orders.currency_code) +
            " " +
            NumberFormat(
              Number(purchase_orders.total_taxable_amount),
              purchase_orders.currency_code
                ? purchase_orders.currency_code
                : "",
              orgCurrencyList
            ),
          amount:
            getCurrencyCode(purchase_orders.currency_code) +
            " " +
            NumberFormat(
              Number(purchase_orders.total),
              purchase_orders.currency_code
                ? purchase_orders.currency_code
                : "",
              orgCurrencyList
            ),
          tds:
            getCurrencyCode(purchase_orders.currency_code) +
            " " +
            NumberFormat(
              Number(purchase_orders.tax_deducted),
              purchase_orders.currency_code
                ? purchase_orders.currency_code
                : "",
              orgCurrencyList
            ),
          organization: purchase_orders.organization,
          actions: {
            id: purchase_orders.id,
            poName: joinTransactionNumber(
              purchase_orders.purchase_order_number
            ),
            status: purchase_orders.purchase_order_status,
            listName: purchase_orders.purchase_order_number,
            emailTo: purchase_orders.email_to,
            isDeletable: purchase_orders.is_deletable,
            organizationId: purchase_orders.organization_id,
            isLocked: purchase_orders.is_locked,
            created_by: purchase_orders.created_by,
            purchase_orders: purchase_orders,
            vendorId: purchase_orders.vendor_id,
            currencyCode: purchase_orders.currency_code,
            transactionDate: purchase_orders.issue_date,
            is_fiscal_closed: purchase_orders.is_fiscal_closed,
            isEditable: purchase_orders.is_editable,
          },
        },
      }))
    : [];
  const sortHandler = (column: string) => {
    if (column === "Issue Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendPurchaseOrderData({
        purchaseOrderId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchPurchaseOrderList();
      }
    }
    return responseAction;
  };
  /**
   * Set the contact email in a state of selected purchase order
   */
  const fetchContactEmail = (id: number, emailsList: any[]) => {
    let emails = emailsList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setPurchaseOrderId(id);
  };

  const downloadPdf = async (purchaseOrderNumber: number, poName: string) => {
    const responseAction = await dispatch(
      getPurchaseOrderPdfLink({
        purchaseOrderId: purchaseOrderNumber,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          poName + ".pdf"
        );
        if (result) {
          toast.success("Purchase Order downloaded successfully!", {
            toastId: "purchase-order-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };
  return (
    <>
      <PurchaseOrderTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        purchaseOrderRolePermission={purchaseOrderRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
      />
      <SendEmailModal
        emailLists={emailList}
        id={purchaseOrderNumber}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
    </>
  );
}

export default React.memo(forwardRef(PurchaseOrderTableContainer));
