import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select, { components, SingleValue } from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { vendorDropdownListForSoa } from "../../../home/vendorManagement/vendorSlice";
import { currentUserSelector } from "../../commonSlice";
import { customSelectStyle } from "../SelectCustomStyle";
import { useInView } from "react-intersection-observer";
import "./VendorSelect.css";
import { colors } from "../../../constants/colors";

type Props = {
  vendorId: string | number;
  vendorName: string;
  orgId?: number | null;
  error: string;
  onChange: (
    newValue: SingleValue<{
      label: string;
      value: string | number;
      currency_id: string | number;
      currency_code: string;
    }>
  ) => void;
  isDisabled: boolean;
  isAllOptionEnabled?: boolean;
  allOptionLabel?: string;
  onBlur?: (e: any) => void;
  isModal?: boolean;
};

const VendorSelectWithOrgFilter = ({
  vendorId,
  vendorName,
  orgId,
  error,
  onChange,
  isDisabled,
  isAllOptionEnabled,
  allOptionLabel,
  onBlur,
  isModal,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [vendorListOptions, setVendorListOptions] = useState<
    {
      label: string;
      value: string | number;
      currency_id: string | number;
      currency_code: string;
    }[]
  >([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [currencyId, setCurrencyId] = useState<string | number>("");
  const [currencyCode, setCurrencyCode] = useState<string>("");
  const { ref, inView } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    if (inView) {
      handleMenuScrollToBottom();
    }
  }, [inView]);
  const getSearchVendorsList = useCallback(
    async (query: string, pageNum: number, signal?: AbortSignal) => {
      try {
        const responseAction = await dispatch(
          vendorDropdownListForSoa({
            orgId: orgId ? Number(orgId) : currentUserInfo.organization_id,
            currentOrgId: currentUserInfo.organization_id,
            searchQuery: query,
            pageNum: pageNum,
            pageSize: 10,
            vendorId: vendorId ? Number(vendorId) : null,
            signal: signal,
          })
        );

        const response = responseAction.payload;
        if (response && response.vendors) {
          const newvendors = Array.isArray(response.vendors)
            ? response.vendors.map((vendor: any) => ({
                label: vendor.vendor_display_name,
                value: vendor.id,
                currency_id: vendor.currency_id, // Include currency_id
                currency_code: vendor.currency_code, // Include currency_code
                organization_name: vendor.organization_name,
                vendor_identification: vendor.identifier,
              }))
            : [];
          let selectedvendor = {};
          setVendorListOptions((prevOptions) => {
            if (vendorId) {
              let combined = [...prevOptions, ...newvendors];
              selectedvendor = combined.find(
                (vendor: any) => Number(vendor.value) === Number(vendorId)
              );
            }
            const combinedOptions = Object.keys(selectedvendor || {}).length
              ? [selectedvendor, ...prevOptions, ...newvendors]
              : [...prevOptions, ...newvendors];

            // Remove duplicates using a Set
            const uniqueOptions = combinedOptions?.reduce((acc, current) => {
              const isDuplicate = acc?.some(
                (item: any) =>
                  item.value === current.value && item.label === current.label
              );
              if (!isDuplicate) {
                acc?.push(current);
              }
              return acc;
            }, [] as { label: string; value: string | number; currency_id: string | number; currency_code: string }[]);

            return uniqueOptions;
          });

          setHasMore(response.pagination.next);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to fetch vendors:", error);
      }
    },
    [currentUserInfo.organization_id, dispatch, vendorId]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getSearchVendorsList(searchQuery, 1, signal);
    return () => {
      controller.abort();
    };
  }, [searchQuery, getSearchVendorsList]);

  const handleVendorSearch = useCallback((query: string) => {
    setIsLoading(true);
    setSearchQuery(query);
    setPageNum(1);
    setVendorListOptions([]); // Reset options only when search query changes
  }, []);

  const handleMenuScrollToBottom = useCallback(async () => {
    if (hasMore && !isLoading) {
      await getSearchVendorsList(searchQuery, pageNum + 1);
      setPageNum((prevPageNum) => prevPageNum + 1);
    }
  }, [hasMore, pageNum, getSearchVendorsList, isLoading]);

  const handleChange = (
    newValue: SingleValue<{
      label: string;
      value: string | number;
      currency_id: string | number;
      currency_code: string;
    }>
  ) => {
    if (newValue) {
      // Update internal currency_id and currency_code states
      setCurrencyId(newValue.currency_id);
      setCurrencyCode(newValue.currency_code);

      // Pass the selected value (including currency_id and currency_code) to the parent
      onChange(newValue);
    }
  };
  const LabelWithRoot = useMemo(() => {
    return (data: any) => {
      return (
        <div
          {...data.innerProps}
          className="custom-select-option-wrapper"
          style={{
            backgroundColor:
              Number(data.data.value) === Number(vendorId)
                ? colors.whisper
                : "white",
          }}
        >
          <span className="custom-select-option-account-name">
            {data.data.label}
          </span>
          {data.data.vendor_identification && (
            <div className="custom-select-option-account-sub-root">
              {data.data.vendor_identification}
            </div>
          )}
          {data.data.organization_name && (
            <div className="custom-select-option-account-root">
              {data.data.organization_name}
            </div>
          )}
        </div>
      );
    };
  }, [vendorId]);
  const CustomMenuList = useMemo(() => {
    return (props: any) => {
      return (
        <components.MenuList {...props}>
          {props.children}
          <div className="temp-div" style={{ height: "10px" }} ref={ref}></div>
        </components.MenuList>
      );
    };
  }, [vendorId]);

  return (
    <Select
      id={"vendor-select-field"}
      name="vendor"
      value={
        vendorId && isAllOptionEnabled !== true
          ? {
              label: vendorName,
              value: vendorId,
              currency_id: currencyId,
              currency_code: currencyCode,
            }
          : (vendorId === 0 || vendorId) && isAllOptionEnabled === true
          ? {
              label: vendorName,
              value: vendorId,
              currency_id: currencyId,
              currency_code: currencyCode,
            }
          : null
      }
      options={
        isAllOptionEnabled
          ? [
              {
                value: 0,
                label: allOptionLabel || "",
                currency_code: "",
                currency_id: 0,
              },
              ...vendorListOptions,
            ]
          : vendorListOptions
      }
      aria-label="vendor Select"
      inputValue={searchQuery}
      className="custom-select vendor-select vendor_name"
      isDisabled={isDisabled}
      isSearchable={true}
      maxMenuHeight={200}
      placeholder="Choose vendor"
      onChange={handleChange} // Use the updated handleChange function
      menuPortalTarget={
        isModal === true
          ? undefined
          : document.body
      }
      styles={customSelectStyle}
      onInputChange={handleVendorSearch}
      components={{ MenuList: CustomMenuList, Option: LabelWithRoot }}
      onMenuOpen={() => {
        setPageNum(1);
        setSearchQuery("");
        setHasMore(true);
        setMenuIsOpen(true);
        getSearchVendorsList("", 1);
      }}
      onMenuClose={() => setMenuIsOpen(false)}
      menuIsOpen={menuIsOpen}
    />
  );
};

export default VendorSelectWithOrgFilter;
