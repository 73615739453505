import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";
import { ErrorToaster, SuccessToaster } from "../../../../helpers/toastHelper";
import { setLoaderState } from "../../../common/commonSlice";
import { DockedButtonGroup } from "../../../common/components/button/DockedButtonGroup";
import { Container } from "../../../common/components/htmlTags/Container";
import { Label } from "../../../common/components/htmlTags/Label";
import { Form } from "../../../common/components/htmlTags/Form";
import { RadioButtonGroup } from "../../../common/components/radioButtonGroup/radioButtonGroup";
import useCommonData from "../../../hooks/useCommon";
import { useMeApiTrigger } from "../../../hooks/useTriggerOrgDetails";
import { configureReminderEmail } from "../reminderEmailSlice";
import NotificationModal from "../../../common/components/NotificationModal";
import { ReportTagFormValues } from "../../../../types";
import { useTransition } from "react";
import Select from "react-select";
import "./ReminderEmailConfigForm.css";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import AccountSelect from "../../../common/components/accountSelect/AccountSelect";
import { ReminderEmailConfigFormValidation } from "./ReminderEmailConfigFormValidation";
import { useInitializeConfigForm } from "../hooks/useInitializeForm";

type Props = {
  handleClose: () => void;
};
export const ReminderEmailConfigForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [isPending, startTransition] = useTransition();
  const { triggerMeApi } = useMeApiTrigger();
  const { initialValues } = useInitializeConfigForm();
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ReminderEmailConfigFormValidation,
    validateOnMount: true,
    onSubmit: async (value) => {
      dispatch(setLoaderState(true));
      const responseAction = await dispatch(
        configureReminderEmail({
          orgId: currentUserInfo.organization_id,
          values: {
            subscription_action: value.subscription_action,
            invoice_action: value.invoice_action,
            write_off_account_id:
              value.invoice_action === "WRITE_OFF"
                ? value.write_off_account_id
                : 0,
          },
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          "Updated reminder email configuration!",
          "reminder-email-config-succcess"
        );
        startTransition(() => {
          triggerMeApi();
        });
        props.handleClose();
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    },
  });

  const CustomOption = (props: any) => {
    const { innerRef, innerProps, data, isSelected, children } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={`custom-select-option-wrapper ${
          isSelected ? "select-option-selected" : ""
        }`}
      >
        <span>{children}</span>
        {data.value === "REVERSE_INVOICE" && (
          <span className="custom-select-option-description">
            Invoice will be marked as 'Closed', and an adjustment credit note will
            be created
          </span>
        )}
      </div>
    );
  };
  return (
    <Form
      className="reminder-email-config-form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Container className="field-container">
        <Label className="" htmlFor="">
          When the automatic reminder period ends, what happens to subscription?
        </Label>
        <Select
          options={[
            { label: "Retain as Active", value: "RETAIN_AS_ACTIVE" },
            { label: "Cancel Subscription", value: "CANCEL_SUBSCRIPTION" },
          ]}
          value={{
            label: formik.values.subscription_action_name,
            value: formik.values.subscription_action,
          }}
          styles={customSelectStyle}
          className="reminder-email-config-select-one"
          onChange={(e) => {
            formik.setFieldValue("subscription_action", e?.value);
            formik.setFieldValue("subscription_action_name", e?.label);
          }}
        />
      </Container>
      <Container className="field-container">
        <Label className="" htmlFor="">
          When the automatic reminder period ends, what happens to invoice?
        </Label>
        <Select
          options={[
            { label: "Retain Overdue Status", value: "MARK_NOT_PAID" },
            { label: "Write Off", value: "WRITE_OFF" },
            { label: "Mark as Void", value: "MARK_VOID" },
            { label: "Reverse the Invoice", value: "REVERSE_INVOICE" },
          ]}
          value={{
            label: formik.values.invoice_action_name,
            value: formik.values.invoice_action,
          }}
          styles={customSelectStyle}
          className="reminder-email-config-select-two"
          onChange={(e) => {
            formik.setFieldValue("invoice_action", e?.value);
            formik.setFieldValue("invoice_action_name", e?.label);
          }}
          components={{
            Option: (props) => <CustomOption {...props} />,
          }}
        />
      </Container>
      {formik.values.invoice_action === "WRITE_OFF" && (
        <Container className="field-account-container">
          <Label className="" htmlFor="">
            Account
          </Label>
          <AccountSelect
            itemAccount={formik.values.write_off_account_id}
            itemAccountName={formik.values.write_off_account_name}
            baseAccount={"Assets~Expenses"}
            organizationId={currentUserInfo.organization_id}
            itemIndex={0}
            error={""}
            id="account"
            className="account-select"
            onChange={(e) => {
              formik.setFieldValue("write_off_account_id", e?.id);
              formik.setFieldValue("write_off_account_name", e?.account_name);
            }}
            wrapperClass="account-select-wrapper"
          />
          {formik.errors.write_off_account_id && (
            <span className="error">
              {formik.errors.write_off_account_id as string}
            </span>
          )}
        </Container>
      )}
      <Container className="w-100">
        <DockedButtonGroup
          isDeleteButtonDisabled={true}
          isDeleteButtonEnabled={false}
          isEdit={false}
          isSaveDisabled={false}
          isModal={true}
          onSave={formik.submitForm}
          onDelete={() => {}}
          onCancel={() => {
            formik.resetForm();
            props.handleClose();
          }}
          saveButtonType="button"
          shouldDockToBottom={false}
        />
      </Container>
    </Form>
  );
};
