import "./tableInfo.css";
import info from "../../../../../assets/images/email-info-msg-icon.svg";
import { Grid } from "@mui/material";
import { InfoMessage } from "../../../../common/components/infos/InfoMessage";

type Props = {
  nextInvoiceAmount: string;
  unpaidAmount: string;
  nextIncoiceDate: string;
  recurringInvoiceStatus: string;
  scheduledSubscriptionData: any;
};
export default function TableInfo(props: Props) {
  const cancelledObject =
    props.scheduledSubscriptionData.status_change_scheduler?.find(
      (item: any) => item.new_status === "CANCELLED" || item.new_status === "STOPPED"
    );
  const shouldShowInfo = cancelledObject?.scheduled_date > props.nextIncoiceDate || cancelledObject?.scheduled_date === undefined;
  return shouldShowInfo ? (
    <Grid container spacing={2} className="child-invoice-container">
      <Grid item xs={6} className="ri_table_info_grid">
        {["cancelled", "expired", "stopped", "non_renewing"].includes(
          props.recurringInvoiceStatus.toLowerCase()
        ) ? null : props.recurringInvoiceStatus && props.nextIncoiceDate ? (
          <InfoMessage message={`Next Invoice for ${props.nextInvoiceAmount} will be created on ${
            props.nextIncoiceDate ? props.nextIncoiceDate : ""
          }`} />
        ) : null}
      </Grid>
      <Grid item xs={6} className="child-data-info">
        {`Unpaid Invoices: ${props.unpaidAmount}`}
      </Grid>
    </Grid>
  ) : null;
}
