import { useMemo, useState } from "react";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import { coustomCase } from "../../../../../helpers/planHelper";
import { ObjectType } from "../../../../../types";
import CenterModal from "../../../../common/components/centerModal/CenterModal";
import DetailTotalSection from "../../../../common/components/detailsPage/DeatailsTotalSection";
import DetailsTableContainerSubscription from "../../../../common/components/detailsPage/DetailsTableContainerSubscription";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import { Container } from "../../../../common/components/htmlTags/Container";
import { Span } from "../../../../common/components/htmlTags/Span";
import { SkeletonLoader } from "../../../../common/components/skeletonLoader/SkeletonLoader";
import TableSkeletonLoader from "../../../../common/components/ui/Loaders/TableSkeletonLoader/TableSkeletonLoader";
import { colors } from "../../../../constants/colors";
import { tdsApplyLevel } from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { formateDateMonth } from "../../../../../helpers/subscriptionHelper";
// import { getPreviewSubscriptionInvoice } from "../../subscriptionSlice";

type Props = {
  invoiceIndex: "first" | "next";
  currencyCode: string;
  taxes: ObjectType;
  tdsLevel: string;
  payload: any;
  getInvoiceForPreview: () => Promise<void>;
  type?: string;
};
export const SubscriptionInvoiceBreakdownNextInvoiceDate = (props: Props) => {
  const { currentUserInfo, orgCurrencyList, dispatch } = useCommonData();
  const [invoiceBreakdown, setInvoiceBreakdown] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const skeletonLoaderProps: {
    variant: "circular" | "rectangular" | "rounded" | "text";
    height: string;
    animation: "wave" | "pulse";
    color: string;
  } = {
    animation: "wave",
    color: colors.warmWhite,
    variant: "rounded",
    height: "25px",
  };
  const { isGstOrg } = useGst();
  let taxTypes = Object.entries(props.taxes);
  function convertToNormalText(str: string) {
    if (!str) return "";
    let words = str.toLowerCase().split("_");
    return words.join(" ");
  }

  function formatFrequency(frequency: string) {
    if (!frequency) return "";
    let formattedStr = frequency
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return formattedStr;
  }

  function convertToTimeUnit(unit: string): string {
    switch (unit) {
      case "DAILY":
        return "days";
      case "WEEKLY":
        return "weeks";
      case "MONTHLY":
        return "months";
      case "YEARLY":
        return "years";
      default:
        return unit;
    }
  }

  /**
   * set table column headings for plan and addon
   */

  const columnsForPlanAddon = useMemo(() => {
    const columns = [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <Span className="item-bold">{value}</Span>;
        },
      },
      // Conditional HSN/SAC Code column
      ...(isGstOrg
        ? [
            {
              Header: "Hsn/sac code",
              accessor: "show.hsnCode",
              className: "hsncode text-nowrap",
            },
          ]
        : []),
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap left-align",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <Span>{value.discount}</Span>
              <Span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </Span>
            </>
          );
        },
      },
      {
        Header: "Coupon",
        accessor: "show.coupon",
        className: "coupon text-nowrap",
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return <TaxColumn values={value} currencyCode={props.currencyCode} />;
        },
      },
      {
        Header: "TDS",
        accessor:
          props.tdsLevel === tdsApplyLevel.LINE_ITEM_LEVEL
            ? "show.item"
            : "TDS",
        className: "tds text-nowrap",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <Span>
                {props.currencyCode}{" "}
                {NumberFormat(
                  Number(value.tds_total),
                  props.currencyCode,
                  orgCurrencyList
                )}
              </Span>
              <Span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </Span>
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <Span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </Span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ];

    return columns;
  }, [props.currencyCode, isGstOrg]);
  const getSubscriptionInvoicePreviewData = async () => {
    setInvoiceBreakdown(props.payload);
  };

  return (
    <>
      <CenterModal
        modalTitle={``}
        onOpenFunction={async () => {
          await getSubscriptionInvoicePreviewData();
        }}
        isCloseButtonEnabled={true}
        buttonContent={<Span style={{ fontSize: "14px" }}>See Breakdown</Span>}
        buttonClass="blue-text-btn no-appearance"
        modalContent={
          <Container
            style={{ maxHeight: "800px", overflow: "auto", minWidth: "1000px" }}
          >
            {/* <Container>
              <Span>
                <h2 className="mb-0 subscription-details-plan-addon-coupon-heading-invoice">
                  Plan, Addon & Coupon Details
                </h2>
              </Span>
            </Container> */}
            {props.type && props.type === "INV" ? (
              props.payload.invoices.map(
                (invoice: {
                  invoicing_period: string;
                  plan_details: any[];
                  addon_details: any[];
                  charge_details: any[];
                  coupons: any[];
                  total: {
                    tax_amount: string | number;
                    payment_made: string | number | undefined;
                    total_taxable_amount: string | number;
                    total_amount: string | number;
                    balance_due: string | number;
                    transaction_tds_amount: number;
                    roundoff_amount: number | null | undefined;
                    coupon_amount: number | null | undefined;
                  };
                  tds_name: any;
                  tds_percentage: any;
                  default_tds_percentage: any;
                  tcs_total: any;
                  tcs_name: any;
                  tcs_percentage: any;
                  roundoff_enabled: boolean | undefined;
                }) => (
                  <>
                    <Container
                      style={{
                        fontSize: "18px",
                        color: colors.charcoalGrey,
                        marginBottom: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Plan, Addon & Coupon Details
                    </Container>
                    <Container
                      className="table-section"
                      style={{ padding: "10px", border: "1px solid #e9e9e9" }}
                    >
                      <Container
                        style={{
                          fontSize: "18px",
                          paddingLeft: "19px",
                          color: colors.charcoalGrey,
                          marginBottom: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Invoicing period:{" "}
                        {invoice.invoicing_period &&
                          formateDateMonth(
                            invoice.invoicing_period.split(" - ")[0]
                          )}{" "}
                        {invoice.invoicing_period && "to"}{" "}
                        {invoice.invoicing_period &&
                          formateDateMonth(
                            invoice.invoicing_period.split(" - ")[1]
                          )}
                      </Container>
                      <Container className="table-wrap subscription-invoice-details">
                        {invoice.plan_details &&
                          invoice.plan_details.length > 0 &&
                          invoice?.plan_details?.map((plan: any) => {
                            return (
                              <>
                                <Container className="plan-description">
                                  {isLoading ? (
                                    <Container className="mb-2">
                                      <SkeletonLoader
                                        {...skeletonLoaderProps}
                                        width="150px"
                                      />
                                    </Container>
                                  ) : (
                                    <>
                                      <Container
                                        style={{
                                          fontSize: "18px",
                                          color: colors.charcoalGrey,
                                          marginBottom: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Plans
                                      </Container>
                                    </>
                                  )}
                                  {isLoading ? (
                                    <Container className="mb-2">
                                      <SkeletonLoader
                                        {...skeletonLoaderProps}
                                        width="200px"
                                      />
                                    </Container>
                                  ) : (
                                    <h6 className="item-bold">
                                      {plan.plan_name}
                                    </h6>
                                  )}
                                  <Container className="plan-tag">
                                    {isLoading ? (
                                      <Container className="mb-2">
                                        <SkeletonLoader
                                          {...skeletonLoaderProps}
                                          width="200px"
                                        />
                                      </Container>
                                    ) : (
                                      <Span>
                                        Frequency:{" "}
                                        {plan.custom_repeat_count &&
                                        plan.custom_repeat_count > 0
                                          ? ` Every ${
                                              plan.custom_repeat_count
                                            }  ${convertToTimeUnit(
                                              plan.frequency
                                            )}`
                                          : coustomCase(plan.frequency)}{" "}
                                        | Pricing model:{" "}
                                        {formatFrequency(plan.pricing_model)}
                                      </Span>
                                    )}
                                  </Container>
                                  <Container>
                                    {isLoading ? (
                                      <Container className="mb-2">
                                        <SkeletonLoader
                                          {...skeletonLoaderProps}
                                          width="200px"
                                        />
                                      </Container>
                                    ) : (
                                      <Span style={{ color: "#747d84" }}>
                                        Invoicing period:{" "}
                                        {plan.invoicing_period &&
                                          formateDateMonth(
                                            plan.invoicing_period.split(
                                              " - "
                                            )[0]
                                          )}{" "}
                                        {plan.invoicing_period && "to"}{" "}
                                        {plan.invoicing_period &&
                                          formateDateMonth(
                                            plan.invoicing_period.split(
                                              " - "
                                            )[1]
                                          )}
                                      </Span>
                                    )}
                                  </Container>
                                  {!!plan.description ? (
                                    <Container>
                                      {isLoading ? (
                                        <Container className="mb-2">
                                          <SkeletonLoader
                                            {...skeletonLoaderProps}
                                            width="200px"
                                          />
                                        </Container>
                                      ) : (
                                        <Span style={{ color: "#747d84" }}>
                                          Description: {plan.description}
                                        </Span>
                                      )}
                                    </Container>
                                  ) : null}
                                </Container>
                                {isLoading ? (
                                  <TableSkeletonLoader
                                    colCount={5}
                                    rowCount={3}
                                  />
                                ) : (
                                  <DetailsTableContainerSubscription
                                    columns={columnsForPlanAddon}
                                    data={plan.items}
                                    currency_code={props.currencyCode}
                                    haveColumnClassName={true}
                                    className="table inv-preview-table subscription-data-view-table "
                                    hiddenColumns={["TDS"]}
                                    pricingModel={plan.pricing_model}
                                    component="invoice"
                                  />
                                )}
                              </>
                            );
                          })}
                        {invoice.addon_details &&
                          invoice.addon_details.length > 0 && (
                            <Container
                              style={{
                                fontSize: "18px",
                                color: colors.charcoalGrey,
                                paddingLeft: "23px",
                                paddingTop: "19px",
                                marginBottom: "-13px",
                                fontWeight: "bold",
                              }}
                            >
                              {isLoading ? (
                                <Container className="mb-2">
                                  <SkeletonLoader
                                    {...skeletonLoaderProps}
                                    width="150px"
                                  />
                                </Container>
                              ) : (
                                "Addons"
                              )}
                            </Container>
                          )}
                        {invoice.addon_details &&
                          invoice.addon_details.length > 0 &&
                          invoice?.addon_details?.map((addon: any) => {
                            return (
                              <>
                                <Container className="plan-description">
                                  {isLoading ? (
                                    <Container className="mb-2">
                                      <SkeletonLoader
                                        {...skeletonLoaderProps}
                                        width="200px"
                                      />
                                    </Container>
                                  ) : (
                                    <h6 className="item-bold">
                                      {addon.addon_name}
                                    </h6>
                                  )}
                                  <Container className="plan-tag">
                                    {isLoading ? (
                                      <Container className="mb-2">
                                        <SkeletonLoader
                                          {...skeletonLoaderProps}
                                          width="200px"
                                        />
                                      </Container>
                                    ) : (
                                      <Span>
                                        Frequency:{" "}
                                        {addon.custom_repeat_count &&
                                        addon.custom_repeat_count > 0
                                          ? ` Every ${
                                              addon.custom_repeat_count
                                            }  ${convertToTimeUnit(
                                              addon.frequency
                                            )}`
                                          : coustomCase(addon.frequency)}{" "}
                                        | Pricing model:{" "}
                                        {formatFrequency(addon.pricing_model)}
                                      </Span>
                                    )}
                                  </Container>
                                  <Container>
                                    {isLoading ? (
                                      <Container className="mb-2">
                                        <SkeletonLoader
                                          {...skeletonLoaderProps}
                                          width="200px"
                                        />
                                      </Container>
                                    ) : (
                                      <Span style={{ color: "#747d84" }}>
                                        Invoicing period:{" "}
                                        {addon.invoicing_period &&
                                          formateDateMonth(
                                            addon.invoicing_period.split(
                                              " - "
                                            )[0]
                                          )}{" "}
                                        {addon.invoicing_period && "to"}{" "}
                                        {addon.invoicing_period &&
                                          formateDateMonth(
                                            addon.invoicing_period.split(
                                              " - "
                                            )[1]
                                          )}
                                      </Span>
                                    )}
                                  </Container>
                                  {!!addon.description ? (
                                    <Container>
                                      {isLoading ? (
                                        <Container className="mb-2">
                                          <SkeletonLoader
                                            {...skeletonLoaderProps}
                                            width="350px"
                                          />
                                        </Container>
                                      ) : (
                                        <Span style={{ color: "#747d84" }}>
                                          Description: {addon.description}
                                        </Span>
                                      )}
                                    </Container>
                                  ) : null}
                                </Container>
                                {isLoading ? (
                                  <TableSkeletonLoader
                                    colCount={5}
                                    rowCount={3}
                                  />
                                ) : (
                                  <DetailsTableContainerSubscription
                                    columns={columnsForPlanAddon}
                                    data={addon.items}
                                    currency_code={props.currencyCode}
                                    haveColumnClassName={true}
                                    className="table inv-preview-table subscription-data-view-table "
                                    hiddenColumns={["TDS"]}
                                    pricingModel={addon.pricing_model}
                                    component="invoice"
                                  />
                                )}
                              </>
                            );
                          })}
                        {invoice.charge_details &&
                          invoice.charge_details.length > 0 && (
                            <Container
                              style={{
                                fontSize: "18px",
                                color: colors.charcoalGrey,
                                paddingLeft: "23px",
                                paddingTop: "19px",
                                marginBottom: "-13px",
                                fontWeight: "bold",
                              }}
                            >
                              {isLoading ? (
                                <Container className="mb-2">
                                  <SkeletonLoader
                                    {...skeletonLoaderProps}
                                    width="150px"
                                  />
                                </Container>
                              ) : (
                                "Charges"
                              )}
                            </Container>
                          )}
                        {invoice.charge_details &&
                          invoice.charge_details.length > 0 &&
                          invoice?.charge_details?.map((charge: any) => {
                            return (
                              <>
                                <Container className="plan-description">
                                  {isLoading ? (
                                    <Container className="mb-2">
                                      <SkeletonLoader
                                        {...skeletonLoaderProps}
                                        width="200px"
                                      />
                                    </Container>
                                  ) : (
                                    <h6 className="item-bold">
                                      {charge.charge_name}
                                    </h6>
                                  )}
                                  <Container className="plan-tag">
                                    {isLoading ? (
                                      <Container className="mb-2">
                                        <SkeletonLoader
                                          {...skeletonLoaderProps}
                                          width="200px"
                                        />
                                      </Container>
                                    ) : (
                                      <Span>
                                        Pricing model:{" "}
                                        {formatFrequency(charge.pricing_model)}
                                      </Span>
                                    )}
                                  </Container>
                                  <Container>
                                    {isLoading ? (
                                      <Container className="mb-2">
                                        <SkeletonLoader
                                          {...skeletonLoaderProps}
                                          width="200px"
                                        />
                                      </Container>
                                    ) : (
                                      <Span style={{ color: "#747d84" }}>
                                        Invoicing period:{" "}
                                        {charge.invoicing_period &&
                                          formateDateMonth(
                                            charge.invoicing_period.split(
                                              " - "
                                            )[0]
                                          )}{" "}
                                        {charge.invoicing_period && "to"}{" "}
                                        {charge.invoicing_period &&
                                          formateDateMonth(
                                            charge.invoicing_period.split(
                                              " - "
                                            )[1]
                                          )}
                                      </Span>
                                    )}
                                  </Container>
                                  {!!charge.description ? (
                                    <Container>
                                      {isLoading ? (
                                        <Container className="mb-2">
                                          <SkeletonLoader
                                            {...skeletonLoaderProps}
                                            width="350px"
                                          />
                                        </Container>
                                      ) : (
                                        <Span style={{ color: "#747d84" }}>
                                          Description: {charge.description}
                                        </Span>
                                      )}
                                    </Container>
                                  ) : null}
                                </Container>
                                {isLoading ? (
                                  <TableSkeletonLoader
                                    colCount={5}
                                    rowCount={3}
                                  />
                                ) : (
                                  <DetailsTableContainerSubscription
                                    columns={columnsForPlanAddon}
                                    data={charge.items}
                                    currency_code={props.currencyCode}
                                    haveColumnClassName={true}
                                    className="table inv-preview-table subscription-data-view-table "
                                    hiddenColumns={["TDS"]}
                                    pricingModel={charge.pricing_model}
                                    component="invoice"
                                  />
                                )}
                              </>
                            );
                          })}
                        {invoice.coupons && invoice.coupons.length > 0 && (
                          <Container
                            style={{
                              fontSize: "18px",
                              color: colors.charcoalGrey,
                              paddingLeft: "23px",
                              paddingTop: "19px",
                              marginBottom: "-13px",
                              fontWeight: "bold",
                            }}
                          >
                            {isLoading ? (
                              <Container className="mb-2">
                                <SkeletonLoader
                                  {...skeletonLoaderProps}
                                  width="150px"
                                />
                              </Container>
                            ) : (
                              "Coupons"
                            )}
                          </Container>
                        )}
                        {invoice?.coupons &&
                          invoice?.coupons.length > 0 &&
                          invoice?.coupons?.map((coupon: any) => {
                            return (
                              <>
                                <Container className="plan-description coupon-description-container">
                                  <Container>
                                    <h6 className="item-bold">{coupon.name}</h6>
                                    <Container className="plan-tag">
                                      {isLoading ? (
                                        <Container className="mb-2">
                                          <SkeletonLoader
                                            {...skeletonLoaderProps}
                                            width="200px"
                                          />
                                        </Container>
                                      ) : (
                                        <Span>
                                          Applicable{" "}
                                          {convertToNormalText(coupon.duration)}
                                        </Span>
                                      )}
                                    </Container>
                                  </Container>
                                  <Container className="coupon-discount-amount-container">
                                    {isLoading ? (
                                      <Container className="mb-2">
                                        <SkeletonLoader
                                          {...skeletonLoaderProps}
                                          width="100px"
                                        />
                                      </Container>
                                    ) : (
                                      coupon.discount
                                    )}
                                  </Container>
                                </Container>
                              </>
                            );
                          })}
                      </Container>
                      <Container className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
                        <Container className="thankyou-note w-50 d-flex align-items-end">
                          {/* {props.invoiceData?.customer_notes && ( */}
                          <Container className="note-container">
                            <></>
                            {/* <Span className="label">Customer Notes</Span> */}
                            {/* <p>{props.invoiceData.customer_notes}</p> */}
                          </Container>
                          {/* )} */}
                        </Container>
                        {isLoading ? (
                          <Container>
                            {Array.from({ length: 4 }).map(() => {
                              return (
                                <Container className="mb-2">
                                  <SkeletonLoader
                                    width="350px"
                                    animation="wave"
                                    color={colors.warmWhite}
                                    variant="rounded"
                                    height="25px"
                                  />
                                </Container>
                              );
                            })}
                          </Container>
                        ) : (
                          <DetailTotalSection
                            currencyCode={props.currencyCode}
                            totalTax={invoice?.total?.tax_amount}
                            paymentLabel="Payment received"
                            payment={invoice?.total?.payment_made}
                            taxTypes={taxTypes}
                            subTotal={invoice?.total?.total_taxable_amount}
                            total={invoice?.total?.total_amount}
                            balanceLabel={"Balance Due"}
                            balanceAmount={
                              invoice?.total?.balance_due
                                ? invoice?.total?.balance_due
                                : zeroDisplayFormat(
                                    decimalPlaceOfCurrency(
                                      props.currencyCode
                                        ? props.currencyCode
                                        : currentUserInfo.currency_code,
                                      orgCurrencyList
                                    )
                                  )
                            }
                            tdsItems={{
                              tdsAmount: invoice?.total?.transaction_tds_amount
                                ? invoice?.total?.transaction_tds_amount
                                : 0,
                              tdsName: invoice?.tds_name,
                              tdsPercentage: invoice?.tds_percentage,
                              defaultTdsPercentage:
                                invoice?.default_tds_percentage,
                            }}
                            tcsItems={{
                              tcsAmount: invoice?.tcs_total,
                              tcsName: invoice?.tcs_name,
                              tcsPercentage: invoice?.tcs_percentage,
                            }}
                            roundOffValue={invoice?.total?.roundoff_amount}
                            roundoffEnabled={invoice?.roundoff_enabled}
                            couponRequired={true}
                            coupon_amount={invoice?.total?.coupon_amount}
                            balanceDueRequired={true}
                          >
                            <></>
                          </DetailTotalSection>
                        )}
                      </Container>
                    </Container>
                  </>
                )
              )
            ) : (
              <>
                <Container
                  style={{
                    fontSize: "18px",
                    color: colors.charcoalGrey,
                    marginBottom: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Plan, Addon & Coupon Details
                </Container>
                <Container
                  className="table-section"
                  style={{ padding: "10px", border: "1px solid #e9e9e9" }}
                >
                  <Container className="table-wrap subscription-invoice-details">
                    {props.payload.plan_details &&
                      props.payload.plan_details.length > 0 &&
                      props.payload?.plan_details?.map((plan: any) => {
                        return (
                          <>
                            <Container className="plan-description">
                              {isLoading ? (
                                <Container className="mb-2">
                                  <SkeletonLoader
                                    {...skeletonLoaderProps}
                                    width="150px"
                                  />
                                </Container>
                              ) : (
                                <Container
                                  style={{
                                    fontSize: "18px",
                                    color: colors.charcoalGrey,
                                    marginBottom: "10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Plans
                                </Container>
                              )}
                              {isLoading ? (
                                <Container className="mb-2">
                                  <SkeletonLoader
                                    {...skeletonLoaderProps}
                                    width="200px"
                                  />
                                </Container>
                              ) : (
                                <h6 className="item-bold">{plan.plan_name}</h6>
                              )}
                              <Container className="plan-tag">
                                {isLoading ? (
                                  <Container className="mb-2">
                                    <SkeletonLoader
                                      {...skeletonLoaderProps}
                                      width="200px"
                                    />
                                  </Container>
                                ) : (
                                  <Span>
                                    Frequency:{" "}
                                    {plan.custom_repeat_count &&
                                    plan.custom_repeat_count > 0
                                      ? ` Every ${
                                          plan.custom_repeat_count
                                        }  ${convertToTimeUnit(plan.frequency)}`
                                      : coustomCase(plan.frequency)}{" "}
                                    | Pricing model:{" "}
                                    {formatFrequency(plan.pricing_model)}
                                  </Span>
                                )}
                              </Container>
                              <Container>
                                {isLoading ? (
                                  <Container className="mb-2">
                                    <SkeletonLoader
                                      {...skeletonLoaderProps}
                                      width="200px"
                                    />
                                  </Container>
                                ) : (
                                  <Span style={{ color: "#747d84" }}>
                                    {plan.invoicing_period &&
                                      formateDateMonth(
                                        plan.invoicing_period.split(" - ")[0]
                                      )}{" "}
                                    {plan.invoicing_period && "to"}{" "}
                                    {plan.invoicing_period &&
                                      formateDateMonth(
                                        plan.invoicing_period.split(" - ")[1]
                                      )}
                                  </Span>
                                )}
                              </Container>
                              {!!plan.description ? (
                                <Container>
                                  {isLoading ? (
                                    <Container className="mb-2">
                                      <SkeletonLoader
                                        {...skeletonLoaderProps}
                                        width="200px"
                                      />
                                    </Container>
                                  ) : (
                                    <Span style={{ color: "#747d84" }}>
                                      Description: {plan.description}
                                    </Span>
                                  )}
                                </Container>
                              ) : null}
                            </Container>
                            {isLoading ? (
                              <TableSkeletonLoader colCount={5} rowCount={3} />
                            ) : (
                              <DetailsTableContainerSubscription
                                columns={columnsForPlanAddon}
                                data={plan.items}
                                currency_code={props.currencyCode}
                                haveColumnClassName={true}
                                className="table inv-preview-table subscription-data-view-table "
                                hiddenColumns={["TDS"]}
                                pricingModel={plan.pricing_model}
                                component="invoice"
                              />
                            )}
                          </>
                        );
                      })}
                    {props.payload.addon_details &&
                      props.payload.addon_details.length > 0 && (
                        <Container
                          style={{
                            fontSize: "18px",
                            color: colors.charcoalGrey,
                            paddingLeft: "23px",
                            paddingTop: "19px",
                            marginBottom: "-13px",
                            fontWeight: "bold",
                          }}
                        >
                          {isLoading ? (
                            <Container className="mb-2">
                              <SkeletonLoader
                                {...skeletonLoaderProps}
                                width="150px"
                              />
                            </Container>
                          ) : (
                            "Addons"
                          )}
                        </Container>
                      )}
                    {props.payload.addon_details &&
                      props.payload.addon_details.length > 0 &&
                      props.payload?.addon_details?.map((addon: any) => {
                        return (
                          <>
                            <Container className="plan-description">
                              {isLoading ? (
                                <Container className="mb-2">
                                  <SkeletonLoader
                                    {...skeletonLoaderProps}
                                    width="200px"
                                  />
                                </Container>
                              ) : (
                                <h6 className="item-bold">
                                  {addon.addon_name}
                                </h6>
                              )}
                              <Container className="plan-tag">
                                {isLoading ? (
                                  <Container className="mb-2">
                                    <SkeletonLoader
                                      {...skeletonLoaderProps}
                                      width="200px"
                                    />
                                  </Container>
                                ) : (
                                  <Span>
                                    Frequency:{" "}
                                    {addon.custom_repeat_count &&
                                    addon.custom_repeat_count > 0
                                      ? ` Every ${
                                          addon.custom_repeat_count
                                        }  ${convertToTimeUnit(
                                          addon.frequency
                                        )}`
                                      : coustomCase(addon.frequency)}{" "}
                                    | Pricing model:{" "}
                                    {formatFrequency(addon.pricing_model)}
                                  </Span>
                                )}
                              </Container>
                              <Container>
                                {isLoading ? (
                                  <Container className="mb-2">
                                    <SkeletonLoader
                                      {...skeletonLoaderProps}
                                      width="200px"
                                    />
                                  </Container>
                                ) : (
                                  <Span style={{ color: "#747d84" }}>
                                    Invoicing period:{" "}
                                    {addon.invoicing_period &&
                                      formateDateMonth(
                                        addon.invoicing_period.split(" - ")[0]
                                      )}{" "}
                                    {addon.invoicing_period && "to"}{" "}
                                    {addon.invoicing_period &&
                                      formateDateMonth(
                                        addon.invoicing_period.split(" - ")[1]
                                      )}
                                  </Span>
                                )}
                              </Container>
                              {!!addon.description ? (
                                <Container>
                                  {isLoading ? (
                                    <Container className="mb-2">
                                      <SkeletonLoader
                                        {...skeletonLoaderProps}
                                        width="350px"
                                      />
                                    </Container>
                                  ) : (
                                    <Span style={{ color: "#747d84" }}>
                                      Description: {addon.description}
                                    </Span>
                                  )}
                                </Container>
                              ) : null}
                            </Container>
                            {isLoading ? (
                              <TableSkeletonLoader colCount={5} rowCount={3} />
                            ) : (
                              <DetailsTableContainerSubscription
                                columns={columnsForPlanAddon}
                                data={addon.items}
                                currency_code={props.currencyCode}
                                haveColumnClassName={true}
                                className="table inv-preview-table subscription-data-view-table "
                                hiddenColumns={["TDS"]}
                                pricingModel={addon.pricing_model}
                                component="invoice"
                              />
                            )}
                          </>
                        );
                      })}
                    {props.payload.charge_details &&
                      props.payload.charge_details.length > 0 && (
                        <Container
                          style={{
                            fontSize: "18px",
                            color: colors.charcoalGrey,
                            paddingLeft: "23px",
                            paddingTop: "19px",
                            marginBottom: "-13px",
                            fontWeight: "bold",
                          }}
                        >
                          {isLoading ? (
                            <Container className="mb-2">
                              <SkeletonLoader
                                {...skeletonLoaderProps}
                                width="150px"
                              />
                            </Container>
                          ) : (
                            "Charges"
                          )}
                        </Container>
                      )}
                    {props.payload.charge_details &&
                      props.payload.charge_details.length > 0 &&
                      props.payload?.charge_details?.map((charge: any) => {
                        return (
                          <>
                            <Container className="plan-description">
                              {isLoading ? (
                                <Container className="mb-2">
                                  <SkeletonLoader
                                    {...skeletonLoaderProps}
                                    width="200px"
                                  />
                                </Container>
                              ) : (
                                <h6 className="item-bold">
                                  {charge.charge_name}
                                </h6>
                              )}
                              <Container className="plan-tag">
                                {isLoading ? (
                                  <Container className="mb-2">
                                    <SkeletonLoader
                                      {...skeletonLoaderProps}
                                      width="200px"
                                    />
                                  </Container>
                                ) : (
                                  <Span>
                                    Pricing model:{" "}
                                    {formatFrequency(charge.pricing_model)}
                                  </Span>
                                )}
                              </Container>
                              <Container>
                                {isLoading ? (
                                  <Container className="mb-2">
                                    <SkeletonLoader
                                      {...skeletonLoaderProps}
                                      width="200px"
                                    />
                                  </Container>
                                ) : (
                                  <Span style={{ color: "#747d84" }}>
                                    Invoicing period:{" "}
                                    {charge.invoicing_period &&
                                      formateDateMonth(
                                        charge.invoicing_period.split(" - ")[0]
                                      )}{" "}
                                    {charge.invoicing_period && "to"}{" "}
                                    {charge.invoicing_period &&
                                      formateDateMonth(
                                        charge.invoicing_period.split(" - ")[1]
                                      )}
                                  </Span>
                                )}
                              </Container>
                              {!!charge.description ? (
                                <Container>
                                  {isLoading ? (
                                    <Container className="mb-2">
                                      <SkeletonLoader
                                        {...skeletonLoaderProps}
                                        width="350px"
                                      />
                                    </Container>
                                  ) : (
                                    <Span style={{ color: "#747d84" }}>
                                      Description: {charge.description}
                                    </Span>
                                  )}
                                </Container>
                              ) : null}
                            </Container>
                            {isLoading ? (
                              <TableSkeletonLoader colCount={5} rowCount={3} />
                            ) : (
                              <DetailsTableContainerSubscription
                                columns={columnsForPlanAddon}
                                data={charge.items}
                                currency_code={props.currencyCode}
                                haveColumnClassName={true}
                                className="table inv-preview-table subscription-data-view-table "
                                hiddenColumns={["TDS"]}
                                pricingModel={charge.pricing_model}
                                component="invoice"
                              />
                            )}
                          </>
                        );
                      })}
                    {props.payload.coupons && props.payload.coupons.length > 0 && (
                      <Container
                        style={{
                          fontSize: "18px",
                          color: colors.charcoalGrey,
                          paddingLeft: "23px",
                          paddingTop: "19px",
                          marginBottom: "-13px",
                          fontWeight: "bold",
                        }}
                      >
                        {isLoading ? (
                          <Container className="mb-2">
                            <SkeletonLoader
                              {...skeletonLoaderProps}
                              width="150px"
                            />
                          </Container>
                        ) : (
                          "Coupons"
                        )}
                      </Container>
                    )}
                    {props.payload?.coupons &&
                      props.payload?.coupons.length > 0 &&
                      props.payload?.coupons?.map((coupon: any) => {
                        return (
                          <>
                            <Container className="plan-description coupon-description-container">
                              <Container>
                                <h6 className="item-bold">{coupon.name}</h6>
                                <Container className="plan-tag">
                                  {isLoading ? (
                                    <Container className="mb-2">
                                      <SkeletonLoader
                                        {...skeletonLoaderProps}
                                        width="200px"
                                      />
                                    </Container>
                                  ) : (
                                    <Span>
                                      Applicable{" "}
                                      {convertToNormalText(coupon.duration)}
                                    </Span>
                                  )}
                                </Container>
                              </Container>
                              <Container className="coupon-discount-amount-container">
                                {isLoading ? (
                                  <Container className="mb-2">
                                    <SkeletonLoader
                                      {...skeletonLoaderProps}
                                      width="100px"
                                    />
                                  </Container>
                                ) : (
                                  coupon.discount
                                )}
                              </Container>
                            </Container>
                          </>
                        );
                      })}
                  </Container>
                  <Container className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
                    <Container className="thankyou-note w-50 d-flex align-items-end">
                      {/* {props.invoiceData?.customer_notes && ( */}
                      <Container className="note-container">
                        <></>
                        {/* <Span className="label">Customer Notes</Span> */}
                        {/* <p>{props.invoiceData.customer_notes}</p> */}
                      </Container>
                      {/* )} */}
                    </Container>
                    {isLoading ? (
                      <Container>
                        {Array.from({ length: 4 }).map(() => {
                          return (
                            <Container className="mb-2">
                              <SkeletonLoader
                                width="350px"
                                animation="wave"
                                color={colors.warmWhite}
                                variant="rounded"
                                height="25px"
                              />
                            </Container>
                          );
                        })}
                      </Container>
                    ) : (
                      <DetailTotalSection
                        currencyCode={props.currencyCode}
                        totalTax={props.payload?.total?.tax_amount}
                        paymentLabel="Payment received"
                        payment={props.payload?.total?.payment_made}
                        taxTypes={taxTypes}
                        subTotal={props.payload?.total?.total_taxable_amount}
                        total={props.payload?.total?.total_amount}
                        balanceLabel={"Balance Due"}
                        balanceAmount={
                          props.payload?.total?.balance_due
                            ? props.payload?.total?.balance_due
                            : zeroDisplayFormat(
                                decimalPlaceOfCurrency(
                                  props.currencyCode
                                    ? props.currencyCode
                                    : currentUserInfo.currency_code,
                                  orgCurrencyList
                                )
                              )
                        }
                        tdsItems={{
                          tdsAmount: props.payload?.total
                            ?.transaction_tds_amount
                            ? props.payload?.total?.transaction_tds_amount
                            : 0,
                          tdsName: props.payload?.tds_name,
                          tdsPercentage: props.payload?.tds_percentage,
                          defaultTdsPercentage:
                            props.payload?.default_tds_percentage,
                        }}
                        tcsItems={{
                          tcsAmount: props.payload?.tcs_total,
                          tcsName: props.payload?.tcs_name,
                          tcsPercentage: props.payload?.tcs_percentage,
                        }}
                        roundOffValue={props.payload?.total?.roundoff_amount}
                        roundoffEnabled={props.payload?.roundoff_enabled}
                        couponRequired={true}
                        coupon_amount={props.payload?.total?.coupon_amount}
                        balanceDueRequired={true}
                      >
                        <></>
                      </DetailTotalSection>
                    )}
                  </Container>
                </Container>
              </>
            )}
          </Container>
        }
      />
    </>
  );
};
