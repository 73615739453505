import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import _, { filter } from "lodash";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import { setListFilters } from "../../../../../appSlice";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import { Form } from "../../../../common/components/htmlTags/Form";
import { ScrollToError } from "../../../../common/components";
import { itemDropdown } from "../../../items/itemSlice";
import { SubscriptionUpdateAlertModal } from "./updateSubscriptionAlertModal/SubscriptionUpdateAlertModal";
import { customerDetailsForTransactions } from "../../../customerManagement/customerSlice";
import { NON_PRICE_RANGE_PRICING_MODEL } from "../../../../../helpers/planHelper";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { clearGstTaxList, getGstTaxList } from "../../../gst/gstSlice";
import { tdsPreferenceSelector } from "../../../tds/tdsSlice";
import { fetchRoundOffPreference } from "../../../preferences/preferencesSlice";
import { validate, validateItemValues } from "./ValidateSubscriptionForm";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import {
  DateFormatHandler,
  getFormatedDate,
} from "../../../../../helpers/helper";
import {
  attachSubscriptionFiles,
  deleteSubscriptionFile,
} from "../../subscriptionSlice";
import {
  couponConfiguration,
  getCouponConfiguration,
  setCouponConfiguration,
} from "../../../couponConfiguration/couponConfigSlice";
import {
  currentUserOrgSelector,
  exchangeApiStatusSelector,
  getLatestCurrencyRate,
  latestCurrencyRateSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import {
  sourceTaxTypes,
  tdsApplyLevel,
  gstRegistrationTypes,
} from "../../../../constants/constants";
import {
  addAddress,
  addressReset,
  addressSelector,
} from "../../../customerManagement/components/customerForm/AddressDetails/addressSlice";
import {
  initialTCSTotalValues,
  initialTDSTotalValues,
  initialTotalValues,
} from "../../../invoice/components/invoiceForm/StateInitialization";
import {
  initialSubscriptionValue,
  initialDefaultValues,
  initialTaxValues,
  initialPlanData,
  basicDetailsNextInvoiceValue,
} from "../../SubscriptionStateInitialization";
import {
  checkSubscriptionNumber,
  createSubscription,
  deleteSubscriptionItemTaxDetails,
  editSubscriptionDetails,
  getAmountCalculation,
  getNextInvoiceDate,
  getTaxableAmount,
  subscriptionEditDetails,
} from "../../subscriptionSlice";
import {
  AddressValues,
  CustomerItemValues,
  FilesValues,
  ItemTaxValues,
  ObjectType,
  TCSTotalValues,
  TDSTotalValues,
  TotalAmountCalculationValues,
  TotalDetailsValues,
} from "../../../../../types";
import {
  BasicDetailsNextInvoiceType,
  NextInvoiceDateType,
  SubscriptionAddonDetails,
  SubscriptionChargeDetails,
  SubscriptionCouponList,
  SubscriptionDefaultRef,
  SubscriptionDefaultValues,
  SubscriptionItemRef,
  SubscriptionItemValues,
  SubscriptionPlanDetails,
  SubscriptionValues,
} from "../../subscriptionType";
import useCommonData from "../../../../hooks/useCommon";
import AddressModal from "../../../../common/components/AddressModal";
import DropZone from "../../../../common/components/DropZone/DropZone";
import NoteTextArea from "../../../../common/components/NoteTextArea/NoteTextArea";
import MultiEmailBox from "../../../../common/components/multiEmailComponent/EmailTo";
import SubscriptionPreview from "./SubscriptionPreview";
import useGst from "../../../../hooks/useGst";
import SubscriptionBasicDetails from "./SubscriptionBasicDetails";
import SubscriptionAddress from "./subscriptionAddress/SubscriptionAddress";
import SubscriptionDetail from "./SubscriptionDetail";
import OrgTag from "../../../../common/components/OrgTag";
import SubscriptionOrganizationDetails from "./SubscriptionOrganizationDetails";
import SubscriptionFormFooter from "./SubscriptionFormFooter";
import { AnalyticsOutlined } from "@mui/icons-material";

function SubscriptionForm() {
  let interval: any = null;
  const { editId, customerSubscriptionId } = useParams();
  const location = window.location.pathname;
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { invoicesRolePermission } = usePermissionHandler();
  const defaultRef = useRef<SubscriptionDefaultRef | null>(null);
  const itemRef = useRef<SubscriptionItemRef | null>(null);
  const addressList: any[] = useAppSelector(addressSelector);
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  const tdsPreference = useAppSelector(tdsPreferenceSelector);
  const couponConfig = useAppSelector(couponConfiguration);
  const [isCustomerChanged, setIsCustomerChanged] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);

  const [subscriptionFormValues, setSubscriptionFormValues] =
    useState<SubscriptionValues>(initialSubscriptionValue);
  const [defaultFormValues, setDefaultFormValues] =
    useState<SubscriptionDefaultValues>(initialDefaultValues);

  const [planItemFormValues, setPlanItemFormValues] =
    useState<SubscriptionPlanDetails>(initialPlanData);
  const [addonItemFormValues, setAddonItemFormValues] = useState<
    SubscriptionAddonDetails[]
  >([]);
  const [chargeItemFormValues, setChargeItemFormValues] = useState<
    SubscriptionChargeDetails[]
  >([]);
  const [couponFormValues, setCouponFormValues] = useState<
    SubscriptionCouponList[]
  >([]);
  const [onlyCouponChangeFlag, setOnlyCouponChangeFlag] = useState(false);
  const [totalFormValues, setTotalFormValues] =
    useState<TotalDetailsValues>(initialTotalValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [planItemFormErrors, setPlanItemFormErrors] = useState<
    CustomerItemValues[]
  >([]);
  const [addonItemFormErrors, setAddonItemFormErrors] = useState<
    CustomerItemValues[][]
  >([]);
  const [chargeItemFormErrors, setChargeItemFormErrors] = useState<
    CustomerItemValues[][]
  >([]);
  const [billingAddressFormValues, setBillingAddressFormValues] =
    useState<AddressValues | undefined>();
  const [shippingAddressFormValues, setShippingAddressFormValues] =
    useState<AddressValues | undefined>();
  const [billingAddressFormValuesForEdit, setBillingAddressFormValuesForEdit] =
    useState<AddressValues | undefined>();
  const [
    shippingAddressFormValuesForEdit,
    setShippingAddressFormValuesForEdit,
  ] = useState<AddressValues | undefined>();
  const [isCustomerName, setIsCustomerName] = useState<boolean>(false);
  const [customerData, setCustomerData] =
    useState<AddressValues[] | undefined>();
  const [emailLists, setEmailLists] = useState<string[] | []>([]);
  const [customerId, setCustomerId] = useState<number>();
  const [currencyId, setCurrencyId] = useState<number | undefined>();
  const [currencyCode, setCurrencyCode] = useState("");
  const [emailData, setEmailData] = useState<{ email: string; id: number }[]>(
    []
  );
  const [changedCustomerData, setChangedCustomerData] = useState<{
    customerId: number;
    currencyId: number;
  }>({ customerId: 0, currencyId: 0 });
  const [deleteEmailIds, setDeleteEmailIds] = useState<{ emailId: number }[]>(
    []
  );
  const [planDeleteId, setPlanDeleteId] = useState<number>(0);
  const [addonDeleteIds, setAddonDeleteIds] = useState<number[]>([]);
  const [chargeDeleteIds, setChargeDeleteIds] = useState<number[]>([]);
  const [couponDeleteIds, setCouponDeleteIds] = useState<number[]>([]);
  const [customerIdForEdit, setCustomerIdForEdit] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [formSubmitAction, setFormSubmitAction] = useState(true);
  const [formSubmit, setFormSubmit] = useState(true);
  const [fileIds, setFileIds] = useState<any>([]);
  const [insertedFiles, setInsertedFiles] = useState<FilesValues[]>([]);
  const fileRef = useRef<any>([]);
  const [conversionDate, setConversionDate] = useState<string>(
    getFormatedDate(new Date())
  );
  const [newConversionDate, setNewConversionDate] = useState<string>("");
  const [subscriptionValuesForEdit, setSubscriptionValuesForEdit] =
    useState<SubscriptionValues>(initialSubscriptionValue);
  const [conversionCurrencyId, setConversionCurrencyId] = useState<number>();
  const [selectedBillingAddressId, setSelectedBillingAddressId] =
    useState<number | string>(0);
  const [selectedShippingAddressId, setSelectedShippingAddressId] =
    useState<number | string>(0);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressId, setAddressId] = useState<number | string>("");
  const [customerBillingAddressId, setCustomerBillingAddressId] = useState(0);
  const [customerShippingAddressId, setCustomerShippingAddressId] = useState(0);
  const [isBillingAddressSame, setIsBillingAddressSame] = useState(false);
  const [isShippingAddressSame, setIsShippingAddressSame] = useState(false);
  const [isSubscriptionChecking, setIsSubscriptionChecking] =
    useState<boolean>(false);
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );
  const [totalTcsValues, setTotalTcsValues] = useState<TCSTotalValues>(
    initialTCSTotalValues
  );
  const [tdsIds, setTdsIds] = useState<number[]>([]);
  const [tcsIds, setTcsIds] = useState<number[]>([]);
  const [planTdsIds, setPlanTdsIds] = useState<number[]>([]);
  const [addonTdsIds, setAddonTdsIds] = useState<number[][]>([]);
  const [chargeTdsIds, setChargeTdsIds] = useState<number[][]>([]);
  const [isRoundOffEnabled, setIsRoundOffEnabled] = useState(false);
  const [subscriptionNumber, setSubscriptionNumber] = useState("");
  const [stateType, setStateType] = useState("");
  const [initialStateType, setInitialStateType] = useState("");
  const [supplyState, setSupplyState] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  useState<number>();
  const [planTransactionTaxDetails, setPlanTransactionTaxDetails] =
    useState<[{ is_group: boolean; tax_id: number }]>();
  const [addonTransactionTaxDetails, setAddonTransactionTaxDetails] =
    useState<[{ is_group: boolean; tax_id: number }]>();
  const { isGstOrg } = useGst();
  const [editTotal, setEditTotal] = useState<number | null>(null);
  const [calculatedTotal, setCalculatedTotal] = useState(0);
  const [gstRegistrationType, setGstRegistrationType] = useState("");
  const [isSendEmailToggleEnable, setIsSendEmailToggleEnable] =
    useState<boolean>(true);
  const [isTotalEditable, setIsTotalEditable] = useState(false);
  const userOrganization = useAppSelector(currentUserOrgSelector);
  const [selectedInvoicingCycle, setSelectedInvoicingCycle] = useState<any>({
    invoicingCycle: "",
    invoiceCycleCount: 0,
  });
  const [isSubscriptionNumberExist, setIsSubscriptionNumberExist] =
    useState(false);
  const [nextInvoiceDateDetails, setNextInvoiceDateDetails] =
    useState<NextInvoiceDateType | null>(null);
  const [buttonAction, setButtonAction] = useState<{
    status: string;
    action: string;
  }>({ status: "", action: "" });
  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);
  const [nextRenewableDate, setNextRenewableDate] = useState<string>("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>("");
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>("");
  const [subscriptionStopDate, setSubscriptionStopDate] = useState<string>("");
  const [customerDisplayName, setCustomerDisplayName] = useState<string>("");
  const [subscriptionCancelDate, setSubscriptionCancelDate] =
    useState<string>("");
  const [subNumber, setSubNumber] = useState("");
  const [formDetailForNexInvoice, setFormDetailForNexInvoice] = useState({
    planId: 0,
    pricing_id: 0,
    total: 0,
  });
  const [nextInvoiceEdit, setNextInvoiceEdit] =
    useState<NextInvoiceDateType | null>(null);
  const [savedTDSPreference, setSavedTDSPreference] = useState<string>("");
  const [basicDetailsNextInvoice, setBasicDetailsNextInvoice] =
    useState<BasicDetailsNextInvoiceType>(basicDetailsNextInvoiceValue);
  const [existingPlanItemIds, setExistingPlanItemIds] = useState<{
    id: any;
    itemIds: any;
  }>({ id: 0, itemIds: [] });
  const [existingAddonItemIds, setExistingAddonItemIds] = useState<
    { id: any; itemIds: any }[]
  >([]);
  const [existingChargeItemIds, setExistingChargeItemIds] = useState<
    { id: any; itemIds: any }[]
  >([]);
  const [editTotalDetails, setEditTotalDetails] = useState<{
    total_taxable_amount: number;
    called: string;
  }>({ total_taxable_amount: 0, called: "" });
  const [updatedFormData, setUpdatedFormData] = useState<any>({});
  const customFieldRef = useRef<any>();
  const [chargedItems, setChargedItems] = useState<any[]>([]);
  const [invoicePreviewPayload, setInvoicePreviewPayload] = useState<any>({});
  const [nextInvoicePayload, setNextInvoicePayload] = useState<any>({});
  const [difference, setDifference] = useState(true);

  interface Differences {
    removed: any[];
    added: any[];
    updated: any[];
  }

  interface DifferenceObject {
    added: Array<{
      frequency: string;
      itemDetails: ItemDetails[];
      planId: string;
    }>;
    removed: any[];
    updated: Array<{
      frequency: string;
      itemDetails: ItemDetails[];
      planId: string;
    }>;
  }

  interface ItemDetails {
    id: number;
    account_id: number;
    account_name: string;
    item_name: string;
    hsn_or_sac_code: number;
  }
  /**
   * To check whether subscription address is same as customer address
   */
  useEffect(() => {
    if (editId) {
      let updatedAddress = addressList?.map((address: any) => {
        return {
          address: address.address,
          city: address.city,
          country_id: address.country_id,
          is_billing: address.is_billing,
          is_shipping: address.is_shipping,
          phone: address.phone,
          state_id: address.state_id,
          zip_code: address.zip_code,
        };
      });
      let billingAddress = { ...billingAddressFormValues };
      let shippingAddress = { ...shippingAddressFormValues };
      delete billingAddress.country_name;
      delete billingAddress.customer_address_id;
      delete billingAddress.id;
      delete billingAddress.state_name;
      delete shippingAddress.country_name;
      delete shippingAddress.customer_address_id;
      delete shippingAddress.id;
      delete shippingAddress.state_name;
      setIsBillingAddressSame(false);
      setIsShippingAddressSame(false);
      for (let address of updatedAddress) {
        if (address.is_billing) {
          setSelectedBillingAddressId(customerBillingAddressId);
          setIsBillingAddressSame(true);
        }
        if (address.is_shipping) {
          setSelectedShippingAddressId(customerShippingAddressId);
          setIsShippingAddressSame(true);
        }
      }
    }
  }, [addressList, customerBillingAddressId, customerShippingAddressId]);

  useEffect(() => {
    dispatch(setLoaderState(true));
    if (!editId) {
      setIsCustomerName(false);
      getRoundOffPreference();
      getCouponConfig();
    }
    if (editId) {
      subscriptionDetailsResponse();
    }
    dispatch(addressReset());
  }, [currentUserInfo.organization_id]);

  const getRoundOffPreference = async () => {
    const response = await dispatch(
      fetchRoundOffPreference({
        orgId: currentUserInfo.organization_id,
      })
    );
    let payload = response.payload;
    setIsRoundOffEnabled(payload.is_enabled);
    setIsTotalEditable(payload.is_total_editable);
  };

  const getCouponConfig = async () => {
    const response = await dispatch(
      getCouponConfiguration({
        orgId: currentUserInfo.organization_id,
      })
    );
  };

  const openAddressModal = () => {
    setShowAddressModal(true);
  };
  const closeAddressModal = () => {
    setShowAddressModal(false);
  };

  /**
   * Set subscription default form values
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSubscriptionFormValues({ ...subscriptionFormValues, [name]: value });
  };

  const handleNewConversionDate = (value: string) => {
    setNewConversionDate(value);
  };

  const asynchronousSubmit = (status: string, action: string) => {
    setButtonAction({ status: status, action: action });
    handleSubmit(status, action);
  };

  const handleCustomerChange = async (e: any) => {
    if (e.value && e.value !== customerIdForEdit) {
      setIsCustomerChanged(true);
      let response = await getCustomerDetails(Number(e.value), "customer");
      let state_type = "";
      if (
        response.gst_registration_type === gstRegistrationTypes.sez ||
        response.gst_registration_type === gstRegistrationTypes.sezDeveloper ||
        response.gst_registration_type === gstRegistrationTypes.overseas ||
        response.other_details.tax_preference === "NON_TAXABLE"
      ) {
        state_type = "Inter State";
      } else {
        state_type = stateType;
      }

      if (state_type !== "") {
        await fetchGstTaxList(
          state_type,
          response.gst_registration_type,
          planTransactionTaxDetails
        );
      } else {
        dispatch(clearGstTaxList());
      }
      setGstRegistrationType(response.gst_registration_type);
      setIsCustomerName(true);
      setCustomerId(Number(e.value));
      setBillingAddressFormValues(undefined);
      setShippingAddressFormValues(undefined);
    } else {
      getCustomerDetails(Number(e.value), "subscription");
      setSubscriptionFormValues(subscriptionValuesForEdit);
      setBillingAddressFormValues(billingAddressFormValuesForEdit);
      setShippingAddressFormValues(shippingAddressFormValuesForEdit);
      setCustomerId(customerIdForEdit);

      let emailContacts = subscriptionValuesForEdit.subscription_emails;
      let contactEmail: string[] = [];
      let emailDetails: any[] = [];
      let removeEmails: { emailId: number }[] = deleteEmailIds;
      emailContacts?.map((contact: any) => {
        contactEmail.push(contact.email);
        emailDetails.push(contact);
        removeEmails = removeEmails?.filter(
          (email) => email.emailId !== contact.id
        );
      });
      setEmailLists(contactEmail);
      setEmailData(emailDetails);
      setDeleteEmailIds(removeEmails);
      setCurrencyId(Number(subscriptionValuesForEdit.currency_id)); // need to check
    }
    setSelectedBillingAddressId(0);
    setSelectedShippingAddressId(0);
  };

  useEffect(() => {
    (async () => {
      try {
        if (customerSubscriptionId) {
          const subscriptionCustomerDetails: any = await getCustomerDetails(
            Number(customerSubscriptionId),
            "subscription"
          );
          if (subscriptionCustomerDetails) {
            dispatch(
              getLatestCurrencyRate({
                base_currency: Number(
                  subscriptionCustomerDetails?.other_details?.currency_id
                ),
                to_currency: Number(currentUserInfo.organization_currency),
                date: getFormatedDate(new Date()),
              })
            );

            let addressDetails = {} as any;
            subscriptionCustomerDetails.address_details.map(
              (values: AddressValues) => {
                if (values.is_billing) {
                  addressDetails.selected_billing_address_id =
                    values.customer_address_id || values.id;
                  addressDetails.selected_billing_address = values;
                  sessionStorage.setItem(
                    "selected_billing_address",
                    String(values.id)
                  );
                }
                if (values.is_shipping) {
                  addressDetails.selected_shipping_address_id =
                    values.customer_address_id || values.id;
                  addressDetails.selected_shipping_address = values;
                  sessionStorage.setItem(
                    "selected_shipping_address",
                    String(values.id)
                  );
                }
              }
            );
            setBillingAddressFormValues(
              addressDetails.selected_billing_address
            );
            setShippingAddressFormValues(
              addressDetails.selected_shipping_address
            );
            const address = {
              billing_address: addressDetails.billing_address,
              shipping_address: addressDetails.shipping_address,
              selected_billing_address_id:
                addressDetails.selected_billing_address_id,
              selected_shipping_address_id:
                addressDetails.selected_shipping_address_id,
              selected_billing_address: addressDetails.selected_billing_address,
              selected_shipping_address:
                addressDetails.selected_shipping_address,
            };
            const value = {
              customer_id: subscriptionCustomerDetails.id,
              customer_name: subscriptionCustomerDetails.customer_display_name,
              currency_id:
                subscriptionCustomerDetails?.other_details?.currency_id,
              address: address,
            };
            setIsCustomerName(true);
            setCurrencyId(
              subscriptionCustomerDetails?.other_details?.currency_id || 0
            );
            dispatch(addAddress(address));
            setDefaultFormValues((prevValues: any) => {
              return {
                ...prevValues,
                ...value,
              };
            });
          }
        }
      } catch (err) {
        console.log("Error occurred when fetching customer details");
      }
    })();
  }, []);

  useEffect(() => {
    customerId && getCustomerDetails(customerId, "subscription");
  }, [customerId]);

  /**
   * Store customer email contacts to delete if customer name is changed on Edit subscription
   */
  useEffect(() => {
    if (editId) {
      emailData?.map((contact) => {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emailId: contact.id },
        ]);
      });
    }
  }, [isCustomerName]);

  const setErrorOnItemTaxChange = (errors: CustomerItemValues[]) => {
    setPlanItemFormErrors([...errors]);
  };

  const setAddonErrorOnItemTaxChange = (errors: CustomerItemValues[][]) => {
    setAddonItemFormErrors([...errors]);
  };

  const setChargeErrorOnItemTaxChange = (errors: CustomerItemValues[][]) => {
    setChargeItemFormErrors([...errors]);
  };
  /**
   * Fetch customer details by id
   */
  const getCustomerDetails = async (customerId: number, type: string) => {
    const responseAction = await dispatch(
      customerDetailsForTransactions({
        customerId: customerId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        dispatch(addressReset());
        setChangedCustomerData({
          customerId: customerId,
          currencyId: response.other_details.currency_id,
        });
        setCustomerDisplayName(response.customer_display_name);
        let addressData = response.address_details;
        addressData?.forEach(async (addressDetails: any) => {
          let address = {
            country_id: addressDetails.country_id
              ? addressDetails.country_id
              : 0,
            customer_name: addressDetails.customer_name,
            address: addressDetails.address,
            city: addressDetails.city,
            state_id: addressDetails.state_id ? addressDetails.state_id : 0,
            zip_code: addressDetails.zip_code,
            phone: addressDetails.phone ? addressDetails.phone : null,
            is_billing: addressDetails.is_billing,
            is_shipping: addressDetails.is_shipping,
            is_primary: addressDetails.is_primary,
            id: addressDetails.id,
            state_name: addressDetails.state_name,
            country_name: addressDetails.country_name,
          };
          dispatch(addAddress(address));
        });
        if (type === "subscription") {
          setCustomerData(response.address_details);
          let defaultFormValues = {
            customer_exemption_type: response.other_details.tax_preference,
            customer_exemption_id: response.other_details.exemption_reason_id,
            discount: response.sales_default.discount,
            discount_type: response.sales_default.discount_type?.toUpperCase(),
            exclude_discount_accounting: response.sales_default
              .exclude_discount_accounting
              ? response.sales_default.exclude_discount_accounting
              : false,
          };
          if (defaultRef.current?.defaultData) {
            setDefaultFormValues((prevData) => {
              return {
                ...prevData,
                ...defaultRef.current?.defaultData,
                ...defaultFormValues,
              };
            });
          }
        } else {
          setCustomerData(response.address_details);
          if (defaultRef.current?.defaultData) {
            const exemptionType =
              response.other_details.tax_preference === "Non-Taxable"
                ? "NON_TAXABLE"
                : response.other_details.tax_preference === "Taxable"
                ? "TAXABLE"
                : "";
            let defaultFormValues = {
              ...defaultRef.current?.defaultData,
              tag_ids: response.other_details.tags?.map((tag: any) => tag.id),
              payment_term_id: response.other_details.payment_term_id,
              currency_id: response.other_details.currency_id,
              customer_exemption_type: response.other_details.tax_preference,
              exclude_discount_accounting: response.sales_default
                .exclude_discount_accounting
                ? response.sales_default.exclude_discount_accounting
                : false,
              customer_exemption_id: response.other_details.exemption_reason_id,
            };
            setDefaultFormValues({ ...defaultFormValues });
          }
          let contacts = response.contact_persons;
          let contactEmail: string[] = [];
          contacts?.map((contact: any) => {
            if (contact.email) contactEmail.push(contact.email);
          });
          setEmailLists(contactEmail);
          setCurrencyId(response.other_details.currency_id);
        }
      }
      return responseAction.payload;
    }
  };

  const getTdsApplyLevel = () => {
    const itemDetails = itemRef.current?.itemPlanValues.itemDetails
      ?.filter((item: any) => item.tds_id)
      ?.map((item: any) => item);

    let addonItemDetails: any[] = [];
    itemRef.current?.itemAddonValues?.forEach((eachAddon: any) => {
      const eachAddonItem = eachAddon.itemDetails
        ?.filter((item: any) => item.tds_id)
        ?.map((item: any) => item);
      if (eachAddonItem.length > 0) addonItemDetails.push(eachAddonItem);
    });

    let chargeItemDetails: any[] = [];
    itemRef.current?.itemChargeValues?.forEach((eachCharge: any) => {
      const eachChargeItem = eachCharge.itemDetails
        ?.filter((item: any) => item.tds_id)
        ?.map((item: any) => item);
      if (eachChargeItem.length > 0) chargeItemDetails.push(eachChargeItem);
    });

    if (itemRef.current?.totalTcsValues.tcs_id) {
      return tdsApplyLevel.NO_TDS;
    }
    if (itemRef?.current?.totalTdsValues.tds_id) {
      return tdsApplyLevel.TRANSACTION_LEVEL;
    }
    if (
      itemDetails?.length ||
      addonItemDetails?.length ||
      chargeItemDetails?.length
    ) {
      return tdsApplyLevel.LINE_ITEM_LEVEL;
    }
    if (
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Customers")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        return itemRef.current?.totalTdsValues.tds_id
          ? tdsApplyLevel.TRANSACTION_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        return itemDetails?.length ||
          addonItemDetails?.length ||
          chargeItemDetails?.length
          ? tdsApplyLevel.LINE_ITEM_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
    }
    return tdsApplyLevel.NO_TDS;
  };

  const getSourceTaxType = () => {
    const planItemDetails = itemRef.current?.itemPlanValues.itemDetails
      ?.filter((item: any) => item.tds_id)
      ?.map((item: any) => item);

    let addonItemDetails = [];
    itemRef.current?.itemAddonValues?.forEach((eachAddon: any) => {
      const eachAddonItem = eachAddon.itemDetails
        ?.filter((item: any) => item.tds_id)
        ?.map((item: any) => item);
      if (eachAddonItem.length > 0) addonItemDetails.push(eachAddonItem);
    });

    let chargeItemDetails = [];
    itemRef.current?.itemChargeValues?.forEach((eachCharge: any) => {
      const eachChargeItem = eachCharge.itemDetails
        ?.filter((item: any) => item.tds_id)
        ?.map((item: any) => item);
      if (eachChargeItem.length > 0) chargeItemDetails.push(eachChargeItem);
    });

    if (itemRef.current?.totalTcsValues.tcs_id) {
      return sourceTaxTypes.TCS;
    }
    if (
      itemRef.current?.totalTdsValues.tds_id ||
      planItemDetails?.length ||
      addonItemDetails?.length ||
      chargeItemDetails?.length
    ) {
      return sourceTaxTypes.TDS;
    }
    return sourceTaxTypes.NO_SOURCE_TAX;
  };

  function deepEqualWithNormalization(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 === "object" && typeof obj2 === "object" && obj1 && obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (!keys2.includes(key)) {
          return false;
        }

        const value1 = obj1[key];
        const value2 = obj2[key];

        const normalizedValue1 =
          typeof value1 === "string" && !isNaN(parseFloat(value1))
            ? parseFloat(value1)
            : value1;
        const normalizedValue2 =
          typeof value2 === "string" && !isNaN(parseFloat(value2))
            ? parseFloat(value2)
            : value2;

        if (!deepEqualWithNormalization(normalizedValue1, normalizedValue2)) {
          return false;
        }
      }

      return true;
    }
    return false;
  }

  const comparePlans = (a: any[], b: any[]) => {
    const mapA = new Map(a.map((plan) => [plan.id, plan]));
    const mapB = new Map(b.map((plan) => [plan.id, plan]));

    let matchedPlans: any[] = [];
    let matchedItems: { planId: number; matchedItems: any[] }[] = [];
    let itemDetailDifferences: { planId: number; differentItems: any[] }[] = [];

    // Find matched plans (same id)
    for (const planA of a) {
      if (mapB.has(planA.id)) {
        matchedPlans.push(planA);
      }
    }

    // Find matched items and check for differences
    for (const plan of matchedPlans) {
      const planB = mapB.get(plan.id)!;
      const matchedItemList: any[] = [];
      const differentItemList: any[] = [];

      const itemMapA = new Map(
        plan.itemDetails.map((item: { id: any }) => [item.id, item])
      );
      const itemMapB = new Map(
        planB.itemDetails.map((item: { id: any }) => [item.id, item])
      );

      for (const itemA of plan.itemDetails) {
        if (itemMapB.has(itemA.id)) {
          const itemB = itemMapB.get(itemA.id)!;
          if (areItemsEqual(itemA, itemB)) {
            matchedItemList.push(itemA);
          } else {
            differentItemList.push(itemB);
          }
        }
      }

      if (matchedItemList.length) {
        matchedItems.push({ planId: plan.id, matchedItems: matchedItemList });
      }
      if (differentItemList.length) {
        itemDetailDifferences.push({
          planId: plan.id,
          differentItems: differentItemList,
        });
      }
    }

    return {
      itemDetailDifferences,
    };
  };

  // Helper function to compare two objects, treating null and "" as equal
  const areItemsEqual = (a: any, b: any): boolean => {
    const keys = new Set([...Object.keys(a), ...Object.keys(b)]);

    for (const key of Array.from(keys)) {
      const valA = a[key] === null ? "" : a[key];
      const valB = b[key] === null ? "" : b[key];
      if (valA !== valB) return false;
    }
    return true;
  };

  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = async (
    status: string,
    action: string,
    updatedData?: any
  ) => {
    dispatch(setListFilters({}));
    await customFieldRef.current.handleSubmit();
    let defaultValues = defaultRef.current?.defaultData;

    if (
      subscriptionStatus === "NON_RENEWING" &&
      defaultValues &&
      !defaultValues.invoicing_cycle_fixed_count
    ) {
      defaultValues.invoicing_cycle_fixed_count = 0;
    }

    let planItemValues: SubscriptionPlanDetails = itemRef.current
      ?.itemPlanValues
      ? itemRef.current?.itemPlanValues
      : planItemFormValues;
    let addonItemValues: SubscriptionAddonDetails[] = itemRef.current
      ?.itemAddonValues
      ? itemRef.current?.itemAddonValues
      : addonItemFormValues;
    let chargeItemValues: SubscriptionChargeDetails[] = itemRef.current
      ?.itemChargeValues
      ? itemRef.current?.itemChargeValues
      : chargeItemFormValues;
    let couponDetails: SubscriptionCouponList[] = itemRef.current
      ?.couponFormValues
      ? itemRef.current?.couponFormValues
      : couponFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let subscriptionInputs: any = {
      ...subscriptionFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
      tds_level: getTdsApplyLevel(),
      source_tax_type: getSourceTaxType(),
    };

    const comparePlan = comparePlans([planItemFormValues], [planItemValues]);

    const planDifferences = comparePlan
      ? comparePlan?.itemDetailDifferences[0]?.differentItems
      : [];

    const compareAddons = comparePlans(addonItemFormValues, addonItemValues);

    const addonDifferences = compareAddons
      ? compareAddons?.itemDetailDifferences[0]?.differentItems
      : [];

    const compareCharges = comparePlans(chargeItemFormValues, chargeItemValues);

    const chargeDifferences = compareCharges
      ? compareCharges?.itemDetailDifferences[0]?.differentItems
      : [];

    const mergedItems = Array.from(
      new Set(
        [
          ...(planDifferences?.length ? planDifferences : []),
          ...(addonDifferences?.length ? addonDifferences : []),
          ...(chargeDifferences?.length ? chargeDifferences : []),
        ].map((item) => JSON.stringify(item))
      )
    )?.map((item) => JSON.parse(item));

    const mergedItemsWithDiscount = mergedItems.map((item: any) => {
      return { ...item, discount: item.discount === "" ? 0 : item.discount };
    });

    setChargedItems([...mergedItemsWithDiscount]);

    let errors,
      planItemErrorResponse,
      addonItemErrorResponse: any = [];
    let chargeItemErrorResponse: any = [];
    let palnItemErrors: any = [];
    let addonItemErros: any = [];
    let chargeItemErros: any = [];

    errors = validate(
      subscriptionInputs,
      isSubscriptionNumberExist,
      emailLists,
      planItemValues,
      isGstOrg,
      editId,
      customFieldRef.current.errors,
      subscriptionStatus
    );

    if (!customFieldRef.current.errors) {
      delete errors.custom_fields;
    }

    if (errors) {
      ScrollToError(Object.keys(errors)[0]);
    }
    let errorExist = false;

    let taxValues = itemRef.current?.taxPlanValues.itemDetails
      ? itemRef.current?.taxPlanValues.itemDetails
      : [];
    planItemValues.itemDetails?.map((item: any, itemIndex: any) => {
      planItemErrorResponse = validateItemValues(
        subscriptionInputs,
        item,
        itemIndex,
        taxValues,
        planItemValues.pricing_model
      );
      if (planItemErrorResponse) {
        if (planItemErrorResponse) {
          ScrollToError(Object.keys(planItemErrorResponse)[0]);
        }
        errorExist = true;
        palnItemErrors.push(planItemErrorResponse);
      } else {
        palnItemErrors.push({});
      }
    });

    addonItemValues?.map((eachAddon, index) => {
      let addonTaxValues = itemRef.current?.taxAddonValues[index].itemDetails
        ? itemRef.current?.taxAddonValues[index].itemDetails
        : [];
      let addonEachItemErrors: any = [];
      eachAddon.itemDetails?.map((item, itemIndex) => {
        addonItemErrorResponse = validateItemValues(
          subscriptionInputs,
          item,
          itemIndex,
          addonTaxValues,
          eachAddon.pricing_model
        );

        if (addonItemErrorResponse) {
          addonEachItemErrors.push(addonItemErrorResponse);
          errorExist = true;
          ScrollToError(Object.keys(addonEachItemErrors)[0]);
        } else {
          addonEachItemErrors.push({});
        }
      });
      addonItemErros.push(addonEachItemErrors);
    });

    chargeItemValues?.map((eachCharge, index) => {
      let chargeTaxValues = itemRef.current?.taxChargeValues[index].itemDetails
        ? itemRef.current?.taxChargeValues[index].itemDetails
        : [];
      let chargeEachItemErrors: any = [];
      eachCharge.itemDetails?.map((item, itemIndex) => {
        chargeItemErrorResponse = validateItemValues(
          subscriptionInputs,
          item,
          itemIndex,
          chargeTaxValues,
          eachCharge.pricing_model
        );

        if (chargeItemErrorResponse) {
          chargeEachItemErrors.push(chargeItemErrorResponse);
          errorExist = true;
          ScrollToError(Object.keys(chargeEachItemErrors)[0]);
        } else {
          chargeEachItemErrors.push({});
        }
      });
      chargeItemErros.push(chargeEachItemErrors);
    });

    if (
      Object.keys(errors).length ||
      (errorExist && palnItemErrors.length) ||
      (errorExist && addonItemErros.length)
    ) {
      setFormErrors(errors);
      setPlanItemFormErrors(palnItemErrors);
      setAddonItemFormErrors(addonItemErros);
      setChargeItemFormErrors(chargeItemErros);
    } else {
      setFormErrors({});
      setPlanItemFormErrors([]);
      setAddonItemFormErrors([]);
      setChargeItemFormErrors([]);
      if (editId) {
        let onlyCouponChange = false;
        if (
          planItemValues.itemDetails[0]?.item_name ===
            planItemFormValues.itemDetails[0]?.item_name &&
          checkAddonNames(addonItemValues, addonItemFormValues) === true &&
          checkChargeNames(chargeItemValues, chargeItemFormValues) === true &&
          checkCouponNames(couponDetails, couponFormValues) === false
        ) {
          onlyCouponChange = true;
        }

        setOnlyCouponChangeFlag(onlyCouponChange);

        if (editId && !didAlertModalOpen) {
          getNextInvoiceAction(
            getFormatedDate(new Date()),
            planItemValues.planId,
            planItemValues.pricing_id,
            calculatedTotal,
            basicDetailsNextInvoice.invoicingoption,
            basicDetailsNextInvoice.is_proration_enabled,
            Number(editId),
            false,
            // nextInvoiceEdit?.invoice_generation
            defaultFormValues.invoice_generation,
            onlyCouponChange,
            mergedItemsWithDiscount,
            exchangeRateValues.timestamp.split(" ")[0]
          );
          setFormDetailForNexInvoice({
            planId: planItemValues.planId,
            pricing_id: planItemValues.pricing_id,
            total: calculatedTotal,
          });
          setDidAlertModalOpen(true);
          return;
        } else if (
          ["SPECIFIC_DATE", "END_OF_CURRENT_TERM"].includes(
            updatedData.update_type
          )
        ) {
          new Date(updatedData.updation_date).toDateString() ===
          new Date().toDateString()
            ? UpdateSubscriptionSubmit(
                subscriptionInputs,
                planItemValues,
                addonItemValues,
                chargeItemValues,
                couponDetails,
                updatedData
              )
            : createSubscriptionSubmit(
                subscriptionInputs,
                planItemValues,
                addonItemValues,
                chargeItemValues,
                couponDetails,
                action,
                updatedData
              );
        } else
          UpdateSubscriptionSubmit(
            subscriptionInputs,
            planItemValues,
            addonItemValues,
            chargeItemValues,
            couponDetails,
            updatedData
          );
      } else
        createSubscriptionSubmit(
          subscriptionInputs,
          planItemValues,
          addonItemValues,
          chargeItemValues,
          couponDetails,
          action
        );
    }
  };

  function checkAddonNames(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].addonName !== arr2[i].addonName) {
        return false;
      }
    }

    return true;
  }

  function checkChargeNames(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].chargeName !== arr2[i].chargeName) {
        return false;
      }
    }

    return true;
  }

  function checkCouponNames(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].name !== arr2[i].name) {
        return false;
      }
    }

    return true;
  }
  /**
   * Create new subscription
   */
  const createSubscriptionSubmit = async (
    subscriptionInputs: any,
    planItemValues: SubscriptionPlanDetails,
    addonItemValues: SubscriptionAddonDetails[],
    chargeItemValues: SubscriptionChargeDetails[],
    couponDetails: SubscriptionCouponList[],
    action: string,
    updatedData?: any
  ) => {
    dispatch(setLoaderState(true));
    subscriptionInputs.conversion_id = exchangeRateValues.id;
    subscriptionInputs.organization_id = currentUserInfo.organization_id;
    subscriptionInputs.is_email_reminder_automatic = isSendEmailToggleEnable;
    let key: keyof typeof subscriptionInputs;
    for (key in subscriptionInputs) {
      if (subscriptionInputs[key] === "") {
        subscriptionInputs[key] = null;
      }
      if (
        key === "subscription_number" &&
        subscriptionInputs[key] !== "" &&
        subscriptionInputs[key] !== null
      ) {
        subscriptionInputs[key] = defaultRef.current?.subscriptionNumber;
      }
      if (key === "tds_percentage") {
        subscriptionInputs[key] =
          subscriptionInputs[key] === "" || subscriptionInputs[key] === null
            ? 0
            : Number(subscriptionInputs[key]);
      }
      if (isGstOrg && key === "place_of_supply") {
        subscriptionInputs[key] = subscriptionInputs[key];
      }
    }

    let subscriptionPlanItems = JSON.parse(
      JSON.stringify(planItemValues.itemDetails)
    );

    for (let items of subscriptionPlanItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax?.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }

    let subscriptionAddon = [];
    for (let eachAddons of addonItemValues) {
      let subscriptionAddonItems = JSON.parse(
        JSON.stringify(eachAddons.itemDetails)
      );

      for (let items of subscriptionAddonItems) {
        let itemInputs = items;
        if (isGstOrg) {
          // updating gst_id in request payload
          itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
            ? itemInputs?.gst_tax?.tax_id
            : null;
        } else {
          delete itemInputs.gst_id;
          delete itemInputs.gst_tax;
        }

        let itemkey: keyof typeof itemInputs;
        for (itemkey in itemInputs) {
          if (itemInputs[itemkey] === "") {
            if (itemkey === "rate" || itemkey === "quantity") {
              itemInputs[itemkey] = 0;
            } else {
              itemInputs[itemkey] = null;
            }
          }
          if (itemkey === "discount") {
            if (itemInputs[itemkey]?.discount_value == 0) {
              itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
            } else if (itemInputs[itemkey]?.discount_percentage == 0) {
              itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
            } else {
              itemInputs[itemkey] = Number(itemInputs[itemkey]);
            }
          }
        }

        let itemTax = itemInputs.taxes;
        let taxData: ItemTaxValues[] = [];
        itemTax?.map((item: any) => {
          if (item.tax_percentage === "") {
            item.tax_percentage = 0;
          }
          if (item.tax_id !== "" || item.tax_percentage !== 0) {
            taxData.push(item);
          }
        });
        items.taxes = taxData;
      }

      subscriptionAddon.push({
        addon_id: eachAddons.addonId,
        addon_pricing_id: eachAddons.pricing_id,
        items: subscriptionAddonItems,
      });
    }

    let subscriptionCharge = [];
    for (let eachCharges of chargeItemValues) {
      let subscriptionChargeItems = JSON.parse(
        JSON.stringify(eachCharges.itemDetails)
      );

      for (let items of subscriptionChargeItems) {
        let itemInputs = items;
        if (isGstOrg) {
          // updating gst_id in request payload
          itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
            ? itemInputs?.gst_tax?.tax_id
            : null;
        } else {
          delete itemInputs.gst_id;
          delete itemInputs.gst_tax;
        }

        let itemkey: keyof typeof itemInputs;
        for (itemkey in itemInputs) {
          if (itemInputs[itemkey] === "") {
            if (itemkey === "rate" || itemkey === "quantity") {
              itemInputs[itemkey] = 0;
            } else {
              itemInputs[itemkey] = null;
            }
          }
          if (itemkey === "discount") {
            if (itemInputs[itemkey]?.discount_value == 0) {
              itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
            } else if (itemInputs[itemkey]?.discount_percentage == 0) {
              itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
            } else {
              itemInputs[itemkey] = Number(itemInputs[itemkey]);
            }
          }
        }

        let itemTax = itemInputs.taxes;
        let taxData: ItemTaxValues[] = [];
        itemTax?.map((item: any) => {
          if (item.tax_percentage === "") {
            item.tax_percentage = 0;
          }
          if (item.tax_id !== "" || item.tax_percentage !== 0) {
            taxData.push(item);
          }
        });
        items.taxes = taxData;
      }

      subscriptionCharge.push({
        charge_id: eachCharges.chargeId,
        charge_pricing_id: eachCharges.pricing_id,
        customer_charge_type: eachCharges.customerChargeType,
        service_days: eachCharges.no_of_days,
        applyCharges: eachCharges.applyCharges,
        items: subscriptionChargeItems,
      });
    }

    $("#form-btn-invoice").addClass("customer-form-section-disable");
    subscriptionInputs.plans = {
      plan_id: planItemValues.planId,
      plan_pricing_id: planItemValues.pricing_id,
      items: subscriptionPlanItems,
    };
    subscriptionInputs.files = fileRef.current.fileIds;
    subscriptionInputs.addons = subscriptionAddon;
    subscriptionInputs.charges = subscriptionCharge;
    subscriptionInputs.coupons =
      couponDetails.length > 0
        ? couponDetails?.map((eachData: SubscriptionCouponList) => eachData.id)
        : [];
    if (emailLists.length > 0) {
      subscriptionInputs.subscription_emails = emailLists;
    }
    let address = [];
    if (billingAddressFormValues?.is_billing === true) {
      let payload: any = billingAddressFormValues;
      address.push({
        ...payload,
        is_billing: !!payload.is_billing,
        is_shipping: !!payload.is_shipping,
        customer_address_id: selectedBillingAddressId,
      });
    }
    if (shippingAddressFormValues?.is_shipping === true) {
      let payload: any = shippingAddressFormValues;
      address.push({
        ...payload,
        is_billing: !!payload.is_billing,
        is_shipping: !!payload.is_shipping,
        customer_address_id: selectedShippingAddressId,
      });
    }

    let selected_address: any = [];
    if (subscriptionInputs.address.length === 0) {
      selected_address = [
        {
          country_id: null,
          address: null,
          city: null,
          state_id: null,
          zip_code: null,
          phone: null,
          is_billing: true,
          is_shipping: false,
          customer_address_id: 0,
        },
        {
          country_id: null,
          address: null,
          city: null,
          state_id: null,
          zip_code: null,
          phone: null,
          is_billing: false,
          is_shipping: true,
          customer_address_id: 0,
        },
      ];
    }
    if (subscriptionInputs.address.length) {
      let shippingAddress = {};
      let billingAddress = {};
      let isShippingAddress = subscriptionInputs.address.some(
        (address: { is_shipping: any }) => address.is_shipping
      );
      let isBillingAddress = subscriptionInputs.address.some(
        (address: { is_billing: any }) => address.is_billing
      );
      if (!isShippingAddress) {
        selected_address.push({
          country_id: null,
          address: null,
          city: null,
          state_id: null,
          zip_code: null,
          phone: null,
          is_billing: false,
          is_shipping: true,
          customer_address_id: 0,
        });
      }
      if (!isBillingAddress) {
        selected_address.push({
          country_id: null,
          address: null,
          city: null,
          state_id: null,
          zip_code: null,
          phone: null,
          is_billing: true,
          is_shipping: false,
          customer_address_id: 0,
        });
      }
    }

    // selected_address.push(subscriptionInputs.address);
    // selected_address.push(subscriptionInputs.address);

    subscriptionInputs.address = address.length ? address : selected_address;

    subscriptionInputs.total = calculatedTotal;
    if (
      !editId &&
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Customers")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        subscriptionInputs.tds_preference = "TRANSACTION_LEVEL";
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        subscriptionInputs.tds_preference = "LINE_LEVEL";
      }
    } else subscriptionInputs.tds_preference = savedTDSPreference;
    if (updatedData && editId) {
      subscriptionInputs.edit_scheduler_details = {
        subscription_id: editId,
        scheduler_option: updatedData.update_type,
        specific_date: getFormatedDate(updatedData.updation_date),
      };
      subscriptionInputs.proration_enabled = updatedData.is_proration_enabled;
    } else {
      subscriptionInputs.proration_enabled = false;
    }
    subscriptionInputs.coupon_configuration = couponConfig;
    const createSubscrptionResponseAction = await dispatch(
      createSubscription({
        values: subscriptionInputs,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (createSubscrptionResponseAction.payload) {
      const createSubscriptionResponse =
        createSubscrptionResponseAction.payload;
      if (
        Object.keys(createSubscriptionResponse).length &&
        !("error" in createSubscriptionResponse)
      ) {
        setTimeout(() => {
          dispatch(setLoaderState(false));
          if (editId && updatedData) {
            navigate(`/subscriptions/detail/${Number(editId)}`, {
              replace: true,
            });
            toast.success("Subscription scheduled successfully!", {
              toastId: "subscription-scheduled-success",
              closeButton: false,
              position: "top-center",
            });
          } else {
            navigate(
              `/subscriptions/detail/${Number(createSubscriptionResponse.id)}`,
              {
                replace: true,
              }
            );
            toast.success("Subscription created successfully!", {
              toastId: "subscription-create-success",
              closeButton: false,
              position: "top-center",
            });
          }
        }, 2000);
      } else if ("error" in createSubscriptionResponse) {
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    }
  };

  const UpdateSubscriptionSubmit = async (
    subscriptionInputs: any,
    planItemValues: SubscriptionPlanDetails,
    addonItemValues: SubscriptionAddonDetails[],
    chargeItemValues: SubscriptionChargeDetails[],
    couponDetails: SubscriptionCouponList[],
    updatedData: any
  ) => {
    if (subscriptionStatus === "FUTURE")
      updatedData.is_proration_enabled = false;

    dispatch(setLoaderState(true));
    subscriptionInputs.invoicing_option = updatedData.invoicing_option
      ? updatedData.invoicing_option
      : "IMMEDIATELY";
    // subscriptionInputs.invoice_generation = updatedData.invoice_generation
    //   ? updatedData.invoice_generation
    //   : "START_OF_SERVICE";
    subscriptionInputs.proration_enabled = updatedData.is_proration_enabled;
    subscriptionInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof subscriptionInputs;
    for (key in subscriptionInputs) {
      if (subscriptionInputs[key] === "") {
        subscriptionInputs[key] = null;
      }

      if (key === "payment_term_id" && subscriptionInputs[key] !== "") {
        if (subscriptionInputs[key] === null) {
          subscriptionInputs[key] = null;
        } else {
          subscriptionInputs[key] = Number(subscriptionInputs[key]);
        }
      }

      if (
        key === "subscription_number" &&
        subscriptionInputs[key] !== "" &&
        subscriptionInputs[key] !== null
      ) {
        subscriptionInputs[key] = defaultRef.current?.subscriptionNumber;
        // subscriptionInputs[key] = defaultRef.current?.defaultData.subscription_number;
      }
      if (key === "tds_percentage") {
        subscriptionInputs[key] =
          subscriptionInputs[key] === "" || subscriptionInputs[key] === null
            ? 0
            : Number(subscriptionInputs[key]);
      }
      if (key === "reference_number" && subscriptionInputs[key]) {
        subscriptionInputs[key] = subscriptionInputs[key].toString().trim();
      }
      if (isGstOrg && key === "place_of_supply") {
        subscriptionInputs[key] = subscriptionInputs[key];
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        subscriptionInputs[key] =
          subscriptionInputs[key] === null ? 0 : subscriptionInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");
    if (editId) {
      let deleteTaxItems = [];
      if (itemRef.current?.deleteTaxItems) {
        deleteTaxItems.push(...itemRef.current?.deleteTaxItems);
      }
      if (itemRef.current?.deleteAddonTaxItems) {
        deleteTaxItems.push(...itemRef.current?.deleteAddonTaxItems);
      }
      if (itemRef.current?.deleteChargeTaxItems) {
        deleteTaxItems.push(...itemRef.current?.deleteChargeTaxItems);
      }
      if (deleteTaxItems) {
        for (let item of deleteTaxItems) {
          await dispatch(
            deleteSubscriptionItemTaxDetails({
              itemId: item.itemId,
              taxId: item.taxItemId,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
      }

      if (isGstOrg) {
        let deleteGstItems = [];

        if (itemRef.current?.deleteGstItems) {
          deleteGstItems.push(...itemRef.current?.deleteGstItems);
        }
        if (itemRef.current?.deleteAddonGstItems) {
          deleteGstItems.push(...itemRef.current?.deleteAddonGstItems);
        }

        if (itemRef.current?.deleteChargeGstItems) {
          deleteGstItems.push(...itemRef.current?.deleteChargeGstItems);
        }
        if (deleteGstItems) {
          for (let item of deleteGstItems) {
            await dispatch(
              deleteSubscriptionItemTaxDetails({
                itemId: item.itemId,
                taxId: item.id,
                orgId: currentUserInfo.organization_id,
              })
            );
          }
        }
      }

      let deleteContactIds: number[] = [];
      deleteEmailIds?.map((contact) => {
        deleteContactIds.push(contact.emailId);
      });
    }
    let subscriptionPlanItems = JSON.parse(
      JSON.stringify(planItemValues.itemDetails)
    );

    for (let items of subscriptionPlanItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax?.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }

    subscriptionPlanItems = subscriptionPlanItems?.map((data: any) => {
      const filteredPlanItemIds: any = [];
      existingPlanItemIds.itemIds?.map((item: { itemId: any }) => {
        if (item.itemId === data.id) {
          filteredPlanItemIds.push(item);
        }
      });

      const eachItemPricingId: any[] = [];
      filteredPlanItemIds[0]?.itemPricingRange?.forEach((id: any) => {
        if (
          data.range_price?.filter(
            (fildata: { id: any }) => fildata.id && fildata.id === id
          ).length === 0
        ) {
          eachItemPricingId.push(id);
        }
      });

      if (
        NON_PRICE_RANGE_PRICING_MODEL.includes(planItemValues.pricing_model)
      ) {
        const combinedIds = data.coupons?.map((id: any) => {
          return data.coupon_details?.find(
            (detail: any) => detail.coupon_id === id
          )
            ? {
                id: data.coupon_details?.find(
                  (detail: any) => detail.coupon_id === id
                ).id,
                coupon_id: id,
              }
            : { id: null, coupon_id: id };
        });
        const returnVal = {
          ...data,
          item_pricing: {
            id: data.itemPricingId,
            price: data.price,
            free_quantity: data.free_quantity,
          },
          coupons: combinedIds,
          coupons_delete_ids: data.coupon_details
            ?.filter((coupon: any) => !data.coupons.includes(coupon.coupon_id))
            ?.map((coupon: any) => coupon.id),
        };

        delete data.itemPricingId;
        delete data.price;
        delete data.free_quantity;

        return returnVal;
      }
      const rangeData = data.range_price;
      delete data.range_price;
      const combinedIds = data.coupons?.map((id: any) => {
        return data.coupon_details?.find(
          (detail: any) => detail.coupon_id === id
        )
          ? {
              id: data.coupon_details?.find(
                (detail: any) => detail.coupon_id === id
              ).id,
              coupon_id: id,
            }
          : { id: null, coupon_id: id };
      });
      const returnVal = {
        ...data,
        item_pricing_range: rangeData,
        delete_pricing_ids: eachItemPricingId,
        coupons: combinedIds,
        coupons_delete_ids: data.coupon_details
          ?.filter((coupon: any) => !data.coupons.includes(coupon.coupon_id))
          ?.map((coupon: any) => coupon.id),
      };

      return returnVal;
    });

    let subscriptionAddon = [];
    for (let eachAddons of addonItemValues) {
      let subscriptionAddonItems = JSON.parse(
        JSON.stringify(eachAddons.itemDetails)
      );

      for (let items of subscriptionAddonItems) {
        let itemInputs = items;
        if (isGstOrg) {
          // updating gst_id in request payload
          itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
            ? itemInputs?.gst_tax?.tax_id
            : null;
        } else {
          delete itemInputs.gst_id;
          delete itemInputs.gst_tax;
        }

        let itemkey: keyof typeof itemInputs;
        for (itemkey in itemInputs) {
          if (itemInputs[itemkey] === "") {
            if (itemkey === "rate" || itemkey === "quantity") {
              itemInputs[itemkey] = 0;
            } else {
              itemInputs[itemkey] = null;
            }
          }
          if (itemkey === "discount") {
            if (itemInputs[itemkey]?.discount_value == 0) {
              itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
            } else if (itemInputs[itemkey]?.discount_percentage == 0) {
              itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
            } else {
              itemInputs[itemkey] = Number(itemInputs[itemkey]);
            }
          }
        }
        let itemTax = itemInputs.taxes;
        let taxData: ItemTaxValues[] = [];
        itemTax?.map((item: any) => {
          if (item.tax_percentage === "") {
            item.tax_percentage = 0;
          }
          if (item.tax_id !== "" || item.tax_percentage !== 0) {
            taxData.push(item);
          }
        });
        items.taxes = taxData;
      }

      subscriptionAddonItems = subscriptionAddonItems?.map((data: any) => {
        if (NON_PRICE_RANGE_PRICING_MODEL?.includes(eachAddons.pricing_model)) {
          const combinedIds = data?.coupons?.map((id: any) => {
            return data?.coupon_details?.find(
              (detail: any) => detail.coupon_id === id
            )
              ? {
                  id: data.coupon_details?.find(
                    (detail: any) => detail.coupon_id === id
                  ).id,
                  coupon_id: id,
                }
              : { id: null, coupon_id: id };
          });
          const returnVal = {
            ...data,
            item_pricing: {
              id: data.itemPricingId,
              price: data.price,
              free_quantity: data.free_quantity,
            },
            coupons: combinedIds,
            coupons_delete_ids: data.coupon_details
              ?.filter(
                (coupon: any) => !data.coupons?.includes(coupon.coupon_id)
              )
              ?.map((coupon: any) => coupon.id),
          };

          delete data.itemPricingId;
          delete data.price;
          delete data.free_quantity;

          return returnVal;
        }

        const filteredAddonItemIds: any = [];
        existingAddonItemIds?.map((addonData: any) => {
          addonData.itemIds?.map((item: { itemId: any }) => {
            if (item.itemId === data.id) {
              filteredAddonItemIds.push(item);
            }
          });
        });

        const eachItemAddonPricingId: any[] = [];
        filteredAddonItemIds[0]?.itemPricingRange?.forEach((id: any) => {
          if (
            data.range_price?.filter(
              (fildata: { id: any }) => fildata.id && fildata.id === id
            ).length === 0
          ) {
            eachItemAddonPricingId.push(id);
          }
        });

        const rangeData = data.range_price;
        delete data.range_price;
        const combinedIds = data.coupons?.map((id: any) => {
          return data.coupon_details?.find(
            (detail: any) => detail.coupon_id === id
          )
            ? {
                id: data.coupon_details?.find(
                  (detail: any) => detail.coupon_id === id
                ).id,
                coupon_id: id,
              }
            : { id: null, coupon_id: id };
        });
        const returnVal = {
          ...data,
          item_pricing_range: rangeData,
          delete_pricing_ids: eachItemAddonPricingId,
          coupons: combinedIds,
          coupons_delete_ids: data.coupon_details
            ?.filter((coupon: any) => !data.coupons?.includes(coupon.coupon_id))
            ?.map((coupon: any) => coupon.id),
        };

        return returnVal;
      });
      subscriptionAddon.push({
        id: eachAddons.id || null,
        addon_id: eachAddons.addonId,
        addon_pricing_id: eachAddons.pricing_id,
        frequency: eachAddons.frequency,
        addon_name: eachAddons.addonName,
        custom_repeat_count: eachAddons.custom_repeat_count,
        pricing_model: eachAddons.pricing_model,
        items: subscriptionAddonItems,
      });
    }

    let subscriptionCharge = [];
    for (let eachCharges of chargeItemValues) {
      let subscriptionChargeItems = JSON.parse(
        JSON.stringify(eachCharges.itemDetails)
      );

      for (let items of subscriptionChargeItems) {
        let itemInputs = items;
        if (isGstOrg) {
          // updating gst_id in request payload
          itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
            ? itemInputs?.gst_tax?.tax_id
            : null;
        } else {
          delete itemInputs.gst_id;
          delete itemInputs.gst_tax;
        }

        let itemkey: keyof typeof itemInputs;
        for (itemkey in itemInputs) {
          if (itemInputs[itemkey] === "") {
            if (itemkey === "rate" || itemkey === "quantity") {
              itemInputs[itemkey] = 0;
            } else {
              itemInputs[itemkey] = null;
            }
          }
          if (itemkey === "discount") {
            if (itemInputs[itemkey]?.discount_value == 0) {
              itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
            } else if (itemInputs[itemkey]?.discount_percentage == 0) {
              itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
            } else {
              itemInputs[itemkey] = Number(itemInputs[itemkey]);
            }
          }
        }
        let itemTax = itemInputs.taxes;
        let taxData: ItemTaxValues[] = [];
        itemTax?.map((item: any) => {
          if (item.tax_percentage === "") {
            item.tax_percentage = 0;
          }
          if (item.tax_id !== "" || item.tax_percentage !== 0) {
            taxData.push(item);
          }
        });
        items.taxes = taxData;
      }

      subscriptionChargeItems = subscriptionChargeItems?.map((data: any) => {
        if (
          NON_PRICE_RANGE_PRICING_MODEL?.includes(eachCharges.pricing_model)
        ) {
          const combinedIds = data.coupons?.map((id: any) => {
            return data.coupon_details?.find(
              (detail: any) => detail.coupon_id === id
            )
              ? {
                  id: data.coupon_details?.find(
                    (detail: any) => detail.coupon_id === id
                  ).id,
                  coupon_id: id,
                }
              : { id: null, coupon_id: id };
          });
          const returnVal = {
            ...data,
            item_pricing: {
              id: data.itemPricingId,
              price: data.price,
              free_quantity: data.free_quantity,
            },
            coupons: combinedIds,
            coupons_delete_ids: data.coupon_details
              ?.filter(
                (coupon: any) => !data.coupons?.includes(coupon.coupon_id)
              )
              ?.map((coupon: any) => coupon.id),
          };

          delete data.itemPricingId;
          delete data.price;
          delete data.free_quantity;

          return returnVal;
        }

        const filteredChargeItemIds: any = [];
        existingChargeItemIds?.map((chargeData: any) => {
          chargeData.itemIds?.map((item: { itemId: any }) => {
            if (item.itemId === data.id) {
              filteredChargeItemIds.push(item);
            }
          });
        });

        const eachItemChargePricingId: any[] = [];
        filteredChargeItemIds[0]?.itemPricingRange?.forEach((id: any) => {
          if (
            data.range_price?.filter(
              (fildata: { id: any }) => fildata.id && fildata.id === id
            ).length === 0
          ) {
            eachItemChargePricingId.push(id);
          }
        });

        const rangeData = data.range_price;
        delete data.range_price;
        const combinedIds = data.coupons?.map((id: any) => {
          return data.coupon_details?.find(
            (detail: any) => detail.coupon_id === id
          )
            ? {
                id: data.coupon_details?.find(
                  (detail: any) => detail.coupon_id === id
                ).id,
                coupon_id: id,
              }
            : { id: null, coupon_id: id };
        });
        const returnVal = {
          ...data,
          item_pricing_range: rangeData,
          delete_pricing_ids: eachItemChargePricingId,
          coupons: combinedIds,
          coupons_delete_ids: data.coupon_details
            ?.filter((coupon: any) => !data.coupons?.includes(coupon.coupon_id))
            ?.map((coupon: any) => coupon.id),
        };

        return returnVal;
      });
      subscriptionCharge.push({
        id: eachCharges.id || null,
        charge_id: eachCharges.chargeId,
        charge_pricing_id: eachCharges.pricing_id,
        frequency: eachCharges.frequency,
        charge_name: eachCharges.chargeName,
        customer_charge_type: eachCharges.customerChargeType,
        service_days: eachCharges.no_of_days,
        applyCharges: eachCharges.applyCharges,
        pricing_model: eachCharges.pricing_model,
        items: subscriptionChargeItems,
      });
    }

    subscriptionInputs.plan_delete_id =
      planItemValues.id && planItemValues.id === planDeleteId
        ? 0
        : planDeleteId;
    subscriptionInputs.plans = {
      id: planItemValues.id || null,
      plan_id: planItemValues.planId,
      plan_pricing_id: planItemValues.pricing_id,
      plan_name: planItemValues.planName,
      custom_repeat_count: planItemValues.custom_repeat_count,
      frequency: planItemValues.frequency,
      pricing_model: planItemValues.pricing_model,
      items: subscriptionPlanItems,
    };

    let addonIds: number[] = [];
    if (addonDeleteIds?.length) {
      addonDeleteIds?.forEach((data) => {
        if (addonItemValues?.length && addonItemValues?.length > 0) {
          if (!addonItemValues?.find((addonData) => addonData.id === data)) {
            addonIds.push(data);
          }
        } else {
          addonIds.push(data);
        }
      });
    }
    subscriptionInputs.addon_delete_ids = addonIds;
    subscriptionInputs.addons = subscriptionAddon;

    let chargeIds: number[] = [];
    if (chargeDeleteIds?.length) {
      chargeDeleteIds?.forEach((data) => {
        if (chargeItemValues?.length && chargeItemValues?.length > 0) {
          if (!chargeItemValues?.find((chargeData) => chargeData.id === data)) {
            chargeIds.push(data);
          }
        } else {
          chargeIds.push(data);
        }
      });
    }
    subscriptionInputs.charge_delete_ids = chargeIds;
    subscriptionInputs.charges = subscriptionCharge;

    let couponIds: number[] = [];
    if (couponDeleteIds?.length) {
      couponDeleteIds?.forEach((data) => {
        if (couponDetails?.length && couponDetails?.length > 0) {
          if (!couponDetails?.find((couponData) => couponData.index === data)) {
            couponIds.push(data);
          }
        } else couponIds.push(data);
      });
    }
    subscriptionInputs.coupons_delete_ids = couponIds;
    subscriptionInputs.coupons =
      couponDetails.length > 0
        ? couponDetails?.map((eachData: SubscriptionCouponList) => ({
            id: eachData.index || null,
            coupon_id: eachData.id,
          }))
        : [];

    subscriptionInputs.files = fileRef.current.fileIds;
    if (emailLists.length > 0) {
      let deleteEmails: number[] = [];
      if (emailData?.length > 0) {
        emailData?.forEach((each) => {
          if (emailLists?.length) {
            let allEmail = emailLists as string[];
            if (!allEmail?.includes(each.email)) {
              deleteEmails?.push(each.id);
            }
          }
        });
      }

      subscriptionInputs.email_delete_ids = deleteEmails;
      subscriptionInputs.subscription_emails =
        emailLists?.length > 0
          ? emailLists?.map((each) => {
              return emailData?.find((data) => data.email === each)
                ? emailData?.find((data) => data.email === each)
                : { email: each };
            })
          : [];
    }

    let address = [];
    if (billingAddressFormValues) {
      billingAddressFormValues.customer_address_id = Number(
        selectedBillingAddressId
      );
      address?.push(billingAddressFormValues);
    }

    if (shippingAddressFormValues) {
      shippingAddressFormValues.customer_address_id = Number(
        selectedBillingAddressId
      );
      address?.push(shippingAddressFormValues);
    }

    subscriptionInputs.address = address;
    subscriptionInputs.total = calculatedTotal;
    subscriptionInputs.roundoff_enabled = isRoundOffEnabled;
    subscriptionInputs.is_total_editable = isTotalEditable;
    subscriptionInputs.is_email_reminder_automatic = isSendEmailToggleEnable;

    const updateInvoiceResponseAction = await dispatch(
      editSubscriptionDetails({
        id: Number(editId),
        editInvoiceInputs: subscriptionInputs,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateInvoiceResponse = updateInvoiceResponseAction.payload;
    if (
      Object.keys(updateInvoiceResponse).length &&
      !("error" in updateInvoiceResponse)
    ) {
      dispatch(setLoaderState(false));
      navigate(`/subscriptions/detail/${Number(updateInvoiceResponse.id)}`, {
        replace: true,
      });
      toast.success("Subscription updated successfully!", {
        toastId: "subscription-update-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in updateInvoiceResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  /**
   * Get invoice details for the edit invoice purpose.
   */
  const subscriptionDetailsResponse = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      subscriptionEditDetails({
        subscriptionId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = responseAction.payload;
      setUpdatedFormData(response);
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;
      if (Object.keys(response).length && !("error" in response) && isSameOrg) {
        let defaultValues = {
          customer_id: response.customer_id,
          customer_name: response.customer_name,
          subscription_number: response.subscription_number,
          payment_term_id: response.payment_term_id,
          currency_id: response.currency_id,
          place_of_supply: response.place_of_supply,
          invoicing_option: response.invoicing_option,
          invoice_generation: response.invoice_generation,
          custom_fields: response.custom_fields,
          start_subscription:
            DateFormatHandler(response.start_date) ===
            response.subscription_created_at
              ? "IMMEDIATELY"
              : "CHOOSE_DATE",
          start_date: response.start_date,
          invoicing_cycle: response.invoicing_cycle,
          invoicing_cycle_fixed_count: response.invoicing_cycle_fixed_count,
          is_mutable: true,
        };
        let otherValues: SubscriptionValues = {
          ...defaultValues,
          tds_level: response.tds_level,
          source_tax_type: response.source_tax_type,
          conversion_id: response.conversion_id,
          conversion_date: response?.conversion_date || "",
          tcs_percentage: response.tcs_percentage,
          tcs_id: response.tcs_id || 0,
          tcs_account_id: response.tcs_account_id,
          tcs_amount: response.tcs_amount,
          total: response.total_details.total,
          tds_id: response.tds_id || 0,
          tds_name: response.tds_name,
          tcs_name: response.tcs_name,
          tds_amount: response.tds_amount,
          tds_account_id: response.tds_account_id,
          addons: response.addon_details?.map((eachAddon: any) => ({
            ...eachAddon,
            addonId: eachAddon.addon_id,
            addonName: eachAddon.addon_name,
            custom_repeat_count: eachAddon.custom_repeat_count,
            pricing_id: eachAddon.addon_pricing_id,
            itemDetails: eachAddon.items,
          })),
          charges: response.charge_details
            ? response.charge_details?.map((eachCharge: any) => ({
                ...eachCharge,
                chargeId: eachCharge.charge_id,
                chargeName: eachCharge.charge_name,
                pricing_id: eachCharge.charge_pricing_id,
                itemDetails: eachCharge.items,
              }))
            : [],
          coupons: response.coupon_details?.map((eachCoupon: any) => ({
            ...eachCoupon,
            id: eachCoupon.coupon_id,
            index: eachCoupon.id,
          })),
          plans: {
            ...response.plan_details,
            planId: response.plan_details.plan_id,
            planName: response.plan_details.plan_name,
            custom_repeat_count: response.plan_details.custom_repeat_count,
            pricing_id: response.plan_details.plan_pricing_id,
            itemDetails: response.plan_details.items,
          },
          address: response.address,
          customer_notes: response.customer_notes,
          files: response?.files?.map((file: any) => file.id) || [],
          tds_percentage: response.tds_percentage,
          subscription_emails: response.subscription_emails?.map(
            (data: any) => data.email
          ),
          is_email_reminder_automatic: response.is_email_reminder_automatic,
        };
        let totalTdsValue = {
          tds_id: response.tds_id,
          tds_account_id: response.tds_account_id,
          tds_percentage: response.tds_percentage,
          tds_amount: response.tds_amount,
          tds_name: response.tds_name || "",
          default_tds_percentage:
            response.default_tds_percentage === null
              ? 0
              : response.default_tds_percentage,
        };
        let totalTcsValue = {
          tcs_id: response.tcs_id,
          tcs_account_id: response.tcs_account_id,
          tcs_percentage: response.tcs_percentage,
          tcs_amount: response.tcs_amount,
          tcs_name: response.tcs_name || "",
          default_tcs_percentage:
            response.default_tcs_percentage === null
              ? 0
              : response.default_tcs_percentage,
        };
        setIsSendEmailToggleEnable(response.is_email_reminder_automatic);
        setSubscriptionNumber(response.subscription_number);
        setTotalTdsValues(totalTdsValue);
        setTotalTcsValues(totalTcsValue);
        setUploadedFiles(response.files);
        setFileIds(response.files?.map((file: any) => file.id));
        setInsertedFiles(response.files?.map((file: any) => file.id));
        let customerDetails = await getCustomerDetails(
          response.customer_id,
          "subscription"
        );

        const preferenceResponse = await dispatch(
          fetchRoundOffPreference({
            orgId: currentUserInfo.organization_id,
            roundoff_transaction_type: "Sales",
          })
        );
        let preferencePayload = preferenceResponse.payload;

        setIsRoundOffEnabled((prevValue) => {
          return response?.roundoff_enabled || preferencePayload.is_enabled
            ? true
            : false;
        });

        setIsTotalEditable((prevValue) => {
          if (response?.roundoff_enabled || preferencePayload.is_enabled) {
            if (response?.roundoff_enabled) {
              return response?.is_total_editable;
            } else {
              return preferencePayload.is_total_editable;
            }
          } else {
            return false;
          }
        });
        if (isGstOrg) {
          let stateType =
            !response.place_of_supply || !currentUserInfo.organization_gst_state
              ? ""
              : response.place_of_supply ===
                currentUserInfo.organization_gst_state
              ? "Intra State"
              : "Inter State";
          if (
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.sez ||
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.sezDeveloper ||
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.overseas
          ) {
            stateType = "Inter State";
          }
          setStateType(stateType);
          setInitialStateType(stateType);

          let transaction_tax_details = [] as any;
          response.plan_details.items?.forEach((item: any) => {
            let gst_tax = item.gst_tax;
            if (gst_tax?.tax_id) {
              transaction_tax_details.push({
                is_group: gst_tax?.is_group ? true : false,
                tax_id: gst_tax?.tax_id,
              });
            }
          });
          response.addon_details?.forEach((eachAddon: any) => {
            eachAddon.items?.forEach((item: any) => {
              let gst_tax = item.gst_tax;
              if (gst_tax?.tax_id) {
                transaction_tax_details.push({
                  is_group: gst_tax?.is_group ? true : false,
                  tax_id: gst_tax?.tax_id,
                });
              }
            });
          });
          response.charge_details &&
            response.charge_details?.forEach((eachCharge: any) => {
              eachCharge.items?.forEach((item: any) => {
                let gst_tax = item.gst_tax;
                if (gst_tax?.tax_id) {
                  transaction_tax_details.push({
                    is_group: gst_tax?.is_group ? true : false,
                    tax_id: gst_tax?.tax_id,
                  });
                }
              });
            });
          setPlanTransactionTaxDetails(transaction_tax_details);
          await fetchGstTaxList(
            stateType,
            customerDetails.gst_registration_type,
            transaction_tax_details
          );

          setGstRegistrationType(customerDetails.gst_registration_type);
        }
        if (response.conversion_date)
          setConversionDate(
            getFormatedDate(new Date(response.conversion_date))
          );
        if (response.subscription_number) {
          if (response.subscription_number.includes("~")) {
            setSubNumber(response.subscription_number.split("~")[1]);
          } else {
            setSubNumber(response.subscription_number);
          }
        }
        setSubscriptionStatus(response.subscription_status);
        setSubscriptionStopDate(response.scheduled_stop_date);
        setSubscriptionCancelDate(response.scheduled_cancel_date);
        setNextRenewableDate(response.next_renewal);
        setSubscriptionEndDate(response.subscription_end_date);
        setCustomerId(response.customer_id);
        setCustomerIdForEdit(response.customer_id);
        setCurrencyCode(response.currency_code);
        setCurrencyId(response?.currency_id || 0);
        setConversionCurrencyId(response.currency_id);
        setSavedTDSPreference(response.tds_preference);
        setSubscriptionFormValues({ ...otherValues });
        setSubscriptionValuesForEdit({ ...otherValues });
        setDefaultFormValues({ ...defaultValues });
        setNextInvoiceEdit({
          first_invoice_start_date: response.next_renewal,
          first_invoice_end_date: response.next_invoice_end_date,
          first_invoice_amount: response.next_invoice_amount,
          first_invoicing_date: response.next_renewal,
          second_invoice_start_date: "",
          second_invoice_end_date: "",
          second_invoice_amount: 0,
          invoice_generation: response.invoice_generation,
        });
        setCouponConfiguration(response.coupon_configuration);
        let addressData = response.address;
        addressData.map((values: any) => {
          if (values.is_billing) {
            setBillingAddressFormValues(values);
            setBillingAddressFormValuesForEdit(values);
            if (values.customer_address_id) {
              setCustomerBillingAddressId(values.customer_address_id);
            }
          }
          if (values.is_shipping) {
            setShippingAddressFormValues(values);
            setShippingAddressFormValuesForEdit(values);
            if (values.customer_address_id) {
              setCustomerShippingAddressId(values.customer_address_id);
            }
          }
        });
        let planItemDetails = response.plan_details;
        let tdsIdArray: number[] = [];
        planItemDetails.items?.map((item: any) => {
          if (!item.taxes.length) {
            item.taxes = [initialTaxValues];
          }
          if (item.tds_id) {
            tdsIdArray.push(item.tds_id);
          }
        });
        if (planItemDetails.items.length) {
          const updatetedItems = planItemDetails.items?.map((data: any) => {
            if (
              NON_PRICE_RANGE_PRICING_MODEL.includes(
                planItemDetails.pricing_model
              )
            ) {
              return {
                ...data,
                itemPricingId: data.item_pricing.id || 0,
                price: data.item_pricing.price || 0,
                free_quantity: data.item_pricing.free_quantity || 0,
                coupons: data.coupon_details?.map(
                  (coupon: any) => coupon.coupon_id
                ),
                conversion_date: response.conversion_date || null,
              };
            }
            return {
              ...data,
              range_price: data.item_pricing_range || [],
              coupons: data.coupon_details?.map(
                (coupon: any) => coupon.coupon_id
              ),
              conversion_date: response.conversion_date || null,
            };
          });
          setPlanItemFormValues({
            id: planItemDetails.id,
            planId: planItemDetails.plan_id,
            planName: planItemDetails.plan_name,
            pricing_id: planItemDetails.plan_pricing_id,
            frequency: planItemDetails.frequency,
            custom_repeat_count: planItemDetails.custom_repeat_count,
            pricing_model: planItemDetails.pricing_model,
            itemDetails: updatetedItems,
          });
          //WORKING
          const planItemFormValuesIdsObj = {
            id: planItemDetails.id,
            itemIds: updatetedItems?.map((item: any) => {
              const itemIdArray: any[] = [];
              item.item_pricing_range?.map((item: any) => {
                itemIdArray.push(item.id);
              });
              return {
                itemId: item.id,
                itemPricingRange: itemIdArray,
              };
            }),
          };

          setExistingPlanItemIds(planItemFormValuesIdsObj);
        } else {
          setPlanItemFormValues({
            planId: 0,
            planName: "",
            frequency: "",
            custom_repeat_count: 0,
            pricing_model: "",
            pricing_id: 0,
            itemDetails: [],
          });
        }
        if (response.tds_id) {
          tdsIdArray.push(response.tds_id);
          setTdsIds(tdsIdArray);
        } else setPlanTdsIds(tdsIdArray);

        let tcsIdArray: number[] = [];
        if (response.tcs_id) {
          tcsIdArray.push(response.tcs_id);
          setTcsIds(tcsIdArray);
        }
        setPlanDeleteId(response.plan_details.id);
        if (response.addon_details?.length) {
          setAddonDeleteIds(
            response.addon_details?.map((eachAddon: any) => eachAddon.id)
          );
          let addonItemDetails = response.addon_details;
          let addonTdsIdArray: number[][] = [];
          addonItemDetails?.forEach((eachAddon: any) => {
            let eachAddonTdsIdArray: number[] = [];
            eachAddon.items?.forEach((item: any) => {
              if (!item.taxes.length) {
                item.taxes = [initialTaxValues];
              }
              if (item.tds_id) {
                eachAddonTdsIdArray.push(item.tds_id);
              }
            });
            if (eachAddonTdsIdArray.length > 0)
              addonTdsIdArray.push(eachAddonTdsIdArray);
          });
          setAddonTdsIds(addonTdsIdArray);
          if (addonItemDetails.length) {
            const updatedAddonData = addonItemDetails?.map((eachAddon: any) => {
              const updatetedItems = eachAddon.items?.map((data: any) => {
                if (
                  NON_PRICE_RANGE_PRICING_MODEL.includes(
                    eachAddon.pricing_model
                  )
                ) {
                  return {
                    ...data,
                    itemPricingId: data.item_pricing.id || 0,
                    price: data.item_pricing.price || 0,
                    free_quantity: data.item_pricing.free_quantity || 0,
                    coupons: data.coupon_details?.map(
                      (coupon: any) => coupon.coupon_id
                    ),
                    conversion_date: response.conversion_date || null,
                  };
                }
                return {
                  ...data,
                  range_price: data.item_pricing_range || [],
                  coupons: data.coupon_details?.map(
                    (coupon: any) => coupon.coupon_id
                  ),
                  conversion_date: response.conversion_date || null,
                };
              });
              return {
                ...eachAddon,
                addonId: eachAddon.addon_id,
                addonName: eachAddon.addon_name,
                custom_repeat_count: eachAddon.custom_repeat_count,
                pricing_id: eachAddon.addon_pricing_id,
                itemDetails: updatetedItems,
              };
            });
            setAddonItemFormValues(updatedAddonData);

            //WORKING
            const addonsItemFormValuesIdsObjArray: { id: any; itemIds: any }[] =
              [];
            updatedAddonData?.map((addons: any) => {
              const addonsItemFormValuesIdsObj = {
                id: addons.id,
                itemIds: addons.itemDetails?.map((item: any) => {
                  const itemIdArray: any[] = [];
                  item.item_pricing_range?.map((item: any) => {
                    itemIdArray.push(item.id);
                  });
                  return {
                    itemId: item.id,
                    itemPricingRange: itemIdArray,
                  };
                }),
              };
              addonsItemFormValuesIdsObjArray.push(addonsItemFormValuesIdsObj);
            });
            setExistingAddonItemIds(addonsItemFormValuesIdsObjArray);
          } else {
            setAddonItemFormValues([]);
          }
        }

        if (response.charge_details?.length) {
          setChargeDeleteIds(
            response.charge_details?.map((eachCharge: any) => eachCharge.id)
          );
          let chargeItemDetails = response.charge_details;
          let chargeTdsIdArray: number[][] = [];
          chargeItemDetails?.forEach((eachCharge: any) => {
            let eachChargeTdsIdArray: number[] = [];
            eachCharge.items?.forEach((item: any) => {
              if (!item.taxes.length) {
                item.taxes = [initialTaxValues];
              }
              if (item.tds_id) {
                eachChargeTdsIdArray.push(item.tds_id);
              }
            });
            if (eachChargeTdsIdArray.length > 0)
              chargeTdsIdArray.push(eachChargeTdsIdArray);
          });
          setChargeTdsIds(chargeTdsIdArray);
          if (chargeItemDetails.length) {
            const updatedChargeData = chargeItemDetails?.map(
              (eachCharge: any) => {
                const updatetedItems = eachCharge.items?.map((data: any) => {
                  if (
                    NON_PRICE_RANGE_PRICING_MODEL.includes(
                      eachCharge.pricing_model
                    )
                  ) {
                    return {
                      ...data,
                      itemPricingId: data.item_pricing.id || 0,
                      price: data.item_pricing.price || 0,
                      free_quantity: data.item_pricing.free_quantity || 0,
                      coupons: data.coupon_details?.map(
                        (coupon: any) => coupon.coupon_id
                      ),
                      conversion_date: response.conversion_date || null,
                    };
                  }
                  return {
                    ...data,
                    range_price: data.item_pricing_range || [],
                    coupons: data.coupon_details?.map(
                      (coupon: any) => coupon.coupon_id
                    ),
                    conversion_date: response.conversion_date || null,
                  };
                });
                return {
                  ...eachCharge,
                  chargeId: eachCharge.charge_id,
                  chargeName: eachCharge.charge_name,
                  no_of_days: eachCharge.service_days,
                  applyCharges: eachCharge.apply_charge_on,
                  customerChargeType: eachCharge.customer_charge_type,
                  pricing_id: eachCharge.charge_pricing_id,
                  itemDetails: updatetedItems,
                };
              }
            );
            setChargeItemFormValues(updatedChargeData);

            //WORKING
            const chargesItemFormValuesIdsObjArray: {
              id: any;
              itemIds: any;
            }[] = [];
            updatedChargeData.map((charges: any) => {
              const chargesItemFormValuesIdsObj = {
                id: charges.id,
                itemIds: charges.itemDetails.map((item: any) => {
                  const itemIdArray: any[] = [];
                  item.item_pricing_range?.map((item: any) => {
                    itemIdArray.push(item.id);
                  });
                  return {
                    itemId: item.id,
                    itemPricingRange: itemIdArray,
                  };
                }),
              };
              chargesItemFormValuesIdsObjArray.push(
                chargesItemFormValuesIdsObj
              );
            });
            setExistingChargeItemIds(chargesItemFormValuesIdsObjArray);
          } else {
            setChargeItemFormValues([]);
          }
        }

        if (
          response.coupon_details?.length &&
          response.coupon_details.length > 0
        ) {
          setCouponDeleteIds(
            response.coupon_details.map((eachCoupon: any) => eachCoupon.id)
          );
          setCouponFormValues(
            response.coupon_details.map((eachCoupon: any) => ({
              id: eachCoupon.coupon_id,
              index: eachCoupon.id,
              name: eachCoupon.name,
              discount: eachCoupon.discount,
              duration: eachCoupon.duration,
            }))
          );
        } else setCouponFormValues([]);
        let totalDetails = response.total_details;
        setTotalFormValues(totalDetails);
        let emailContacts = response.subscription_emails;
        let contactEmail: string[] = [];
        let emailDetails: any[] = [];
        emailContacts.map((contact: any) => {
          contactEmail.push(contact.email);
          emailDetails.push(contact);
        });
        setEmailLists(contactEmail);
        setEmailData(emailDetails);
        setEditTotal(response.total_details.total || 0);
      }
    }
  };
  const setEmailHandler = (email: string[]) => {
    setEmailLists(email);
  };

  const handleAddressFormValues = async (values: AddressValues) => {
    if (values.is_billing === true) {
      setBillingAddressFormValues(values);
    }
    if (values.is_shipping === true) {
      setShippingAddressFormValues(values);
    }
  };

  useEffect(() => {
    dispatch(setLoaderState(true));
    const fetchItems = async () => {
      if (currentUserInfo.organization_id) {
        await dispatch(
          itemDropdown({
            currencyId: currentUserInfo.organization_currency,
            conversionId: 0,
            orgId: currentUserInfo.organization_id,
            transaction_type: "SALES",
            for_subscription: true,
          })
        );
      }
      dispatch(setLoaderState(false));
    };
    fetchItems();
  }, [currentUserInfo, dispatch]);

  useEffect(() => {
    customerData?.map((item: any) => {
      if (
        item.is_billing === true &&
        ((item.is_primary && selectedBillingAddressId === 0) ||
          item.id === selectedBillingAddressId) &&
        (item.country_id !== null ||
          item.address !== null ||
          item.city !== null ||
          item.state_id !== null ||
          item.zip_code !== null ||
          item.phone !== null)
      ) {
        if (editId && customerId === customerIdForEdit) {
          if (!isBillingAddressSame) setSelectedBillingAddressId(0);
          const billingAddressCopy = { ...billingAddressFormValuesForEdit };
          delete billingAddressCopy.id;
          delete billingAddressCopy.is_billing;
          delete billingAddressCopy.is_shipping;
          const isNullish = Object.values(billingAddressCopy).every((value) => {
            if (value === null) {
              return true;
            }
            return false;
          });
          if (!isNullish) {
            setBillingAddressFormValues(billingAddressFormValuesForEdit);
          } else {
            setSelectedBillingAddressId(item.id);
            setBillingAddressFormValues(item);
          }
        } else {
          setSelectedBillingAddressId(item.id);
          setBillingAddressFormValues(item);
        }
      }
      if (
        item.is_shipping === true &&
        ((item.is_primary && selectedShippingAddressId === 0) ||
          item.id === selectedShippingAddressId) &&
        (item.country_id !== null ||
          item.address !== null ||
          item.city !== null ||
          item.state_id !== null ||
          item.zip_code !== null ||
          item.phone !== null)
      ) {
        if (editId && customerId === customerIdForEdit) {
          if (!isShippingAddressSame) setSelectedShippingAddressId(0);
          const shippingAddressCopy = { ...shippingAddressFormValuesForEdit };
          delete shippingAddressCopy.id;
          delete shippingAddressCopy.is_billing;
          delete shippingAddressCopy.is_shipping;
          const isNullish = Object.values(shippingAddressCopy).every(
            (value) => {
              if (value === null) {
                return true;
              }
              return false;
            }
          );
          if (!isNullish) {
            setShippingAddressFormValues(shippingAddressFormValuesForEdit);
          } else {
            setSelectedShippingAddressId(item.id);
            setShippingAddressFormValues(item);
          }
        } else {
          setSelectedShippingAddressId(item.id);
          setShippingAddressFormValues(item);
        }
      }
    });
  }, [customerData]);

  /**
   * Function to set Primary billing address
   */
  const selectBillingAddressHandler = (index: number | undefined | string) => {
    if (index) {
      setSelectedBillingAddressId(index);
    }
  };
  /**
   * Function to set Primary shipping address
   */
  const selectShippingAddressHandler = (index: number | undefined | string) => {
    if (index) {
      setSelectedShippingAddressId(index);
    }
  };

  /**
   * Function to store emailIds to be deleted in Edit subscription
   */
  const removeEmailHandler = (email: string) => {
    emailData.map((contact) => {
      if (contact.email === email) {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emailId: contact.id },
        ]);
      }
    });
  };

  /**
   * function to get taxable amount
   */
  const amountCalculation = async (data: {
    values: any;
    orgId: number;
    signal: Object;
    conversionDate: string;
  }) => {
    setInvoicePreviewPayload({
      ...data.values,
      conversion_date: conversionDate,
      addon_item_list: data.values.addon_item_list.map((item: any) => {
        return {
          ...item,
          addon_id: item?.subscription_addon_id,
          addon_pricing_id: item.pricing_id,
        };
      }),
      charge_item_list: data.values.charge_item_list.map((item: any) => {
        return {
          ...item,
          charges_id: item.pricing_id,
        };
      }),
      tds_name: subscriptionFormValues.tds_name || "",
      tcs_name: subscriptionFormValues.tcs_name || "",
    });
    const responseAction = await dispatch(getAmountCalculation(data));
    return responseAction;
  };
  /**
   * function to get taxable amount
   */
  const taxableAmount = async (data: {
    values: SubscriptionItemValues;
    orgId: number;
    signal: Object;
  }) => {
    const taxResponseAction = await dispatch(getTaxableAmount({ ...data }));
    return taxResponseAction;
  };

  /**
   * function to get upload files
   */
  const uploadAttachedFiles = async (file: any) => {
    let formData = new FormData();
    formData.append("file", file);
    let responseAction = await dispatch(
      attachSubscriptionFiles({
        file: formData,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  /**
   * Remove uploaded files
   */
  const removeFile = async (fileId: any) => {
    if (insertedFiles.includes(fileId)) {
      await dispatch(
        deleteSubscriptionFile({
          fileId,
          orgId: currentUserInfo.organization_id,
        })
      );
    }

    let defaultValues = defaultRef.current?.defaultData;
    let planItemValues: SubscriptionPlanDetails = itemRef.current
      ?.itemPlanValues
      ? itemRef.current?.itemPlanValues
      : planItemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let subscriptionInputs: any = {
      ...subscriptionFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
      tds_level: getTdsApplyLevel(),
      source_tax_type: getSourceTaxType(),
    };
    let errors = validate(
      subscriptionInputs,
      isSubscriptionNumberExist,
      emailLists,
      planItemValues,
      isGstOrg,
      editId,
      customFieldRef.current.errors,
      subscriptionStatus
    );

    if (!customFieldRef.current.errors) {
      delete errors.custom_fields;
    }

    if (Object.keys(errors).length || customFieldRef.current.errors) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
    return {};
  };

  useEffect(() => {
    let onProgressFiles = uploadedFiles?.filter(
      (files: any) => files.progressInfo === 0 && files.status !== "removed"
    ).length;
    if (onProgressFiles) {
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
    }
  }, [uploadedFiles]);

  const handleSubmitAction = (status: boolean) => {
    if (status) {
      setFormSubmitAction(false);
    } else {
      setFormSubmitAction(true);
    }
  };

  /**
   * set address ID handler
   */
  const setAddressIdHandler = (id: number | string) => {
    setAddressId(id);
  };

  const fetchConsumerDetail = (customerId: number) => {
    getCustomerDetails(customerId, "subscription");
  };

  const fetchGstTaxList = async (
    stateType: string,
    gstRegType?: string,
    transaction_tax_details?: [{ is_group: boolean; tax_id: number }]
  ) => {
    if (!transaction_tax_details && planTransactionTaxDetails) {
      transaction_tax_details = planTransactionTaxDetails;
    }
    if (
      gstRegType === gstRegistrationTypes.sez ||
      gstRegType === gstRegistrationTypes.sezDeveloper ||
      gstRegType === gstRegistrationTypes.overseas
    ) {
      stateType = "Inter State";
    }
    dispatch(clearGstTaxList());
    await dispatch(
      getGstTaxList({
        stateType: stateType,
        orgId: currentUserInfo.organization_id,
        is_sez: false,
        transaction_tax_details,
        transactionSource: "Invoices", // needs to be "Subscription"
      })
    );
  };

  const getTotalValues = (total: number | string) => {
    setTotalAmount(Number(total));
  };

  const updateCalculatedTotal = (val: any) => {
    setCalculatedTotal(val);
  };

  /**
   * Check subscription number already exist
   */
  const checkSubscriptionNumberExist = async (subscriptionNumber: string) => {
    setIsSubscriptionChecking(true);
    if (!subscriptionNumber || subscriptionNumber.includes("undefined")) {
      setIsSubscriptionChecking(false);
      return;
    }
    if (interval) {
      clearTimeout(interval);
    }
    interval = setTimeout(async () => {
      try {
        const responseAction = await dispatch(
          checkSubscriptionNumber({
            subscriptionNumber: subscriptionNumber?.trim(),
            orgId: currentUserInfo.organization_id,
            subscriptionId: editId ? Number(editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.subscription_number_available === false) {
              setIsSubscriptionNumberExist(true);
              setFormErrors({
                ...formErrors,
                subscription_number:
                  "Subscription number already exists. Please choose a different one",
              });
            } else {
              setIsSubscriptionNumberExist(false);
              setFormErrors({ ...formErrors, subscription_number: "" });
            }
          }
        }
      } catch (error) {
        setFormErrors({
          ...formErrors,
          subscription_number:
            "An error occurred while checking the subscription number.",
        });
      } finally {
        setIsSubscriptionChecking(false);
      }
    }, 1000);
  };

  const removeDuplicates = (items: any) => {
    const uniqueItems: any = [];
    const seenIds = new Set<number>();

    for (const item of items) {
      if (!seenIds.has(item.inventory_item_id)) {
        seenIds.add(item.inventory_item_id);
        if (item.hsn_or_sac_code === "") {
          item.hsn_or_sac_code = null;
        }
        uniqueItems.push(item);
      }
    }

    return uniqueItems;
  };

  const getNextInvoiceAction = async (
    startDate: string,
    planId: number,
    pricing_id: number,
    total: number,
    invOption: string,
    is_proration_enabled: boolean,
    subscription_id?: number,
    in_subscription_create?: boolean,
    invoice_generation?: string,
    onlyCouponChange?: boolean,
    updatedItemList?: any,
    conversionDate?: string
  ) => {
    const date = new Date(startDate);
    const selectedStartDate = date.setHours(0, 0, 0, 0);
    let initalNextInvoiceDate = null;

    if (nextInvoiceEdit) {
      const [day, month, year] = nextInvoiceEdit.first_invoice_start_date
        .split("-")
        .map(Number);
      const stopScheduledDate = new Date(`${month}-${day}-${year}`);
      initalNextInvoiceDate = stopScheduledDate.setHours(0, 0, 0, 0);
    }
    if (
      initalNextInvoiceDate &&
      initalNextInvoiceDate < selectedStartDate &&
      subscriptionStatus !== "CANCELLED"
    ) {
      setNextInvoiceDateDetails(nextInvoiceEdit);
    } else {
      setNextInvoicePayload((prevValue: any) => ({
        ...prevValue,
        invoicingoption: invOption ? invOption : "",
        pricing_id: pricing_id,
        plan_id: planId,
        start_date: startDate ? startDate : "",
        total_amount: total,
        is_proration_enabled: is_proration_enabled,
        invoice_generation: invoice_generation,
        only_coupon_change: onlyCouponChange,
        updated_item_list: updatedItemList ? updatedItemList : chargedItems,
        invoicing_cycle: "FOREVER",
        invoicing_cycle_fixed_count: 1,
        charges_amount: 0,
        customer_charge_type: null,
      }));
      const responseNextInvoiceAction = await dispatch(
        getNextInvoiceDate({
          invoicingoption: invOption ? invOption : "",
          pricing_id: pricing_id,
          plan_id: planId,
          start_date: startDate ? startDate : "",
          total_amount: total,
          orgId: currentUserInfo.organization_id,
          is_proration_enabled: is_proration_enabled,
          subscription_id: subscription_id,
          in_subscription_create: in_subscription_create,
          invoice_generation: invoice_generation,
          onlyCouponChange: onlyCouponChange,
          updatedItemList: updatedItemList
            ? removeDuplicates(updatedItemList)
            : removeDuplicates(chargedItems),
          currency_id: subscriptionFormValues.currency_id as number,
          conversion_date: subscriptionFormValues.conversion_date
            ? subscriptionFormValues.conversion_date
            : conversionDate
            ? conversionDate
            : exchangeRateValues.timestamp.split(" ")[0],
          // updatedItemList: updatedItemList,
        })
      );
      if (responseNextInvoiceAction.payload) {
        setNextInvoiceDateDetails(responseNextInvoiceAction.payload);
      }
    }
  };

  const getNextInvoicePayload = (nextInvoiceData: any) => {
    setNextInvoicePayload({ ...nextInvoiceData });
  };

  function compareArrays(arr1: any, arr2: any) {
    const map = new Map();
    arr1.forEach((item: { id: any; taxes: any; gst_tax: any }) => {
      map.set(item.id, {
        taxes: JSON.stringify(item.taxes, (_, v) => (v === "0" ? 0 : v)),
        gst_tax: JSON.stringify(item.gst_tax, (_, v) => (v === "0" ? 0 : v)),
      });
    });

    return arr2.every((item: { id: any; taxes: any; gst_tax: any }) => {
      if (map.has(item.id)) {
        const { taxes, gst_tax } = map.get(item.id);
        return (
          taxes === JSON.stringify(item.taxes, (_, v) => (v === "0" ? 0 : v)) &&
          gst_tax ===
            JSON.stringify(item.gst_tax, (_, v) => (v === "0" ? 0 : v))
        );
      }
      return false;
    });
  }

  function compareAddonItems(arrayA: any, arrayB: any) {
    function deepEqual(obj1: any, obj2: any) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    for (let addonA of arrayA) {
      let addonB = arrayB.find(
        (b: { addon_id: any }) => b.addon_id === addonA.addon_id
      );
      if (!addonB) continue;

      for (let itemA of addonA.itemDetails) {
        let itemB = addonB.items.find((b: { id: any }) => b.id === itemA.id);
        if (!itemB) continue;

        if (
          !deepEqual(itemA.taxes, itemB.taxes) ||
          !deepEqual(itemA.gst_tax, itemB.gst_tax)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  function compareChargeItems(arrayA: any, arrayB: any) {
    function deepEqual(obj1: any, obj2: any) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    for (let addonA of arrayA) {
      let addonB = arrayB.find(
        (b: { charge_id: any }) => b.charge_id === addonA.charge_id
      );
      if (!addonB) continue;

      for (let itemA of addonA.itemDetails) {
        let itemB = addonB.items.find((b: { id: any }) => b.id === itemA.id);
        if (!itemB) continue;

        if (
          !deepEqual(itemA.taxes, itemB.taxes) ||
          !deepEqual(itemA.gst_tax, itemB.gst_tax)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  useEffect(() => {
    let planCompare;
    let addonCompare;
    let chargeCompare;
    if (
      itemRef.current?.itemPlanValues?.itemDetails &&
      updatedFormData?.plan_details?.items
    ) {
      planCompare = compareArrays(
        itemRef.current?.itemPlanValues?.itemDetails,
        updatedFormData?.plan_details?.items
      );
    }
    if (itemRef.current?.itemAddonValues && updatedFormData?.addon_details) {
      addonCompare = compareAddonItems(
        itemRef.current?.itemAddonValues,
        updatedFormData?.addon_details
      );
    }
    if (itemRef.current?.itemChargeValues && updatedFormData?.charge_details) {
      chargeCompare = compareChargeItems(
        itemRef.current?.itemChargeValues,
        updatedFormData?.charge_details
      );
    }
    if (planCompare && addonCompare && chargeCompare) {
      setDifference(false);
    } else {
      setDifference(true);
    }
  }, [itemRef.current, updatedFormData]);

  return (
    <>
      <Card
        wrapperClass="subscription-form card-create-item white-space-break-spaces"
        key={location}
      >
        <CardHeader isBackButtonEnabled={true}>
          <div className="form-subscription-header">
            {editId ? "Edit Subscription" : "Create Subscription"}
            <span className="subscription-header-org-span">
              <OrgTag
                organization={
                  userOrganization?.find(
                    (org: any) => org.id === currentUserInfo.organization_id
                  )?.name || ""
                }
              />
            </span>{" "}
          </div>
        </CardHeader>
        <CardBody>
          <Form
            name="add_invoice_form"
            id="add_invoice_form"
            className={`add-module-item-form ${
              defaultRef.current?.defaultData?.customer_id ? "" : " inactive"
            }`}
            tabIndex={-1}
          >
            <div className="fields-wrapper pt-0">
              <div className="fields-wrappers">
                {/* itemRef.current?.itemPlanValues */}
                <SubscriptionBasicDetails
                  defaultFormValues={defaultFormValues}
                  subscriptionFormValues={subscriptionFormValues}
                  handleCustomerChange={handleCustomerChange}
                  checkSubscriptionNumberExist={checkSubscriptionNumberExist}
                  formErrors={formErrors}
                  organizationId={currentUserInfo.organization_id}
                  handleNewConversionDate={handleNewConversionDate}
                  ref={defaultRef}
                  customFieldRef={customFieldRef}
                  // getCustomFieldData={() => {}}
                  customFieldValue={[]}
                  setStateType={setStateType}
                  fetchGstTaxList={fetchGstTaxList}
                  setSupplyState={setSupplyState}
                  initialStateType={initialStateType}
                  setIsStateChanged={setIsStateChanged}
                  selectedInvoicingCycle={selectedInvoicingCycle}
                  setBasicDetailsNextInvoice={setBasicDetailsNextInvoice}
                  itemRef={itemRef}
                  subscriptionNumber={subscriptionNumber}
                />
                {(isCustomerName || editId) && (
                  <SubscriptionAddress
                    billingAddressFormValues={billingAddressFormValues}
                    shippingAddressFormValues={shippingAddressFormValues}
                    customerData={customerData}
                    createBillingAddress={(values) =>
                      handleAddressFormValues(values)
                    }
                    createShippingAddress={(values) =>
                      handleAddressFormValues(values)
                    }
                    selectBillingAddressHandler={selectBillingAddressHandler}
                    selectShippingAddressHandler={selectShippingAddressHandler}
                    selectedBillingAddressId={selectedBillingAddressId}
                    selectedShippingAddressId={selectedShippingAddressId}
                    openAddressModal={openAddressModal}
                    setAddressIdHandler={setAddressIdHandler}
                    customerId={customerId}
                    customerIdForEdit={customerIdForEdit}
                    subscriptionFormValues={subscriptionFormValues}
                    customerDisplayName={customerDisplayName}
                  />
                )}
                <SubscriptionDetail
                  formValues={subscriptionFormValues}
                  itemFormValues={planItemFormValues}
                  addonItemFormValues={addonItemFormValues}
                  chargeItemFormValues={chargeItemFormValues}
                  couponFormValues={couponFormValues}
                  totalFormValues={totalFormValues}
                  formErrors={formErrors}
                  itemFormErrors={planItemFormErrors}
                  addonItemFormError={addonItemFormErrors}
                  chargeItemFormError={chargeItemFormErrors}
                  currencyId={currencyId}
                  currencyCode={currencyCode}
                  amountCalculation={amountCalculation}
                  taxableAmount={taxableAmount}
                  isHsnCode={true}
                  taxType="payable"
                  lineItemBaseAccount={"Incomes~Liabilities"}
                  discountBaseAccount="Expenses"
                  conversionDate={conversionDate}
                  newConversionDate={newConversionDate}
                  conversionCurrencyId={conversionCurrencyId}
                  userId={customerId}
                  componentType={"invoice"}
                  setErrorOnItemTaxChange={setErrorOnItemTaxChange}
                  setAddonErrorOnItemTaxChange={setAddonErrorOnItemTaxChange}
                  setChargeErrorOnItemTaxChange={setChargeErrorOnItemTaxChange}
                  totalTdsValues={totalTdsValues}
                  totalTcsValues={totalTcsValues}
                  tdsIds={tdsIds}
                  tcsIds={tcsIds}
                  planTdsIds={planTdsIds}
                  addonTdsIds={addonTdsIds}
                  chargeTdsIds={chargeTdsIds}
                  ref={itemRef}
                  stateType={stateType}
                  supplyState={supplyState}
                  getTotalValue={getTotalValues}
                  gstRegistrationType={gstRegistrationType}
                  isRoundOffEnabled={isRoundOffEnabled}
                  isTotalEditable={isTotalEditable}
                  editTotal={editTotal}
                  updateCalculatedTotal={updateCalculatedTotal}
                  hideExchangeRate={false}
                  customerDetails={defaultFormValues}
                  isStateChanged={isStateChanged}
                  isCustomerVendorChanged={isCustomerChanged}
                  selectedInvoicingCycle={setSelectedInvoicingCycle}
                  setNextInvoiceDateDetails={setNextInvoiceDateDetails}
                  changedCustomerData={changedCustomerData}
                  tdsPreferencetoCheck={savedTDSPreference}
                  basicDetailsNextInvoice={basicDetailsNextInvoice}
                  setEditTotalDetails={setEditTotalDetails}
                  editTotalDetails={editTotalDetails}
                  couponConfig={couponConfig || {}}
                  getNextInvoicePayload={getNextInvoicePayload}
                >
                  <NoteTextArea
                    label="Customer Notes"
                    name="customer_notes"
                    id="customerNotes"
                    className="notes-textarea"
                    placeholder="Enter notes here..."
                    value={subscriptionFormValues.customer_notes || ""}
                    onChange={handleChange}
                    formErrors={formErrors}
                  />
                </SubscriptionDetail>
                <section id="upload_section">
                  <div className="upload-header pb-3">Attach Files</div>
                  <DropZone
                    uploadedFiles={uploadedFiles}
                    uploadFileIds={fileIds}
                    uploadAttachedFiles={uploadAttachedFiles}
                    removeFile={removeFile}
                    handleSubmitAction={handleSubmitAction}
                    ref={fileRef}
                  />
                  <span className="error">{formErrors.attachFiles}</span>
                </section>
                <div className="email-reciepients-wrapper">
                  <Grid container sx={{ maxWidth: "682px" }}>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={7}
                      sx={{ alignSelf: "flex-end" }}
                    >
                      <label htmlFor="deposit_to">Email to</label>
                    </Grid>
                    <Grid
                      item
                      columns={2}
                      xs={10}
                      sm={10}
                      md={5}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <FormControlLabel
                        className="emailEnableAndDisable"
                        control={
                          <Switch
                            checked={isSendEmailToggleEnable}
                            color="primary"
                            disableRipple
                          />
                        }
                        label="Automatic Reminder Emails"
                        labelPlacement="start"
                        onClick={(event) => {
                          event.stopPropagation();
                          setIsSendEmailToggleEnable(!isSendEmailToggleEnable);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <MultiEmailBox
                    onChange={setEmailHandler}
                    emailList={emailLists}
                    removeEmailHandler={removeEmailHandler}
                  />
                  <span className="error">{formErrors.email}</span>
                </div>
                <SubscriptionOrganizationDetails />
                {nextInvoiceDateDetails && !editId ? (
                  <SubscriptionPreview
                    previewDetails={nextInvoiceDateDetails}
                    currencyId={currencyId}
                    roundoffValue={
                      itemRef.current ? itemRef.current.roundOffValue : null
                    }
                    invoicePreviewPayload={{
                      ...invoicePreviewPayload,
                      ...nextInvoicePayload,
                    }}
                    tdsLevel={tdsPreference.apply_tds_at}
                  />
                ) : null}
              </div>
              <SubscriptionFormFooter
                editId={editId}
                isLocked={false}
                lockDate={""}
                name="subscription"
                isFormSubmit={formSubmit && formSubmitAction}
                onClickSubmit={asynchronousSubmit}
                rolePermission={invoicesRolePermission}
                isPrint={true}
                isDisabledDraft={
                  exchangeRateAPiStatus ||
                  defaultRef.current?.defaultData?.customer_id === "" ||
                  isSubscriptionChecking
                    ? true
                    : false
                }
                isDisabledSave={
                  defaultRef.current?.defaultData?.customer_id === "" ||
                  isSubscriptionChecking
                    ? true
                    : false
                }
                onClickDelete={() => {}}
              />
            </div>
          </Form>
        </CardBody>
      </Card>
      {showAddressModal ? (
        <AddressModal
          closeAddressModal={closeAddressModal}
          addressId={addressId}
          createdFrom="subscription_page"
          fetchConsumerDetail={fetchConsumerDetail}
          consumerId={customerId}
          consumerName={
            String(defaultFormValues.customer_name)?.length > 5 &&
            String(defaultFormValues.customer_name)?.includes("(") &&
            String(defaultFormValues.customer_name)?.includes(")")
              ? String(defaultFormValues.customer_name)?.slice(0, -5)
              : String(defaultFormValues.customer_name)
          }
          selectedBillingAddressId={selectedBillingAddressId}
          billingAddressFormValues={billingAddressFormValues}
          storeNewAddressIdHandler={() => {}}
        />
      ) : null}
      <SubscriptionUpdateAlertModal
        onCancel={() => {
          setDidAlertModalOpen(false);
        }}
        onSubmit={(updatedData) => {
          handleSubmit(buttonAction.status, buttonAction.action, updatedData);
        }}
        alertModalOpen={didAlertModalOpen}
        isSubscriptionStatusFuture={subscriptionStatus === "FUTURE"}
        nextRenewableDate={nextRenewableDate}
        isPlanUpdated={
          subscriptionValuesForEdit.plans.planId !==
            formDetailForNexInvoice.planId &&
          subscriptionValuesForEdit.plans.pricing_id !==
            formDetailForNexInvoice.pricing_id
        }
        nextInvoiceDateDetails={nextInvoiceDateDetails}
        getNextInvoiceAction={(date, invoicingOption, is_proration_enabled) =>
          getNextInvoiceAction(
            date,
            formDetailForNexInvoice.planId,
            formDetailForNexInvoice.pricing_id,
            formDetailForNexInvoice.total,
            invoicingOption,
            is_proration_enabled,
            Number(editId),
            false,
            defaultFormValues.invoice_generation,
            onlyCouponChangeFlag
            // exchangeRateValues.timestamp.split(" ")[0]
          )
        }
        subscriptionStopDate={subscriptionStopDate}
        currencyCode={currencyCode}
        subscriptionStatus={subscriptionStatus}
        subscriptionCancelDate={subscriptionCancelDate}
        totalTaxableAmount={itemRef.current?.totalTaxableAmount}
        initialTotalAmount={editTotalDetails.total_taxable_amount}
        subscriptionEndDate={subscriptionEndDate}
        subscription_id={Number(editId)}
        setChargedItems={setChargedItems}
        invoicePreviewPayload={{
          ...invoicePreviewPayload,
          ...nextInvoicePayload,
        }}
        tdsLevel={subscriptionFormValues.tds_level}
        difference={difference}
      />
    </>
  );
}

export default React.memo(SubscriptionForm);
