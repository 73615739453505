import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBankAccountCategoryDetails, getBankAccounts } from "./BankingApi";

export const Dummy = () => {};

export const bankAccountCategoryDetails = createAsyncThunk(
  "banking/getBankAccountCategoryDetails",
  async (orgId: number) => {
    return getBankAccountCategoryDetails(orgId);
  }
);

export const fetchBankAccount = createAsyncThunk(
  "fetch-bank-accounts",
  async (data: { pageNum: number; pageSize: number; orgId: number }) => {
    return await getBankAccounts(data);
  }
);
