import { json } from "stream/consumers";
import { Api } from "../../../constants";
import {
  get,
  postFormData,
  put,
  post,
  remove,
} from "../../../helpers/apiHelper";
import {
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
  UpdateNextInvoiceDateValues,
} from "../../../types";

/**
 * API call to get plan list
 */

export async function getSubscriptionList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  orgIds: number[];
  statusList: string[];
  CustomerList: number[];
  frequency: string[];
}) {
  let url =
    Api.subscription +
    "list" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`;

  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }

  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }

  if (data.CustomerList.length !== 0) {
    const customerIds = data.CustomerList.map((customerId) => {
      return `&customer_ids=${customerId}`;
    }).join("");
    url += customerIds;
  }

  if (data.frequency.length !== 0) {
    const frequencies = data.frequency
      .map((frequency) => {
        return `&frequency_list=${frequency}`;
      })
      .join("");
    url += frequencies;
  }
  const result = await get(url);
  return result;
}

export async function subscriptionsList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  orgIds: number[];
  statusList: string[];
  CustomerList: number[];
  frequency: string[];
}) {
  return await post(
    Api.subscription + "list" + `?current_organization=${data.orgId}`,
    {
      page_num: data.page,
      page_size: data.itemsPerPage,
      date_sort_order: data.dateSortOrder,
      org_ids: data.orgIds,
      status_list: data.statusList,
      customer_ids: data.CustomerList,
      frequency_list: data.frequency,
    }
  );
}

/**
 * API call to update plan status (stop/resume/void)
 */
export async function updateSubscriptionStatus(data: {
  subscriptionId: number;
  status: string;
  orgId: number;
  change_status_option?: string;
  specific_date?: any;
  unbilled_charge?: string;
  scheduler_id?: number;
}) {
  const result = await put(
    Api.subscription +
      "change-status" +
      `/${data.subscriptionId}?current_organization=${data.orgId}${
        data.scheduler_id ? `&scheduler_id=${data.scheduler_id}` : ""
      }`,
    {
      status: data.status,
      change_status_option: data.change_status_option,
      specific_date: data.specific_date,
      charge_option: data.unbilled_charge,
    }
  );
  return result;
}

/**
 * API call to delete plan
 */

export async function deleteSubscription(data: {
  deleteSubscriptionId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      "delete" +
      `/${data.deleteSubscriptionId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * API call to delete plan
 */

export async function deletePricing(data: {
  deletePricingId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.plan +
      "pricing/delete" +
      `/${data.deletePricingId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * Api to get the transaction limit status
 */
export async function planTransactionLimitStatus(orgId: number) {
  const result = await get(
    Api.plan + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call to get plan dropdown list
 */

export async function getSubscriptionPlanDropdownList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  searchQuery: string;
  signal?: AbortSignal | undefined;
}) {
  let url =
    Api.plan +
    "dropdown/plans" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId}${
      data.searchQuery ? "&plan_name=" + data.searchQuery : ""
    }`;

  const result = await get(url, data.signal);
  return result;
}

/**
 * API call to get plan fequency dropdown list
 */

export async function getSubscriptionPlanFrequencyList(data: {
  planId: string;
  orgId: number;
  currencyId: number;
}) {
  let url =
    Api.plan +
    `dropdown/billing-frequency/${Number(data.planId)}?current_organization=${
      data.orgId
    }&currency_id=${data.currencyId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get selected plan frequency Item details
 */

export async function getSubscriptionPlanFrequencyItems(data: {
  planId: number;
  pricingId: number;
  orgId: number;
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.plan +
    `get-plan-item-detail?current_organization=${data.orgId}&plan_id=${data.planId}&pricing_id=${data.pricingId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get Addon dropdown list
 */

export async function getSubscriptionAddonDropdownList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  frequency: string;
  custom_frequency_count: number;
  addonIds: number[];
  searchQuery: string;
  signal?: AbortSignal;
}) {
  let url =
    Api.addon +
    "dropdown/addons" +
    `?frequency=${data.frequency}&custom_frequency_count=${
      data.custom_frequency_count
    }&page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId}${
      data.searchQuery ? "&addon_name=" + data.searchQuery : ""
    }`;

  if (data.addonIds.length !== 0) {
    const addonId = data.addonIds
      .map((value) => {
        return `&addons_id=${value}`;
      })
      .join("");
    url += addonId;
  }
  const result = await get(url, data.signal);
  return result;
}

export async function getSubscriptionChargesDropdownList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  frequency: string;
  chargeIds: number[];
  isForDropdown: boolean;
  searchQuery: string;
  signal?: AbortSignal;
}) {
  let url =
    Api.charges +
    "list" +
    `?frequency=${data.frequency}&page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId}&org_ids=${data.orgId}${
      data.searchQuery ? "&charge_name=" + data.searchQuery : ""
    }&for_dropdown=${data.isForDropdown ? data.isForDropdown : false}`;

  if (data.chargeIds.length !== 0) {
    const chargeId = data.chargeIds
      .map((value) => {
        return `&charge_ids=${value}`;
      })
      .join("");
    url += chargeId;
  }
  const result = await get(url, data.signal);
  return result;
}

/**
 * API call to get plan fequency dropdown list
 */

export async function getSubscriptionAddonFrequencyList(data: {
  addonId: string;
  orgId: number;
  frequency: string;
  custom_frequency_count: number;
  currencyId: number;
}) {
  let url =
    Api.addon +
    `dropdown/billing-frequency/${Number(data.addonId)}?current_organization=${
      data.orgId
    }&frequency=${data.frequency}&custom_frequency_count=${
      data.custom_frequency_count
    }&currency_id=${data.currencyId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get selected addon frequency Item details
 */

export async function getSubscriptionAddonFrequencyItems(data: {
  addonId: number;
  pricingId: number;
  orgId: number;
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.addon +
    `get-addon-item-detail?current_organization=${data.orgId}&addon_id=${data.addonId}&pricing_id=${data.pricingId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  const result = await get(url);
  return result;
}

export async function getSubscriptionChargeFrequencyItems(data: {
  chargeId: number;
  orgId: number;
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.charges +
    `get-charge-item-detail?current_organization=${data.orgId}&charge_id=${data.chargeId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get Coupon Search list
 */

export async function getSubscriptionCouponList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  searchTerm: string;
  couponIds: number[];
  currencyId: number;
  conversionId?: number;
  discountType: string;
}) {
  let url =
    Api.coupon +
    "dropdown" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}&currency_id=${data.currencyId}`;

  if (data.conversionId) {
    const searchTerm = `&conversion_id=${data.conversionId}`;
    url += searchTerm;
  }

  if (data.searchTerm && data.searchTerm !== "") {
    const searchTerm = `&coupon_name=${data.searchTerm}`;
    url += searchTerm;
  }

  if (data.couponIds.length !== 0) {
    const couponId = data.couponIds
      .map((value) => {
        return `&coupon_id=${value}`;
      })
      .join("");
    url += couponId;
  }

  if (!!data.discountType) {
    url += `&discount_type=${data.discountType}`;
  }
  const result = await get(url);
  return result;
}
export async function subscriptionTransactionLimitStatus(orgId: number) {
  const result = await get(
    Api.subscription + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

export async function getChildInvoiceList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  invoiceId: number;
  orgId: number;
}) {
  let url =
    Api.salesInvoice +
    "invoice/list" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&recurring_invoice_id=${data.invoiceId}&current_organization=${data.orgId}`;
  //${data.invoiceId}

  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  const result = await get(url);

  return result;
}

/**
 * API call for getting invoice detail
 */
export async function getSubscriptionOverviewDetails(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await get(
    Api.subscription +
      `overview/${data.subscriptionId}?current_organization=${data.orgId}`
    //${data.subscriptionId}24
  );
  return result;
}

export async function getSubscriptionDataForInvoice(data: {
  invoiceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.salesInvoice +
      Api.invoice +
      `/subscription-data/${data.invoiceId}?current_organization=${data.orgId}`
  );
  return result;
}

export async function saveConfigurationProration(data: {
  orgId: number;
  isProrationEnabled: boolean;
}) {
  const result = await put(
    Api.subscription +
      "update-proration-preference" +
      `?current_organization=${data.orgId}`,
    {
      organization_id: data.orgId,
      is_proration_enabled: data.isProrationEnabled,
    }
  );
  return result;
}

export async function getProrationPreference(data: { orgId: number }) {
  const result = await get(
    Api.subscription +
      `get-proration-preference?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting subscription scheduler details
 */
export async function getSubscriptionSchedulerDetails(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await get(
    Api.subscription +
      `scheduler/details/${data.subscriptionId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to upload subscription attachments
 */
export async function subscriptionAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.subscription +
      `subscriptions-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * Api to delete subscription attachments
 */
export async function subscriptionAttachmentsDelete(data: {
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      `subscriptions-attachments/${data.fileId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for get subscription number
 */
export async function getSubscriptionNumber(orgId: number) {
  const result = await get(
    Api.subscription + `new-subscription-number?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for calculating taxable amount
 */

export async function calculateTaxableAmount(data: {
  values: TaxableAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.subscription + `item/taxable-amount?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call for calculating invoice amount
 */

export async function calculateAmount(data: {
  values: TotalAmountCalculationValues;
  conversionDate: string;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.subscription + `amount-calculation?current_organization=${data.orgId}`,
    { ...data.values, conversion_date: data.conversionDate },
    data.signal
  );
  return result;
}

/**
 * API call for creating subscription
 */
export async function createNewSubscription(data: {
  values: any;
  orgId: number;
}) {
  const result = await post(
    Api.subscription + `create-subscription?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for checking the subscription number exist or not
 */
export async function subscriptionNumberExist(data: {
  subscriptionNumber: string;
  orgId: number;
  subscriptionId: number | null;
}) {
  const encodedName = encodeURIComponent(data.subscriptionNumber);
  const result = await get(
    Api.subscription +
      `subscription-number-exist?subscription_number=${encodedName}&subscription_id=${data.subscriptionId}&current_organization=${data.orgId}`
  );
  return result;
}

export async function nextInvoiceDate(data: {
  invoicingoption: string;
  orgId: number;
  pricing_id: number;
  plan_id: number;
  start_date: string;
  total_amount: number;
  invoicing_cycle?: string;
  invoicing_cycle_fixed_count?: number;
  is_proration_enabled?: boolean;
  subscription_id?: number;
  in_subscription_create?: boolean;
  invoice_generation?: string;
  onlyCouponChange?: boolean;
  total_details?: any;
  coupon_list?: number[];
  conversion_id?: number;
  coupon_configuration?: any;
  updatedItemList?: [];
  charge_item_list?: [];
  currency_id: number | null;
  conversion_date: string;
}) {
  let url =
    Api.subscription +
    `next-invoice-details?in_subscription_create=${data.in_subscription_create}&current_organization=${data.orgId}`;

  if (data.subscription_id) {
    url += `&subscription_id=${data.subscription_id}`;
  }

  const payload = {
    invoicingoption: data.invoicingoption,
    orgId: data.orgId,
    pricing_id: data.pricing_id,
    plan_id: data.plan_id,
    start_date: data.start_date,
    total_amount: data.total_amount,
    invoicing_cycle: data.invoicing_cycle,
    invoicing_cycle_fixed_count: data.invoicing_cycle_fixed_count,
    is_proration_enabled: data.is_proration_enabled,
    subscription_id: data.subscription_id,
    in_subscription_create: data.in_subscription_create,
    invoice_generation: data.invoice_generation,
    onlyCouponChange: data.onlyCouponChange,
    total_details: data.total_details,
    coupon_list: data.coupon_list,
    conversion_id: data.conversion_id,
    coupon_configuration: data.coupon_configuration,
    updated_item_list: data.updatedItemList,
    charge_item_list: data.charge_item_list,
    currency_id: data.currency_id,
    conversion_date: data.conversion_date,
  };

  const result = await post(url, payload); // Assuming 'post' is a function for making POST requests
  return result;
}

/**
 * API call for geting next invoice date for the subscription
 */
// export async function nextInvoiceDate(data: {
//   invoicingoption: string;
//   orgId: number;
//   pricing_id: number;
//   plan_id: number;
//   start_date: string;
//   total_amount: number;
//   invoicing_cycle?: string;
//   invoicing_cycle_fixed_count?: number;
//   is_proration_enabled?: boolean;
//   subscription_id?: number;
//   in_subscription_create?: boolean;
//   invoice_generation?: string;
//   onlyCouponChange?: boolean;
//   total_details?: any;
//   coupon_list?: number[];
//   conversion_id?: number;
//   coupon_configuration?: any;
//   updatedItemList?: [];
// }) {
//   let url =
//     Api.subscription +
//     `next-invoice-date?invoicingoption=${data.invoicingoption}&plan_id=${data.plan_id}&pricing_id=${data.pricing_id}&start_date=${data.start_date}&in_subscription_create=${data.in_subscription_create}&invoice_generation=${data.invoice_generation}&total_amount=${data.total_amount}&current_organization=${data.orgId}`;

//   if (data.subscription_id) {
//     url += `&subscription_id=${data.subscription_id}`;
//   }

//   if (data.invoicing_cycle) {
//     url += `&invoicing_cycle=${data.invoicing_cycle}`;
//   }

//   if (
//     data.invoicing_cycle &&
//     data.invoicing_cycle_fixed_count &&
//     data.invoicing_cycle === "FIXED"
//   ) {
//     url += `&invoicing_cycle_fixed_count=${data.invoicing_cycle_fixed_count}`;
//   }

//   if (data.onlyCouponChange) {
//     url += `&only_coupon_change=${data.onlyCouponChange}`;
//   }

//   if (data.conversion_id) {
//     url += `&conversion_id=${data.conversion_id}&coupon_list=${
//       data.coupon_list
//     }&coupon_configuration=${JSON.stringify(data.coupon_configuration)}`;
//   }

//   if (data.total_details) {
//     url += `&gst_total=${data.total_details.total_details.gst_total}&total_taxable_amount=${data.total_details.total_details.total_taxable_amount}&tax_amount=${data.total_details.total_details.tax_amount}&total=${data.total_details.total_details.total}`;
//   }

//   url += `&proration=${data.is_proration_enabled}`;

//   const result = await get(url);
//   return result;
// }

/**
 * API call for getting invoice edit detail
 */
export async function getSubscriptionDetails(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await get(
    Api.subscription +
      `edit-details/${data.subscriptionId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for  edit subscription
 */
export async function editSubscription(data: {
  id: number;
  editInvoiceInputs: any;
  orgId: number;
}) {
  const result = await put(
    Api.subscription +
      `edit-subscription/${data.id}?current_organization=${data.orgId}`,
    data.editInvoiceInputs
  );
  return result;
}

/**
 * API call for  cancel scheduler
 */
//
export async function cancelSchedulerHandler(data: {
  schedulerId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      `cancel/scheduler/${data.schedulerId}?current_organization=${data.orgId}`
  );
  return result;
}

export async function removeCancelSchedulerHandler(data: {
  orgId: number;
  schedulerId: number;
  comments: string;
}) {
  const result = await put(
    Api.subscription +
      `remove-cancel-scheduler/?current_organization=${data.orgId}`,
    {
      scheduler_id: data.schedulerId,
      comments: data.comments,
    }
  );
  return result;
}

/**
 * API call to get restore plan/addon frequency Item details
 */

export async function getSubscriptionItemsDetails(data: {
  itemId: number;
  pricingId: number;
  orgId: number;
  currencyId: number;
  conversionId: number;
  isPlan: boolean;
}) {
  let url =
    Api.subscription +
    `reset-pricing?current_organization=${data.orgId}&is_plan=${data.isPlan}&pricing_id=${data.pricingId}&item_id=${data.itemId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get selected addon frequency converted Item details
 */
export async function getSubscriptionAddonConvertedItems(data: {
  addonId: number[];
  pricingId: number[];
  orgId: number;
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.addon +
    `get-addon-detail-list?current_organization=${data.orgId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  if (data.addonId.length !== 0) {
    const addonId = data.addonId
      .map((addonId) => {
        return `&addon_id=${addonId}`;
      })
      .join("");
    url += addonId;
  }
  if (data.pricingId.length !== 0) {
    const pricingId = data.pricingId
      .map((pricingId) => {
        return `&pricing_id=${pricingId}`;
      })
      .join("");
    url += pricingId;
  }
  const result = await get(url);
  return result;
}

export async function getSubscriptionChargeConvertedItems(data: {
  chargeId: number[];
  pricingId: number[];
  orgId: number;
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.charges +
    `get-charge-detail-list?current_organization=${data.orgId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  if (data.chargeId.length !== 0) {
    const chargeId = data.chargeId
      .map((chargeId) => {
        return `&addon_id=${chargeId}`;
      })
      .join("");
    url += chargeId;
  }
  if (data.pricingId.length !== 0) {
    const pricingId = data.pricingId
      .map((pricingId) => {
        return `&pricing_id=${pricingId}`;
      })
      .join("");
    url += pricingId;
  }
  const result = await get(url);
  return result;
}

/**
 * API call to get converted Coupon list
 */

export async function getConvertedCouponList(data: {
  orgId: number;
  couponIds: number[];
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.coupon +
    "get_coupons_list" +
    `?current_organization=${data.orgId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  if (data.couponIds.length !== 0) {
    const couponId = data.couponIds
      .map((value) => {
        return `&coupon_id=${value}`;
      })
      .join("");
    url += couponId;
  }

  const result = await get(url);
  return result;
}

/**
 * API to cancel the susbcription
 */

//11111
export async function cancelSubscription(data: {
  orgId: number;
  cancelData: any;
}) {
  let url =
    Api.subscription +
    "cancel-subscription" +
    `?current_organization=${data.orgId}`;

  const result = await put(url, data.cancelData);
  return result;
}

/**
 * API to reactivate the susbcription
 */

export async function reactivateSubscription(data: {
  orgId: number;
  reactivationData: any;
}) {
  let url =
    Api.subscription +
    "reactivate-subscription" +
    `?current_organization=${data.orgId}`;

  const result = await put(url, data.reactivationData);
  return result;
}

/**
 * API call for getting subscription scheduler details
 */
export async function getUnbilledChargesListDetails(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await get(
    Api.subscription +
      `unbilled-charges/list/${data.subscriptionId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to delete unbilled charges
 */

export async function deleteUnbilledCharges(data: {
  deleteUnbilledId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      "unbilled-charges/delete" +
      `/${data.deleteUnbilledId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * API call to invoice unbilled charges now
 */

export async function invoiceUnbilledChargesNow(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await post(
    Api.subscription +
      "unbilled-charges/invoice-now" +
      `/${data.subscriptionId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * API call for delete subscription item tax
 */
export async function deleteSubscriptionItemTax(data: {
  itemId: number;
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      "invoice-item-tax/delete/" +
      data.itemId +
      "/" +
      data.taxId +
      `?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to regenerate void invoices
 */
export async function regenerateVoidInvoicesAPI(data: {
  orgId: number;
  subscriptionId: number;
  invoiceStartDate: string;
  invoiceEndDate: string;
  prorationEnabled?: boolean;
  includeCharges?: boolean;
  invoicingOptions?: any;
}) {
  let url =
    Api.subscription +
    `regenerate-invoice/${data.subscriptionId}?current_organization=${data.orgId}&invoice_start_date=${data.invoiceStartDate}&invoice_end_date=${data.invoiceEndDate}`;

  if (data.prorationEnabled) {
    const prorationEnabled = `&proration_enabled=${data.prorationEnabled}`;
    url += prorationEnabled;
  }
  if (data.invoicingOptions) {
    const invoicingOptions = `&invoicing_option=${data.invoicingOptions}`;
    url += invoicingOptions;
  }
  const includeCharges = `&include_charges=${data.includeCharges}`;
  url += includeCharges;
  const result = await post(url);
  return result;
}

export async function getCancelActionDetails(data: {
  credit_note: string;
  include_cancellation_date: boolean;
  subscription_id: number;
  manage_unbilled_charges: string | null;
  unused_credits_days: number;
  cancel_status_option: string;
  cancel_date: string;
  apply_refundable_to_unpaid: boolean;
  orgId: number;
}) {
  let url =
    Api.subscription +
    "cancel-impact" +
    `/${data.subscription_id}?cancel_status_option=${data.cancel_status_option}&cancel_date=${data.cancel_date}&credit_note=${data.credit_note}&unused_credits_days=${data.unused_credits_days}&current_organization=${data.orgId}`;

  if (data.include_cancellation_date) {
    url += `&include_cancellation_date=${data.include_cancellation_date}`;
  }
  if (data.manage_unbilled_charges) {
    url += `&manage_unbilled_charges=${data.manage_unbilled_charges}`;
  }

  if (data.apply_refundable_to_unpaid) {
    url += `&apply_refundable_to_unpaid=${data.apply_refundable_to_unpaid}`;
  }
  const result = await get(url);

  return result;
}

export async function previewSubscriptionInvoice(data: {
  values: any;
  orgId: number;
  subscriptionId?: number;
}) {
  const result = await post(
    `${Api.subscription}invoice-preview?current_organization=${data.orgId}${
      data.subscriptionId ? "&subscription_id=" + data.subscriptionId : ""
    }`,
    data.values
  );
  return result;
}

/**
 * API call for getting subscription scheduler details
 */

export async function getPreviewDetails(data: {
  subscriptionId: number;
  invoicingOption: string;
  proratedCredits: boolean;
  nextInvoiceDate: string;
  orgId: number;
}) {
  const result = await get(
    Api.subscription +
      `get-preview-details?subscription_id=${data.subscriptionId}&invoicing_option=${data.invoicingOption}&prorated_credits=${data.proratedCredits}&next_invoice_date=${data.nextInvoiceDate}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for update next invoice date
 */

export async function updateNextInvoiceDate(data: {
  values: UpdateNextInvoiceDateValues;
  orgId: number;
}) {
  const result = await post(
    Api.subscription +
      `edit-next-invoice-date?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
