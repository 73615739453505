import { DateFormatHandler } from "../../../../../helpers/helper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { coustomCaseWithRemoveUnderscore } from "../../../../../helpers/planHelper";
import { formateDateMonth } from "../../../../../helpers/subscriptionHelper";
import { ObjectType } from "../../../../../types";
import InfoTag from "../../../../common/components/InfoTag";
import useCommonData from "../../../../hooks/useCommon";
import { NextInvoiceDateType } from "../../subscriptionType";
import { SubscriptionInvoiceBreakdown } from "../view/SubscriptionInvoiceBreakdown";
import "./subscriptionPreview.css";

type props = {
  previewDetails: NextInvoiceDateType;
  currencyId: number | undefined;
  roundoffValue?: any;
  invoicePreviewPayload: any;
  tdsLevel: string;
};
function SubscriptionPreview({
  previewDetails,
  currencyId,
  roundoffValue,
  invoicePreviewPayload,
  tdsLevel,
}: props) {
  const { orgCurrencyList } = useCommonData();
  const isInvoicePreviewEnabled = process.env.REACT_APP_INVOICE_PREVIEW_ENABLED;
  const currencyCode =
    orgCurrencyList.filter((item: ObjectType) => {
      return item.id === currencyId;
    })[0]?.code || "";

  const getIsPlanOnly = () => {
    if (invoicePreviewPayload) {
      if (
        invoicePreviewPayload?.addon_item_list?.length > 0 ||
        invoicePreviewPayload?.charge_item_list?.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <>
      {previewDetails.subscription_status ? (
        <div className="subscription-invoice-preview-status">
          <InfoTag
            status={coustomCaseWithRemoveUnderscore(
              previewDetails.subscription_status
            )}
          />
          {["IN_TRIAL", "FUTURE"].includes(
            previewDetails.subscription_status
          ) ? (
            <div className="p-t-15">
              Subscription activates on{" "}
              {previewDetails.subscription_activates_on
                ? formateDateMonth(
                    DateFormatHandler(previewDetails.subscription_activates_on)
                  )
                : ""}
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        className={`subscription-org subscription-preview-note-wrapper subscription-preview-container
      ${
        previewDetails.subscription_status === "NON_RENEWING" ||
        previewDetails.second_invoice_amount === null
          ? "non-renewing-note-wrapper"
          : ""
      }`}
      >
        <div className="note-header pb-2">Upcoming Invoices</div>
        <div className="subscription-preview-cart-container">
          <div className="subscription-preview-cart">
            <div className="subscription-preview-cart-header-container">
              <div className="subscription-preview-cart-header subscription-preview-cart-header-heading">
                First Invoice
              </div>
              <div className="subscription-preview-cart-header">
                {/* <a href="#">View Preview</a> */}
              </div>
            </div>
            <div className="subscription-preview-cart-data-cols">
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                Amount
              </div>
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                {`${currencyCode} ${NumberFormat(
                  Number(previewDetails.first_invoice_amount),
                  currencyCode,
                  orgCurrencyList
                )}`}
              </div>
            </div>
            <div className="subscription-preview-cart-data-cols">
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                Invoice Date
              </div>
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                {formateDateMonth(
                  previewDetails.first_invoicing_date
                    ? DateFormatHandler(previewDetails.first_invoicing_date)
                    : ""
                )}
              </div>
            </div>
            <div className="subscription-preview-cart-data-cols">
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                Period
              </div>
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                {`${formateDateMonth(
                  DateFormatHandler(previewDetails.first_invoice_start_date)
                )} to ${formateDateMonth(
                  DateFormatHandler(previewDetails.first_invoice_end_date)
                )}`}
              </div>
            </div>
            {isInvoicePreviewEnabled === "true" && (
              <SubscriptionInvoiceBreakdown
                invoiceIndex="first"
                tdsLevel={tdsLevel}
                taxes={{}}
                currencyCode={currencyCode}
                payload={{ ...invoicePreviewPayload }}
                isPlanOnly={getIsPlanOnly()}
              />
            )}
          </div>
          {previewDetails.subscription_status === "NON_RENEWING" ||
          previewDetails.second_invoice_amount === null ? null : (
            <div className="subscription-preview-cart">
              <div className="subscription-preview-cart-header-container">
                <div className="subscription-preview-cart-header subscription-preview-cart-header-heading">
                  Next Invoice
                </div>
                <div className="subscription-preview-cart-header">
                  {/* <a href="#">View Preview</a> */}
                </div>
              </div>
              <div className="subscription-preview-cart-data-cols">
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                  Amount
                </div>
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                  {`${currencyCode} ${NumberFormat(
                    Number(
                      previewDetails.second_invoice_amount +
                        Number(
                          roundoffValue
                            ? Math.round(roundoffValue * 100) / 100
                            : 0
                        )
                    ),
                    currencyCode,
                    orgCurrencyList
                  )}`}
                </div>
              </div>
              <div className="subscription-preview-cart-data-cols">
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                  Next Invoice Date
                </div>
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                  {formateDateMonth(
                    // DateFormatHandler(previewDetails.second_invoice_start_date)
                    DateFormatHandler(previewDetails.second_invoice_date)
                  )}
                </div>
              </div>
              <div className="subscription-preview-cart-data-cols">
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                  Period
                </div>
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                  {`${formateDateMonth(
                    DateFormatHandler(previewDetails.second_invoice_start_date)
                  )} to ${formateDateMonth(
                    DateFormatHandler(previewDetails.second_invoice_end_date)
                  )}`}
                </div>
              </div>
              {isInvoicePreviewEnabled === "true" && (
                <SubscriptionInvoiceBreakdown
                  invoiceIndex="next"
                  tdsLevel={tdsLevel}
                  taxes={{}}
                  currencyCode={currencyCode}
                  payload={{ ...invoicePreviewPayload }}
                  isPlanOnly={getIsPlanOnly()}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SubscriptionPreview;
