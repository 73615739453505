import { useEffect, useRef, useState } from "react";
import Select, { InputActionMeta, SingleValue, components } from "react-select";
import "../../../../index.css";
import { ObjectType } from "../../../../types";
import { colors } from "../../../constants/colors";
import { Container } from "../htmlTags/Container";
import InfoTooltip from "../InfoTooltip";
import { SkeletonLoader } from "../skeletonLoader/SkeletonLoader";
import "./FormSelectField.css";

type SelectFieldProps = {
  name: string;
  id: string;
  options: {
    label: string;
    value: number | string;
  }[];
  className: string;
  isDisabled: boolean;
  value:
    | {
        label: string;
        value: number | string;
      }
    | "";
  defaultValue?:
    | {
        label: string;
        value: number | string;
      }
    | "";
  onChange: (
    e: SingleValue<"" | { label: string; value: number | string }>
  ) => void;
  styles: ObjectType;
  placeholder: string;
  isSearchable: boolean;
  error: string | undefined;
  label: string;
  isRequired: boolean;
  isOnlyInRow: boolean;
  wrapperClass?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  handleClearSelection?: () => void;
  tooltipMessage?: string | undefined;
  messagePlacement?: any | undefined;
  dropdownCustomMessage?: string;
  onMenuScrollToBottom?: () => void;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  count: number;
  inputValue?: string;
  onInputChange?:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
};
export const FormSelectFieldWithScroll = (props: SelectFieldProps) => {
  const [isMenuListOpen, setIsMenuListOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   if (isMenuListOpen) {
  //   const handleScroll = (event: any) => {
  //     if (menuRef.current) {
  //       const { scrollTop, scrollHeight, clientHeight } = menuRef.current;
  //       if (Math.ceil(scrollTop) + clientHeight >= scrollHeight && !loading) {
  //         if (props.onMenuScrollToBottom) {
  //           setLoading(true);
  //           props.onMenuScrollToBottom()
  //         }
  //       }
  //     }
  //   };

  //   const menuNode = document.querySelector('.select-cust-scroll__menu-list');
  //   if (menuNode) {
  //     menuRef.current = menuNode as HTMLDivElement;
  //     menuRef.current.addEventListener('scroll', handleScroll);
  //   }

  //   // Clean up the event listener on component unmount or menu close
  //   return () => {
  //     if (menuRef.current) {
  //       menuRef.current.removeEventListener('scroll', handleScroll);
  //     }
  //   }}
  // });

  useEffect(() => {
    if (props.count > 0) {
      setLoading(false);
    }
  }, [props.count]);
  const CustomMenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        <div className="custom-menu-list">{props.children}</div>
      </components.MenuList>
    );
  };
  return (
    <div
      className={`select-field-wrap col ${
        props.wrapperClass ? props.wrapperClass : ""
      } ${props.isOnlyInRow === true ? "row" : ""}`}
    >
      <div className="relative-wrapper">
        <label
          htmlFor={props.id}
          className={props.isRequired ? "fw-bold required" : "fw-bold"}
        >
          {props.label}
        </label>
        {props?.tooltipMessage ? (
          <InfoTooltip
            title={props.tooltipMessage}
            placement={props?.messagePlacement || "top"}
          />
        ) : null}
        <Select
          name={props.name}
          id={props.id}
          classNamePrefix="select-cust-scroll"
          options={props.options}
          className={`resusable-form-select-field  ${props.className} ${
            props.error ? "error" : ""
          }`}
          isDisabled={props.isDisabled}
          onMenuClose={() => {
            setIsMenuListOpen(false);
            props.onMenuClose && props.onMenuClose();
          }}
          onMenuOpen={() => {
            setIsMenuListOpen(true);
            props.onMenuOpen && props.onMenuOpen();
          }}
          captureMenuScroll
          value={
            Object.values(props.value || {}).some((val) => val !== "")
              ? props.value
              : ""
          }
          onMenuScrollToBottom={props.onMenuScrollToBottom}
          inputValue={props.inputValue}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          styles={{ ...props.styles }}
          placeholder={props.placeholder}
          isSearchable={props.isSearchable}
          onInputChange={props.onInputChange}
          components={{
            MenuList: (menuProps) =>
              props.isLoading ? (
                <Container>
                  {Array.from({ length: 3 }).map(() => {
                    return (
                      <Container style={{ margin: "10px" }}>
                        <SkeletonLoader
                          animation="wave"
                          width="100%"
                          color={colors.warmWhite}
                          variant="rectangular"
                          height="20px"
                        />
                      </Container>
                    );
                  })}
                </Container>
              ) : (
                <CustomMenuList {...menuProps} />
              ),
          }}
          isClearable={props.isClearable === true ? true : false}
          isLoading={props.isLoading === true ? true : false}
          noOptionsMessage={() =>
            props.dropdownCustomMessage
              ? props.dropdownCustomMessage
              : "No options"
          }
        />
      </div>
      <span className="error">{props.error}</span>
    </div>
  );
};
