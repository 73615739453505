import { getFormatedDate } from "../../../../../helpers/helper";
import { DeferredItem } from "../../../../../types";
import { sourceTaxTypes, tdsApplyLevel } from "../../../../constants/constants";

export const initialDefaultValues = {
  customer_id: "",
  customer_name: "",
  invoice_number: "",
  invoice_date: getFormatedDate(new Date()),
  reference_number: "",
  payment_term_id: "",
  due_date: getFormatedDate(new Date()),
  tag_ids: [],
  currency_id: "",
  place_of_supply: "",
  invoice_status: "",
  is_mutable: true,
};
export const initialTaxValues = {
  tax_id: "",
  tax_percentage: "",
};
export const initialItemValues = {
  account_id: "",
  account_name: "",
  item_name: "",
  hsn_or_sac_code: "",
  quantity: 1.0,
  rate: 0.0,
  discount: 0.0,
  discount_type: "Absolute",
  discount_account_id: "",
  discount_account_name: "",
  taxes: [initialTaxValues],
  inventory_item_id: null,
  tds_id: null,
  tds_amount: 0,
  tds_account_id: 0,
  tds_percentage: 0,
  tds_name: "",
  gst_tax: null,
  currency_id: null,
  item_exemption_type: "",
  item_exemption_id: "",
};
export const initialInvoiceValues = {
  customer_id: "",
  customer_name: "",
  invoice_number: "",
  invoice_date: getFormatedDate(new Date()),
  payment_term_id: "",
  due_date: getFormatedDate(new Date()),
  customer_notes: "",
  tag_ids: [],
  items: [initialItemValues],
  email_to: [],
  conversion_id: "",
  currency_id: "",
  file_ids: [],
  tds_percentage: 0,
  reference_number: "",
  is_locked: false,
  lock_date: "",
  custom_fields: {},
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  source_tax_type: sourceTaxTypes.TCS,
  place_of_supply: "",
};
export const initialTotalValues = {
  total_taxable_amount: 0,
  tax_amount: 0,
  total: 0,
  taxes: [],
  tds_percentage: 0,
  transaction_tds_amount: 0,
};

export const billingEmptyObject = {
  country_id: null,
  address: null,
  city: null,
  state_id: null,
  zip_code: null,
  phone: null,
  is_billing: true,
  is_shipping: false,
};

export const shippingEmptyObject = {
  country_id: null,
  address: null,
  city: null,
  state_id: null,
  zip_code: null,
  phone: null,
  is_billing: false,
  is_shipping: true,
};
export const shippingEmptyFormvalues = {
  country_id: 0,
  address: "",
  city: "",
  state_id: 0,
  zip_code: null,
  phone: "",
  is_billing: false,
  is_shipping: false,
  is_primary: false,
  state_name: "",
  country_name: "",
};
export const billingEmptyFormValues = {
  country_id: 0,
  address: "",
  city: "",
  state_id: 0,
  zip_code: null,
  phone: "",
  is_billing: false,
  is_shipping: false,
  is_primary: false,
  state_name: "",
  country_name: "",
};

export const initialTDSTotalValues = {
  tds_id: null,
  tds_amount: 0,
  tds_percentage: 0,
  tds_account_id: 0,
  tds_name: "",
  default_tds_percentage: 0
};

export const initialTCSTotalValues = {
  tcs_id: null,
  tcs_amount: 0,
  tcs_percentage: 0,
  tcs_account_id: 0,
  tcs_name: "",
  default_tcs_percentage: 0
};
