import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import { CurrencyFormType, ObjectType } from "../../../../types";

const amountFormatter = (
  amount: string,
  currencyCode: string,
  orgCurrencyList: CurrencyFormType[]
) => {
  if (amount?.split(" ").length > 1) {
    return NumberFormat(
      Number(amount.split(" ")[1]),
      currencyCode,
      orgCurrencyList
    );
  } else if (amount?.split(" ").length === 1 && !Number.isNaN(amount)) {
    return NumberFormat(Number(amount), currencyCode, orgCurrencyList);
  } else {
    return "";
  }
};

const customerDetailsSetter = (searchResult: ObjectType) => {
  const {
    customer_display_name,
    short_code,
    customer_email,
    customer_phone,
    customer_id,
    organization_name,
  } = searchResult;

  return {
    id: customer_id,
    "Customer Name": customer_display_name,
    "Short Code": short_code,
    Email: customer_email,
    Phone: customer_phone,
    Organization: organization_name,
  };
};

const vendorDetailsSetter = (searchResult: ObjectType) => {
  const {
    vendor_display_name,
    short_code,
    tag_name,
    vendor_email,
    vendor_phone,
    vendor_id,
    organization_name,
  } = searchResult;

  return {
    id: vendor_id,
    "Vendor Name": vendor_display_name,
    "Short Code": short_code,
    Tags: Array.isArray(tag_name) ? tag_name.join(", ") : "",
    Email: vendor_email,
    Phone: vendor_phone,
    "Organization Name": organization_name,
  };
};

const invoiceDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    invoice_id,
    invoice_number,
    reference_number,
    tag_name,
    customer_name,
    invoice_date,
    invoice_status,
    due_date,
    total,
    total_taxable_amount,
    balance_due,
    tax_deducted,
    sales_invoice_billing_address,
    sales_invoice_shipping_address,
    created_by_user_name,
    organization_name,
    currency_code,
  } = searchResult;

  return {
    "Invoice Number": invoice_number,
    "Reference Number": reference_number,
    Tags: Array.isArray(tag_name) ? tag_name.join(", ") : "",
    "Customer Name": customer_name,
    "Invoice Date": invoice_date,
    Status: invoice_status,
    "Due Date": due_date,
    "Billing Address": sales_invoice_billing_address,
    "Shipping Address": sales_invoice_shipping_address,
    "Created by": created_by_user_name,
    Organization: organization_name,
    "Tax Deducted": currency_code
      ? currency_code +
        " " +
        amountFormatter(tax_deducted, currency_code, orgCurrencyList)
      : tax_deducted,
    Total: currency_code
      ? currency_code +
        " " +
        amountFormatter(total, currency_code, orgCurrencyList)
      : total,
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        amountFormatter(total_taxable_amount, currency_code, orgCurrencyList)
      : total_taxable_amount,
    "Balance Due": currency_code
      ? currency_code +
        " " +
        amountFormatter(balance_due, currency_code, orgCurrencyList)
      : balance_due,
    id: invoice_id,
  };
};

const billDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    bill_id,
    bill_date,
    bill_number,
    reference_number,
    due_date,
    tag_name,
    vendor_name,
    organization_name,
    total,
    total_taxable_amount,
    amount_due,
    tax_deducted,
    currency_code,
    bill_status,
  } = searchResult;

  return {
    "Bill Number": bill_number,
    "Reference Number": reference_number,
    Tags: Array.isArray(tag_name) ? tag_name.join(", ") : "",
    "Vendor Name": vendor_name,
    "Organization Name": organization_name,
    Total: currency_code
      ? currency_code +
        " " +
        amountFormatter(total, currency_code, orgCurrencyList)
      : total,
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        amountFormatter(total_taxable_amount, currency_code, orgCurrencyList)
      : total_taxable_amount,
    "Tax Deducted(TDS)": currency_code
      ? currency_code +
        " " +
        amountFormatter(tax_deducted, currency_code, orgCurrencyList)
      : tax_deducted,
    "Balance Due": currency_code
      ? currency_code +
        " " +
        amountFormatter(amount_due, currency_code, orgCurrencyList)
      : amount_due,
    "Bill Date": bill_date,
    Status: bill_status,
    "Due Date": due_date,
    id: bill_id,
  };
};

const creditNoteDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    credit_note_id,
    credit_note_number,
    reference_number,
    customer_name,
    credit_note_date,
    credit_note_status,
    invoice_number,
    credit_note_balance,
    total,
    currency_code,
    total_taxable_amount,
    organization_name,
  } = searchResult;

  return {
    "Credit Note Number": credit_note_number,
    "Reference Number": reference_number,
    "Customer Name": customer_name,
    "Credit Note Date": credit_note_date,
    "Credit Note Balance": currency_code
      ? currency_code +
        " " +
        amountFormatter(credit_note_balance, currency_code, orgCurrencyList)
      : credit_note_balance,
    "Invoice Numbers":
      invoice_number && Array.isArray(invoice_number)
        ? invoice_number.join(",")
        : "",
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        amountFormatter(total_taxable_amount, currency_code, orgCurrencyList)
      : total_taxable_amount,
    Organization: organization_name,
    Total: currency_code
      ? currency_code +
        " " +
        amountFormatter(total, currency_code, orgCurrencyList)
      : total,
    Status: credit_note_status,
    id: credit_note_id,
  };
};

const debitNoteDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    debit_note_id,
    debit_note_number,
    reference_number,
    vendor_name,
    debit_note_date,
    debit_note_status,
    organization_name,
    bill_number,
    debit_note_balance,
    total,
    currency_code,
    total_taxable_amount,
  } = searchResult;

  return {
    "Debit Note Number": debit_note_number,
    "Reference Number": reference_number,
    "Vendor Name": vendor_name,
    "Organization Name": organization_name,
    Status: debit_note_status,
    "Debit Note Date": debit_note_date,
    "Bill Numbers":
      bill_number && Array.isArray(bill_number) ? bill_number.join(",") : "",
    id: debit_note_id,
    Total: currency_code
      ? currency_code +
        " " +
        amountFormatter(total, currency_code, orgCurrencyList)
      : total,
    "Debit Note Balance": currency_code
      ? currency_code +
        " " +
        amountFormatter(debit_note_balance, currency_code, orgCurrencyList)
      : debit_note_balance,
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        amountFormatter(total_taxable_amount, currency_code, orgCurrencyList)
      : total_taxable_amount,
  };
};
const coaDetailSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  return {
    id: searchResult.account_id,
    "Account Name": searchResult.account_name,
    "Ledger Code": searchResult.ledger_code,
    "Account Type": searchResult.account_type,
    Description: searchResult.description,
  };
};
const budgetDetailSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  return {
    id: searchResult.budget_id,
    "Budget Name": searchResult.name,
    "Start Date": searchResult.start_date,
    "Organization Name": searchResult.organization_name,
    "Created By User": searchResult.created_by_user_name,
   
  };
};
const prDetailSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    payment_received_id,
    payment_number,
    payment_date,
    customer_name,
    currency_code,
    pr_status,
    reference_number,
    invoice_number,
    amount_received,
    organization_name,
    amount_in_excess,
    tax_deducted,
  } = searchResult;

  return {
    id: payment_received_id,
    "Customer Name": customer_name,
    Status: pr_status,
    "Payment Number": payment_number,
    "Payment Date": payment_date,
    "Amount Received": currency_code
      ? currency_code +
        " " +
        amountFormatter(amount_received, currency_code, orgCurrencyList)
      : amount_received,
    "Amount Excess": currency_code
      ? currency_code +
        " " +
        amountFormatter(amount_in_excess, currency_code, orgCurrencyList)
      : amount_in_excess,
    "Tax Deducted(TDS)": currency_code
      ? currency_code +
        " " +
        amountFormatter(tax_deducted, currency_code, orgCurrencyList)
      : tax_deducted,
    "Organization Name": organization_name,
    "Invoice Numbers": Array.isArray(invoice_number)
      ? invoice_number.join(", ")
      : "",
    "Reference Number": reference_number,
  };
};
const pmDetailSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    payment_made_id,
    payment_number,
    organization_name,
    payment_date,
    vendor_name,
    paid_through,
    bill_number,
    pm_status,
    currency_code,
    amount_paid,
    amount_in_excess,
    reference_number,
  } = searchResult;

  return {
    id: payment_made_id,
    "Vendor Name": vendor_name,
    "Payment Number": payment_number,
    "Payment Date": payment_date,
    Status: pm_status,
    "Paid Through Account": paid_through,
    "Organization Name": organization_name,
    "Amount Paid": currency_code
      ? currency_code +
        " " +
        amountFormatter(amount_paid, currency_code, orgCurrencyList)
      : amount_paid,
    "Amount Excess": currency_code
      ? currency_code +
        " " +
        amountFormatter(amount_in_excess, currency_code, orgCurrencyList)
      : amount_in_excess,
    "Bill Numbers":
      bill_number && Array.isArray(bill_number) ? bill_number.join(", ") : "",
    "Reference Number": reference_number,
  };
};
const jvDetailSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    journal_voucher_id,
    journal_voucher_number,
    reference_number,
    journal_status,
    journal_voucher_date,
    currency_amount,
    notes,
    created_by_user_name,
    organization_name,
  } = searchResult;
  return {
    id: journal_voucher_id,
    "Journal Voucher Number": journal_voucher_number,
    Status: journal_status,
    Date: journal_voucher_date,
    Amount: currency_amount,
    Notes: notes,
    Reference: reference_number,
    "Organization Name": organization_name,
    "Created By User": created_by_user_name,
  };
};

const recurringInvDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    recurring_invoice_id,
    profile_name,
    reference_number,
    customer_name,
    currency_code,
    recurring_invoice_status,
    frequency,
    ends_on,
    organization_name,
    recurring_invoice_billing_address,
    recurring_invoice_shipping_address,
    total,
    total_taxable_amount,
    tax_deducted,
    created_by_user_name,
    last_invoice_date,
    next_invoice_date,
    start_on,
  } = searchResult;

  return {
    "Profile Name": profile_name,
    "Reference Number": reference_number,
    "Customer Name": customer_name,
    Frequency: frequency,
    "Last Invoice Date": last_invoice_date,
    "Next Invoice Date": next_invoice_date,
    Status: recurring_invoice_status,
    "Start Date": start_on,
    "End Date": ends_on,
    "Billing Address": recurring_invoice_billing_address,
    "Shipping Address": recurring_invoice_shipping_address,
    "Created by": created_by_user_name,
    Organization: organization_name,
    "Tax Deducted": currency_code
      ? currency_code +
        " " +
        amountFormatter(tax_deducted, currency_code, orgCurrencyList)
      : tax_deducted,
    Amount: currency_code
      ? currency_code +
        " " +
        amountFormatter(total, currency_code, orgCurrencyList)
      : total,
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        amountFormatter(total_taxable_amount, currency_code, orgCurrencyList)
      : total_taxable_amount,
    id: recurring_invoice_id,
  };
};
const proformaInvDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    proforma_invoice_id,
    proforma_number,
    reference_number,
    customer_name,
    currency_code,
    organization_name,
    issue_date,
    expiry_date,
    total,
    total_taxable_amount,
    proforma_status,
    proforma_shipping_address,
    proforma_billing_address,
    item_name,

  } = searchResult;

  return {
    "Proforma Number": proforma_number,
    "Reference Number": reference_number,
    "Customer Name": customer_name,
    Status: proforma_status,
    Organization: organization_name,
    "Issue Date": issue_date,
    "Expiry Date": expiry_date,
    "Proforma Shipping Address":proforma_shipping_address,
    "Proforma Billing Address":proforma_billing_address,
    Items: Array.isArray(item_name) ? item_name.join(", ") : "",
    Amount: currency_code
      ? currency_code +
        " " +
        amountFormatter(total, currency_code, orgCurrencyList)
      : total,
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        amountFormatter(total_taxable_amount, currency_code, orgCurrencyList)
      : total_taxable_amount,
    id: proforma_invoice_id,
  };
};

const planDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    plan_id,
    plan_external_name,
    plan_status,
    organization_name,
    item_name,
    last_modified,
    plan_internal_name,
   
  } = searchResult;

  return {
    "Plan External Name": plan_external_name,
    "Plan Internal Name": plan_internal_name,
    Status: plan_status,
    Items: Array.isArray(item_name) ? item_name.join(", ") : "",
    Organization: organization_name,
    "Last Modified Date": last_modified,
    id: plan_id,
  };
};

const addonDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    addon_id,
    addon_external_name,
    addon_status,
    organization_name,
    item_name,
    last_modified,
    addon_internal_name,

  } = searchResult;

  return {
    "Addon External Name": addon_external_name,
    "Addon Internal Name": addon_internal_name,
    Status: addon_status,
    Items: Array.isArray(item_name) ? item_name.join(", ") : "",
    Organization: organization_name,
    "Last Modified Date": last_modified,
    id: addon_id,
  };
};

const chargeDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    charges_id,
    charges_external_name,
    charges_status,
    organization_name,
    item_name,
    last_modified,
    charges_internal_name,

  } = searchResult;

  return {
    "Charge External Name": charges_external_name,
    "Charge Internal Name": charges_internal_name,
    Status: charges_status,
    Items: Array.isArray(item_name) ? item_name.join(", ") : "",
    Organization: organization_name,
    "Last Modified Date": last_modified,
    id: charges_id,
  };
};

const couponDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    coupon_id,
    name,
    coupon_status,
    organization_name,
    account_name,
    discount,
    duration,
    validity_date,
  } = searchResult;

  return {
    "Coupon Name": name,
    Status: coupon_status,
    "Account Name": account_name,
    Organization: organization_name,
    id: coupon_id,
    Discount: discount,
    Duration: duration,
    "Validity Date": validity_date,
  };
};

const subscriptionDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    subscription_id,
    subscription_number,
    subscription_status,
    currency_code,
    subscription_period,
    customer_name,
    created_by_user_name,
    created_date,
    organization_name,
    start_date,
    shipping_address,
    billing_address,
    plan_name,
    mrr,
    next_renewal_date,
  } = searchResult;

  return {
    "Subscription Number": subscription_number,
    "Customer Name": customer_name,
    "Subscription Period": subscription_period,
    "Next Renewal": next_renewal_date,
    Status: subscription_status,
    "Start Date": start_date,
    "Billing Address": billing_address,
    "Shipping Address": shipping_address,
    "Created by": created_by_user_name,
    Organization: organization_name,
    "Created Date":created_date,
    "Plan Name": Array.isArray(plan_name)
    ? plan_name.join(", ")
    : "",

    MRR: currency_code
      ? currency_code +
        " " +
        amountFormatter(mrr, currency_code, orgCurrencyList)
      : mrr,
    id: subscription_id,
  };
};

export const searchDetailsSetter = (
  searchResult: ObjectType,
  module: string,
  orgCurrencyList: CurrencyFormType[]
) => {
  switch (module) {
    case "customers":
      return customerDetailsSetter(searchResult);
    case "vendors":
      return vendorDetailsSetter(searchResult);
    case "invoices":
      return invoiceDetailsSetter(searchResult, orgCurrencyList);
    case "bills":
      return billDetailsSetter(searchResult, orgCurrencyList);
    case "credit_notes":
      return creditNoteDetailsSetter(searchResult, orgCurrencyList);
    case "debit_notes":
      return debitNoteDetailsSetter(searchResult, orgCurrencyList);
    case "payment_received":
      return prDetailSetter(searchResult, orgCurrencyList);
    case "payment_made":
      return pmDetailSetter(searchResult, orgCurrencyList);
    case "journal_voucher":
      return jvDetailSetter(searchResult, orgCurrencyList);
    case "chart_of_accounts":
      return coaDetailSetter(searchResult, orgCurrencyList);
    case "recurring_invoices":
      return recurringInvDetailsSetter(searchResult, orgCurrencyList);
    case "plans":
      return planDetailsSetter(searchResult, orgCurrencyList);
    case "addons":
      return addonDetailsSetter(searchResult, orgCurrencyList);
    case "coupons":
      return couponDetailsSetter(searchResult, orgCurrencyList);
    case "subscription":
      return subscriptionDetailsSetter(searchResult, orgCurrencyList);
    case "charges":
       return chargeDetailsSetter(searchResult,orgCurrencyList)
    case "budget":
        return budgetDetailSetter(searchResult,orgCurrencyList)
    case "proforma_invoices":
      return proformaInvDetailsSetter(searchResult,orgCurrencyList)
    default:
      throw new Error(`Module '${module}' is not supported.`);
  }
};
