import React, { useEffect } from "react";
import Select from "react-select";
import {
  customerDropdownList,
  customerListSelector,
} from "../../home/customerManagement/customerSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { customSelectStyle } from "./SelectCustomStyle";
import { currentUserSelector } from "../commonSlice";
import CustomOption from "./ui/formElements/FormSelect/CustomOption";

type Props = {
  customerId: string | number;
  errors: string;
  onChange: (e: { label: string; value: string | number }) => void;
  isDisabled?: boolean;
};
function CustomerSelect({ customerId, errors, onChange, isDisabled }: Props) {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const customerList = useAppSelector(customerListSelector);

  useEffect(() => {
    getSearchCustomersList();
  }, [currentUserInfo.organization_id]);

  const getSearchCustomersList = async () => {
    await dispatch(
      customerDropdownList({
        orgId: currentUserInfo.organization_id,
        searchQuery: "",
        pageNum: 1,
        pageSize: 500,
        customerId: null,
      })
    );
  };
  /**
   * Get the list of all customers
   */
  const customerListOptions = customerList.map((customer, index) => ({
    label: customer.customer_display_name,
    value: customer.id,
    import_id: customer?.import_id,
  }));

  /**
   * Get the customer name in the selection dropdown : Edit invoice
   */
  const customerName = customerList
    .filter((customer) => customer.id === Number(customerId))
    .map((customer) => ({
      label: customer.customer_display_name,
      value: customer.id,
    }));

  const customFilterOption = (option: any, inputValue: any) => {
    return (
      option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      (option.data?.import_id &&
        option.data.import_id
          .toLowerCase()
          .includes(inputValue.toLowerCase())) ||
      false
    );
  };
  return (
    <Select
      name="customer_id"
      id="customer_id"
      value={customerName}
      options={customerListOptions}
      placeholder="Choose customer"
      onChange={(e) => {
        if (e) onChange({ label: e?.label, value: e?.value });
      }}
      className={`form-select custom-select customer-select customer_name select-field select-field ${
        errors ? "error" : ""
      }`}
      styles={customSelectStyle}
      isDisabled={isDisabled}
      filterOption={customFilterOption}
      components={{
        Option: (props) => (
          <CustomOption {...props} className={""} name={"customer"} />
        ),
      }}
    />
  );
}

export default CustomerSelect;
