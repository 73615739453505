import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getForexGainOrLossAmount } from "../../../../../../../helpers/transactionHelper";
import { InvoiceToAllocate, ObjectType } from "../../../../../../../types";
import { setLoaderState } from "../../../../../../common/commonSlice";
import useCommonData from "../../../../../../hooks/useCommon";
import useForexCalculation from "../../../../../../hooks/useForexCalculation";
import {
  creditAllocationCalculation,
  getInvoicesToAllocate,
  updateCreditAllocation,
} from "../../../../creditNoteSlice";
import { FormikProps } from "formik";
import { SuccessToaster } from "../../../../../../../helpers/toastHelper";

export const useApplyToInvoice = () => {
  const { getForexGainOrLoss } = useForexCalculation();
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const [error, setError] = useState(false);
  const { editId } = useParams();
  const getInvoiceForex = async (
    sourceConversionId: number,
    targetConversionId: number,
    amount: number,
    invoiceId: number,
    index: number,
    formik: FormikProps<any>,
    forex?: ObjectType
  ) => {
    const forexAmount = await getForexGainOrLoss(
      sourceConversionId,
      targetConversionId,
      amount ? amount : 0,
      invoiceId,
      "CreditNote",
      true,
      forex
    );
    formik.setFieldValue(
      `invoice_allocate_list.${index}.forex_amount`,
      forexAmount
    );
  };

  /**
   * credit note allocation calculation
   */
  const calculateAllocationAmount = async (
    invoiceToAllocate: InvoiceToAllocate[],
    formik: FormikProps<any>,
    signal: AbortSignal
  ) => {
    let input: number[] = [];
    invoiceToAllocate.map((invoice: InvoiceToAllocate) => {
      if (
        invoice.amount_credited &&
        invoice.amount_credited !== null &&
        invoice.amount_credited !== ""
      ) {
        input.push(Number(invoice.amount_credited));
      } else {
        input.push(0);
      }
    });
    if (input.length) {
      const responseAction = await dispatch(
        creditAllocationCalculation({
          creditNoteId: Number(editId),
          orgId: currentUserInfo.organization_id,
          values: input,
          signal: signal,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          formik.setFieldValue("amount_to_credit", response.amount_to_credit);
          formik.setFieldValue("credit_balance", response.remaining_credits);
        }
      }
    }
    if (
      formik.errors.invoice_allocate_list &&
      (formik.errors.invoice_allocate_list as ObjectType[])?.length > 0
    ) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const getInvoicesToAllocateCreditNote = async (formik: FormikProps<any>) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getInvoicesToAllocate({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        const tempInvoiceList: InvoiceToAllocate[] = Array.isArray(
          response.invoice_allocate_list
        )
          ? response.invoice_allocate_list
              ?.filter(
                (invoice: any) =>
                  Number(invoice.invoice_balance) !== 0 ||
                  Number(invoice.amount_credited) > 0
              )
              ?.map((invoice: InvoiceToAllocate) => {
                return {
                  ...invoice,
                  forex_amount: { [invoice.invoice_id]: invoice.forex_amount },
                };
              })
          : [];
        formik.setFieldValue("invoice_allocate_list", tempInvoiceList);
        formik.setFieldValue("credit_balance", response.credit_balance);
        formik.setFieldValue("balance", response.credit_balance);
      }
    }
    dispatch(setLoaderState(false));
  };

  const submitApplyToInvoiceAllocation = async (
    inputs: {
      amount_to_credit: number;
      id: number | null;
      invoice_id: number;
    }[],
    refreshCNDetails: () => void,
    onCloseModal: () => void
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      updateCreditAllocation({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
        values: inputs,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        SuccessToaster(
          "Credits applied to invoice(s)!",
          "credit-apply-success"
        );
        onCloseModal();
        refreshCNDetails();
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    }
  };

  return {
    getInvoiceForex,
    getInvoicesToAllocateCreditNote,
    calculateAllocationAmount,
    submitApplyToInvoiceAllocation,
    error,
  };
};
