import React from "react";
import "./TextButton.css";

export interface TextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Font size of the button text (e.g., "16px", "1rem").
   */
  fontSize?: string;

  /**
   * Text color of the button.
   */
  color?: string;

  /**
   * Font weight of the button text (e.g., "bold", "400").
   */
  fontWeight?: string;

  /**
   * Aria-label for accessibility, used when the button does not contain text.
   */
  ariaLabel?: string;

   /**
   * Text align for aligning text, used when the button text needs to have a particular alignment.
   */
  textAlign?: "left" | "right" | "center"
}

/**
 * A customizable text button component.
 *
 * @param {TextButtonProps} props - The props for the TextButton component.
 * @returns {JSX.Element} The rendered button element.
 */
export const TextButton = (props: TextButtonProps) => {
  const {
    children,
    className,
    ariaLabel,
    style,
    fontSize,
    fontWeight,
    name,
    color,
    textAlign,
    ...rest
  } = props;
  return (
    <button
      name={name}
      className={`reusable-text-button ${className || ""}`.trim()}
      {...rest}
      style={{
        ...style,
        fontSize: fontSize || "14px",
        color: color || "rgb(13, 90, 229)",
        fontWeight: fontWeight || "normal",
        textAlign: textAlign || "center",
      }}
      aria-label={ariaLabel || name}
    >
      {children} 
    </button>
  );
};
