import React from "react";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { useAppSelector } from "../../../../../app/hooks";
import { orgCurrencyListSelector } from "../../../../common/commonSlice";
import { Container } from "../../../../common/components/htmlTags/Container";
import Modal from "@mui/material/Modal";
import "../regenerateInvoice/RegenerateInvoice.css";

interface RegenerateInvoiceoverviewSubscriptionData {
  handleGenerate: () => void;
  handleClose: () => void;
  startDate: Date | null;
  endDate: string;
  overviewSubscriptionData?: any;
}

const formatToDisplayDate = (date: Date | string | null): string => {
  if (!date) return "N/A";
  const parsedDate = typeof date === "string" ? new Date(date) : date;
  return parsedDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

const RegenerateInvoicePreview = ({
  handleGenerate,
  handleClose,
  startDate,
  endDate,
  overviewSubscriptionData,
}: RegenerateInvoiceoverviewSubscriptionData) => {
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const renderTableRows = () => {
    const rows: Array<{
      itemName: string;
      qty: number;
      rate: number;
      discount: string | number;
      tds: number;
      tax: number;
      taxableAmount: number;
    }> = [];

    if (overviewSubscriptionData) {
      const {
        plan_details = [],
        charge_details = [],
        addon_details = [],
      } = overviewSubscriptionData;

      // Plan Details
      plan_details?.forEach((plan: any) => {
        plan.items?.forEach((item: any) => {
          rows.push({
            itemName: item.item_name,
            qty: parseFloat(item.quantity) || 0,
            rate: parseFloat(item.rate) || 0,
            discount:
              item.discount_type === "PERCENTAGE"
                ? `${parseFloat(item.discount) || 0}%`
                : parseFloat(item.discount) || 0,
            tds: parseFloat(item.tds_amount) || 0,
            tax: parseFloat(item.taxes[0]?.item_tax_amount) || 0,
            taxableAmount: parseFloat(item.taxable_amount) || 0,
          });
        });
      });

      // Charge Details
      charge_details
        ?.filter((charge: any) =>
          overviewSubscriptionData.charges_to_include.includes(charge.id)
        )
        ?.forEach((charge: any) => {
          charge.items?.forEach((item: any) => {
            rows.push({
              itemName: item.item_name,
              qty: parseFloat(item.quantity) || 0,
              rate: parseFloat(item.rate) || 0,
              discount:
                item.discount_type === "PERCENTAGE"
                  ? `${parseFloat(item.discount) || 0}%`
                  : parseFloat(item.discount) || 0,
              tds: parseFloat(item.tds_amount) || 0,
              tax: parseFloat(item.taxes[0]?.item_tax_amount) || 0,
              taxableAmount: parseFloat(item.taxable_amount) || 0,
            });
          });
        });

      // Addon Details
      addon_details?.forEach((addon: any) => {
        addon.items?.forEach((item: any) => {
          rows.push({
            itemName: item.item_name,
            qty: parseFloat(item.quantity) || 0,
            rate: parseFloat(item.rate) || 0,
            discount:
              item.discount_type === "PERCENTAGE"
                ? `${parseFloat(item.discount) || 0}%`
                : parseFloat(item.discount) || 0,
            tds: parseFloat(item.tds_amount) || 0,
            tax: parseFloat(item.taxes[0]?.item_tax_amount) || 0,
            taxableAmount: parseFloat(item.taxable_amount) || 0,
          });
        });
      });
    }

    return rows.map((row, index) => (
      <tr key={index}>
        <td>{row.itemName}</td>
        <td>{row.qty}</td>
        <td>{row.rate.toFixed(2)}</td>
        <td>{row.discount}</td>
        {/* <td>{row.tds.toFixed(2)}</td> */}
        <td>{Number(row.tax || 0).toFixed(2)}</td>
        <td>{row.taxableAmount.toFixed(2)}</td>
      </tr>
    ));
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="regenerate-invoice-title"
      aria-describedby="regenerate-invoice-description"
      className="regenerate-invoice-modal-wrapper"
    >
      <Container className="regenerate-invoice-preview-modal">
        <Container className="modal-header-regenerate-invoice">
          <h5 className="modal-title-regenerate-invoice">
            Preview of the invoice for the period from{" "}
            {formatToDisplayDate(startDate)} 0:00 to{" "}
            {formatToDisplayDate(endDate)} 23:59
          </h5>
        </Container>

        <Container className="modal-body-regenerate-invoice">
          <table className="preview-table-regenerating-invoices">
            <thead>
              <tr>
                <th>Items</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Discount</th>
                {/* <th>TDS</th> */}
                <th>Tax</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>

          <Container className="regenerate-invoice-summary-container">
            <Container className="calc-section">
              <Container className="subtotal-section pb-4">
                <Container>Total Taxable Amount</Container>
                <Container>
                  {overviewSubscriptionData.currency_code +
                    " " +
                    NumberFormat(
                      Number(
                        overviewSubscriptionData.total_details
                          .total_taxable_amount
                      ),
                      overviewSubscriptionData.currency_code
                        ? overviewSubscriptionData.currency_code
                        : "",
                      orgCurrencyList
                    )}
                </Container>
              </Container>
              <Container className="discount-section pb-4">
                <Container>Total Tax</Container>
                <Container>
                  {overviewSubscriptionData.currency_code +
                    " " +
                    NumberFormat(
                      Number(overviewSubscriptionData.total_details.tax_amount),
                      overviewSubscriptionData.currency_code
                        ? overviewSubscriptionData.currency_code
                        : "",
                      orgCurrencyList
                    )}
                </Container>
              </Container>
              {overviewSubscriptionData?.roundoff_enabled === true && (
                <Container className="discount-section pb-4">
                  <Container>Round Off</Container>
                  <Container>
                    {overviewSubscriptionData.currency_code +
                      " " +
                      NumberFormat(
                        Number(overviewSubscriptionData.roundOffValue),
                        overviewSubscriptionData.currency_code
                          ? overviewSubscriptionData.currency_code
                          : "",
                        orgCurrencyList
                      )}
                  </Container>
                </Container>
              )}
              {overviewSubscriptionData && (
                <Container className="discount-section pb-4">
                  <Container>Coupon</Container>
                  <Container>
                    -
                    {overviewSubscriptionData.currency_code +
                      " " +
                      NumberFormat(
                        Number(
                          overviewSubscriptionData.total_details.coupon_amount
                        ),
                        overviewSubscriptionData.currency_code
                          ? overviewSubscriptionData.currency_code
                          : "",
                        orgCurrencyList
                      )}
                  </Container>
                </Container>
              )}
            </Container>

            <Container className="total-calc ms-auto">
              <Container className="total-section pb-4">
                <Container>Total</Container>
                <Container>
                  {overviewSubscriptionData.currency_code +
                    " " +
                    NumberFormat(
                      Number(overviewSubscriptionData.total_details.total),
                      overviewSubscriptionData.currency_code
                        ? overviewSubscriptionData.currency_code
                        : "",
                      orgCurrencyList
                    )}
                </Container>
              </Container>
              {overviewSubscriptionData.children}
            </Container>
          </Container>
        </Container>

        <Container className="allocation-button-wrapper">
          <button
            type="button"
            className="btn save-button"
            onClick={handleGenerate}
          >
            Generate
          </button>
          <button
            type="button"
            className="btn cancel-button allocation-cancel"
            onClick={handleClose}
          >
            Cancel
          </button>
        </Container>
      </Container>
    </Modal>
  );
};

export default RegenerateInvoicePreview;
