import { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import Select, { components } from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ObjectType } from "../../../../types";
import { colors } from "../../../constants/colors";
import { fetchAccountsGlCode } from "../../../home/chartOfAccounts/coaSlice";
import { currentUserSelector } from "../../commonSlice";
import { Container } from "../htmlTags/Container";
import { customSelectStyle } from "../SelectCustomStyle";
import { SkeletonLoader } from "../skeletonLoader/SkeletonLoader";

type Props = {
  itemAccount: number | null;
  itemAccountName?: string;
  organizationId: number;
  handleItemAccountChange: (e: any, index: number, accountName: string) => void;
  itemIndex: number;
  error: string;
  errorMessage?: string;
  baseAccount?: string;
  currencyFilter?: number;
  accountFilter?: number[];
  className?: string;
  isDisabled?: boolean;
  name?: string;
  id?: string;
  wrapperClass?: string;
};
export const AccountSearchSelect = (props: Props) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [accountList, setAccountList] = useState<any>([]);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getSearchAccountList(searchQuery, signal).finally(() => {
      setIsLoading(false);
    });
    return () => {
      controller.abort();
    };
  }, [searchQuery]);

  const getSearchAccountList = useCallback(
    async (query: string, signal?: AbortSignal) => {
      try {
        const responseAction = await dispatch(
          fetchAccountsGlCode({
            accountSearchTerm: query,
            baseAccount: props.baseAccount ? props.baseAccount : "",
            orgId: props.organizationId,
            signal: signal,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            setIsLoading(false);
            let accounts: ObjectType[];
            if (props.currencyFilter) {
              accounts = response.account_list
                .filter((account: ObjectType) => {
                  return (
                    account.currency_id === props.currencyFilter ||
                    account.currency_id === null
                  );
                })
                .map((account: any) => {
                  return {
                    value: account.id,
                    label: account.account_name,
                    root: account.account_type,
                    currency_id: account.currency_id,
                    currency_code: account.currency_code,
                  };
                });
              setAccountList(accounts);
            } else if (props.accountFilter) {
              accounts = response.account_list
                .filter((account: ObjectType) => {
                  return (
                    account.currency_id === props.currencyFilter ||
                    account.currency_id === null
                  );
                })
                .map((account: any) => {
                  return {
                    value: account.id,
                    label: account.account_name,
                    root: account.account_type,
                    currency_id: account.currency_id,
                    currency_code: account.currency_code,
                  };
                });
              setAccountList(accounts);
            } else {
              accounts = response.account_list.map((account: any) => {
                return {
                  value: account.id,
                  label: account.account_name,
                  root: account.account_type,
                  currency_id: account.currency_id,
                  currency_code: account.currency_code,
                };
              });
              setAccountList(accounts);
            }
            return accounts;
          }
        }

        return [];
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to fetch customers:", error);
      }
    },
    [currentUserInfo.organization_id, dispatch]
  );
  const handleChange = (data: { value: string; label: string }) => {
    props.handleItemAccountChange(data.value, props.itemIndex, data.label);
  };

  const handleAccountSearch = useCallback((query: string) => {
    setIsLoading(true);
    setSearchQuery(query);
    setAccountList([]); // Reset options only when search query changes
  }, []);
  const LabelWithRoot = ({ data }: any) => {
    return (
      <div
        onClick={() => {
          handleChange({ value: data.value, label: data.label });
          setMenuIsOpen(false);
          setSearchQuery("")
        }}
        className="custom-select-option-wrapper"
        style={{
          backgroundColor:
            data.value === props.itemAccount ? colors.whisper : "white",
        }}
      >
        <span className="custom-select-option-account-name">{data.label}</span>
        {data.root && (
          <div className="custom-select-option-account-root">{data.root}</div>
        )}
      </div>
    );
  };
  const CustomMenuList = useMemo(() => {
    return (props: any) => {
      return (
        <components.MenuList {...props}>
          {isLoading
            ? Array.from({ length: 3 }).map(() => {
                return (
                  <Container className="mb-4">
                    <Container className="mb-2">
                      <SkeletonLoader
                        animation="wave"
                        width="100%"
                        color={colors.warmWhite}
                        variant="rounded"
                        height="15px"
                      />
                    </Container>
                    <Container className="mb-1">
                      <SkeletonLoader
                        animation="wave"
                        width="100%"
                        color={colors.warmWhite}
                        variant="rounded"
                        height="10px"
                      />
                    </Container>
                  </Container>
                );
              })
            : props.children}
          <div className="temp-div" ref={ref}></div>
        </components.MenuList>
      );
    };
  }, [
    searchQuery,
    isLoading,
    JSON.stringify(props),
    JSON.stringify(accountList),
  ]);
  return (
    <Select
      name="account_id"
      value={
        props.itemAccount
          ? { label: props.itemAccountName, value: props.itemAccount }
          : null
      }
      options={accountList}
      aria-label="Account Select"
      inputValue={searchQuery}
      className="custom-select customer-select account_name"
      isDisabled={props.isDisabled}
      isSearchable={true}
      maxMenuHeight={200}
      placeholder="Choose account"
      onChange={(e) =>
        handleChange({
          value: e ? String(e.value) : "",
          label: e ? String(e.label) : "",
        })
      }
      styles={customSelectStyle}
      onInputChange={handleAccountSearch}
      onMenuOpen={() => {
        setSearchQuery("");
        setMenuIsOpen(true);
        getSearchAccountList("");
      }}
      components={{ MenuList: CustomMenuList, Option: LabelWithRoot }}
      onMenuClose={() => setMenuIsOpen(false)}
      menuIsOpen={menuIsOpen}
    />
  );
};
