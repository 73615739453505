import React, { useEffect, useState, useRef, useCallback } from "react";
import { ArrowDropBlue, ArrowDropDown } from "../../../../assets/images";
import { useAuditTrail } from "./hooks/useAuditTrail";
import { Props, Row } from "./types/types";
import AuditLogTableContainer from "./AuditLogTableContainer";
import Loader from "../../../home/components/Loader";
import "./AuditTrail.css";

const AuditTrail: React.FC<Props> = ({
  title,
  transactionType,
  transactionId,
}) => {
  const [rows, setRows] = useState<Row[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [prevPageToken, setPrevPageToken] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const { auditTrail } = useAuditTrail();

  const fetchAuditData = useCallback(
    (pageToken: string | null) => {
      auditTrail({
        setHasMore,
        setIsLoading,
        setPrevPageToken,
        setRows,
        pageToken,
        rows,
        transactionId,
        transactionType,
      });
    },
    [auditTrail, transactionId, transactionType, rows]
  );

  const handleToggleVisibility = () => {
    setIsVisible((prev) => !prev);
    if (!isVisible) {
      fetchAuditData(null);
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchAuditData(null);
    }
  }, [fetchAuditData, isVisible]);

  const lastRowRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading || !hasMore) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            fetchAuditData(prevPageToken);
          }
        },
        { threshold: 1.0 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isLoading, hasMore, prevPageToken, fetchAuditData]
  );

  const headers = ["DATE & TIME", "ACTION", "UPDATED BY"];
  const viewDetailsText = "View Details";
  const hideDetailsText = "Hide Details";
  const viewDetailsImage = ArrowDropBlue;
  const detailsHeaders = ["DETAILS", "OLD VALUE", "NEW VALUE"];

  return (
    <div
      className={title === "Audit Trail" ? "audit-view-container" : ""}
      id="audit-view-container"
    >
      <div className="jv-postings-section">
        <div className="audit-trail-header" onClick={handleToggleVisibility}>
          <h2>{title}</h2>
          <img
            src={ArrowDropDown}
            className={`ArrowDropBlue ${isVisible ? "open" : "closed"}`}
          />
        </div>
        {isVisible && (
          <div className="table-section">
            {isLoading && <Loader />}
            <AuditLogTableContainer
              headers={headers}
              rows={rows}
              detailsColumn="details"
              viewDetailsText={viewDetailsText}
              hideDetailsText={hideDetailsText}
              viewDetailsImage={viewDetailsImage}
              detailsHeaders={detailsHeaders}
              lastRowRef={lastRowRef}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AuditTrail;
