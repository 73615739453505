import { FormikProps, FormikTouched } from "formik";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useAppSelector } from "../../../../../../app/hooks";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
import { zeroDisplayFormat } from "../../../../../../helpers/numberFormatHelper";
import {
  CurrencyList,
  JournalItemValues,
  ObjectType,
} from "../../../../../../types";
import CustomSearchSelect from "../../../../../common/components/CustomSearchSelect/CustomSearchSelect";
import { customSelectStyle } from "../../../../../common/components/SelectCustomStyle";
import { DeleteIcon } from "../../../../../common/components/customSvgIcons/deleteIcon";
import MultiExchangeRate from "../../../../../common/components/exchangeRate/multipleExchangeRate/MutipleExchangeRate";
import InputPrefix from "../../../../../common/components/formPrefixInputField/InputPrefix";
import { colors } from "../../../../../constants/colors";
import useCommonData from "../../../../../hooks/useCommon";
import {
  customerDropdownList,
  customerListSelector,
} from "../../../../customerManagement/customerSlice";
import {
  vendorDropdownList,
  vendorListSelector,
} from "../../../../vendorManagement/vendorSlice";
import { useJVExchange } from "../../hooks/useJVExchange";
import { FormSelectFieldWithScroll } from "../../../../../common/components/formSelectField/FormSelectFiledWithScroll";
import CustomerSelect from "../../../../../common/components/CustomerSelect/CustomerSelect";
import CustomerSelectWithCurrency from "../../../../../common/components/CustomerSelect/CustomerSelectWithCurrency";
import VendorSelectWithCurrency from "../../../../../common/components/VendorSelect/VendorSelectWithCurrency";

type Props = {
  index: number;
  item: JournalItemValues;
  handleItemAccountChange: (
    accountId: number,
    index: number,
    accountName: string
  ) => void;
  itemFormErrors: ObjectType[];
  isCurrencyChanged?: boolean;
  isDateChanged?: boolean;
  handleItemOnBlur: (name: string, amount: number, index: number) => void;
  onDeleteItemClick: (e: any) => void;
  getExchangeRateValues?: (values: CurrencyList, index: number) => void;
  currencyTriggerFlag: number;
  dateTriggerFlag: number;
  formik: FormikProps<any>;
};
function JournalItem(props: Props, ref?: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    currencyDateChangeHandler,
    fetchCustomConversions,
    userExchangeRateSubmitHandler,
    currencyChangeHandler,
  } = useJVExchange();
  const { editId } = useParams();
  const vendorsList = useAppSelector(vendorListSelector);
  //   const [initialLoad, setInitialLoad] = useState(true);
  const [exchangeRateValues, setExchangeRateValues] = useState<CurrencyList>({
    id: 1,
    to_currency: "",
    to_currency_id: 1,
    base_currency: "",
    base_currency_id: 1,
    timestamp: "",
    rate: 0,
    by_user: false,
  });
  const abortControllerRef = useRef<AbortController | null>(null);
  const customerList = useAppSelector(customerListSelector);
  const [customerListOptions, setCustomerListOptions] = useState<
    { label: string; value: string | number }[]
  >([]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };
  useEffect(() => {
    if (props.item.account_name === "Sundry Creditors") getSearchVendorsList();
  }, [currentUserInfo.organization_id]);
  useEffect(() => {
    if (props.item.conversion_id && !editId) {
      fetchCustomConversions(props.item.conversion_id, setExchangeData);
    }
  }, [props.item.conversion_id]);

  const getSearchVendorsList = async () => {
    await dispatch(vendorDropdownList(currentUserInfo.organization_id));
  };
  const setExchangeData = (values: CurrencyList) => {
    setExchangeRateValues(values);
    // if (editId) {
    //   props.getExchangeRateValues &&
    //     props.getExchangeRateValues(values, props.index);
    // }
  };

  /**
   * Get the vendor name in the selection dropdown : Edit Journal Voucher
   */
  const vendorName = (contactId: number | string) => {
    return vendorsList
      .filter((vendor) => vendor.id === contactId)
      .map((vendor) => ({
        label: vendor.vendor_display_name,
        value: vendor.id,
      }));
  };

  //Handling the customer dropown show properly by removing the overflow element
  const onMenuOpenHandler = () => {
    $(".jv-item-container .item-table-wrapper").css({ overflow: "initial" });
    // $(".jv-item-container table").css("margin-left", "-" + cust_vendLeft + "px");
  };
  //Adding the table overflow back on customer dropdown hide
  const onMenuCloseHandler = () => {
    $(".jv-item-container .item-table-wrapper").css({
      "overflow-x": "auto",
      "overflow-y": "hidden",
    });
    // $(".jv-item-container table").css("margin-left", 0);
  };
  const vendorCurrency = (contactId: number | string) => {
    let currencyDetails: any = {};
    currencyDetails = vendorsList.filter(
      (vendor) => vendor.id === contactId
    )[0];

    return {
      currencyId: currencyDetails.currency_id,
      currencyCode: currencyDetails.currency_code,
    };
  };
  const customerCurrency = (contactId: number | string) => {
    let currencyDetails: any = {};
    currencyDetails = customerList.filter(
      (customer: any) => customer.id === contactId
    )[0];
    return {
      currencyId: currencyDetails.currency_id,
      currencyCode: currencyDetails.currency_code,
    };
  };
  const LabelWithRoot = useMemo(() => {
    return (data: any) => {
      return (
        <div
          {...data.innerProps}
          className="custom-select-option-wrapper"
          style={{
            backgroundColor:
              Number(data.data.value) === Number(props.item.contact_id)
                ? colors.whisper
                : "white",
          }}
        >
          <span className="custom-select-option-account-name">
            {data.data.label}
          </span>
          {data.data.organization_name && (
            <div className="custom-select-option-account-root">
              {data.data.organization_name}
            </div>
          )}
        </div>
      );
    };
  }, [props.item.contact_id]);

  return (
    <>
      <tr key={`${props.index}`}>
        <td>
          <CustomSearchSelect
            id={"account" + props.index}
            name={`item_details.${props.index}.account_id`}
            itemAccount={Number(props.item.account_id)}
            itemAccountName={
              props.item.account_name ? props.item.account_name : ""
            }
            className={`${
              !props.formik.values.currency_id ? "disabled-item" : ""
            }`}
            organizationId={Number(currentUserInfo.organization_id)}
            handleItemAccountChange={(accountId, index, accountName) => {
              props.formik.setFieldValue(
                `item_details.${index}.account_id`,
                accountId
              );
              props.formik.setFieldValue(
                `item_details.${index}.account_name`,
                accountName
              );
              if (
                !["Sundry Creditors", "Sundry Debtors"].includes(accountName)
              ) {
                props.formik.setFieldValue(
                  `item_details.${props.index}.contact_id`,
                  null
                );
                props.formik.setFieldValue(
                  `item_details.${props.index}.currency_id`,
                  null
                );
                props.formik.setFieldValue(
                  `item_details.${props.index}.currency_code`,
                  ""
                );
                props.formik.setFieldValue(
                  `item_details.${props.index}.contact_type`,
                  null
                );
                props.formik.setFieldValue(
                  `item_details.${props.index}.contact_name`,
                  null
                );
                props.formik.setFieldValue(
                  `item_details.${props.index}.conversion_rate`,
                  ""
                );
                props.formik.setFieldValue(
                  `item_details.${props.index}.conversion_id`,
                  null
                );
              }
            }}
            itemIndex={props.index}
            error={
              props.itemFormErrors &&
              (props.formik?.touched.item_details as FormikTouched<any>[]) &&
              props.itemFormErrors[props.index]?.account_id
                ? "error"
                : ""
            }
            currencyFilter={props.formik.values.currency_id}
            isDisabled={!props.formik.values.currency_id}
            accountFilter={props.formik.values.accounts_for_sv}
          />
          {
            // (
            props.itemFormErrors &&
              props?.itemFormErrors[props.index]?.account_id &&
              (props.formik?.touched.item_details as FormikTouched<any>[]) && (
                <span className="error">
                  {props?.itemFormErrors[props.index]?.account_id}
                </span>
              )
          }
        </td>
        <td>
          <div className="custom-select-wrapper w-100">
            {props.item.account_name === "Sundry Debtors" ? (
              <>
                <CustomerSelectWithCurrency
                  isDisabled={
                    props.item.account_name === "Sundry Debtors" ||
                    props.item.account_name === "Sundry Creditors"
                      ? false
                      : true
                  }
                  customerId={props.item.contact_id}
                  customerName={props.item.contact_name}
                  error={
                    props.itemFormErrors &&
                    (props.formik?.touched
                      .item_details as FormikTouched<any>[]) &&
                    props.itemFormErrors[props.index]?.contact_id
                      ? "error"
                      : ""
                  }
                  onChange={(e) => {
                    if (e) {
                      props.formik.setFieldValue(
                        `item_details.${props.index}.contact_id`,
                        e.value
                      );
                      props.formik.setFieldValue(
                        `item_details.${props.index}.currency_id`,
                        e.currency_id
                      );
                      props.formik.setFieldValue(
                        `item_details.${props.index}.currency_code`,
                        e.currency_code
                      );
                      props.formik.setFieldValue(
                        `item_details.${props.index}.contact_type`,
                        props.item.account_name === "Sundry Debtors"
                          ? "Customer"
                          : props.item.account_name === "Sundry Creditors"
                          ? "Vendor"
                          : null
                      );
                      props.formik.setFieldValue(
                        `item_details.${props.index}.contact_name`,
                        e.label
                      );
                      currencyChangeHandler(
                        props.formik.values.currency_id,
                        (e?.currency_id as number) || null,
                        setExchangeData,
                        props.formik,
                        "jv_item",
                        props.index
                      );
                    }
                  }}
                />
              </>
            ) : props.item.account_name === "Sundry Creditors" ? (
              <VendorSelectWithCurrency
                isDisabled={
                  props.item.account_name === "Sundry Creditors" ? false : true
                }
                vendorId={props.item.contact_id}
                vendorName={props.item.contact_name}
                error={
                  props.itemFormErrors &&
                  (props.formik?.touched
                    .item_details as FormikTouched<any>[]) &&
                  props.itemFormErrors[props.index]?.contact_id
                    ? "error"
                    : ""
                }
                onChange={(e) => {
                  if (e) {
                    props.formik.setFieldValue(
                      `item_details.${props.index}.contact_id`,
                      e.value
                    );
                    props.formik.setFieldValue(
                      `item_details.${props.index}.currency_id`,
                      e.currency_id
                    );
                    props.formik.setFieldValue(
                      `item_details.${props.index}.currency_code`,
                      e.currency_code
                    );
                    props.formik.setFieldValue(
                      `item_details.${props.index}.contact_type`,
                      "Vendor"
                    );
                    props.formik.setFieldValue(
                      `item_details.${props.index}.contact_name`,
                      e.label
                    );
                    currencyChangeHandler(
                      props.formik.values.currency_id,
                      (e?.currency_id as number) || null,
                      setExchangeData,
                      props.formik,
                      "jv_item",
                      props.index
                    );
                  }
                }}
              />
            ) : (
              <Select
                id={"contact_id" + props.index}
                name={`item_details.${props.index}.contact_id`}
                value={null}
                options={[]}
                components={{
                  Option:
                    props.item.account_name === "Sundry Creditors"
                      ? LabelWithRoot
                      : undefined,
                }}
                placeholder="Choose"
                onChange={() => {}}
                className={`form-select custom-select customer-select mt-0`}
                styles={customSelectStyle}
                isDisabled={true}
              />
            )}
            {props.itemFormErrors &&
              (props.formik?.touched.item_details as FormikTouched<any>[]) &&
              props.itemFormErrors[props.index]?.contact_id && (
                <span className="error">
                  {props.itemFormErrors[props.index]?.contact_id}
                </span>
              )}

            {(props.item.account_name === "Sundry Debtors" ||
              props.item.account_name === "Sundry Creditors") &&
              props.item.currency_id !== 0 &&
              !Number.isNaN(props.item.currency_id) &&
              (props.formik.values.currency_id !== 0 ||
                !Number.isNaN(props.formik.values.currency_id)) && (
                <MultiExchangeRate
                  toCurrencyCode={props.item.currency_code || ""}
                  toCurrencyId={props.item.currency_id}
                  currencyCode={props.formik.values.currency_code}
                  currencyId={props.formik.values.currency_id}
                  editId={editId}
                  conversionId={props.item.conversion_id || 0}
                  conversionDate={props.formik.values.date || ""}
                  newConversionDate={props.formik.values.date || ""}
                  conversionRate={String(props.item.conversion_rate)}
                  conversionTimestamp={String(props.item.conversion_timestamp)}
                  setExchangeData={setExchangeData}
                  exchangeRateSubmitHandler={async (data: CurrencyList) => {
                    await userExchangeRateSubmitHandler(
                      { ...data, by_user: true },
                      setExchangeData,
                      props.formik,
                      "jv_item",
                      props.index
                    );
                  }}
                  isCurrencyChanged={Boolean(props.isCurrencyChanged)}
                  isDateChanged={Boolean(props.isDateChanged)}
                  isReverseConversionRequired={false}
                  position="end"
                  byUser={props.item.by_user}
                />
              )}
          </div>
        </td>
        <td>
          <input
            type="text"
            className={`jv-description  ${
              !props.formik.values.currency_id ? "disabled-item" : ""
            }`}
            name={`item_details.${props.index}.description`}
            id={"description" + props.index}
            placeholder="Enter description"
            value={props.item.description}
            onChange={(e: any) => {
              //   props.handleItemChange(e, props.index);
              props.formik.setFieldValue(
                `item_details.${props.index}.description`,
                e.target.value
              );
            }}
            maxLength={200}
            disabled={!props.formik.values.currency_id}
          />
        </td>
        <td>
          <InputPrefix
            prefix={props.formik.values.currency_code || ""}
            inputType="text"
            id={"debit" + props.index}
            isAmountField={true}
            name={`item_details.${props.index}.debit`}
            className={`form-control border-end-0 ${
              !props.formik.values.currency_id || props.item.credit
                ? "disabled-item"
                : ""
            }`}
            placeholder={zeroDisplayFormat(
              decimalPlaceOfCurrency(
                props.formik.values.currency_code
                  ? props.formik.values.currency_code
                  : currentUserInfo.currency_code,
                orgCurrencyList
              )
            )}
            onKeyPress={handleKeyPress}
            value={props.item.debit ? props.item.debit : ""}
            disabled={
              !props.formik.values.currency_id || props.item.credit
                ? true
                : false
            }
            error={""}
            onChange={(e: any) => {
              props.formik.setFieldValue(
                `item_details.${props.index}.debit`,
                e.target.value
              );
              //   props.handleItemChange(e, props.index);
            }}
            onBlur={props.handleItemOnBlur}
            index={props.index}
          />
          {!Number.isNaN(props.item.converted_debit_rate) &&
            Number(props.item.converted_debit_rate) !== 0 &&
            props.item.converted_debit_rate !== undefined &&
            props.item.currency_code !== undefined &&
            props.item.currency_code !== null &&
            props.item.currency_code !== "" &&
            props.item.currency_id &&
            Number(props.item.converted_credit_rate) !==
              Number(props.item.debit) && (
              <span className="jv-converted-amount-display">{`${props.item.currency_code}  ${props.item.converted_debit_rate}`}</span>
            )}
        </td>
        <td>
          <InputPrefix
            prefix={props.formik.values.currency_code || ""}
            inputType="text"
            id={"credit" + props.index}
            name={`item_details.${props.index}.credit`}
            isAmountField={true}
            className={`form-control border-end-0 ${
              !props.formik.values.currency_id || props.item.debit
                ? "disabled-item"
                : ""
            }`}
            placeholder={zeroDisplayFormat(
              decimalPlaceOfCurrency(
                props.formik.values.currency_code
                  ? props.formik.values.currency_code
                  : currentUserInfo.currency_code,
                orgCurrencyList
              )
            )}
            value={props.item.credit ? props.item.credit : ""}
            disabled={
              !props.formik.values.currency_id || props.item.debit
                ? true
                : false
            }
            error={""}
            onChange={(e: any) => {
              props.formik.setFieldValue(
                `item_details.${props.index}.credit`,
                e.target.value
              );
            }}
            onKeyPress={handleKeyPress}
            onBlur={props.handleItemOnBlur}
            index={props.index}
          />
          {!Number.isNaN(props.item.converted_credit_rate) &&
            Number(props.item.converted_credit_rate) !== 0 &&
            props.item.converted_credit_rate !== undefined &&
            props.item.currency_code !== null &&
            props.item.currency_code !== undefined &&
            Number(props.item.converted_credit_rate) !==
              Number(props.item.credit) && (
              <span className="jv-converted-amount-display">{`${props.item.currency_code}  ${props.item.converted_credit_rate}`}</span>
            )}
        </td>
        <td width="50px">
          <a
            className="delete-btn delete-jv"
            href="#"
            onClick={(e) => props.onDeleteItemClick(e)}
          >
            <DeleteIcon color={colors.slate} />
          </a>
        </td>
      </tr>
    </>
  );
}

export default React.memo(forwardRef(JournalItem));
