/**
 * Span Component
 * 
 * A wrapper component for rendering a <span> element with additional accessibility and styling options.
 * 
 * @param {object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The content inside the <span> element.
 * @param {string} [props.className] - Optional CSS class for styling.
 * @param {string} [props.id] - Optional ID for the <span> element.
 * @param {CSSProperties} [props.style] - Optional inline styles.
 * @param {string} [props.role] - Optional ARIA role for accessibility.
 * @param {string} [props.ariaLabelledBy] - Optional ID of another element that labels this span.
 * 
 * @returns {JSX.Element} A span element with applied properties.
 */
import { CSSProperties } from "react";

interface SpanProps extends  React.HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
  className?: string;
  id?: string;
  style?: CSSProperties;
  role?: string;
  ariaLabelledBy?: string;
};

export const Span = (props: SpanProps) => {
  const { children, ariaLabelledBy, ...rest } = props;
  return (
    <span {...rest} aria-labelledby={ariaLabelledBy}>
      {children}
    </span>
  );
};
