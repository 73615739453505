import CenterModal from "../../../../common/components/centerModal/CenterModal";
import "./cancelChangesModal.css";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { useAppDispatch } from "../../../../../app/hooks";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import CloseButton from "../../../../common/components/button/CloseButton";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import { Form } from "../../../../common/components/htmlTags/Form";
import { useFormik } from "formik";
import { useState } from "react";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { invoicingCycleOption } from "../../SubscriptionStateInitialization";
import {
  FormInputField,
  FormSelectField,
  NoteTextArea,
} from "../../../../common/components";
import InfoTag from "../../../../common/components/InfoTag";
import { coustomCaseWithRemoveUnderscore } from "../../../../../helpers/planHelper";
import * as yup from "yup";
import { removeCancelScheduler } from "../../subscriptionSlice";
import { toast } from "react-toastify";

export const CancelVoidScheduledModal = (props: any) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);

  const validate = yup.object({
    comments: yup.string().required("Please enter any comment"),
    invoicing_cycle_fixed_count: yup
      .number()
      .nullable()
      .when("invoicing_cycle", (invoicing_cycle, schema) => {
        if (invoicing_cycle[0] === "FIXED") {
          return schema.required("Invoicing cycle fixed count is required");
        }
        return schema.notRequired();
      }),
  });

  const formik = useFormik({
    initialValues: {
      invoicing_cycle: "FOREVER",
      comments: "",
      invoicing_cycle_fixed_count: 1,
    },
    enableReinitialize: true,
    validationSchema: validate,
    onSubmit: (values) => {
      modalSubmitHandler();
    },
  });

  const modalCancelHandler = async () => {
    formik.resetForm();
    setDidAlertModalOpen(false);
  };

  const modalSubmitHandler = async () => {
    const responseAction = await dispatch(
      removeCancelScheduler({
        orgId: currentUserInfo.organization_id,
        schedulerId: props.schedulerId,
        comments: formik.values.comments,
      })
    );

    if (responseAction) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        toast.success("Remove scheduled cancellation successfully!", {
          toastId: "resume-plan-success",
          closeButton: false,
          position: "top-center",
        });
        props.subscriptionSchedulerDetails();
        formik.resetForm();
        setDidAlertModalOpen(false);
        props.refreshSubscriptionDetails();
      } else if ("error" in response) {
      }
    }
  };

  const ConfigureButton = () => {
    return (
      <>
        <span
          className="subscription-scheduler-cancel subscription-scheduler-cancel-P-15"
          onClick={() => setDidAlertModalOpen(true)}
        >
          {" "}
          Remove Scheduled Cancellation
        </span>
      </>
    );
  };
  return (
    <>
      <ConfigureButton />
      <Dialog
        open={didAlertModalOpen}
        className={`subscription-update-popover`}
        onClose={modalCancelHandler}
      >
        <Card wrapperClass="subscription-update">
          <CardHeader wrapperClass={`subscription-update-header-wrapper`}>
            <Grid container>
              <Grid item xs={10} className="p-t-15 header">
                {"Remove Scheduled Cancellation?"}
              </Grid>
              <Grid item xs={2} className="popover-close-button">
                <CloseButton closeModalHandler={modalCancelHandler} />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            {props.subscriptionStatus !== "NON_RENEWING" && (
              <div className="p-b-18 mw-325">
                {/* date and status need to be added on that specific date */}
                <div>
                  This subscription will be next invoiced on{" "}
                  <strong>{props.overviewSubscriptionData.next_renewal}</strong>{" "}
                  and will remain{" "}
                  <InfoTag
                    status={
                      props.subscriptionStatus &&
                      coustomCaseWithRemoveUnderscore(props.subscriptionStatus)
                    }
                  />
                </div>
              </div>
            )}
            <Form
              name="add_invoice_form"
              id="add_invoice_form"
              className=""
              tabIndex={-1}
            >
              <div className="row custom-row"></div>
              <div className="row custom-row m-t-10">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <NoteTextArea
                    label="Comments"
                    name="comments"
                    id="comments"
                    className="notes-textarea"
                    placeholder="Add your comments here..."
                    value={formik.values.comments || ""}
                    onChange={(e) => {
                      formik.setFieldValue("comments", e.target.value);
                    }}
                    formErrors={{}}
                    labelClassName="required"
                  />
                </div>
                {formik.errors.comments && (
                  <div className="col-12 col-lg-4 mw-325">
                    <span className="error ">{formik.errors.comments}</span>
                  </div>
                )}
              </div>
              <div className="row mb-0">
                <div className="col">
                  <div
                    className={`form-button-wrapper w-100 'd-flex justify-content-end mt-3`}
                    id="form-btn"
                  >
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        formik.submitForm();
                      }}
                      className="save-button"
                    >
                      {"Update"}
                    </button>
                    <button
                      type="button"
                      onClick={modalCancelHandler}
                      className="text-decoration-none cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
};
