import { useState } from "react";
import useCommonData from "../../../../../hooks/useCommon";
import useListData from "../../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import { fetchBankAccount } from "../../../services/BankingSlice";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { BankAccountListItem } from "../types";
import { setLoaderState } from "../../../../../common/commonSlice";
export const useBankingLandingPage = () => {
  const { currentUserInfo, orgCurrencyList, dispatch } = useCommonData();
  const { setEndCount, setStartCount, setTotalList } = usePaginationDispatch();
  const { page, itemsPerPage } = useListData();
  const [bankAccounts, setBankAccounts] = useState<BankAccountListItem[]>([]);
  const [statementBalance, setStatementBalance] = useState(0);
  const [amountInSystem, setAmountInSystem] = useState(0);
  const getBankAccounts = async (pageNum: number, pageSize: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      fetchBankAccount({
        orgId: currentUserInfo.organization_id,
        pageNum: pageNum,
        pageSize: pageSize,
      })
    );
    const response = responseAction.payload;
    if (response) {
      const { statement_balance, amount_in_finbooks } = response;
      setAmountInSystem(amount_in_finbooks);
      setStatementBalance(statement_balance);
      const { total, accounts } = response.account_list;
      setTotalList(total);
      let length = accounts.length;

      let formattedAccounts = Array.isArray(accounts)
        ? accounts.map((account, index) => {
            return {
              ...account,
              statement_balance:
                account.currency_code +
                " " +
                NumberFormat(
                  Number(account.statement_balance),
                  account.currency_code || currentUserInfo.currency_code,
                  orgCurrencyList
                ),
              balance:
                account.currency_code +
                " " +
                NumberFormat(
                  Number(account.balance),
                  account.currency_code || currentUserInfo.currency_code,
                  orgCurrencyList
                ),
              action: {
                id: account.id,
                is_mutable: account?.is_mutable || true,
                name: account.account_name,
              },
            };
          })
        : [];
      setBankAccounts(formattedAccounts);
      if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
      setEndCount((page - 1) * itemsPerPage + length);
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
    return bankAccounts;
  };
  return { bankAccounts, statementBalance, amountInSystem, getBankAccounts };
};
