import { Container } from "../../../../common/components/htmlTags/Container";
import { SkeletonLoader } from "../../../../common/components/skeletonLoader/SkeletonLoader";
import TableSkeletonLoader from "../../../../common/components/ui/Loaders/TableSkeletonLoader/TableSkeletonLoader";
import { colors } from "../../../../constants/colors";
import "./SubscriptionInvoiceBreakdownSkeletonLoader.css";
export const SubscriptionInvoiceBreakdownSkeletonLoader = ({itemCount}:{itemCount: number}) => {
  return (
    <Container className="subscription-invoice-preview-skeleton-loader-wrapper">
      {Array.from({ length: itemCount }).map(() => {
        return (
          <Container className="subscription-invoice-preview-basic-details-skeleton-loader-wrapper">
            <Container className="mb-2">
              <SkeletonLoader
                width="150px"
                animation="wave"
                height="25px"
                color={colors.warmWhite}
                variant="rounded"
              />
            </Container>
            <Container className="subscription-invoice-preview-basic-description-skeleton-loader-wrapper">
              <Container className="mb-2">
                <SkeletonLoader
                  width="300px"
                  animation="wave"
                  height="15px"
                  color={colors.warmWhite}
                  variant="rounded"
                />
              </Container>
              <Container className="mb-2">
                <SkeletonLoader
                  width="300px"
                  animation="wave"
                  height="15px"
                  color={colors.warmWhite}
                  variant="rounded"
                />
              </Container>
              <Container className="mb-2">
                {" "}
                <SkeletonLoader
                  width="350px"
                  animation="wave"
                  height="15px"
                  color={colors.warmWhite}
                  variant="rounded"
                />
              </Container>
            </Container>
            <Container className="subscription-invoice-preview-item-table-skeleton-loader-wrapper mb-2">
              <TableSkeletonLoader rowCount={3} colCount={6} />
            </Container>
          </Container>
        );
      })}
      <Container className="subscription-invoice-preview-total-section-skeleton-loader-wrapper">
        <Container className="mb-2">
          {" "}
          <SkeletonLoader
            width="350px"
            animation="wave"
            height="30px"
            color={colors.warmWhite}
            variant="rounded"
          />
        </Container>
        <Container className="mb-2">
          <SkeletonLoader
            width="350px"
            animation="wave"
            height="30px"
            color={colors.warmWhite}
            variant="rounded"
          />
        </Container>
        <Container className="mb-2">
          <SkeletonLoader
            width="350px"
            animation="wave"
            height="40px"
            color={colors.warmWhite}
            variant="rounded"
          />
        </Container>
        <Container className="mb-2">
          <SkeletonLoader
            width="350px"
            animation="wave"
            height="40px"
            color={colors.warmWhite}
            variant="rounded"
          />
        </Container>
      </Container>
    </Container>
  );
};
