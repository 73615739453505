import * as yup from "yup";

export const ModalFormContentValidation = yup.object({
  number_of_renewals: yup
    .number()
    .required("Please enter number of renewals.")
    .test(
      "number_of_renewals",
      "Number of renewals must be greater than 0.",
      (value) => {
        if (value === 0) {
          return false;
        }
        return true;
      }
    )
    .test(
      "number_of_renewals",
      "Value entered should not be greater than remaining invoicing cycles.",
      (value, context) => {
        const { count, cycle } = context.parent;
        if (cycle === "FIXED" && value > count) {
          return false;
        }
        return true;
      }
    ),
});
