import { useContext, useEffect, useRef } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { PrimaryButton } from "../../../../../components/buttons/primaryButton/PrimaryButton";
import { SecondaryButton } from "../../../../../components/buttons/secondaryButton/SecondaryButton";
import { Container } from "../../../../../components/htmlTags/container/Container";
import { Label } from "../../../../../components/htmlTags/label/Label";
import { Span } from "../../../../../components/htmlTags/span/Span";
import { ModalContext } from "../../../../../components/modals/ModalContext";
import { getFiscalYear } from "../../../../../helpers/fiscalYearHelper";
import { getFormatedDate } from "../../../../../helpers/helper";
import {
  currenciesSelector,
  latestCurrencyRateSelector,
} from "../../../../common/commonSlice";
import CurrencySelect from "../../../../common/components/CurrencySelect";
import ExchangeRate from "../../../../common/components/ExchangeRate";
import InputPrefix from "../../../../common/components/formPrefixInputField/InputPrefix";
import { FormFieldContainer } from "../../../../common/components/ui/containers/FormFieldContainer/FormFieldContainer";
import Input from "../../../../common/components/ui/formElements/Input/Input";
import useCommonData from "../../../../hooks/useCommon";
import "./AddAccountForm.css";
import { useAddAccount } from "./hooks/useAddAccount";

function AddAccountForm(props: {
  editId?: number;
  refreshList: () => Promise<void>;
}) {
  const { closeModal } = useContext(ModalContext);
  const { currentUserInfo } = useCommonData();
  const exchangeRef = useRef<any>([]);
  const {
    formik,
    isAccountNameExist,
    getOpeningBalanceConversion,
    glCodeExist,
  } = useAddAccount(props.refreshList, props.editId);

  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;

  const fiscal_year_date = getFiscalYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "current"
  );

  const openingBalanceDate = currentUserInfo.is_ledger_org_closed
    ? fiscal_year_date.startDate
    : currentUserInfo.migration_date;

  const currencies = useAppSelector(currenciesSelector);

  useEffect(() => {
    getOpeningBalanceConversion(
      Number(formik.values.opening_balance_bank_currency),
      new AbortController().signal
    );
  }, [
    formik.values.currency,
    formik.values.opening_balance_bank_currency,
    exchangeRateValues,
  ]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    isAccountNameExist(formik.values.account_name, signal);
    return () => {
      controller.abort();
    };
  }, [formik.values.account_name]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    glCodeExist(formik.values.ledger_code, signal);
    return () => {
      controller.abort();
    };
  }, [formik.values.ledger_code]);
  return (
    <Container className=" add-account-form-modal-br">
      <Container className="col-12 col-lg-4 mw-325 void-subscription-margin-container">
        <Container className="date-selector-wrapper reactive-subscription-margin row">
          <FormFieldContainer
            wrapperClass="add_account_wrapper mb-0"
            label={"Account Name"}
            isRequired={true}
          >
            <Input
              name="account_name"
              type="text"
              className={`form-control`}
              value={formik.values.account_name}
              placeholder={"Enter Account Name"}
              maxLength={200}
              disabled={false}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched.account_name && formik.errors.account_name
                  ? formik.errors.account_name
                  : !formik.values.is_account_name_available &&
                    formik.values.account_name
                  ? "Account name already exists!"
                  : ""
              }
              errorClass="error_account_name"
              required={true}
            />
          </FormFieldContainer>
        </Container>
        <Container className="date-selector-wrapper reactive-subscription-margin row">
          <Container className="">
            <Label className="form-label form-labels">Description</Label>
            <textarea
              className={`form-control `}
              id="description"
              name="description"
              value={formik.values.description}
              placeholder="Add description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
          </Container>
        </Container>
        {currentUserInfo.is_super_parent && (
          <>
            <Container
              className="date-selector-wrapper reactive-subscription-margin row"
              style={{ position: "relative" }}
            >
              <small style={{ position: "absolute", left: "79px" }}>
                (Range between 000000-099999)
              </small>
              <FormFieldContainer
                wrapperClass="add_account_wrapper mb-0"
                label={"Ledger Code"}
                isRequired={false}
              >
                <Input
                  name="ledger_code"
                  type="text"
                  className={`form-control`}
                  value={formik.values.ledger_code}
                  placeholder={"Enter Ledger Code"}
                  maxLength={16}
                  disabled={false}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.ledger_code && formik.errors.ledger_code
                      ? formik.errors.ledger_code
                      : !formik.values.is_gl_code_available &&
                        formik.values.ledger_code
                      ? "Ledger code already exists!"
                      : ""
                  }
                  errorClass="error_reference_number"
                />
              </FormFieldContainer>
            </Container>
            <Container className="date-selector-wrapper reactive-subscription-margin row">
              <Container className="">
                <CurrencySelect
                  label="Currency"
                  currencyId={formik.values.currency}
                  onChange={(data) => {
                    formik.setFieldValue("currency", data.value);
                    formik.setFieldValue("currency_code", data.code);
                    formik.setFieldTouched("currency", true);
                  }}
                  isDisabled={false}
                  error={
                    formik.touched.currency &&
                    !formik.values.currency &&
                    formik.errors.currency
                      ? formik.errors.currency
                      : ""
                  }
                />
              </Container>
            </Container>
            <Container className="date-selector-wrapper reactive-subscription-margin row">
              <Label
                className=""
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Span style={{ width: "200px" }}>
                  Opening Balance{" "}
                  <small style={{ fontWeight: "500" }}>(Bank currency)</small>
                </Span>
                <Span className="opening-balance-exchange-rate">
                  <ExchangeRate
                    currencyId={Number(formik.values.currency)}
                    currencyCode={formik.values.currency_code}
                    editId={""}
                    conversionId={""}
                    conversionCurrencyId={currentUserInfo.organization_currency}
                    conversionDate={
                      openingBalanceDate
                        ? openingBalanceDate
                        : getFormatedDate(new Date())
                    }
                    newConversionDate={
                      openingBalanceDate
                        ? openingBalanceDate
                        : getFormatedDate(new Date())
                    }
                    ref={exchangeRef}
                    module="COA"
                  />
                </Span>
              </Label>
              <InputPrefix
                prefix={
                  formik.values.currency_code
                    ? formik.values.currency_code
                    : currencies?.filter((curr) => {
                        return (
                          curr.id === currentUserInfo.organization_currency
                        );
                      })[0]?.code
                }
                inputType="text"
                name={"opening_balance_bank_currency"}
                id={"opening_balance_bank_currency"}
                className="form-control border-end-0"
                placeholder={"0.00"}
                value={formik.values.opening_balance_bank_currency}
                disabled={false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.opening_balance_bank_currency
                    ? formik.errors.opening_balance_bank_currency
                    : ""
                }
                index={0}
              />
              {formik.errors.opening_balance_bank_currency && (
                <Span className="error">
                  {formik.errors.opening_balance_bank_currency}
                </Span>
              )}
            </Container>
            <Container className="date-selector-wrapper reactive-subscription-margin row">
              <Label className="">
                Opening Balance{" "}
                <small style={{ fontWeight: "500" }}>(Base currency)</small>
              </Label>
              <InputPrefix
                prefix={
                  currencies?.filter((curr) => {
                    return curr.id === currentUserInfo.organization_currency;
                  })[0]?.code
                }
                inputType="text"
                name={"opening_balance_base_currency"}
                id={"opening_balance_base_currency"}
                className="form-control border-end-0"
                placeholder={"0.00"}
                value={formik.values.opening_balance_base_currency}
                disabled={true}
                error={
                  formik.errors.opening_balance_base_currency
                    ? formik.errors.opening_balance_base_currency
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                index={0}
              />
              {formik.errors.opening_balance_base_currency && (
                <Span className="error">
                  {formik.errors.opening_balance_base_currency}
                </Span>
              )}
            </Container>
          </>
        )}
        <Container className="row mb-0">
          <Container className="col">
            <Container
              className={`form-button-wrapper w-100 'd-flex justify-content-end mt-3`}
              id="form-btn"
            >
              <PrimaryButton
                buttonVariant="normal"
                type="submit"
                onClick={(e: any) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                disabled={Object.keys(formik.errors).length ? true : false}
                className="ms-4"
              >
                {"Add Account"}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                buttonVariant="normal"
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleReset(e);
                  closeModal();
                }}
                
              >
                Cancel
              </SecondaryButton>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default AddAccountForm;
