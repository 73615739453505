import { FormikProps } from "formik";
import VendorSelect from "../../../../../../common/components/VendorSelect/VendorSelect";
import { AddVendorModal } from "../../../../../vendorManagement/components/addVendorModal/AddVendorModal";
import { useParams } from "react-router-dom";
import { vendorDetailsForTransactions } from "../../../../../vendorManagement/vendorSlice";
import { unpaidVendorBillsList } from "../../../../paymentsMadeSlice";
import useCommonData from "../../../../../../hooks/useCommon";
import NotificationModal from "../../../../../../common/components/NotificationModal";
import { useEffect, useState } from "react";
import { decimalPlaceOfCurrency } from "../../../../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../../../../helpers/roundOffHelper";
import { usePm } from "../../usePm";
import { getFormatedDate } from "../../../../../../../helpers/helper";

type Props = {
  formik: FormikProps<any>;
};

const PaymentVendor = ({ formik }: Props) => {
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  const { editId, vendorId } = useParams();
  const { vendorCurrencyChangeHandler } = usePm({
    formik: formik,
  });
  const [newVendor, setNewVendor] = useState({ value: 0, label: "" });

  useEffect(() => {
    if (vendorId) {
      getVendorDetails(Number(vendorId));
      getUnpaidBillsListOfVendor(Number(vendorId));
    }
  }, [vendorId, JSON.stringify(orgCurrencyList)]);

  /**
   * Handling Vendor change in Create or Edit BIll Payment form
   */
  const handleVendorChange = (e: any) => {
    if (editId && formik.initialValues.vendor_id) {
      $("#notificationPopupModal").modal("show");
      setNewVendor({ value: e.value, label: e.label });
    } else {
      if (e.value) {
        updateNewVendor(e);
      }
    }
  };

  const updateNewVendor = (e: { value: number; label: string }) => {
    formik.setFieldValue("vendor_id", e.value);
    formik.setFieldValue("vendor_name", e.label.trim());
    formik.setFieldValue("paid_through_account_id", "");
    formik.setFieldValue("paid_through_account_name", "");
    getVendorDetails(e.value);
    getUnpaidBillsListOfVendor(e.value);
  };

  const popupModalSubmitHandler = () => {
    updateNewVendor(newVendor);
  };

  const roundAmountPaid = (currencyCode: string) => {
    const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
    const roundedAmount = getRoundOffAmount(
      formik.values.amount_paid,
      decimals
    );
    formik.setFieldValue("amount_paid", roundedAmount);
  };

  /**
   * Fetch vendor details by id
   */
  const getVendorDetails = async (vendorId: number) => {
    const responseAction = await dispatch(
      vendorDetailsForTransactions({
        vendorId: vendorId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        formik.setFieldValue("currency_id", response.other_details.currency_id);
        let currencyId = response.other_details.currency_id;
        if (response.other_details.currency_id) {
          let currencyValues = orgCurrencyList
            .filter(
              (currency: any) => Number(currency.id) === Number(currencyId)
            )
            .map((currency) => currency);

          if (currencyValues.length) {
            formik.setFieldValue("currency_code", currencyValues[0].code);
            formik.setFieldValue("is_currency_changed", true);
            roundAmountPaid(currencyValues[0].code);
          }
        }
        vendorCurrencyChangeHandler(response.other_details.currency_id);
      }
    }
  };

  /**
   * Fetch unpaid vendor bills list
   */
  const getUnpaidBillsListOfVendor = async (vendorsId: number) => {
    if (vendorsId) {
      const responseAction = await dispatch(
        unpaidVendorBillsList({
          vendorId: vendorsId,
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        let unpaid_bill_lines = response.map((unpaid_bill: any) => {
          return {
            bill_number: unpaid_bill.bill_number,
            bill_date: unpaid_bill.bill_date,
            bill_amount: unpaid_bill.bill_amount,
            amount_due: unpaid_bill.amount_due,
            conversion_id: unpaid_bill.conversion_id,
            bill_id: unpaid_bill.id,
            allocation_date: getFormatedDate(),
            id: null,
            is_sundry_tag_associated: unpaid_bill.is_sundry_tag_associated,
          };
        });
        if (Object.keys(response).length && !("error" in response)) {
          if (editId && vendorsId !== formik.initialValues.vendor_id) {
            formik.setFieldValue("unpaid_bill_lines", unpaid_bill_lines);
          } else if (editId && vendorsId === formik.initialValues.vendor_id) {
            formik.setFieldValue(
              "unpaid_bill_lines",
              formik.initialValues.unpaid_bill_lines
            );
          } else {
            formik.setFieldValue("unpaid_bill_lines", unpaid_bill_lines);
          }
        } else {
          formik.setFieldValue("unpaid_bill_lines", []);
        }
      }
    }
  };

  return (
    <>
      <div className="col-12 col-lg-4 mw-325 mb-3">
        <div className="vendor-dropdown ">
          <div className="link-label-grouped">
            <label htmlFor="vendor_name" className="required">
              Vendor Name
            </label>
            <AddVendorModal />
          </div>
          <VendorSelect
            vendorId={formik.values.vendor_id}
            vendorName={formik.values.vendor_name}
            error={formik.errors.vendor_name?.toString() || ""}
            onChange={(option: any) => {
              handleVendorChange(option);
            }}
            isDisabled={false}
          />
          <span className="error error_vendor_id">
            {(formik.touched.vendor_id &&
              formik.errors.vendor_id?.toString()) ||
              ""}
          </span>
        </div>
        <div className="notification-modal-popup">
          <NotificationModal
            modalHeader="Bill payment status update!"
            modalBody={`You are attempting to change the vendor. This will update the payment status of existing bills of ${formik.initialValues.vendor_name}\n\nWould you like to continue?`}
            modalSubmit="Yes, Continue"
            modalCancel="Cancel"
            modalSubmitHandler={popupModalSubmitHandler}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentVendor;
