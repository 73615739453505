import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { OrganizationTreeValues, Transactionlimit } from "../../../../../types";
import {
  currentUserSelector,
  getOrganizationListOfUser,
} from "../../../../common/commonSlice";
import NotificationModal from "../../../../common/components/NotificationModal";
import Loader from "../../../components/Loader";
import "../../OrganizationHierarchy.css";
import {
  getTransactionLimitStatus,
  hierarchyList,
} from "../../OrganizationSlice";
import DeleteSubOrg from "../subOrgForm/deleteSubOrg";
import { SubOrgAddModal } from "../subOrgForm/subOrgAddModal";
import { ListAction } from "./listAction";
import { renderTreeData } from "./treeRendering";
const OrganizationTree = () => {
  const createSubOrgInitialState = {
    name: "",
    country_id: null,
    state_id: null,
    gst: "",
    currency: "",
    currency_id: "",
    transaction_exist: false,
    fiscal_year: "",
    timezone:""
  };
  const initialState = [
    {
      parent_id: null,
      organization_details: { ...createSubOrgInitialState, id: 1 },
      level: 0,
      node: [],
    },
  ];
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [listingData, setListingData] =
    useState<OrganizationTreeValues[]>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteSubOrgDetails, setDeleteSubOrgDetails] = useState({
    id: 0,
    name: "",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let [orgHierarchyIds, setOrgHierarchyIds] = useState<any>([]);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });

  useEffect(() => {
    findCurrentOrg(listingData);
  }, [listingData, currentUserInfo.organization_id]);

  useEffect(() => {
    fetchHierarchyList();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    getUserOrganizations();
  }, []);

  function findCurrentOrg(list: any) {
    list.map((org: any, index: number) => {
      if (org.organization_details.id === currentUserInfo.organization_id) {
        orgHierarchyIds = [];
        orgHierarchyIds.push(org.organization_details.id);
        if (org.node?.length !== 0)
          findChild(org.node, org.organization_details.id);
        setOrgHierarchyIds([...orgHierarchyIds]);
      } else {
        findCurrentOrg(org.node);
      }
    });
  }

  function findChild(list: any, currentOrgId: any) {
    list.map((org: any, index: number) => {
      orgHierarchyIds.push(org.organization_details.id);
      if (org.node?.length !== 0)
        findChild(org.node, org.organization_details.id);
    });
  }
  function toggleExpanding(e: Event) {
    const thisEle = e.target as any;
    thisEle.classList.toggle("active");
    thisEle.closest(".parent-row").classList.toggle("expanded");
    var panel: any = thisEle.closest(".parent-row").nextElementSibling;
    panel.classList.toggle("show");
  }

  /**
   * Returning left border based on depth of child.
   */
  const leftBorderOfChild = (count: number) => {
    let countArray = [];
    for (let i = 0; i < count; i++) {
      countArray.push(i);
    }
    let result = countArray.map((item, index) => {
      return (
        <div
          className="plain-line-column left-float left-border"
          key={index}
        ></div>
      );
    });
    return result;
  };

  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
      return response;
    }
  };
  /**
   * Fetch Hierarchy List
   */
  const fetchHierarchyList = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      hierarchyList(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      }, 500);
    }
    const response = responseAction.payload;
    let responseArr: any[] = [];
    if (Object.keys(response).length && !("error" in response)) {
      responseArr.push(response);
      setListingData(responseArr);
    } else if ("error" in response) {
      setListingData([]);
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteSubOrg = (id: number, name: string) => {
    setShowDeleteModal(true);
    setDeleteSubOrgDetails({
      id: id,
      name: name,
    });
  };

  /**
   * Organization list to display list on header (in case of create or delete in sub organization)
   */
  const getUserOrganizations = async () => {
    await dispatch(getOrganizationListOfUser(currentUserInfo.organization_id));
  };

  return (
    <>
      <div className="card card-user-management card-customer card-org-hierarchy main-card overflowX-hidden h-100">
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <h1 className="fw-bold">Organization Hierarchy</h1>
        </div>
        <div className="card-body">
          <div className="grid-table org-hierarchy-grid-table">
            <div className="table-body">
              {/* tree view grid item 1 */}
              {listingData?.map((org, index) => {
                return (
                  <div key={org.organization_details.id}>
                    <div
                      key={org.organization_details.id}
                      data-id={org.organization_details.id}
                      className="table-collapsible-item auto-flex"
                    >
                      <div
                        className={`parent-row item-row level-${org.level + 1}`}
                      >
                        <div className="item-col account-col align-left">
                          <div className="btn-container left-float horizontal-border bottom-border left-border">
                            <button
                              className="collapsible-btn"
                              onClick={(e: any) => toggleExpanding(e)}
                            ></button>
                          </div>
                          <div className="item-label left-float horizontal-border left-border">
                            {org.organization_details.name}
                          </div>
                        </div>
                        <div className="item-col act-col horizontal-border">
                          {orgHierarchyIds.includes(
                            org.organization_details.id
                          ) ? (
                            <div className="action">
                              <ListAction
                                organization={org}
                                currentUserInfo={currentUserInfo}
                                deleteSubOrg={deleteSubOrg}
                                fetchHierarchyList={fetchHierarchyList}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="table-collapsible-content">
                        {org.node ? (
                          renderTreeData(
                            org,
                            orgHierarchyIds,
                            currentUserInfo,
                            leftBorderOfChild,
                            toggleExpanding,
                            deleteSubOrg,
                            fetchHierarchyList
                          )
                        ) : (
                          <></>
                        )}
                        {orgHierarchyIds.includes(
                          org.organization_details.id
                        ) ? (
                          <div
                            className={`parent-row item-row level-${
                              org.level + 2
                            }`}
                          >
                            <div className="item-col account-col align-left">
                              {leftBorderOfChild(org.level)}
                              <div className="plain-line-column left-float left-border horizontal-border"></div>
                              <div className="plain-line-column left-float left-border horizontal-border">
                                <SubOrgAddModal
                                  isListButton={true}
                                  currentOrgId={currentUserInfo.organization_id}
                                  parentOrgId={org.organization_details.id}
                                  values={createSubOrgInitialState}
                                  fetchHierarchyList={fetchHierarchyList}
                                />
                              </div>
                              <div className="item-label add-sub-wrapper left-float horizontal-border left-border">
                                <SubOrgAddModal
                                  isListLink={true}
                                  currentOrgId={currentUserInfo.organization_id}
                                  parentOrgId={org.organization_details.id}
                                  values={createSubOrgInitialState}
                                  fetchHierarchyList={fetchHierarchyList}
                                />
                              </div>
                            </div>
                            <div className="item-col act-col horizontal-border">
                              <div className="action"></div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {isLoading ? <Loader /> : null}
        </div>
        {showDeleteModal && (
          <DeleteSubOrg
            handleClose={handleCloseDeleteModal}
            deleteSubOrgDetails={deleteSubOrgDetails}
            refreshHierarchyListing={fetchHierarchyList}
            organizationId={currentUserInfo.organization_id}
          />
        )}
        <NotificationModal
          modalId="transactionLimitModal"
          modalHeader={"Transaction limit exceeded!"}
          modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
          modalSubmit="OK"
          modalCancel=""
          modalSubmitHandler={() => {}}
        />
      </div>
    </>
  );
};

export default OrganizationTree;
