import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import { PlanPriceDefaultValue } from "../../../types";
import { CouponformValue } from "./couponType";

export async function checkCouponName(data: {
  name: string;
  orgId: number;
  coupon_id: number;
}) {
  const encodedName = encodeURIComponent(data.name);
  const result = await get(
    Api.coupon +
      `name-exists/?current_organization=${data.orgId}&coupon_name=${encodedName}&coupon_id=${data.coupon_id}`
  );
  return result;
}

export async function createNewCoupon(data: {
  values: CouponformValue;
  orgId: number;
}) {
  const result = await post(
    Api.coupon + `create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to get plan list
 */

export async function getCouponList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  orgIds: number[];
  statusList: string[];
}) {
  let url =
    Api.coupon +
    "list" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`;

  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }

  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }

  const result = await get(url);
  return result;
}
export async function getCouponDetailList(data: {
  couponIds: number[];
  orgId: number;
}) {
  let couponUrl = "";
  if (data.couponIds.length > 0) {
    const couponIds = data.couponIds
      .map((couponId) => {
        return `&coupon_id=${couponId}`;
      })
      .join("");
    couponUrl += couponIds;
  }
  const result = await get(
    Api.coupon + `get_coupons_list?current_organization=${data.orgId}` + couponUrl
  );
  return result;
}

export async function getCouponForEdit(data: {
  couponId: number | string;
  orgId: number;
}) {
  const result = await get(
    Api.coupon +
      "detail" +
      `/${data.couponId}?current_organization=${data.orgId}`
  );
  return result;
}

export async function editCouponApi(data: {
  values: CouponformValue;
  couponId: string | undefined;
  orgId: number;
}) {
  const result = await put(
    Api.coupon + `edit/${data.couponId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to get plan item list
 */

export async function getPlanItemList(data: { planId: any; orgId: number }) {
  const result = await get(
    Api.plan +
      "item/list" +
      `/${data.planId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to get plan item list
 */

export async function createPlanPrice(data: {
  values: PlanPriceDefaultValue;
  orgId: number;
}) {
  const result = await post(
    Api.plan + "price-point/create" + `?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 *  API call for getting plan detail and pricing list
 */
export async function getPlanOverviewDetails(data: {
  planId: number;
  orgId: number;
}) {
  const result = await get(
    Api.plan + `details/${data.planId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to update plan status (stop/resume/void)
 */
export async function updateCouponStatus(data: {
  id: number;
  status: string;
  orgId: number;
}) {
  const result = await put(
    Api.coupon +
      "change-status" +
      `/${data.id}?current_organization=${data.orgId}`,
    { status: data.status }
  );
  return result;
}

/**
 * API call to delete plan
 */

export async function deleteCoupon(data: {
  deletePlanId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.coupon +
      "delete" +
      `/${data.deletePlanId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * API call to delete plan
 */

export async function deletePricing(data: {
  deletePricingId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.plan +
      "pricing/delete" +
      `/${data.deletePricingId}?current_organization=${data.orgId}`
  );

  return result;
}

export async function getEditPriceDetails(data: { priceId: number }) {
  const result = await get(
    Api.plan + "pricing/edit-details" + `/${data.priceId}`
  );
  return result;
}

export async function editPlanPrice(data: {
  values: PlanPriceDefaultValue;
  orgId: number;
  priceId: any;
}) {
  const result = await put(
    Api.plan +
      "pricing/edit" +
      `/${data.priceId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

export async function checkPriceInternalName(data: {
  internalName: string;
  orgId: number;
  plan_id: number;
  pricing_id: string;
}) {
  let url =
    Api.plan +
    Api.pricePoint +
    "/" +
    Api.internalNameExists +
    `/${data.plan_id}?current_organization=${data.orgId}`;

  if (data?.pricing_id) {
    url += `&pricing_id=${data.pricing_id}`;
  }
  const result = await post(url, { internal_name: data.internalName });

  return result;
}

/**
 * Api to get the transaction limit status
 */
export async function couponTransactionLimitStatus(orgId: number) {
  const result = await get(
    Api.coupon + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

/**
 * Api to get the coupon overview data
 */
export async function getCouponDetails(data: {
  couponId: number | string;
  orgId: number;
}) {
  const result = await get(
    Api.coupon +
      "overview" +
      `/${data.couponId}?current_organization=${data.orgId}`
  );
  return result;
}
