import React from "react";
import "./SecondaryButton.css";

/**
 * Accessible Secondary Button Component
 *
 * A reusable secondary button that follows accessibility (a11y) best practices.
 *
 * Features:
 * - Supports ARIA attributes for screen readers.
 * - Fully keyboard navigable.
 * - Customizable styles and size.
 * - Proper role and semantic button element.
 *
 * @param {string} [ariaLabel] - Accessible label for screen readers.
 *
 * @returns {JSX.Element} A fully accessible Secondary button component.
 */
export interface SecondaryButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ariaLabel?: string; // Ensures accessibility
  buttonVariant: "normal" | "danger"
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = (
  props: SecondaryButtonProps
) => {
  const { children, ariaLabel, className, name,buttonVariant, ...rest } = props;
  return (
    <button
      {...rest}
      className={`no-appearance ${
        className ? className : ""
      } reusable-secondary-button-${buttonVariant} ${rest.disabled?"disabled-btn":""}`.trim()}
      aria-label={ariaLabel || name}
    >
      {children}
    </button>
  );
};
