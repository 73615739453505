import { useState } from "react";
import { Container } from "../../htmlTags/container/Container";
import { RawButton } from "../rawButton/RawButton";
import "./TabButtonGroup.css";
interface TabButtonGroupProps {
  defaultValue: { value: string; label: string };
  tabs: { value: string; label: string }[];
  onTabSwitch: (activeTab: string) => void;
}
export const TabButtonGroup = ({
  tabs,
  defaultValue,
  onTabSwitch,
}: TabButtonGroupProps) => {
  const [activeTab, setActiveTab] = useState(defaultValue.label);
  return (
    <Container className="tab-button-group-wrapper">
      {tabs.map((tab) => {
        return (
          <RawButton
            key={tab.label}
            aria-labelledby={tab.label}
            className={
              activeTab === tab.label
                ? "primary-normal-button-class"
                : "secondary-normal-button-class"
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onTabSwitch(tab.label);
              setActiveTab(tab.label);
            }}
          >
            {tab.label}
          </RawButton>
        );
      })}
    </Container>
  );
};
