import { ObjectType } from "../../../../types";
import { Api } from "../../../../constants";

export async function downloadFile(
  filterValues: ObjectType,
  reportType: string,
  fileName: string
) {
  try {
    const response = await fetch(
      `${Api.reports}${reportType}/generate?is_export=true&current_organization=${filterValues?.organization_id}`,
      {
        method: "POST",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Access-Control-Allow-Credentials": "true",
          ["auth"]: String(sessionStorage.getItem("token")),
          ["Content-Type"]: "application/json",
        },
        credentials: "include",
        body: JSON.stringify(filterValues),
      }
    );

    if (!response.ok) throw new Error("Failed to download the file");

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);

    // Create a link element, trigger a download, and remove the element
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Clean up the object URL
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}

export function getFileName(orgName: string, reportType: string, startDate: string, endDate: string) {
  return `${orgName ? orgName + "-" : ""}${reportType}-${startDate ? startDate : ''}-${endDate ? endDate : ''}.xlsx`;
}
