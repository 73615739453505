import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select, { components, SingleValue } from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { customerDropdownListForSoa } from "../../../home/customerManagement/customerSlice";
import { currentUserSelector } from "../../commonSlice";
import { customSelectStyle } from "../SelectCustomStyle";
import { useInView } from "react-intersection-observer";
import "./CustomerSelect.css";
import { colors } from "../../../constants/colors";

type Props = {
  customerId: string | number;
  customerName: string;
  orgId?: number | null;
  error: string;
  onChange: (
    newValue: SingleValue<{
      label: string;
      value: string | number;
      currency_id: string | number;
      currency_code: string;
    }>
  ) => void;
  isDisabled: boolean;
  isAllOptionEnabled?: boolean;
  allOptionLabel?: string;
  onBlur?: (e: any) => void;
  isModal?: boolean;
};

const CustomerSelectWithOrgFilter = ({
  customerId,
  customerName,
  orgId,
  error,
  onChange,
  isDisabled,
  isAllOptionEnabled,
  allOptionLabel,
  onBlur,
  isModal,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [customerListOptions, setCustomerListOptions] = useState<
    {
      label: string;
      value: string | number;
      currency_id: string | number;
      currency_code: string;
    }[]
  >([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [currencyId, setCurrencyId] = useState<string | number>("");
  const [currencyCode, setCurrencyCode] = useState<string>("");
  const { ref, inView } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    if (inView) {
      handleMenuScrollToBottom();
    }
  }, [inView]);
  const getSearchCustomersList = useCallback(
    async (query: string, pageNum: number, signal?: AbortSignal) => {
      try {
        const responseAction = await dispatch(
          customerDropdownListForSoa({
            orgId: orgId ? Number(orgId) : currentUserInfo.organization_id,
            currentOrgId: currentUserInfo.organization_id,
            searchQuery: query,
            pageNum: pageNum,
            pageSize: 10,
            customerId: customerId ? Number(customerId) : null,
            signal: signal,
          })
        );

        const response = responseAction.payload;
        if (response && response.customers) {
          const newCustomers = Array.isArray(response.customers)
            ? response.customers.map((customer: any) => ({
                label: customer.customer_display_name,
                value: customer.id,
                currency_id: customer.currency_id, // Include currency_id
                currency_code: customer.currency_code, // Include currency_code
                organization_name: customer.organization_name,
                customer_identification: customer.identifier,
              }))
            : [];
          let selectedCustomer = {};
          setCustomerListOptions((prevOptions) => {
            if (customerId) {
              let combined = [...prevOptions, ...newCustomers];
              selectedCustomer = combined.find(
                (customer: any) => Number(customer.value) === Number(customerId)
              );
            }
            const combinedOptions = Object.keys(selectedCustomer || {}).length
              ? [selectedCustomer, ...prevOptions, ...newCustomers]
              : [...prevOptions, ...newCustomers];

            // Remove duplicates using a Set
            const uniqueOptions = combinedOptions?.reduce((acc, current) => {
              const isDuplicate = acc?.some(
                (item: any) =>
                  item.value === current.value && item.label === current.label
              );
              if (!isDuplicate) {
                acc?.push(current);
              }
              return acc;
            }, [] as { label: string; value: string | number; currency_id: string | number; currency_code: string }[]);

            return uniqueOptions;
          });

          setHasMore(response.pagination.next);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to fetch customers:", error);
      }
    },
    [currentUserInfo.organization_id, dispatch, customerId]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getSearchCustomersList(searchQuery, 1, signal);
    return () => {
      controller.abort();
    };
  }, [searchQuery, getSearchCustomersList]);

  const handleCustomerSearch = useCallback((query: string) => {
    setIsLoading(true);
    setSearchQuery(query);
    setPageNum(1);
    setCustomerListOptions([]); // Reset options only when search query changes
  }, []);

  const handleMenuScrollToBottom = useCallback(async () => {
    if (hasMore && !isLoading) {
      await getSearchCustomersList(searchQuery, pageNum + 1);
      setPageNum((prevPageNum) => prevPageNum + 1);
    }
  }, [hasMore, pageNum, getSearchCustomersList, isLoading]);

  const handleChange = (
    newValue: SingleValue<{
      label: string;
      value: string | number;
      currency_id: string | number;
      currency_code: string;
    }>
  ) => {
    if (newValue) {
      // Update internal currency_id and currency_code states
      setCurrencyId(newValue.currency_id);
      setCurrencyCode(newValue.currency_code);

      // Pass the selected value (including currency_id and currency_code) to the parent
      onChange(newValue);
    }
  };
  const LabelWithRoot = useMemo(() => {
    return (data: any) => {
      return (
        <div
          {...data.innerProps}
          className="custom-select-option-wrapper"
          style={{
            backgroundColor:
              Number(data.data.value) === Number(customerId)
                ? colors.whisper
                : "white",
          }}
        >
          <span className="custom-select-option-account-name">
            {data.data.label}
          </span>
          {data.data.customer_identification && (
            <div className="custom-select-option-account-sub-root">
              {data.data.customer_identification}
            </div>
          )}
          {data.data.organization_name && (
            <div className="custom-select-option-account-root">
              {data.data.organization_name}
            </div>
          )}
        </div>
      );
    };
  }, [customerId]);
  const CustomMenuList = useMemo(() => {
    return (props: any) => {
      return (
        <components.MenuList {...props}>
          {props.children}
          <div className="temp-div" style={{ height: "10px" }} ref={ref}></div>
        </components.MenuList>
      );
    };
  }, [customerId]);

  return (
    <Select
      id={"customer-select-field"}
      name="customer"
      value={
        customerId && isAllOptionEnabled !== true
          ? {
              label: customerName,
              value: customerId,
              currency_id: currencyId,
              currency_code: currencyCode,
            }
          : (customerId === 0 || customerId) && isAllOptionEnabled === true
          ? {
              label: customerName,
              value: customerId,
              currency_id: currencyId,
              currency_code: currencyCode,
            }
          : null
      }
      options={
        isAllOptionEnabled
          ? [
              {
                value: 0,
                label: allOptionLabel || "",
                currency_code: "",
                currency_id: 0,
              },
              ...customerListOptions,
            ]
          : customerListOptions
      }
      aria-label="Customer Select"
      inputValue={searchQuery}
      className="custom-select customer-select customer_name"
      isDisabled={isDisabled}
      isSearchable={true}
      maxMenuHeight={200}
      placeholder="Choose customer"
      onChange={handleChange} // Use the updated handleChange function
      menuPortalTarget={
        isModal === true
          ? undefined
          : document.body
      }
      styles={customSelectStyle}
      onInputChange={handleCustomerSearch}
      components={{ MenuList: CustomMenuList, Option: LabelWithRoot }}
      onMenuOpen={() => {
        setPageNum(1);
        setSearchQuery("");
        setHasMore(true);
        setMenuIsOpen(true);
        getSearchCustomersList("", 1);
      }}
      onMenuClose={() => setMenuIsOpen(false)}
      menuIsOpen={menuIsOpen}
    />
  );
};

export default CustomerSelectWithOrgFilter;
