import * as yup from "yup";

export const ReminderEmailConfigFormValidation = yup.object({
  write_off_account_id: yup.number().test({
    name: "write-off-account-id",
    message: "Please select a write-off account.",
    test: function (value) {
      const { invoice_action } = this.options.context as any;
      if (invoice_action === "WRITE_OFF" && value === 0) {
        return false;
      } else {
        return true;
      }
    },
  }),
});
