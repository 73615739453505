import { SvgIconProp } from "../types";

export const RightArrowIcon = (props: SvgIconProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "16px"}
      height={props.height ? props.height : "16px"}
      viewBox="0 0 16 16"
    >
      <path
        fill={props.color}
        d="M10.78 8.669H2.665V7.335h8.115L7.204 3.76l.943-.943 5.185 5.185-5.185 5.185-.943-.942z"
      ></path>
    </svg>
  );
};
