import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPlanSpecificInvoicing,
  createSideWideCalenderInvoicing,
  deleteCalenderInvoicing,
  editPlanSpecificInvoicing,
  getPlanSpecificCalenderInvoicingList,
  getSiteWideCalenderEditDetails,
  getSiteWideCalenderInvoicingList,
  getplanSpecificDropdownList,
  updateSideWideCalenderInvoicing,
} from "./calenderBillingAPI";

export const createSideWideCalenderInvoicingHandler = createAsyncThunk(
  "CouponBillingManagement/createSideWideCalenderInvoicingHandler",
  async (data: {
    orgId: number;
    invoicing_frequency: string;
    invoicing_type: string;
    set_date: string | null;
    set_day: string | null;
  }) => {
    return await createSideWideCalenderInvoicing(data);
  }
);

export const updateSideWideCalenderInvoicingHandler = createAsyncThunk(
  "CouponBillingManagement/updateSideWideCalenderInvoicingHandler",
  async (data: {
    orgId: number;
    invoicing_frequency: string;
    invoicing_type: string;
    set_date: string | null;
    set_day: string | null;
    calendarInvoicingId: number;
  }) => {
    return await updateSideWideCalenderInvoicing(data);
  }
);

export const siteWideCalenderInvoicingList = createAsyncThunk(
  "CouponBillingManagement/siteWideCalenderInvoicingList",
  async (data: { orgId: number; pageNum: number; pageSize: number }) => {
    return await getSiteWideCalenderInvoicingList(data);
  }
);

export const planSpecificCalenderInvoicingList = createAsyncThunk(
  "CouponBillingManagement/planSpecificCalenderInvoicingList",
  async (data: {
    orgId: number;
    pageNum: number;
    pageSize: number;
    frequencyList: string[];
  }) => {
    return await getPlanSpecificCalenderInvoicingList(data);
  }
);

export const siteWideCalenderEditDetails = createAsyncThunk(
  "CouponBillingManagement/siteWideCalenderEditDetails",
  async (data: { orgId: number; calendarInvoicingId: number }) => {
    return await getSiteWideCalenderEditDetails(data);
  }
);

export const deleteCalenderDetails = createAsyncThunk(
  "CouponBillingManagement/delete",
  async (data: { calendarInvoicingId: number; orgId: number }) => {
    return await deleteCalenderInvoicing(data);
  }
);

export const getPlanSpecificDropdown = createAsyncThunk(
  "planSpecific/planDropdown",
  async (data: {
    page: number;
    itemsPerPage: number;
    orgId: number;
    frequency: string;
    currency: string;
    calendarInvoicingID: number;
  }) => {
    return await getplanSpecificDropdownList(data);
  }
);

export const createPlanSpecificInvoicingHandler = createAsyncThunk(
  "calendarInvoicing/create-plan-specific",
  async (data: {
    organization_id: number;
    invoicing_frequency: string;
    currency_id: number;
    invoicing_type: string;
    set_date: string | null;
    set_day: string | null;
    pricing_ids: number[];
  }) => {
    return await createPlanSpecificInvoicing(data);
  }
);

export const editPlanSpecificInvoicingHandler = createAsyncThunk(
  "calendarInvoicing/edit-plan-specific",
  async (data: {
    organization_id: number;
    invoicing_frequency: string;
    invoicing_type: string;
    set_date: string | null;
    set_day: string | null;
    pricing_ids: number[];
    id: number;
  }) => {
    return await editPlanSpecificInvoicing(data);
  }
);
const initialState = {};

export const calenderBillingSlice = createSlice({
  name: "CouponBillingManagement",
  initialState,
  reducers: {},
});

export default calenderBillingSlice.reducer;
