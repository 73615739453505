import React from "react";
import "./IconButton.css";

/**
 * Accessible Icon Button Component
 *
 * A reusable icon button that follows accessibility (a11y) best practices.
 *
 * Features:
 * - Supports ARIA attributes for screen readers.
 * - Fully keyboard navigable.
 * - Customizable styles and size.
 * - Proper role and semantic button element.
 *
 * @param {string} [fontSize] - Optional font size styling.
 * @param {string} [ariaLabel] - Accessible label for screen readers.
 *
 * @returns {JSX.Element} A fully accessible icon button component.
 */
export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fontSize?: string;
  ariaLabel?: string; // Ensures accessibility
}

export const IconButton: React.FC<IconButtonProps> = (
  props: IconButtonProps
) => {
  const { children, ariaLabel, className, fontSize, style, name, ...rest } =
    props;
  return (
    <button
      {...rest}
      className={`no-appearance ${
        className ? className : ""
      } reusable-icon-button`.trim()}
      aria-label={ariaLabel || name}
      style={{ ...style, fontSize: fontSize || "14px" }}
    >
      {children}
    </button>
  );
};
