import { colors } from "../../constants/colors";
import "../styles/AlertModal.css";
import { DateFormatHandler, getFormatedDate } from "../../../helpers/helper";
import { FormSelectField } from "./formSelectField/FormSelectField";
import {
  invoicingCycleOption,
  invoicingOptions,
} from "../../home/subscription/SubscriptionStateInitialization";
import { customSelectStyle } from "./SelectCustomStyle";
import { FormInputField } from "./formInputField/FormInputField";
import moment from "moment";
import CustomDatepicker from "../../utils/atoms/datepicker";
import { FormikProps } from "formik";
import { ObjectType } from "../../../types";
import {
  formateDateMonth,
  getValidPastDate,
} from "../../../helpers/subscriptionHelper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { RadioButtonGroup } from "./radioButtonGroup/radioButtonGroup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useEffect, useState } from "react";
import useCommonData from "../../hooks/useCommon";
import { NumberFormat } from "../../../helpers/numberFormatHelper";
import { Link } from "react-router-dom";
import { SubscriptionInvoiceBreakdownNextInvoiceDate } from "../../home/subscription/components/view/SubscriptionInvoiceBreakdownNextInvoiceDate";
import { getPreviewDetailsHandler } from "../../home/subscription/subscriptionSlice";

type Props = {
  modalCancelHandler: () => void;
  formik: FormikProps<any>;
  nextInvoiceDate?: string;
  startDate?: string;
  endDate?: string | null;
  currencyCode?: string;
  subscriptionId?: number;
  openPreviewModal?: () => void;
};

const EditNextInvoiceDateModal = (props: Props) => {
  const [nextInvoiceDateShow, setNextInvoiceDateShow] = useState<
    string | undefined
  >(props.nextInvoiceDate);
  const [details, setDetails] = useState<any>({});
  const { orgCurrencyList, dispatch, currentUserInfo } = useCommonData();
  function formatDate(input: string): string {
    const date = new Date(input);
    const months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  function convertDate(dateStr: string) {
    let parts = dateStr.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }

  function compareDates(inputDate: string, compareTo: string): string {
    const input = new Date(inputDate);
    const compare = new Date(convertDate(compareTo));

    if (input < compare) {
      return "SMALLER";
    } else if (input > compare) {
      return "GREATER";
    } else {
      return "SAME";
    }
  }

  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return convertToDate(`${day}-${month}-${year}`);
  };

  function convertToDate(dateString: string): Date {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  // const isValidDateFormat = (dateStr: string): boolean => {
  //   const regexPattern = /^\d{4}-\d{2}-\d{2}$/;
  //   if (!regexPattern.test(dateStr)) return false;
  //   const [year, month, day] = dateStr.split("-").map(Number);
  //   const date = new Date(year, month - 1, day);
  //   return (
  //     date.getFullYear() === year &&
  //     date.getMonth() === month - 1 &&
  //     date.getDate() === day
  //   );
  // };

  const isValidDateFormat = (dateStr: string): boolean => {
    const regexPattern = /^\d{4}-\d{2}-\d{2}$/;

    if (!regexPattern.test(dateStr)) return false;

    const [year, month, day] = dateStr.split("-").map(Number);
    const date = new Date(year, month - 1, day);

    if (
      date.getFullYear() !== year ||
      date.getMonth() !== month - 1 ||
      date.getDate() !== day
    ) {
      return false;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    return date >= today;
  };

  const getDetails = async () => {
    const formattedDate = formatDateType(
      props.formik.values.next_invoice_billing_date
    );
    const isCorrectDateFormat = isValidDateFormat(formattedDate);

    if (props.formik.values.next_invoice_billing_date && isCorrectDateFormat) {
      const updateSubscrptionResponseAction = await dispatch(
        getPreviewDetailsHandler({
          subscriptionId: props.subscriptionId ? props.subscriptionId : 0,
          invoicingOption: props.formik.values.invoicing_option,
          proratedCredits: props.formik.values.prorate_credits,
          nextInvoiceDate: formattedDate,
          orgId: currentUserInfo.organization_id,
        })
      );

      if (updateSubscrptionResponseAction.payload) {
        setDetails(updateSubscrptionResponseAction.payload);
      } else {
        setDetails({});
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => getDetails(), 300);
    return () => clearTimeout(timer);
  }, [props.formik.values]);

  function formatDateType(dateString: any): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return dateString;
    }
    const yyyy: number = date.getFullYear();
    const mm: string = String(date.getMonth() + 1).padStart(2, "0");
    const dd: string = String(date.getDate()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
  }

  const dateFormat = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  function formatDateForComparison(dateString: any): string {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div className="edit-next-invoice-date-modal">
      <div className="col-12 col-lg-4 mw-325 void-subscription-margin-container">
        <div className="date-selector-wrapper reactive-subscription-margin row">
          <label className="pause-date-label" htmlFor="cancel_date">
            <div>Edit Next Invoicing Date</div>
            <div className="edit-next-invoice-date-show-date">
              <small style={{ color: "#747d84" }}>
                The next invoicing date is{" "}
                {formateDateMonth(DateFormatHandler(props.nextInvoiceDate))}
              </small>
            </div>
          </label>
          <CustomDatepicker
            date={
              props.formik.values.next_invoice_billing_date
                ? props.formik.values.next_invoice_billing_date
                : null
            }
            handleDate={(date) => {
              props.formik.setFieldValue("next_invoice_billing_date", date);
              setNextInvoiceDateShow(date?.toString() || "");
            }}
            type="next_invoice_billing_date"
            name="next_invoice_billing_date"
            id="next_invoice_billing_date"
            error={""}
            zIndex={0}
            placeholder="Enter next invoicing date"
            enableFutureDate={true}
            isDisabled={false}
            minDate={getCurrentDate()}
            maxDate={
              props.endDate
                ? convertToDate(dateFormat(props.endDate))
                : undefined
            }
            // readOnly={true}
          />
          {props.formik.errors.next_invoice_billing_date &&
            typeof props.formik.errors.next_invoice_billing_date ===
              "string" && (
              <span className="error">
                {props.formik.errors.next_invoice_billing_date}
              </span>
            )}
        </div>
        {props.formik.values.next_invoice_billing_date && (
          <div
            className="date-selector-wrapper reactive-subscription-margin row"
            style={{
              marginLeft: "0px",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  className={`checkbox chekBoxlabel`}
                  checked={props.formik.values.prorate_credits}
                  onChange={(e) => {
                    props.formik.setFieldValue(
                      `prorate_credits`,
                      e.target.checked
                    );
                  }}
                  name="prorate_credits"
                  id="prorate_credits"
                />
              }
              label="Prorate Credits"
              className="chekBoxlabel-prorated-label"
            />
            <small
              style={{
                position: "absolute",
                top: "18px",
                left: "24px",
                color: "#747d84",
              }}
            >
              {props.nextInvoiceDate &&
                compareDates(
                  props.formik.values.next_invoice_billing_date,
                  props.nextInvoiceDate
                ) === "GREATER" &&
                `Charges will be raised for the extended period.`}
              {props.nextInvoiceDate &&
                compareDates(
                  props.formik.values.next_invoice_billing_date,
                  props.nextInvoiceDate
                ) === "SMALLER" &&
                `Credit will be
                issued for the reduced period.`}
            </small>
          </div>
        )}
        {props.formik.values.prorate_credits &&
          props.nextInvoiceDate &&
          compareDates(
            props.formik.values.next_invoice_billing_date,
            props.nextInvoiceDate
          ) === "GREATER" && (
            <div
              className="date-selector-wrapper reactive-subscription-margin row"
              style={{ marginLeft: "0px", marginBottom: "0px" }}
            >
              <label
                className="pause-date-label edit-next-invoice-invoicing-option"
                htmlFor="cancel_date"
              >
                Invoicing Options
              </label>
              <RadioButtonGroup
                name="invoicing_option"
                id="invoicing_option"
                valuesArray={[
                  { label: "Immediately", value: "IMMEDIATELY" },
                  {
                    label: "Add to unbilled charges",
                    value: "UNBILLED",
                  },
                ]}
                value={props.formik.values.invoicing_option}
                icon={
                  <span>
                    <RadioButtonUncheckedIcon />
                  </span>
                }
                checkedIcon={
                  <span>
                    <RadioButtonCheckedIcon className="radio-btn-tds" />
                  </span>
                }
                isDisabled={false}
                formik={props.formik}
                onClick={(e) => {
                  props.formik.setFieldValue("invoicing_option", e);
                }}
              />
            </div>
          )}
        <div className="date-selector-wrapper reactive-subscription-margin row">
          <div className="mb-3">
            <label
              htmlFor="comments"
              className="form-label form-labels required"
            >
              Comments
            </label>
            <textarea
              className={`form-control `}
              id="comments"
              name="comments"
              value={props.formik.values.comments}
              placeholder="Comments"
              onChange={(e) =>
                props.formik.setFieldValue("comments", e.target.value)
              }
            ></textarea>
            {props.formik.touched.comments && props.formik.errors.comments && (
              <span className="error">Please enter comments</span>
            )}
          </div>
        </div>
        {Object.keys(details).length > 0 &&
        !props.formik.errors.next_invoice_billing_date ? (
          <div className="date-selector-wrapper reactive-subscription-margin row">
            <div className="subscription-org subscription-preview-container">
              <div
                className="subscription-popup-preview-cart"
                style={{ width: "100%" }}
              >
                <div className="subscription-preview-cart-header-container">
                  <div className="subscription-preview-cart-header subscription-preview-cart-header-heading">
                    {details.document === "CN" ? "Credit Note" : "Next Invoice"}
                  </div>
                  <div className="subscription-preview-cart-header">
                    {/* <a href="#">View Preview</a> */}
                  </div>
                </div>
                <div className="subscription-preview-cart-data-cols">
                  <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                    {details.document === "CN"
                      ? "Credit note date"
                      : "Next invoice date"}
                  </div>
                  <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                    {nextInvoiceDateShow && nextInvoiceDateShow?.length > 10
                      ? formateDateMonth(
                          DateFormatHandler(dateFormat(nextInvoiceDateShow))
                        )
                      : formateDateMonth(
                          DateFormatHandler(nextInvoiceDateShow)
                        )}
                  </div>
                </div>
                {details.document === "CN" && (
                  <div className="subscription-preview-cart-data-cols">
                    <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                      {details.document === "CN"
                        ? "Credit note period"
                        : "Next invoice period"}
                    </div>
                    <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                      {formateDateMonth(details.cn_period.split(" - ")[0])} to{" "}
                      {formateDateMonth(details.cn_period.split(" - ")[1])}
                    </div>
                  </div>
                )}
                <div className="subscription-preview-cart-data-cols">
                  <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                    {details.document === "CN"
                      ? "Credit note amount"
                      : "Next invoice amount"}
                  </div>
                  <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                    {`${props?.currencyCode || ""} ${NumberFormat(
                      Number(
                        details.document === "CN"
                          ? details.cn_amount
                          : details.invoice_amount
                      ),
                      props?.currencyCode || "",
                      orgCurrencyList
                    )}`}
                  </div>
                </div>
                <div
                  className="subscription-preview-cart-data-cols edit-next-invoicing-date-edit-breakdown"
                  style={{ justifyContent: "start" }}
                >
                  <SubscriptionInvoiceBreakdownNextInvoiceDate
                    invoiceIndex={"next"}
                    currencyCode={props.currencyCode ? props.currencyCode : ""}
                    taxes={{}}
                    tdsLevel={""}
                    payload={
                      details.document === "CN"
                        ? details.cn_preview
                          ? details.cn_preview
                          : {}
                        : details.invoice_preview
                        ? details.invoice_preview
                        : {}
                    }
                    getInvoiceForPreview={async () => {}}
                    type={details.document}
                  />
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EditNextInvoiceDateModal;
