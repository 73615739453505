import { Container } from "../../../common/components/htmlTags/Container";
import { Form } from "../../../common/components/htmlTags/Form";
import { useEffect, useState } from "react";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import { setLoaderState } from "../../../common/commonSlice";
import { FormSelectField, SaveButton } from "../../../common/components";
import { OrgFiscalYearSelect } from "../../../common/components/orgFiscalYearSelect/OrgFiscalYearSelect";
import useCommonData from "../../../hooks/useCommon";
import { useMeApiTrigger } from "../../../hooks/useTriggerOrgDetails";
import {
  getOrgTimezoneList,
  updateFiscalYear,
  updateOrgTimezone,
} from "../../organizationProfile/OrganizationProfileSlice";
import "./FiscalYearPopup.css";
import { InfoMessage } from "../../../common/components/infos/InfoMessage";
import { ObjectType } from "../../../../types";
import Select from "react-select";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import { FieldContainer } from "../../../common/components/refund";

type Props = {
  setDialogOpen: (shouldOpen: boolean) => void;
};
export const FiscalYearPopupForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { triggerMeApi } = useMeApiTrigger();
  const [timeZoneList, setTimeZoneList] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedFiscalOption, setSelectedFiscalOption] = useState<string>("");
  const [selectedTimeZone, setSelectedTimeZone] = useState<{
    label: string;
    value: string;
  }>({ label: "", value: "" });
  useEffect(() => {
    getTimezones();
  }, [currentUserInfo.organization_id]);
  const handleFiscalYearChange = (fiscalYear: string) => {
    setSelectedFiscalOption(fiscalYear);
  };
  const handleSubmit = async () => {
    dispatch(setLoaderState(true));
    let promiseArray = [];
    if (!currentUserInfo.timezone) {
      promiseArray.push(
        dispatch(
          updateOrgTimezone({
            orgId: currentUserInfo.organization_id,
            timezone: selectedTimeZone.value,
          })
        )
      );
    }
    if (!currentUserInfo.organization_fiscal_year) {
      promiseArray.push(
        dispatch(
          updateFiscalYear({
            orgId: currentUserInfo.organization_id,
            fiscalYear: selectedFiscalOption,
          })
        )
      );
    }
    try {
      const [responseAction, timezoneResponseAction] = await Promise.all(
        promiseArray
      );
      const response = responseAction.payload;
      const timezoneResponse = timezoneResponseAction.payload;

      if (!("error" in response)) {
        SuccessToaster(
          "Fiscal year updated successfully!",
          "fiscal-year-update-success"
        );
      }

      if (!("error" in timezoneResponse)) {
        SuccessToaster(
          "Timezone updated successfully!",
          "timezone-update-success"
        );
      }

      if (
        (!("error" in response) && currentUserInfo.organization_fiscal_year) ||
        (!("error" in timezoneResponse) && currentUserInfo.timezone)
      ) {
        alert("Hi");
        props.setDialogOpen(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      dispatch(setLoaderState(false));
      triggerMeApi();
    }
  };
  const getTimezones = async () => {
    const responseAction = await dispatch(
      getOrgTimezoneList({ orgId: currentUserInfo.organization_id })
    );
    const response = responseAction.payload;
    if (response) {
      let zones = Object.keys(response).map((key) => {
        return {
          value: key,
          label: `${key} (${response[key]})`,
        };
      });
      setTimeZoneList(zones);
    }
  };
  return (
    <Form className="fiscal-year-popup-form">
      <Container className="w-100 ">
        <Container className="fiscal-year-info-message mb-4">
          <InfoMessage
            message={`Please select ${
              !currentUserInfo.timezone &&
              !currentUserInfo.organization_fiscal_year
                ? "a fiscal year and timezone"
                : !currentUserInfo.timezone &&
                  currentUserInfo.organization_fiscal_year
                ? "a timezone"
                : "a fiscal year"
            } for your organization before proceeding.`}
          />
        </Container>
        {!currentUserInfo.organization_fiscal_year && (
          <FieldContainer
            label="Fiscal Year"
            required={true}
            wrapperClass="mb-0"
            isMarginBottomEnabled={
              currentUserInfo.organization_fiscal_year ? false : true
            }
          >
            <OrgFiscalYearSelect
              handleFiscalYearChange={handleFiscalYearChange}
              fiscalYear={selectedFiscalOption}
              isDisabled={false}
            />
          </FieldContainer>
        )}
        {!currentUserInfo.timezone && (
          <FieldContainer label="Timezone" required={true}>
            <Select
              name="timezone"
              id="timezone"
              className=""
              value={
                Object.values(selectedTimeZone || {}).some((val) => val !== "")
                  ? selectedTimeZone
                  : ""
              }
              options={timeZoneList}
              styles={customSelectStyle}
              onChange={(e) => {
                setSelectedTimeZone({
                  label: (e as any)?.label ? (e as any)?.label : "",
                  value: (e as any)?.value ? (e as any)?.value : "",
                });
              }}
              placeholder={"Choose timezone"}
              isClearable={true}
              isSearchable={true}
              isDisabled={false}
            />
          </FieldContainer>
        )}
        <Container>
          <SaveButton
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            isDisabled={
              currentUserInfo.organization_fiscal_year
                ? !Object.values(selectedTimeZone).some((val) => val !== "")
                : currentUserInfo.timezone
                ? !selectedFiscalOption
                : !currentUserInfo.organization_fiscal_year &&
                  !currentUserInfo.timezone
                ? !Object.values(selectedTimeZone).some((val) => val !== "") ||
                  !selectedFiscalOption
                : false
            }
            buttonContent="Continue"
            buttonClassName="fiscal-year-popup-submit-btn"
          />
        </Container>
      </Container>
    </Form>
  );
};
