import { useAppSelector } from "../../../../app/hooks";
import { IsFeatureEnabled } from "../../../../helpers/featureFlagHelper";
import { currentUserSelector } from "../../../common/commonSlice";
import { AccountsIcon } from "../../../common/components/customSvgIcons/accountsIcon";
import { DashboardIcon } from "../../../common/components/customSvgIcons/dashboardIcon";
import { OrgHeirarchyIcon } from "../../../common/components/customSvgIcons/orgHeirarchyIcon";
import { PurchasesIcon } from "../../../common/components/customSvgIcons/purchasesIcon";
import { ReportsIcon } from "../../../common/components/customSvgIcons/reportsIcon";
import { SalesIcon } from "../../../common/components/customSvgIcons/salesIcon";
import { SubscriptionIcon } from "../../../common/components/customSvgIcons/subscriptionIcon";
import { UserRolesIcon } from "../../../common/components/customSvgIcons/userRolesIcon";
import { ItemsLogo } from "../../../common/components/customSvgIcons/ItemsLogo";
import { colors } from "../../../constants/colors";

export const SideBarLinks = () => {
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let svEnabled = process.env.REACT_APP_SV_ENABLED;
  let bankingEnabled = process.env.REACT_APP_BANKING_ENABLED;
  let usersPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Users")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  const currentUserInfo = useAppSelector(currentUserSelector);
  return [
    {
      isAccordion: false,
      props: {
        moduleName: "Dashboard",
        icon: <DashboardIcon color={colors.charcoalGrey} />,
        subModulePermissionToCheck: [],
        path: "/",
        pathsToCheck: ["/"],
      },
    },
    bankingEnabled === "true" && {
      isAccordion: false,
      props: {
        moduleName: "Banking",
        icon: <DashboardIcon color={colors.charcoalGrey} />,
        subModulePermissionToCheck: [],
        path: "/banking",
        pathsToCheck: ["/banking"],
      },
    },
    {
      isAccordion: false,
      props: {
        moduleName: "Items",
        icon: <ItemsLogo color={colors.charcoalGrey} />,
        subModulePermissionToCheck: ["Item"],
        path: "/items",
        pathsToCheck: ["/items"],
      },
    },
    {
      isAccordion: true,
      props: {
        accordionTitle: "Sales",
        icon: <SalesIcon color={colors.charcoalGrey} />,
        moduleClass: "sales",
        modulePathsToCheck: [
          "customers",
          "invoice",
          "credit-note",
          "payment-received",
          "recurring-inv",
          "proforma-inv",
        ],
        modulePermissionsToCheck: [
          "Customer",
          "Invoices",
          "Credit Notes",
          "Payments Received",
          "Recurring Invoices",
          "Proforma Invoice",
        ],
        accordionSubItems: [
          {
            subModulePermissionToCheck: "Customer",
            path: "customers",
            subModuleName: "Customers",
            subModulePathToCheck: ["customers"],
          },
          {
            subModulePermissionToCheck: "Invoices",
            path: "invoice",
            subModuleName: "Invoices",
            subModulePathToCheck: ["invoice"],
          },
          {
            subModulePermissionToCheck: "Credit Notes",
            path: "credit-note",
            subModuleName: "Credit Notes",
            subModulePathToCheck: ["credit-note"],
          },
          {
            subModulePermissionToCheck: "Payments Received",
            path: "payment-received",
            subModuleName: "Payments Received",
            subModulePathToCheck: ["payment-received"],
          },
          {
            subModulePermissionToCheck: "Recurring Invoices",
            path: "recurring-inv",
            subModuleName: "Recurring Invoices",
            subModulePathToCheck: ["recurring-inv"],
          },
          {
            subModulePermissionToCheck: "Proforma Invoice",
            path: "proforma-inv",
            subModuleName: "Proforma Invoices",
            subModulePathToCheck: ["proforma-inv"],
          },
        ],
      },
    },
    {
      isAccordion: true,
      props: {
        accordionTitle: "Purchases",
        icon: <PurchasesIcon color={colors.charcoalGrey} />,
        moduleClass: "purchase",
        modulePathsToCheck: [
          "vendors",
          "bill",
          "debit-note",
          "payment-made",
          "purchase-order",
        ],
        modulePermissionsToCheck: [
          "Vendor",
          "Bills",
          "Debit Notes",
          "Payments Made",
          "Purchase Order",
        ],
        accordionSubItems: [
          {
            subModulePermissionToCheck: "Vendor",
            path: "vendors",
            subModuleName: "Vendors",
            subModulePathToCheck: ["vendors"],
          },
          {
            subModulePermissionToCheck: "Bills",
            path: "bill",
            subModuleName: "Bills",
            subModulePathToCheck: ["bill"],
          },
          {
            subModulePermissionToCheck: "Debit Notes",
            path: "debit-note",
            subModuleName: "Debit Notes",
            subModulePathToCheck: ["debit-note"],
          },
          {
            subModulePermissionToCheck: "Payments Made",
            path: "payment-made",
            subModuleName: "Payments Made",
            subModulePathToCheck: ["payment-made"],
          },
          {
            subModulePermissionToCheck: "Purchase Order",
            path: "purchase-order",
            subModuleName: "Purchase Order",
            subModulePathToCheck: ["purchase-order"],
          },
        ],
      },
    },
    {
      isAccordion: true,
      props: {
        accordionTitle: "Accounts",
        icon: <AccountsIcon color={colors.charcoalGrey} />,
        moduleClass: "accounts",
        modulePathsToCheck: [
          "accounts",
          "journal-entry",
          "transaction-locking",
          "fiscal-year-closing",
          "system-voucher",
          "budget",
        ],
        modulePermissionsToCheck: [
          "Chart of Account",
          "Journal Entry",
          "System Voucher",
          "Transaction Locking",
          "Fiscal Year Closing",
          "Budget",
        ],
        accordionSubItems: [
          {
            subModulePermissionToCheck: "Chart of Account",
            path: "accounts/list",
            subModuleName: "Chart of Account",
            subModulePathToCheck: ["accounts"],
          },
          {
            subModulePermissionToCheck: "Journal Entry",
            path: "journal-entry",
            subModuleName: "Journal Entry",
            subModulePathToCheck: ["journal-entry"],
          },
          svEnabled === "true" && {
            subModulePermissionToCheck: "System Voucher",
            path: "system-voucher",
            subModuleName: "System Voucher",
            subModulePathToCheck: ["system-voucher"],
          },
          {
            subModulePermissionToCheck: "Transaction Locking",
            path: "transaction-locking",
            subModuleName: "Transaction Locking",
            subModulePathToCheck: ["transaction-locking"],
          },
          {
            subModulePermissionToCheck:
              "Chart of Account" /* To be changed after permission matrix gets updated */,
            path: "fiscal-year-closing",
            subModuleName: "Fiscal Year Closing",
            subModulePathToCheck: ["fiscal-year-closing"],
          },
          {
            subModulePermissionToCheck: "Budget",
            path: "budget",
            subModuleName: "Budget",
            subModulePathToCheck: ["budget"],
          },
        ],
      },
    },
    {
      isAccordion: true,
      props: {
        accordionTitle: "Subscription",
        icon: <SubscriptionIcon color={colors.charcoalGrey} />,
        moduleClass: "subscription",
        modulePathsToCheck: [
          "plan",
          "addons",
          "coupons",
          "subscriptions",
          "charges",
        ],
        modulePermissionsToCheck: [
          "Plans",
          "Addons",
          "Coupons",
          "Subscriptions",
          "Charges",
        ],
        accordionSubItems: [
          {
            subModulePermissionToCheck: "Plans",
            path: "plan",
            subModuleName: "Plans",
            subModulePathToCheck: ["plan"],
          },
          {
            subModulePermissionToCheck: "Addons",
            path: "addons",
            subModuleName: "Addons",
            subModulePathToCheck: ["addons"],
          },
          {
            subModulePermissionToCheck: "Charges",
            path: "charges",
            subModuleName: "Charges",
            subModulePathToCheck: ["charges"],
          },
          {
            subModulePermissionToCheck: "Coupons",
            path: "coupons",
            subModuleName: "Coupons",
            subModulePathToCheck: ["coupons"],
          },
          {
            subModulePermissionToCheck: "Subscriptions",
            path: "subscriptions",
            subModuleName: "Subscriptions",
            subModulePathToCheck: ["subscriptions"],
          },
        ],
      },
    },
    {
      isAccordion: true,
      props: {
        accordionTitle: "Reports",
        icon: <ReportsIcon color={colors.charcoalGrey} />,
        moduleClass: "reports",
        modulePathsToCheck: [
          "soa",
          "trial-balance",
          "profit-and-loss",
          "balance-sheet",
          "account-transactions",
          "day-book",
          "aging-report",
          "vendor-balance",
          "customer-balance",
          "payable-summary",
          "receivable-summary",
          "sundry-debtor-aging-summary",
          "sundry-debtor-aging-details",
          "sundry-creditor-aging-summary",
          "sundry-creditor-aging-details",
          "variance-report",
          "summary-report",
        ],
        modulePermissionsToCheck: [
          "Statement of Accounts",
          "Trial Balance",
          "Profit and Loss",
          "Balance Sheet",
          "Account Transactions & Day Book",
          "Sundry Debtor Aging Summary",
          "Sundry Debtor Aging Details",
          "Sundry Creditor Aging Summary",
          "Sundry Creditor Aging Details",
          "Receivable Summary",
          "Payable Summary",
          "Customer Balance",
          "Vendor Balance",
          "Budget",
          "Budget",
        ],
        accordionSubItems: [
          {
            subModulePermissionToCheck: "Statement of Accounts",
            path: "soa",
            subModuleName: "Statement of Accounts",
            subModulePathToCheck: ["soa"],
          },
          {
            subModulePermissionToCheck: "Trial Balance",
            path: "trial-balance",
            subModuleName: "Trial Balance",
            subModulePathToCheck: ["trial-balance"],
          },
          {
            subModulePermissionToCheck: "Profit and Loss",
            path: "profit-and-loss",
            subModuleName: "Profit and Loss",
            subModulePathToCheck: ["profit-and-loss"],
          },
          {
            subModulePermissionToCheck: "Balance Sheet",
            path: "balance-sheet",
            subModuleName: "Balance Sheet",
            subModulePathToCheck: ["balance-sheet"],
          },
          {
            subModulePermissionToCheck: "Account Transactions & Day Book",
            path: "account-transactions",
            subModuleName: "Account Transactions",
            subModulePathToCheck: ["account-transactions"],
          },
          {
            subModulePermissionToCheck: "Account Transactions & Day Book",
            path: "day-book",
            subModuleName: "Day Book",
            subModulePathToCheck: ["day-book"],
          },
          {
            isExpandable: true,
            subModulePermissionToCheck: [
              "Sundry Debtor Aging Summary",
              "Sundry Debtor Aging Details",
              "Sundry Creditor Aging Summary",
              "Sundry Creditor Aging Details",
            ],
            path: "#",
            subModuleName: "Aging Report",
            subModulePathToCheck: [
              "aging-report",
              "sundry-debtor-aging-summary",
              "sundry-debtor-aging-details",
              "sundry-creditor-aging-summary",
              "sundry-creditor-aging-details",
            ],
            childItems: [
              {
                subModulePermissionToCheck: "Sundry Debtor Aging Summary",
                path: "sundry-debtor-aging-summary",
                subModuleName: "Sundry Debtor Aging Summary",
                subModulePathToCheck: ["sundry-debtor-aging-summary"],
              },
              {
                subModulePermissionToCheck: "Sundry Debtor Aging Details",
                path: "sundry-debtor-aging-details",
                subModuleName: "Sundry Debtor Aging Details",
                subModulePathToCheck: ["sundry-debtor-aging-details"],
              },
              {
                subModulePermissionToCheck: "Sundry Creditor Aging Summary",
                path: "sundry-creditor-aging-summary",
                subModuleName: "Sundry Creditor Aging Summary",
                subModulePathToCheck: ["sundry-creditor-aging-summary"],
              },
              {
                subModulePermissionToCheck: "Sundry Creditor Aging Details",
                path: "sundry-creditor-aging-details",
                subModuleName: "Sundry Creditor Aging Details",
                subModulePathToCheck: ["sundry-creditor-aging-details"],
              },
            ],
          },
          {
            subModulePermissionToCheck: "Vendor Balance",
            path: "vendor-balance",
            subModuleName: "Vendor Balance",
            subModulePathToCheck: ["vendor-balance"],
          },
          {
            subModulePermissionToCheck: "Customer Balance",
            path: "customer-balance",
            subModuleName: "Customer Balance",
            subModulePathToCheck: ["customer-balance"],
          },
          {
            subModulePermissionToCheck: "Receivable Summary",
            path: "receivable-summary",
            subModuleName: "Receivable Summary",
            subModulePathToCheck: ["receivable-summary"],
          },
          {
            subModulePermissionToCheck: "Payable Summary",
            path: "payable-summary",
            subModuleName: "Payable Summary",
            subModulePathToCheck: ["payable-summary"],
          },
          {
            isExpandable: true,
            subModulePermissionToCheck: "Budget",
            path: "#",
            subModuleName: "Budget Report",
            subModulePathToCheck: ["variance-report", "summary-report"],
            childItems: [
              {
                subModulePermissionToCheck: "Budget",
                path: "variance-report",
                subModuleName: "Budget Variance Report",
                subModulePathToCheck: ["variance-report"],
              },
              {
                subModulePermissionToCheck: "Budget",
                path: "summary-report",
                subModuleName: "Budget Summary Report",
                subModulePathToCheck: ["summary-report"],
              },
            ],
          },
          // {
          //   subModulePermissionToCheck: "Budget",
          //   path: "summary-report",
          //   subModuleName: "Budget Summary Report",
          //   subModulePathToCheck: ["summary-report"],
          // },
        ],
      },
    },
    {
      isAccordion: false,
      props: {
        moduleName: "Users & Roles",
        icon: <UserRolesIcon color={colors.charcoalGrey} />,
        subModulePermissionToCheck: ["Users", "Roles"],
        path:
          usersPermission.length === 0 ||
          !IsFeatureEnabled("Users", currentUserInfo.disabled_feature)
            ? "/roles"
            : "/users",
        pathsToCheck: ["users", "roles"],
      },
    },
    {
      isAccordion: false,
      props: {
        moduleName: "Org. Hierarchy",
        icon: <OrgHeirarchyIcon color={colors.charcoalGrey} />,
        subModulePermissionToCheck: ["Organization Hierarchy"],
        path: "/organization/hierarchy",
        pathsToCheck: ["/organization/hierarchy"],
      },
    },
  ];
};
