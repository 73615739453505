import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DocIconSquare, Locator } from "../../../../../assets/images";
import { addonDetails } from "../../addonSlice";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import { useAppSelector } from "../../../../../app/hooks";
import {
  customFrequencySettingsSelector,
  fetchCustomFrequencySettings,
  setLoaderState,
} from "../../../../common/commonSlice";
import {
  DeleteInitialData,
  AddonDetailData,
  PricingListData,
} from "../../addonType";
import {
  deleteInitialData,
  addonDetailInitialData,
  defaultFrequencyCol,
} from "../../AddonStateInitialization";
import useCommonData from "../../../../hooks/useCommon";
import AddonPricingTable from "./AddonPricingTable";
import AddonViewHeader from "./AddonViewHeader";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";
import ItemDetails from "../../../../common/components/detailsPage/ItemDetails";
import "./AddonDetail.css";

const AddonDetailBody = () => {
  const customFrequencyList = useAppSelector(customFrequencySettingsSelector);
  const { addonDetailId } = useParams();
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const [activeTab, setActiveTab] = useState<string>("overviewAndPricing");
  const [addonDetailData, setAddonDetailData] = useState<AddonDetailData>(
    addonDetailInitialData
  );
  const [deleteAction, setDeleteAction] =
    useState<DeleteInitialData>(deleteInitialData);

  const [customFrequencyArraySave, setCustomFrequencyArraySave] = useState<
    any[]
  >([]);

  /**
   * Setting active tab in session storage
   */
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    sessionStorage.setItem("addons", tab);
  };

  useEffect(() => {
    const storedTab = sessionStorage.getItem("addons");
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);
  /**
   * Fetch Addon Details and Pricing List
   */
  useEffect(() => {
    if (addonDetailId) {
      fetchAddonDetail();
    }
  }, [currentUserInfo.organization_id, addonDetailId]);

  const fetchAddonDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      addonDetails({
        addonId: Number(addonDetailId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);

      const response = responseAction.payload;

      if (Object.keys(response).length && !("error" in response)) {
        const modifiedPricingValue = await getModifiedPricingValue(response);

        const modifiedResponse = {
          ...response,
          pricings: modifiedPricingValue,
        };

        setAddonDetailData({ ...modifiedResponse });
      } else {
        dispatch(setErrorID(noAccessErrorCodes.invoiceDetail));
        navigate("/addons");
      }
    }
  };
  const onClickDelete = (actionType: string, id: number, addonName: string) =>
    setDeleteAction({
      actionType: actionType,
      id: id,
      addonName: addonName,
    });

  function convertToPlural(input: string): string {
    const conversionMap: Record<string, string> = {
      MONTHLY: "MONTHS",
      WEEKLY: "WEEKS",
      YEARLY: "YEARS",
      DAILY: "DAYS",
    };
    return conversionMap[input] || input;
  }

  const checkFrequencyCount = (eachFre: any, data: string) => {
    const modifiedFrequency =
      eachFre.custom_repeat_count + " " + convertToPlural(eachFre.frequency);
    const isTrue =
      modifiedFrequency === data.split("-")[1] &&
      eachFre.currency_code === data.split("-")[0].toUpperCase()
        ? true
        : false;

    return isTrue;
  };

  // const getModifiedPricingValue = (pricingList: PricingListData[]) => {
  //   return pricingList && pricingList.length > 0
  //     ? customFrequencyArray.map((data: string) =>
  //         pricingList.find((eachFre: any) => {
  //           return eachFre.custom_repeat_count <= 0
  //             ? eachFre.frequency === data
  //             : checkFrequencyCount(eachFre, data);
  //         })
  //           ? {
  //               ...pricingList.find((eachFre: PricingListData) =>
  //                 eachFre.custom_repeat_count <= 0
  //                   ? eachFre.frequency === data.toString()
  //                   : checkFrequencyCount(eachFre, data.toString())
  //               ),
  //               isPriceSet: true,
  //               frequency: data.toString(),
  //             }
  //           : { ...defaultFrequencyCol, frequency: data }
  //       )
  //     : [];
  // };

  const getModifiedPricingValue = async (pricingList: any) => {
    const responseAction = await dispatch(
      fetchCustomFrequencySettings(currentUserInfo.organization_id)
    );

    if (responseAction.payload) {
      const customFrequencyList = await responseAction.payload;
      const allCurrency = Object.values(pricingList.currency_dict);
      const customFrequencyArray: any[] = [];
      customFrequencyList.length > 0 &&
        customFrequencyList.map((item: any) => {
          allCurrency.map((currency: any) => {
            if (item.is_enabled) {
              customFrequencyArray.push(
                currency + "-" + item.frequency.toUpperCase()
              );
            }
          });
        });

      setCustomFrequencyArraySave(customFrequencyArray);

      return pricingList.pricings && pricingList.pricings.length > 0
        ? customFrequencyArray.map((data: string) =>
            pricingList.pricings.find((eachFre: any) => {
              return eachFre.custom_repeat_count <= 0
                ? eachFre.frequency === data.split("-")[1] &&
                    eachFre.currency_code === data.split("-")[0].toUpperCase()
                : checkFrequencyCount(eachFre, data);
            })
              ? {
                  ...pricingList.pricings.find((eachFre: PricingListData) =>
                    eachFre.custom_repeat_count <= 0
                      ? eachFre.frequency === data.split("-")[1].toString() &&
                        eachFre.currency_code ===
                          data.split("-")[0].toUpperCase()
                      : checkFrequencyCount(eachFre, data.toString())
                  ),
                  isPriceSet: true,
                  frequency: data.split("-")[1],
                }
              : {
                  ...defaultFrequencyCol,
                  frequency: data.split("-")[1],
                  currency: data.split("-")[0],
                }
          )
        : [];
    }
  };

  const getCurrencyCode = (pricings: any) => {
    if (Array.isArray(pricings)) {
      for (const pricing of pricings) {
        if (
          Array.isArray(pricing.item_price) &&
          pricing.item_price.length > 0
        ) {
          return pricing.item_price[0].currency_code || "N/A";
        }
      }
    }
    return "N/A";
  };
  const currencyCode = getCurrencyCode(addonDetailData.pricings);

  return (
    <div>
      <div
        className="card card-dashboard customer-detail-card w-100 white-space-break-spaces"
        id="customer-detail-card"
      >
        <AddonViewHeader
          addonDetailData={addonDetailData}
          deleteAction={deleteAction}
          onClickDelete={onClickDelete}
          refreshAddon={fetchAddonDetail}
        />
        <div className="tab-container w-100 px-0">
          <ul
            className="nav nav-pills pills-tab mt-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={
                  activeTab === "overviewAndPricing"
                    ? "nav-link active"
                    : "nav-link"
                }
                id="pills-overview-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-overview"
                type="button"
                role="tab"
                aria-controls="pills-overview"
                aria-selected="true"
                onClick={() => handleTabClick("overviewAndPricing")}
              >
                Overview & Pricing
              </button>
            </li>
          </ul>
          <div className="tab-content pb-0" id="pills-tabContent">
            <div
              className={
                activeTab === "overviewAndPricing"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-overview"
              role="tabpanel"
              aria-labelledby="pills-overview-tab"
              tabIndex={0}
            >
              <div className="tab-content-overview addon-overview">
                <div className="overview-wrapper tab-content-wrapper">
                  <section className="section-blocks addr-section addon-blocks">
                    <h2>
                      <img
                        src={DocIconSquare}
                        width="24"
                        height="24"
                        alt="Other details"
                      />
                      Item Details
                    </h2>
                    <div className="address-wrapper section-content-wrapper flex-nowrap">
                      <div className="address-container">
                        {addonDetailData.item_detail &&
                        addonDetailData.item_detail.length > 0
                          ? addonDetailData.item_detail.map(
                              (itemDetail, index) => (
                                <ItemDetails
                                  itemDetail={itemDetail}
                                  index={index}
                                  currencyCode={""}
                                />
                              )
                            )
                          : null}
                      </div>
                    </div>
                  </section>
                  <section className="section-blocks ">
                    <h2>
                      <img
                        src={Locator}
                        width="24"
                        height="24"
                        alt="Other details"
                      />
                      Pricing
                    </h2>
                    <div className="main-card overflowX-hidden h-100">
                      <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
                        <AddonPricingTable
                          pricingListData={
                            addonDetailData && addonDetailData.pricings
                          }
                          organizationId={addonDetailData.organization_id}
                          frequencyRow={customFrequencyArraySave}
                          onClickDelete={onClickDelete}
                          addonId={addonDetailData.id}
                          addonStatus={addonDetailData.addon_status}
                          currencyCode={getCurrencyCode(
                            addonDetailData.pricings
                          )}
                          currencyDict={addonDetailData.currency_dict}
                        />
                      </div>
                    </div>
                  </section>
                  <section>
                    <AuditTrail
                      title="Audit Trail"
                      transactionType="addons"
                      transactionId={Number(addonDetailId)}
                    />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddonDetailBody;
