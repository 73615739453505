import React, { ButtonHTMLAttributes } from "react";
import "./RawButton.css"
export const RawButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  const { children, className, ...rest } = props;
  return (
    <button className={`reusable-raw-btn ${className || ""}`} {...rest}>
      {children}
    </button>
  );
};
