export const deleteInitialData = {
  actionType: "",
  id: -1,
  addonName: "",
};

export const chooseDayOption = [
  { label: "Sunday", value: "SUNDAY" },
  { label: "Monday", value: "MONDAY" },
  { label: "Tuesday", value: "TUESDAY" },
  { label: "Wednesday", value: "WEDNESDAY" },
  { label: "Thursday", value: "THURSDAY" },
  { label: "Friday", value: "FRIDAY" },
  { label: "Saturday", value: "SATURDAY" },
];

export const selectFrequencyOption = [
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Yearly", value: "YEARLY" },
];

export const tagsListObject = [
  { label: "tag1", value: "tag1" },
  { label: "tag2", value: "tag2" },
  { label: "tag3", value: "tag3" },
  { label: "tag4", value: "tag4" },
];

export const planInvoicingInitialValue = {
  invoicing_frequency: "",
  invoicing_currency: "",
  invoicing_type: "AUTO_SET",
  set_date: "",
  set_day: "",
  choose_plan: [],
};

export const frequencyFilters = [
  { id: 1, name: "Weekly" },
  { id: 2, name: "Monthly" },
  { id: 3, name: "Yearly" },
];
