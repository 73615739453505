import { createAsyncThunk } from "@reduxjs/toolkit";
import { futureInvoicePreview, generateFutureInvoice } from "./FutureRenewalApi";

export const futureRenewalInvoicePreview = createAsyncThunk(
  "future/renewal/invoice/preview",
  async (data: {
    subscriptionId: number;
    numberOfRenewals: number;
    isUnbilled: boolean;
    orgId: number;
  }) => {
    return await futureInvoicePreview(data);
  }
);

export const generateFutureInvoices = createAsyncThunk(
  "future/renewal/invoice/generate",
  async (data: {
    subscriptionId: number;
    numberOfRenewals: number;
    isUnbilled: boolean;
    orgId: number;
  }) => {
    return await generateFutureInvoice(data);
  }
);

