import * as yup from "yup";

export const validatePlanSpecificForm = yup.object({
  invoicing_frequency: yup
    .string()
    .required("Please select a invoicing frequency"),
  invoicing_currency: yup
    .string()
    .required("Please select a invoicing currency"),
  set_date: yup
    .string()
    .test("set_date", "Please enter valid date.", function (value) {
      const { invoicing_type, invoicing_frequency } = this.parent as any;
      if (
        invoicing_type === "SET_DATE" &&
        invoicing_frequency !== "WEEKLY" &&
        (value === "Invalid date" || !value)
      ) {
        return false;
      }
      return true;
    }),
  set_day: yup
    .string()
    .test("set_day", "Please select the day.", function (value) {
      const { invoicing_type, invoicing_frequency } = this.parent as any;
      if (
        invoicing_type === "SET_DATE" &&
        invoicing_frequency === "WEEKLY" &&
        !value
      ) {
        return false;
      }
      return true;
    }),
  choose_plan: yup
    .array()
    .test("choose_plan", "Please select plan.", function (value) {
      if (value && value.length <= 0) {
        return false;
      }
      return true;
    }),
});
