import { SvgIconProp } from "../types";
/**
 * EditIcon Component
 *
 * A reusable SVG edit icon that can be customized via props.
 *
 * @component
 * @param {SvgIconProp} props - The properties for the EditIcon component.
 * @param {string} [props.width="16px"] - Width of the icon.
 * @param {string} [props.height="16px"] - Height of the icon.
 * @param {string} [props.rotate] - Rotation transformation (e.g., "rotate(45deg)").
 * @param {string} [props.color="currentColor"] - Fill color of the icon.
 * @returns {JSX.Element} The rendered EditIcon component.
 */
export const EditIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "16px"}
      height={props.height ? props.height : "16px"}
      viewBox={`0 0 ${
        props.width ? props.width.substring(0, props.width.length - 2) : "16"
      } ${
        props.height ? props.height.substring(0, props.height.length - 2) : "16"
      }`}
      style={{ transform: props.rotate, margin: props.margin || "" }}
    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 0h16v16H0z" />
        <path
          d="m10.485 6.457-.942-.942-6.21 6.209v.943h.943l6.21-6.21zm.943-.942.943-.943-.943-.943-.943.943.943.943zM4.828 14H2v-2.829l8.957-8.956c.26-.26.682-.26.942 0L13.785 4.1c.26.26.26.682 0 .942L4.83 14h-.001z"
          fill={props.color}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
