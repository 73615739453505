import React, { useMemo } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { PricingListData } from "../../addonType";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { defaultFrequencyCol } from "../../../plan/PlanStateInitialization";
import {
  coustomCase,
  pricingModuleRenderValue,
} from "../../../../../helpers/planHelper";
import {
  currentUserSelector,
  customFrequencySettingsSelector,
} from "../../../../common/commonSlice";
import useCommonData from "../../../../hooks/useCommon";
import AddonPricingListActions from "./AddonPricingListActions";
import TableContainer from "../../../../common/components/table/TableContainer";
import CustomPriceColumn from "./AddonPriceColumn";
import TableSkeletonLoader from "../../../../common/components/ui/Loaders/TableSkeletonLoader/TableSkeletonLoader";

type Props = {
  onClickDelete: (actionType: string, id: number, addonName: string) => void;
  pricingListData: PricingListData[] | null;
  organizationId: number;
  frequencyRow: string[];
  addonId: number;
  addonStatus: string;
  currencyCode: string;
  currencyDict: any;
};
function PricingTable({
  onClickDelete,
  pricingListData,
  organizationId,
  frequencyRow,
  addonId,
  addonStatus,
  currencyCode,
  currencyDict,
}: Props) {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const customFrequencyList = useAppSelector(customFrequencySettingsSelector);
  const { navigate } = useCommonData();
  const { addonsRolePermission } = usePermissionHandler();
  let showEmptyTable =
    addonsRolePermission.includes("Read") &&
    addonsRolePermission.includes("Create") &&
    organizationId === currentUserInfo.organization_id &&
    !(addonStatus.toLowerCase() === "cancelled");

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Currency",
        accessor: "show.currency",
        sort: false,
        sortOrder: "",
        className: "plan_table_col",
      },
      {
        Header: "Frequency",
        accessor: "show.frequency",
        sort: false,
        sortOrder: "",
        className: "addon_table_col",
      },
      {
        Header: "Pricing Model",
        accessor: "show.pricingModel",
        sort: false,
        sortOrder: "",
        className: "addon_table_col",
      },
      {
        Header: "Price",
        accessor: "show.priceItem",
        sort: false,
        sortOrder: "",
        className: "addon_price",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomPriceColumn
              value={value}
              showPricingActions={showEmptyTable}
              isButtonVisible={
                value.price
                  ? addonsRolePermission.includes("Update")
                  : addonsRolePermission.includes("Create")
              }
            />
          );
        },
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return !addonsRolePermission.includes("Delete") ||
            !value.price ||
            value.addonStatus.toLowerCase() === "cancelled" ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <AddonPricingListActions
              value={value}
              onClickDelete={onClickDelete}
            />
          );
        },
      },
    ],
    [currentUserInfo.organization_id, addonsRolePermission, showEmptyTable]
  );

  function getKeyByValue(
    obj: { [key: number]: string },
    value: string
  ): number | undefined {
    const entry = Object.entries(obj).find(([_, v]) => v === value);
    return entry ? Number(entry[0]) : undefined;
  }

  /**
   * set table row data
   */
  const getPricingData = (addon: any, index: any) => ({
    show: {
      currency: addon.currency || addon.currency_code,
      frequency: coustomCase(addon.frequency),
      pricingModel:
        pricingModuleRenderValue.find(
          (eachPriceModel) => eachPriceModel.key === addon.pricing_model
        )?.value ?? "",
      priceItem: {
        item: addon.item_price,
        price: addon.isPriceSet,
        index: index,
        frequency: addon.frequency,
        pricingModel: addon.pricing_model,
        organizationId: organizationId,
        addonId: addonId,
        addonStatus: addonStatus,
        currencyCode: addon.currency_code
          ? addon.currency_code
          : addon.currency,
        pricingId: addon.pricing_id,
        currencyId: getKeyByValue(
          currencyDict,
          addon.currency || addon.currency_code
        ),
      },
      actions: {
        organizationId: organizationId,
        price: addon.isPriceSet,
        pricngId: addon.pricing_id,
        addonStatus: addonStatus,
        frequency: coustomCase(addon.frequency),
        pricingExistsSubscription: addon.pricing_exists_subscription,
        currencyCode: getKeyByValue(currencyDict, addon.currency),
      },
    },
  });

  const data = pricingListData
    ? pricingListData.map((addon, index) => getPricingData(addon, index))
    : [];

  const allCurrency = Object.values(currencyDict);
  const customFrequencyArray: any[] = [];
  customFrequencyList.length > 0 &&
    customFrequencyList.map((item: any) => {
      allCurrency.map((currency: any) => {
        if (item.is_enabled) {
          customFrequencyArray.push(
            currency + "-" + item.frequency.toUpperCase()
          );
        }
      });
    });

  const getDefaultPricingTableData = () =>
    customFrequencyArray.map((data: string) => ({
      ...defaultFrequencyCol,
      frequency: data.split("-")[1],
      currency: data.split("-")[0],
    }));

  const modifiedPricingData = getDefaultPricingTableData().map((addon, index) =>
    getPricingData(addon, index)
  );

  return (
    <div className="card-body">
      <div
        id="invoice-table-wrapper"
        className="list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer no-data addon-table"
      >
        <div className="row">
          <div className="col-md-12">
            {pricingListData && pricingListData.length > 0 ? (
              <TableContainer
                className="list-table table table-striped w-100"
                columns={columns}
                data={data}
                url={"/addons"}
                sortHandler={() => {}}
                editPermission={addonsRolePermission.includes("Update")}
                isDetailViewExist={true}
              >
                <TableSkeletonLoader colCount={6} rowCount={5} />
              </TableContainer>
            ) : (
              <TableContainer
                className="list-table table table-striped w-100"
                columns={columns}
                data={modifiedPricingData}
                url={"/addons"}
                sortHandler={() => {}}
                editPermission={addonsRolePermission.includes("Update")}
                isDetailViewExist={true}
              >
                <TableSkeletonLoader colCount={6} rowCount={5} />
              </TableContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PricingTable);
