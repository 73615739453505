import { CSSProperties } from "react";
interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  /** The content inside the container */
  children: React.ReactNode;
  /** Additional CSS classes */
  className?: string;
  /** Unique identifier for the container */
  id?: string;
  /** Inline styles for the container */
  style?: CSSProperties;
  /** ARIA role attribute */
  role?: string;
  /** ARIA attribute for labeling the container */
  ariaLabelledBy?: string;
  /** Ref for the container element */
  ref?: React.Ref<HTMLDivElement>;
}
/**
 * Container Component
 *
 * A flexible wrapper component that accepts standard div attributes.
 *
 * @component
 * @param {ContainerProps} props - The properties for the Container component.
 * @returns {JSX.Element} A styled div element that serves as a layout wrapper.
 */
export const Container = (props: ContainerProps): JSX.Element => {
  const { children, ...rest } = props;
  return (
    <div
      aria-labelledby={rest.ariaLabelledBy}
      {...rest} // Spread remaining props
    >
      {children}
    </div>
  );
};
