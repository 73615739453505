import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../app/hooks";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { PlanPriceDefaultValue, ObjectType } from "../../../../../types";
import { validate, validationTrailCountMsg } from "./ValidatePriceForm";
import { setLoaderState } from "../../../../common/commonSlice";
import { coustomCase } from "../../../../../helpers/planHelper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { message } from "../../../../constants/messages";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { SelectCustomStyleCustomer } from "../../../../common/components/SelectCustomStyleCustomer";
import { discountedTrialInitialValue } from "../../PlanStateInitialization";
import {
  FormInputField,
  FormSelectField,
  FormTextAreaField,
} from "../../../../common/components";
import {
  planItemList,
  planPricePointCreate,
  planPricePointEdit,
} from "../../planSlice";
import useCommonData from "../../../../hooks/useCommon";
import Select from "react-select";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import ScrollToError from "../../../../common/components/scrollToError";
import DiscountedTrialRange from "./DiscountedTrialRange";
import Grid from "@mui/material/Grid";
import Item from "./Item";
import "../../../recurringInvoice/components/recurringInvoiceForm/Invoice.css";
import "./defaultDetails.css";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";

type Props = {
  organizationId: number;
  frequency?: string;
  planId: number;
  priceDetails?: any;
  priceId?: any;
  planDetailData: any;
  checkInternalName: (internalName: string) => void;
  isInternalNameExist: boolean;
  currencyId?: number | string;
  currencyCode?: string;
};

interface OptionType {
  value: string;
  label: string;
}

const DefaultDetails: ForwardRefRenderFunction<any, Props> = (props, ref) => {
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();
  const initialDefaultValues = {
    frequency: props.frequency?.toUpperCase(),
    internal_name: "",
    external_name: "",
    description: "",
    items: [],
    customer_id: "",
    trial: false,
    pricing_model: "FLAT_FEE",
    billing_model: "",
    show_description: false,
    trial_count: 1,
    trial_frequency: "DAYS",
    currency_id: props.currencyId,
    invoicing_cycle: "FOREVER",
    plan_id: props.planId,
    total_price: 0,
    invoicing_cycle_fixed_count: 1,
    delete_item_pricing: [],
    discount_trial_ranges: discountedTrialInitialValue,
    delete_trial_range: [],
    discount_trial: false,
  };

  const [planItemListData, setPlanItemListData] = useState<any[]>([]);
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  const [plan, setPlan] = useState<any>({
    label: "Flat Fee",
    value: "FLAT_FEE",
  });

  const options: OptionType[] = [
    { label: "Day(s)", value: "DAYS" },
    { label: "Month(s)", value: "MONTHS" },
  ];

  const pricingModelOptions: OptionType[] = [
    { label: "Flat Fee", value: "FLAT_FEE" },
    { label: "Per Unit", value: "PER_UNIT" },
    { label: "Tiered", value: "TIERED" },
    { label: "Volume", value: "VOLUME" },
    { label: "Stairstep", value: "STAIRSTEP" },
  ];

  const billingCycleOptions: OptionType[] = [
    { label: "Forever", value: "FOREVER" },
    { label: "Fixed", value: "FIXED" },
  ];

  type LocationProps = {
    consumerId?: string;
  };
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const { customerId, editId } = useParams();
  const [defaultFormValues, setDefaultFormValues] =
    useState<PlanPriceDefaultValue>(initialDefaultValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});

  const [selectedOption, setSelectedOption] = useState<OptionType | null>(
    options[0]
  );
  const [selectedOptionPricingModel, setSelectedOptionPricingModel] = useState(
    pricingModelOptions[0]
  );

  const [selectedOptionBillingCycle, setSelectedOptionBillingCycle] = useState(
    billingCycleOptions[0]
  );

  const planId = props.planId;

  const fetchPlanItemList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      planItemList({
        planId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;

      if (Object.keys(response).length && !("error" in response)) {
        let totalAmount = 0;
        response.items.map(
          (item: {
            price: {
              range_from: string | number;
              from: string | number;
              to: string | number;
              price_per_step: string | number;
              price: any;
            }[];
            rate: string;
            currency_code: string;
          }) => {
            item.price = [
              {
                range_from: 1,
                from: "",
                to: "",
                price_per_step: "",
                price: Number(item.rate).toFixed(
                  decimalPlaceOfCurrency(item.currency_code, orgCurrencyList)
                ),
              },
            ];
            item.rate = Number(item.rate).toFixed(
              decimalPlaceOfCurrency(item.currency_code, orgCurrencyList)
            );
            return (totalAmount += Number(item.rate));
          }
        );
        setAmount(totalAmount);
        setPlanItemListData(response.items);

        // const optionPricingModel = pricingModelOptions.find((option) => option.value === props.priceDetails.pricing_model)
        // if(optionPricingModel) {
        //   setSelectedOptionPricingModel(optionPricingModel)
        //   setPlan(optionPricingModel);
        // }
      } else if ("error" in response) {
      }
    }
  }, [currentUserInfo.organization_id]);

  const capitalize = (text: string | undefined) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
    return "";
  };

  useEffect(() => {
    if (props.planDetailData && props.planDetailData.external_name) {
      props.checkInternalName(
        `${props.planDetailData.external_name} - ${capitalize(props.frequency)}`
      );
      setDefaultFormValues({
        ...defaultFormValues,
        internal_name: `${props.planDetailData.external_name} - ${capitalize(
          props.frequency
        )} - ${props.currencyCode}`,
        external_name: `${props.planDetailData.external_name} - ${capitalize(
          props.frequency
        )}`,
      });
    }
  }, [props.planDetailData]);

  useEffect(() => {
    const priceCopy = JSON.parse(JSON.stringify(props.priceDetails));
    delete priceCopy.items;
    setDefaultFormValues({ ...defaultFormValues, ...priceCopy });
    const optionPricingModel = pricingModelOptions.find(
      (option) => option.value === props.priceDetails.pricing_model
    );
    if (optionPricingModel) {
      setSelectedOptionPricingModel(optionPricingModel);
      setPlan(optionPricingModel);
    }
    if (props.priceDetails.invoicing_cycle)
      setSelectedOptionBillingCycle({
        label: coustomCase(props.priceDetails.invoicing_cycle),
        value: props.priceDetails.invoicing_cycle,
      });
    if (props.priceDetails.trial_frequency)
      setSelectedOption({
        label: coustomCase(
          props.priceDetails.trial_frequency.replace("S", "(s)")
        ),
        value: props.priceDetails.trial_frequency,
      });
    let totalAmount = 0;

    if (
      props.priceDetails &&
      props.priceDetails.items &&
      props.priceDetails.items.length
    ) {
      props.priceDetails.items.map(
        (item: {
          plan_item_id: any;
          steps: any[];
          price: any;
          free_quantity: any;
          item_pricing_id: any;
        }) => {
          const planData = planItemListData.find(
            (e) => item.plan_item_id === e.plan_item_id
          );
          if (planData) {
            if (
              props.priceDetails.pricing_model === "PER_UNIT" ||
              props.priceDetails.pricing_model === "FLAT_FEE"
            ) {
              planData.price = [];
              planData.price.push({
                price: item.price,
                free_quantity: item.free_quantity,
                item_pricing_id: item.item_pricing_id,
                range_from: 1,
                range_to: "",
                price_per_step: "",
              });
              totalAmount += Number(item.price);
            } else {
              planData.plan_item_id = item.plan_item_id;
              planData.price = item.steps;
              // planData.item_pricing_id=item.item_pricing_id
            }
            setAmount(totalAmount);
            return planData;
          }
        }
      );
    }
  }, [props.priceDetails, planItemListData]);

  useEffect(() => {
    fetchPlanItemList();
  }, [currentUserInfo.organization_id]);

  function isFloat(value: any) {
    const res = value.split(".");
    if (res[1]) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (props.isInternalNameExist) {
      setFormErrors({
        ...formErrors,
        internal_name:
          "Internal Name already exists. Please choose a different one",
      });
    } else {
      setFormErrors({ ...formErrors, internal_name: "" });
    }
  }, [props.isInternalNameExist]);

  const updateTotalAmount = () => {
    let totalAmount = 0;
    planItemListData.forEach(
      (item: {
        price: {
          from: string;
          to: string;
          price_per_step: string;
          price: number;
        }[];
        rate: number;
      }) => {
        item.price.forEach((priceObj) => {
          if (!isNaN(priceObj.price)) {
            totalAmount += Number(priceObj.price);
          }
        });
      }
    );
    setAmount(totalAmount);
  };
  const updateTotalPricePerStepAmount = () => {
    let totalAmount = 0;
    planItemListData.forEach(
      (item: {
        price: {
          from: string;
          to: string;
          price_per_step: string;
          price: number;
        }[];
        rate: number;
      }) => {
        item.price.forEach((priceObj) => {
          totalAmount += Number(priceObj.price_per_step);
        });
      }
    );
    setAmount(totalAmount);
  };

  const handleChange = (e: any, index: any = "") => {
    let { name, value, checked } = e.target;
    if (name === "internal_name") {
      props.checkInternalName(value);
    }

    if (
      (name === "trial_count" || name === "invoicing_cycle_fixed_count") &&
      [Number.isNaN(Number(value)), value === "0"].some(Boolean)
    ) {
      return;
    }
    if (name === "trial_count") {
      setFormErrors({
        ...formErrors,
        trial_count: validationTrailCountMsg(
          value,
          defaultFormValues.trial_frequency
        ),
      });
    }

    if (name == "show_description") {
      setDefaultFormValues({ ...defaultFormValues, [name]: checked });
    } else if (
      name === "trial_count" ||
      name === "invoicing_cycle_fixed_count"
    ) {
      setDefaultFormValues({
        ...defaultFormValues,
        [name]: `${value.replaceAll(".", "").trim()}`,
      });
    } else if (name == "trial") {
      if (["TRIAL_NO", "TRIAL_DISCOUNTED"].includes(value)) {
        setSelectedOption({ label: "Day(s)", value: "DAYS" });
      }
      setFormErrors({
        ...formErrors,
        trial_count: value !== "TRIAL_FREE" ? null : formErrors.trial_count,
        trialError: value !== "TRIAL_DISCOUNTED" ? [] : formErrors.trialError,
      });
      setDefaultFormValues({
        ...defaultFormValues,
        [name]: !(value === "TRIAL_NO"),
        trial_count: ["TRIAL_NO", "TRIAL_DISCOUNTED"].includes(value)
          ? 1
          : defaultFormValues.trial_count,
        trial_frequency: ["TRIAL_NO", "TRIAL_DISCOUNTED"].includes(value)
          ? "DAYS"
          : defaultFormValues.trial_frequency,
        discount_trial_ranges:
          value !== "TRIAL_DISCOUNTED" || !defaultFormValues.discount_trial
            ? [...discountedTrialInitialValue]
            : [...defaultFormValues.discount_trial_ranges],
        discount_trial: value === "TRIAL_DISCOUNTED",
      });
    } else {
      setDefaultFormValues({ ...defaultFormValues, [name]: value });
    }
  };

  const handleChangeSelect = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    setDefaultFormValues({
      ...defaultFormValues,
      trial_frequency: selectedOption.value,
    });
    setFormErrors({
      ...formErrors,
      trial_count: validationTrailCountMsg(
        defaultFormValues.trial_count,
        selectedOption.value
      ),
    });
  };

  const handleChangePrice = (e: any, index: any = "") => {
    e.preventDefault();
    const { name, value } = e.target;
    defaultFormValues.items[index].price = value;
    setDefaultFormValues({ ...defaultFormValues });
  };
  const handleFreeQuatity = (e: any, index: any = "") => {
    e.preventDefault();
    const { name, value } = e.target;
    defaultFormValues.items[index].free_quantity = value;
    setDefaultFormValues({ ...defaultFormValues });
  };

  const handleChangeFrom = (e: any, index: any = "", itemDetails: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }

    const { name, value } = e.target;

    if (
      (name === "range_to" ||
        name === "price_per_step" ||
        name === "price" ||
        name === "free_quantity" ||
        name === "range_from") &&
      Number.isNaN(Number(value))
    ) {
      return;
    }

    itemDetails.price[index][name] =
      name === "range_to" || name === "free_quantity"
        ? `${value.replaceAll(".", "").trim()}`
        : value;
    itemDetails.price[index]["currency_id"] = itemDetails.currency_id;
    itemDetails.price[index]["hsn_or_sac_code"] = itemDetails.hsn_or_sac_code;
    itemDetails.price[index]["item_name"] = itemDetails.item_name;
    itemDetails.price[index].plan_item_id = itemDetails.plan_item_id;
    if (!defaultFormValues.items) {
      defaultFormValues.items = [];
    }
    if (!defaultFormValues.items[index]) {
      defaultFormValues.items[index] = {};
      defaultFormValues.items[index]["range_from"] = parseFloat(value);
    }
    setDefaultFormValues({ ...defaultFormValues });
    if (name === "price") {
      updateTotalAmount();
    }
    if (name === "price_per_step") {
      updateTotalPricePerStepAmount();
    }
  };

  const handleAmountOnBlur = (e: any, index: any = "", itemDetails: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }

    const { name, value } = e.target;

    if (
      (name === "range_to" ||
        name === "price_per_step" ||
        name === "price" ||
        name === "free_quantity" ||
        name === "range_from") &&
      Number.isNaN(Number(value))
    ) {
      return;
    }

    itemDetails.price[index][name] =
      name === "range_to" || name === "free_quantity"
        ? `${value.replaceAll(".", "").trim()}`
        : value;
    itemDetails.price[index]["currency_id"] = itemDetails.currency_id;
    itemDetails.price[index]["hsn_or_sac_code"] = itemDetails.hsn_or_sac_code;
    itemDetails.price[index]["item_name"] = itemDetails.item_name;
    itemDetails.price[index].plan_item_id = itemDetails.plan_item_id;
    itemDetails.price[index].price = itemDetails.price[index].price
      ? getRoundOffAmount(Number(itemDetails.price[index].price), 6)
      : 0;
    itemDetails.price[index].price_per_step = itemDetails.price[index]
      .price_per_step
      ? getRoundOffAmount(Number(itemDetails.price[index].price_per_step), 6)
      : "";
    if (!defaultFormValues.items) {
      defaultFormValues.items = [];
    }
    if (!defaultFormValues.items[index]) {
      defaultFormValues.items[index] = {};
      defaultFormValues.items[index]["range_from"] = parseFloat(value);
    }
    setDefaultFormValues({ ...defaultFormValues });
    if (name === "price") {
      updateTotalAmount();
    }
    if (name === "price_per_step") {
      updateTotalPricePerStepAmount();
    }
  };

  const handleAddNewField = (itemDetails: any) => {
    const itemData = JSON.parse(JSON.stringify(itemDetails));
    const rangeFrom = itemData.price[itemData.price.length - 1];
    itemDetails.price.push({
      range_from: parseFloat(rangeFrom.range_to) + 1,
      range_to: null,
      price_per_step: "",
    });
    setDefaultFormValues({ ...defaultFormValues });
  };

  const removeRowHandle = (
    index: any = "",
    itemDetails: any,
    item_pricing_id: any
  ) => {
    itemDetails.price[index - 1].range_to = 0;
    itemDetails.price.pop();
    let delete_item_pricing = JSON.parse(
      JSON.stringify(defaultFormValues.delete_item_pricing)
    );
    if (item_pricing_id) {
      delete_item_pricing.push(item_pricing_id);
    }
    setDefaultFormValues({ ...defaultFormValues, delete_item_pricing });
  };

  const handleChangeTo = (e: any, index: any = "") => {
    e.preventDefault();
    const { name, value } = e.target;
    defaultFormValues.items[index].range_to = value;
    setDefaultFormValues({ ...defaultFormValues });
  };

  const handleChangePricePerStep = (e: any, index: any = "") => {
    e.preventDefault();
    const { name, value } = e.target;
    defaultFormValues.items[index].price_per_step = value;
    setDefaultFormValues({ ...defaultFormValues });
  };

  function convertToPeriodicity(input: string) {
    if (input.includes("WEEKS")) {
      return "WEEKLY";
    } else if (input.includes("MONTHS")) {
      return "MONTHLY";
    } else if (input.includes("DAYS")) {
      return "DAILY";
    } else if (input.includes("YEARS")) {
      return "YEARLY";
    } else {
      return input;
    }
  }

  function extractNumber(input: string) {
    const match = input.match(/^\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    defaultFormValues.custom_repeat_count = extractNumber(
      defaultFormValues.frequency
    );

    defaultFormValues.frequency = convertToPeriodicity(
      defaultFormValues.frequency
    );

    let errors = validate(
      defaultFormValues,
      planItemListData,
      props.isInternalNameExist
    );

    let ItemObjArr: any[] = [];

    const dupItems = JSON.parse(JSON.stringify(planItemListData));
    let removePricingId = false;
    if (
      props.priceDetails &&
      props.priceDetails.pricing_model &&
      props.priceDetails.pricing_model !== defaultFormValues.pricing_model
    ) {
      removePricingId = true;
    }
    dupItems.map((planItem: { price: any[]; plan_item_id: any }) => {
      planItem.price.map((itemobj: any) => {
        delete itemobj.from;
        delete itemobj.fromErrors;
        delete itemobj.price_per_stepErrors;
        delete itemobj.to;
        if (removePricingId) {
          delete itemobj.item_pricing_id;
        }
        if (defaultFormValues.pricing_model === "PER_UNIT") {
          delete itemobj.price_per_step;
          delete itemobj.range_from;
          delete itemobj.fromErrors;
          delete itemobj.range_to;
          delete itemobj.price_per_stepErrors;
        } else if (defaultFormValues.pricing_model === "FLAT_FEE") {
          delete itemobj.price_per_step;
          delete itemobj.range_from;
          delete itemobj.fromErrors;
          delete itemobj.range_to;
          delete itemobj.price_per_stepErrors;
        }
        if (!itemobj.plan_item_id) {
          itemobj.plan_item_id = planItem.plan_item_id;
        }
        if (itemobj.range_from) {
          itemobj.range_from = parseFloat(itemobj.range_from);
        }
        if (
          defaultFormValues.pricing_model === "TIERED" ||
          defaultFormValues.pricing_model === "VOLUME" ||
          defaultFormValues.pricing_model === "STAIRSTEP"
        ) {
          if (itemobj.range_to) {
            itemobj.range_to = parseFloat(itemobj.range_to);
          } else {
            itemobj.range_to = -1;
          }
        }
        ItemObjArr.push(itemobj);
      });
    });

    defaultFormValues.discount_trial_ranges.map((discountItems) => {
      if (discountItems.range_to === 0 || discountItems.range_to === "") {
        discountItems.range_to = -1;
      }
    });

    defaultFormValues.items = ItemObjArr;
    setDefaultFormValues({ ...defaultFormValues });

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      ScrollToError(Object.keys(errors)[0]);
    } else {
      dispatch(setLoaderState(true));
      if (!props.priceId) {
        const responseAction = await dispatch(
          planPricePointCreate({
            values: defaultFormValues,
            orgId: currentUserInfo.organization_id,
          })
        );
        if (responseAction.payload) {
          setTimeout(function () {
            dispatch(setLoaderState(false));
          }, 500);
          const response = responseAction.payload;

          if (Object.keys(response).length && !("error" in response)) {
            navigate(`/plan/detail/${planId}`);
          } else if ("error" in response) {
          }
        }
      } else {
        const responseAction = await dispatch(
          planPricePointEdit({
            values: defaultFormValues,
            orgId: currentUserInfo.organization_id,
            priceId: props.priceId,
          })
        );
        if (responseAction.payload) {
          setTimeout(function () {
            dispatch(setLoaderState(false));
          }, 500);
          const response = responseAction.payload;

          if (Object.keys(response).length && !("error" in response)) {
            navigate(`/plan/detail/${planId}`);
          } else if ("error" in response) {
          }
        }
      }
    }
  };

  const changePlan = (value: any) => {
    setPlan(value);
    setSelectedOptionPricingModel(value);
    value = value.value;
    let totalAmount = 0;

    if (
      props.priceDetails &&
      props.priceDetails.items &&
      props.priceDetails.items.length
    ) {
      props.priceDetails.items.map(
        (item: {
          plan_item_id: any;
          steps: any[];
          price: any;
          free_quantity: any;
          item_pricing_id: any;
        }) => {
          const planData = planItemListData.find(
            (e) => item.plan_item_id === e.plan_item_id
          );
          if (planData) {
            if (value === "PER_UNIT" || value === "FLAT_FEE") {
              planData.price = [];
              planData.price.push({
                price: item.price ? item.price : planData.rate,
                free_quantity: item.free_quantity,
                item_pricing_id: item.item_pricing_id,
                range_from: 1,
                range_to: "",
                price_per_step: "",
              });
              totalAmount += item.price
                ? Number(item.price)
                : Number(planData.rate);
            } else {
              planData.plan_item_id = item.plan_item_id;
              if (item?.steps?.length) {
                planData.price = item.steps;
              } else {
                planData.price = [
                  {
                    range_from: 1,
                    from: "",
                    to: "",
                    price_per_step: "",
                    price: item.price ? item.price : planData.rate,
                  },
                ];
              }
            }

            setAmount(totalAmount);
            return planData;
          }
        }
      );
    }

    setDefaultFormValues({
      ...defaultFormValues,
      pricing_model: value,
    });
  };

  const handleBillingCycleChangeSelect = (value: any) => {
    setSelectedOptionBillingCycle(value);
    value = value.value;
    setDefaultFormValues({
      ...defaultFormValues,
      invoicing_cycle: value,
    });
  };

  return (
    <section className="form-top-section">
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <FormInputField
            type="text"
            name="internal_name"
            id="internal_name"
            className={
              formErrors.internal_name ? "form-control error" : "form-control"
            }
            label="Internal Name"
            placeholder="Enter profile name"
            maxLength={100}
            value={defaultFormValues.internal_name}
            onChange={handleChange}
            isRequired={true}
            error={formErrors.internal_name}
            tooltipMessage={message().plan.priceDailyPlanNameInt}
          />
        </div>
        <FormInputField
          type="text"
          name="external_name"
          id="external_name"
          className={
            formErrors.external_name ? "form-control error" : "form-control"
          }
          label="External Name"
          placeholder="Enter profile name"
          maxLength={100}
          value={defaultFormValues.external_name}
          onChange={handleChange}
          isRequired={false}
          error={formErrors.external_name}
          tooltipMessage={message().plan.pricePlanNameExt}
        />
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <FormTextAreaField
            type=""
            label="Description"
            name="description"
            id="description"
            className="notes-textarea desc-area line-height-normal"
            placeholder="Add Description"
            value={defaultFormValues.description}
            onChange={handleChange}
          />
          <div className="show-desc-container">
            <span className="check-container show-desc-inv">
              <input
                name="show_description"
                checked={defaultFormValues.show_description}
                onChange={handleChange}
                type="checkbox"
                className="show-desc-inv-chk"
              />
              &nbsp; &nbsp; Show description in invoices
            </span>
          </div>
        </div>
        <div className={`col-12 col-lg-4 ml-0 reusable-form-text-area-field`}>
          <label className={`fw-bold required`} htmlFor={""}>
            This plan has a trial period?
          </label>
          <div className="option-div radio-container">
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item>
                <input
                  type="radio"
                  name="trial"
                  className="trial-radio"
                  required={true}
                  onChange={handleChange}
                  value={"TRIAL_NO"}
                  checked={defaultFormValues.trial === false}
                />{" "}
                &nbsp;&nbsp;No
              </Grid>
              {/* <Grid item>
                <input
                  type="radio"
                  name="trial"
                  className="trial-radio"
                  required={true}
                  onChange={handleChange}
                  value={"TRIAL_FREE"}
                  checked={defaultFormValues.trial === true}
                />{" "}
                &nbsp;&nbsp;Yes
              </Grid> */}
              {/*Uncomment the below code to enable the discounted trial flow */}
              <Grid item>
                <input
                  type="radio"
                  name="trial"
                  className="trial-radio"
                  required={true}
                  onChange={handleChange}
                  value={"TRIAL_FREE"}
                  checked={defaultFormValues.trial === true}
                />{" "}
                &nbsp;&nbsp;Yes
              </Grid>
              {/*Uncomment the below code to enable the discounted trial flow */}
              {/* <Grid item>
                <input
                  type="radio"
                  name="trial"
                  className="trial-radio"
                  required={true}
                  onChange={handleChange}
                  value={"TRIAL_FREE"}
                  checked={
                    defaultFormValues.trial === true &&
                    defaultFormValues.discount_trial === false
                  }
                />{" "}
                &nbsp;&nbsp;Free trial
              </Grid>
              <Grid item>
                <input
                  type="radio"
                  name="trial"
                  className="trial-radio"
                  required={true}
                  onChange={handleChange}
                  value={"TRIAL_DISCOUNTED"}
                  checked={
                    defaultFormValues.trial === true &&
                    defaultFormValues.discount_trial === true
                  }
                />{" "}
                &nbsp;&nbsp;Discounted Trial
              </Grid> */}
            </Grid>
          </div>
          {formErrors.trial && (
            <span className="error">{formErrors.trial}</span>
          )}
          <div>
            {defaultFormValues.trial === true &&
              defaultFormValues.discount_trial === false && (
                <div className="form-input-icon-wrapper trial_count_wrapper_plan_price">
                  <div className="trial-period-count-div">
                    <input
                      type="text"
                      name="trial_count"
                      id="trial_count"
                      className="trial-period-count trial-period-count-radius"
                      placeholder=""
                      onChange={handleChange}
                      value={defaultFormValues.trial_count}
                      minLength={0}
                      maxLength={10}
                    />
                    {formErrors.trial_count && (
                      <span className="trial-period-error error">
                        {formErrors.trial_count}
                      </span>
                    )}
                  </div>
                  <Select
                    name="trial_frequency"
                    id="plan_trial_frequency"
                    options={options}
                    className={`trial-period-count trial-period-type-radius state-select form-select custom-select trial-period-height`}
                    onChange={handleChangeSelect}
                    value={selectedOption}
                    styles={SelectCustomStyleCustomer}
                  />
                </div>
              )}
          </div>
        </div>
      </div>

      {defaultFormValues.trial === true &&
      defaultFormValues.discount_trial === true ? (
        <div className="row custom-row">
          <div className="item-container item-cont-width">
            <DiscountedTrialRange
              frequency={props.frequency}
              discountedTrialData={defaultFormValues.discount_trial_ranges}
              setDefaultFormValues={setDefaultFormValues}
              trialError={formErrors.trialError || []}
            />
          </div>
        </div>
      ) : null}
      {/* plan_exist_subscription */}
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325 pricint-model-wrap">
          <FormSelectField
            name="pricing_model"
            id="pricing_model"
            label="Pricing model"
            options={pricingModelOptions}
            wrapperClass="mw-325"
            className="state-select form-select custom-select form-control pricing-model-dp"
            value={selectedOptionPricingModel}
            onChange={changePlan}
            styles={customSelectStyle}
            placeholder={"Flat Fee"}
            isSearchable={true}
            isDisabled={
              (props.priceDetails.pricing_exists_subscription && true) || false
            }
            error={formErrors.pricing_model}
            isRequired={false}
            isOnlyInRow={false}
            tooltipMessage={message().plan.pricingModel}
          />
        </div>
        <FormSelectField
          name="invoicing_cycle"
          id="billing_cycle"
          label="Invoicing cycle"
          options={billingCycleOptions}
          wrapperClass="mw-325"
          className="state-select form-select custom-select"
          value={selectedOptionBillingCycle}
          onChange={handleBillingCycleChangeSelect}
          styles={customSelectStyle}
          placeholder={"Forever"}
          isSearchable={true}
          isDisabled={
            (props.priceDetails.pricing_exists_subscription && true) || false
          }
          error={formErrors.invoicing_cycle}
          isRequired={false}
          isOnlyInRow={false}
        />
        <div style={{ width: "200px" }}>
          {defaultFormValues.invoicing_cycle === "FIXED" && (
            <>
              <input
                type="text"
                name="invoicing_cycle_fixed_count"
                id="billing_cycle_fixed_count"
                className={`trial-period-count billing_cycle_fixed_count_class form-control ${
                  props.priceDetails.pricing_exists_subscription &&
                  "billing_cycle_fixed_count_disabled"
                }`}
                placeholder=""
                onChange={handleChange}
                maxLength={6}
                value={defaultFormValues.invoicing_cycle_fixed_count}
                disabled={
                  (props.priceDetails.pricing_exists_subscription && true) ||
                  false
                }
              />
              {formErrors.invoicing_cycle_fixed_count && (
                <span style={{ paddingLeft: "29px" }} className="error">
                  {formErrors.invoicing_cycle_fixed_count}
                </span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="row custom-row">
        <div className="item-container item-cont-width">
          {planItemListData.map((item, key) => (
            <Item
              sl={key}
              planItemId={item.plan_item_id}
              rate={item.rate}
              itemName={item.item_name}
              currencyCode={props.currencyCode}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              defaultFormValues={defaultFormValues}
              setDefaultFormValues={setDefaultFormValues}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleChangePrice={handleChangePrice}
              handleFreeQuatity={handleFreeQuatity}
              handleChangeFrom={handleChangeFrom}
              handleAmountOnBlur={handleAmountOnBlur}
              handleChangeTo={handleChangeTo}
              handleChangePricePerStep={handleChangePricePerStep}
              item={item}
              plan={plan}
              handleAddNewField={handleAddNewField}
              removeRowHandle={removeRowHandle}
            />
          ))}
          {defaultFormValues.pricing_model === "PER_UNIT" ||
          defaultFormValues.pricing_model === "FLAT_FEE" ? (
            <div className="total-container">
              {`Total price: ${props.currencyCode || ""} ${NumberFormat(
                Number(amount),
                currentUserInfo.currency_code,
                orgCurrencyList
              )}`}
            </div>
          ) : null}
        </div>
      </div>
      <div className="row mb-0">
        <div className="col">
          <div
            className="form-button-wrapper w-100 docked-button-container"
            id="form-btn"
          >
            <button
              type="button"
              onClick={(event) => {
                handleSubmit(event);
              }}
              className="save-button"
            >
              Save
            </button>
            <button
              type="button"
              className="text-decoration-none cancel-button"
              onClick={() =>
                navigate(`/plan/detail/${props.planId}`, {
                  replace: true,
                })
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(forwardRef(DefaultDetails));
