import { useMemo, useRef } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import { ProformaData } from "../../../../types";
import { orgCurrencyListSelector } from "../../../common/commonSlice";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import { RowWiseFieldView } from "../../../common/components/rowWiseFieldsView/rowWiseFieldsView";
import { tdsApplyLevel } from "../../../constants/constants";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../helpers/numberFormatHelper";
import useCommonData from "../../../hooks/useCommon";
import CompanyDetails from "../../../common/components/detailsPage/CompanyDeatails";
import DetailTotalSection from "../../../common/components/detailsPage/DeatailsTotalSection";
import TaxColumn from "../../../common/components/detailsPage/TaxColumn";
import FileList from "../../../common/components/FileList";
import JournelView from "../../../common/components/JournelView";
import DetailsTableContainer from "../../../common/components/detailsPage/DetailsTableContainer";
import useGst from "../../../hooks/useGst";
import AuditTrail from "../../../common/components/auditTrail/AuditTrail";
import { getProfileFormatedDate } from "../../../../helpers/helper";

type Props = {
  invoiceData: ProformaData;
  reRenderFlag: number;
  invoiceDetail: () => void;
};

const ProformaPreviewBody: React.FC<Props> = ({
  invoiceData,
  reRenderFlag,
  invoiceDetail,
}) => {
  let taxTypes = Object.entries(invoiceData.total_details.taxes);
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const { creditNoteRolePermission } = usePermissionHandler();
  const transactionRef = useRef<{ fetchCNPRList: () => void }>(null);
  const creditApplyRef = useRef<{ getApplyCreditList: () => void }>(null);
  const { isGstOrg } = useGst();
  const isAuditLogEnabled = process.env.REACT_APP_AUDIT_LOG_ENABLED;

  /**
   * Format date from YYYY-MM-DD to DD-MM-YYYY
   */
  const dateFormatHandler = (date: string) => {
    if (!date) {
      return "";
    }
    let newDate = date.split("-").reverse().join("-");
    return newDate;
  };

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      // Conditional HSN/SAC Code column
      ...(isGstOrg  &&
        Number(currentUserInfo.organization_id) ===
          Number(invoiceData.organization_id)
        ? [
            {
              Header: "Hsn/sac code",
              accessor: "show.hsnCode",
              className: "hsncode text-nowrap",
            },
          ]
        : []),
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <span className="amt">{value.discount}</span>
              <span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <TaxColumn
              values={value}
              currencyCode={invoiceData.currency_code}
            />
          );
        },
      },
      {
        Header: "TDS",
        accessor:
          invoiceData.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL
            ? "show.item"
            : "TDS",
        className: "tds text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <span className="amt">
                {invoiceData.currency_code}{" "}
                {NumberFormat(
                  Number(value.tds_total),
                  invoiceData.currency_code,
                  orgCurrencyList
                )}
              </span>
              <span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </span>
              {/* <span>
                
              </span> */}
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ],
    [invoiceData.currency_code]
  );
  /**
   * set table row data
   */
  const data = invoiceData.item_details
    ? invoiceData.item_details.map((itemData, index) => ({
        show: {
          description: itemData.item_name,
          hsnCode: itemData.hsn_or_sac_code,
          account: itemData.account_name,
          quantity: NumberFormat(
            Number(itemData.quantity),
            invoiceData.currency_code,
            orgCurrencyList
          ),
          rate:
            invoiceData.currency_code +
            " " +
            NumberFormat(
              Number(itemData.rate),
              invoiceData.currency_code,
              orgCurrencyList
            ) +
            " / Unit",
          discount: {
            discount:
              invoiceData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.discount.discount_value),
                invoiceData.currency_code,
                orgCurrencyList
              ),
            discountAccount: itemData.discount_account_name,
          },
          tax:
            itemData.gst_tax?.tax_id !== null
              ? itemData.gst_tax
              : itemData.taxes,
          taxableAmount:
            invoiceData.currency_code +
            " " +
            NumberFormat(
              Number(itemData.taxable_amount),
              invoiceData.currency_code,
              orgCurrencyList
            ),
          item: itemData,
        },
      }))
    : [];

  return (
    <div id="invoice" className="invoice-preview transaction-preview">
      <div className="invoice-header d-flex justify-content-between">
        <CompanyDetails organizationData={invoiceData.organization_details} />
        <div className="invoice-details">
          <div className="label-invoice">Proforma Invoice</div>
          <div className="invoice-number-label">Proforma Invoice Number</div>
          <div className="invoice-number">
            {joinTransactionNumber(invoiceData.proforma_number) || ""}
          </div>
          {invoiceData.reference_number ? (
            <>
              <div className="invoice-number-label">Reference Number</div>
              <div className="invoice-number">
                {invoiceData.reference_number}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="bill-details-section d-flex justify-content-between">
        <div className="billed-to">
          <span className="bill-details-header">Billed To</span>
          <div className="company-address pt-3">
            {invoiceData.address_details.map((data, index) => {
              if (data.is_billing) {
                return (
                  <div key={index}>
                    <span>
                      <b>
                        {" "}
                        {data.customer_name
                          ? data.customer_name
                          : invoiceData.customer_name}
                      </b>
                    </span>
                    {data.address && <br />}
                    <span>{data.address ? data.address : ""}</span>
                    {(data.city || data.state_name) && <br />}
                    <span>
                      {data.city ? data.city : ""}
                      {data.city === null || data.city === "" ? "" : ", "}
                      {data.state_name ? data.state_name : ""}
                    </span>
                    {(data.country_name || data.zip_code) && <br />}
                    <span>
                      {data.country_name ? data.country_name : ""}
                      {data.country_name === null ||
                      data.country_name === "" ||
                      data.zip_code === null
                        ? ""
                        : "-"}
                      {data.zip_code ? data.zip_code : ""}
                    </span>
                    {invoiceData.customer_gstin && (
                      <>
                        <br />
                        <span className="gst-info">
                          GSTIN:{" " + invoiceData.customer_gstin}
                        </span>
                      </>
                    )}
                    {invoiceData?.customer_trn && (
                      <>
                        <br />
                        <span className="gst-info">
                          TRN:
                          {" " + invoiceData.customer_trn
                            ? invoiceData.customer_trn
                            : ""}
                        </span>
                      </>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="ship-to">
          <span className="bill-details-header">Ship To</span>
          <div className="company-address pt-3">
            {invoiceData.address_details.map((data, index) => {
              if (data.is_shipping) {
                return (
                  <div key={index}>
                    <span>
                      <b>
                        {" "}
                        {data.customer_name
                          ? data.customer_name
                          : invoiceData.customer_name}
                      </b>
                    </span>
                    {data.address && <br />}
                    <span>{data.address}</span>
                    {(data.city || data.state_name) && <br />}
                    <span>
                      {data.city}
                      {data.city === null || data.city === "" ? "" : ", "}
                      {data.state_name}
                    </span>
                    {(data.country_name || data.zip_code) && <br />}
                    <span>
                      {data.country_name}
                      {data.country_name === null ||
                      data.country_name === "" ||
                      data.zip_code === null
                        ? ""
                        : "-"}
                      {data.zip_code}
                    </span>
                    {(data.country_name || data.zip_code) && <br />}
                    {invoiceData.customer_gstin && (
                      <>
                        <br />
                        <span className="gst-info">
                          GSTIN:{" " + invoiceData.customer_gstin}
                        </span>
                      </>
                    )}
                    {invoiceData?.customer_trn && (
                      <>
                        <br />
                        <span className="gst-info">
                          TRN:
                          {" " + invoiceData.customer_trn
                            ? invoiceData.customer_trn
                            : ""}
                        </span>
                      </>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="inv-date-wrap">
          <div className="inv-date">
            <span className="bill-details-header">Issue Date</span>
            <div>
              {getProfileFormatedDate(
                currentUserInfo.organization_date_format,
                invoiceData.issue_date
              )}
            </div>
          </div>
          <div className="inv-date">
            <span className="bill-details-header">Expiry Date</span>
            <div>
              {getProfileFormatedDate(
                currentUserInfo.organization_date_format,
                invoiceData.expiry_date
              )}
            </div>
          </div>
        </div>
        {invoiceData?.place_of_supply !== null && isGstOrg && (
          <div className="invoice-supply-wrap">
            <div className="invoice-supply m-0">
              <span className="invoice-details-header">Place of supply</span>
              <div>{invoiceData.place_of_supply}</div>
            </div>
          </div>
        )}
      </div>
      <RowWiseFieldView
        mapObject={invoiceData.custom_fields}
        module="Invoices"
      />
      <div className="table-section">
        <div className="table-wrap">
          <DetailsTableContainer
            columns={columns}
            data={data}
            haveColumnClassName={true}
            className="table inv-preview-table"
            hiddenColumns={["TDS"]}
          />
        </div>
        <div className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
          <div className="thankyou-note w-50 d-flex align-items-end">
            {invoiceData?.customer_notes && (
              <div className="note-container">
                <span className="label">Customer Notes</span>
                <p>{invoiceData.customer_notes}</p>
              </div>
            )}
          </div>
          <DetailTotalSection
            currencyCode={invoiceData.currency_code}
            totalTax={invoiceData.total_details.tax_amount}
            paymentLabel="Payment received"
            payment={undefined}
            taxTypes={taxTypes}
            subTotal={invoiceData.total_details.total_taxable_amount}
            total={invoiceData.total_details.total}
            balanceDueRequired={true}
            balanceLabel={""}
            balanceAmount={0}
            tdsItems={{
              tdsAmount: invoiceData.total_details.transaction_tds_amount
                ? invoiceData.total_details.transaction_tds_amount
                : 0,
              tdsName: invoiceData.tds_name,
              tdsPercentage: invoiceData.tds_percentage,
              defaultTdsPercentage: invoiceData.default_tds_percentage,
            }}
            tcsItems={{
              tcsAmount: invoiceData.tcs_total,
              tcsName: invoiceData.tcs_name,
              tcsPercentage: invoiceData.tcs_percentage,
            }}
            roundOffValue={invoiceData.total_details.roundoff_amount}
            roundoffEnabled={invoiceData.roundoff_enabled}
          >
            <></>
          </DetailTotalSection>
        </div>
      </div>
      {invoiceData.proforma_invoice_files.length ? (
        <FileList files={invoiceData.proforma_invoice_files} />
      ) : null}
      {isAuditLogEnabled === "true" && (
        <AuditTrail
          title="Audit Trail"
          transactionType="proforma_invoices"
          transactionId={Number(invoiceData.id)}
        />
      )}
    </div>
  );
};
export default ProformaPreviewBody;
