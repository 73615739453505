/**
 * `BankingDetailsTileItem` is a reusable component that displays a banking-related item, including an icon, title, amount, and optional anchor link.
 *
 * @component
 * @example
 * // Example usage of BankingDetailsTileItem
 * <BankingDetailsTileItem
 *   title="Available Balance"
 *   amount="$1000"
 *   icon="balance-amount"
 *   iconText="Balance"
 *   anchorText="View Details"
 *   anchorLink="/details"
 *   onClickHandler={handleClick}
 * />
 *
 * @param {string} title - The title or label of the banking item.
 * @param {string} [amount] - The amount associated with the banking item. Optional.
 * @param {"cash-in-hand" | "balance-amount"} [icon] - The type of icon to display for the item. Can be "cash-in-hand" or "balance-amount". Optional.
 * @param {string} [iconText] - Additional text to display next to the icon. Optional.
 * @param {string} [anchorText] - The text for the anchor link. Optional.
 * @param {string} [anchorLink] - The URL to navigate to when the anchor link is clicked. Optional.
 * @param {onClickHandler} [onClickHandler] - The function to handle the click event of the anchor link. Optional.
 *
 * @returns {JSX.Element} A JSX element representing the banking details item.
 */

import { Container } from "../../../../../../../components/htmlTags/container/Container";
import { Span } from "../../../../../../../components/htmlTags/span/Span";
import { Paragraph } from "../../../../../../../components/htmlTags/paragraph/Paragraph";
import { Link } from "react-router-dom";
import { BankBalanceIcon } from "../../../../../../../components/svgIcons/bankBalance/BankBalanceIcon";
import { CashInHandIcon } from "../../../../../../../components/svgIcons/cashInHandIcon/CashInHandIcon";
import { RightArrowIcon } from "../../../../../../../components/svgIcons/rightArrowIcon/RightArrowIcon";
import "./BankingDetailsTileItem.css";
import { BankDetailsItemProps } from "./types";

// const IconComponent = ({
//   icon,
// }: {
//   icon?: "cash-in-hand" | "balance-amount";
// }) => {
//   switch (icon) {
//     case "cash-in-hand":
//       return <CashInHandIcon width="20px" height="20px" />;
//     case "balance-amount":
//       return <BankBalanceIcon width="20px" height="20px" />;
//     default:
//       return null;
//   }
// };

const BankingDetailsTileItem = ({
  title,
  amount,
  icon,
  iconText,
  anchorText,
  anchorLink,
  onClickHandler,
}: BankDetailsItemProps) => {
  return (
    <Container className="banking-display-item">
      <Container className="banking-item-img-cont">
        {icon}
        {iconText && (
          <Paragraph className="banking-item-details-amount">
            {iconText}
          </Paragraph>
        )}
      </Container>
      <Container className="banking-item-details">
        <Paragraph className="banking-item-details-title">{title}</Paragraph>
        {amount && (
          <Paragraph className="banking-item-details-amount">
            {amount}
          </Paragraph>
        )}
        {anchorText && anchorLink && (
          <Link
            className="banking-item-details-anchor"
            to={anchorLink}
            onClick={onClickHandler}
          >
            <Span>{anchorText}</Span>
            <RightArrowIcon color="#0D5AE5" />
          </Link>
        )}
      </Container>
    </Container>
  );
};

export default BankingDetailsTileItem;
