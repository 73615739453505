import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  SuccessToaster
} from "../../../../helpers/toastHelper";
import { ReportTagFormValues } from "../../../../types";
import { setLoaderState } from "../../../common/commonSlice";
import { DockedButtonGroup } from "../../../common/components/button/DockedButtonGroup";
import { Container } from "../../../common/components/htmlTags/Container";
import { Form } from "../../../common/components/htmlTags/Form";
import { Label } from "../../../common/components/htmlTags/Label";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import useCommonData from "../../../hooks/useCommon";
import { payloadRefiner } from "../helpers/payloadRefiner";
import { useDeleteTag } from "../hooks/useDeleteEmailFrequency";
import { useFetchEmailFrequencyList } from "../hooks/useFetchEmailFrequency";
import { useInitializeForm } from "../hooks/useInitializeForm";
import {
  checkIfReminderExist,
  createReportingTag,
  updateReminderEmailFrequency,
} from "../reminderEmailSlice";
import { ReminderEmailFormValidation } from "./ReminderEmailFormValidation";
import "./ReminderEmailFrequencyForm.css";

type Props = {
  onClose: () => void;
  isEdit?: boolean;
  isClone?: boolean;
  editId?: number;
  reminderDetails?: any;
  setReminderEmailFrequencyList: React.SetStateAction<any>;
};
export const ReminderEmailFrequencyForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { getEmailFrequency } = useFetchEmailFrequencyList();
  const { initialValues } = useInitializeForm(props.reminderDetails);
  const { deleteEmailFrequency } = useDeleteTag();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isReminderExist, setIsReminderExist] = useState(false);
  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: ReminderEmailFormValidation,
    validateOnChange: true,
    onSubmit: async (values) => {
      dispatch(setLoaderState(true));
      const responseAction =
        props.isEdit === true
          ? await updateReminderEmail(payloadRefiner(values))
          : await dispatch(
              createReportingTag({
                values: payloadRefiner(values),
                orgId: currentUserInfo.organization_id,
              })
            );

      if (!("error" in responseAction.payload)) {
        SuccessToaster(
          props.isEdit === true
            ? "Reminder email frequency updated successfully!"
            : "Reminder email frequency created successfully!",
          props.isEdit === true
            ? "reminder-email-frequency-update-success"
            : "reminder-email-frequency-create-success"
        );
        props.onClose && props.onClose();
        getEmailFrequency(props.setReminderEmailFrequencyList);
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    },
  });
  useEffect(() => {
    checkIfReminderExistHandler();
  }, [formik.values]);
  const checkIfReminderExistHandler = async () => {
    if (
      formik.values.days_offset === "" ||
      (isInitialLoad && props.reminderDetails)
    ) {
      return;
    }
    const responseAction = await dispatch(
      checkIfReminderExist({
        values: payloadRefiner(formik.values),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (!("error" in responseAction)) {
      setIsReminderExist(responseAction.payload);
    }
  };
  const updateReminderEmail = async (values: ReportTagFormValues) => {
    let responseAction: any = {};
    responseAction = await dispatch(
      updateReminderEmailFrequency({
        values: values,
        orgId: currentUserInfo.organization_id,
        id: Number(props.editId),
      })
    );
    return responseAction;
  };
  return (
    <Container className="reminder-email-frequency-form-wrapper">
      <Form
        id=""
        className="reminder-email-frequency-form"
        onSubmit={formik.handleSubmit}
      >
        <Container className="reminder-email-frequency-form-container">
          <Container className="field-container">
            <Label className="label-text" htmlFor="name">
              Remind (day(s))
            </Label>
            <input
              className="days-input"
              type="text"
              id="day"
              name="day"
              placeholder="0"
              value={formik.values.days_offset}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "" || /^-?(\d+|\d*\.\d+)?$/.test(value)) {
                  formik.setFieldValue("days_offset", value);
                  setIsInitialLoad(false);
                }
              }}
            />
          </Container>
          <Container className="field-container">
            <Label className="label-text" htmlFor="name">
              Due date
            </Label>
            <Select
              options={[
                { value: "AFTER", label: "after" },
                { value: "BEFORE", label: "before" },
              ]}
              styles={customSelectStyle}
              placeholder="Select"
              className="due-date-dropdown"
              value={{
                value: formik.values.direction,
                label: formik.values.direction === "AFTER" ? "after" : "before",
              }}
              onChange={(e) => {
                formik.setFieldValue("direction", e?.value);
                setIsInitialLoad(false);
              }}
            />
          </Container>
          <Container className="field-container">
            <Label className="label-text" htmlFor="name">
              Applicable for
            </Label>
            <Select
              options={[
                { value: "RECURRING", label: "Recurring Invoices" },
                { value: "ONE_TIME", label: "One Time Invoices" },
                { value: "BOTH", label: "Both" },
              ]}
              styles={customSelectStyle}
              placeholder="Select"
              className="applicable-for-dropdown"
              value={{
                value: formik.values.invoice_type,
                label:
                  formik.values.invoice_type === "RECURRING"
                    ? "Recurring Invoices"
                    : formik.values.invoice_type === "ONE_TIME"
                    ? "One Time Invoices"
                    : "Both",
              }}
              onChange={(e) => {
                formik.setFieldValue("invoice_type", e?.value);
                setIsInitialLoad(false);
              }}
            />
          </Container>
        </Container>
        {formik.errors.days_offset && (
          <span className="error">{formik.errors.days_offset as string}</span>
        )}
        {isReminderExist && (
          <span className="error">Reminder already exists</span>
        )}
        <DockedButtonGroup
          onSave={(e) => {
            e.preventDefault();
            formik.submitForm();
          }}
          onCancel={() => {
            formik.resetForm();
            props.onClose();
          }}
          onDelete={() => {
            deleteEmailFrequency(
              Number(props.editId),
              props.setReminderEmailFrequencyList
            );
          }}
          isDeleteButtonDisabled={false}
          isDeleteButtonEnabled={false}
          saveButtonType="submit"
          isSaveDisabled={
            Object.keys(formik.errors).length ? true : false || isReminderExist
          }
          isEdit={props.isEdit === true}
          isModal={true}
          shouldDockToBottom={true}
        />
      </Form>
    </Container>
  );
};
