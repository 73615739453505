import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadUsingURL } from "../../../../../helpers/helper";
import { BillDetailValues, ObjectType } from "../../../../../types";
import { setLoaderState } from "../../../../common/commonSlice";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import { DownloadIcon } from "../../../../common/components/customSvgIcons/downloadIcon";
import { VoidConfirmationModal } from "../../../../common/components/voidConfirmationPopup/VoidConfirmationModal";
import { WriteOffModal } from "../writeOffBill/writeOffBillModal";
import { EmailIcon } from "../../../../common/components/customSvgIcons/emailIcon";
import { message } from "../../../../constants/messages";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { colors } from "../../../../constants/colors";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { CreateDebitNoteAlertModal } from "./createDebitNote/createDebitNoteAlertModal";
import {
  getBillPdfLink,
  billDetails,
  billList,
  sendBillData,
} from "../../billsSlice";
import useCommonData from "../../../../hooks/useCommon";
import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import DeleteBill from "../Delete";
import BillsDetailBody from "./BillsDetailBody";
import TagDarkIcon from "../../../../common/components/customSvgIcons/tagDarkIcon";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import HeaderBody from "../../../../common/components/detailsPage/HeaderBody";
import SendEmailModal from "../../../../common/components/SendEmailModal";

function BillsDetailHeader() {
  type LocationProps = {
    redirectTo?: string;
  };
  const location = usePreservedLocation();
  const { dispatch, currentUserInfo, navigate } = useCommonData();
  const locationState = location?.state as LocationProps;
  const redirectTo = locationState?.redirectTo;
  const { editBillId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  const { paymentsMadeRolePermission } = usePermissionHandler();
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [deleteBillId, setDeleteBillId] = useState(-1);
  const [deleteBillItemId, setDeleteBillItemId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [billToPay, setBillToPay] = useState<ObjectType>({});
  const [reRenderFlag, setReRenderFlag] = useState(0);

  const billDetailsInitialValues = {
    id: "",
    vendor_id: "",
    user_id: "",
    organization_id: "",
    bill_status: "",
    vendor_name: "",
    vendor_notes: "",
    currency_code: "",
    currency_id: null,
    bill_number: "",
    reference_number: "",
    bill_date: "",
    payment_term_id: "",
    due_date: "",
    address_details: [],
    custom_fields: {},
    item_details: [],
    total_details: {
      total_taxable_amount: "",
      tax_amount: "",
      total: "",
      taxes: [],
      tds_percentage: 0,
      transaction_tds_amount: 0,
    },
    bill_files: [],
    email_to: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
      address: "",
      logo_path: "",
      phone_number: "",
      zip_code: "",
      city: "",
    },
    is_mutable: true,
    payment_term: "",
    is_locked: false,
    lock_date: "",
    tcs_total: 0,
    excess_debit: 0,
    tds_level: "",
    tds_name: null,
    tds_percentage: null,
    tcs_name: null,
    tcs_percentage: null,
    is_fiscal_closed: false,
  };
  const [billData, setBillData] = useState<BillDetailValues>(
    billDetailsInitialValues
  );

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let billsRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Bills")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  /**
   * Fetch Bill Details
   */
  useEffect(() => {
    if (editBillId) {
      billDetail();
    }
  }, [editBillId, currentUserInfo.organization_id]);
  /**
   * Set the entered emails in the bill data to a state.
   */
  useEffect(() => {
    let emails = billData.email_to.map((data) => {
      return data.email;
    });
    setEmailList(emails);
  }, [billData]);
  /**
   * Fetch bill details
   */
  const billDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      billDetails({
        billId: Number(editBillId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.bill_status === "Draft") {
          navigate("/bill");
        }
        setBillData(response);
        let billToPayment: ObjectType = response;
        billToPayment["Bill_ID"] = response.bill_number;
        setBillToPay(billToPayment);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.billDetail));
      }
    }
  };
  /**
   * Back button handler from Bill details page
   */
  const backFromBillViewHandler = () => {
    // if (editBillId) {
    //   redirectTo ? navigate(redirectTo) : navigate("/bill");
    // } else {
    //   redirectTo ? navigate(redirectTo) : navigate("/bill/create");
    // }
    redirectUrl ? navigate(redirectUrl) : navigate(-1);
  };

  const fetchBillList = async (action: string) => {
    await dispatch(
      billList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        vendorList: [],
        orgIds: [],
        statusList: [],
      })
    );
    if (action === "delete") {
      redirectUrl ? navigate(redirectUrl) : navigate(-1);
      // redirectTo ? navigate(redirectTo) : navigate("/bill");
    }
  };

  const downloadPdf = async () => {
    const responseAction = await dispatch(
      getBillPdfLink({
        billId: Number(editBillId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          billData.bill_number?.replace("~", "-") + ".pdf"
        );
        if (result) {
          toast.success("Bill downloaded successfully!", {
            toastId: "bill-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      }
    }
  };
  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendBillData({
        billId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchBillList("send");
      }
    }
    return responseAction;
  };
  const reRenderJournal = () => {
    setReRenderFlag(Math.floor(Math.random() * 1000));
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromBillViewHandler}
        data={{
          num: billData.bill_number,
          status: billData.bill_status,
        }}
        dataId={editBillId}
      />
      <div className="card-body">
        <HeaderBody
          dataId={editBillId}
          data={{
            num: billData.bill_number,
            status: billData.bill_status,
            userName: billData.vendor_name,
            date: billData.bill_date,
            orgName: billData.organization_details.organization_name,
          }}
          isSameOrg={
            billData.organization_id === currentUserInfo.organization_id
          }
          onHeaderNameClick={() => {
            navigate(`/vendors/detail/${billData.vendor_id}`);
          }}
        >
          {billsRolePermission.includes("Update") &&
          !billData.is_fiscal_closed ? (
            <Edit
              url={`/bill/edit/${billData.id}?replace=true${
                redirectUrl ? "&redirect=" + redirectUrl : ""
              }`}
              isMutable={true} //change to true after implementing edit of closed and partially paid bill.
              name="Bill"
              isLocked={billData.is_locked}
              className=""
              lockDate={billData.lock_date}
              isVoid={billData.bill_status === "Void"}
            />
          ) : null}
          {billsRolePermission.includes("Delete") &&
          !billData.is_fiscal_closed ? (
            <Delete
              className="text-decoration-none delete-item"
              deleteIcon={true}
              isMutable={billData.is_mutable}
              onClick={() => {
                if (billData.is_mutable) {
                  setDeleteBillId(Number(billData.id));
                  setDeleteBillItemId(billData.bill_number);
                }
              }}
              isLocked={billData.is_locked}
              lockDate={billData.lock_date}
              name="Bill"
            />
          ) : null}

          <KebabMenu wrapperClass="detail-top-action-menu">
            <li>
              <button
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#emailToModal"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span>
                  <EmailIcon color={colors.battleshipGrey} />
                  Send Email
                </span>
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={downloadPdf}
                className="dropdown-item"
              >
                <span>
                  <DownloadIcon color={colors.battleshipGrey} />
                  Download PDF
                </span>
              </button>
            </li>
            {(paymentsMadeRolePermission.includes("Create") &&
              billData.bill_status !== "Draft" &&
              billData.bill_status !== "Closed") ||
            (billData.write_off_id !== null &&
              billData.bill_status === "Closed") ? (
              <li>
                <WriteOffModal
                  bill_id={Number(editBillId)}
                  bill_amount={billData.total_details.balance_due}
                  currency_code={billData.currency_code}
                  fetchBillDetail={billDetail}
                  onCloseFunction={reRenderJournal}
                  transactionDate={billData.bill_date}
                  isVoid={billData.bill_status === "Void"}
                />
              </li>
            ) : null}
            <li>
              <CreateDebitNoteAlertModal
                debitNoteData={billData}
                isCreationPossible={true}
                isVoid={billData.bill_status === "Void"}
              />
            </li>
            {currentUserInfo.is_tags_enabled === true && (
              <li>
                <button
                  type="button"
                  onClick={() => {
                    if (
                      billData.bill_status === "Void" &&
                      !billData.is_tags_associated
                    ) {
                      ErrorToaster(message().bill.associateTagsToVoidBill);
                    } else {
                      navigate(
                        `/bill/edit/${Number(billData.id)}/reporting-tags`,
                        { replace: true }
                      );
                    }
                  }}
                  className="dropdown-item"
                >
                  <span>
                    <TagDarkIcon color={colors.battleshipGrey} />
                    Reporting Tags
                  </span>
                </button>
              </li>
            )}
            {billsRolePermission.includes("Update") && (
              <li>
                <VoidConfirmationModal
                  transactionNumber={billData.bill_number}
                  transactionStatus={billData.bill_status}
                  module="Bill"
                  isTransactionExist={!billData.is_mutable}
                  transactionId={Number(billData.id)}
                  onSubmit={async () => {
                    await billDetail();
                    reRenderJournal();
                  }}
                  isFiscalClosed={billData.is_fiscal_closed}
                />
              </li>
            )}
          </KebabMenu>
        </HeaderBody>
        <BillsDetailBody
          billData={billData}
          billDetail={billDetail}
          reRenderFlag={reRenderFlag}
        />
      </div>
      <SendEmailModal
        emailLists={emailList}
        id={Number(editBillId)}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
      <DeleteBill
        deleteBillId={deleteBillId}
        deleteBillItemId={deleteBillItemId}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshBills={fetchBillList}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
}

export default BillsDetailHeader;
