import React from "react";

type PopoverContextType = {
  onClosepopover: () => void;
};

export const PopoverContext = React.createContext<PopoverContextType>({
  onClosepopover: () => {},
});

export const useModalContext = () => React.useContext(PopoverContext);
