import { useState } from "react";
import { TabButtonGroup } from "../../../../../../../components/buttons/tabButtonGroup/TabButtonGroup";
import { Container } from "../../../../../../../components/htmlTags/container/Container";
import { Span } from "../../../../../../../components/htmlTags/span/Span";
import { Ul } from "../../../../../../../components/htmlTags/unorderedList/UnorderedList";
import { Li } from "../../../../../../../components/htmlTags/listItem/ListItem";
import "./InfoPopup.css";
export const InfoPopup = () => {
  const [tab, setTab] = useState("Info");
  const [conversions, setConversions] = useState([
    "1 INR = 89.33332 USD",
    "1 USD = 0.92345 EUR",
    "1 EUR = 150.23456 JPY",
    "1 GBP = 1.28765 USD",
    "1 AUD = 0.66234 USD",
    "1 CAD = 0.74256 USD",
    "1 CHF = 1.10789 USD",
    "1 CNY = 0.13879 USD",
    "1 HKD = 0.12756 USD",
    "1 SGD = 0.74023 USD",
    "1 NZD = 0.60321 USD",
    "1 SEK = 0.09512 USD",
    "1 NOK = 0.09187 USD",
    "1 MXN = 0.05843 USD",
    "1 BRL = 0.19874 USD",
    "1 ZAR = 0.05289 USD",
    "1 RUB = 0.01123 USD",
    "1 KRW = 0.00076 USD",
    "1 MYR = 0.21145 USD",
    "1 IDR = 0.000064 USD",
    "1 PHP = 0.01784 USD",
    "1 THB = 0.02789 USD",
    "1 VND = 0.000042 USD",
    "1 EGP = 0.03289 USD",
    "1 PKR = 0.00321 USD",
    "1 BDT = 0.00874 USD",
    "1 NGN = 0.00123 USD",
    "1 KES = 0.00789 USD",
    "1 ARS = 0.00231 USD",
    "1 COP = 0.00025 USD",
  ]);
  return (
    <Container className="info-popup-wrapper">
      <Container className="info-popup-tab-button">
        <TabButtonGroup
          tabs={[
            { value: "info", label: "Info" },
            { value: "exchange-rate", label: "Exchange Rate" },
          ]}
          defaultValue={{ value: "info", label: "Info" }}
          onTabSwitch={(activeTab) => {
            setTab(activeTab);
          }}
        />
      </Container>
      <Container className="info-popup-tab-content">
        {tab === "Info" ? (
          <Container className="info-popup-information-tab-content">
            <Span>
              <b>- Statement balance</b> is the balance of all imported bank
              statements excluding deleted and excluded imports
              <br /><br/>
              <b>- Balance in Finbooks</b> is the balance of the account in
              Finbooks based on all the opening balance and transactions entered
              into this bank account
            </Span>
          </Container>
        ) : (
          <Container className="info-popup-conversion-tab-content">
            {conversions.map((value) => {
              return (
                <Container
                  className="info-popup-conversion-list-item"
                  key={value}
                >
                  {value}
                </Container>
              );
            })}
          </Container>
        )}
      </Container>
    </Container>
  );
};
