import { SvgIconProp } from "../types";
/**
 * CloseIcon Component
 *
 * A reusable SVG close (X) icon that can be customized via props.
 *
 * @component
 * @param {SvgIconProp} props - The properties for the CloseIcon component.
 * @param {string} [props.width="16px"] - Width of the icon.
 * @param {string} [props.height="16px"] - Height of the icon.
 * @param {string} [props.rotate] - Rotation transformation (e.g., "rotate(45deg)").
 * @param {string} [props.color="currentColor"] - Fill color of the icon.
 * @returns {JSX.Element} The rendered CloseIcon component.
 */
export const CloseIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "16px"}
      height={props.height ? props.height : "16px"}
      viewBox={`0 0 ${
        props.width ? props.width.substring(0, props.width.length - 2) : "16"
      } ${
        props.height ? props.height.substring(0, props.height.length - 2) : "16"
      }`}
      style={{ transform: props.rotate, margin: props.margin || ""  }}
    >
      <path
        d="M12.0007 10.586L16.9507 5.63599L18.3647 7.04999L13.4147 12L18.3647 16.95L16.9507 18.364L12.0007 13.414L7.05072 18.364L5.63672 16.95L10.5867 12L5.63672 7.04999L7.05072 5.63599L12.0007 10.586Z"
        fill={props.color}
        fillRule={"nonzero"}
      />
    </svg>
  );
};
