import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";
import { FutureInvoiceIcon } from "../../../common/components/FutureInvoiceIcon";
import {
  setLoaderState,
  useCommonData,
  useState,
} from "../../../home/paymentsReceived/components/paymentReceivedForm/imports";
import { SaveButton, SecondaryButton } from "../button";
import CenterModal from "../centerModal/CenterModal";
import { ModalContext } from "../centerModal/centerModalContext";
import { Label } from "../htmlTags/Label";
import { Input } from "../ui";
import ModalPreviewContent from "./components/ModalPreviewContent";
import "./FutureRenewalModal.css";
import { futureRenewalInvoicePreview } from "./FutureRenewalSlice";
import { ModalFormContentValidation } from "./ModalFormContentValidation";
import { FormHelperText } from "@mui/material";
import CustomTooltips from "../Tooltip";
import { InfoIconImg } from "../../../../assets/images";

const FutureRenewalModal = (props: {
  subscriptionId: string;
  fetchChildInvoiceList: () => void;
  invoicingCycle: {
    cycle: string;
    count: number;
  };
}) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [invoicePreviewData, setInvoicePreviewData] = useState<any>(null);
  const [renewalFormData, setRenewalFormData] = useState<any>(null);
  const [modalRenderBoolean, setModalRenderBoolean] = useState(false);
  const ButtonContent = (
    <div className="future-renewal-button-content">
      <FutureInvoiceIcon
        width="20px"
        height="20px"
        margin="auto"
        color="var(--battleship-grey)"
      />
      <span>Invoice Future Cycles</span>
    </div>
  );
  const ModalFormContent = (props: any) => {
    const formik = useFormik({
      initialValues: {
        number_of_renewals: 1,
        add_to_unbilled_charges: false,
        cycle: props.invoicingCycle.cycle,
        count: props.invoicingCycle.count,
      },
      enableReinitialize: true,
      validationSchema: ModalFormContentValidation,
      validateOnChange: true,
      onSubmit: async (values) => {
        dispatch(setLoaderState(true));
        const responseAction = await dispatch(
          futureRenewalInvoicePreview({
            subscriptionId: props.subscriptionId,
            numberOfRenewals: values.number_of_renewals,
            isUnbilled: values.add_to_unbilled_charges,
            orgId: currentUserInfo.organization_id,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          setInvoicePreviewData(response);
          setRenewalFormData(values);
          setModalRenderBoolean(true);
        }
        dispatch(setLoaderState(false));
      },
    });
    const numberOfRenewals = (e: any) => {
      const value = e.target.value;
      if (value === "" || /^\d+(\.\d+)?$/.test(value)) {
        formik.setFieldValue("number_of_renewals", value);
      }
    };
    return (
      <div className="future-renewal-modal-content">
        <div className="future-renewal-modal-content">
          <div className="future-renewal-modal-content-item">
            <div className="future-renewal-modal-content-item-label">
              An advance invoice allows you to collect payment for multiple
              upcoming invoicing terms of a subscription in one go.
            </div>
            <div className="future-renewal-modal-content-item-label">
              <Label
                htmlFor="number-of-renewals"
                className="future-renewal-modal-content-item-label-text"
              >
                Number of Renewals
              </Label>
              <Input
                type="text"
                id="number-of-renewals"
                name="number-of-renewals"
                className="future-renewal-modal-content-item-input"
                placeholder="0"
                value={formik.values.number_of_renewals}
                onChange={numberOfRenewals}
              />
              <span className="error future-renewal-modal-error-message">
                {formik.errors.number_of_renewals}
              </span>
            </div>
            <div className="future-renewal-modal-content-item-checkbox-wrapper">
              <FormControlLabel
                id="add_to_unbilled_charges"
                name="add_to_unbilled_charges"
                control={
                  <CheckBox
                    value={formik.values.add_to_unbilled_charges}
                    checked={formik.values.add_to_unbilled_charges}
                    onChange={formik.handleChange}
                    className="future-renewal-modal-checkbox"
                  />
                }
                label={
                  <div>
                    Add to unbilled charges
                    <CustomTooltips
                      title="Advance charges will be added to unbilled charges of this subscription and can be invoiced later."
                      placement="top"
                    >
                      <img
                        style={{ marginLeft: "5px" }}
                        src={InfoIconImg}
                        width="16"
                        alt="info icon"
                      />
                    </CustomTooltips>
                  </div>
                }
              />
            </div>
            <div className="future-renewal-modal-content-item-footer-wrapper">
              <SaveButton
                buttonContent="Preview"
                isDisabled={false}
                type="submit"
                buttonClassName="future-renewal-modal-button"
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
              <SecondaryButton
                buttonContent="Dismiss"
                isDisabled={false}
                onClick={() => {
                  props.onCloseModal();
                  formik.resetForm();
                }}
                type="submit"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="future-renewal-modal-wrapper">
      <CenterModal
        modalTitle={
          modalRenderBoolean
            ? `Preview of advance invoice for ${invoicePreviewData.invoices.length} renewals`
            : `Invoice for Future Renewals`
        }
        modalTitleClass="future-renewal-modal-title"
        modalContent={
          <ModalContext.Consumer>
            {(context) => {
              const { onCloseModal } = context;
              return modalRenderBoolean ? (
                <ModalPreviewContent
                  onCloseModal={onCloseModal}
                  subscriptionId={props.subscriptionId}
                  numberOfRenewals={renewalFormData.number_of_renewals}
                  isUnbilled={renewalFormData.add_to_unbilled_charges}
                  invoicePreviewData={invoicePreviewData}
                  fetchChildInvoiceList={props.fetchChildInvoiceList}
                />
              ) : (
                <ModalFormContent
                  onCloseModal={onCloseModal}
                  subscriptionId={props.subscriptionId}
                  invoicingCycle={props.invoicingCycle}
                />
              );
            }}
          </ModalContext.Consumer>
        }
        onCloseFunction={() => {
          setModalRenderBoolean(false);
        }}
        buttonClass="future-renewal-button"
        buttonContent={ButtonContent}
        isModalButtonPositionStart={true}
      />
    </div>
  );
};

export default FutureRenewalModal;