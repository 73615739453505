import { SuccessToaster } from "../../../../helpers/toastHelper";
import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import { deleteReminderEmailFrequency } from "../reminderEmailSlice";
import { useFetchEmailFrequencyList } from "./useFetchEmailFrequency";

export const useDeleteTag = () => {
  const { getEmailFrequency } = useFetchEmailFrequencyList();
  const { currentUserInfo, dispatch } = useCommonData();
  const deleteEmailFrequency = async (
    id: number,
    setEmailFrequencyList?: React.SetStateAction<any>
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      deleteReminderEmailFrequency({
        orgId: currentUserInfo.organization_id,
        id: id,
      })
    );
    // const response = responseAction.payload;
    // if (!("error" in response)) {
    //   SuccessToaster(
    //     "Reporting tag deleted successfully!",
    //     "reporting-tag-delete-success"
    //   );
    //   getEmailFrequency(setEmailFrequencyList);
    //   dispatch(setLoaderState(false));
    // } else {
    //   dispatch(setLoaderState(false));
    // }
    if(responseAction.payload) {
      if(!("error" in responseAction.payload)) {
        SuccessToaster(
          "Reporting tag deleted successfully!",
          "reporting-tag-delete-success"
        );
        getEmailFrequency(setEmailFrequencyList);
        dispatch(setLoaderState(false));
      }
    }
  };
  return { deleteEmailFrequency };
};
