import { ObjectType } from "../../../../types";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/lab/Autocomplete";
import "./InegratedDropdownComponent.css";
import { DownArrowIcon } from "../DownArrowIcon";
import { customSelectStyle } from "../SelectCustomStyle";
import CustomOption from "../ui/formElements/FormSelect/CustomOption";
import { useEffect } from "react";
import { useRef } from "react";
import Popper from "../popper/popper";

type Props = {
  optionList: ObjectType[];
  placeholderForInputField: string;
  placeholderForDropdown: string;
  selectedOptionId: number;
  selectedOptionName: string;
  inputValue: string | number;
  handleDropdownChange: (e: any) => void;
  handleInputChange: (e: any) => void;
  flexRatio?: {
    dropdown: number;
    input: number;
  };
  wrapperClass?: string;
  textFieldClass?: string;
  autoCompleteClass?: string;
  isInputReadOnly?: boolean;
  disableDropdown?: boolean;
  disableInputField?: boolean;
  isInputNumeric?: boolean;
  dropDownInLeft?: boolean;
  isPagnated?: boolean;
  nextSetOfData?: () => void;
  removeInputField?: boolean;
  error?: string;
  disableClearable?: boolean;
  filterOptions?: (options: any, state: any) => any;
};

function InegratedDropdownComponent(props: Props) {
  const paperRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef(0);
  const handleInputChange = (e: any) => {
    if (Boolean(props.isInputNumeric)) {
      if (e.target.value === "" || /^-?(\d+|\d*\.\d+)?$/.test(e.target.value)) {
        props.handleInputChange(e);
      }
    } else {
      props.handleInputChange(e);
    }
  };
  const handleDropdownChange = (e: any, newVal: any) => {
    props.handleDropdownChange(newVal);
  };
  const nextSetOfData = () => {
    props.nextSetOfData && props.nextSetOfData();
  };

  const CustomPaper = (props: any) => {
    useEffect(() => {
      const handleScroll = (e: any) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        scrollPosition.current = scrollTop;
        if (scrollTop + clientHeight >= scrollHeight) {
          nextSetOfData();
        }
      };

      const paper = paperRef.current;
      if (paper) {
        paper.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (paper) {
          paper.removeEventListener("scroll", handleScroll);
        }
      };
    }, []);
    useEffect(() => {
      const paper = paperRef.current;
      if (paper) {
        paper.scrollTop = scrollPosition.current; // Restore scroll position
      }
    }, [props.optionList]);

    return (
      <Paper
        {...props}
        ref={paperRef}
        id="integrated-dropdown-component-paper"
        sx={{
          borderRadius: "4px",
          appearance: "none",
          paddingBottom: "5px",
          boxShadow: "none",
          border: "1px solid var(--new-whisper)",
          maxHeight: "200px !important",
          overflow: "hidden",
          inset: "0 !important",
        }}
      />
    );
  };

  return (
    <div
      className={`integrated-dropdown-component ${props.wrapperClass || ""}`}
    >
      <div
        className={`integrated-dropdown-component-wrapper ${
          props.dropDownInLeft ? "reverse-order" : ""
        }`}
      >
        {!props.removeInputField && (
          <TextField
            placeholder={props.placeholderForInputField}
            className={`input-field ${
              props.dropDownInLeft
                ? "remove-top-left-border-radius remove-bottom-left-border-radius remove-left-border"
                : "remove-top-right-border-radius remove-bottom-right-border-radius remove-right-border"
            } ${props.error ? "error" : ""} ${props.textFieldClass || ""}`}
            variant="outlined"
            value={props.inputValue}
            onChange={handleInputChange}
            disabled={Boolean(props.disableInputField)}
            InputProps={{
              readOnly: Boolean(props.isInputReadOnly),
            }}
            sx={{
              flex: props.flexRatio?.input || 1,
            }}
          />
        )}
        <Autocomplete
          id="integrated-dropdown-component"
          options={props.optionList}
          value={
            props.selectedOptionId
              ? {
                  label: props.selectedOptionName,
                  value: props.selectedOptionId,
                }
              : null
          }
          inputValue={props.selectedOptionName || ""}
          onChange={handleDropdownChange}
          className={`right-autocomplete remove-padding ${
            !props.removeInputField &&
            (props.dropDownInLeft
              ? "remove-top-right-border-radius remove-bottom-right-border-radius"
              : "remove-top-left-border-radius remove-bottom-left-border-radius")
          } ${props.error ? "error" : ""} ${props.autoCompleteClass || ""}`}
          renderInput={(params) => (
            <TextField {...params} placeholder={props.placeholderForDropdown} />
          )}
          popupIcon={<DownArrowIcon color="rgb(145, 145, 156)" scale="2" />}
          disabled={Boolean(props.disableDropdown)}
          sx={{ flex: props.flexRatio?.dropdown || 1 }}
          disableClearable={props.disableClearable}
          filterOptions={props.filterOptions}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "normal",
                cursor: "pointer",
                padding: "8px 18px",
                backgroundColor: "var(--white-fill) !important",
                "&:hover": {
                  color: "var(--cerulean-blue)",
                  backgroundColor: "var(--white-fill) !important",
                },
                "&[aria-selected='true']": {
                  color: "var(--cerulean-blue)",
                  backgroundColor: "var(--white-fill) !important",
                },
              }}
            >
              {option.label}
            </Box>
          )}
          PaperComponent={CustomPaper}
          componentsProps={{
            popper: {
              id: "integrated-dropdown-component-popper",
            },
          }}
        />
      </div>
      {props.error && <span className="error-message">{props.error}</span>}
    </div>
  );
}

export default InegratedDropdownComponent;
