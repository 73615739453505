export const redirectLinkSetter = (
  module: string,
  id: number,
  status?: string
) => {
  switch (module) {
    case "customers":
      return `/customers/detail/${id}`;
    case "invoices":
      return status === "Draft"
        ? `/invoice/edit/${id}`
        : `/invoice/detail/${id}`;
    case "credit_notes":
      return status === "Draft"
        ? `/credit-note/edit/${id}`
        : `/credit-note/detail/${id}`;
    case "payment_received":
      return status === "Draft"
        ? `/payment-received/edit/${id}`
        : `/payment-received/detail/${id}`;
    case "item":
      return `/item/detail/${id}`;
    case "bills":
      return status === "Draft" ? `/bill/edit/${id}` : `/bill/detail/${id}`;
    case "vendors":
      return `/vendors/detail/${id}`;
    case "debit_notes":
      return status === "Draft"
        ? `/debit-note/edit/${id}`
        : `/debit-note/detail/${id}`;
    case "payment_made":
      return status === "Draft"
        ? `/payment-made/edit/${id}`
        : `/payment-made/detail/${id}`;
    case "journal_voucher":
      return status === "Draft"
        ? `/journal-entry/edit/${id}`
        : `/journal-entry/detail/${id}`;
    case "chart_of_accounts":
      return `/chart-of-accounts/detail/${id}`;
    case "recurring_invoices":
      return `/recurring-inv/detail/${id}`;
    case "plans":
      return `/plan/detail/${id}`;
    case "addons":
      return `/addons/detail/${id}`;
    case "coupons":
      return `/coupons/detail/${id}`;
    case "subscription":
      return `/subscriptions/detail/${id}`;
    case "charges":
      return `/charges/detail/${id}`;
    case "budget":
      return `/budget/detail/${id}`;
    case "proforma_invoices":
        return `/proforma-inv/detail/${id}`;
    default:
      return "";
  }
};
