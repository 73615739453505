import React, { useEffect, useState } from "react";
import InvoiceTransactions from "./InvoiceTransactions";
import "../../../../../common/styles/ConsumerTransactions.css";
import AccordianItem from "../../../../../common/components/detailsPage/AccordianItem";
import CNTransactions from "./CNTransactions";
import PRTransactions from "./PRTransactions";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { currentUserSelector } from "../../../../../common/commonSlice";
import { useParams } from "react-router-dom";
import { IsFeatureEnabled } from "../../../../../../helpers/featureFlagHelper";
import { getTransactionLimitStatus as invoiceLimitStatus } from "../../../../invoice/invoiceSlice";
import { Transactionlimit } from "../../../../../../types";
import NotificationModal from "../../../../../common/components/NotificationModal";
import Loader from "../../../../components/Loader";
import { getTransactionLimitStatus as cnLimitStatus } from "../../../../creditNote/creditNoteSlice";
import { getTransactionLimitStatus as prLimitStatus } from "../../../../paymentsReceived/paymentReceivedSlice";
import SubscriptionTransactions from "./SubscriptionTransactions";
import { getSubscriptionTransactionLimitStatus as subscriptionLimitStatus } from "../../../../subscription/subscriptionSlice";
type Props = {
  isSameOrg: boolean;
};
export default function CustomerTransactions(props: Props) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const [subModulePermission, setSubModulePermission] = useState<any[]>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  let subModules: any[] = [];
  useEffect(() => {
    subModules = organizationRole.length
      ? organizationRole.map((permission: any) => {
          return permission.subModule;
        })
      : [];
    let unique = Array.from(new Set(subModules));
    setSubModulePermission(unique);
  }, [
    currentUserInfo.organization_id,
    sessionStorage.getItem("organizationRole"),
  ]);
  let invoicesRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Invoices")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  let creditNoteRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Credit Notes"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  let paymentsReceivedPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Payments Received"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  let subscriptionPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Subscriptions"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async (type: string) => {
    setIsLoading(true);
    let responseAction: any;
    if (type === "Invoice") {
      responseAction = await dispatch(
        invoiceLimitStatus(currentUserInfo.organization_id)
      );
    }
    if (type === "Credit Notes") {
      responseAction = await dispatch(
        cnLimitStatus(currentUserInfo.organization_id)
      );
    }
    if (type === "Payments Received") {
      responseAction = await dispatch(
        prLimitStatus(currentUserInfo.organization_id)
      );
    }
    if (type === "Subscriptions") {
      responseAction = await dispatch(
        subscriptionLimitStatus(currentUserInfo.organization_id)
      );
    }
    const response = responseAction.payload;
    setIsLoading(false);
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
      return response.limit_exceed;
    } else {
      return false;
    }
  };
  return (
    <>
      {subModulePermission.includes("Invoices") ||
      subModulePermission.includes("Credit Notes") ||
      subModulePermission.includes("Payments Received") ||
      subModulePermission.includes("Subscriptions") ? (
        <div
          className="accordion transactions-accordion"
          id="transactions-accordion"
        >
          {subModulePermission.includes("Invoices") &&
          IsFeatureEnabled("Invoices", currentUserInfo.disabled_feature) ? (
            <AccordianItem
              title="Invoice"
              index={1}
              createUrl={`/invoice/create/${editId}`}
              permission={
                invoicesRolePermission.includes("Create")
              }
              redirectTo={`/customers/detail/${editId}`}
              consumerId={editId}
              checkLimit={fetchTransactionLimit}
            >
              <InvoiceTransactions isSameOrg={props.isSameOrg} />
            </AccordianItem>
          ) : null}
          {subModulePermission.includes("Credit Notes") &&
          IsFeatureEnabled("Credit Notes", currentUserInfo.disabled_feature) ? (
            <AccordianItem
              title="Credit Notes"
              index={2}
              createUrl={`/credit-note/create/${editId}`}
              permission={
                creditNoteRolePermission.includes("Create")
              }
              redirectTo={`/customers/detail/${editId}`}
              consumerId={editId}
              checkLimit={fetchTransactionLimit}
            >
              <CNTransactions isSameOrg={props.isSameOrg} />
            </AccordianItem>
          ) : null}
          {subModulePermission.includes("Payments Received") &&
          IsFeatureEnabled(
            "Payments Received",
            currentUserInfo.disabled_feature
          ) ? (
            <AccordianItem
              title="Payments Received"
              index={3}
              createUrl={`/payment-received/create/${editId}`}
              permission={
                paymentsReceivedPermission.includes("Create")
              }
              redirectTo={`/customers/detail/${editId}`}
              consumerId={editId}
              checkLimit={fetchTransactionLimit}
            >
              <PRTransactions isSameOrg={props.isSameOrg} />
            </AccordianItem>
          ) : null}
          {subModulePermission.includes("Subscriptions") &&
          IsFeatureEnabled(
            "Subscriptions",
            currentUserInfo.disabled_feature
          ) ? (
            <AccordianItem
              title="Subscriptions"
              index={4}
              createUrl={`/subscriptions/create/${editId}`}
              permission={
                subscriptionPermission.includes("Create")
              }
              redirectTo={`/customers/detail/${editId}`}
              consumerId={editId}
              checkLimit={fetchTransactionLimit}
            >
              <SubscriptionTransactions isSameOrg={props.isSameOrg} />
            </AccordianItem>
          ) : null}
        </div>
      ) : null}
      <NotificationModal
        modalId="transactionLimitModal"
        modalHeader={"Transaction limit exceeded!"}
        modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
      {isLoading ? <Loader /> : null}
    </>
  );
}
