import { getSystemVoucherConfigList } from "./../../../../home/systemVoucher/systemVoucherSlice";
import React, { useEffect, useState } from "react";
import { getModuleStatusFilter } from "../../../../../helpers/statusFilterHelper";
import { useAppSelector } from "../../../../../app/hooks";
import { customerFilterList } from "../../../../home/customerManagement/customerSlice";
import { listFilterSelector } from "../../../../../appSlice";
import { vendorFilterList, vendorsFilterList } from "../../../../home/vendorManagement/vendorSlice";
import {
  itemType,
  tagFilters,
  transactionCategory,
  invoiceType,
} from "../../../../constants/constants";
import {
  ObjectType,
  OrgListObjectType,
  SelectedFiltersType,
} from "../../../../../types";
import {
  getOrganizationListForReport,
  reportOrgSelector,
  dateListFilterSelector,
  dueDateListFilterSelector,
  fetchCustomFrequencySettings,
} from "../../../commonSlice";
import useCommonData from "../../../../hooks/useCommon";
import { fieldOptionsForExport } from "../../reportCustomizationModal/OptionConstants";

function useFilterData(props: any) {
  const { dispatch, currentUserInfo } = useCommonData();
  const orgList = useAppSelector(reportOrgSelector);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const [selectedFilters, setSelectedFilters] = useState<ObjectType>({});
  const [orgListFilter, setOrgListFilter] = useState<OrgListObjectType[]>([]);
  const [customerFilterArray, setCustomerFilterArray] = useState<
    OrgListObjectType[]
  >([]);
  const [vendorFilterArray, setVendorFilterArray] = useState<
    OrgListObjectType[]
  >([]);
  const [dateFilter, setDateFilter] = useState({
    date: { startDate: "", endDate: "" },
  });
  const [dueDateFilter, setDueDateFilter] = useState({
    dueDate: { startDate: "", endDate: "" },
  });
  const [systemVoucherFilterArray, setSystemVoucherFilterArray] = useState<
    OrgListObjectType[]
  >([]);
  const [frequencyListArray, setFrequencyListArray] = useState<
    OrgListObjectType[]
  >([]);

  useEffect(() => {
    if (props.haveFilter) getOrgList();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    getOrgListFilter();
  }, [orgList]);

  useEffect(() => {
    setSelectedFilters({ ...selectedFilterData });
  }, [selectedFilterData]);

  useEffect(() => {
    if (
      props.type === "Invoices" ||
      props.type === "CN" ||
      props.type === "PR" ||
      props.type === "RI" ||
      props.type === "Subscription" ||
      props.type === "CalenderInvoice" ||
      props.type === "ProformaInvoice"
    ) {
      getCustomerFilterList();
      // getFrequencyConfigList();
    }
    if (
      props.type === "Bills" ||
      props.type === "Purchase Orders" ||
      props.type === "DN" ||
      props.type === "PM"
    )
      getVendorsFilterList();
    if (props.type === "SV") {
      systemVoucherConfigList();
    }
  }, [selectedFilters?.Organizations, currentUserInfo.organization_id]);

  useEffect(() => {
    setDateFilter(dateListFilter);
  }, [dateListFilter]);
  useEffect(() => {
    setDueDateFilter(dueDateListFilter);
  }, [dueDateListFilter]);

  const initialFilterObject = (): ObjectType => {
    switch (props.type) {
      case "Invoices":
        return {
          Organizations: [],
          Status: [],
          Customers: [],
          date: {},
          DueDate: {},
          InvoiceType: [],
        };
      case "CN":
      case "PR":
        return {
          Organizations: [],
          Status: [],
          Customers: [],
          date: {},
        };
      case "RI":
        return {
          Organizations: [],
          Status: [],
          Customers: [],
          date: {},
          DueDate: {},
        };
      case "Bills":
        return {
          Organizations: [],
          Status: [],
          Vendors: [],
          date: {},
          DueDate: {},
        };
      case "Purchase Orders":
        return {
          Organizations: [],
          Status: [],
          Vendors: [],
          date: {},
          DueDate: {},
        };
      case "DN":
      case "PM":
        return {
          Organizations: [],
          Status: [],
          Vendors: [],
          date: {},
        };
      case "JV":
        return {
          Organizations: [],
          Status: [],
          Tag: [],
          date: {},
        };
      case "Plan":
      case "ADDON":
        return {
          Organizations: [],
          Status: [],
        };
      case "Coupon":
        return {
          Organizations: [],
          Status: [],
        };
      case "Items":
        return {
          Status: [],
          ItemType: [],
          TransactionCategory: [],
        };
      case "Subscription":
        return {
          Organizations: [],
          Status: [],
          Customers: [],
          Frequency: [],
        };
      case "CalenderInvoice":
        return {
          Frequency: [],
        };
      case "ProformaInvoice":
        return {
          Organizations: [],
          Status: [],
          Customers: [],
          IssueDate: {},
          ExpiryDate: {},
        };
      case "SV":
        return {
          Organizations: [],
          Status: [],
          date: {},
          SystemVoucherType: [],
        };
      default:
        return { Organizations: [] };
    }
  };
  /**
   * Setting filter options for the ListFilter Component
   */
  const filters = (): any[] => {
    switch (props.type) {
      case "Invoices":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Customers: customerFilterArray },
          dateFilter,
          dueDateFilter,
          { InvoiceType: invoiceType },
        ];
      case "CN":
      case "PR":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Customers: customerFilterArray },
          dateFilter,
        ];
      case "RI":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Customers: customerFilterArray },
          dateFilter,
          dueDateFilter,
        ];
      case "Bills":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Vendors: vendorFilterArray },
          dateFilter,
          dueDateFilter,
        ];
      case "Purchase Orders":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Vendors: vendorFilterArray },
          dateFilter,
          dueDateFilter,
        ];
      case "DN":
      case "PM":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Vendors: vendorFilterArray },
          dateFilter,
        ];
      case "JV":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Tag: tagFilters },
          dateFilter,
        ];
      case "Plan":
      case "ADDON":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
        ];
      case "Coupon":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
        ];
      case "Items":
        return [
          // { Organizations: orgListFilter },
          { ItemType: itemType },
          { Status: getModuleStatusFilter(props.type) },
          { TransactionCategory: transactionCategory },
        ];
      case "Subscription":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Customers: customerFilterArray },
          {
            Frequency: [
              { name: "Daily", id: 1 },
              { name: "Weekly", id: 2 },
              { name: "Monthly", id: 3 },
              { name: "Yearly", id: 4 },
            ],
          },
        ];
      case "CalenderInvoice":
        return [
          {
            Frequency: [
              { name: "Weekly", id: 1 },
              { name: "Monthly", id: 2 },
              { name: "Yearly", id: 3 },
            ],
          },
        ];
      case "ProformaInvoice":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Customers: customerFilterArray },
          dateFilter,
          dueDateFilter,
        ];
      case "SV":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          {
            SystemVoucherType: systemVoucherFilterArray,
          },
          dateFilter,
        ];
      default:
        return [
          {
            Organizations: orgListFilter,
          },
        ];
    }
  };
  /**
   * Fetching report org list
   */
  const getOrgList = async () => {
    // if (currentOrganizationId !== 0 && isLastOrgIdNull === 0) {
    const responseAction = await dispatch(
      getOrganizationListForReport({ orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
      }
    }
    // }
  };
  /**
   * setting org list for filters
   */
  const getOrgListFilter = async () => {
    let orgsList: OrgListObjectType[] = [];
    if (orgList.length) {
      orgList.map((orgs: ObjectType) => {
        const { name, id } = orgs;
        orgsList.push({ name: name, id: id });
      });
    }
    setOrgListFilter(orgsList);
  };

  /**
   * fetching Customer list according to the selected orgs for filters
   */
  const getCustomerFilterList = async () => {
    const responseAction = await dispatch(
      customerFilterList({
        organizationIds: selectedFilters?.Organizations,
        orgId: currentUserInfo.organization_id,
        searchQuery: "",
        pageNum: 1,
        pageSize: 500,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCustomerFilterArray(response?.customers);
      } else {
        setCustomerFilterArray([]);
      }
    }
  };
  /**
   * fetching Vendor list according to the selected orgs for filters
   */
  const getVendorsFilterList = async () => {
    const responseAction = await dispatch(
      vendorsFilterList({
        organizationIds: selectedFilters?.Organizations,
        orgId: currentUserInfo.organization_id,
        searchQuery: "",
        pageNum: 1,
        pageSize: 500,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setVendorFilterArray(response?.vendors);
      } else {
        setVendorFilterArray([]);
      }
    }
  };
  /**
   * fetching System Voucher Type list according to the selected orgs for filters
   */
  const systemVoucherConfigList = async () => {
    const responseAction = await dispatch(
      getSystemVoucherConfigList({
        orgId: currentUserInfo.organization_id,
        isIntegrated: true,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let svList: OrgListObjectType[] = [];
        response.system_voucher_configs.map((svs: ObjectType) => {
          const { system_voucher_name, id } = svs;
          svList.push({ name: system_voucher_name, id: id });
        });
        setSystemVoucherFilterArray(svList);
      } else {
        setSystemVoucherFilterArray([]);
      }
    }
  };

  const filterChange = (selectedFilters: SelectedFiltersType) => {
    setSelectedFilters(selectedFilters);
  };

  /**
   * fetch frequency list
   */
  const getFrequencyConfigList = async () => {
    const responseAction = await dispatch(
      fetchCustomFrequencySettings(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let frequencyList: OrgListObjectType[] = [];
        response.map((item: ObjectType) => {
          const { frequency, custom_repeat_frequency, id } = item;
          frequencyList.push({ name: frequency, id: custom_repeat_frequency });
        });
        setFrequencyListArray(fieldOptionsForExport.frequency as any);
      } else {
        setFrequencyListArray([]);
      }
    }
  };

  return {
    initialFilterObject,
    filters,
    filterChange,
  };
}

export default useFilterData;
