import Dialog from "@mui/material/Dialog";
import { FiscalYearPopupForm } from "./FiscalYearPopupForm";
import DialogTitle from "@mui/material/DialogTitle";
import "./FiscalYearPopup.css";
import { useEffect, useState } from "react";
import useCommonData from "../../../hooks/useCommon";

type Props = {
  shouldOpen: boolean;
};
export const FiscalYearPopupModal = (props: Props) => {
  const { currentUserInfo } = useCommonData();
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    setOpen(props.shouldOpen);
  }, [props.shouldOpen]);
  const setDialogOpen = (shouldOpen: boolean) => {
    setOpen(shouldOpen);
  };
  return (
    <Dialog className="fiscal-year-popup-dialog" open={open} onClose={() => {}}>
      <DialogTitle className="dialog-title-fiscal-year-popup">
        {`${
          !currentUserInfo.timezone && !currentUserInfo.organization_fiscal_year
            ? "Update Fiscal Year and Timezone"
            : !currentUserInfo.timezone &&
              currentUserInfo.organization_fiscal_year
            ? "Update Timezone"
            : "Update Fiscal Year"
        }`}
      </DialogTitle>
      <FiscalYearPopupForm setDialogOpen={setDialogOpen} />
    </Dialog>
  );
};
