import { Container } from "../../../../../components/htmlTags/container/Container";
import BankingCardHeader from "../../components/bankingCardHeader/BankingCardHeader";
import DataTable from "../../../../../components/tables/Table";
import { ColumnDef } from "@tanstack/react-table";
import { CashInHandIcon } from "../../../../../components/svgIcons/cashInHandIcon/CashInHandIcon";
import { BankBalanceIcon } from "../../../../../components/svgIcons/bankBalance/BankBalanceIcon";
import { useBankingLandingPage } from "./hooks/useBankingLandingPage";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import useListData from "../../../../hooks/useListData";
import { useEffect, useRef, useState } from "react";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../hooks/useCommon";
import Pagination from "../../../../../components/pagination/paginationFooterInfo/PaginationFooterInfo";
import TableInfo from "../../../../../components/pagination/paginationHeaderInfo/PaginationHeaderInfo";
import { Heading } from "../../../../../components/htmlTags/heading/Heading";
import "./BankingLandingPage.css";
import { ObjectType } from "../../../../../types";
import { ListAction } from "./components/listAction/ListAction";
import AddAccountModal from "../../modals/addBankAccountModal/AddAccountModal";
import { Span } from "../../../../../components/htmlTags/span/Span";
import DeleteBankAccountModal from "../../modals/deleteAccountModal/DeleteAccountModal";
const bankingDetailsRight = [
  {
    title: "Uncategorized Transactions",
    iconText: "20",
    anchorText: "Categorize Now",
    anchorLink: "/banking/categorize",
  },
];

export const BankingLandingPage = () => {
  const { setPage, setItemsPerPage, setItemOffset } = usePaginationDispatch();
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const [accountId, setAccountId] = useState(0);
  const [accountName, setAccountName] = useState("");
  const { page, pageCount, itemsPerPage, totalList, startCount, endCount } =
    useListData();
  const { bankAccounts, amountInSystem, statementBalance, getBankAccounts } =
    useBankingLandingPage();
  const editAccountRef = useRef<any>(null);
  const deleteAccountRef = useRef<any>(null);
  // Define columns
  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "account_name",
      header: "Account Name",
    },
    {
      accessorKey: "last_reconciled",
      header: "Last Reconciled",
    },
    {
      accessorKey: "unreconciled_imports",
      header: "Unreconciled Imports",
    },
    {
      accessorKey: "statment_balance",
      header: "Statement Balance",
      cell: (cell) => {
        return (
          <Span className="right-aligned-text">{cell.getValue() as any}</Span>
        );
      },
    },
    {
      accessorKey: "balance",
      header: "Amount in Finbook",
      cell: (cell) => {
        return (
          <Span className="right-aligned-text">{cell.getValue() as any}</Span>
        );
      },
    },
    {
      accessorKey: "action",
      header: "",
      cell: (cell) => {
        let actionValues = cell.getValue() as unknown as ObjectType;
        return (
          <ListAction
            id={actionValues.id}
            name={actionValues.name}
            is_mutable={actionValues.is_mutable}
            editModalRef={editAccountRef}
            deleteModalRef={deleteAccountRef}
            setAccountId={setAccountId}
            setAccountName={setAccountName}
          />
        );
      },
    },
  ];
  useEffect(() => {
    getBankAccounts(page, itemsPerPage);
  }, [page, itemsPerPage, currentUserInfo.organization_id]);
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  return (
    <Container className="banking-landing-page-wrapper">
      <Container className="banking-landing-page-header-wrapper">
        <Heading
          ariaLabelledBy="banking-landing-page-header"
          className="banking-landing-page-header"
          variant="h1"
        >
          Banking
        </Heading>
        {currentUserInfo.is_super_parent && (
          <Container>
            <AddAccountModal
              refreshList={async () => {
                await getBankAccounts(page, itemsPerPage);
              }}
            />
          </Container>
        )}
      </Container>
      <Container className="banking-landing-page-content-wrapper">
        <Container>
          <BankingCardHeader
            bankingDetailsLeft={[
              {
                title: "Statement Balance",
                amount: `${currentUserInfo.currency_code} ${NumberFormat(
                  statementBalance,
                  currentUserInfo.currency_code,
                  orgCurrencyList
                )}`,
                icon: <CashInHandIcon width="20px" height="20px" />,
              },
              {
                title: "Amount in Finbooks",
                amount: `${currentUserInfo.currency_code} ${NumberFormat(
                  amountInSystem,
                  currentUserInfo.currency_code,
                  orgCurrencyList
                )}`,
                icon: <BankBalanceIcon width="20px" height="20px" />,
              },
            ]}
            bankingDetailsRight={[]}
            isInfoEnabled
          />
        </Container>
        <Container>
          <TableInfo
            total={totalList}
            startCount={startCount}
            endCount={endCount}
            itemsPerPage={itemsPerPage}
            setPageInfo={(count, page) => {
              setItemsPerPage(count);
              setPage(page);
            }}
          />
        </Container>

        <Container>
          <DataTable
            rightAlignedColumns={["statment_balance", "balance"]}
            data={bankAccounts}
            columns={columns}
          />
        </Container>
        <Container className="card-footer clear-both">
          {totalList > itemsPerPage ? (
            <Pagination
              pageCount={pageCount}
              page={page}
              handlePageClick={handlePageClick}
            />
          ) : (
            ""
          )}
        </Container>
      </Container>
      <AddAccountModal
        editId={accountId}
        modalRef={editAccountRef}
        refreshList={async () => {
          await getBankAccounts(page, itemsPerPage);
        }}
      />
      <DeleteBankAccountModal
        deleteModalRef={deleteAccountRef}
        bankAccountId={accountId}
        bankAccountName={accountName}
        refreshList={async () => {
          await getBankAccounts(page, itemsPerPage);
        }}
      />
    </Container>
  );
};
