import React, { useRef, useState } from "react";
import { AddTagIcon } from "../../../assets/images";
import { Card } from "./htmlTags/Card/Card";
import { CardBody } from "./htmlTags/Card/CardBody";
import { CardHeader } from "./htmlTags/Card/CardHeader";
import { Form } from "./htmlTags/Form";
import { customSelectStyle } from "./SelectCustomStyle";
import { FormSelectField } from "./formSelectField/FormSelectField";
import { AddAddonFormInitialValueType } from "../../home/subscription/subscriptionType";
import { useFormik } from "formik";
import { addAddonFormInitialValue } from "../../home/subscription/SubscriptionStateInitialization";
import { coustomCase } from "../../../helpers/planHelper";
import { validateAddAddonForm } from "../../home/subscription/components/subscriptionForm/ValidateSubscriptionForm";
import { ObjectType } from "../../../types";
import { FormSelectFieldWithScroll } from "./formSelectField/FormSelectFiledWithScroll";
import {
  subscriptionAddonDropdown,
  subscriptionAddonFrequency,
  subscriptionAddonFrequencyItems,
} from "../../home/subscription/subscriptionSlice";
import Dialog from "@mui/material/Dialog";
import CloseButton from "./button/CloseButton";
import Grid from "@mui/material/Grid";
import useCommonData from "../../hooks/useCommon";

type Props = {
  onSaveClick: (selectedFrequency: any) => void;
  planFrequency: string;
  custom_frequency_count: number;
  selectedAddonlist: number[];
  currencyId: number | undefined;
  conversionId: number | undefined;
};

type addonType = {
  currency_dict: {};
  external_name: string;
  id: number;
  organization: string;
  organization_id: number;
  addon_status: string;
};

function AddLineAddon(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [addonFormErrors, setAddonFormErrors] = useState<ObjectType>({});
  const [searchKey, setSearchKey] = useState("");
  const [addonListData, setAddonListData] = useState<addonType[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  function extractNumber(input: string): string | null {
    const match = input.match(/\d+/);
    return match ? match[0] : null;
  }

  const formik = useFormik({
    initialValues: {
      ...addAddonFormInitialValue,
    } as AddAddonFormInitialValueType,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let errors = validateAddAddonForm(values);
      if (Object.keys(errors).length) {
        setAddonFormErrors(errors);
      } else {
        let passingValue = values.addonFrequencyData.filter(
          (data: any) =>
            data.frequency === values.selectedFrequency.value &&
            data.custom_repeat_count ===
              Number(extractNumber(values.selectedFrequency.label))
        )[0];

        const itemDetails = await getSelectedAddonFrequencyItemDetails(
          values.selectedAddon.value,
          passingValue.pricing_id
        );
        props.onSaveClick({
          ...passingValue,
          addonId: values.selectedAddon.value,
          addonName: values.selectedAddon.label,
          item_price: itemDetails,
        });
        setAddonFormErrors({});
        formik.resetForm();
      }
    },
  });

  const fetchAddonDropdown = async (count: number, searchQuery: string) => {
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const responseAction = await dispatch(
      subscriptionAddonDropdown({
        page: count,
        itemsPerPage: 10,
        orgId: currentUserInfo.organization_id,
        frequency: props.planFrequency,
        addonIds: props.selectedAddonlist,
        custom_frequency_count: props.custom_frequency_count,
        searchQuery: searchQuery,
        signal: abortControllerRef.current.signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setAddonListData(response.addons);
        const modifiedResponse = response.addons.map((data: any) => ({
          value: data.id.toString(),
          label: data.external_name,
          currency_dict: data.currency_dict
        }));
        formik.setFieldValue(
          "addonDropdownData",
          count > 1
            ? formik.values.addonDropdownData.concat(modifiedResponse)
            : modifiedResponse
        );
        formik.setFieldValue(
          "isNextEnabled",
          !(response.pagination.next === null)
        );
        if (response.pagination.next !== null) {
          const regex = new RegExp(`page_num=(\\d+)`);
          const modifiedString = response.pagination.next.match(regex);
          const count = modifiedString[1]
            ? modifiedString[1]
            : formik.values.count;
          formik.setFieldValue("count", count);
        }
      } else if ("error" in response) {
      }
    }
  };

  const fetchFrequencyDropdown = async (currencyId: number) => {
    const responseAction = await dispatch(
      subscriptionAddonFrequency({
        addonId: formik.values.selectedAddon.value,
        orgId: currentUserInfo.organization_id,
        frequency: props.planFrequency,
        custom_frequency_count:
          props.custom_frequency_count > 0 ? props.custom_frequency_count : 1,
        currencyId: currencyId,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        const modifiedResponse = response.pricings.map((data: any) => ({
          value: data.frequency,
          label: coustomCase(data.frequency, data.custom_repeat_count),
        }));
        formik.setFieldValue("frequencyDropdownData", modifiedResponse);
        formik.setFieldValue("addonFrequencyData", response.pricings);
      } else if ("error" in response) {
      }
    }
  };

  const fetchCurrencyDropdown = async (planID: number) => {
    const addonData: any = formik.values.addonDropdownData.filter(
      (item: any) => item.value === planID);
    const modifiedOptionData =
      Object.keys(addonData[0].currency_dict).length > 0
        ? Object.entries(addonData[0].currency_dict).map(([key, value]) => ({
            value: Number(key),
            label: value,
          }))
        : [
            {
              value: currentUserInfo.organization_currency,
              label: currentUserInfo.currency_code,
            },
          ];

    formik.setFieldValue(
      "currencyDropdownData",
      modifiedOptionData.length > 0
        ? modifiedOptionData
        : [
            {
              value: currentUserInfo.organization_currency,
              label: currentUserInfo.currency_code,
            },
          ]
    );
  };

  // const fetchBillingDropdown = async (addonId: number) => {
  //   const responseAction = await dispatch(
  //     subscriptionAddonFrequency({
  //       addonId: addonId,
  //       orgId: currentUserInfo.organization_id,
  //       frequency: props.planFrequency,
  //       custom_frequency_count: props.custom_frequency_count,
  //     })
  //   );

  //   if (responseAction.payload) {
  //     const response = responseAction.payload;
  //     if (Object.keys(response).length && !("error" in response)) {
  //       const modifiedResponse = response.pricings
  //         .filter((data: any) =>
  //           filterAddonFrequency(props.planFrequency, data.frequency)
  //         )
  //         .map((data: any) => ({
  //           value: data.frequency,
  //           label: coustomCase(data.frequency, data.custom_repeat_count),
  //         }));
  //       formik.setFieldValue("frequencyDropdownData", modifiedResponse);
  //       formik.setFieldValue("addonFrequencyData", response.pricings);
  //     } else if ("error" in response) {
  //     }
  //   }
  // };

  const getSelectedAddonFrequencyItemDetails = async (
    addonId: number | string,
    pricingId: number
  ) => {
    const responseAction = await dispatch(
      subscriptionAddonFrequencyItems({
        addonId: addonId as number,
        pricingId: pricingId,
        orgId: currentUserInfo.organization_id,
        currencyId: props.currencyId ? props.currencyId : 0,
        conversionId: props.conversionId ? props.conversionId : 0,
      })
    );
    let itemDetails: any[] = [];
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        itemDetails = response;
      }
    }
    return itemDetails;
  };

  const handleAddonSearch = (searchQuery: string) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    if (searchQuery.length > 2 || searchQuery === "") {
      setIsLoading(true);
      fetchAddonDropdown(1, searchQuery).finally(() => {
        setIsLoading(false);
      });
    }
    setSearchKey(searchQuery);
  };

  return (
    <>
      <div className="w-100 add-line-button-wrapper add-line">
        <div className="w-100 d-flex align-items-center p-b-18">
          <a
            href="#"
            role="button"
            className={`add-tags-button add-line-button d-flex align-items-center ${
              props?.planFrequency ? "" : "add-detail-disabled"
            }`}
            onClick={(e) => e.preventDefault()}
          >
            <img src={AddTagIcon} alt="" />
            <span
              className="px-2"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                fetchAddonDropdown(1, "");
                formik.setFieldValue("openPlanPopover", true);
              }}
            >
              Select Addons
            </span>
          </a>
        </div>
      </div>
      <Dialog
        open={formik.values.openPlanPopover}
        className="subscription-addon-popover"
        onClose={() => formik.resetForm()}
      >
        <Card wrapperClass="addon-card">
          <CardHeader wrapperClass="addon-card-header-wrapper">
            <Grid container>
              <Grid item xs={10} className="p-t-15">
                Select Addons
              </Grid>
              <Grid xs={2} className="popover-close-button">
                <CloseButton
                  closeModalHandler={() => {
                    formik.resetForm();
                  }}
                />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Form
              name="add_invoice_form"
              id="add_invoice_form"
              className=""
              tabIndex={-1}
            >
              <div className="row custom-row">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectFieldWithScroll
                    count={formik.values.count}
                    name="select_addon"
                    id="subscription_select_addon"
                    label="Select Addon"
                    options={formik.values.addonDropdownData}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.selectedAddon}
                    onChange={(option: any) => {
                      formik.setFieldValue("selectedAddon", option);
                      formik.setFieldValue("selectedCurrency", {
                        value: "",
                        label: "",
                      });
                      formik.setFieldValue("selectedFrequency", {
                        value: "",
                        label: "",
                      });
                      if (option?.value) {
                        fetchCurrencyDropdown(option?.value);
                      }
                    }}
                    onMenuScrollToBottom={() => {
                      if (formik.values.isNextEnabled)
                        fetchAddonDropdown(formik.values.count, searchKey);
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={true}
                    isDisabled={false}
                    error={
                      addonFormErrors?.select_addon
                        ? addonFormErrors.select_addon
                        : ""
                    }
                    isRequired={false}
                    isOnlyInRow={false}
                    dropdownCustomMessage="No Addons available"
                    inputValue={searchKey}
                    onInputChange={handleAddonSearch}
                    isClearable={true}
                    isLoading={isLoading}
                  />
                </div>
              </div>

              <div className="row custom-row m-t-10">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectField
                    name="billing_currency"
                    id="subscription_billing_currency"
                    label="Select Currency"
                    options={formik.values.currencyDropdownData}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.selectedCurrency}
                    onChange={(option: any) => {
                      formik.setFieldValue("selectedCurrency", option);
                      formik.setFieldValue("selectedFrequency", {
                        value: "",
                        label: "",
                      });
                      if (option?.value) {
                        fetchFrequencyDropdown(option?.value);
                      }
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={false}
                    isDisabled={
                      formik.values.selectedAddon?.label &&
                      formik.values.selectedAddon.label !== ""
                        ? false
                        : true
                    }
                    error={
                      addonFormErrors?.billing_currency
                        ? addonFormErrors.billing_currency
                        : ""
                    }
                    isRequired={false}
                    isOnlyInRow={false}
                    classNamePrefix="billing-frequency-select"
                  />
                </div>
              </div>

              <div className="row custom-row m-t-10">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectField
                    name="billing_frequency"
                    id="subscription_billing_frequency"
                    label="Frequency"
                    options={formik.values.frequencyDropdownData}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.selectedFrequency}
                    onChange={(option: any) => {
                      formik.setFieldValue("selectedFrequency", option);
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={true}
                    isDisabled={
                      formik.values.selectedAddon?.label &&
                      formik.values.selectedAddon.label !== ""
                        ? false
                        : true
                    }
                    error={
                      addonFormErrors?.billing_frequency
                        ? addonFormErrors.billing_frequency
                        : ""
                    }
                    isRequired={false}
                    isOnlyInRow={false}
                    classNamePrefix="billing-frequency-select"
                  />
                </div>
              </div>
              <div className="row mb-0">
                <div className="col">
                  <div className="form-button-wrapper w-100" id="form-btn">
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        formik.submitForm();
                      }}
                      className="save-button"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setAddonFormErrors({});
                        formik.resetForm();
                      }}
                      className="text-decoration-none cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
}

export default React.memo(AddLineAddon);
