import { Modal } from "../../../../../components/modals/Modal";
import { DeleteBankAccount } from "../../components/addAccountForm/components/deleteBankAccount/DeleteBankAccount";

function DeleteBankAccountModal(props: {
  bankAccountId: number;
  bankAccountName: string;
  deleteModalRef?: any;
  refreshList: () => Promise<void>;
}) {
  return (
    <>
      <Modal
        variant="alert-modal"
        buttonContent={<></>}
        buttonClass="primary-button-normal-class"
        isCloseButtonEnabled
        modalTitle={"Delete bank account!"}
        ref={props.deleteModalRef}
      >
        <DeleteBankAccount
          bankAccountId={props.bankAccountId}
          bankAccountName={props.bankAccountName}
          refreshList={props.refreshList}
        />
      </Modal>
    </>
  );
}

export default DeleteBankAccountModal;
