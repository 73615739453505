import { createAsyncThunk } from "@reduxjs/toolkit";
import { createReminderEmailFrequency, reminderEmialFrequencyList, deleteReminderEmailFrequencies, updateReminderEmailFrequencies, configureReminderEmailFrequencies, getReminderEmailConfig, checkIfReminderExists } from "./reminderEmailAPI";

export const createReportingTag = createAsyncThunk(
  "reminder/email/frequency/create",
  async (data: { values: any; orgId: number }) => {
    return await createReminderEmailFrequency(data);
  }
);

export const checkIfReminderExist = createAsyncThunk(
  "reminder/email/frequency/check",
  async (data: { values: any; orgId: number }) => {
    return await checkIfReminderExists(data);
  }
);

export const updateReminderEmailFrequency = createAsyncThunk(
    "reminder/email/frequency/update",
    async (data: { orgId: number; values: any, id: number }) => {
        return await updateReminderEmailFrequencies(data);
    }
)

export const deleteReminderEmailFrequency = createAsyncThunk(
    "reminder/email/frequency/delete",
  async (data: { orgId: number; id: number }) => {
    return await deleteReminderEmailFrequencies(data);
  }
);

export const getReminderEmailFrequencyList = createAsyncThunk(
    "reminder/email/frequency/list",
  async (data: { orgId: number, page: number, itemsPerPage: number }) => {
    return await reminderEmialFrequencyList(data);
  }
)

export const configureReminderEmail = createAsyncThunk(
    "reminder/email/frequency/configure",
    async (data: { orgId: number; values: any }) => {
        return await configureReminderEmailFrequencies(data);
    }
)

export const getReminderEmailFrequencyConfig = createAsyncThunk(
    "reminder/email/frequency/config",
    async (data: { orgId: number }) => {
        return await getReminderEmailConfig(data);
    }
)