import * as yup from "yup";

export const ReminderEmailFormValidation = yup.object({
  days_offset: yup
    .number()
    .required("Please enter a reminder email day.")
    .test("days_offset", "Please enter a valid reminder email day.", (value) => {
      if (value === 0) {
        return false;
      }
      return true;
    }),
});
