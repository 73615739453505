import * as Yup from "yup";
export const AddAccountValidationSchema = Yup.object({
  account_name: Yup.string()
    .max(16, "Max 16 characters")
    .required("Please enter an account name"),
  description: Yup.string().optional(),
  ledger_code: Yup.string()
    .matches(/^\d{6}$/, "Ledger Code must be exactly 6 digits")
    .test(
      "is-valid-range",
      "Ledger Code must be between 000000 and 099999",
      (value) => {
        if (!value) return true;
        const num = parseInt(value, 10);
        return num >= 0 && num <= 99999;
      }
    )
    .optional(),
  currency: Yup.string()
    .required("Please select a currency")
    .test("is-not-zero", "Please select a currency", (value) => value !== "0"),
  opening_balance_bank_currency: Yup.number()
    .typeError("Must be a number")
    .optional(),
  opening_balance_base_currency: Yup.number()
    .typeError("Must be a number")
    .optional(),
});
