import moment from "moment";
import { ObjectType } from "../types";
import { getFormatedDate } from "./helper";
export const getFiscalYear = (fiscalYear: string, state: string) => {
  /**
   * Helper funcion to get month and year for corresponding filter selection from organization fiscal year
   */
  const range = fiscalYear?.split("-") || ["", ""];

  const dateObj = new Date();
  const month: ObjectType = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };
  const monthDays: ObjectType = {
    January: 31,
    February: 28,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
  };
  if (state === "previous") {
    let year = dateObj.getFullYear() - 1;
    if (dateObj.getMonth() < Number(month[range[1]])) {
      if (Number(year) % 4 === 0) {
        monthDays.February = monthDays.February + 1;
        if (range[0] === "January") {
          return {
            startDate: `${year}-${month[range[0]]}-01`,
            endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        } else {
          return {
            startDate: `${year - 1}-${month[range[0]]}-01`,
            endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        }
      } else {
        if (range[0] === "January") {
          return {
            startDate: `${year}-${month[range[0]]}-01`,
            endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        } else {
          if (Number(year) % 4 === 0) {
            monthDays.February = monthDays.February + 1;
            return {
              startDate: `${year - 1}-${month[range[0]]}-01`,
              endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
            };
          } else {
            return {
              startDate: `${year - 1}-${month[range[0]]}-01`,
              endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
            };
          }
        }
      }
    } else {
      if (Number(year) % 4 === 0) {
        monthDays.February = monthDays.February + 1;
        if (range[0] === "January") {
          return {
            startDate: `${year}-${month[range[0]]}-01`,
            endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        } else {
          return {
            startDate: `${year}-${month[range[0]]}-01`,
            endDate: `${year + 1}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        }
      } else {
        if (range[0] === "January") {
          return {
            startDate: `${year}-${month[range[0]]}-01`,
            endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        } else {
          if (Number(year + 1) % 4 === 0) {
            monthDays.February = monthDays.February + 1;
            return {
              startDate: `${year}-${month[range[0]]}-01`,
              endDate: `${year + 1}-${month[range[1]]}-${monthDays[range[1]]}`,
            };
          } else {
            return {
              startDate: `${year}-${month[range[0]]}-01`,
              endDate: `${year + 1}-${month[range[1]]}-${monthDays[range[1]]}`,
            };
          }
        }
      }
    }
  } else {
    let year = dateObj.getFullYear();
    if (dateObj.getMonth() >= Number(month[range[1]])) {
      if (Number(year + 1) % 4 === 0) {
        monthDays.February = monthDays.February + 1;
        return {
          startDate: `${year}-${month[range[0]]}-01`,
          endDate: `${year + 1}-${month[range[1]]}-${monthDays[range[1]]}`,
        };
      } else {
        return {
          startDate: `${year}-${month[range[0]]}-01`,
          endDate: `${year + 1}-${month[range[1]]}-${monthDays[range[1]]}`,
        };
      }
    } else {
      if (range[0] === "January") {
        if (Number(year) % 4 === 0) {
          monthDays.February = monthDays.February + 1;
          return {
            startDate: `${year}-${month[range[0]]}-01`,
            endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        } else {
          return {
            startDate: `${year}-${month[range[0]]}-01`,
            endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        }
      } else {
        if (Number(year) % 4 === 0) {
          monthDays.February = monthDays.February + 1;
          return {
            startDate: `${year - 1}-${month[range[0]]}-01`,
            endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        } else {
          return {
            startDate: `${year - 1}-${month[range[0]]}-01`,
            endDate: `${year}-${month[range[1]]}-${monthDays[range[1]]}`,
          };
        }
      }
    }
  }
};
export const getFiscalQuarterYear = (fiscalYear: string, state: string) => {
  const startEndDate = getFiscalYear(fiscalYear, "current");
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  interface DateRange {
    startDate: string;
    endDate: string;
  }
  const getQuarter = () => {
    switch (fiscalYear) {
      case "January-December":
        if (state === "Current") {
          return [
            [`${currentYear}-01-01`, `${currentYear}-03-31`],
            [`${currentYear}-04-01`, `${currentYear}-06-30`],
            [`${currentYear}-07-01`, `${currentYear}-09-30`],
            [`${currentYear}-10-01`, `${currentYear}-12-31`],
          ];
        } else {
          return [
            [`${currentYear}-01-01`, `${currentYear}-03-31`],
            [`${currentYear}-04-01`, `${currentYear}-06-30`],
            [`${currentYear}-07-01`, `${currentYear}-09-30`],
            [`${currentYear - 1}-10-01`, `${currentYear - 1}-12-31`],
          ];
        }

      case "February-January":
        if (state === "Current") {
          return [
            [`${currentYear}-02-01`, `${currentYear}-04-30`],
            [`${currentYear}-05-01`, `${currentYear}-07-31`],
            [`${currentYear}-08-01`, `${currentYear}-10-31`],
            [
              `${currentMonth > 2 ? currentYear : currentYear - 1}-11-01`,
              `${currentMonth > 2 ? currentYear + 1 : currentYear}-01-31`,
            ],
          ];
        } else {
          return [
            [`${currentYear}-02-01`, `${currentYear}-04-30`],
            [`${currentYear}-05-01`, `${currentYear}-07-31`],
            [
              `${currentMonth > 2 ? currentYear : currentYear - 1}-08-01`,
              `${currentMonth > 2 ? currentYear : currentYear - 1}-10-31`,
            ],
            [`${currentYear - 1}-11-01`, `${currentYear}-01-31`],
          ];
        }
      case "March-February":
        if (state === "Current") {
          return [
            [`${currentYear}-03-01`, `${currentYear}-05-31`],
            [`${currentYear}-06-01`, `${currentYear}-08-31`],
            [`${currentYear}-09-01`, `${currentYear}-11-30`],
            [
              `${currentMonth > 3 ? currentYear : currentYear - 1}-12-01`,
              `${currentMonth > 3 ? currentYear + 1 : currentYear}-02-${
                (currentMonth > 3 ? currentYear + 1 : currentYear) % 4 === 0
                  ? "29"
                  : "28"
              }`,
            ],
          ];
        } else {
          return [
            [`${currentYear}-03-01`, `${currentYear}-05-31`],
            [`${currentYear}-06-01`, `${currentYear}-08-31`],
            [
              `${currentMonth > 3 ? currentYear : currentYear - 1}-09-01`,
              `${currentMonth > 3 ? currentYear : currentYear - 1}-11-30`,
            ],
            [
              `${currentYear - 1}-12-01`,
              `${currentYear}-02-${currentYear % 4 === 0 ? "29" : "28"}`,
            ],
          ];
        }
      case "April-March":
        if (state === "Current") {
          return [
            [`${currentYear}-04-01`, `${currentYear}-06-30`],
            [`${currentYear}-07-01`, `${currentYear}-09-30`],
            [`${currentYear}-10-01`, `${currentYear}-12-31`],
            [`${currentYear}-01-01`, `${currentYear}-03-31`],
          ];
        } else {
          return [
            [`${currentYear}-04-01`, `${currentYear}-06-30`],
            [`${currentYear}-07-01`, `${currentYear}-09-30`],
            [
              `${currentMonth > 4 ? currentYear : currentYear - 1}-10-01`,
              `${currentMonth > 4 ? currentYear : currentYear - 1}-12-31`,
            ],
            [`${currentYear}-01-01`, `${currentYear}-03-31`],
          ];
        }
      case "May-April":
        if (state === "Current") {
          return [
            [`${currentYear}-05-01`, `${currentYear}-07-31`],
            [`${currentYear}-08-01`, `${currentYear}-10-31`],
            [
              `${currentMonth > 5 ? currentYear : currentYear - 1}-11-01`,
              `${currentMonth > 5 ? currentYear + 1 : currentYear}-01-31`,
            ],
            [
              `${currentMonth > 5 ? currentYear + 1 : currentYear}-02-01`,
              `${currentMonth > 5 ? currentYear + 1 : currentYear}-04-30`,
            ],
          ];
        } else {
          return [
            [`${currentYear}-05-01`, `${currentYear}-07-31`],
            [
              `${currentMonth > 5 ? currentYear : currentYear - 1}-08-01`,
              `${currentMonth > 5 ? currentYear : currentYear - 1}-10-31`,
            ],
            [`${currentYear - 1}-11-01`, `${currentYear}-01-31`],
            [`${currentYear}-02-01`, `${currentYear}-04-30`],
          ];
        }
      case "June-May":
        if (state === "Current") {
          return [
            [`${currentYear}-06-01`, `${currentYear}-08-31`],
            [`${currentYear}-09-01`, `${currentYear}-11-30`],
            [
              `${currentMonth > 6 ? currentYear : currentYear - 1}-12-01`,
              `${currentMonth > 6 ? currentYear + 1 : currentYear}-02-${
                (currentMonth > 6 ? currentYear + 1 : currentYear) % 4 === 0
                  ? "29"
                  : "28"
              }`,
            ],
            [
              `${currentMonth > 6 ? currentYear + 1 : currentYear}-03-01`,
              `${currentMonth > 6 ? currentYear + 1 : currentYear}-05-31`,
            ],
          ];
        } else {
          return [
            [`${currentYear}-06-01`, `${currentYear}-08-31`],
            [`${currentYear - 1}-09-01`, `${currentYear - 1}-11-30`],
            [
              `${currentYear - 1}-12-01`,
              `${currentYear}-02-${currentYear % 4 === 0 ? "29" : "28"}`,
            ],
            [`${currentYear}-03-01`, `${currentYear}-05-31`],
          ];
        }
      case "July-June":
        if (state === "Current") {
          return [
            [`${currentYear}-07-01`, `${currentYear}-09-30`],
            [`${currentYear}-10-01`, `${currentYear}-12-31`],
            [
              `${currentMonth > 7 ? currentYear + 1 : currentYear}-01-01`,
              `${currentMonth > 7 ? currentYear + 1 : currentYear}-03-31`,
            ],
            [
              `${currentMonth > 7 ? currentYear + 1 : currentYear}-04-01`,
              `${currentMonth > 7 ? currentYear + 1 : currentYear}-06-30`,
            ],
          ];
        } else {
          return [
            [`${currentYear}-07-01`, `${currentYear}-09-30`],
            [`${currentYear - 1}-10-01`, `${currentYear - 1}-12-31`],
            [`${currentYear}-01-01`, `${currentYear}-03-31`],
            [`${currentYear}-04-01`, `${currentYear}-06-30`],
          ];
        }
      case "August-July":
        if (state === "Current") {
          return [
            [`${currentYear}-08-01`, `${currentYear}-10-31`],
            [
              `${currentMonth > 8 ? currentYear : currentYear - 1}-11-01`,
              `${currentMonth > 8 ? currentYear + 1 : currentYear}-01-31`,
            ],
            [
              `${currentMonth > 8 ? currentYear + 1 : currentYear}-02-01`,
              `${currentMonth > 8 ? currentYear + 1 : currentYear}-04-30`,
            ],
            [
              `${currentMonth > 8 ? currentYear + 1 : currentYear}-05-01`,
              `${currentMonth > 8 ? currentYear + 1 : currentYear}-07-31`,
            ],
          ];
        } else {
          return [
            [
              `${currentMonth > 8 ? currentYear : currentYear - 1}-08-01`,
              `${currentMonth > 8 ? currentYear : currentYear - 1}-10-31`,
            ],
            [`${currentYear - 1}-11-01`, `${currentYear}-01-31`],
            [`${currentYear}-02-01`, `${currentYear}-04-30`],
            [`${currentYear}-05-01`, `${currentYear}-07-31`],
          ];
        }
      case "September-August":
        if (state === "Current") {
          return [
            [`${currentYear}-09-01`, `${currentYear}-11-30`],
            [
              `${currentMonth > 9 ? currentYear : currentYear - 1}-12-01`,
              `${currentMonth > 9 ? currentYear + 1 : currentYear}-02-${
                (currentMonth > 9 ? currentYear + 1 : currentYear) % 4 === 0
                  ? "29"
                  : "28"
              }`,
            ],
            [
              `${currentMonth > 9 ? currentYear + 1 : currentYear}-03-01`,
              `${currentMonth > 9 ? currentYear + 1 : currentYear}-05-31`,
            ],
            [
              `${currentMonth > 9 ? currentYear + 1 : currentYear}-06-01`,
              `${currentMonth > 9 ? currentYear + 1 : currentYear}-08-31`,
            ],
          ];
        } else {
          return [
            [
              `${currentMonth > 9 ? currentYear : currentYear - 1}-09-01`,
              `${currentMonth > 9 ? currentYear : currentYear - 1}-11-30`,
            ],
            [
              `${currentYear - 1}-12-01`,
              `${currentYear}-02-${currentYear % 4 === 0 ? "29" : "28"}`,
            ],
            [`${currentYear}-03-01`, `${currentYear}-05-31`],
            [`${currentYear}-06-01`, `${currentYear}-08-31`],
          ];
        }
      case "October-September":
        if (state === "Current") {
          return [
            [`${currentYear}-10-01`, `${currentYear}-12-31`],
            [
              `${currentMonth > 10 ? currentYear + 1 : currentYear}-01-01`,
              `${currentMonth > 10 ? currentYear + 1 : currentYear}-03-31`,
            ],
            [
              `${currentMonth > 10 ? currentYear + 1 : currentYear}-04-01`,
              `${currentMonth > 10 ? currentYear + 1 : currentYear}-06-30`,
            ],
            [
              `${currentMonth > 10 ? currentYear + 1 : currentYear}-07-01`,
              `${currentMonth > 10 ? currentYear + 1 : currentYear}-09-30`,
            ],
          ];
        } else {
          return [
            [`${currentYear - 1}-10-01`, `${currentYear - 1}-12-31`],
            [`${currentYear}-01-01`, `${currentYear}-03-31`],
            [`${currentYear}-04-01`, `${currentYear}-06-30`],
            [`${currentYear}-07-01`, `${currentYear}-09-30`],
          ];
        }
      case "November-October":
        if (state === "Current") {
          return [
            [
              `${currentMonth > 11 ? currentYear : currentYear - 1}-11-01`,
              `${currentMonth > 11 ? currentYear + 1 : currentYear}-01-31`,
            ],
            [
              `${currentMonth > 11 ? currentYear + 1 : currentYear}-02-01`,
              `${currentMonth > 11 ? currentYear + 1 : currentYear}-04-30`,
            ],
            [
              `${currentMonth > 11 ? currentYear + 1 : currentYear}-05-01`,
              `${currentMonth > 11 ? currentYear + 1 : currentYear}-07-31`,
            ],
            [
              `${currentMonth > 11 ? currentYear + 1 : currentYear}-08-01`,
              `${currentMonth > 11 ? currentYear + 1 : currentYear}-10-31`,
            ],
          ];
        } else {
          return [
            [`${currentYear - 1}-11-01`, `${currentYear}-01-31`],
            [`${currentYear}-02-01`, `${currentYear}-04-30`],
            [`${currentYear}-05-01`, `${currentYear}-07-31`],
            [
              `${currentMonth > 11 ? currentYear : currentYear - 1}-08-01`,
              `${currentMonth > 11 ? currentYear : currentYear - 1}-10-31`,
            ],
          ];
        }
      case "December-November":
        if (state === "Current") {
          return [
            [
              `${currentMonth > 12 ? currentYear : currentYear - 1}-12-01`,
              `${currentMonth > 12 ? currentYear + 1 : currentYear}-02-${
                (currentMonth > 12 ? currentYear + 1 : currentYear) % 4 === 0
                  ? "29"
                  : "28"
              }`,
            ],
            [
              `${currentMonth > 12 ? currentYear + 1 : currentYear}-03-01`,
              `${currentMonth > 12 ? currentYear + 1 : currentYear}-05-31`,
            ],
            [
              `${currentMonth > 12 ? currentYear + 1 : currentYear}-06-01`,
              `${currentMonth > 12 ? currentYear + 1 : currentYear}-08-31`,
            ],
            [
              `${currentMonth > 12 ? currentYear + 1 : currentYear}-09-01`,
              `${currentMonth > 12 ? currentYear + 1 : currentYear}-11-30`,
            ],
          ];
        } else {
          return [
            [
              `${currentYear - 1}-12-01`,
              `${currentYear}-02-${currentYear % 4 === 0 ? "29" : "28"}`,
            ],
            [`${currentYear}-03-01`, `${currentYear}-05-31`],
            [`${currentYear}-06-01`, `${currentYear}-08-31`],
            [
              `${currentMonth > 12 ? currentYear : currentYear - 1}-09-01`,
              `${currentMonth > 12 ? currentYear : currentYear - 1}-11-30`,
            ],
          ];
        }

      default:
        return "invalid";
    }
  };
  const quarters = getQuarter();

  const month: ObjectType = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };
  let months = Object.keys(month);
  const range = fiscalYear?.split("-") || ["", ""];
  let currentQuarter = 0;
  const getMonthIndex = (index: number) => {
    return index < 12 ? index : index - 12;
  };
  const fiscalQuarterArray: [string[]] = [[]];
  for (let i = 0; i < 11; i = i + 3) {
    fiscalQuarterArray.push([
      months[getMonthIndex(months.indexOf(range[0]) + i)],
      months[getMonthIndex(months.indexOf(range[0]) + i + 1)],
      months[getMonthIndex(months.indexOf(range[0]) + i + 2)],
    ]);
  }
  fiscalQuarterArray.shift();
  for (let index = 0; index < fiscalQuarterArray.length; index++) {
    if (fiscalQuarterArray[index].includes(months[currentMonth])) {
      currentQuarter = index;
    }
  }
  if (state === "Previous") {
    return {
      startDate:
        quarters[
          currentQuarter - 1 < 0 ? 3 - currentQuarter : currentQuarter - 1
        ][0],
      endDate:
        quarters[
          currentQuarter - 1 < 0 ? 3 - currentQuarter : currentQuarter - 1
        ][1],
    };
  }

  return {
    startDate: quarters[currentQuarter][0],
    endDate: quarters[currentQuarter][1],
  };
};

export const getfiscalYearStartMonth = (fiscalYear: string) => {
  const range = fiscalYear?.split("-") || ["", ""];
  const month: ObjectType = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };
  return month[range[0]];
};

export const getStartYearDate = (fiscalYear: string, date: Date) => {
  const fiscalYearStartMonth = getfiscalYearStartMonth(fiscalYear);
  let fiscalYearStartYear = date.getFullYear();
  if (date.getMonth() < fiscalYearStartMonth) {
    fiscalYearStartYear -= 1;
  }
  const startYearDate = new Date(fiscalYearStartYear, fiscalYearStartMonth, 1);
  return getFormatedDate(startYearDate);
};

export const getPreviousFiscalYears = (
  numberOfYears: number,
  fiscalYear: string
) => {
  const fiscalYearData = getFiscalYear(fiscalYear, "current");
  let fiscalYearArray: Array<ObjectType> = [];
  const getStartAndEndDate = (index: number) => {
    return {
      startDate: getFormatedDate(
        moment(fiscalYearData.startDate).subtract(index, "years").toDate()
      ),
      endDate: getFormatedDate(
        moment(fiscalYearData.endDate).subtract(index, "years").toDate()
      ),
    };
  };
  for (let i = 0; i < numberOfYears; i++) {
    fiscalYearArray.push(getStartAndEndDate(i));
  }
  return fiscalYearArray;
};

export function getStartAndEndDateOfMonth(): { startDate: string; endDate: string } {
  const now = new Date();
  
  // Start date: First day of the month
  const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
  
  // End date: Last day of the month
  const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  // Format date as DD/MM/YYYY
  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };
}
