import { SvgIconProp } from "../../../types";

export const FutureInvoiceIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "20"}
      height={props.height ? props.height : "20"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
          d="M7.2 15.75H3.9375C3.78832 15.75 3.64524 15.6907 3.53975 15.5852C3.43426 15.4798 3.375 15.3367 3.375 15.1875V2.8125C3.375 2.66332 3.43426 2.52024 3.53975 2.41475C3.64524 2.30926 3.78832 2.25 3.9375 2.25H10.6875L14.625 6.1875V8.1"
          stroke={props.color}
          stroke-width="1.5"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
        <path
          d="M10.6875 2.25V6.1875H14.625"
          stroke={props.color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.4004 11.5302V12.6052L12.6099 13.1428"
          stroke={props.color}
          stroke-width="1.2"
          stroke-linecap="square"
        />
        <path
          d="M13.0723 11.1045H14.4295V9.89932"
          stroke={props.color}
          stroke-width="1.2"
          stroke-linecap="square"
        />
        <path
          d="M13.9223 14.9271L14.3494 14.5057L13.5067 13.6514L13.0795 14.0728L13.9223 14.9271ZM13.5603 10.2177L13.9965 9.80573L13.9939 9.80298L13.5603 10.2177ZM13.8164 11.4145C14.0202 11.6759 14.3972 11.7226 14.6585 11.5189C14.9199 11.3152 14.9666 10.9382 14.7629 10.6768L13.8164 11.4145ZM13.0795 14.0728C12.7556 14.3924 12.3519 14.6021 11.9204 14.68L12.1335 15.8609C12.8081 15.7391 13.4299 15.4128 13.9223 14.9271L13.0795 14.0728ZM11.9204 14.68C11.489 14.7578 11.0448 14.7014 10.6419 14.5161L10.1405 15.6063C10.7652 15.8936 11.4588 15.9827 12.1335 15.8609L11.9204 14.68ZM10.6419 14.5161C10.2387 14.3307 9.8925 14.0231 9.64966 13.6279L8.62721 14.256C8.99098 14.8481 9.51622 15.3192 10.1405 15.6063L10.6419 14.5161ZM9.64966 13.6279C9.40668 13.2324 9.27921 12.7682 9.28579 12.2937L8.0859 12.2771C8.07621 12.9759 8.26359 13.6642 8.62721 14.256L9.64966 13.6279ZM9.28579 12.2937C9.29237 11.8192 9.43264 11.3594 9.68626 10.9718L8.6821 10.3147C8.30199 10.8957 8.0956 11.5782 8.0859 12.2771L9.28579 12.2937ZM9.68626 10.9718C9.93972 10.5844 10.294 10.2878 10.7018 10.1148L10.2332 9.01006C9.60082 9.2783 9.06238 9.73354 8.6821 10.3147L9.68626 10.9718ZM10.7018 10.1148C11.1092 9.94195 11.5548 9.89891 11.984 9.98984L12.2327 8.8159C11.5618 8.67374 10.8659 8.74169 10.2332 9.01006L10.7018 10.1148ZM11.984 9.98984C12.4134 10.0808 12.8114 10.3028 13.1267 10.6325L13.9939 9.80298C13.5155 9.30286 12.9034 8.95802 12.2327 8.8159L11.984 9.98984ZM13.1241 10.6297C13.5168 11.0455 13.4401 10.9317 13.8164 11.4145L14.7629 10.6768C14.3423 10.1372 14.3676 10.1987 13.9965 9.80574L13.1241 10.6297Z"
          fill={props.color}
        />
    </svg>
  );
};
