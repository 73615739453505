import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import "./invoicingFrequencyConfig.css";
import { toast } from "react-toastify";
import { ErrorToaster } from "../../../../helpers/toastHelper";

export const InvoicingFrequencyConfig = (props: any) => {
  const [config, setConfig] = useState(props.amountInWordsConfig);

  return (
    <div className="amount-in-words-config">
      <h6>Default frequencies </h6>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {Object.keys(props.amountInWordsConfig).length > 0 &&
          props.amountInWordsConfig.map((type: any, key: number) => {
            return (
              <>
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      className={`checkbox chekBoxlabel ${
                        type.is_enabled ? "checkBoxChecked" : ""
                      } ${type.is_used ? "checkBoxUsed" : ""}`}
                      checked={type.is_enabled}
                      onChange={(e) => {
                        if (
                          e.target.parentElement?.classList.contains(
                            "checkBoxUsed"
                          ) &&
                          type.is_enabled
                        ) {
                          ErrorToaster(
                            `Cannot disable ${type.frequency} as there are price points created for that invoicing frequency.`
                          );
                          return;
                        }
                        props.formik.setFieldValue(
                          `amountInWordsConfig[${key}].is_enabled`,
                          e.target.checked
                        );
                      }}
                      name={`amountInWordsConfig[${key}].is_enabled`}
                      checkedIcon={<></>}
                      icon={<></>}
                      indeterminateIcon={<></>}
                    />
                  }
                  label={type.frequency}
                />
              </>
            );
          })}
      </Box>
    </div>
  );
};
