import { useAppDispatch } from "../../../../app/hooks";
import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { CardBody } from "../../../common/components/htmlTags/Card/CardBody";
import CloseButton from "../../../common/components/button/CloseButton";
import { Form } from "../../../common/components/htmlTags/Form";
import Table from "../../../common/components/table/Table";
import { SubscriptionInvoiceBreakdownNextInvoiceDate } from "./view/SubscriptionInvoiceBreakdownNextInvoiceDate";

type Props = {
  didAlertModalOpen?: boolean;
  setDidAlertModalOpen?: Dispatch<SetStateAction<boolean>>;
  nextInvoiceDate?: string;
  startDate?: string;
  nextInvoiceDateEditDetails?: any;
  currencyCode: string;
};
const EditNextInvoicingDateDetails = (props: Props) => {
  const dispatch = useAppDispatch();

  const modalCancelHandler = () => {
    if (props.setDidAlertModalOpen) props.setDidAlertModalOpen(false);
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Item",
        accessor: "show.item",
        className: "item left-align",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap left-align",
      },
      {
        Header: "Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
      {
        Header: "Test1",
        accessor: "show.test1",
        className: "test1 text-nowrap text-align-right",
      },
      {
        Header: "Test2",
        accessor: "show.test2",
        className: "test2 text-nowrap text-align-right",
      },
    ];
    return baseColumns;
  }, []);

  const data = [
    {
      show: {
        item: "Item",
        discount: "Discount",
        taxableAmount: "Amount",
        test1: "Amount",
        test2: "Amount",
      },
    },
    {
      show: {
        item: "Item2",
        discount: "Discount2",
        taxableAmount: "Amount2",
        test1: "Amount2",
        test2: "Amount2",
      },
    },
  ];

  return (
    <>
      <Dialog
        open={props?.didAlertModalOpen || false}
        className={`subscription-update-popover`}
        onClose={modalCancelHandler}
      >
        {/* <Card wrapperClass="subscription-update">
          <CardHeader wrapperClass={`subscription-update-header-wrapper`}>
            <Grid container>
              <Grid item xs={10} className="p-t-15 header">
                {"Edit Next Invoicing Date Details"}
              </Grid>
              <Grid item xs={2} className="popover-close-button">
                <CloseButton closeModalHandler={modalCancelHandler} />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Form
              name="add_invoice_form"
              id="add_invoice_form"
              className=""
              tabIndex={-1}
            >
              <div className="row mb-0">
                <div className="col">
                  <div className="table-section">
                    <div className="table-wrap">
                      <Table
                        id={""}
                        columns={columns}
                        data={data}
                        tableClassName={"table subscription-view-plan-table"}
                        dataSort={false}
                        isLoading={false}
                        haveColumnClassName={false}
                        rowClassName={"table-navigation"}
                        emptyClassName={"text-center dataTables_empty"}
                        hiddenColumns={[]}
                        tbodyRowOnClick={() => {}}
                        tbodyColumnClick={() => {}}
                      >
                        {""}
                      </Table>
                    </div>
                  </div>
                  <div
                    className={`form-button-wrapper w-100 'd-flex justify-content-end mt-3`}
                    id="form-btn"
                  >
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                      }}
                      className="save-button"
                    >
                      {"Update"}
                    </button>
                    <button
                      type="button"
                      onClick={modalCancelHandler}
                      className="text-decoration-none cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card> */}
        <SubscriptionInvoiceBreakdownNextInvoiceDate
          invoiceIndex={"next"}
          currencyCode={props.currencyCode}
          taxes={{}}
          tdsLevel={""}
          payload={props.nextInvoiceDateEditDetails}
          getInvoiceForPreview={async () => {}}
        />
      </Dialog>
    </>
  );
};
export default EditNextInvoicingDateDetails;
