import { ObjectType } from "../../../../../types";

interface Item {
    item_name: string;
    quantity: number;
    inventory_item_id: number | null;
    rate: number;
    range_price: number | null;
    hsn_or_sac_code: string | null;
    account_id: number | null;
    account_name: string;
    discount: number;
    discount_account_id: null | number;
    discount_type: string;
    discount_account_name: string ;
    tds_id: null | number;
    tds_account_id: null | number;
    tds_percentage: number;
    tds_amount: number;
    tds_account_name: string ;
    tds_total: number | null;
    tds_name: string ;
    default_tds_percentage: number | null;
    section_name: string ;
    gst_id: number | null;
    taxable_amount: number | null;
    taxes: any[];
    gst_tax: ObjectType;
    gst_details: ObjectType;
    item_exemption_id: number | null;
    item_exemption_type: string ;
    item_exemption_details: ObjectType ;
    item_exemption_reason: string ;
    conversion_date: string ;
    coupons: any[];
    coupon_details: ObjectType;
    coupon_amount: number | null;
}

interface AddonDetail {
    id: number | null;
    addon_id: number;
    addon_name: string;
    frequency: string;
    custom_repeat_count: number;
    pricing_model: string;
    items: Item[];
}

interface ChargeDetail {
    id: number;
    charge_id: number;
    charge_name: string;
    apply_charge_on: string;
    service_days: number;
    customer_charge_type: string;
    pricing_model: string;
    description: string;
    items: Item[];
}

interface Invoice {
    invoice_info: {
        invoicing_date: string;
        start_date: string;
        end_date: string;
        invoicing_period: string;
    };
    plan_details: any[];
    addon_details: AddonDetail[];
    charge_details: ChargeDetail[];
    coupon_details: any[];
    total_details: any;
}

interface Response {
    subscription_status: string;
    subscription_activates_on: string;
    first_invoice: Invoice;
    second_invoice: Invoice;
}

function mergeItemsById<T extends { items: Item[] }>(details: T[], idKey: keyof T): T[] {
    const map = new Map<number, T>();
    details.forEach(detail => {
        const id = detail[idKey] as unknown as number;
        if (map?.has(id)) {
            const existing = map?.get(id);
            (existing as any).items = (existing as any).items?.concat(detail.items);
        } else {
            map.set(id, { ...detail });
        }
    });
    return Array.from(map.values());
}

export function modifyResponse(response: Response): Response {
    response.first_invoice.addon_details = mergeItemsById(response.first_invoice.addon_details, 'addon_id');
    response.first_invoice.charge_details = mergeItemsById(response.first_invoice.charge_details, 'charge_id');
    if(response.second_invoice){
        response.second_invoice.addon_details = mergeItemsById(response.second_invoice.addon_details, 'addon_id');
        response.second_invoice.charge_details = mergeItemsById(response.second_invoice.charge_details, 'charge_id');
    }
    return response;
}
