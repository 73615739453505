import React, { forwardRef, useEffect, useMemo, useState } from "react";
import useCommonData from "../../../../../hooks/useCommon";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import useListData from "../../../../../hooks/useListData";
import { useAppSelector } from "../../../../../../app/hooks";
import {
  filterAppliedState,
  listFilterSelector,
} from "../../../../../../appSlice";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import {
  fiscalYearFilterSelector,
  setLoaderState,
  dateListFilterSelector,
  dueDateListFilterSelector,
} from "../../../../../common/commonSlice";
import CustomTooltips from "../../../../../common/components/Tooltip";
import Popover from "../../../../../common/components/Popover";
import {
  DateFormatHandler,
  StatusColor,
  downloadUsingURL,
  getProfileFormatedDate,
} from "../../../../../../helpers/helper";
import TableSetup from "../../../../../common/components/table/TableSetup";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import { toast } from "react-toastify";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import SendEmailModal from "../../../../../common/components/SendEmailModal";
import DebitNoteTable from "./DebitNoteTable";
import {
  DebitNoteListValues as DebitNoteListType,
  Transactionlimit,
} from "../../../../../../types";
import {
  creditNoteMandatoryValues,
  debitNoteColumnValues,
  debitNoteDefaultValues,
  debitNoteMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import {
  debitNoteList,
  getDebitNotePdfLink,
  sendDebitNoteData,
} from "../../../debitNoteSlice";
import ListActions from "../ListActions";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (id: number, listNum: string, status: string) => void;
};
function DebitNoteTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setDateSortOrder,
    setTotalList,
  } = usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } =
    useListData();
  const { debitNoteRolePermission } = usePermissionHandler();
  const selectedFilterData = useAppSelector(listFilterSelector);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const [debitNoteData, setDebitNoteData] = useState<DebitNoteListType[]>([]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [debitNoteId, setDebitNoteId] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "Reference Number",
    "Total Taxable Amount",
  ]);

  useEffect(() => {
    fetchTableCustomizationFields(
      "Debit Notes",
      debitNoteDefaultValues,
      creditNoteMandatoryValues
    );
    fetchDebitNoteList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    // JSON.stringify(fiscalYearFilter),
    // selectedFilterData,
    dateListFilter,
    dueDateListFilter,
  ]);

  useEffect(() => {
    if (isFilterApplied) fetchDebitNoteList();
  }, [selectedFilterData, isFilterApplied]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    debitNoteColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  }, [debitNoteData, selectedColumns]);
  /**
   * Fetch all Debit notes
   */
  const fetchDebitNoteList = async () => {
    dispatch(setLoaderState(true));
    const vendorList = selectedFilterData.hasOwnProperty("Vendors")
      ? selectedFilterData?.Vendors
      : [];
    const responseAction = await dispatch(
      debitNoteList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        vendorList: vendorList,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        endDate: dateListFilter.date.endDate || "",
        startDate: dateListFilter.date.startDate || "",
        startDueDate: dueDateListFilter.dueDate.startDate || "",
        endDueDate: dueDateListFilter.dueDate.endDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);

      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setDebitNoteData(response.debit_notes);
        let length = response.debit_notes?.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  };
  /**
   * Set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Debit Note Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Debit Note Number",
        accessor: "show.debitNoteId",
        sort: false,
        sortOrder: "",
        className: "cr_note_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Vendor Name",
        accessor: "show.vendorName",
        sort: false,
        sortOrder: "",
        className: "vend_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement={"bottom"}
              title={value?.length > 15 ? value : ""}
            >
              <span className="d-block ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "dt_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Total",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Bill Number",
        accessor: selectedColumns.includes("Bill Number")
          ? "show.billNumbers"
          : "Bill Number",
        sort: false,
        sortOrder: "",
        className: "bill_num",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? <Popover values={value} tag={false} /> : "-";
        },
      },
      {
        Header: "Debit Note Balance",
        accessor: selectedColumns.includes("Debit Note Balance")
          ? "show.balance"
          : "Debit Note Balance",
        sort: false,
        sortOrder: "",
        className: "cr_note_balance",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Debit Notes"
            tableColumns={selectedColumns}
            customizeColumnValues={debitNoteColumnValues}
            mandatoryColumns={debitNoteMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Debit Notes",
                debitNoteDefaultValues,
                creditNoteMandatoryValues
              );
              fetchDebitNoteList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!debitNoteRolePermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              onClickSendMail={() => fetchContactEmail(value.id, value.emailTo)}
              onClickDownloadPdf={() => downloadPdf(value.id, value.dbnName)}
              onClickDelete={() =>
                props.deleteListData(value.id, value.listName, value.status)
              }
            />
          );
        },
      },
    ],
    [
      dateSortOrder,
      selectedColumns,
      hiddenColumns,
      currentUserInfo.organization_id,
      debitNoteRolePermission,
    ]
  );
  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  /**
   * Set table row data
   */
  const data = debitNoteData
    ? debitNoteData?.map((debitNote, index) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            debitNote.debit_note_date
          ),
          debitNoteId: joinTransactionNumber(debitNote.debit_note_no),
          referenceNumber: debitNote.reference_number,
          vendorName: debitNote.vendor_name,
          status: debitNote.debit_note_status,
          totalTaxableAmount:
            getCurrencyCode(debitNote.currency_code) +
            " " +
            NumberFormat(
              Number(debitNote.total_taxable_amount),
              debitNote.currency_code ? debitNote.currency_code : "",
              orgCurrencyList
            ),
          amount:
            getCurrencyCode(debitNote.currency_code) +
            " " +
            NumberFormat(
              Number(debitNote.total),
              debitNote.currency_code ? debitNote.currency_code : "",
              orgCurrencyList
            ),
          balance: debitNote.balance_due
            ? getCurrencyCode(debitNote.currency_code) +
              " " +
              NumberFormat(
                Number(debitNote.balance_due),
                debitNote.currency_code ? debitNote.currency_code : "",
                orgCurrencyList
              )
            : getCurrencyCode(debitNote.currency_code) +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  debitNote.currency_code
                    ? debitNote.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
          billNumbers: debitNote.bill_numbers.map((bills) => {
            return { id: bills.id, name: joinTransactionNumber(bills.name) };
          }),
          organization: debitNote.organization,
          actions: {
            id: debitNote.id,
            dbnName: joinTransactionNumber(debitNote.debit_note_no),
            status: debitNote.debit_note_status,
            listName: debitNote.debit_note_no,
            emailTo: debitNote.email_to,
            isMutable: debitNote.bill_numbers.length > 0 ? false : true,
            organizationId: debitNote.organization_id,
            isLocked: debitNote.is_locked,
            lockDate: debitNote.lock_date,
            is_fiscal_closed: debitNote.is_fiscal_closed,
          },
        },
      }))
    : [];
  /**
   * Set the contact email in a state of selected debit note
   */
  const fetchContactEmail = (id: number, emailList: any[]) => {
    let emails = emailList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setDebitNoteId(id);
  };

  /**
   * Download pdf handler
   */
  const downloadPdf = async (debitNoteId: number, dbnName: string) => {
    const responseAction = await dispatch(
      getDebitNotePdfLink({
        debitNoteId: debitNoteId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          dbnName + ".pdf"
        );
        if (result) {
          toast.success("Debit note downloaded successfully!", {
            toastId: "debit-note-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };
  /**
   * To set the date sort order in a state after clicking on Date column
   */
  const sortHandler = (column: string) => {
    if (column === "Debit Note Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * Send Debit note data to added email ids
   */
  const SendEmails = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendDebitNoteData({
        debitNoteId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchDebitNoteList();
      }
    }
    return responseAction;
  };

  return (
    <>
      <DebitNoteTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        debitNoteRolePermission={debitNoteRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
      />
      <SendEmailModal
        emailLists={emailList}
        id={debitNoteId}
        sendEmail={(id: number, emailList: string[]) =>
          SendEmails(id, emailList)
        }
      />
    </>
  );
}

export default React.memo(forwardRef(DebitNoteTableContainer));
