import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { deleteReminderEmailFrequency } from "../reminderEmailSlice";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deleteReminderEmailId: number;
  deleteReminderEmailItemId: string;
  organizationId: number;
  refreshInvoices: (action: string) => void;
};
const DeleteReminderEmail = (props: Props) => {
  const dispatch = useAppDispatch();
  const isDeletedRef = useRef(false);

  useEffect(() => {
    isDeletedRef.current = false;
  }, [props.deleteReminderEmailId]);

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    if (!isDeletedRef.current) {
      $("#delete-button").addClass("customer-form-section-disable");
      const deleteRoleResponseAction = await dispatch(
        deleteReminderEmailFrequency({
          id: props.deleteReminderEmailId,
          orgId: props.organizationId,
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      props.refreshInvoices("delete");
      isDeletedRef.current = true;
      toast.success("Reminder email frequency deleted successfully!", {
        toastId: "reminder-email-frequency-delete-success",
        closeButton: false,
        position: "top-center",
      });
      modalCancelHandler();
    }
  };
  return (
    <DeleteModal
      heading="Delete Reminder Email Frequency!"
      subHeading="Are you sure you want to delete the reminder email frequency"
      transactionName={joinTransactionNumber(props.deleteReminderEmailItemId)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteReminderEmail;
