import Divider from "@mui/material/Divider";
import { TextButton } from "../../../../../../../components/buttons/textButton/TextButton";
import { Container } from "../../../../../../../components/htmlTags/container/Container";
import { Popover } from "../../../../../../../components/popover/Popover";
import { DeleteIcon } from "../../../../../../../components/svgIcons/deleteIcon/DeleteIcon";
import { EditIcon } from "../../../../../../../components/svgIcons/editIcon/EditIcon";
import { KebabIcon } from "../../../../../../../components/svgIcons/kebabIcon/KebabIcon";
import { ErrorToaster } from "../../../../../../../helpers/toastHelper";
import { colors } from "../../../../../../constants/colors";
import useCommonData from "../../../../../../hooks/useCommon";
import { getTransactionStatusOfAccount } from "../../../../../chartOfAccounts/coaSlice";
import "./ListAction.css";
import { ListActionProps } from "./types";
export const ListAction = ({
  id,
  name,
  editModalRef,
  deleteModalRef,
  setAccountId,
  setAccountName,
}: ListActionProps) => {
  const { currentUserInfo, dispatch } = useCommonData();
  const accessExclusionMapping = currentUserInfo.is_super_parent
    ? []
    : ["Delete"];
  const actions = [
    { name: "Reconcile Account", icon: <></> },
    { name: "Bank Statements", icon: <></> },
    { name: "Account Transactions", icon: <></> },
    { name: "Imported Statements", icon: <></> },
    {
      name: "Edit",
      icon: <EditIcon color={colors.battleshipGrey} margin="0px 5px 0px 0px" />,
    },
    {
      name: "Delete",
      icon: (
        <DeleteIcon
          width="18px"
          height="18px"
          color={colors.tomato}
          margin="0px 5px 0px 0px"
        />
      ),
    },
  ];
  const handleMenuClick = (menuClicked: string) => {
    switch (menuClicked) {
      case "Edit":
        editAccount();
        break;
      case "Delete":
        deleteAccount();
        break;
      default:
        console.error("Wrong choice!");
    }
  };
  const editAccount = async () => {
    if (await getIsAccountMutable()) {
      setAccountId(id);
      editModalRef.current && editModalRef.current.openModal();
    } else {
      ErrorToaster(
        "Unable to edit bank account as there are transactions associated with it!"
      );
    }
  };
  const deleteAccount = async () => {
    if (await getIsAccountMutable()) {
      setAccountId(id);
      setAccountName(name);
      deleteModalRef.current && deleteModalRef.current.openModal();
    } else {
      ErrorToaster(
        "Unable to delete bank account as there are transactions associated with it!"
      );
    }
  };
  const getIsAccountMutable = async () => {
    const responseAction = await dispatch(
      getTransactionStatusOfAccount({
        transactionId: id,
        orgId: currentUserInfo.organization_id,
        transaction: "account_id",
      })
    );
    const response = responseAction.payload;
    return response ? !response[id] : false;
  };
  const ActionItem = (name: string, icon: React.ReactNode) => {
    return (
      <Container className="list-action-item-wrapper">
        <TextButton
          className="list-action-item-button"
          textAlign="left"
          fontSize="14px"
          fontWeight="normal"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleMenuClick(name);
          }}
        >
          {icon} {name}
        </TextButton>
        {name === "Imported Statements" ? (
          <Container className="list-action-divider">
            <Divider />
          </Container>
        ) : (
          <></>
        )}
      </Container>
    );
  };
  return (
    <Popover
      buttonContent={<KebabIcon />}
      verticalPosition="bottom"
      horizontalPosition="left"
      verticalTransform="top"
      horizontalTransform="right"
    >
      <Container className="list-action-items-mapping-wrapper">
        {actions
          .filter((action) => !accessExclusionMapping.includes(action.name))
          .map((action) => {
            return ActionItem(action.name, action.icon);
          })}
      </Container>
    </Popover>
  );
};
