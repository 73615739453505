import * as React from "react";
import MUIPopover from "@mui/material/Popover";
import { PopoverContext } from "./PopoverContext";
import "./Popover.css";
import { RawButton } from "../buttons/rawButton/RawButton";
import { Container } from "../htmlTags/container/Container";

type ReusablePopoverProps = {
  buttonContent: React.ReactNode;
  children: React.ReactNode;
  wrapperClass?: string;
  buttonClass?: string;
  popoverBodyClass?: string;
  verticalPosition: "top" | "bottom" | "center";
  horizontalPosition: "left" | "right" | "center";
  verticalTransform: "top" | "bottom" | "center";
  horizontalTransform: "left" | "right" | "center";

  onOpenFunction?: () => void;
  onCloseFunction?: () => void;
  showOnMouseOver?: Boolean;
};
export const Popover = ({
  buttonContent,
  children,
  buttonClass,
  popoverBodyClass,
  verticalPosition,
  verticalTransform,
  horizontalPosition,
  horizontalTransform,
  onCloseFunction,
  onOpenFunction,
  showOnMouseOver,
  wrapperClass,
}: ReusablePopoverProps) => {
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onOpenFunction && onOpenFunction();
  };

  const onMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (showOnMouseOver) {
      setAnchorEl(event.currentTarget);
      onOpenFunction && onOpenFunction();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    onCloseFunction && onCloseFunction();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <PopoverContext.Provider value={{ onClosepopover: handleClose }}>
      <Container>
        <RawButton
          aria-labelledby="popover-open-button"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e);
          }}
          onMouseEnter={onMouseOver}
          className={` no-appearance ${
            buttonClass ? buttonClass : ""
          }`}
        >
          {buttonContent}
        </RawButton>
        <MUIPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={wrapperClass ? wrapperClass : ""}
          onClose={handleClose}
          disableScrollLock
          anchorOrigin={{
            vertical: verticalPosition,
            horizontal: horizontalPosition,
          }}
          transformOrigin={{
            vertical: verticalTransform,
            horizontal: horizontalTransform,
          }}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        >
          <Container
            className={
              popoverBodyClass
                ? popoverBodyClass
                : "reusable-popover-content"
            }
          >
            {children}
          </Container>
        </MUIPopover>
      </Container>
    </PopoverContext.Provider>
  );
};
