import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { downloadUsingURL } from "../../../../helpers/helper";
import { setErrorID } from "../../../../appSlice";
import { noAccessErrorCodes } from "../../../constants/constants";
import { EmailIcon } from "../../../common/components/customSvgIcons/emailIcon";
import { colors } from "../../../constants/colors";
import { DownloadIcon } from "../../../common/components/customSvgIcons/downloadIcon";
import { MarkAsDeclinedIcon } from "../../../common/components/MarkAsDeclinedIcon";
import { CancelIcon } from "../../../common/components/CancelIcon";
import { MarkAsInvoicedIcon } from "../../../common/components/MarkAsInvoicedIcon";
import { UnmarkIcon } from "../../../common/components/UnmarkIcon";
import { ObjectType, ProformaData } from "../../../../types";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../common/commonSlice";
import {
  changeProformaStatus,
  getProformaPdfLink,
  proformaDetails,
  proformaList,
  sendProformaData,
} from "../proformaSlice";
import SendEmailModal from "../../../common/components/SendEmailModal";
import DeleteProformaInvoice from "../Delete";
import InvoicePreviewBody from "./ProformaPreviewBody";
import Header from "../../../common/components/detailsPage/Header";
import HeaderBody from "../../../common/components/detailsPage/HeaderBody";
import Edit from "../../../common/components/kebabMenu/Edit";
import Delete from "../../../common/components/Delete";
import KebabMenu from "../../../common/components/kebabMenu/KebabMenu";
import usePreservedLocation from "../../../hooks/usePreservedLocation";
import MarkAsAccepted from "../../../common/components/MarkAsAccpeted";
import CreateInvoice from "../../../common/components/CreateInvoice";
import MarkAsDeclineModal from "../components/modals/markAsDeclinedModal";
import MarkAsCancelledModal from "../components/modals/markAsCancelledModal";
import CreateInoviceModal from "../components/modals/CreateInvoiceModal";

function ProformaPreviewHeader() {
  type LocationProps = {
    redirectTo?: string;
  };
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const navigate = useNavigate();
  const locationState = location.state as LocationProps;
  const redirectTo = locationState?.redirectTo;
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  const type = searchParams.get("type");
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [deleteInvoiceId, setDeleteInvoiceId] = useState(-1);
  const [deleteInvoiceItemId, setDeleteInvoiceItemId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [openModalFlag, setOpenModalFlag] = useState(false);
  const [reRenderFlag, setReRenderFlag] = useState(0);
  const previewDetailsInitialValues = {
    id: "",
    customer_id: "",
    user_id: "",
    organization_id: "",
    proforma_status: "",
    customer_name: "",
    customer_notes: "",
    customer_gstin: "",
    currency_code: "",
    currency_id: null,
    custom_fields: {},
    proforma_number: "",
    issue_date: "",
    payment_term_id: "",
    expiry_date: "",
    address_details: [],
    sales_invoice_files: [],
    item_details: [],
    total_details: {
      total_taxable_amount: "",
      tax_amount: "",
      total: "",
      taxes: [],
      payment_made: "",
      tds_percentage: 0,
      transaction_tds_amount: 0,
    },
    email_to: [],
    organization_details: {
      organization_name: "",
      organization_gstin: "",
      email: "",
      country: "",
      state: "",
      address: "",
      logo_path: "",
      phone_number: "",
      zip_code: "",
      city: "",
    },
    is_mutable: true,
    reference_number: "",
    payment_term: "",
    is_locked: false,
    lock_date: "",
    excess_credit: 0,
    tcs_total: 0,
    tds_level: "",
    tds_name: null,
    tds_percentage: null,
    tcs_name: null,
    tcs_percentage: null,
    place_of_supply: "",
    write_off_id: null,
    is_fiscal_closed: false,
    file_ids: [],
    source_tax_type: "",
    default_tds_percentage: null,
    roundoff_enabled: false,
    proforma_invoice_files: [],
    is_invoice_associated: false,
  };
  const [invoiceData, setInvoiceData] = useState<ProformaData>(
    previewDetailsInitialValues
  );
  const [invoiceToPay, setInvoiceToPay] = useState<ObjectType>({});

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let invoicesRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Invoices")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  let paymentsReceivedPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Payments Received"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  const declineModalRef = useRef<any>();
  const cancelModalRef = useRef<any>();
  const createInvoicModalRef = useRef<any>();

  /**
   * Fetch Invoice Details
   */
  useEffect(() => {
    if (editId) {
      invoiceDetail();
    }
  }, [editId, currentUserInfo.organization_id]);

  /**
   * Set the entered emails in the invoice data to a state.
   */
  useEffect(() => {
    let emails = invoiceData.email_to?.map((data) => {
      return data.email;
    });
    setEmailList(emails);
  }, [invoiceData]);

  const invoiceDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      proformaDetails({
        proformaId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.invoice_status === "Draft") {
          navigate("/invoice");
        }
        setInvoiceData(response);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.invoiceDetail));
        // redirectTo ? navigate(redirectTo) : navigate("/invoice");
      }
    }
  };

  /**
   * Back button handler from Invoice details page
   */
  const backFromInvoiceViewHandler = () => {
    redirectUrl ? navigate(redirectUrl) : navigate(-1);
    // if (editInvoiceId) {
    //   redirectTo ? navigate(redirectTo) : navigate("/invoice");
    // } else {
    //   redirectTo ? navigate(redirectTo) : navigate("/invoice/create");
    // }
  };

  const fetchInvoiceList = async (action: string) => {
    await dispatch(
      proformaList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        statusList: [],
        customerList: [],
      })
    );
    if (action === "delete") {
      // redirectTo ? navigate(redirectTo) : navigate("/invoice");
      redirectUrl ? navigate(redirectUrl) : navigate(-1);
    }
  };

  const downloadPdf = async () => {
    const responseAction = await dispatch(
      getProformaPdfLink({
        proformaId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 1
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          invoiceData.proforma_number?.replace("~", "-") + ".pdf"
        );
        if (result) {
          toast.success("Invoice downloaded successfully!", {
            toastId: "invoice-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      }
    }
  };

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendProformaData({
        proformaId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchInvoiceList("send");
      }
    }
    return responseAction;
  };

  const reRenderJournal = () => {
    setReRenderFlag(Math.floor(Math.random() * 1000));
  };
  const changeStatus = async (status: string, reason?: string) => {
    const response = await dispatch(
      changeProformaStatus({
        proforma_id: Number(invoiceData.id),
        reason: reason ? reason : "",
        proforma_status: status,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (Object.keys(response).length && !("error" in response)) {
      invoiceDetail();
    }
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromInvoiceViewHandler}
        data={{
          num: invoiceData.proforma_number,
          status: invoiceData.proforma_status,
        }}
        dataId={editId}
      />
      <div className="card-body">
        <HeaderBody
          dataId={editId}
          data={{
            num: invoiceData.proforma_number,
            status: invoiceData.proforma_status,
            userName: invoiceData.customer_name,
            date: invoiceData.issue_date,
            orgName: invoiceData.organization_details.organization_name,
          }}
          isSameOrg={
            invoiceData.organization_id === currentUserInfo.organization_id
          }
        >
          {invoicesRolePermission.includes("Update") &&
          invoiceData.proforma_status === "Open" &&
          !invoiceData.is_fiscal_closed &&
          !invoiceData.is_subscription ? (
            <Edit
              url={`/proforma-inv/edit/${invoiceData.id}?replace=true${
                redirectUrl ? "&redirect=" + redirectUrl : ""
              }`}
              isMutable={true} //change to true after implementing edit of closed and partially paid invoice.
              name="Invoice"
              isLocked={invoiceData.is_locked}
              className=""
              lockDate={invoiceData.lock_date}
            />
          ) : null}
          {invoicesRolePermission.includes("Delete") &&
          invoiceData.proforma_status !== "Cancelled" &&
          !invoiceData.is_fiscal_closed ? (
            <Delete
              className="text-decoration-none delete-item"
              deleteIcon={true}
              isMutable={!invoiceData.is_mutable}
              isLocked={invoiceData.is_locked}
              lockDate={invoiceData.lock_date}
              name="Proforma Invoice"
              onClick={() => {
                if (!invoiceData.is_mutable) {
                  setDeleteInvoiceId(Number(invoiceData.id));
                  setDeleteInvoiceItemId(invoiceData.proforma_number);
                }
              }}
              isInvoiceAssociated={invoiceData.is_invoice_associated}
            />
          ) : null}
          {/* {invoiceData.proforma_status === "Open" ? (
            <MarkAsAccepted
              className=""
              name="mark as approved"
              onClick={() => changeStatus("Approved")}
              icon={true}
            />
          ) : null} */}
          {invoiceData.proforma_status === "Open" ? (
            <CreateInvoice
              className=""
              name="create invoice"
              onClick={() =>
                createInvoicModalRef.current?.openAlertModal &&
                createInvoicModalRef.current.openAlertModal()
              }
              icon={true}
            />
          ) : null}
          {invoiceData.proforma_status !== "Cancelled" && (
            <KebabMenu wrapperClass="detail-top-action-menu">
              <li>
                <button
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#emailToModal"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span>
                    <EmailIcon color={colors.battleshipGrey} />
                    Send Email
                  </span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={downloadPdf}
                  className="dropdown-item"
                >
                  <span>
                    <DownloadIcon color={colors.battleshipGrey} />
                    Download PDF
                  </span>
                </button>
              </li>
              {/* {invoiceData.proforma_status === "Open" && (
                <li>
                  <button
                    type="button"
                    onClick={() =>
                      declineModalRef.current?.openAlertModal &&
                      declineModalRef.current?.openAlertModal()
                    }
                    className="dropdown-item"
                  >
                    <span>
                      <MarkAsDeclinedIcon color={colors.battleshipGrey} />
                      Mark as declined
                    </span>
                  </button>
                </li>
              )}
              {invoiceData.proforma_status === "Declined" && (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      changeStatus("Open");
                    }}
                    className="dropdown-item"
                  >
                    <UnmarkIcon color={colors.battleshipGrey} />
                    <span>Unmark as declined</span>
                  </button>
                </li>
              )}
              {invoiceData.proforma_status === "Approved" && (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      changeStatus("Open");
                    }}
                    className="dropdown-item"
                  >
                    <UnmarkIcon color={colors.battleshipGrey} />
                    <span>Unmark as approved</span>
                  </button>
                </li>
              )} */}
              {invoiceData.proforma_status === "Open" && (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      changeStatus("Invoiced");
                    }}
                    className="dropdown-item"
                  >
                    <span>
                      <MarkAsInvoicedIcon color={colors.battleshipGrey} />
                      Mark as invoiced
                    </span>
                  </button>
                </li>
              )}
              {invoiceData.proforma_status === "Invoiced" && (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      changeStatus("Open");
                    }}
                    className="dropdown-item"
                  >
                    <span>
                      <UnmarkIcon color={colors.battleshipGrey} />
                      Unmark as invoiced
                    </span>
                  </button>
                </li>
              )}
              {invoiceData.proforma_status !== "Draft" &&
                invoiceData.proforma_status !== "Cancelled" && (
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        cancelModalRef.current?.openAlertModal &&
                          cancelModalRef.current.openAlertModal();
                      }}
                      className="dropdown-item"
                    >
                      <span>
                        <CancelIcon color={colors.battleshipGrey} />
                        Cancel
                      </span>
                    </button>
                  </li>
                )}
            </KebabMenu>
          )}
        </HeaderBody>
        <InvoicePreviewBody
          invoiceData={invoiceData}
          reRenderFlag={reRenderFlag}
          invoiceDetail={invoiceDetail}
        />
        <SendEmailModal
          emailLists={emailList}
          id={Number(editId)}
          sendEmail={(id: number, emailList: string[]) =>
            sendEmail(id, emailList)
          }
        />
        <DeleteProformaInvoice
          deleteInvoiceId={Number(invoiceData.id)}
          deleteInvoiceItemId={deleteInvoiceItemId}
          refreshInvoices={fetchInvoiceList}
          organizationId={currentUserInfo.organization_id}
        />
        <MarkAsDeclineModal
          message=""
          modalTitle="Decline Proforma Invoice"
          isCloseButtonEnabled={true}
          isSubmitEnabled={true}
          openRef={declineModalRef}
          handleStatusChange={changeStatus}
        />
        <MarkAsCancelledModal
          message={`Are you sure you want to cancel this Proforma Invoice ${invoiceData.proforma_number}?`}
          modalTitle="Cancel Proforma Invoice"
          isCloseButtonEnabled={true}
          isSubmitEnabled={true}
          openRef={cancelModalRef}
          handleStatusChange={changeStatus}
        />
        <CreateInoviceModal
          message=""
          modalTitle={`Create Invoice with ${invoiceData.proforma_number}`}
          isCloseButtonEnabled={true}
          isSubmitEnabled={true}
          openRef={createInvoicModalRef}
          handleStatusChange={changeStatus}
          proformaId={Number(invoiceData.id)}
        />
      </div>
    </>
  );
}

export default ProformaPreviewHeader;
