import React, { useState, useEffect } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import Checkbox from "@mui/material/Checkbox";
import "./Table.css";

interface DataTableProps<T> {
  columns: ColumnDef<T, any>[];
  data: T[];
  enableRowSelection?: boolean;
  enableSorting?: boolean;
  enableColumnSearch?: boolean;
  enableRowExpansion?: boolean;
  rightAlignedColumns?: string[];
  renderSubComponent?: (row: T) => React.ReactNode;
  onRowSelectionChange?: (selectedRows: T[]) => void;
}

const DataTable = <T,>({
  columns,
  data,
  enableRowSelection = false,
  enableSorting = false,
  enableColumnSearch = false,
  enableRowExpansion = false,
  rightAlignedColumns,
  renderSubComponent,
  onRowSelectionChange,
}: DataTableProps<T>) => {
  const [selectedRowIds, setSelectedRowIds] = useState<Record<number, boolean>>(
    {}
  );
  const [globalSelected, setGlobalSelected] = useState(false);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: enableSorting ? getSortedRowModel() : undefined,
    getFilteredRowModel: enableColumnSearch ? getFilteredRowModel() : undefined,
  });

  useEffect(() => {
    if (onRowSelectionChange) {
      const selectedData = data.filter((_, index) => selectedRowIds[index]);
      onRowSelectionChange(selectedData);
    }
  }, [selectedRowIds, data, onRowSelectionChange]);

  const handleSelectAll = () => {
    const newSelected = !globalSelected;
    setGlobalSelected(newSelected);
    const newSelectedRowIds = newSelected
      ? Object.fromEntries(data.map((_, index) => [index, true]))
      : {};
    setSelectedRowIds(newSelectedRowIds);
  };

  const handleRowSelect = (index: number) => {
    setSelectedRowIds((prev) => {
      const newSelectedRowIds = { ...prev, [index]: !prev[index] };
      if (!newSelectedRowIds[index]) {
        setGlobalSelected(false);
      }
      return newSelectedRowIds;
    });
  };
  return (
    <table id={"dataTable"}>
      <thead>
        <tr>
          {enableRowSelection && (
            <th>
              <Checkbox
                checked={globalSelected}
                onChange={handleSelectAll}
                indeterminate={
                  Object.keys(selectedRowIds).length > 0 && !globalSelected
                }
              />
            </th>
          )}
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  className={`${
                    rightAlignedColumns?.includes(header.id)
                      ? "right-aligned"
                      : "left-aligned"
                  }`}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              );
            })
          )}
        </tr>
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <React.Fragment key={row.id}>
            <tr>
              {enableRowSelection && (
                <td>
                  <Checkbox
                    checked={!!selectedRowIds[index]}
                    onChange={() => handleRowSelect(index)}
                  />
                </td>
              )}
              {row.getVisibleCells().map((cell) => {

                return(
                <td key={cell.id} className={`${
                  rightAlignedColumns?.includes(cell.column.id)
                    ? "right-aligned"
                    : "left-aligned"
                }`}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              )})}
            </tr>
            {enableRowExpansion && renderSubComponent && selectedRowIds[index] && (
              <tr>
                <td colSpan={columns.length + (enableRowSelection ? 1 : 0)} >
                  {renderSubComponent(row.original)}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
