/**
 * Modal Component
 *
 * A reusable modal component that provides  functionality.
 *
 * @param {object} props - The properties passed to the component.
 * @param {boolean} props.open - Controls the visibility of the modal.
 * @param {function} props.onClose - Callback function triggered when the modal is closed.
 * @param {React.ReactNode} props.children - Content to be displayed inside the modal.
 * @param {string} [props.className] - Optional CSS class for styling.
 * @param {string} [props.title] - Optional title for the modal.
 *
 * @example
 * <Modal open={isOpen} onClose={handleClose} title="Warning">
 *   <p>This is an  message.</p>
 * </Modal>
 */

import React, { forwardRef, useImperativeHandle, useState } from "react";
import MUImodal from "@mui/material/Modal/Modal";
import { IconButton } from "../buttons/iconButton/IconButton";
import "./Modal.css";
import { ModalContext } from "./ModalContext";
import { CloseIcon } from "../svgIcons/closeIcon/CloseIcon";
import { Container } from "../htmlTags/container/Container";
import { ReusableModalProps } from "./types";
import { Span } from "../htmlTags/span/Span";
import { Heading } from "../htmlTags/heading/Heading";
import { RawButton } from "../buttons/rawButton/RawButton";
import { Tooltip } from "../tooltips/Tooltip";

export const Modal = forwardRef(
  (
    {
      buttonContent,
      children,
      modalTitle,
      modalTitleAction,
      wrapperClass,
      buttonClass,
      modalBodyClass,
      modalButtonWrapperClass,
      isCloseButtonEnabled,
      disabledActionFunction,
      isButtonDisabled,
      onOpenFunction,
      onCloseFunction,
      tooltipMessage,
      tooltipPlacement,
      variant,
    }: ReusableModalProps,
    ref?: React.ForwardedRef<any>
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
      setIsOpen(true);
      onOpenFunction && onOpenFunction();
      // ($(".dropdown-ctrl") as any).dropdown("hide");
    };

    const closeModal = () => {
      setIsOpen(false);
      onCloseFunction && onCloseFunction();
    };

    useImperativeHandle(ref, () => ({
      openModal: openModal,
    }));

    return (
      <ModalContext.Provider value={{ closeModal: closeModal }}>
        <Container id="commonModal" className={modalButtonWrapperClass || ""}>
          {!ref && (
            <Container className="reusable-common-modal-btn">
              <RawButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  if (isButtonDisabled === true) {
                    e.preventDefault();
                    disabledActionFunction && disabledActionFunction();
                  } else {
                    e.preventDefault();
                    openModal();
                  }
                }}
                className={`${
                  isButtonDisabled === true && !disabledActionFunction
                    ? "is-disabled-common-modal-btn"
                    : ""
                } ${buttonClass || ""}`}
              >
                {tooltipMessage ? (
                  <Tooltip
                    variant="simple-tooltip"
                    placement={tooltipPlacement || "bottom"}
                    tooltipContent={tooltipMessage || ""}
                  >
                    {buttonContent}
                  </Tooltip>
                ) : (
                  buttonContent
                )}
              </RawButton>
            </Container>
          )}
          <MUImodal
            open={isOpen}
            onClose={closeModal}
            className={`common-modal  ${wrapperClass || ""}`}
          >
            <Container className={`common-modal-body ${variant}`}>
              <Container className="common-modal-body-btn-wrapper">
                {isCloseButtonEnabled && (
                  <Span className="common-modal-close-btn-span">
                    <IconButton
                      id="commonModalCloseBtn"
                      name="common-modal-close-btn"
                      onClick={closeModal}
                      ariaLabel="common-modal-close-btn"
                    >
                      <CloseIcon height="18px" width="18px" />
                    </IconButton>
                  </Span>
                )}
                <Container className="common-modal-title-wrapper">
                  <Container className="common-modal-title">
                    <Heading
                      variant="h5"
                      ariaLabelledBy="common-modal-heading"
                      className="common-modal-heading"
                    >
                      {modalTitle}
                    </Heading>
                  </Container>
                  {modalTitleAction && (
                    <Container className="common-modal-title-action">
                      {modalTitleAction}
                    </Container>
                  )}
                </Container>
                <Container
                  className={`common-modal-content ${modalBodyClass || ""}`}
                >
                  {children}
                </Container>
              </Container>
            </Container>
          </MUImodal>
        </Container>
      </ModalContext.Provider>
    );
  }
);
