import { ReportTagFormValues } from "../../../../types";
import { SettingsIcon } from "../../../common/components";
import { Span } from "../../../common/components/htmlTags/Span";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { colors } from "../../../constants/colors";

import { ReminderEmailConfigForm } from "./ReminderEmailConfigForm";

type Props = {
};
export const ReminderEmailConfigModal = (props: Props) => {
  return (
    <SideModal
      modalTitle={"Configure Reminder Email"}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <ReminderEmailConfigForm
                handleClose={onCloseModal}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <Span className="d-flex">
          <SettingsIcon color={colors.booger} margin="0px 5px 0px 0px" />
          Configure Reminder Email
        </Span>
      }
      buttonClass={
        "no-appearance secondary-btn configure-reporting-tag-setting-btn "
      }
      //   wrapperClass={props.wrapperClass ? props.wrapperClass : "w-100"}
      //   onOpenFunction={props.onOpenFunction}
    />
  );
};
