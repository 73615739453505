import { colors } from "../../../features/constants/colors";
import { SvgIconProp } from "../types";

export const NextIcon = (props: SvgIconProp) => {
  return (
    <svg
    width={props.width ? props.width : "5"}
    height={props.height ? props.height : "8"}
    viewBox={`0 0 ${
      props.width ? props.width.substring(0, props.width.length - 2) : "5"
    } ${
      props.height ? props.height.substring(0, props.height.length - 2) : "8"
    }`}
    >
      <defs>
        <path id="epvl9f0z9a" d="m.938 0 4 4-4 4L0 7.062 3.063 4 0 .937z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="m6lpjky77b" fill={props.color || colors.white}>
          <use xlinkHref="#epvl9f0z9a" />
        </mask>
        <use fill={colors.charcoalGrey} xlinkHref="#epvl9f0z9a" />
        <g mask="url(#m6lpjky77b)" fill={colors.charcoalGrey}>
          <path d="M-38-32h72v72h-72z" />
        </g>
      </g>
    </svg>
  );
};
