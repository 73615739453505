import React, { useEffect } from "react";
import Select from "react-select";
import { tableRowCountSelectStyle } from "./PaginationHeaderInfoStyleConfig";
import { Container } from "../../htmlTags/container/Container";
import { Span } from "../../htmlTags/span/Span";
import { Label } from "../../htmlTags/label/Label";
import "./PaginationHeaderInfo.css";
type Props = {
  total: number;
  startCount: number;
  endCount: number;
  itemsPerPage?: number;
  setPageInfo: (count: number, page: number) => void;
};
export default function TableInfo(props: Props) {
  const resultPerPage = [10, 25, 50, 100];
  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  return (
    <Container className="pagination-header-info-wrapper">
      <Container className="pagination-header-result-info">
        <Container
          className="pagination-header-result-info"
          id="list-table-info"
          role="status"
          aria-live="polite"
        >
          <Span>Showing</Span>
          <Span>
            {props.total > 1 ? (
              <b>
                {" "}
                {props.startCount} - {props.endCount}{" "}
              </b>
            ) : (
              <b> {props.total} </b>
            )}
          </Span>
          <Span>of</Span>{" "}
          <Span>
            {" "}
            <b>{props.total}</b>
          </Span>
        </Container>
      </Container>
      <Container className="pagination-header-info-controls-wrapper">
        <Container  id="list-table-length">
          <Label className="results-per-page-label">
            Results per page:
            <Span className="row-count-wrapper">
              <Select
                name="list-table-length"
                options={resultPerPage.map((data) => {
                  return {
                    label: data,
                    value: data,
                  };
                })}
                className="state-select form-select custom-select mt-0"
                value={{
                  label: props.itemsPerPage,
                  value: props.itemsPerPage,
                }}
                onChange={(e: any) => {
                  props.setPageInfo(Number(e.value), 1);
                }}
                styles={tableRowCountSelectStyle}
                isSearchable={false}
              />
            </Span>
          </Label>
        </Container>
      </Container>
    </Container>
  );
}
