import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Row from "./AuditLogTableRowContent";
import { AuditLogTableContainerProps } from "../../../../types";
import "./AuditTrail.css";

const AuditLogTableContainer: React.FC<AuditLogTableContainerProps> = ({
  headers,
  rows,
  detailsColumn,
  viewDetailsText,
  hideDetailsText,
  viewDetailsImage,
  detailsHeaders,
  lastRowRef,
}) => {
  return (
    <TableContainer
      component={Paper}
      className="audit-log-container"
      id="audit-log-container"
    >
      <Table aria-label="collapsible table" className="audit-log-table">
        <TableHead className="audit-log-table-head">
          <TableRow className="audit-log-table-head-row">
            {headers.map((header, index) => (
              <TableCell key={index} className="audit-log-table-cell-header">
                {header}
              </TableCell>
            ))}
            <TableCell className="audit-log-table-cell-spacer" />
          </TableRow>
        </TableHead>
        <TableBody className="audit-log-table-body">
          {rows.map((row, index) => (
            <Row
              key={index}
              row={row}
              detailsColumn={detailsColumn}
              colSpan={headers.length + 1}
              viewDetailsText={viewDetailsText}
              hideDetailsText={hideDetailsText}
              viewDetailsImage={viewDetailsImage}
              detailsHeaders={detailsHeaders}
              className="audit-log-table-row"
              ref={index === rows.length - 1 ? lastRowRef : null}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuditLogTableContainer;
