import { Box, Grid } from "@mui/material";
import MUICard from "@mui/material/Card";
import { useEffect, useMemo, useState } from "react";
import { ReminderEmailFrequency } from "../../../../types";
import EmptyReport from "../../../common/components/EmptyReport";
import { listboxCustomStyle } from "../../../common/components/ListBoxCustomStyle";
import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import { Container } from "../../../common/components/htmlTags/Container";
import ListCellPopover from "../../../common/components/listCellPopover/ListCellPopover";
import TableContainer from "../../../common/components/table/TableContainer";
import TableInfo from "../../../common/components/table/TableInfo";
import useCommonData from "../../../hooks/useCommon";
import useListData from "../../../hooks/useListData";
import { usePaginationDispatch } from "../../../hooks/usePaginationDispatch";
import PaginationContainer from "../../items/components/list/PaginationContainer";
import "../ReminderEmail.css";
import { ListActions } from "../components/listActions/ListActions";
import { useFetchEmailFrequencyList } from "../hooks/useFetchEmailFrequency";
// import { ReportingTagFormModal } from "../reportingTagForm/ReportingTagFormModal";
// import { ReportingTagSettingsFormModal } from "../reportingTagSettings/ReportingTagsSettingsFormModal";
import { useReportTagSettingsDetail } from "../../../hooks/useReportTagSettingsDetail";
import { useSubModulePermission } from "../../../hooks/useSubModulePermissions";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import { Switch } from "@mui/material";
import { ReminderEmailConfigModal } from "../reminderEmailSettings/ReminderEmailConfigModal";
import { ReminderEmailFrequencyFormModal } from "../reminderEmailForm/ReminderEmailFrequencyFormModal";
import { updateReminderEmailFrequency } from "../reminderEmailSlice";
import { EmailInfoIcon } from "../../../../assets/images";
import "../ReminderEmail.css";
export const ReminderEmailFrequencyList = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const { getEmailFrequency } = useFetchEmailFrequencyList();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const { setItemsPerPage, setPage } = usePaginationDispatch();
  const { isReportingTagEnabled } = useReportTagSettingsDetail();
  const subModulePermission = useSubModulePermission();
  const [reminderEmailFrequency, setReminderEmailFrequency] = useState<
    ReminderEmailFrequency[]
  >([]);
  const { reportinTagsPermission } = usePermissionHandler();
  useEffect(() => {
    getEmailFrequency(setReminderEmailFrequency);
  }, [
    page,
    itemsPerPage,
    currentUserInfo.organization_id,
    currentUserInfo.is_tags_enabled,
  ]);
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "show.name",
        sort: false,
        sortOrder: "",
        className: "reminder-email-frequency-name",
      },
      {
        Header: "",
        accessor: "show.description",
        sort: false,
        sortOrder: "",
        className: "reminder-email-frequency-description",
      },
      {
        Header: "",
        accessor: "show.enabled",
        sort: false,
        sortOrder: "",
        className: "reminder-email-frequency-switch",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <Switch
                checked={value.value}
                color="primary"
                className={
                  value.value
                    ? "reminder-email-frequency-switch-checked"
                    : "reminder-email-frequency-switch-unchecked"
                }
                disableRipple
                onChange={async (e) => {
                  const responseAction = await dispatch(
                    updateReminderEmailFrequency({
                      orgId: currentUserInfo.organization_id,
                      values: {
                        ...value.reminderDetails,
                        enabled: !value.value,
                      },
                      id: value.id,
                    })
                  );
                  if (!("error" in responseAction.payload)) {
                    getEmailFrequency(setReminderEmailFrequency);
                  }
                }}
              />
            </>
          );
        },
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "reminder-email-frequency-action",
        Cell: ({ cell: { value } }: any) => {
          return (
            <ListActions
              id={value.id}
              setReminderEmailFrequency={setReminderEmailFrequency}
              reminderDetails={value.reminderDetails}
              refreshInvoices={() => {
                getEmailFrequency(setReminderEmailFrequency);
              }}
            />
          );
        },
      },
    ],
    [currentUserInfo.organization_id, page, itemsPerPage]
  );
  /**
   * set table row data
   */
  const data: any = Array.isArray(reminderEmailFrequency)
    ? reminderEmailFrequency.map((reminder) => ({
        show: {
          name: `Reminder - ${reminder.reminder_email_setting_number}`,
          description: `Remind me ${reminder.days_offset} day(s) ${reminder.direction} due date`,
          enabled: {
            value: reminder.enabled,
            reminderDetails: reminder,
            id: reminder.id,
          },
          actions: {
            id: reminder.id,
            reminderDetails: reminder,
          },
        },
      }))
    : [];
  return (
    <Card>
      <CardHeader
        headerActions={
          <Container className="d-flex w-100 reporting-tag-header-btn-group">
            <ReminderEmailConfigModal />
            <ReminderEmailFrequencyFormModal
              setReminderEmailFrequencyList={setReminderEmailFrequency}
            />
          </Container>
        }
        wrapperClass="reporting-tag-list-header"
        key={`${
          String(currentUserInfo.is_super_parent) +
          String(currentUserInfo.is_tags_enabled) +
          String(currentUserInfo.organization_id)
        }`}
      >
        Reminder Email Customization
      </CardHeader>
      <MUICard variant="outlined" sx={listboxCustomStyle.cardBox}>
        <Box
          component="div"
          id="invoice-table-wrapper"
          className={
            "list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
            `${totalList > 0 ? "" : "no-data"}`
          }
        >
          <div className="alert-msg info-alert-masg d-flex align-items-center w-100">
            <img src={EmailInfoIcon} alt="info icon" />
            <p className="mb-0">
              System provides default reminders on the due date and the midpoint
              between invoice creation and its due date.
            </p>
          </div>
          <TableInfo
            total={totalList}
            startCount={startCount}
            endCount={endCount}
            itemsPerPage={itemsPerPage}
            setPageInfo={(countPerPage, page) => {
              setItemsPerPage(countPerPage);
              setPage(page);
            }}
          />
          <Grid>
            <div className="reminder-email-frequency-top-border"></div>
            <TableContainer
              className="list-table table table-striped w-100 reminder-email-frequency-table"
              columns={columns}
              data={data}
              url={"/users"}
              sortHandler={() => {}}
              editPermission={false}
              isDetailViewExist={false}
            >
              <EmptyReport
                heading="No Reminder Email Frequency found!"
                alt="no reminder email frequency"
              />
            </TableContainer>
          </Grid>
        </Box>
      </MUICard>
      {totalList > itemsPerPage ? <PaginationContainer /> : ""}
    </Card>
  );
};
