import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import { RowData } from "../../../../types";
import "./AuditTrail.css";

type RowProps = {
  row: RowData;
  detailsColumn: string;
  colSpan: number;
  viewDetailsText: string;
  hideDetailsText: string;
  viewDetailsImage: string;
  detailsHeaders: string[];
  className?: string;
};

const Row = React.forwardRef<HTMLTableRowElement, RowProps>(
  (
    {
      row,
      detailsColumn,
      colSpan,
      viewDetailsText,
      hideDetailsText,
      viewDetailsImage,
      detailsHeaders,
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);

    const StyledTableRow = styled(TableRow)({
      borderTop: "1px solid var(--approx-whisper) !important",
      backgroundColor: `${!open ? "#fff" : "#f5f8fe"}`,
    });
    const StyledTableRow2 = styled(TableRow)({
      borderTop: "1px solid var(--approx-whisper) !important",
      backgroundColor: "#fff",
    });

    return (
      <>
        <StyledTableRow className="audit-log-table-row" ref={ref}>
          {Object.keys(row).map(
            (key) =>
              key !== "history" && (
                <TableCell
                  key={key}
                  align={key === detailsColumn ? "right" : "left"}
                  className={`${
                    open
                      ? "audit-log-table-row-cell-open"
                      : "audit-log-table-row-cell-closed"
                  }`}
                >
                  {row[key]}
                </TableCell>
              )
          )}
          <TableCell
            align="right"
            onClick={() => setOpen(!open)}
            className="audit-trail-link"
          >
            {open ? hideDetailsText : viewDetailsText}{" "}
            <img
              src={viewDetailsImage}
              className={`ArrowDropBlue ${open ? "open" : "closed"}`}
              alt="dropIcon"
            />
          </TableCell>
        </StyledTableRow>
        <TableRow className="audit-log-table-collapse-row">
          <TableCell
            colSpan={4}
            sx={{
              padding: "0 !important",
              backgroundColor: "#f5f8fe",
            }}
            className="audit-details-table-wrapper-padding"
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className="audit-details-table-wrapper"
            >
              <Table size="medium" className="audit-details-table">
                <TableHead className="audit-details-table-head">
                  <TableRow>
                    {detailsHeaders.map((header, index) => (
                      <TableCell
                        key={index}
                        align={
                          index === detailsHeaders.length - 1 ? "right" : "left"
                        }
                        className="audit-details-table-head-cell-head"
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="audit-details-table-body">
                  {row.history?.map((historyRow, index) => (
                    <StyledTableRow2 key={index}>
                      <TableCell
                        className="audit-details-table-head-cell"
                        scope="row"
                      >
                        {historyRow.details}
                      </TableCell>
                      <TableCell className="audit-details-table-head-cell">
                        {historyRow.oldValue}
                      </TableCell>
                      <TableCell
                        className="audit-details-table-head-cell"
                        align="right"
                      >
                        {historyRow.newValue}
                      </TableCell>
                    </StyledTableRow2>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
);

export default Row;
