import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  PaymentReceivedListValues,
  Transactionlimit,
} from "../../../../../../types";
import useCommonData from "../../../../../hooks/useCommon";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import {
  fiscalYearFilterSelector,
  setLoaderState,
  dateListFilterSelector,
  dueDateListFilterSelector,
} from "../../../../../common/commonSlice";
import { useAppSelector } from "../../../../../../app/hooks";
import { listFilterSelector } from "../../../../../../appSlice";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import {
  paymentReceivedColumnValues,
  paymentReceivedDefaultValues,
  paymentReceivedMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import useListData from "../../../../../hooks/useListData";
import {
  getPaymentReceivedPdfLink,
  invoicePaymentList,
  sendPaymentReceivedData,
} from "../../../paymentReceivedSlice";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import SendEmailModal from "../../../../../common/components/SendEmailModal";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  DateFormatHandler,
  StatusColor,
  downloadUsingURL,
  getProfileFormatedDate,
} from "../../../../../../helpers/helper";
import TableSetup from "../../../../../common/components/table/TableSetup";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import ListActions from "./ListActions";
import CustomTooltips from "../../../../../common/components/Tooltip";
import Popover from "../../../../../common/components/Popover";
import { toast } from "react-toastify";
import PRTable from "./PRTable";

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (
    id: number,
    name: string,
    status: string,
    isUnpaid: boolean
  ) => void;
};

function PRTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setDateSortOrder,
    setTotalList,
  } = usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } =
    useListData();
  const { paymentsReceivedRolePermission } = usePermissionHandler();
  const selectedFilterData = useAppSelector(listFilterSelector);
  // const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const [invoicePaymentData, setInvoicePaymentData] = useState<
    PaymentReceivedListValues[]
  >([]);
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [invoicePaymentId, setInvoicePaymentId] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  useImperativeHandle(
    ref,
    () => ({
      fetchInvoicePaymentList: fetchInvoicePaymentList,
    }),
    []
  );

  useEffect(() => {
    fetchTableCustomizationFields(
      "Payments Received",
      paymentReceivedDefaultValues,
      paymentReceivedMandatoryValues
    );
    fetchInvoicePaymentList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    selectedFilterData,
    // JSON.stringify(fiscalYearFilter),
    dateListFilter,
    dueDateListFilter,
  ]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    paymentReceivedColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  }, [invoicePaymentData, selectedColumns]);
  /**
   * Fetch all Invoice Payments
   */
  const fetchInvoicePaymentList = async () => {
    dispatch(setLoaderState(true));
    const customerList = selectedFilterData.hasOwnProperty("Customers")
      ? selectedFilterData?.Customers
      : [];
    const responseAction = await dispatch(
      invoicePaymentList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        customerList: customerList,
        endDate: dateListFilter.date.endDate || "",
        startDate: dateListFilter.date.startDate || "",
        startDueDate: dueDateListFilter.dueDate.startDate || "",
        endDueDate: dueDateListFilter.dueDate.endDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setInvoicePaymentData(response.payments_received);
        let length = response.payments_received?.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  };

  const sortHandler = (column: string) => {
    if (column === "Payment Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };

  /**
   * Set the contact email in a state of selected invoice
   */
  const fetchContactEmail = (id: number, emailList: any[]) => {
    let emails = emailList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setInvoicePaymentId(id);
  };

  const downloadPdf = async (paymentReceivedId: number, prName: string) => {
    const responseAction = await dispatch(
      getPaymentReceivedPdfLink({
        paymentReceivedId: paymentReceivedId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          prName + ".pdf"
        );
        if (result) {
          toast.success("Payment receipt downloaded successfully!", {
            toastId: "payment-received-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      }
    }
  };

  const getCurrencyCode = (code: string) => (code === null ? "" : code);

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Payment Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Payment Number",
        accessor: selectedColumns.includes("Payment Number")
          ? "show.paymentNumber"
          : "Payment Number",
        sort: false,
        sortOrder: "",
        className: "pay_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Customer Name",
        accessor: "show.customerName",
        sort: false,
        sortOrder: "",
        className: "cust_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips placement="bottom" title={value}>
              <span className={"d-block ellipsis"}>{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Invoice Number",
        accessor: selectedColumns.includes("Invoice Number")
          ? "show.invoiceNumber"
          : "Invoice Number",
        sort: false,
        sortOrder: "",
        className: "inv_num",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? <Popover values={value} tag={false} /> : "-";
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "pay_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Amount Received",
        accessor: "show.amountReceived",
        sort: false,
        sortOrder: "",
        className: "amt_recived",
      },
      {
        Header: "Amount in Excess",
        accessor: selectedColumns.includes("Amount in Excess")
          ? "show.amountInExcess"
          : "Amount in Excess",
        sort: false,
        sortOrder: "",
        className: "amt_excess",
      },
      {
        Header: "Tax Deducted(TDS)",
        accessor: selectedColumns.includes("Tax Deducted(TDS)")
          ? "show.tds"
          : "Tax Deducted(TDS)",
        sort: false,
        sortOrder: "",
        className: "tds",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Payments Received"
            tableColumns={selectedColumns}
            customizeColumnValues={paymentReceivedColumnValues}
            mandatoryColumns={paymentReceivedMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Payments Received",
                paymentReceivedDefaultValues,
                paymentReceivedMandatoryValues
              );
              fetchInvoicePaymentList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!paymentsReceivedRolePermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              onClickSendMail={() => fetchContactEmail(value.id, value.emailTo)}
              onClickDownloadPdf={() => downloadPdf(value.id, value.prName)}
              onClickDelete={() => {
                props.deleteListData(
                  value.id,
                  value.listName,
                  value.status,
                  value.isUnpaidInvoiceExist
                );
              }}
            />
          );
        },
      },
    ],
    [
      dateSortOrder,
      selectedColumns,
      hiddenColumns,
      currentUserInfo.organization_id,
    ]
  );

  /**
   * set table row data
   */
  const data = invoicePaymentData
    ? invoicePaymentData?.map((paymentReceived, index) => ({
        show: {
          date: getProfileFormatedDate(
            currentUserInfo.organization_date_format,
            paymentReceived.payment_date
          ),
          paymentNumber: joinTransactionNumber(paymentReceived.payment_number),
          referenceNumber: paymentReceived.reference_number,
          customerName: paymentReceived.customer_name,
          invoiceNumber: paymentReceived.invoice_numbers.map((invoice) => {
            return { name: joinTransactionNumber(invoice) };
          }),
          status: paymentReceived.pr_status,
          amountReceived:
            getCurrencyCode(paymentReceived.currency_code) +
            " " +
            NumberFormat(
              Number(paymentReceived.amount_received),
              paymentReceived.currency_code,
              orgCurrencyList
            ),
          amountInExcess:
            getCurrencyCode(paymentReceived.currency_code) +
            " " +
            NumberFormat(
              Number(paymentReceived.amount_excess),
              paymentReceived.currency_code,
              orgCurrencyList
            ),
          tds:
            getCurrencyCode(paymentReceived.currency_code) +
            " " +
            NumberFormat(
              Number(paymentReceived.tax_deducted),
              paymentReceived.currency_code,
              orgCurrencyList
            ),
          organization: paymentReceived.organization,
          actions: {
            id: paymentReceived.id,
            prName: joinTransactionNumber(paymentReceived.payment_number),
            status: paymentReceived.pr_status,
            listName: paymentReceived.payment_number,
            emailTo: paymentReceived.email_to,
            isUnpaidInvoiceExist: paymentReceived.unpaid_invoice_lines_exist,
            organizationId: paymentReceived.organization_id,
            isLocked: paymentReceived.is_locked,
            lockDate: paymentReceived.lock_date,
            is_fiscal_closed: paymentReceived.is_fiscal_closed,
            isMutable: paymentReceived.is_mutable,
          },
        },
      }))
    : [];

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendPaymentReceivedData({
        prId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchInvoicePaymentList();
      }
    }
    return responseAction;
  };

  return (
    <>
      <PRTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        paymentsReceivedRolePermission={paymentsReceivedRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
      />
      <SendEmailModal
        emailLists={emailList}
        id={invoicePaymentId}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
    </>
  );
}

export default React.memo(forwardRef(PRTableContainer));
