import MUITooltip from "@mui/material/Tooltip";
import React from "react";
type SimpleTooltipProps = {
  children: React.ReactNode;
  variant: "component-tooltip" | "simple-tooltip";
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
  tooltipContent: React.ReactNode;
};

export const Tooltip = ({
  variant,
  children,
  placement,
  tooltipContent,
}: SimpleTooltipProps) => {
  return (
    <MUITooltip
      arrow={variant === "component-tooltip" ? false : true}
      title={tooltipContent}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor:
              variant === "component-tooltip" ? "var(--white-fill)" : undefined,
            border:
              variant === "component-tooltip" ? "1px solid #f4f4f4" : "none",
            padding: variant === "component-tooltip" ? "0px" : undefined,
          },
        },
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 0],
              },
            },
          ],
        },
      }}
    >
      <span>{children}</span>
    </MUITooltip>
  );
};
