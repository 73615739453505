import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import UserService from "../../../../../keycloak/UserService";
import { OrgValues } from "../../../../../types";
import {
  countrySelector,
  currenciesSelector,
  currentUserDetails,
  currentUserSelector,
  getOrganizationListForReport,
  getOrganizationListOfUser,
  setLoaderState,
} from "../../../../common/commonSlice";
import CountrySelect from "../../../../common/components/CountrySelect";
import CurrencySelect from "../../../../common/components/CurrencySelect";
import StateSelect from "../../../../common/components/StateSelect";
import { SaveButton } from "../../../../common/components/button/SaveButton";
import { SecondaryButton } from "../../../../common/components/button/SecondaryButton";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import { OrgFiscalYearSelect } from "../../../../common/components/orgFiscalYearSelect/OrgFiscalYearSelect";
import "../../OrganizationHierarchy.css";
import { createNewSubOrg, editSubOrg } from "../../OrganizationSlice";
import { SubOrgValidation } from "./subOrgFormValidation";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import Select from "react-select";
import { getOrgTimezoneList } from "../../../organizationProfile/OrganizationProfileSlice";
import { Span } from "../../../../common/components/htmlTags/Span";

type Props = {
  values: OrgValues;
  editId?: number;
  parentId?: number | null;
  currentOrgId?: number;
  handleClose: () => void;
  fetchHierarchyList: () => Promise<any>;
};
type RowProps = {
  label: string;
  id: string;
  children: React.ReactNode;
  marginBottom?: string;
};
const RowTag = ({ label, id, children, marginBottom }: RowProps) => {
  return (
    <div className={`row ${marginBottom ? marginBottom : "mb-2"}`}>
      <div className="col">
        <label htmlFor={id} className="required org-hierarchy-label">
          {label}
        </label>
        {children}
      </div>
    </div>
  );
};
export const SubOrgForm = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const currencies = useAppSelector(currenciesSelector);
  const countries = useAppSelector(countrySelector);
  const editName = useRef(props.values.name);
  const dispatch = useAppDispatch();
  const [timeZoneList, setTimeZoneList] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    getTimezones();
  }, [currentUserInfo.organization_id]);

  const formik = useFormik({
    initialValues: {
      ...props.values,
    },
    enableReinitialize: true,
    validationSchema: SubOrgValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      dispatch(setLoaderState(true));
      const responseAction = props.editId
        ? await dispatch(
            editSubOrg({
              values: values,
              currentOrgId: Number(currentUserInfo.organization_id),
              orgId: Number(props.currentOrgId),
            })
          )
        : await dispatch(
            createNewSubOrg({
              values: values,
              currentOrgId: Number(currentUserInfo.organization_id),
              parentId: Number(props.parentId),
            })
          );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (!props.editId) await UserService.updateToken();
        await dispatch(getOrganizationListOfUser(Number(props.currentOrgId)));
        await dispatch(
          getOrganizationListForReport({ orgId: Number(props.currentOrgId) })
        );
        await dispatch(
          currentUserDetails({
            orgId: currentUserInfo.organization_id,
            isOrgChanged: false,
          })
        );
        dispatch(setLoaderState(false));
        await props.fetchHierarchyList();
        props.handleClose();
        SuccessToaster(
          props.editId
            ? "Sub organization updated successfully!"
            : "Sub organization created successfully!",
          "sub-org-create-edit"
        );
      } else {
        dispatch(setLoaderState(false));
      }
    },
  });
  const getTimezones = async () => {
    const responseAction = await dispatch(
      getOrgTimezoneList({ orgId: currentUserInfo.organization_id })
    );
    const response = responseAction.payload;
    if (response) {
      let zones = Object.keys(response).map((key) => {
        return {
          value: key,
          label: `${key} (${response[key]})`,
        };
      });
      setTimeZoneList(zones);
    }
  };
  const handleCountryChange = (e: any) => {
    const value = e.value;
    const currencyCode = countries?.find((country) => {
      return country.id === parseInt(value);
    })?.currency;
    const currencyId = currencies.find(
      (currency) => currency.code === currencyCode
    )?.id;
    formik.setValues({
      ...formik.values,
      country_id: parseInt(value),
      currency_id: currencyId ? currencyId : "",
      state_id: null,
    });
  };
  const handleStateChange = (e: any) => {
    const stateId = e.value;
    formik.setValues({ ...formik.values, state_id: parseInt(stateId) });
  };

  return (
    <form
      id="addSubOrg-form"
      className="addSubOrg-form"
      onSubmit={formik.handleSubmit}
    >
      <FormInputField
        type="text"
        name="name"
        id="org_name"
        className={"form-control"}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        placeholder="Enter organization name"
        onChange={formik.handleChange}
        disabled={
          formik.values.transaction_exist || formik.isSubmitting ? true : false
        }
        error={formik.errors.name}
        wrapperClass="sub-org-input"
        label="Organization Name"
        isRequired={true}
      />
      <RowTag label="Country" id="country_id">
        <CountrySelect
          countryId={formik.values.country_id}
          isDisabled={formik.values.transaction_exist || formik.isSubmitting}
          onChange={handleCountryChange}
          id="country_id"
          error={formik.errors.country_id}
          wrapperClass="form-select-org-hierarchy-wrap"
          selectClass="form-select-org-hierarchy"
        />
      </RowTag>
      <RowTag label="State" id="state_id">
        <StateSelect
          countryId={formik.values.country_id}
          stateId={formik.values.state_id}
          id="state_id"
          onChange={handleStateChange}
          isDisabled={formik.values.transaction_exist || formik.isSubmitting}
          error={formik.errors.state_id}
          wrapperClass="form-select-org-hierarchy-wrap"
          selectClass="form-select-org-hierarchy"
        />
      </RowTag>
      <CurrencySelect
        currencyId={formik.values.currency_id}
        label="Currency"
        onChange={formik.handleChange}
        isDisabled={true}
        error=""
        wrapperClass="form-select-org-hierarchy-currency"
      />
      {props.editId && props.values.gst && (
        <FormInputField
          type="text"
          name="name"
          id="gst"
          className={"form-control input-disabled"}
          value={props.values.gst}
          onBlur={formik.handleBlur}
          placeholder=""
          onChange={formik.handleChange}
          disabled={true}
          wrapperClass="sub-org-input"
          label="GSTIN"
          isRequired={false}
        />
      )}
      <RowTag label="Fiscal Year" id="fiscal_year" marginBottom="mb-3">
        <OrgFiscalYearSelect
          handleFiscalYearChange={(fiscalYear: string) => {
            formik.setFieldValue("fiscal_year", fiscalYear);
          }}
          fiscalYear={formik.values.fiscal_year}
          fiscalYearError={formik.errors.fiscal_year}
          isDisabled={formik.values.transaction_exist}
        />
      </RowTag>
      <RowTag label="Timezone" id="timezone">
        <Select
          name="timezone"
          id="timezone"
          className={`form-select custom-select fiscal-year-select mt-0 w-100 ${
            formik.errors.timezone ? "error" : ""
          } ${currentUserInfo.timezone ? "disabled" : ""}`}
          value={
            formik.values.timezone
              ? {
                  value: formik.values.timezone,
                  label: timeZoneList.find(
                    (tz) => tz.value === formik.values.timezone
                  )?.label,
                }
              : ""
          }
          options={timeZoneList}
          styles={customSelectStyle}
          onChange={(e) => {
            formik.setFieldValue(
              "timezone",
              (e as any)?.value ? (e as any)?.value : ""
            );
          }}
          placeholder={"Choose timezone"}
          isClearable={true}
          isSearchable={true}
          isDisabled={currentUserInfo.timezone && props.editId ? true : false}
        />
        {formik.errors.timezone && (
          <Span className="error">{formik.errors.timezone}</Span>
        )}
      </RowTag>
      <div className="row mt-4">
        <div className="col">
          <div className="form-button-wrapper w-100">
            <SaveButton
              type="submit"
              buttonContent={props.editId ? "Update" : "Save"}
              isDisabled={formik.isSubmitting ? true : false}
            />
            <SecondaryButton
              type="reset"
              buttonContent="Cancel"
              isDisabled={formik.isSubmitting ? true : false}
              onClick={props.handleClose}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
