import { createAsyncThunk } from "@reduxjs/toolkit";
import { OrganizationProfileValues } from "../../../types";
import {
  editOrganizationProfile,
  fiscalYearSetter,
  getOrganizationProfileDetails,
  organizationProfileCreationStatus,
  organizationTimezoneList,
  organizationTimezoneSetter,
  profileLogoAttachments,
  removeLogoAttachment,
} from "./organizationProfileAPI";

export const updateOrganizationProfile = createAsyncThunk(
  "updateOrgProfile",
  async (data: { orgId: number; values: FormData; isLogoRemoved: boolean }) => {
    return await editOrganizationProfile(data);
  }
);

export const organizationDetails = createAsyncThunk(
  "orgDetails",
  async (orgId: number) => {
    return await getOrganizationProfileDetails(orgId);
  }
);

export const attachProfileLogo = createAsyncThunk(
  "orgProfile/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await profileLogoAttachments(data);
  }
);

export const removeProfileLogo = createAsyncThunk(
  "orgProfile/remove",
  async (orgId: number) => {
    return await removeLogoAttachment(orgId);
  }
);

export const updateFiscalYear = createAsyncThunk(
  "orgProfile/fiscalYearUpdate",
  async (data: { fiscalYear: string; orgId: number }) => {
    return await fiscalYearSetter(data);
  }
);

export const isOrganizationSetup = createAsyncThunk(
  "orgProfile/org-setup-status",
  async (data: { orgId: number }) => {
    return await organizationProfileCreationStatus(data);
  }
);

export const getOrgTimezoneList = createAsyncThunk(
  "org/time-zone-list",
  async (data: { orgId: number }) => {
    return await organizationTimezoneList(data);
  }
);
export const updateOrgTimezone = createAsyncThunk(
  "org/time-zone-list",
  async (data: { orgId: number, timezone: string }) => {
    return await organizationTimezoneSetter(data);
  }
);

