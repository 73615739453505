import { SvgIconProp } from "../types";
/**
 * DeleteIcon Component
 *
 * A reusable SVG Delete icon that can be customized via props.
 *
 * @component
 * @param {SvgIconProp} props - The properties for the DeleteIcon component.
 * @param {string} [props.width="16px"] - Width of the icon.
 * @param {string} [props.height="16px"] - Height of the icon.
 * @param {string} [props.rotate] - Rotation transformation (e.g., "rotate(45deg)").
 * @param {string} [props.color="currentColor"] - Fill color of the icon.
 * @returns {JSX.Element} The rendered DeleteIcon component.
 */
export const DeleteIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "16px"}
      height={props.height ? props.height : "16px"}
      viewBox={`0 0 ${
        props.width ? props.width.substring(0, props.width.length - 2) : "16"
      } ${
        props.height ? props.height.substring(0, props.height.length - 2) : "16"
      }`}
      style={{ transform: props.rotate,  margin: props.margin || "" }}
    >
      <g fill={"none"} fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M5.833 3.333V1.667h8.334v1.666h4.166V5h-1.666v12.5c0 .46-.373.833-.834.833H4.167a.833.833 0 0 1-.834-.833V5H1.667V3.333h4.166zM5 5v11.667h10V5H5zm2.5 2.5h1.667v6.667H7.5V7.5zm3.333 0H12.5v6.667h-1.667V7.5z"
          fill={props.color}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
