import { colors } from "../../../features/constants/colors";
import { SvgIconProp } from "../types";

export const KebabIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "20px"}
      height={props.height ? props.height : "20px"}
      viewBox={`0 0 ${
        props.width ? props.width.substring(0, props.width.length - 2) : "20"
      } ${
        props.height ? props.height.substring(0, props.height.length - 2) : "20"
      }`}
      style={{ transform: props.rotate, margin: props.margin || ""  }}
    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 0h20v20H0z" />
        <path
          d="M10 2.5c-.917 0-1.667.75-1.667 1.667 0 .916.75 1.666 1.667 1.666s1.667-.75 1.667-1.666c0-.917-.75-1.667-1.667-1.667zm0 11.667c-.917 0-1.667.75-1.667 1.666 0 .917.75 1.667 1.667 1.667s1.667-.75 1.667-1.667c0-.916-.75-1.666-1.667-1.666zm0-5.834c-.917 0-1.667.75-1.667 1.667s.75 1.667 1.667 1.667 1.667-.75 1.667-1.667-.75-1.667-1.667-1.667z"
          fill={props.color || colors.lynch}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
