import { colors } from "../../../features/constants/colors";
import { SvgIconProp } from "../types";

export const PreviousIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "5"}
      height={props.height ? props.height : "8"}
      viewBox={`0 0 ${
        props.width ? props.width.substring(0, props.width.length - 2) : "5"
      } ${
        props.height ? props.height.substring(0, props.height.length - 2) : "8"
      }`}
    >
      <defs>
        <path id="9mhpebgn8a" d="m.938 0 4 4-4 4L0 7.062 3.063 4 0 .937z" />
      </defs>
      <g transform="matrix(-1 0 0 1 5 0)" fill="none" fillRule="evenodd">
        <mask id="fviaarhavb" fill={props.color || colors.white}>
          <use xlinkHref="#9mhpebgn8a" />
        </mask>
        <use fill={colors.charcoalGrey} xlinkHref="#9mhpebgn8a" />
        <g mask="url(#fviaarhavb)" fill={colors.charcoalGrey}>
          <path d="M-38-32h72v72h-72z" />
        </g>
      </g>
    </svg>
  );
};
