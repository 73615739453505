import React from "react";
import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import useListData from "../../../hooks/useListData";
import { usePaginationDispatch } from "../../../hooks/usePaginationDispatch";
import { getReportingTagList } from "../../reportingTags/reportingTagAPIFiles/reportingTagSlice";
import {
  planSpecificCalenderInvoicingList,
  siteWideCalenderEditDetails,
  siteWideCalenderInvoicingList,
} from "../calenderBillingSlice";
import { useAppSelector } from "../../../../app/hooks";
import { listFilterSelector } from "../../../../appSlice";
import { getFrequencyFilterString } from "../helpers/calendarInvoicingHelper";
import {
  CalendarPlanSpecificBillingList,
  CalendarSiteWideBillingList,
  PlanInvoicingInitialType,
} from "../calendarBillingTypes";
import { FormikProps } from "formik";
import { SelectValues } from "../../../../types";
import { useFetchPlanSpecificDropdown } from "./useFetchPlanSpecificDropdown";

export const useFetchSiteWideBillingList = () => {
  const { page, itemsPerPage } = useListData();
  const { setStartCount, setEndCount, setTotalList } = usePaginationDispatch();
  const { currentUserInfo, dispatch } = useCommonData();
  const getSiteWideBilling = async (
    setSiteWideBillingList: (siteWideList: CalendarSiteWideBillingList) => void
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      siteWideCalenderInvoicingList({
        orgId: currentUserInfo.organization_id,
        pageNum: page,
        pageSize: itemsPerPage,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      setSiteWideBillingList(response || []);
      setTotalList(response.total || 0);
      if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
      setEndCount(
        itemsPerPage * page < response.total
          ? itemsPerPage * page
          : response.total
      );
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return { getSiteWideBilling };
};

export const useFetchPlanSpecificBillingList = () => {
  const { page, itemsPerPage } = useListData();
  const { setStartCount, setEndCount, setTotalList } = usePaginationDispatch();
  const { currentUserInfo, dispatch } = useCommonData();
  const selectedFilterData = useAppSelector(listFilterSelector);
  const getPlanSpecificBilling = async (
    setPlanSpecificBillingList: (
      planSpecificList: CalendarPlanSpecificBillingList
    ) => void
    // setPlanSpecificBillingList: React.Dispatch<React.SetStateAction<CalendarPlanSpecificBillingList>>
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      planSpecificCalenderInvoicingList({
        orgId: currentUserInfo.organization_id,
        pageNum: page,
        pageSize: itemsPerPage,
        frequencyList: selectedFilterData.Frequency
          ? getFrequencyFilterString(selectedFilterData.Frequency).map(
              (eachFrequency: string) => eachFrequency.toUpperCase()
            )
          : [],
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      setPlanSpecificBillingList({
        plan_calendar_invoicing: response.plan_calendar_invoicing || [],
      });
      setTotalList(response.total || 0);
      if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
      setEndCount(
        itemsPerPage * page < response.total
          ? itemsPerPage * page
          : response.total
      );
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return { getPlanSpecificBilling };
};

export const useFetchListDetails = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const { getPlanDropdown } = useFetchPlanSpecificDropdown();
  const getListDetails = async (
    id: number | undefined,
    setInitialValues: (initialValue: PlanInvoicingInitialType) => void,
    setPlanSpecificDropdown: (dropdowns: SelectValues[]) => void,
    setPlanCount: (count: number) => void,
    setIsNextEnabled: (nextEnabled: boolean) => void
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      siteWideCalenderEditDetails({
        orgId: currentUserInfo.organization_id,
        calendarInvoicingId: id ? id : 0,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      const updatedChoosePlan =
        response?.pricing_ids?.length > 0
          ? response.pricing_ids.map((data: number) => ({
              value: data,
              label: "",
            }))
          : [];
      const itemsPerPage =
        response.pricing_ids.length <= 10 ? 10 : response.pricing_ids.length;

      setInitialValues({
        invoicing_frequency: response.invoicing_frequency,
        invoicing_currency: response.currency_id,
        invoicing_type: response.invoicing_type,
        set_date: response.set_date ? response.set_date : "",
        set_day: response.set_day ? response.set_day : "",
        choose_plan: updatedChoosePlan,
      });
      getPlanDropdown(
        setPlanSpecificDropdown,
        [],
        response.invoicing_frequency,
        response.currency_id,
        1,
        setPlanCount,
        setIsNextEnabled,
        id,
        itemsPerPage
      );
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return { getListDetails };
};
