import { CurrentUserValues } from "../../../../types";
import { TAX_SYSTEM } from "../../../constants/constants";

export const SettingsSidebarLinks = (currentUserInfo: CurrentUserValues) => {
  let gstEnabled =
    process.env.REACT_APP_GST_ENABLED === "true" &&
    currentUserInfo.organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM;
  let vatEnabled = currentUserInfo.organization_tax_system === "UAE_TAX_SYSTEM";
  let isCalendarInvoicingEnabled =
    process.env.REACT_APP_CALENDAR_INVOICING_ENABLED;
  let isReminderEmailEnabled = process.env.REACT_APP_REMINDER_EMAIL_ENABLED;
  return [
    {
      isAccordion: false,
      props: {
        moduleName: "Organization Profile",
        subModulePermissionToCheck: ["Organization Profile"],
        path: "/settings/organization-profile",
        pathsToCheck: ["organization-profile"],
      },
    },
    {
      isAccordion: true,
      props: {
        accordionTitle: "Taxes",
        moduleClass: "taxes",
        modulePathsToCheck: [
          "tax-rates",
          "tds",
          "tcs",
          "gst",
          "default-preference",
          "tax-exemption",
          "vat",
        ],
        modulePermissionsToCheck: ["Tax Configuration", "TDS", "TCS", "GST"],
        accordionSubItems: [
          {
            subModulePermissionToCheck: "Tax Configuration",
            path: "settings/taxes/tax-rates",
            subModuleName: "Tax Rates",
            subModulePathToCheck: ["tax-rates"],
          },
          !vatEnabled && {
            subModulePermissionToCheck: "TDS",
            path: "settings/taxes/tds",
            subModuleName: "TDS",
            subModulePathToCheck: ["tds"],
          },
          !vatEnabled && {
            subModulePermissionToCheck: "TCS",
            path: "settings/taxes/tcs",
            subModuleName: "TCS",
            subModulePathToCheck: ["tcs"],
          },
          gstEnabled &&
            gstEnabled === true && {
              isExpandable: false,
              isAccordion: true,
              subModulePermissionToCheck: "GST",
              path: "",
              subModuleName: "GST",
              subModulePathToCheck: ["gst-tax-rates", "gst"],
              props: {
                accordionTitle: "GST",
                moduleClass: "taxes",
                modulePathsToCheck: [
                  "gst-tax-rates",
                  "gst",
                  "default-preference",
                  "tax-exemption",
                ],
                modulePermissionsToCheck: ["GST"],
                accordionSubItems: [
                  {
                    subModulePermissionToCheck: "GST",
                    path: "settings/taxes/gst-config",
                    subModuleName: "GST Settings",
                    subModulePathToCheck: ["gst-config"],
                  },
                  {
                    subModulePermissionToCheck: "GST",
                    path: "settings/gst-tax-rates",
                    subModuleName: "Tax Rates",
                    subModulePathToCheck: ["gst-tax-rate"],
                  },
                  {
                    subModulePermissionToCheck: "GST",
                    path: "settings/taxes/tax-exemption",
                    subModuleName: "Tax Exemption",
                    subModulePathToCheck: ["tax-exemption"],
                  },
                  {
                    subModulePermissionToCheck: "GST",
                    path: "settings/taxes/default-preference",
                    subModuleName: "Default Tax Preferences",
                    subModulePathToCheck: ["default-preference"],
                  },
                ],
              },
            },
          vatEnabled &&
            vatEnabled === true && {
              isExpandable: false,
              isAccordion: true,
              subModulePermissionToCheck: "Organization Profile",
              path: "",
              subModuleName: "VAT",
              subModulePathToCheck: ["vat-config"],
              props: {
                accordionTitle: "VAT",
                moduleClass: "taxes",
                modulePathsToCheck: ["vat-config"],
                modulePermissionsToCheck: ["Organization Profile"],
                accordionSubItems: [
                  {
                    subModulePermissionToCheck: "Organization Profile",
                    path: "settings/taxes/vat-config",
                    subModuleName: "VAT Settings",
                    subModulePathToCheck: ["vat-config"],
                  },
                ],
              },
            },
        ],
      },
    },
    {
      isAccordion: false,
      props: {
        moduleName: "Customize Prefix",
        subModulePermissionToCheck: ["Customize Prefix"],
        path: "/settings/customize-prefix",
        pathsToCheck: ["customize-prefix"],
      },
    },
    {
      isAccordion: false,
      props: {
        moduleName: "Currencies",
        subModulePermissionToCheck: [],
        path: "/settings/currency-config",
        pathsToCheck: ["currency-config"],
      },
    },
    {
      isAccordion: false,
      props: {
        moduleName: "Custom Fields",
        subModulePermissionToCheck: [],
        path: "/settings/custom-fields",
        pathsToCheck: ["custom-fields"],
      },
    },
    {
      isAccordion: true,
      modulePathsToCheck: ["general", "preferences"],
      props: {
        accordionTitle: "Preferences",
        modulePermissionsToCheck: [
          "Custom Field Sales",
          "Reporting Tag",
          "Recurring Invoice",
        ],
        modulePathsToCheck: ["general", "preferences"],
        accordionSubItems: [
          {
            isExpandable: false,
            isAccordion: true,
            subModulePermissionToCheck: ["Recurring Invoices"],
            path: "",
            subModuleName: "General",
            subModulePathToCheck: [
              "rounding-off-sales",
              "rounding-off-purchase",
              "recurring-invoice",
              "allocation-date",
              "configure-transactions",
              "reminder-email-notification-customization",
            ],
            props: {
              accordionTitle: "General",
              moduleClass: "taxes",
              modulePathsToCheck: [
                "rounding-off-sales",
                "rounding-off-purchase",
                "recurring-invoice",
                "allocation-date",
                "configure-transactions",
                "reminder-email-notification-customization",
              ],
              modulePermissionsToCheck: ["Recurring Invoices"],
              accordionSubItems: [
                {
                  subModulePermissionToCheck: "Tax Configuration",
                  path: "settings/preferences/general/rounding-off-sales",
                  subModuleName: "Rounding off in Sales Transactions",
                  subModulePathToCheck: ["rounding-off-sales"],
                },
                {
                  subModulePermissionToCheck: "Tax Configuration",
                  path: "settings/preferences/general/rounding-off-purchase",
                  subModuleName: "Rounding off in Purchase Transactions",
                  subModulePathToCheck: ["rounding-off-purchase"],
                },
                {
                  subModulePermissionToCheck: "Recurring Invoices",
                  path: "settings/preferences/general/recurring-invoice",
                  subModuleName: "Recurring Invoice",
                  subModulePathToCheck: ["recurring-invoice"],
                },
                currentUserInfo.is_super_parent && {
                  subModulePermissionToCheck: "Tax Configuration",
                  path: "settings/preferences/general/allocation-date",
                  subModuleName: "Allocation date",
                  subModulePathToCheck: ["allocation-date"],
                },
                {
                  subModulePermissionToCheck: "Recurring Invoices",
                  path: "settings/preferences/general/configure-transactions",
                  subModuleName: "Configure Transactions",
                  subModulePathToCheck: ["configure-transactions"],
                },
                isReminderEmailEnabled === "true" && {
                  subModulePermissionToCheck: "Recurring Invoices",
                  path: "settings/preferences/general/reminder-email-notification-customization",
                  subModuleName: "Reminder Email Notification Customization",
                  subModulePathToCheck: [
                    "reminder-email-notification-customization",
                  ],
                },
              ],
            },
          },
          (currentUserInfo.is_tags_enabled ||
            currentUserInfo.is_super_parent) && {
            subModulePermissionToCheck: "Reporting Tag",
            path: "settings/preferences/reporting-tags",
            subModuleName: "Reporting Tags",
            subModulePathToCheck: ["reporting-tags"],
          },
        ],
      },
    },
    {
      isAccordion: true,
      modulePathsToCheck: ["migration-date", "advanced-accounting"],
      props: {
        accordionTitle: "Advanced Accounting",
        modulePermissionsToCheck: ["Organization Profile"],
        modulePathsToCheck: ["migration-date", "advanced-accounting"],
        accordionSubItems: [
          {
            subModulePermissionToCheck: "Organization Profile",
            path: "settings/advanced-accounting/migration-date",
            subModuleName: "Migration Date",
            subModulePathToCheck: ["migration-date"],
          },
        ],
      },
    },
    {
      isAccordion: true,
      modulePathsToCheck: ["subscriptions"],
      props: {
        accordionTitle: "Subscription",
        modulePermissionsToCheck: ["Subscriptions"],
        modulePathsToCheck: [
          "calender-invoicing",
          "coupon-config",
          "invoicing-frequency",
        ],
        accordionSubItems: [
          isCalendarInvoicingEnabled === "true" && {
            subModulePermissionToCheck: "Subscriptions",
            path: "settings/subscriptions/calender-invoicing",
            subModuleName: "Calendar Invoicing",
            subModulePathToCheck: ["calender-invoicing"],
          },
          {
            subModulePermissionToCheck: "Subscriptions",
            path: "settings/subscriptions/coupon-config",
            subModuleName: " Coupon Configuration",
            subModulePathToCheck: ["coupon-config"],
          },
          {
            subModulePermissionToCheck: "Subscriptions",
            path: "settings/subscriptions/invoicing-frequency",
            subModuleName: " Invoicing Frequency",
            subModulePathToCheck: ["invoicing-frequency"],
          },
        ],
      },
    },
    {
      isAccordion: false,
      props: {
        moduleName: "Payment Gateway",
        subModulePermissionToCheck: [],
        path: "/settings/payment-gateway",
        pathsToCheck: ["payment-gateway"],
      },
    },
    {
      isAccordion: false,
      props: {
        moduleName: "SMTP Server Configuration",
        subModulePermissionToCheck: [],
        path: "/settings/smtps-config",
        pathsToCheck: ["smtps-config"],
      },
    },
    {
      isAccordion: false,
      props: {
        moduleName: "Export Data",
        subModulePermissionToCheck: [],
        path: "/settings/export-data",
        pathsToCheck: ["export-data"],
      },
    },
  ];
};
