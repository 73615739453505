import { id } from "date-fns/locale";
import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";

export async function createSideWideCalenderInvoicing(data: {
  orgId: number;
  invoicing_frequency: string;
  invoicing_type: string;
  set_date: string | null;
  set_day: string | null;
}) {
  let url =
    Api.subscription +
    `create-calendar-invoicing?current_organization=${data.orgId}`;

  const result = await post(url, {
    organization_id: data.orgId,
    invoicing_frequency: data.invoicing_frequency,
    invoicing_type: data.invoicing_type,
    set_date: data.set_date,
    set_day: data.set_day,
  });

  return result;
}

export async function updateSideWideCalenderInvoicing(data: {
  orgId: number;
  invoicing_frequency: string;
  invoicing_type: string;
  set_date: string | null;
  set_day: string | null;
  calendarInvoicingId: number;
}) {
  const result = await put(
    Api.subscription +
      `calendar-invoicing/site-wide/update/${data.calendarInvoicingId}` +
      `?current_organization=${data.orgId}`,
    {
      id: data.calendarInvoicingId,
      organization_id: data.orgId,
      invoicing_frequency: data.invoicing_frequency,
      invoicing_type: data.invoicing_type,
      set_date: data.set_date,
      set_day: data.set_day,
    }
  );
  return result;
}

export async function getSiteWideCalenderInvoicingList(data: {
  orgId: number;
  pageNum: number;
  pageSize: number;
}) {
  const result = await get(
    Api.subscription +
      `calendar-invoicing/site-wide/list?current_organization=${data.orgId}&page_num=${data.pageNum}&page_size=${data.pageSize}`
  );
  return result;
}

export async function getPlanSpecificCalenderInvoicingList(data: {
  orgId: number;
  pageNum: number;
  pageSize: number;
  frequencyList: string[];
}) {
  let url =
    Api.subscription +
    `plan-based/calendar-invoicing/list?current_organization=${data.orgId}&page_num=${data.pageNum}&page_size=${data.pageSize}`;

  if (data.frequencyList.length !== 0) {
    const frequencys = data.frequencyList
      .map((frequency) => {
        return `&frequency_list=${frequency}`;
      })
      .join("");
    url += frequencys;
  }

  const result = await get(url);
  return result;
}

export async function getSiteWideCalenderEditDetails(data: {
  orgId: number;
  calendarInvoicingId: number;
}) {
  const result = await get(
    Api.subscription +
      `calendar-invoicing-details/${data.calendarInvoicingId}?current_organization=${data.orgId}`
  );
  return result;
}

export async function deleteCalenderInvoicing(data: {
  calendarInvoicingId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      "calendar-invoicing/delete" +
      `/${data.calendarInvoicingId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * API call to get plan dropdown list
 */

export async function getplanSpecificDropdownList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  frequency: string;
  currency: string;
  calendarInvoicingID: number;
}) {
  let url =
    Api.plan +
    "dropdown/plan/pricing" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&frequency=${data.frequency}&current_organization=${data.orgId}`;

  if (data.currency) {
    url += `&currency_id=${data.currency}`;
  }

  if (data.calendarInvoicingID && data.calendarInvoicingID !== 0) {
    const calendarInvoicingID = `&calendar_invoicing_id=${data.calendarInvoicingID}`;
    url += calendarInvoicingID;
  }
  const result = await get(url);
  return result;
}

/**
 * API call to create plan specific invoicing
 */

export async function createPlanSpecificInvoicing(data: {
  organization_id: number;
  invoicing_frequency: string;
  currency_id: number;
  invoicing_type: string;
  set_date: string | null;
  set_day: string | null;
  pricing_ids: number[];
}) {
  let url =
    Api.subscription +
    `plan-based/create-calendar-invoicing?current_organization=${data.organization_id}`;

  const result = await post(url, data);

  return result;
}

/**
 * API call to create edit specific invoicing
 */

export async function editPlanSpecificInvoicing(data: {
  organization_id: number;
  invoicing_frequency: string;
  invoicing_type: string;
  set_date: string | null;
  set_day: string | null;
  pricing_ids: number[];
  id: number;
}) {
  let url =
    Api.subscription +
    `plan-based/calendar-invoicing/update/${data.id}?current_organization=${data.organization_id}`;

  const result = await put(url, data);

  return result;
}
