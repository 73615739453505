import React, { useEffect, useState } from "react";
import "../../../../../index.css";
import "./MultipleExchangeRate.css";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { CurrencyList } from "../../../../../types";
import { MultipleExchangeRatePopper } from "./MultipleExchangeRatePopper";

type Props = {
  currencyId: number | undefined; // latest currency of the transaction
  currencyCode: string | undefined;
  conversionId: string | number; // saved conversion id, only have value in edit section
  conversionRate: string;
  conversionTimestamp: string;
  conversionDate: string; // date of already saved conversion
  module?: string; // to apply style based on module
  toCurrencyId: number | null | undefined;
  toCurrencyCode: string;
  position: "start" | "end";
  isExchangeRateEditable?: boolean;
  isExchangeRateHidden?: boolean;
  exchangeRateSubmitHandler?: (data: any) => void;
  maxLength?: number;
  byUser?: boolean;
  isDisabled?: boolean;
};

const MultiExchangeRate = (props: Props) => {
  return (
    <div
      className="multiple-exchange-rate-wrapper position-relative mt-1"
      key={`${
        Number(props.conversionId) +
        Number(props.currencyId) +
        Number(props.toCurrencyId)
      }`}
    >
      {props.currencyId !== props.toCurrencyId &&
      Number(props.conversionRate) !== 0 &&
      !Number.isNaN(props.conversionRate) &&
      props.currencyCode &&
      props.toCurrencyCode &&
      !["null", "undefined"].includes(String(props.conversionRate)) &&
      Number(props.toCurrencyCode) !== Number(props.currencyId) ? (
        props.isExchangeRateEditable !== false ? (
          <div
            className={`multiple-exchange-rate-display-wrapper justify-content-${props.position}`}
          >
            <div
              className={
                props.module === "COA"
                  ? "multiple-exchange-date-rate-wrapper-multiline"
                  : "multiple-exchange-date-rate-wrapper"
              }
            >
              <div className="multiple-exchange-date-wrapper">
                ({props.byUser ? "Edited as on " : "As on "}
                {DateFormatHandler(
                  props.conversionTimestamp?.split(" ", 1).toString()
                )}{" "}
                )
              </div>
              <div className="fw-bold multiple-exchange-converted-rate-wrapper">
                1 {" " + props.currencyCode} ={" "}
                {props.conversionRate + " " + props.toCurrencyCode}
              </div>
            </div>
            {props.isDisabled ? (
              <></>
            ) : (
              <div className="multiple-exchange-rate-icon-wrapper">
                <MultipleExchangeRatePopper
                  conversionDate={props.conversionDate}
                  conversionRate={props.conversionRate}
                  currencyId={Number(props.currencyId)}
                  toCurrencyId={Number(props.toCurrencyId)}
                  toCurrencyCode={props.toCurrencyCode}
                  module={props.module}
                  exchangeRateSubmitHandler={props.exchangeRateSubmitHandler}
                  maxLength={props.maxLength}
                />
              </div>
            )}
          </div>
        ) : props.isExchangeRateHidden === true ? (
          <></>
        ) : (
          <span className="sub-label">
            1 {" " + props.currencyCode} ={" "}
            {props.conversionRate + " " + props.toCurrencyCode}
          </span>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default MultiExchangeRate;
