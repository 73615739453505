import { Container } from "../../../../../components/htmlTags/container/Container";
import { Span } from "../../../../../components/htmlTags/span/Span";
import { Popover } from "../../../../../components/popover/Popover";
import BankingDetailsTileItem from "./components/bankingDetailsTileItem/BankingDetailsTileItem";
import "./BankingCardHeader.css";
import { InfoPopup } from "./components/infoPopup/InfoPopup";


type BankingCardHeaderProps = {
  isInfoEnabled: boolean;
  bankingDetailsLeft: {
    title: string;
    amount: string;
    icon: React.ReactNode;
  }[];
  bankingDetailsRight: {
    title: string;
    anchorText: string;
    anchorLink: string;
    iconText: string;
  }[];
};

export default function BankingCardHeader({
  bankingDetailsLeft = [],
  bankingDetailsRight = [],
  isInfoEnabled,
}: BankingCardHeaderProps) {
  return (
    <Container className="banking-header-card-wrapper">
      <Container className="banking-header-card">
        <Container className="banking-header-card-left">
          {bankingDetailsLeft.map((item, index) => (
            <BankingDetailsTileItem key={index} {...item} icon={item.icon} />
          ))}
        </Container>
        <Container className="banking-header-card-right">
          {bankingDetailsRight.map((item, index) => (
            <BankingDetailsTileItem key={index} {...item} />
          ))}
        </Container>
      </Container>
      {isInfoEnabled && (
        <Container className="banking-header-info-popover">
          <Popover
            buttonContent={<Span>What are these?</Span>}
            buttonClass="text-button-normal-class blue"
            showOnMouseOver={false}
            verticalPosition="bottom"
            verticalTransform="top"
            horizontalPosition="left"
            horizontalTransform="left"
          >
            <InfoPopup />
          </Popover>
        </Container>
      )}
    </Container>
  );
}
