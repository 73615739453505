import { useEffect } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { ObjectType } from "../../../../types";
import useCommonData from "../../../hooks/useCommon";
import { paymentTermsSelector } from "../../commonSlice";
import CustomSearchSelect from "../CustomSearchSelect/CustomSearchSelect";
import PaymentTermSelect from "../PaymentTermSelect";
import { Discount } from "../discount/Discount";
import { Container } from "../htmlTags/Container";
import { Label } from "../htmlTags/Label";
import { Span } from "../htmlTags/Span";
import { InfoMessage } from "../infos/InfoMessage";
import { PaymentTermsModal } from "../paymentTerms/paymentTermsModal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InfoTooltip from "../InfoTooltip";
import { AccountSearchSelect } from "../accountSelect/AccountSearchSelect";

type DefaultConsumerValuesType = {
  account: {
    id: number | null;
    name: string;
  };
  discount: {
    discount: number | string;
    account: {
      id: number | null;
      name: string;
    };
    discountType: string;
  };
  paymentTerm: {
    id: number | null;
    name: string;
  };
  module: "Sales" | "Purchase";
  handleAccountChange: (id: number | null, name: string) => void;
  handleDiscountChange: (
    discount: string | number,
    account: ObjectType,
    discountType: string
  ) => void;
  errors: ObjectType;
  handlePaymentChangeHandle: (paymentTerm: ObjectType) => void;
  handleConsolidatedInvoiceChange?: (e: any) => void;
  consolidatedInvoicing?: boolean;
  currencyCode: string;
  handleExcludeDiscountAccount?: (e: any) => void;
  excludeDiscountAccount?: boolean;
};
export const SalesPurchaseDefault = (props: DefaultConsumerValuesType) => {
  const { dispatch, currentUserInfo } = useCommonData();
  let organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const paymentTerms = useAppSelector(paymentTermsSelector) || [
    { id: null, term_name: "" },
  ];
  let subModulesWithPermission = organizationRole.length
    ? organizationRole.map((permission: any) => {
        return permission.subModule;
      })
    : [];
  useEffect(() => {
    props.handlePaymentChangeHandle({
      name: paymentTerms[0]?.term_name,
      value: paymentTerms[0]?.id,
    });
  }, [paymentTerms]);
  return (
    <Container
      className="tab-pane fade"
      id={`pills-${props.module.toLowerCase()}-default`}
      role="tabpanel"
      ariaLabelledBy={`pills-${props.module.toLowerCase()}-default-tab`}
    >
      <InfoMessage
        message={`Selected ${props.module.toLowerCase()} defaults can be overridden at the transaction level`}
        wrapperClass="mw-500 mb-4"
      />
      <Container className="row">
        <Container className="col mw-325 btn-group-vertical ">
          <Label htmlFor="account" className="">
            {`${props.module} Account`}
          </Label>
          <Container className="w-100">
            <AccountSearchSelect
              itemAccount={Number(props.account.id)}
              itemAccountName={props.account.name}
              organizationId={currentUserInfo.organization_id}
              handleItemAccountChange={(id, index, name) => {
                props.handleAccountChange(id, name);
              }}
              itemIndex={0}
              error={""}
              baseAccount={props.module === "Purchase" ? "Expenses" : "Incomes"}
              wrapperClass="sales-default-account-select w-100"
              className="sales-purchase-default-account-select"
            />
          </Container>
          <Span className="error">{""}</Span>
        </Container>
        <Container className="col-12 col-lg-4 mw-325">
          <Container className="link-label-grouped">
            <Label className="" htmlFor="c_payment_terms">
              Payment Terms
            </Label>
            {window.location.pathname.includes("customers") ||
            (window.location.pathname.includes("vendors") &&
              subModulesWithPermission.includes("Payments Terms")) ? (
              <PaymentTermsModal />
            ) : null}
          </Container>
          <PaymentTermSelect
            dropPosition={"bottom"}
            paymentTermId={
              props.paymentTerm.id === null
                ? (Array.isArray(paymentTerms) &&
                    typeof paymentTerms[0] === "object" &&
                    paymentTerms[0]?.id) ||
                  0
                : props.paymentTerm.id
            }
            onChange={(data) => {
              props.handlePaymentChangeHandle(data);
            }}
          />
        </Container>
      </Container>
      <Container className="row">
        <Container className="col mw-325 mt-3">
          <Discount
            organizationId={currentUserInfo.organization_id}
            handleItemAccountChange={(data) => {
              props.handleDiscountChange(
                props.discount.discount,
                {
                  id: data.target.value,
                  name: props.discount.account.name,
                },
                props.discount.discountType
              );
            }}
            handleDiscountChange={(data) => {
              props.handleDiscountChange(
                data.target.value,
                {
                  id: props.discount.account.id,
                  name: props.discount.account.name,
                },
                props.discount.discountType
              );
            }}
            handleDiscountType={(value: string) => {
              props.handleDiscountChange(
                props.discount.discount,
                {
                  id: props.discount.account.id,
                  name: props.discount.account.name,
                },
                value
              );
            }}
            index={0}
            discount={props.discount.discount}
            discountType={
              props.discount.discountType
                ? props.discount.discountType
                : "Absolute"
            }
            discountAccountId={props.discount.account.id}
            discountAccountName={props.discount.account.name}
            currencyCode={props.currencyCode}
            baseAccount={props.module === "Purchase" ? "Incomes" : "Expenses"}
            error={props.errors.discount}
            handleItemChange={(e: any, index: any) => {}}
            handleOnBlur={(name: string, amount: number, index: number) => {
              props.handleDiscountChange(
                amount,
                {
                  id: props.discount.account.id,
                  name: props.discount.account.name,
                },
                props.discount.discountType
              );
            }}
            wrapperClass="sales-default-discount"
            isDisabled={props.excludeDiscountAccount}
          />
        </Container>
      </Container>
      <Container className="row" style={{ paddingLeft: "9px" }}>
        <Container className="col mw-325 mt-3">
          <FormControlLabel
            id="exclude_discount_accounting"
            name="exclude_discount_accounting"
            control={
              <Checkbox
                value={props.excludeDiscountAccount}
                checked={props.excludeDiscountAccount}
                onChange={props.handleExcludeDiscountAccount}
                className="reporting-tags-settings-form-checkbox"
              />
            }
            label="Exclude discount from accounting"
          />
          <InfoTooltip title="Select this option if you do not want the discount to be accounted as an expense in the system. When selected, the discount will only be applied to reduce the gross value without creating any accounting entries." />
        </Container>
      </Container>
      <Container className="row" style={{ paddingLeft: "9px" }}>
        <Container className="col mw-325">
          <FormControlLabel
            id="consolidated_invoicing"
            name="consolidated_invoicing"
            control={
              <Checkbox
                value={props.consolidatedInvoicing}
                checked={props.consolidatedInvoicing}
                onChange={(e) =>
                  props.handleConsolidatedInvoiceChange
                    ? props.handleConsolidatedInvoiceChange(e)
                    : {}
                }
                className="reporting-tags-settings-form-checkbox"
              />
            }
            label="Consolidated invoicing"
          />
        </Container>
      </Container>
    </Container>
  );
};
