import { useFormik } from "formik";
import Multiselect from "multiselect-react-dropdown";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { TagSelectedClose } from "../../../../../assets/images";
import { colors } from "../../../../constants/colors";
import { addonList } from "../../../../home/addons/addonSlice";
import { chargesList } from "../../../../home/charges/ChargesSlice";
import { fetchAccountsForFilter } from "../../../../home/chartOfAccounts/coaSlice";
import { couponList } from "../../../../home/coupon/couponSlice";
import { customerDropdownList } from "../../../../home/customerManagement/customerSlice";
import { reportTableFilterSelector } from "../../../../home/reports/ReportSlice";
import PredefinedDateRangePicker from "../../../../home/reports/componets/PredefinedDateRange";
import { subscriptionPlanDropdown } from "../../../../home/subscription/subscriptionSlice";
import {
  currentUserSelector,
  fetchCustomFrequencySettings,
  getOrganizationListOfUser,
} from "../../../commonSlice";
import { customSelectStyle } from "../../SelectCustomStyle";
import { DeleteIcon } from "../../customSvgIcons/deleteIcon";
import { FormSelectField } from "../../formSelectField/FormSelectField";
import { addFilterOptions, fieldOptionsForExport } from "../OptionConstants";
import "./addFilterComponent.css";

type Props = {
  onDelete: (index: number) => void; // Accepts an index argument
  index: number; // Add an index prop
  value: {
    field: string;
    comparator: string;
    value: string | string[];
  };
  getFilterValues: (
    data: {
      field: string;
      comparator: string;
      value: string | string[];
    },
    index: number
  ) => void;
  selectedFields: string[];
  isError: boolean;
  deleteBtnClass?: string;
  moduleType?: string;
};
type FilterRef = {
  handleSubmit: () => Promise<void>;
  isError: boolean;
};
export const AddFilter = forwardRef(
  (props: Props, ref?: React.ForwardedRef<FilterRef>) => {
    const [fieldOptions, setFieldOptions] = useState<
      {
        label: string;
        value: string;
        organization_name?: string;
        customer_identification?: string;
      }[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState<
      {
        value: string;
        label: string;
      }[]
    >([]);
    const dispatch = useAppDispatch();
    const currentUserInfo = useAppSelector(currentUserSelector);
    const tableFilters = useAppSelector(reportTableFilterSelector);
    const initialFilterValue = {
      field: "",
      comparator: "",
      value: "",
    };
    const formik = useFormik({
      initialValues: { ...initialFilterValue },
      enableReinitialize: true,
      onSubmit: (values) => {},
    });
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(10);
    let isPaginationLoading = false;
    const moduleToCheck = [
      "Transaction Type",
      "Account",
      "Customer",
      "Plan",
      "Addons",
      "Coupons",
      "Charges",
      "Subscription Status",
      "Invoice Status",
      "Frequency",
    ];
    useEffect(() => {
      props.getFilterValues(formik.values, props.index);
    }, [formik.values]);
    useEffect(() => {
      if (
        ["Transaction Type", "Account"].includes(formik.values.field) &&
        selectedTags.length > 0
      ) {
        formik.setFieldValue(
          "value",
          selectedTags.map((tags) => {
            return tags.label;
          })
        );
      }
      if (
        [
          "Customer",
          "Plan",
          "Addons",
          "Coupons",
          "Charges",
          "Subscription Status",
          "Invoice Status",
          "Is Recurring",
          "Frequency",
        ].includes(formik.values.field) &&
        selectedTags.length > 0
      ) {
        formik.setFieldValue(
          "value",
          selectedTags.map((tags) => {
            return tags.value;
          })
        );
      }
    }, [selectedTags]);
    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;

      switch (formik.values.field) {
        case "Transaction Type":
          fetchFieldValueOptions("Transaction Type", signal);
          break;
        case "Account":
          fetchFieldValueOptions("Account", signal);
          break;
        case "Account Group":
          fetchFieldValueOptions("Account Group", signal);
          break;
        case "Customer":
          fetchFieldOptionsForExport("Customer");
          break;
        case "Plan":
          fetchFieldOptionsForExport("Plan");
          break;
        case "Addons":
          fetchFieldOptionsForExport("Addons");
          break;
        case "Coupons":
          fetchFieldOptionsForExport("Coupons");
          break;
        case "Charges":
          fetchFieldOptionsForExport("Charges");
          break;
        case "Subscription Status":
          setFieldOptions(fieldOptionsForExport.subscription_status);
          break;
        case "Invoice Status":
          setFieldOptions(fieldOptionsForExport.invoice_status);
          break;
        case "Is Recurring":
          setFieldOptions(fieldOptionsForExport.is_recurring);
          break;
        case "Organization":
          fetchFieldOptionsForExport("Organization");
          break;
        case "Frequency":
          fetchFieldOptionsForExport("Frequency");
          break;
        default:
          setFieldOptions([]);
          break;
      }
      return () => {
        controller.abort();
      };
    }, [formik.values.field]);
    useImperativeHandle(
      ref,
      () => ({
        handleSubmit: async () => {
          await formik.submitForm();
        },
        isError: Object.keys(formik.errors).length > 0,
      }),
      [formik]
    );
    const options = {
      field:
        props.moduleType === "Subscription Export"
          ? addFilterOptions.subscriptionField
          : props.moduleType === "Invoice Export"
          ? addFilterOptions.invoiceField
          : addFilterOptions.field,
      comparator: addFilterOptions.comparator,
      field_option: fieldOptions,
    };

    const handleSelectTags = (e: any) => {
      setSelectedTags((values) => {
        return [...e];
      });
    };

    const handleRemoveTags = (e: any) => {
      setSelectedTags((values) => {
        return [...e];
      });
    };
    const fetchFieldValueOptions = async (
      field: string,
      signal: AbortSignal
    ) => {
      setIsLoading(true);
      const responseAction = await dispatch(
        fetchAccountsForFilter({
          orgId: tableFilters.organization_id
            ? tableFilters.organization_id
            : currentUserInfo.organization_id,
          field: field,
          signal: signal,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        setFieldOptions(
          response.map((option: string) => {
            return { value: option, label: option };
          })
        );
        setIsLoading(false);
      } else {
        setFieldOptions([]);
        setIsLoading(false);
      }
    };
    const fetchFieldOptionsForExport = async (field: string) => {
      if (page === 1) {
        setIsLoading(true);
      }
      isPaginationLoading = true;
      if (field === "Customer") {
        if (total > fieldOptions.length) {
          const customerResponseAction = await dispatch(
            customerDropdownList({
              orgId: currentUserInfo.organization_id,
              searchQuery: "",
              customerId: null,
              pageNum: page,
              pageSize: 10,
            })
          );
          if (customerResponseAction.payload) {
            const response = customerResponseAction.payload;

            setFieldOptions((prevOptions) => [
              ...prevOptions,
              ...response.customers.map((customer: any) => ({
                label: customer.customer_display_name,
                value: customer.id,
                organization_name: customer.organization_name,
                customer_identification: customer.import_id,
              })),
            ]);

            setPage(page + 1);
            setTotal(response.total);
            isPaginationLoading = false;
          }
        }
      } else if (field === "Plan") {
        if (total > fieldOptions.length) {
          const planResponseAction = await dispatch(
            subscriptionPlanDropdown({
              page: page,
              itemsPerPage: 10,
              orgId: currentUserInfo.organization_id,
              searchQuery: "",
              signal: undefined,
            })
          );
          if (planResponseAction.payload) {
            const response = planResponseAction.payload;
            setFieldOptions((prevOptions) => [
              ...prevOptions,
              ...response.plans.map((plan: any) => ({
                label: plan.external_name,
                value: plan.id,
              })),
            ]);
            setPage(page + 1);
            setTotal(response.total);
            isPaginationLoading = false;
          }
        }
      } else if (field === "Addons") {
        if (total > fieldOptions.length) {
          const addonsResponseAction = await dispatch(
            addonList({
              page: page,
              itemsPerPage: 10,
              orgId: currentUserInfo.organization_id,
              orgIds: [],
              statusList: [],
            })
          );
          if (addonsResponseAction.payload) {
            const response = addonsResponseAction.payload;
            setFieldOptions((prevOptions) => [
              ...prevOptions,
              ...response.addons.map((addon: any) => ({
                label: addon.external_name,
                value: addon.id,
              })),
            ]);
            setPage(page + 1);
            setTotal(response.total);
            isPaginationLoading = false;
          }
        }
      } else if (field === "Coupons") {
        if (total > fieldOptions.length) {
          const couponResponseAction = await dispatch(
            couponList({
              page: page,
              itemsPerPage: 10,
              orgId: currentUserInfo.organization_id,
              orgIds: [],
              statusList: [],
            })
          );
          if (couponResponseAction.payload) {
            const response = couponResponseAction.payload;
            setFieldOptions((prevOptions) => [
              ...prevOptions,
              ...response.coupons.map((coupon: any) => ({
                label: coupon.name,
                value: coupon.id,
              })),
            ]);
            setPage(page + 1);
            setTotal(response.total);
            isPaginationLoading = false;
          }
        }
      } else if (field === "Charges") {
        if (total > fieldOptions.length) {
          const chargeResponseAction = await dispatch(
            chargesList({
              page: page,
              itemsPerPage: 10,
              orgId: currentUserInfo.organization_id,
              orgIds: [],
              statusList: [],
            })
          );
          if (chargeResponseAction.payload) {
            const response = chargeResponseAction.payload;
            setFieldOptions((prevOptions) => [
              ...prevOptions,
              ...response.charges.map((charge: any) => ({
                label: charge.external_name,
                value: charge.id,
              })),
            ]);
            setPage(page + 1);
            setTotal(response.total);
            isPaginationLoading = false;
          }
        }
      } else if (field === "Organization") {
        const orgResponseAction = await dispatch(
          getOrganizationListOfUser(currentUserInfo.organization_id)
        );
        if (orgResponseAction.payload) {
          const response = orgResponseAction.payload;
          setFieldOptions(
            response.map((org: any) => ({
              label: org.name,
              value: org.id,
            }))
          );
        }
      } else if (field === "Frequency") {
        const frequencyResponseAction = await dispatch(
          fetchCustomFrequencySettings(currentUserInfo.organization_id)
        );
        if (frequencyResponseAction.payload) {
          const response = frequencyResponseAction.payload;
          setFieldOptions(
            response.map((item: any) => ({
              label: item.frequency,
              value: {
                [item.custom_repeat_frequency]: item.custom_repeat_count,
              },
            }))
          );
        }
      }
      setIsLoading(false);
    };
    const getSelectedOption = () => {
      if (
        [
          "Customer",
          "Plan",
          "Addons",
          "Coupons",
          "Charges",
          "Subscription Status",
          "Invoice Status",
          "Frequency",
        ].includes(formik.values.field) &&
        formik.values.value.length > 0
      ) {
        return Array.isArray(formik.values.value)
          ? formik.values.value.map((tags) => {
              const obj = fieldOptions.find((item) => item.value === tags);
              return {
                label: obj?.label,
                value: obj?.value,
              };
            })
          : [];
      } else {
        return Array.isArray(formik.values.value)
          ? formik.values.value.map((tags) => {
              return {
                label: tags,
                value: tags,
              };
            })
          : [];
      }
    };
    const mulitSelectPlaceholderHandler = () => {
      const dontNeedSuffix = [
        "Addons",
        "Coupons",
        "Charges",
        "Invoice Status",
        "Subscription Status",
      ];
      return `Select ${
        formik.values.field !== ""
          ? dontNeedSuffix.includes(formik.values.field)
            ? formik.values.field.toLowerCase()
            : formik.values.field === "Frequency"
            ? "frequencies"
            : formik.values.field.toLowerCase() + "s"
          : "an option"
      }`;
    };

    useEffect(() => {
      // Select the dropdown container dynamically
      const dropdownElement = document.querySelector(
        ".multiselect-container .optionContainer"
      ) as HTMLDivElement | null;

      if (!dropdownElement) return;

      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = dropdownElement;
        if (
          scrollTop + clientHeight >= scrollHeight - 10 &&
          !isPaginationLoading
        ) {
          if (
            ["Plan", "Addons", "Coupons", "Charges", "Customer"].includes(
              formik.values.field
            )
          ) {
            if (!isPaginationLoading) {
              fetchFieldOptionsForExport(formik.values.field);
            }
          }
        }
      };

      dropdownElement.addEventListener("scroll", handleScroll);

      return () => {
        dropdownElement.removeEventListener("scroll", handleScroll);
      };
    }, [fieldOptions]);

    return (
      <div
        className={`row position-relative add-filter-section ${
          props.isError ? "add-filter-error" : ""
        }`}
        key={props.index}
        tabIndex={props.index}
      >
        <FormSelectField
          name="field"
          id="field"
          className={`form-select customize-report-select ${
            props.isError && formik.values.field === ""
              ? "add-filter-error"
              : ""
          } `}
          isDisabled={isLoading}
          isOnlyInRow={false}
          styles={customSelectStyle}
          placeholder="Select a field"
          isRequired={false}
          isSearchable={true}
          error={formik.errors.field}
          label="Select Field"
          onChange={(e: any) => {
            formik.setValues({ field: e.label, comparator: "", value: "" });
            setPage(1);
            setFieldOptions([]);
          }}
          options={options.field.filter(
            (fields: { value: string; label: string }) =>
              !props?.selectedFields?.includes(fields.label)
          )}
          value={{
            label: formik.values.field,
            value: formik.values.field,
          }}
          wrapperClass={`${
            moduleToCheck.includes(formik.values.field)
              ? "transaction-type-select"
              : ""
          }`}
          isClearable={false}
        />
        {!["Is Recurring", "Created On"].includes(formik.values.field) && (
          <FormSelectField
            name="comparator"
            id="comparator"
            className={`form-select customize-report-select ${
              props.isError && formik.values.comparator === ""
                ? "add-filter-error"
                : ""
            }`}
            isDisabled={false}
            isOnlyInRow={false}
            styles={customSelectStyle}
            placeholder="Select a comparator"
            isRequired={false}
            isSearchable={true}
            error={formik.errors.comparator}
            label="Select Comparator"
            onChange={(e: any) => formik.setFieldValue("comparator", e.label)}
            options={options.comparator}
            value={{
              label: formik.values.comparator,
              value: formik.values.comparator,
            }}
            wrapperClass={`${
              moduleToCheck.includes(formik.values.field)
                ? "transaction-type-select"
                : ""
            }`}
            isClearable={false}
          />
        )}
        {!moduleToCheck.includes(formik.values.field) ? (
          formik.values.field !== "Created On" ? (
            <FormSelectField
              name="value"
              id="value"
              className={`form-select customize-report-select ${
                props.isError && formik.values.field === ""
                  ? "add-filter-error e"
                  : ""
              }`}
              isDisabled={false}
              isOnlyInRow={false}
              styles={customSelectStyle}
              placeholder={`Select ${
                formik.values.field !== ""
                  ? formik.values.field.toLowerCase()
                  : "an option"
              }`}
              isRequired={false}
              isSearchable={true}
              error={formik.errors.value}
              label="Additional Field"
              onChange={(e: any) => formik.setFieldValue("value", [e.label])}
              options={options.field_option}
              value={{
                label: formik.values.value[0],
                value: formik.values.value[0],
              }}
              isClearable={false}
              isLoading={isLoading}
            />
          ) : (
            <div className="transaction-type-select">
              <span className="align-wrapper">
                <label className="form-label" htmlFor="tags">
                  Date
                </label>
              </span>
              <PredefinedDateRangePicker
                isSingleDatePicker={false}
                isAdvanced={true}
                defaultValue={"quarter"}
                reportType={""}
                resetType={""}
                handleDateRange={(
                  start: string,
                  end: string,
                  range: string
                ) => {
                  formik.setFieldValue("value", [{ start, end, range }]);
                }}
                filterdata={{}}
                isInModal={true}
              />
            </div>
          )
        ) : (
          <div className="form-outline trans-type-item mb-4 tag-wrapper position-relative">
            <span className="align-wrapper">
              <label className="form-label" htmlFor="tags">
                Additional Field
              </label>
            </span>
            <Multiselect
              options={options.field_option}
              id="value_transaction"
              displayValue="label"
              showCheckbox={true}
              loading={isLoading}
              emptyRecordMsg={"No Matches found"}
              className={`${
                props.isError && formik.values.value === ""
                  ? "add-filter-error"
                  : ""
              } white-space-break-spaces`}
              customCloseIcon={
                <img src={TagSelectedClose} alt="tag-selected-close-img" />
              }
              placeholder={mulitSelectPlaceholderHandler()}
              onSelect={handleSelectTags}
              onRemove={handleRemoveTags}
              selectedValues={getSelectedOption()}
              optionValueDecorator={(v, option) => {
                return (
                  <span className="multi-select-label-wrapper">
                    <span
                      className={` ${
                        ["Customer"].includes(formik.values.field)
                          ? "multi-select-main-label-bold"
                          : "multi-select-main-label"
                      }`}
                    >
                      {option.label}
                    </span>
                    {option.customer_identification && (
                      <span className="multi-select-label-sub-root">
                        {option.customer_identification}
                      </span>
                    )}
                    {option.organization_name && (
                      <span className="multi-select-label-root">
                        {option.organization_name}
                      </span>
                    )}
                  </span>
                );
              }}
            />
          </div>
        )}
        <button
          className={`no-appearance delete-button position-absolute ${props.deleteBtnClass}`}
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            props.onDelete(props.index);
          }}
        >
          <DeleteIcon color={colors.tomato} />
        </button>
      </div>
    );
  }
);
