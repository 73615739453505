import { useEffect, useMemo, useRef, useState } from "react";
import { FormikTouched } from "formik";
import { FormValues } from "../../../../../adapters/formAdapter/type";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { getFormatedDate } from "../../../../../helpers/helper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import {
  ObjectType,
  SubscriptionDetailConsolidatedInvoiceValues,
  SubscriptionDetailValues,
} from "../../../../../types";
import { colors } from "../../../../constants/colors";
import labels from "../../../../constants/labels";
import messages from "../../../../home/invoice/components/createInvoice/resources/messages";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import {
  createExchangeRateList,
  setExchangeApiSatus,
} from "../../../commonSlice";
import AddLineItem from "../../AddLineItem";
import AccountSelect from "../../accountSelect/AccountSelect";
import { DeleteIcon } from "../../customSvgIcons";
import MultiExchangeRate from "../../exchangeRate/exchangeRateWithPopper/MutipleExchangeRate";
import { Container } from "../../htmlTags/Container";
import { Section } from "../../htmlTags/Section";
import { Span } from "../../htmlTags/Span";
import { Button, FormFieldContainer, Input } from "../../ui";
import SectionHeading from "../../ui/containers/sectionHeading/SectionHeading";
import GstSelect from "../gstSelect/GstSelect";
import { Discount, ItemSelect, TaxSelect, TdsSelect } from "../index";
import ItemDetailsWrapper from "./components/itemDetailsWrapper/ItemDetailsWrapper";
import LineItemWrapper from "./components/lineItemWrapper/LineItemWrapper";
import useItemForSales from "./hooks/useItemForSales";
import "./itemDetails.css";
import { itemErrorSetter } from "./utils/itemErrorSetter";
import { useInvoiceAmountCalculation } from "../../../../home/invoice/components/createInvoice/hooks/useInvoiceAmountCalculation";
import { setLoaderState } from "../../../../common/commonSlice";
import { subscriptionDataForInvoice } from "../../../../home/subscription/subscriptionSlice";
import {
  subscriptionDetailsConsolidatedInitialValues,
  subscriptionDetailsFutureRenewalInitialValues,
  subscriptionDetailsInitialValues,
} from "../../../../home/subscription/SubscriptionStateInitialization";
import DetailsTableContainerSubscription from "../../detailsPage/DetailsTableContainerSubscription";
import TaxColumn from "../../detailsPage/TaxColumn";
import ItemDetailsSubscription from "./ItemDetailsSubscription";
import { tdsApplyLevel } from "../../../../constants/constants";
import InvoicePreviewBodyCosolidatedInvoice from "../../../../home/invoice/components/invoicePreview/InvoicePreviewBodyCosolidatedInvoice";
import InvoicePreviewBodyConsolidatedInvoiceEdit from "../../../../home/invoice/components/createInvoice/InvoicePreviewBodyConsolidatedInvoiceEdit";
import InvoiceFutureRenewalEdit from "../../../../home/invoice/components/createInvoice/InvoiceFutureRenewalEdit";

type Props = {
  isHsnCode: boolean;
  values: FormValues;
  setValues: (values: any) => void;
  errors: any;
  setErrors: any;
  touched: ObjectType;
  setTouched?: (touched: FormikTouched<any>) => void;
  setFieldTouched?: (field: string, isTouched?: boolean) => void;
  children: any;
  module?: string;
  is_subscription?: boolean;
  is_consolidated?: boolean;
  editId?: string | number;
};

const ItemDetails = (props: Props) => {
  const [overviewSubscriptionData, setOverviewSubscriptionData] =
    useState<SubscriptionDetailValues>(subscriptionDetailsInitialValues);
  const [
    overviewSubscriptionDataConsolidated,
    setOverviewSubscriptionDataConsolidated,
  ] = useState<SubscriptionDetailConsolidatedInvoiceValues>(
    subscriptionDetailsConsolidatedInitialValues
  );
  const [overviewFutureRenewalData, setOverviewFutureRenewalData] =
    useState<any>(subscriptionDetailsFutureRenewalInitialValues);
  const { isGstOrg } = useGst();
  const { orgCurrencyList, currentUserInfo, dispatch } = useCommonData();
  const scrollLeftRef = useRef(0);
  const { calculateInvoiceTotalAmount, abortControllerRef } =
    useInvoiceAmountCalculation({
      values: props.values,
      setValues: props.setValues,
    });
  const {
    addItemContainer,
    handleItemDropdownChange,
    handleItemChange,
    handleItemGstChange,
    handleDiscountAccountChange,
    handleDiscountTypeChange,
    handleDiscountValueChange,
    handleTDSAmountChange,
    handleTDSChange,
    handleDiscountOnBlur,
    handleHSNChange,
    handleTDSOnBlur,
    handleItemAccountChange,
    clearTDS,
    deleteItemContainer,
    addItemTaxContainer,
    deleteItemTaxContainer,
    handleItemTaxChange,
  } = useItemForSales({
    values: props.values,
    setValues: props.setValues,
    module: props.module,
  });
  const isUAEOrg = currentUserInfo.organization_tax_system === "UAE_TAX_SYSTEM";

  const handleExchangeRateSubmit = async (data: any) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    dispatch(setExchangeApiSatus(true));
    const createResponseAction = await dispatch(
      createExchangeRateList({
        values: { ...data, timestamp: getFormatedDate() },
      })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      SuccessToaster(messages.success.conversionUpdated, "rate-create-success");
      props.setValues({
        conversion_id: createResponse.id,
        conversion_rate: createResponse.rate,
        conversion_timestamp: getFormatedDate(createResponse.timestamp),
        by_user: createResponse.by_user,
        recalculate_exchange_rate: true,
      });
      calculateInvoiceTotalAmount({
        values: {
          ...props.values,
          conversion_id: createResponse.id,
          conversion_rate: createResponse.rate,
          conversion_timestamp: getFormatedDate(createResponse.timestamp),
        },
      });
    }
    dispatch(setExchangeApiSatus(false));
  };
  const clearGstSelection = (itemIndex: number, gst_object: ObjectType) => {
    const items = props.values.items;
    const deletedItemsId = [
      ...props.values.deleted_gst_id,
      {
        itemId: items[itemIndex].id,
        id: items[itemIndex].gst_tax?.id,
      },
    ];
    items[itemIndex].gst_tax = null;
    items[itemIndex].item_exemption_type = "";
    items[itemIndex].item_exemption_id = null;
    props.setValues({
      items: items,
      deleteItemGst: deletedItemsId,
    });
  };

  const setAccountDropdown = () => {
    // var leftScroll = 0;
    var scrollItem: any = document.querySelector(".item-det-container");
    let resizeFlag: boolean = false;
    scrollItem &&
      scrollItem.addEventListener("scroll", function (e: any) {
        scrollLeftRef.current = scrollItem.scrollLeft;
      });
    $(".dropdown-toggle").on("show.bs.dropdown", function () {
      // setTimeout(() => {
      $(".items-entry-wrapper").addClass("overflow-initial");
      $(".item-det-container").removeClass("overflow-coloumn");
      $(".item-det-container .item-grid-account").css(
        "margin-left",
        "-" + scrollLeftRef.current + "px"
      );
      // }, 5);
    });
    $(".dropdown-toggle").on("hide.bs.dropdown", function () {
      // setTimeout(() => {
      $(".items-entry-wrapper").removeClass("overflow-initial");
      $(".item-det-container").addClass("overflow-coloumn");
      $(".item-det-container .item-grid-account").css("margin-left", 0);
      // }, 0);
    });

    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".dropdown-toggle") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };
  $(() => {
    setAccountDropdown();
  });

  const subscriptionDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      subscriptionDataForInvoice({
        invoiceId: Number(props.editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (props.is_consolidated) {
          setOverviewSubscriptionDataConsolidated(response);
        } else if (props.values.future_invoice_renewals) {
          setOverviewFutureRenewalData(response);
        } else {
          setOverviewSubscriptionData(response);
        }
      } else {
      }
    }
  };

  useEffect(() => {
    if (props.is_subscription && props.editId) {
      subscriptionDetail();
    }
  }, [props.is_subscription, props.editId]);

  const columnsForPlanAddon = useMemo(() => {
    const columns = [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      // Conditional HSN/SAC Code column
      ...(isGstOrg
        ? [
            {
              Header: "Hsn/sac code",
              accessor: "show.hsnCode",
              className: "hsncode text-nowrap",
            },
          ]
        : []),
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap left-align",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <span>{value.discount}</span>
              <span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <TaxColumn
              values={value}
              currencyCode={props.values.currency_code}
            />
          );
        },
      },
      {
        Header: "TDS",
        accessor:
          props.values.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL
            ? "show.item"
            : "TDS",
        className: "tds text-nowrap",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <span>
                {props.values.currency_code}{" "}
                {NumberFormat(
                  Number(value.tds_total),
                  props.values.currency_code,
                  orgCurrencyList
                )}
              </span>
              <span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </span>
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ];

    return columns;
  }, [props.values.currency_code, isGstOrg]);

  return (
    <>
      <Section className="item-details-wrapper white-space-break-spaces">
        <SectionHeading heading={labels.itemDetails.title}>
          <MultiExchangeRate
            conversionDate={props.values.invoice_date}
            conversionId={props.values.conversion_id}
            conversionRate={props.values.conversion_rate}
            conversionTimestamp={props.values.conversion_timestamp}
            currencyCode={props.values.currency_code}
            currencyId={
              props.values.currency_id
                ? Number(props.values.currency_id)
                : undefined
            }
            toCurrencyId={currentUserInfo.organization_currency}
            toCurrencyCode={currentUserInfo.currency_code}
            position="start"
            isExchangeRateEditable={true}
            isExchangeRateHidden={
              currentUserInfo.organization_currency === props.values.currency_id
                ? true
                : false
            }
            exchangeRateSubmitHandler={handleExchangeRateSubmit}
            maxLength={8}
            byUser={props.values.by_user}
            isDisabled={props.values.is_subscription ? true : false}
          />
        </SectionHeading>
        <Container className="items-entry-outer-wrapper w-100 clear-both overflow-hidden">
          <Container className="items-entry-wrapper cn-items-entry-wrapper w-100 clear-both px-0 overflow-hidden">
            {Array.isArray(props.values.items) &&
              props.values.items.map((item: any, index: number) => {
                return (
                  <>
                    {props.is_subscription ? (
                      props.values.future_invoice_renewals ? (
                        index === 0 ? (
                          <InvoiceFutureRenewalEdit
                            overviewSubscriptionData={overviewFutureRenewalData}
                            columnsForPlanAddon={columnsForPlanAddon}
                            currency_code={props.values.currency_code}
                          />
                        ) : (
                          ""
                        )
                      ) : !props.is_consolidated ? (
                        index === 0 ? (
                          <ItemDetailsSubscription
                            overviewSubscriptionData={overviewSubscriptionData}
                            columnsForPlanAddon={columnsForPlanAddon}
                            currency_code={props.values.currency_code}
                          />
                        ) : (
                          ""
                        )
                      ) : index === 0 ? (
                        <InvoicePreviewBodyConsolidatedInvoiceEdit
                          overviewSubscriptionData={
                            overviewSubscriptionDataConsolidated
                          }
                          columnsForPlanAddon={columnsForPlanAddon}
                          currency_code={props.values.currency_code}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      <ItemDetailsWrapper key={item.uuid}>
                        <LineItemWrapper
                          wrapperClass={`position-relative ${
                            props.values.is_subscription
                              ? "disabled-greyed-out"
                              : ""
                          }`}
                        >
                          {props.values?.items?.length > 1 && (
                            <Button
                              type="button"
                              disabled={
                                !props.values.customer_id ? true : false
                              }
                              onClick={(e: any) => {
                                deleteItemContainer(index);
                              }}
                              wrapperClass="delete-taxable delete-btn delete-container-btn delete-line-item"
                            >
                              <DeleteIcon color={colors.slate} />
                            </Button>
                          )}
                          <Container className="row g-0 flex-nowrap">
                            <FormFieldContainer
                              label={labels.itemDetails.itemSelect}
                              labelClass="mb-8"
                              isRequired={true}
                              wrapperClass="w-380"
                            >
                              <ItemSelect
                                selectedItem={{
                                  id: item["inventory_item_id"] || null,
                                  name: item["item_name"] || "",
                                }}
                                itemIndex={index}
                                error={itemErrorSetter(
                                  "item_name",
                                  props.errors,
                                  props.touched[
                                    "items"
                                  ] as unknown as ObjectType[],
                                  index
                                )}
                                errorClass={`error_items.${index}.item_name`}
                                disabled={
                                  props.values.is_subscription
                                    ? true
                                    : !props.values.customer_id
                                    ? true
                                    : false
                                }
                                greyedOut={""}
                                onItemChange={handleItemDropdownChange}
                                onInputItemChange={handleItemChange}
                                placeholder={
                                  labels.itemDetails.itemSelectPlaceholder
                                }
                                onBlur={(e) => {
                                  props.setFieldTouched &&
                                    props.setFieldTouched(
                                      `items.${index}.item_id`,
                                      true
                                    );
                                }}
                              />
                            </FormFieldContainer>

                            {props.isHsnCode && isGstOrg && (
                              <FormFieldContainer
                                label={labels.itemDetails.hsnCode}
                                labelClass="mb-8"
                                isRequired={true}
                                wrapperClass="w-310"
                              >
                                <Input
                                  name="hsn_or_sac_code"
                                  placeholder={
                                    labels.itemDetails.hsnCodePlaceholder
                                  }
                                  value={item["hsn_or_sac_code"]}
                                  onChange={(e) => {
                                    handleHSNChange(e.target.value, index);
                                  }}
                                  error={itemErrorSetter(
                                    "hsn_or_sac_code",
                                    props.errors,
                                    props.touched[
                                      "items"
                                    ] as unknown as ObjectType[],
                                    index
                                  )}
                                  errorClass={`error_items.${index}.hsn_or_sac_code`}
                                  disabled={
                                    props.values.is_subscription
                                      ? true
                                      : !props.values.customer_id
                                      ? true
                                      : false
                                  }
                                  onBlur={(e) => {
                                    props.setFieldTouched &&
                                      props.setFieldTouched(
                                        `items.${index}.hsn_or_sac_code`,
                                        true
                                      );
                                  }}
                                  className={""}
                                />
                              </FormFieldContainer>
                            )}
                            {props?.values?.settings?.tds?.apply_tds_at !==
                              "Transaction level" &&
                              props?.values?.settings?.tds?.is_enabled &&
                              !isUAEOrg && (
                                <FormFieldContainer
                                  label={"TDS"}
                                  labelClass="mb-8"
                                  isRequired={false}
                                  wrapperClass="w-310"
                                >
                                  <TdsSelect
                                    id="test"
                                    name="tds"
                                    index={index}
                                    tdsIds={[]}
                                    dropdownPosition="bottom"
                                    disabled={
                                      props.values.is_subscription
                                        ? true
                                        : !props.values.customer_id ||
                                          props.values.tcs_id
                                        ? true
                                        : false
                                    }
                                    handleTDSChange={handleTDSChange}
                                    handleTDSAmountChange={
                                      handleTDSAmountChange
                                    }
                                    handleOnBlur={(name, amount, index) => {
                                      handleTDSOnBlur(
                                        name,
                                        amount,
                                        index,
                                        props.values.currency_code
                                      );
                                      props.setFieldTouched &&
                                        props.setFieldTouched(
                                          `items.${index}.tds_id`,
                                          true
                                        );
                                    }}
                                    clearTDS={clearTDS}
                                    item={
                                      item || {
                                        tds_id: 0,
                                        tds_amount: 0,
                                        tds_percentage: 0,
                                        tds_name: "",
                                        tds_total: 0,
                                      }
                                    }
                                    error={itemErrorSetter(
                                      "tds_id",
                                      props.errors,
                                      props.touched[
                                        "items"
                                      ] as unknown as ObjectType[],
                                      index
                                    )}
                                    errorClass={`error_items.${index}.tds`}
                                    currencyCode={props.values.currency_code}
                                  />
                                </FormFieldContainer>
                              )}
                          </Container>
                          <Container className="row g-0 flex-nowrap">
                            <FormFieldContainer
                              label={labels.itemDetails.account}
                              labelClass="mb-8"
                              isRequired={true}
                              wrapperClass="w-200"
                            >
                              <AccountSelect
                                itemAccount={item["account_id"]}
                                itemAccountName={item["account_name"]}
                                organizationId={currentUserInfo.organization_id}
                                isDisabled={
                                  props.values.is_subscription
                                    ? true
                                    : !props.values.customer_id
                                    ? true
                                    : false
                                }
                                onChange={(account) => {
                                  handleItemAccountChange(
                                    account?.id || null,
                                    account?.account_name || "",
                                    index,
                                    account?.account_type || ""
                                  );
                                  props.setFieldTouched &&
                                    props.setFieldTouched(
                                      `items.${index}.account_name`,
                                      true
                                    );
                                }}
                                itemIndex={0}
                                error={itemErrorSetter(
                                  "account_name",
                                  props.errors,
                                  props.touched[
                                    "items"
                                  ] as unknown as ObjectType[],
                                  index
                                )}
                                errorClass={`error_items.${index}.account_name`}
                                baseAccount={"Incomes~Liabilities"}
                                wrapperClass={`w-100 clear-both ${
                                  itemErrorSetter(
                                    "account_name",
                                    props.errors,
                                    props.touched[
                                      "items"
                                    ] as unknown as ObjectType[],
                                    index
                                  )
                                    ? `error_items.${index}.account_name`
                                    : ""
                                }`}
                                className={`account-full-width clear-both`}
                                onBlur={(e) => {}}
                              />
                            </FormFieldContainer>
                            <FormFieldContainer
                              label={labels.itemDetails.quantity}
                              labelClass="mb-8"
                              isRequired={false}
                              wrapperClass="width-100"
                            >
                              <Input
                                placeholder={zeroDisplayFormat(
                                  decimalPlaceOfCurrency(
                                    props.values.currency_code
                                      ? props.values.currency_code
                                      : currentUserInfo.currency_code,
                                    orgCurrencyList
                                  )
                                )}
                                value={item.quantity}
                                name="quantity"
                                disabled={
                                  props.values.is_subscription
                                    ? true
                                    : !props.values.customer_id
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  handleItemChange(e, index);
                                }}
                                error={itemErrorSetter(
                                  "quantity",
                                  props.errors,
                                  props.touched[
                                    "items"
                                  ] as unknown as ObjectType[],
                                  index
                                )}
                                errorClass={`error_items.${index}.quantity`}
                                onBlur={(e) => {
                                  props.setFieldTouched &&
                                    props.setFieldTouched(
                                      `items.${index}.quantity`,
                                      true
                                    );
                                }}
                              />
                            </FormFieldContainer>
                            <FormFieldContainer
                              label={labels.itemDetails.rate}
                              labelClass="mb-8"
                              isRequired={true}
                              wrapperClass="width-100"
                            >
                              <Input
                                placeholder={zeroDisplayFormat(
                                  decimalPlaceOfCurrency(
                                    props.values.currency_code
                                      ? props.values.currency_code
                                      : currentUserInfo.currency_code,
                                    orgCurrencyList
                                  )
                                )}
                                value={item.rate}
                                name="rate"
                                disabled={
                                  props.values.is_subscription
                                    ? true
                                    : !props.values.customer_id
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  handleItemChange(e, index);
                                }}
                                error={itemErrorSetter(
                                  "rate",
                                  props.errors,
                                  props.touched[
                                    "items"
                                  ] as unknown as ObjectType[],
                                  index
                                )}
                                errorClass={`error_items.${index}.rate`}
                                onBlur={(e) => {
                                  props.setFieldTouched &&
                                    props.setFieldTouched(
                                      `items.${index}.rate`,
                                      true
                                    );
                                }}
                              />
                            </FormFieldContainer>
                            <Container className="form-field w-310">
                              <Discount
                                index={index}
                                discount={item["discount"]}
                                discountType={item["discount_type"]}
                                currencyCode={props.values.currency_code}
                                isDisabled={
                                  props.values.is_subscription
                                    ? true
                                    : !props.values.customer_id
                                    ? true
                                    : false
                                }
                                error={itemErrorSetter(
                                  "discount_account_id",
                                  props.errors,
                                  props.touched[
                                    "items"
                                  ] as unknown as ObjectType[],
                                  index
                                )}
                                errorClass={`error_items.${index}.discount_account_id`}
                                discountAccountId={item["discount_account_id"]}
                                discountAccountName={
                                  item["discount_account_name"]
                                }
                                onDiscountValueChange={
                                  handleDiscountValueChange
                                }
                                onDiscountAccountChange={
                                  handleDiscountAccountChange
                                }
                                onDiscountType={handleDiscountTypeChange}
                                onBlur={(amount) => {
                                  handleDiscountOnBlur(Number(amount), index);
                                  props.setFieldTouched &&
                                    props.setFieldTouched(
                                      `items.${index}.discount_account_id`,
                                      true
                                    );
                                }}
                                isDropdownDisabled={
                                  props.values.exclude_discount_accounting
                                }
                              />
                            </Container>
                            <FormFieldContainer
                              label={"TAX"}
                              labelClass="tax-label mb-8"
                              isRequired={false}
                              wrapperClass="w-200"
                              tooltipMessage={
                                "Add the tax label and amounts to be added to the discounted amount"
                              }
                              messagePlacement="top-end"
                            >
                              {isGstOrg ? (
                                <GstSelect
                                  gstOnChange={(gst, item_exemption) =>
                                    handleItemGstChange(
                                      gst,
                                      index,
                                      item_exemption
                                    )
                                  }
                                  itemIndex={index}
                                  organizationId={
                                    currentUserInfo.organization_id
                                  }
                                  item={item}
                                  disabled={
                                    props.values.is_subscription
                                      ? true
                                      : !props.values.customer_id
                                      ? true
                                      : false
                                  }
                                  disabledFeature={[]}
                                  updateIsItemEdited={() => {}}
                                  customerExemptionType={
                                    props.values.customer_details
                                      .customer_exemption_type
                                  }
                                  gst_tax={props.values.items[index].gst_tax}
                                  clearGstSelection={clearGstSelection}
                                />
                              ) : (
                                <TaxSelect
                                  itemIndex={index}
                                  taxes={props.values.items[index].taxes}
                                  taxErrors={
                                    props.errors &&
                                    props.errors[index] &&
                                    props.errors[index]["taxes"]
                                  }
                                  errorClass={`error_items.${index}.taxes`}
                                  organizationId={
                                    currentUserInfo.organization_id
                                  }
                                  onTaxLabelChange={(e, num, taxIndex) => {
                                    const data = {
                                      target: {
                                        name: "tax_id",
                                        value: e,
                                      },
                                    };
                                    handleItemTaxChange(data, index, taxIndex);
                                  }}
                                  onTaxValueChange={handleItemTaxChange}
                                  onItemTaxDelete={deleteItemTaxContainer}
                                  onTaxBlur={() => {}}
                                  addItemTaxContainer={addItemTaxContainer}
                                  disabled={
                                    props.values.is_subscription
                                      ? true
                                      : !props.values.customer_id
                                      ? true
                                      : false
                                  }
                                  disabledFeature={[]}
                                  currencyCode={props.values.currency_code}
                                />
                              )}
                            </FormFieldContainer>
                            <FormFieldContainer
                              label={"Taxable Amount"}
                              labelClass="mb-8"
                              isRequired={false}
                              wrapperClass="item-col tax-amt-col w-140 p-0"
                              tooltipMessage="Total Taxable Amount"
                              messagePlacement="top-start"
                              disabled={
                                props.values.is_subscription
                                  ? true
                                  : !props.values.customer_id
                                  ? true
                                  : false
                              }
                            >
                              <Container className="d-flex taxable-amount align-items-center justify-content-between w-100 py-2 text-nowrap">
                                <Span
                                  className="amount-taxable-nil"
                                  id={"taxable_amount_span_" + index}
                                >
                                  {props.values.currency_code}{" "}
                                  {item.taxable_amount}
                                </Span>
                              </Container>
                            </FormFieldContainer>
                          </Container>
                        </LineItemWrapper>
                      </ItemDetailsWrapper>
                    )}
                  </>
                );
              })}
            {!props.is_subscription && (
              <AddLineItem onClick={addItemContainer} />
            )}
            {props.children}
          </Container>
        </Container>
      </Section>
    </>
  );
};

export default ItemDetails;
