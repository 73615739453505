import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import {
  AccountsGLCodeRanges,
  COA,
  coaAmountCalculation,
} from "../../../types";

/**
 * API call for create COA
 */
export async function createCoa(data: {
  parentId: number;
  value: COA;
  orgId: number;
}) {
  const result = await post(
    Api.chartOfAccount +
      "create?parent_id=" +
      data.parentId +
      `&current_organization=${data.orgId}`,
    data.value
  );
  return result;
}
/**
 * API call for update COA
 */
export async function updateCoa(data: {
  accountId: number;
  value: COA;
  orgId: number;
}) {
  const result = await put(
    Api.chartOfAccount +
      "edit/" +
      data.accountId +
      `?current_organization=${data.orgId}`,
    data.value
  );
  return result;
}
/**
 * Fetch details of single account
 */
export async function fetchCoaById(data: { accountId: number; orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      "list/" +
      data.accountId +
      `?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Delete a single account
 */
export async function deleteCoa(data: { accountId: number; orgId: number }) {
  const result = await remove(
    Api.chartOfAccount +
      "delete/" +
      data.accountId +
      `?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for List view of Chart of Accounts
 */
export async function listViewOfCoa(data: {
  page: number;
  itemsPerPage: number;
  accountName: string;
  nameSort: string;
  typeSort: string;
  balanceSort: string;
  orgId: number;
}) {
  let result;
  if (data.nameSort === "" && data.typeSort === "" && data.balanceSort === "") {
    result = await get(
      Api.chartOfAccount +
        "view/list" +
        `?page_num=${data.page}&page_size=${data.itemsPerPage}&account_name=${data.accountName}&current_organization=${data.orgId}`
    );
  } else if (data.nameSort === "" && data.balanceSort === "") {
    result = await get(
      Api.chartOfAccount +
        "view/list" +
        `?page_num=${data.page}&page_size=${data.itemsPerPage}&account_name=${data.accountName}&account_type_order=${data.typeSort}&current_organization=${data.orgId}`
    );
  } else if (data.typeSort === "" && data.balanceSort === "") {
    result = await get(
      Api.chartOfAccount +
        "view/list" +
        `?page_num=${data.page}&page_size=${data.itemsPerPage}&account_name=${data.accountName}&account_name_order=${data.nameSort}&current_organization=${data.orgId}`
    );
  } else {
    result = await get(
      Api.chartOfAccount +
        "view/list" +
        `?page_num=${data.page}&page_size=${data.itemsPerPage}&account_name=${data.accountName}&account_balance_order=${data.balanceSort}&current_organization=${data.orgId}`
    );
  }
  return result;
}

/**
 * API call for Tree view of Chart of Accounts
 */
export async function treeViewOfCoa(data: { name: string; orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      "view/tree" +
      `?account_name=${data.name}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for Tree view of Chart of Accounts to display in Add account modal
 */
export async function treeViewInAddAccount(orgId: number) {
  const result = await get(
    Api.chartOfAccount + "account-modal/tree" + `?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting Accounts search suggestions
 */
export async function getSearchSuggestions(data: {
  accountName: string;
  orgId: number;
}) {
  const result = await get(
    Api.chartOfAccount +
      "view/suggestion-list" +
      `?account_name=${data.accountName}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting Account type
 */
export async function getAccountType(data: {
  accountId: number;
  orgId: number;
}) {
  const result = await get(
    Api.chartOfAccount +
      "view/account-type" +
      `?account_id=${data.accountId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting Accounts GL ranges
 */
export async function getAccountGlRanges(orgId: number) {
  const result = await get(
    Api.chartOfAccount + "view/base" + `?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting Accounts GL ranges
 */
export async function editAccountGlRanges(data: {
  accounts: AccountsGLCodeRanges[];
  orgId: number;
}) {
  const result = await post(
    Api.chartOfAccount + `edit/base?current_organization=${data.orgId}`,
    { accounts: data.accounts }
  );
  return result;
}

/**
 * API call for validating GL code ranges
 */
export async function glCodeValidate(data: {
  glCodeRange: AccountsGLCodeRanges[];
  orgId: number;
}) {
  const result = await post(
    Api.chartOfAccount + `gl_code/validate?current_organization=${data.orgId}`,
    { accounts: data.glCodeRange }
  );
  return result;
}

/**
 * API call for getting Accounts under Parent accounts
 */
export async function getSubAccounts(data: {
  parentAccountName: string[];
  orgId: number;
  transactionType?: string;
}) {
  let result;
  let url =
    Api.chartOfAccount +
    `sub-account/dropdown-list?current_organization=${data.orgId}`;
  const baseAccountUrl = data.parentAccountName
    .map((account) => `&base_account=${encodeURIComponent(account)}`)
    .join("");
  const transactionTypeUrl = data.transactionType
    ? `&transaction_type=${data.transactionType}`
    : "";
  url += baseAccountUrl + transactionTypeUrl;
  result = await get(url);
  return result;
}

/**
 * API call for getting Accounts with gl code under Parent accounts
 */
export async function getAccountsGLCode(data: {
  accountSearchTerm: string;
  baseAccount: string | null;
  orgId: number;
  signal?: AbortSignal;
}) {
  let result;
  if (data.baseAccount) {
    if (data.baseAccount.includes("~")) {
      let baseAccounts = data.baseAccount.split("~");
      if (data.baseAccount.length !== 0) {
        let url =
          Api.chartOfAccount +
          `view/dropdown-list?account_search=${data.accountSearchTerm}`;

        const baseAccountsUrl = baseAccounts
          .map((account) => {
            return `&base_account=${account}`;
          })
          .join("");
        url += baseAccountsUrl;
        url += `&current_organization=${data.orgId}`;
        result = await get(url, data.signal);
      }
    } else {
      result = await get(
        Api.chartOfAccount +
          `view/dropdown-list?account_search=${data.accountSearchTerm}&base_account=${data.baseAccount}&current_organization=${data.orgId}`,
        data.signal
      );
    }
  } else {
    result = await get(
      Api.chartOfAccount +
        `view/dropdown-list?account_search=${data.accountSearchTerm}&current_organization=${data.orgId}`,
      data.signal
    );
  }
  return result;
}

/**
 * API call for getting Bank accounts with given currency code
 */
export async function getBankAccounts(data: {
  currencyId: number;
  orgId: number;
  transaction_source: string;
}) {
  const result = await get(
    Api.chartOfAccount +
      `currency-bankaccount/dropdown-list?currency_id=${data.currencyId}&current_organization=${data.orgId}&transaction_source=${data.transaction_source}`
  );
  return result;
}

/**
 * Temporary API call for getting Bank accounts for Payments with given currency code
 */
export async function getPaymentBankAccounts(data: {
  currencyId: number;
  orgId: number;
  transaction_source: string;
}) {
  const result = await get(
    Api.chartOfAccount +
      `payment-bankaccount/dropdown-list?currency_id=${data.currencyId}&current_organization=${data.orgId}&transaction_source=${data.transaction_source}`
  );
  return result;
}

/**
 * API call for checking if account name is unique
 */
export async function accountNameExists(data: {
  accountName: string;
  editAccountId: number;
  orgId: number;
  signal: AbortSignal | undefined;
}) {
  const result = await get(
    Api.chartOfAccount +
      `account-exist?account_name=${data.accountName}&edit_account_id=${data.editAccountId}&current_organization=${data.orgId}`,
    data.signal
  );
  return result;
}

/**
 * API call to get Income Expense total for dashboard
 */
export async function incomeExpenseTotal(data: {
  currencyId: number;
  startDate: string;
  endDate: string;
  orgId: number;
}) {
  const result = await get(
    Api.chartOfAccount +
      `dashboard/income-expense-total?currency_id=${data.currencyId}&start_date=${data.startDate}&end_date=${data.endDate}&organization_id=${data.orgId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to get Transaction Journals
 */
export async function TransactionJournals(data: {
  transactionType: string;
  transactionId: number;
  orgId: number;
  isCashPosting: boolean;
}) {
  const result = await get(
    Api.chartOfAccount +
      `journal/generate?transaction_type=${data.transactionType}&transaction_id=${data.transactionId}&is_cash_posting=${data.isCashPosting}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to get Audit Logs
 */
export async function AuditLogs(data: {
  transactionType: string;
  transactionId: number;
  orgId: number;
  pageToken?: string | null;
}) {
  const result = await get(
    Api.auditLog +
      `get_changelog?transaction_type=${data.transactionType}&transaction_id=${data.transactionId}&current_organization=${data.orgId}` +
      (data.pageToken ? `&prev_page_token=${data.pageToken}` : "")
  );
  return result;
}

/**
 * API call to get Transaction status
 */
export async function TransactionStatus(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount + `transaction-status?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to check transaction status of each account
 */
export async function TransactionStatusOfAccount(data: {
  transactionId: number;
  orgId: number;
  transaction: string;
}) {
  const result = await get(
    Api.chartOfAccount +
      `transaction-status?current_organization=${data.orgId}&${data.transaction}=${data.transactionId}`
  );
  return result;
}

/**
 * API call to get amount calculation for opening balance
 */
export async function OpeningBalanceCalculation(data: {
  orgId: number;
  values: coaAmountCalculation;
  signal?: Object;
}) {
  const result = await post(
    Api.chartOfAccount +
      `opening-balance/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );

  return result;
}

/**
 * API call to get accounts based on filter for report customization
 */
export async function ReportCustomizationAccountFetch(data: {
  orgId: number;
  signal?: AbortSignal;
  field: string;
}) {
  const result = await get(
    Api.chartOfAccount +
      `account-transaction-filter?current_organization=${data.orgId}&field=${data.field}`,
    data.signal
  );
  return result;
}
/**
 * API call to check gl-code exist
 */
export async function isGLCodeExist(data: {
  glCode: number | string;
  accountId?: number | null;
  orgId: number;
  signal: AbortSignal;
}) {
  const result = await get(
    Api.chartOfAccount +
      `glcode-status?account_id=${data.accountId}&current_organization=${data.orgId}&gl_start_code=${data.glCode}`,
    data.signal
  );
  return result;
}

/**
 * API call to move ledgers
 */
export async function updateHeadOfAccount(data: {
  values: { existing_head_id: number; new_head_id: number };
  account_id: number;
  orgId: number;
}) {
  const result = await put(
    Api.chartOfAccount +
      `update-head?account_id=${data.account_id}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
