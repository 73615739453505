import { useMemo, useState } from "react";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { coustomCase } from "../../../../../helpers/planHelper";
import { ObjectType } from "../../../../../types";
import CenterModal from "../../../../common/components/centerModal/CenterModal";
import DetailsTableContainerSubscription from "../../../../common/components/detailsPage/DetailsTableContainerSubscription";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import { Container } from "../../../../common/components/htmlTags/Container";
import { Span } from "../../../../common/components/htmlTags/Span";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import { getPreviewSubscriptionInvoice } from "../../subscriptionSlice";
import { modifyResponse } from "../helpers/mergeItemsWithSameId";
import "./SubscriptionInvoiceBreakdown.css";
import { SubscriptionInvoiceBreakdownSkeletonLoader } from "./SubscriptionInvoiceBreakdownSkeletonLoader";
import SubscriptionInvoiceBreakdownTotalSection from "./SubscriptionInvoiceBreakdownTotalSection";
import { useParams } from "react-router-dom";

type Props = {
  invoiceIndex: "first" | "next";
  currencyCode: string;
  taxes: ObjectType;
  tdsLevel: string;
  payload: any;
  subscriptionId?: number;
  isPlanOnly?: boolean;
};
export const SubscriptionInvoiceBreakdown = (props: Props) => {
  const { editId } = useParams();
  const { currentUserInfo, orgCurrencyList, dispatch } = useCommonData();
  const [invoiceBreakdown, setInvoiceBreakdown] = useState<any>({});

  const [planItems, setPlanItems] = useState<{
    planName: string;
    customRepeatCount: number;
    frequency: string;
    pricingModel: string;
    invoicingPeriod: string;
    description: string;
    items: ObjectType[];
  }>({
    planName: "",
    customRepeatCount: 0,
    frequency: "0",
    pricingModel: "",
    invoicingPeriod: "",
    description: "",
    items: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const { isGstOrg } = useGst();
  function convertToNormalText(str: string) {
    if (!str) return "";
    let words = str.toLowerCase().split("_");
    return words.join(" ");
  }

  function formatFrequency(frequency: string) {
    if (!frequency) return "";
    let formattedStr = frequency
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return formattedStr;
  }

  function convertToTimeUnit(unit: string): string {
    switch (unit) {
      case "DAILY":
        return "days";
      case "WEEKLY":
        return "weeks";
      case "MONTHLY":
        return "months";
      case "YEARLY":
        return "years";
      default:
        return unit;
    }
  }

  /**
   * set table column headings for plan and addon
   */

  const columnsForPlanAddon = useMemo(() => {
    const columns = [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <Span className="item-bold">{value}</Span>;
        },
      },
      // Conditional HSN/SAC Code column
      ...(isGstOrg
        ? [
            {
              Header: "Hsn/sac code",
              accessor: "show.hsnCode",
              className: "hsncode text-nowrap",
            },
          ]
        : []),
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap left-align",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <Span>{value.discount}</Span>
              <Span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </Span>
            </>
          );
        },
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-align-left tds-column-min-wdith",
        Cell: ({ cell: { value } }: any) => {
          return <TaxColumn values={value} currencyCode={props.currencyCode} />;
        },
      },
      {
        Header: "TDS",
        accessor: props.tdsLevel === "Line item level" ? "show.item" : "TDS",
        className: "tds tds-column-min-wdith",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <Span>
                {props.currencyCode}{" "}
                {NumberFormat(
                  Number(value.tds_total),
                  props.currencyCode,
                  orgCurrencyList
                )}
              </Span>
              <Span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </Span>
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <Span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </Span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ];

    return columns;
  }, [props.currencyCode, isGstOrg]);
  const getSubscriptionInvoicePreviewData = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      getPreviewSubscriptionInvoice({
        orgId: currentUserInfo.organization_id,
        values: { ...props.payload },
        subscriptionId: props.subscriptionId,
      })
    );
    const tempResponse = responseAction.payload;
    if (!("error" in tempResponse)) {
      const response = modifyResponse(tempResponse);
      setIsLoading(false);
      setInvoiceBreakdown(
        props.invoiceIndex === "first"
          ? response["first_invoice"]
          : response["second_invoice"]
      );
      let invoice =
        props.invoiceIndex === "first"
          ? response["first_invoice"]
          : response["second_invoice"];
      let plans = invoice.plan_details.flatMap((plan: any) => plan.items);
      setPlanItems({
        items: plans,
        planName: invoice.plan_details[0].plan_name,
        customRepeatCount: invoice.plan_details[0].custom_repeat_count,
        frequency: invoice.plan_details[0].frequency,
        pricingModel: invoice.plan_details[0].pricing_model,
        invoicingPeriod: invoice.invoice_info.invoicing_period,
        description: invoice.plan_details[0].description,
      });
    } else {
      setIsLoading(false);
    }
  };
  return (
    <>
      <CenterModal
        modalTitle={`Breakdown of ${
          editId ? "next" : props.invoiceIndex
        } invoice`}
        onOpenFunction={async () => {
          await getSubscriptionInvoicePreviewData();
        }}
        isCloseButtonEnabled={true}
        buttonContent={
          <Span className="subscription-invoice-preview-modal-btn">
            See Breakdown
          </Span>
        }
        buttonClass="blue-text-btn no-appearance"
        modalContent={
          <Container className="subscription-invoice-preview-body-wrapper">
            {isLoading ? (
              <SubscriptionInvoiceBreakdownSkeletonLoader
                itemCount={props.isPlanOnly ? 1 : 2}
              />
            ) : (
              <Container className="table-section">
                <Container className="table-wrap subscription-invoice-details">
                  {invoiceBreakdown.plan_details &&
                    invoiceBreakdown.plan_details.length > 0 && (
                      <>
                        <Container>
                          <Container className="subscription-items-name-wrappper">
                            Plans
                          </Container>
                        </Container>

                        <Container className="plan-description subscription-items-description-wrappper">
                          <h6 className="item-bold">{planItems.planName}</h6>
                          <Container className="plan-tag">
                            <Span>
                              Frequency:{" "}
                              {planItems.customRepeatCount &&
                              planItems.customRepeatCount > 0
                                ? ` Every ${
                                    planItems.customRepeatCount
                                  }  ${convertToTimeUnit(planItems.frequency)}`
                                : coustomCase(planItems.frequency)}{" "}
                              | Pricing model:{" "}
                              {formatFrequency(planItems.pricingModel)}
                            </Span>
                          </Container>
                          <Container>
                            <Span>
                              Invoicing period: {planItems.invoicingPeriod}
                            </Span>
                          </Container>
                          {!!planItems.description ? (
                            <Container>
                              <Span>Description: {planItems.description}</Span>
                            </Container>
                          ) : null}
                        </Container>

                        <>
                          <Container className="subscription-items-description-table-wrappper">
                            <DetailsTableContainerSubscription
                              columns={columnsForPlanAddon}
                              data={planItems.items}
                              currency_code={props.currencyCode}
                              haveColumnClassName={true}
                              className="table inv-preview-table subscription-data-view-table table-with-border td-with-word-break "
                              hiddenColumns={["TDS"]}
                              pricingModel={planItems.pricingModel}
                              component="invoice"
                            />
                          </Container>
                        </>
                      </>
                    )}
                  {invoiceBreakdown.addon_details &&
                    invoiceBreakdown.addon_details.length > 0 && (
                      <Container className="subscription-items-name-wrappper">
                        Addons
                      </Container>
                    )}
                  {invoiceBreakdown.addon_details &&
                    invoiceBreakdown.addon_details.length > 0 &&
                    invoiceBreakdown?.addon_details?.map((addon: any) => {
                      return (
                        <>
                          <Container className="subscription-items-description-table-wrappper ">
                            <Container className="plan-description subscription-items-description-wrappper">
                              <h6 className="item-bold">{addon.addon_name}</h6>

                              <Container className="plan-tag">
                                <Span>
                                  Frequency:{" "}
                                  {addon.custom_repeat_count &&
                                  addon.custom_repeat_count > 0
                                    ? ` Every ${
                                        addon.custom_repeat_count
                                      }  ${convertToTimeUnit(addon.frequency)}`
                                    : coustomCase(addon.frequency)}{" "}
                                  | Pricing model:{" "}
                                  {formatFrequency(addon.pricing_model)}
                                </Span>
                              </Container>
                              <Container>
                                <Span>
                                  Invoicing period:{" "}
                                  {
                                    invoiceBreakdown?.invoice_info
                                      ?.invoicing_period
                                  }
                                </Span>
                              </Container>
                              {!!addon.description ? (
                                <Container>
                                  <Span>Description: {addon.description}</Span>
                                </Container>
                              ) : null}
                            </Container>

                            <DetailsTableContainerSubscription
                              columns={columnsForPlanAddon}
                              data={addon.items}
                              currency_code={props.currencyCode}
                              haveColumnClassName={true}
                              className="table inv-preview-table subscription-data-view-table table-with-border td-with-word-break "
                              hiddenColumns={["TDS"]}
                              pricingModel={addon.pricing_model}
                              component="invoice"
                            />
                          </Container>
                        </>
                      );
                    })}
                  {invoiceBreakdown.charge_details &&
                    invoiceBreakdown.charge_details.length > 0 && (
                      <Container className="subscription-items-name-wrappper">
                        Charges
                      </Container>
                    )}
                  {invoiceBreakdown.charge_details &&
                    invoiceBreakdown.charge_details.length > 0 &&
                    invoiceBreakdown?.charge_details?.map((charge: any) => {
                      return (
                        <>
                          {" "}
                          <Container className="subscription-items-description-table-wrappper">
                            <Container className="plan-description subscription-items-description-wrappper">
                              <h6 className="item-bold">
                                {charge.charge_name}
                              </h6>

                              <Container className="plan-tag">
                                <Span>
                                  Pricing model:{" "}
                                  {formatFrequency(charge.pricing_model)}
                                </Span>
                              </Container>
                              <Container>
                                <Span>
                                  Invoicing period:{" "}
                                  {
                                    invoiceBreakdown?.invoice_info
                                      ?.invoicing_period
                                  }
                                </Span>
                              </Container>
                              {!!charge.description ? (
                                <Container>
                                  <Span>Description: {charge.description}</Span>
                                </Container>
                              ) : null}
                            </Container>

                            <DetailsTableContainerSubscription
                              columns={columnsForPlanAddon}
                              data={charge.items}
                              currency_code={props.currencyCode}
                              haveColumnClassName={true}
                              className="table inv-preview-table subscription-data-view-table table-with-border td-with-word-break "
                              hiddenColumns={["TDS"]}
                              pricingModel={charge.pricing_model}
                              component="invoice"
                            />
                          </Container>
                        </>
                      );
                    })}
                  {invoiceBreakdown.coupon_details &&
                    invoiceBreakdown.coupon_details.length > 0 && (
                      <Container className="subscription-items-name-wrappper">
                        Coupons
                      </Container>
                    )}
                  {invoiceBreakdown?.coupon_details &&
                    invoiceBreakdown?.coupon_details.length > 0 &&
                    invoiceBreakdown?.coupon_details?.map((coupon: any) => {
                      return (
                        <>
                          <Container className="plan-description subscription-items-coupon-description-container ">
                            <Container>
                              <h6 className="item-bold">{coupon.name}</h6>
                              <Container className="plan-tag">
                                <Span>
                                  Applicable{" "}
                                  {convertToNormalText(coupon.duration_type)}
                                </Span>
                              </Container>
                            </Container>
                            <Container className="coupon-discount-amount-container">
                              {coupon.discount_type === "ABSOLUTE" &&
                                `${props.currencyCode} `}
                              {coupon.discount_value}
                              {coupon.discount_type === "PERCENTAGE" && "%"}
                            </Container>
                          </Container>
                        </>
                      );
                    })}
                  <Container className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
                    <Container className="thankyou-note w-50 d-flex align-items-end">
                      <Container className="note-container">
                        <></>
                      </Container>
                    </Container>

                    <SubscriptionInvoiceBreakdownTotalSection
                      className="subscription-preview-total-section"
                      currencyCode={props.currencyCode}
                      totalTax={invoiceBreakdown?.total_details?.tax_amount}
                      paymentLabel="Payment received"
                      payment={invoiceBreakdown?.total_details?.payment_made}
                      taxTypes={Object.entries(
                        invoiceBreakdown?.total_details?.taxes || {}
                      )}
                      subTotal={
                        invoiceBreakdown?.total_details?.total_taxable_amount
                      }
                      total={invoiceBreakdown?.total_details?.total}
                      balanceLabel={"Balance Due"}
                      balanceAmount={
                        invoiceBreakdown?.total_details?.balance_due
                          ? invoiceBreakdown?.total_details?.balance_due
                          : invoiceBreakdown?.total_details?.total
                      }
                      tdsItems={{
                        tdsAmount: invoiceBreakdown?.total_details?.tds_total
                          ? invoiceBreakdown?.total_details?.tds_total
                          : 0,
                        tdsName: invoiceBreakdown?.total_details?.tds_name,
                        tdsPercentage:
                          invoiceBreakdown?.total_details?.tds_percentage,
                        defaultTdsPercentage:
                          invoiceBreakdown?.total_details
                            ?.tds_default_percentage,
                      }}
                      tcsItems={{
                        tcsAmount: invoiceBreakdown?.total_details?.tcs_total,
                        tcsName: invoiceBreakdown?.total_details?.tcs_name,
                        tcsPercentage:
                          invoiceBreakdown?.total_details?.tcs_percentage,
                        defaultTcsPercentage:
                          invoiceBreakdown?.total_details
                            ?.tcs_default_percentage,
                      }}
                      roundOffValue={
                        invoiceBreakdown?.total_details?.roundoff_amount
                      }
                      roundoffEnabled={invoiceBreakdown?.roundoff_enabled}
                      couponRequired={false}
                      coupon_amount={
                        invoiceBreakdown?.total_details?.coupon_amount
                      }
                    >
                      <></>
                    </SubscriptionInvoiceBreakdownTotalSection>
                  </Container>
                </Container>
              </Container>
            )}
          </Container>
        }
      />
    </>
  );
};
