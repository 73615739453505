interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children: React.ReactNode;
  ariaLabelledBy: string;
}
export const Heading = ({
  variant,
  children,
  ariaLabelledBy,
  ...rest
}: HeadingProps) => {
  switch (variant) {
    case "h1":
      return (
        <h1 {...rest} aria-labelledby={ariaLabelledBy}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 {...rest} aria-labelledby={ariaLabelledBy}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 {...rest} aria-labelledby={ariaLabelledBy}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 {...rest} aria-labelledby={ariaLabelledBy}>
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5 {...rest} aria-labelledby={ariaLabelledBy}>
          {children}
        </h5>
      );
    case "h6":
      return (
        <h6 {...rest} aria-labelledby={ariaLabelledBy}>
          {children}
        </h6>
      );
    default:
      return (
        <h5 {...rest} aria-labelledby={ariaLabelledBy}>
          {children}
        </h5>
      );
  }
};
