import React from "react";
import { AddTagIcon } from "../../../assets/images";

function AddLineItem({ onClick, isDisabled }: { onClick: () => void, isDisabled?: boolean }) {
  return (
    <div className="w-100 add-line-button-wrapper">
      <div className="w-100 add-line d-flex align-items-center">
        <a
          href="#"
          role="button"
          className={`add-tags-button add-line-button d-flex align-items-center ${isDisabled ? "disabled" : ""}`}
          onClick={(e) => e.preventDefault()}
        >
          <img src={AddTagIcon} alt="" />
          <span className="px-2" onClick={() => isDisabled ? null : onClick()}>
            Add another line
          </span>
        </a>
      </div>
    </div>
  );
}

export default React.memo(AddLineItem);
