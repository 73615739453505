import React from "react";
import ReactPaginate from "react-paginate";
import "./PaginationFooterInfo.css";
import { NextIcon } from "../../svgIcons/nextIcon/NextIcon";
import { PreviousIcon } from "../../svgIcons/previousIcon/PreviousIcon";
import { Container } from "../../htmlTags/container/Container";

type Props = {
  pageCount: number;
  page: number;
  handlePageClick: (e: any) => void;
};
export default function Pagination({
  pageCount,
  page,
  handlePageClick,
}: Props) {
  return (
    <Container className="pagination-wrapper w-100">
      <ReactPaginate
        breakLabel="..."
        nextLabel={<NextIcon />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={<PreviousIcon />}
        activeClassName={"pagination-active"}
        forcePage={page - 1}
        disabledClassName={"pagination__link--disabled"}
      />
    </Container>
  );
}
