import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Select, { FormatOptionLabelMeta } from "react-select";
import {
  CustomerDropdownList,
  FormSelectOption,
  ObjectType,
  SoaFilters,
  VendorDropdownList,
} from "../../../../../types";
import CustomSearchSelect from "../../../../common/components/CustomSearchSelect/CustomSearchSelect";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import "./ReportFilter.css";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  customerDropdownListForSoa,
  customerSoaListSelector,
} from "../../../customerManagement/customerSlice";
import {
  currenciesSelector,
  currencyDropdown,
  currentUserSelector,
  getOrganizationCurrencies,
} from "../../../../common/commonSlice";
import {
  vendorDropdownListForSoa,
  vendorSoaListSelector,
} from "../../../vendorManagement/vendorSlice";
import { FormControlLabel, Radio, ToggleButton } from "@mui/material";
import RadioButton from "../../../../common/components/RadioButton";
import PredefinedDateRangePicker from "../../componets/PredefinedDateRange";
import { fetchAccountsGlCode } from "../../../chartOfAccounts/coaSlice";
import { getFormatedDate } from "../../../../../helpers/helper";
import OrganizationDropDown from "../../componets/OrganizationDropDown";
import {
  dateRangeSelector,
  fetchTdsCustomers,
  fetchTdsVendors,
  reportTableFilterSelector,
  setDateRange,
  setLoader,
  setReportCustomizationFlag,
  setReportTableFilters,
} from "../../ReportSlice";
import { colors } from "../../../../constants/colors";
import { getFiscalQuarterYear } from "../../../../../helpers/fiscalYearHelper";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTooltips from "../../../../common/components/Tooltip";
import ReportTypeToggleButton from "../../ReportTypeToggleButton/ReportTypeToggleButton";
import { Container } from "../../../../common/components/htmlTags/Container";
import FormSelect from "../../../../common/components/ui/formElements/FormSelect/FormSelect";
import IntegratedReportSelect from "../../componets/integeratedReportDropDown/IntegeratedReportDropDown";
import CustomerSelectWithOrgFilter from "../../../../common/components/CustomerSelect/CustomerSelectWithOrgFilter";
import VendorSelectWithOrgFilter from "../../../../common/components/VendorSelect/VedorSelectWithOrgFilter";

type Props = {
  handleReport: () => void;
  clearReport: () => void;
  reportType: string;
  // habdleReportType?: (e:any) => void;
  enable_toggleReportType?: boolean;
};
const ReportFilter = (props: Props, ref: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const customerDropdown = useAppSelector(customerSoaListSelector);
  const currencyLists = useAppSelector(currenciesSelector);
  const vendorDropdown = useAppSelector(vendorSoaListSelector);
  const dateRange = useAppSelector(dateRangeSelector);
  const quarterStartDate = getFiscalQuarterYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "Current"
  ).startDate;
  const quarterEndDate = getFiscalQuarterYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "Current"
  ).endDate;
  const hashFragment = location.hash;
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  const filters = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

  const initialFormValues: SoaFilters = {
    statement_type: filters ? filters.statement_type : "Customer",
    contact_or_account_id: filters ? filters.contact_or_account_id : "",
    currency_id: filters ? filters.currency_id : null,
    date_range: filters ? filters.date_range : "this_quarter",
    start_date: filters ? filters.start_date : quarterStartDate,
    end_date: filters ? filters.end_date : quarterStartDate,
    is_sundry: filters ? filters.is_sundry : false,
    is_integrated: filters ? filters.is_integrated : true,
    organization_id: filters
      ? filters.organization_id
      : currentUserInfo.organization_id
      ? currentUserInfo.organization_id
      : null,
    entity_id: filters ? filters.entity_id : null,
    entity_type: filters ? filters.entity_type : null,
    is_tds: filters ? filters.is_tds : false,
    sundry_type: filters && filters.is_sundry ? filters.sundry_type : null,
  };
  let resetFormValues: SoaFilters = {
    statement_type: "Customer",
    contact_or_account_id: "",
    currency_id: null,
    date_range: "this_quarter",
    start_date: quarterStartDate,
    end_date: quarterStartDate,
    is_sundry: false,
    is_integrated: true,
    organization_id: currentUserInfo.organization_id
      ? currentUserInfo.organization_id
      : null,
    entity_id: null,
    entity_type: null,
    is_tds: false,
    sundry_type: null,
  };

  const [customerList, setCustomerList] = useState<CustomerDropdownList[]>([]);
  const [vendorsList, setVendorsList] = useState<VendorDropdownList[]>([]);
  let [formValues, setFormValues] = useState<SoaFilters>(initialFormValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [isCustom, setIsCustom] = useState(false);
  const [accountList, setAccountList] = React.useState<ObjectType[]>([]);
  const [currencySelect, setCurrencySelect] = useState(true);
  const [readOnlyCurrency, setReadOnlyCurrency] = useState(false);
  const [currencyIds, setCurrencyIds] = useState<number[]>([]);
  const [accountName, setAccountName] = useState("");
  const [orgCurrency, setOrgCurrency] = useState("");
  const [orgFilterCurrencies, setOrgFilterCurrencies] = useState<number[]>([]);
  const [resetType, setResetType] = useState("Customer");
  const [orgDisable, setOrgDisable] = useState(false);
  const tableFilterValues = useAppSelector(reportTableFilterSelector);
  const [initialLoad, setInitialLoad] = useState(true);
  const [customerSelection, setCustomerSelection] = useState<{
    label: string;
    value: number | string;
  }>({ label: "", value: "" });
  const [vendorSelection, setVendorSelection] = useState<{
    label: string;
    value: number | string;
  }>({ label: "", value: "" });
  useEffect(() => {
    if (filters) applyFilter();
  }, []);
  const [entityCustomerSelection, setEntityCustomerSelection] = useState<{
    label: string;
    value: number | string;
  }>({ label: "", value: 0 });
  useEffect(() => {
    if (filters) applyFilter();
  }, []);

  useEffect(() => {
    const filters = decodedFilterString
      ? JSON.parse(decodedFilterString)
      : undefined;
    dispatch(
      setReportTableFilters({
        ...tableFilterValues,
        date_range: filters ? filters.date_range : "this_quarter",
        start_date: filters ? filters.start_date : quarterStartDate,
        end_date: filters ? filters.end_date : quarterEndDate,
        account_id: filters ? filters.account_id : 0,
        statement_type: filters ? filters.statement_type : "",
        contact_or_account_id: filters ? filters.contact_or_account_id : "",
        currency_id: filters
          ? filters.currency_id
          : currentUserInfo.organization_currency,
        is_sundry: filters ? filters.is_sundry : false,
        organization_id: filters
          ? filters.organization_id
          : currentUserInfo.organization_id,
        sundry_type: filters && filters.is_sundry ? filters.sundry_type : null,
        is_integrated: filters ? filters.is_integrated : true,
      })
    );
    dispatch(
      setDateRange({
        date_range: filters ? filters.date_range : "this_quarter",
        start_date: filters ? filters.start_date : quarterStartDate,
        end_date: filters ? filters.end_date : quarterEndDate,
      })
    );
    dispatch(setReportCustomizationFlag(true));
    if (filters && currentUserInfo.organization_id !== filters.organization_id)
      navigate(`?`);
  }, [currentUserInfo.organization_id, formValues.statement_type]);

  useEffect(() => {
    // resetRedux();
    if (currentUserInfo.organization_currency !== 0) {
      formValues["currency_id"] = filters
        ? filters.currency_id
        : currentUserInfo.organization_currency;
      formValues["organization_id"] = currentUserInfo.organization_id;
    }
    fetchCurrency(currentUserInfo.organization_id);
    // clearFilter("orgSwitch");
    getFormListData();
  }, [currentUserInfo.organization_currency, currentUserInfo.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [isCustom]);

  useEffect(() => {
    let currencyArray = [];
    currencyArray.push(...orgFilterCurrencies);
    if (accountName === "BankAccount") {
      const selectedAccount = accountList.filter(
        (data) => data.id === formValues.contact_or_account_id
      )[0];
      let bankCurrency = selectedAccount.currency_id;
      currencyArray.push(bankCurrency);
    }
    setCurrencyIds([...currencyArray]);
  }, [orgFilterCurrencies, accountName]);

  useEffect(() => {
    if (accountName === "TDSCustomers") {
      fetchTDSCustomers();
    }
    if (accountName === "TDSVendors") {
      fetchTDSVendors();
    }
  }, [accountName, formValues.organization_id]);

  useImperativeHandle(
    ref,
    () => ({
      account: accountName,
      formValues: formValues,
    }),
    [formValues, accountName]
  );

  useEffect(() => {
    setCustomerList([...customerDropdown]);
  }, [customerDropdown]);
  useEffect(() => {
    setVendorsList([...vendorDropdown]);
  }, [vendorDropdown]);

  useEffect(() => {
    if (
      ((filters?.organization_id !== formValues["organization_id"] &&
        initialLoad) ||
        (!initialLoad && filters) ||
        !filters) &&
      (formValues.statement_type === "Customer" ||
        formValues.statement_type === "Vendor")
    ) {
      setFormErrors({});
      props.clearReport();
      formValues.contact_or_account_id = "";
      formValues.currency_id = null;
      setFormValues({ ...formValues });
      // getFormListData();
      customerAndVendorList();
      setInitialLoad(false);
    }
  }, [formValues["organization_id"]]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      start_date: dateRange.start_date,
      end_date: dateRange.end_date,
      date_range: dateRange.date_range,
    });
  }, [dateRange]);

  const resetRedux = async () => {
    dispatch(
      setDateRange({
        start_date: "",
        end_date: "",
        date_range: "",
      })
    );
  };

  const getFormListData = useCallback(async () => {
    dispatch(setLoader(true));

    await dispatch(currencyDropdown(currentUserInfo.organization_id));

    const responseAction = await dispatch(
      fetchAccountsGlCode({
        accountSearchTerm: "",
        baseAccount: "",
        orgId: currentUserInfo.organization_id,
      })
    );
    dispatch(setLoader(false));
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setAccountList(response.account_list);
        return response.account_list;
      }
    }
  }, [currentUserInfo.organization_id]);

  const customerAndVendorList = async () => {
    await dispatch(
      customerDropdownListForSoa({
        orgId: formValues["organization_id"]
          ? Number(formValues["organization_id"])
          : currentUserInfo.organization_id,
        currentOrgId: currentUserInfo.organization_id,
        pageNum: 1,
        pageSize: 500,
        customerId: null,
      })
    );
    await dispatch(
      vendorDropdownListForSoa({
        orgId: formValues["organization_id"]
          ? Number(formValues["organization_id"])
          : currentUserInfo.organization_id,
        currentOrgId: currentUserInfo.organization_id,
        pageNum: 1,
        pageSize: 500,
        vendorId: null,
      })
    );
  };
  /**
   * Get customer based on TDS account
   */
  const fetchTDSCustomers = async () => {
    const responseAction = await dispatch(
      fetchTdsCustomers({
        reportOrg: Number(formValues.organization_id),
        orgId: currentUserInfo.organization_id,
        customerName: "",
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCustomerList([...response]);
      }
    }
  };
  /**
   * Get vendors based on TDS account
   */
  const fetchTDSVendors = async () => {
    const responseAction = await dispatch(
      fetchTdsVendors({
        reportOrg: Number(formValues.organization_id),
        orgId: currentUserInfo.organization_id,
        vendorName: "",
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setVendorsList([...response]);
      }
    }
  };
  /**
   * Get the list of all customers
   */
  const customerListOptions = customerList.map((customer, index) => ({
    label: customer.customer_display_name,
    value: customer.id,
    currencyId: customer.currency_id,
  }));
  /**
   * Get the list of all vendors
   */
  const vendorListOptions = vendorsList.map((vendor, index) => ({
    label: vendor.vendor_display_name,
    value: vendor.id,
    organizationName: vendor.organization_name,
    currency_id: vendor.currency_id,
  }));
  /**
   * Get the list of all currencies
   */
  const currencyListOptions = currencyLists
    .filter((currency) => currencyIds.includes(currency.id))
    .map((currency, index) => ({
      label: currency.code,
      value: currency.id,
    }));
  /*
   * Get the customer/vendor/currency/date_range name in the selection dropdown
   */
  const getName = (listOption: any[], name: string) => {
    let selectValue =
      name === "contact_or_account_id"
        ? formValues.contact_or_account_id
        : name === "currency_id"
        ? formValues.currency_id
        : name === "tdsCustomer" || name === "tdsVendor"
        ? formValues.entity_id
        : "";
    if (
      (name === "tdsCustomer" || name === "tdsVendor") &&
      selectValue === null
    ) {
      return [
        {
          label: "All",
          value: null,
        },
      ];
    }

    return listOption
      .filter((listData) => listData.value === selectValue)
      .map((listData) => ({
        label: listData.label,
        value: listData.value,
      }));
  };
  /**
   * Function to set the statement type
   */
  const handleChange = (e: any) => {
    setCustomerSelection({ label: "", value: "" });
    setVendorSelection({ label: "", value: "" });
    setEntityCustomerSelection({ label: "", value: "" });
    if (filters) navigate(`?`);
    const { name, value } = e.target;
    let formData = { ...resetFormValues };
    formData = { ...formData, [name]: value };
    if (name === "statement_type") {
      setFormErrors({});
      setCurrencySelect(true);
      setReadOnlyCurrency(false);
      setCurrencyIds([]);
      setAccountName("");
      setResetType(value);
    }
    if (value === "Other") {
      fetchCurrency(currentUserInfo.organization_id);
      formData = {
        ...formData,
        organization_id: currentUserInfo.organization_id,
        currency_id: currentUserInfo.organization_currency,
      };
    } else {
      setCustomerList([...customerDropdown]);
    }
    setFormValues(formData);
    setOrgDisable(false);
    props.clearReport();
  };
  /**
   * Function to set customer or vendor or currency id
   */
  const handleSelectChange = (e: any, name: string, listOption?: any) => {
    if (name === "contact_or_account_id") {
      setOrgDisable(false);
      let currencyArray = [];
      currencyArray.push(...orgFilterCurrencies, e?.currency_id);
      setCustomerSelection({ value: e?.value, label: e?.label });
      setVendorSelection({ value: e?.value, label: e?.label });
      setCurrencyIds([...currencyArray]);
      if (e.value) {
        setFormValues((values) => ({
          ...values,
          [name]: e.value,
          currency_id: e?.currency_id,
        }));
      }
    } else {
      setFormValues((values) => ({
        ...values,
        [name]: e.value,
      }));
    }
  };

  /*
   * Function to set the value directly to the name field given.
   */
  const handleSelectBoolanValues = (fieldValue: any, name: string) => {
    setFormValues((values) => ({
      ...values,
      [name]: fieldValue,
    }));
  };
  /**
   * Function to set account id
   */
  const handleItemAccountChange = (accountId: number, index: number) => {
    if (accountId) {
      setOrgDisable(false);
      formValues["contact_or_account_id"] = accountId;
      const selectedAccount = accountList.filter(
        (data) => data.id === accountId
      )[0];
      setAccountName(selectedAccount.account_class);
      if (
        selectedAccount.account_class === "SundryDebtor" ||
        selectedAccount.account_class === "SundryCreditor"
      ) {
        formValues["is_sundry"] = true;
        formValues["sundry_type"] =
          selectedAccount.account_class === "SundryDebtor"
            ? "Debtor"
            : "Creditor";
      } else {
        formValues["is_sundry"] = false;
        formValues["sundry_type"] = null;
      }
      if (
        selectedAccount.account_class === "BankAccount" &&
        selectedAccount.currency_id
      ) {
        setCurrencySelect(true);
        setReadOnlyCurrency(false);
        let currencyId = currentUserInfo.organization_currency
          ? currentUserInfo.organization_currency
          : 0;
        setCurrencyIds([selectedAccount?.currency_id, currencyId]);
        formValues["currency_id"] = selectedAccount?.currency_id;
      } else {
        formValues["currency_id"] = currentUserInfo.organization_currency
          ? currentUserInfo.organization_currency
          : null;

        setCurrencySelect(false);
        let currencyData: any = currencyLists.filter(
          (currency) => currency.id === formValues["currency_id"]
        )[0];
        setOrgCurrency(currencyData?.code);
        setReadOnlyCurrency(true);
      }
      if (
        selectedAccount.account_class === "TDSCustomers" ||
        selectedAccount.account_class === "TDSVendors"
      ) {
        formValues["is_tds"] = true;
        formValues["entity_id"] = null;
        formValues["entity_type"] = null;
      } else {
        formValues["is_tds"] = false;
        formValues["entity_id"] = null;
        formValues["entity_type"] = null;
      }
      setFormValues({ ...formValues });
    }
  };
  /**
   * Function to set start date and end date
   */
  const handleDateRange = (start: any, end: any, range: string) => {
    let dateRange = range.replace(" ", "_").toLowerCase();
    setFormValues({
      ...formValues,
      start_date: start,
      end_date: end,
      date_range: dateRange,
    });
  };

  const radioStyle: any = {
    color: colors.slate,
    width: "16px",
    height: "16px",
    "&.Mui-checked": {
      color: colors.ceruleanBlue,
    },
  };
  /**
   * Handle tds change
   */
  const handleTdsChange = (e: any, name: string, type: string) => {
    if (e.value === null) {
      setFormValues((values) => ({
        ...values,
        [name]: e.value,
        entity_type: null,
        is_tds: true,
      }));
      setOrgDisable(false);
    } else {
      if (type === "Customer") {
        setEntityCustomerSelection({ label: e.label, value: e.value });
      }
      setFormValues((values) => ({
        ...values,
        [name]: e.value,
        entity_type: type,
        is_tds: true,
      }));
      setOrgDisable(true);
    }
  };
  /**
   * Fetch organization currencies
   */
  const fetchCurrency = async (id: number) => {
    formValues["organization_id"] = id;
    const responseAction = await dispatch(
      getOrganizationCurrencies({
        selectedOrgId: id,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setOrgFilterCurrencies(response);
      }
    }
  };
  /**
   * Fetching report based on filters
   */
  const applyFilter = () => {
    let errors: any = [];
    errors = validate();
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      props.handleReport();
    }
  };
  const validate = () => {
    const errors: ObjectType = {};
    if (formValues.statement_type) {
      if (!formValues.contact_or_account_id) {
        let errorMsg = "";
        if (formValues.statement_type === "Customer") {
          errorMsg = "Please choose a customer";
        }
        if (formValues.statement_type === "Vendor") {
          errorMsg = "Please choose a vendor";
        }
        if (formValues.statement_type === "Other") {
          errorMsg = "Please choose a account";
        }
        if (errorMsg) errors.contact_or_account_id = errorMsg;
      }
      if (formValues.statement_type === "Other") {
        if (!formValues.organization_id) {
          errors.organization_id = "Please choose organization";
        }
        if (!formValues.currency_id) {
          errors.currency_id = "Please choose currency";
        }
      }

      if (!formValues.start_date || !formValues.end_date) {
        errors.date_ranges = "Please select a date range";
      }
    }
    return errors;
  };
  /**
   * Clear filter data
   */
  const clearFilter = (type?: string) => {
    if (filters) navigate(`?`);
    dispatch(
      setDateRange({
        start_date: "",
        end_date: "",
        date_range: "",
      })
    );
    // e.preventDefault();
    setResetType("reset");
    setAccountName("");
    setOrgDisable(false);
    setFormErrors({});
    setCustomerSelection({ label: "", value: "" });
    setVendorSelection({ label: "", value: "" });
    setEntityCustomerSelection({ label: "", value: "" });
    if (type === "orgSwitch") {
      let filterValues = {
        contact_or_account_id: "",
        date_range: "today",
        start_date: getFormatedDate(),
        end_date: getFormatedDate(),
        is_sundry: false,
        entity_id: null,
        entity_type: null,
        is_tds: false,
        is_integrated: true,
      };
      setFormValues({ ...formValues, ...filterValues });
    } else {
      setFormValues(resetFormValues);
    }

    props.clearReport();
    setTimeout(() => {
      if (type === "orgSwitch") {
        setResetType(formValues.statement_type);
      } else {
        setResetType(resetFormValues.statement_type);
      }
    }, 0);
  };
  const tdsCustomerList = () => {
    let tdsCustomers: any = [];
    let list = customerListOptions;
    let allOption = {
      label: "All",
      value: null,
      currencyId: null,
    };
    tdsCustomers.push(allOption);
    tdsCustomers.push(...list);
    return tdsCustomers;
  };

  const tdsVendorList = () => {
    let tdsVendors: any = [];
    let list = vendorListOptions;
    let allOption = {
      label: "All",
      value: null,
      currencyId: null,
    };
    tdsVendors.push(allOption);
    tdsVendors.push(...list);
    return tdsVendors;
  };
  // const handleIntegratedReport = (e: any) => {
  //   let isIntegrated = e === "integrated" ? true : false;
  //   props.habdleReportType && props.habdleReportType(isIntegrated);
  // }
  const LabelWithRoot = useMemo(() => {
    return (data: any) => {
      return (
        <div
          {...data.innerProps}
          className="custom-select-option-wrapper"
          style={{
            backgroundColor:
              Number(data.data.value) ===
              Number(formValues.contact_or_account_id)
                ? colors.whisper
                : "white",
          }}
        >
          <span className="custom-select-option-account-name">
            {data.data.label}
          </span>
          {data.data.organizationName && (
            <div className="custom-select-option-account-root">
              {data.data.organizationName}
            </div>
          )}
        </div>
      );
    };
  }, [formValues.contact_or_account_id]);

  return (
    <div className="top-filter-container filter-box w-100">
      <form name="filter-form" className="filter-form w-100 p-0 m-0">
        <Container className="top-controls-wrapper p-0 m-0">
          <RadioButton
            name="statement_type"
            value={formValues.statement_type}
            handleChange={handleChange}
          >
            <FormControlLabel
              value="Customer"
              control={<Radio size="small" sx={radioStyle} />}
              label="Customer"
            />
            <FormControlLabel
              value="Vendor"
              control={<Radio size="small" sx={radioStyle} />}
              label="Vendor"
            />
            <FormControlLabel
              value="Other"
              control={<Radio size="small" sx={radioStyle} />}
              label="Other Accounts"
            />
          </RadioButton>
        </Container>
        <div className="row g-0">
          <div
            className={
              // formValues.statement_type === "Other"
              // ?
              "col filter-wrapper four-col-filter d-flex"
              // : "col filter-wrapper d-flex"
            }
          >
            {formValues.statement_type === "Customer" ||
            formValues.statement_type === "Vendor" ? (
              <div className="filter-item">
                {/* <OrgSelectionDropdown ref={orgRef} /> */}
                <OrganizationDropDown
                  fetchDependencies={fetchCurrency}
                  selectedOrg={formValues.organization_id}
                  error={formErrors?.organization_id ? "error" : ""}
                  orgDisable={orgDisable}
                />
                <span className="error">{formErrors.organization_id}</span>
              </div>
            ) : null}
            <div className="filter-item">
              {formValues.statement_type === "Customer" ? (
                <CustomerSelectWithOrgFilter
                  customerId={customerSelection.value}
                  customerName={customerSelection.label}
                  orgId={
                    formValues["organization_id"]
                      ? Number(formValues["organization_id"])
                      : currentUserInfo.organization_id
                  }
                  onChange={(e) => {
                    handleSelectChange(
                      e,
                      "contact_or_account_id",
                      customerListOptions
                    );
                  }}
                  error={formErrors.contact_or_account_id}
                  isDisabled={false}
                />
              ) : formValues.statement_type === "Vendor" ? (
                <VendorSelectWithOrgFilter
                  vendorId={vendorSelection.value}
                  vendorName={vendorSelection.label}
                  orgId={
                    formValues["organization_id"]
                      ? Number(formValues["organization_id"])
                      : currentUserInfo.organization_id
                  }
                  onChange={(e) => {
                    handleSelectChange(e, "contact_or_account_id", vendorListOptions);
                  }}
                  error={formErrors.contact_or_account_id}
                  isDisabled={false}
                />
              ) : formValues.statement_type === "Other" ? (
                <CustomSearchSelect
                  itemAccount={Number(formValues.contact_or_account_id)}
                  organizationId={currentUserInfo.organization_id}
                  handleItemAccountChange={handleItemAccountChange}
                  itemIndex={0}
                  error={
                    formValues.statement_type === "Other" &&
                    formErrors?.contact_or_account_id
                      ? "error"
                      : ""
                  }
                />
              ) : null}
              <span className="error">
                {formErrors.contact_or_account_id
                  ? formErrors.contact_or_account_id
                  : ""}
              </span>
            </div>
            {formValues.statement_type === "Other" ? (
              <div className="filter-item">
                {/* <OrgSelectionDropdown ref={orgRef} /> */}
                <OrganizationDropDown
                  fetchDependencies={fetchCurrency}
                  selectedOrg={formValues.organization_id}
                  error={formErrors?.organization_id ? "error" : ""}
                  orgDisable={orgDisable}
                />
                <span className="error">{formErrors.organization_id}</span>
              </div>
            ) : null}
            <div className="filter-item">
              <PredefinedDateRangePicker
                isSingleDatePicker={false}
                isAdvanced={true}
                defaultValue="quarter"
                reportType="SOA"
                resetType={resetType}
                handleDateRange={handleDateRange}
                filterdata={{
                  startDate: tableFilterValues.start_date,
                  endDate: tableFilterValues.end_date,
                  dateRange: tableFilterValues.date_range,
                }}
              />
              <span className="error">{formErrors?.date_ranges}</span>
            </div>

            <div className="filter-item">
              {/* {currencySelect ? ( */}
              <Select
                name="currency_id"
                className={`form-select custom-select currency-select ${
                  formErrors.currency_id ? "error" : ""
                }`}
                value={getName(currencyListOptions, "currency_id")}
                options={currencyListOptions}
                placeholder="Choose currency"
                isSearchable={false}
                onChange={(e) => handleSelectChange(e, "currency_id")}
                styles={customSelectStyle}
              />
              <span className="error">{formErrors.currency_id}</span>
            </div>
            {props.enable_toggleReportType ? (
              <div className="filter-item report-type-filter-tem">
                <IntegratedReportSelect
                  handleChange={(e) => {
                    let isIntegartedValue =
                      e?.value === "integrated" ? true : false;
                    handleSelectBoolanValues(
                      isIntegartedValue,
                      "is_integrated"
                    );
                  }}
                  value={
                    formValues.is_integrated
                      ? { value: "integrated", label: "Integrated" }
                      : { value: "individual", label: "Individual" }
                  }
                  name="is_integerated"
                />
              </div>
            ) : (
              ""
            )}
            {formValues.statement_type === "Other" &&
            (accountName === "TDSCustomers" || accountName === "TDSVendors") ? (
              <div className="filter-item">
                {accountName === "TDSCustomers" ? (
                  // <Select
                  //   name="entity_id"
                  //   className="form-select custom-select customer-select"
                  //   value={
                  //     getName(customerListOptions, "tdsCustomer") || {
                  //       label: "All",
                  //       value: null,
                  //     }
                  //   }
                  //   options={tdsCustomerList()}
                  //   onChange={(e) =>
                  //     habdleTdsChange(e, "entity_id", "Customer")
                  //   }
                  //   styles={customSelectStyle}
                  //   isSearchable={true}
                  // />
                  <CustomerSelectWithOrgFilter
                    onChange={(e) => {
                      handleTdsChange(e, "entity_id", "Customer");
                    }}
                    orgId={
                      formValues["organization_id"]
                        ? Number(formValues["organization_id"])
                        : currentUserInfo.organization_id
                    }
                    customerId={Number(entityCustomerSelection.value)}
                    customerName={entityCustomerSelection.label || "All"}
                    error=""
                    isDisabled={false}
                    isAllOptionEnabled={true}
                    allOptionLabel="All"
                  />
                ) : accountName === "TDSVendors" ? (
                  <Select
                    name="entity_id"
                    className="form-select custom-select customer-select"
                    value={
                      getName(vendorListOptions, "tdsVendor") || {
                        label: "All",
                        value: null,
                      }
                    }
                    options={tdsVendorList()}
                    onChange={(e) => handleTdsChange(e, "entity_id", "Vendor")}
                    styles={customSelectStyle}
                    isSearchable={true}
                    components={{ Option: LabelWithRoot }}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={
            formValues.statement_type === "Other"
              ? "col button-container filter-wrapper four-col-filter d-flex"
              : "col button-container filter-wrapper d-flex"
          }
        >
          <Container className="buttons-wrapper">
            <button
              type="button"
              className="save-btn form-btn"
              onClick={applyFilter}
            >
              Apply
            </button>
            <a
              className="role-cancel text-decoration-none cancel-btn form-btn"
              href=""
              onClick={(e) => {
                e.preventDefault();
                clearFilter();
              }}
              role="button"
            >
              Reset
            </a>
          </Container>
        </div>
      </form>
    </div>
  );
};
export default forwardRef(ReportFilter);
