import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import {
  changeSubscriptionStatus,
  reactivateSubscriptionAction,
  updateNextInvoiceDateHandler,
} from "../subscriptionSlice";
import { useFormik } from "formik";
import { DateFormatHandler, getFormatedDate } from "../../../../helpers/helper";
import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CardBody } from "../../../common/components/htmlTags/Card/CardBody";
import CloseButton from "../../../common/components/button/CloseButton";
import { Form } from "../../../common/components/htmlTags/Form";
import { validateReactivateForm } from "./ValidationSubscriptionAction";
import { ObjectType } from "../../../../types";
import { setLoaderState } from "../../../common/commonSlice";
import EditNextInvoiceDateModal from "../../../common/components/EditNextInvoiceDateModal";
import * as yup from "yup";
import useCommonData from "../../../hooks/useCommon";
import dayjs from "dayjs";

type Props = {
  didAlertModalOpen?: boolean;
  setDidAlertModalOpen?: Dispatch<SetStateAction<boolean>>;
  nextInvoiceDate?: string;
  startDate?: string;
  endDate?: string | null;
  editNextInvoiceDateDataUpdate?: () => void;
  setNextInvoiceDateEditDetails?: Dispatch<SetStateAction<any>>;
  subscriptionId?: number;
  currencyCode?: string;
  refreshSubscription: () => void;
};
const EditNextInvoicingDate = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();

  const modalCancelHandler = () => {
    formik.resetForm();
    if (props.setDidAlertModalOpen) props.setDidAlertModalOpen(false);
  };

  const validationSchema = yup.object({
    next_invoice_billing_date: yup
      .string()
      .nullable() // Allow null initially
      .required("Please select a date")
      .test("is-valid-date", "Please enter a valid date", function (value) {
        if (!value) return false;
        return dayjs(value).isValid();
      })
      .test(
        "is-future-date",
        "The renewal date cannot be in the past",
        function (value) {
          if (!value)
            return this.createError({ message: "Please select a date" }); // Ensures error message
          const selectedDate = dayjs(value).startOf("day");
          const today = dayjs().startOf("day");
          if (!selectedDate.isSame(today) && !selectedDate.isAfter(today)) {
            return this.createError({
              message: "The renewal date cannot be in the past",
            });
          }
          return true;
        }
      )
      .test(
        "is-valid-date-check",
        "The new renewal date cannot be the same as the current renewal date",
        function (value) {
          if (!value)
            return this.createError({ message: "Please select a date" });
          if (DateFormatHandler(props.nextInvoiceDate) === formatDate(value)) {
            return this.createError({
              message:
                "The new renewal date cannot be the same as the current renewal date",
            });
          }
          return true;
        }
      ),
    comments: yup.string().required("Please enter comments"),
  });

  function formatDate(dateString: string): string {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const formik = useFormik({
    initialValues: {
      next_invoice_billing_date: "",
      prorate_credits: false,
      invoicing_option: "IMMEDIATELY",
      comments: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        subscription_id: props.subscriptionId ? props.subscriptionId : 0,
        next_invoice_date:
          formatDate(values.next_invoice_billing_date) + "T00:00:00",
        prorate_credits: values.prorate_credits,
        comments: values.comments,
        invoicing_option: values.invoicing_option,
      };

      dispatch(setLoaderState(true));

      const updateSubscrptionResponseAction = await dispatch(
        updateNextInvoiceDateHandler({
          values: data,
          orgId: currentUserInfo.organization_id,
        })
      );

      const updateSubscrptionResponse = updateSubscrptionResponseAction.payload;

      if (
        Object.keys(updateSubscrptionResponse).length &&
        !("error" in updateSubscrptionResponse)
      ) {
        dispatch(setLoaderState(false));
        modalCancelHandler();
        toast.success("Next invoice date updated successfully!", {
          toastId: "subscription-update-success",
          closeButton: false,
          position: "top-center",
        });
        props.refreshSubscription();
      } else if ("error" in updateSubscrptionResponse) {
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    },
  });

  const openPreviewModal = () => {
    props.setNextInvoiceDateEditDetails &&
      props.setNextInvoiceDateEditDetails({});
    props.editNextInvoiceDateDataUpdate &&
      props.editNextInvoiceDateDataUpdate();
  };

  return (
    <>
      <Dialog
        open={props?.didAlertModalOpen || false}
        className={`subscription-update-popover`}
        onClose={modalCancelHandler}
      >
        <Card wrapperClass="subscription-update">
          <CardHeader wrapperClass={`subscription-update-header-wrapper`}>
            <Grid container>
              <Grid item xs={10} className="p-t-15 header">
                {"Edit Next Invoicing Date"}
              </Grid>
              <Grid item xs={2} className="popover-close-button">
                <CloseButton closeModalHandler={modalCancelHandler} />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Form
              name="add_invoice_form"
              id="add_invoice_form"
              className=""
              tabIndex={-1}
            >
              <EditNextInvoiceDateModal
                modalCancelHandler={modalCancelHandler}
                formik={formik}
                nextInvoiceDate={props.nextInvoiceDate}
                startDate={props.startDate}
                endDate={props.endDate}
                currencyCode={props.currencyCode}
                openPreviewModal={openPreviewModal}
                subscriptionId={props.subscriptionId}
              />

              <div className="row mb-0">
                <div className="col">
                  <div
                    className={`form-button-wrapper w-100 'd-flex justify-content-end mt-3`}
                    id="form-btn"
                  >
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                      className="save-button"
                    >
                      {"Update"}
                    </button>
                    <button
                      type="button"
                      onClick={modalCancelHandler}
                      className="text-decoration-none cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
};
export default EditNextInvoicingDate;
