import { useMemo } from "react";
import { ObjectType, TableData } from "../../../../types";
import DetailsTableContainer from "../../../common/components/detailsPage/DetailsTableContainer";
import EmptyReport from "../../../common/components/EmptyReport";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import {
  DateFormatHandler,
  StatusColor,
  getProfileFormatedDate,
  handleNegativeValue,
} from "../../../../helpers/helper";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import { useAppSelector } from "../../../../app/hooks";
import { orgCurrencyListSelector } from "../../../common/commonSlice";
import { Link, useNavigate } from "react-router-dom";
import { Cell, Row } from "react-table";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import useCommonData from "../../../hooks/useCommon";
import "../Reports.css";

type Props = {
  receivableSummaryData: any;
  filterString?: string;
};
export const ReceivableSummaryTable = (props: Props) => {
  const { currentUserInfo } = useCommonData();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const { subModulesWithPermission } = usePermissionHandler();
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "show.customerName",
        className: "column-navigation consumer-name",
      },
      {
        Header: "Organization",
        accessor:
          Object.values(currentUserInfo.user_access_mapping)
            .toString()
            .split(",").length > 1
            ? "show.organization"
            : "Organization",
        className: "org",
      },
      {
        Header: "Date",
        accessor: "show.date",
        className: "trans-date text-nowrap",
      },
      {
        Header: "Transaction#",
        accessor: "show.transaction",
        className: "column-navigation transactions text-nowrap",
      },
      {
        Header: "Transaction Type",
        accessor: "show.transactionType",
        className: "column-navigation transaction-type",
      },
      {
        Header: "Reference #",
        accessor: "show.reference",
        className: "column-navigation text-nowrap reference",
      },
      {
        Header: "Status",
        accessor: "show.status",
        className: "column-navigation status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },

      {
        Header: "Total(Customer Currency)",
        accessor: "show.totalCustomerCurrency",
        className:
          "column-navigation total-currency text-align-right text-nowrap",
      },
      {
        Header: "Balance(Customer Currency)",
        accessor: "show.balanceCustomerCurrency",
        className:
          "column-navigation balance-currency text-align-right text-nowrap",
      },
      {
        Header: "Total",
        accessor: "show.total",
        className: "column-navigation total text-align-right text-nowrap",
      },
      {
        Header: "Balance",
        accessor: "show.balance",
        className: "column-navigation balance text-align-right text-nowrap",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    []
  );
  const data = props.receivableSummaryData
    ? props.receivableSummaryData?.receivables_list?.map(
        (reportData: ObjectType) => {
          return {
            show: {
              customerName: reportData.customer_name,
              organization: reportData.organization_name,
              date: getProfileFormatedDate(
                currentUserInfo.organization_date_format,
                reportData.transaction_date
              ),
              transaction: joinTransactionNumber(reportData.transaction_number),
              transactionType: reportData.transaction_type,
              reference: reportData.reference_number,
              status: reportData.transaction_status,
              totalCustomerCurrency:
                reportData.total !== null
                  ? handleNegativeValue(
                      NumberFormat(
                        Number(reportData.total),
                        props.receivableSummaryData.currency_code,
                        orgCurrencyList
                      ),
                      reportData.currency_code
                    )
                  : reportData.customer_name === "Total"
                  ? ""
                  : "0",
              balanceCustomerCurrency: reportData.balance
                ? handleNegativeValue(
                    NumberFormat(
                      Number(reportData.balance),
                      props.receivableSummaryData.currency_code,
                      orgCurrencyList
                    ),
                    reportData.currency_code
                  )
                : reportData.customer_name === "Total"
                ? ""
                : "0",
              total: reportData.total_base
                ? handleNegativeValue(
                    NumberFormat(
                      Number(reportData.total_base),
                      props.receivableSummaryData.currency_code,
                      orgCurrencyList
                    ),
                    props.receivableSummaryData.currency_code
                  )
                : "0",
              balance: reportData.balance_base
                ? handleNegativeValue(
                    NumberFormat(
                      Number(reportData.balance_base),
                      props.receivableSummaryData.currency_code,
                      orgCurrencyList
                    ),
                    props.receivableSummaryData.currency_code
                  )
                : "0",
            },
            action:
              reportData.transaction_type === "Invoice"
                ? {
                    transUrl: `/invoice/detail/${reportData.transaction_id}${props.filterString}`,
                    transPermission:
                      subModulesWithPermission.includes("Invoices"),
                    userUrl: `/customers/detail/${reportData.customer_id}${props.filterString}`,
                    userPermission:
                      subModulesWithPermission.includes("Customer"),
                    customNavigation: true,
                  }
                : reportData.transaction_type === "Credit Note"
                ? {
                    transUrl: `/credit-note/detail/${reportData.transaction_id}${props.filterString}`,
                    transPermission:
                      subModulesWithPermission.includes("Credit Notes"),
                    userUrl: `/customers/detail/${reportData.customer_id}${props.filterString}`,
                    userPermission:
                      subModulesWithPermission.includes("Customer"),
                    customNavigation: true,
                  }
                : { url: "", permission: "" },
          };
        }
      )
    : [];
  const navigation = (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => {
    if (cell.column.Header) {
      let action = row?.values?.action;
      if (
        cell.column.Header === "Transaction Type" ||
        cell.column.Header === "Transaction#" ||
        cell.column.Header === "Reference #" ||
        cell.column.Header === "Status" ||
        cell.column.Header === "Total(Customer Currency)" ||
        cell.column.Header === "Balance(Customer Currency)" ||
        cell.column.Header === "Total" ||
        cell.column.Header === "Balance"
      ) {
        if (action?.transUrl && action?.transPermission) {
          navigate(`?${props.filterString}`);
          navigate(action?.transUrl);
        }
      } else if (cell.column.Header === "Customer") {
        if (action?.userUrl && action?.userPermission) {
          navigate(`?${props.filterString}`);
          navigate(action?.userUrl);
        }
      }
    }
  };
  return (
    <div className="table-wrapper">
      <DetailsTableContainer
        columns={columns}
        data={data}
        className={"table report-table receivable-summary"}
        hiddenColumns={["action"]}
        haveColumnClassName={true}
        navigation={navigation}
      >
        <EmptyReport heading="No transactions found!" alt="Empty Report" />
      </DetailsTableContainer>
    </div>
  );
};
