import React, { useMemo, useState } from "react";
import {
  InvoiceDetailValues,
  SubscriptionDetailConsolidatedInvoiceValues,
} from "../../../../../types";
import { subscriptionDetailsConsolidatedInitialValues } from "../../../subscription/SubscriptionStateInitialization";
import DetailsTableContainerSubscription from "../../../../common/components/detailsPage/DetailsTableContainerSubscription";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import useGst from "../../../../hooks/useGst";
import { tdsApplyLevel } from "../../../../constants/constants";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../hooks/useCommon";
import { DateFormatHandler } from "../../../../../helpers/helper";

type Props = {
  overviewSubscriptionData: { invoices: any[] };
  columnsForPlanAddon: any[];
  currency_code: string;
};

const InvoiceFutureRenewalEdit: React.FC<Props> = ({
  overviewSubscriptionData,
  columnsForPlanAddon,
  currency_code,
}) => {
  function formatFrequency(frequency: string) {
    if (!frequency) return "";
    let formattedStr = frequency
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return formattedStr;
  }

  function convertToNormalText(str: string) {
    if (!str) return "";
    let words = str.toLowerCase().split("_");
    return words.join(" ");
  }
  return (
    <div
      className="consolidate-invoice-details-wrapper-edit"
      style={{ padding: "32px" }}
    >
      <div className="table-section">
        {overviewSubscriptionData.invoices.map((overviewSubscription, key) => {
          return (
            <>
              {overviewSubscription.invoicing_period && (
                <div
                  style={{
                    fontSize: "larger",
                    margin: "10px 0",
                    paddingLeft: "18px",
                    fontWeight: 600,
                  }}
                >
                  {overviewSubscriptionData.invoices
                    ? "Plan, Addon & Coupon Details"
                    : `Invoicing Period: ${DateFormatHandler(
                        overviewSubscription.invoicing_period.split(" - ")[0]
                      )} to ${DateFormatHandler(
                        overviewSubscription.invoicing_period.split(" - ")[1]
                      )}`}
                </div>
              )}

              <div className="table-wrap subscription-invoice-details">
                {overviewSubscription.plan_details &&
                  overviewSubscription.plan_details.length > 0 &&
                  overviewSubscription?.plan_details?.map((plan: any) => {
                    return (
                      <>
                        <div className="plan-description">
                          <div
                            style={{
                              fontSize: "larger",
                              marginBottom: "10px",
                            }}
                          >
                            Plans
                          </div>
                          <h6 className="item-bold">{plan.plan_name}</h6>
                          <div className="plan-tag">
                            <span>
                              Frequency: {formatFrequency(plan.frequency)} |
                              Pricing model:{" "}
                              {formatFrequency(plan.pricing_model)}
                            </span>
                          </div>
                          <div>
                            <span style={{ color: "#747d84" }}>
                              Invoicing period: {plan.invoicing_period}
                            </span>
                          </div>
                          {!!plan.description ? (
                            <div>
                              <span style={{ color: "#747d84" }}>
                                Description: {plan.description}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <DetailsTableContainerSubscription
                          columns={columnsForPlanAddon}
                          data={plan.items}
                          currency_code={currency_code}
                          haveColumnClassName={true}
                          className="table inv-preview-table subscription-data-view-table"
                          hiddenColumns={["TDS"]}
                          pricingModel={plan.pricing_model}
                          component="invoice"
                        />
                      </>
                    );
                  })}
                {overviewSubscription.addon_details &&
                  overviewSubscription.addon_details.length > 0 && (
                    <div
                      style={{
                        fontSize: "larger",
                        paddingLeft: "23px",
                        paddingTop: "19px",
                        marginBottom: "-13px",
                      }}
                    >
                      Addons
                    </div>
                  )}
                {overviewSubscription.addon_details &&
                  overviewSubscription.addon_details.length > 0 &&
                  overviewSubscription?.addon_details?.map((addon: any) => {
                    return (
                      <>
                        <div className="plan-description">
                          <h6 className="item-bold">{addon.addon_name}</h6>
                          <div className="plan-tag">
                            <span>
                              Frequency: {formatFrequency(addon.frequency)} |
                              Pricing model:{" "}
                              {formatFrequency(addon.pricing_model)}
                            </span>
                          </div>
                          <div>
                            <span style={{ color: "#747d84" }}>
                              Invoicing period: {addon.invoicing_period}
                            </span>
                          </div>
                          {!!addon.description ? (
                            <div>
                              <span style={{ color: "#747d84" }}>
                                Description: {addon.description}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <DetailsTableContainerSubscription
                          columns={columnsForPlanAddon}
                          data={addon.items}
                          currency_code={currency_code}
                          haveColumnClassName={true}
                          className="table inv-preview-table subscription-data-view-table"
                          hiddenColumns={["TDS"]}
                          pricingModel={addon.pricing_model}
                          component="invoice"
                        />
                      </>
                    );
                  })}
                {overviewSubscription.coupons &&
                  overviewSubscription.coupons.length > 0 && (
                    <div
                      style={{
                        fontSize: "larger",
                        paddingLeft: "23px",
                        paddingTop: "19px",
                        marginBottom: "-13px",
                      }}
                    >
                      Coupons
                    </div>
                  )}
                {overviewSubscription.coupons &&
                  overviewSubscription.coupons.length > 0 &&
                  overviewSubscription?.coupons?.map((coupon: any) => {
                    return (
                      <>
                        <div className="plan-description coupon-description-container">
                          <div>
                            <h6 className="item-bold">{coupon.name}</h6>
                            <div className="plan-tag">
                              <span>
                                Applicable{" "}
                                {convertToNormalText(coupon.duration)}
                              </span>
                            </div>
                          </div>
                          <div className="coupon-discount-amount-container">
                            {coupon.discount}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default InvoiceFutureRenewalEdit;
