import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../../app/hooks";
import { ModalContext } from "../../../../../../components/modals/ModalContext";
import { getFormatedDate } from "../../../../../../helpers/helper";
import { SuccessToaster } from "../../../../../../helpers/toastHelper";
import {
  latestCurrencyRateSelector,
  setLoaderState,
} from "../../../../../common/commonSlice";
import useCommonData from "../../../../../hooks/useCommon";
import {
  amountCalculation,
  checkAccountNameExist,
  checkGLCodeExists,
  create,
  deleteById,
  fetchById,
  update,
} from "../../../../chartOfAccounts/coaSlice";
import { bankAccountCategoryDetails } from "../../../services/BankingSlice";
import { AccountData, FormValues } from "../../../types";
import { AddAccountValidationSchema } from "../utils/AddAccountFormValidation";
export const useAddAccount = (
  refreshList: () => Promise<void>,
  editId?: number
) => {
  const [initialValue, setInitialValue] = useState<FormValues>({
    id: 0,
    account_name: "",
    is_account_name_available: true,
    is_gl_code_available: true,
    add_as_an_account_category: false,
    description: "",
    ledger_code: "",
    currency: 0,
    currency_code: "",
    opening_balance_bank_currency: "",
    opening_balance_base_currency: "",
  });
  const { dispatch, currentUserInfo } = useCommonData();
  const { closeModal } = useContext(ModalContext);
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;

  const getAccountDetails = async () => {
    const responseAction = await dispatch(
      fetchById({
        accountId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (response) {
      setInitialValue({
        ...response,
        currency: response.currency_id,
        is_account_name_available: true,
        is_gl_code_available: true,
        ledger_code: response.gl_start_code
      });
    }
  };
  useEffect(() => {
    if (editId) {
      getAccountDetails();
    }
  }, [editId]);

  const formik = useFormik<FormValues>({
    initialValues: initialValue,
    validationSchema: AddAccountValidationSchema,
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (value) => {
      if (
        formik.isValid &&
        formik.values.is_account_name_available &&
        formik.values.is_gl_code_available
      ) {
        handleSubmit(
          value,
          dispatch,
          currentUserInfo,
          exchangeRateValues,
          closeModal,
          refreshList,
          editId
        );
      }
    },
  });
  const isAccountNameExist = async (name: string, signal?: AbortSignal) => {
    const responseAction = await dispatch(
      checkAccountNameExist({
        accountName: name,
        orgId: currentUserInfo.organization_id,
        editAccountId: formik.values.id || 0,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.name_available === true) {
          formik.setFieldValue("is_account_name_available", true);
        } else {
          formik.setFieldValue("is_account_name_available", false);
        }
      }
    }
  };
  const glCodeExist = async (glCode: string | number, signal: AbortSignal) => {
    const responseAction = await dispatch(
      checkGLCodeExists({
        accountId: editId || 0,
        orgId: currentUserInfo.organization_id,
        glCode: glCode,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.is_available === true) {
          formik.setFieldValue("is_gl_code_available", true);
        } else {
          formik.setFieldValue("is_gl_code_available", false);
        }
      }
    }
  };
  const getOpeningBalanceConversion = async (
    opening_balance: number,
    signal?: Object
  ) => {
    if (formik.values.currency && opening_balance) {
      const responseAction = await dispatch(
        amountCalculation({
          orgId: currentUserInfo.organization_id,
          values: {
            conversion_date: getFormatedDate(new Date()),
            currency_id: Number(formik.values.currency),
            organization_id: currentUserInfo.organization_id,
            conversion_id: exchangeRateValues.id,
            amount: String(opening_balance),
            org_currency_id: currentUserInfo.organization_currency,
          },
          signal: signal || {},
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          formik.setFieldValue(
            "opening_balance_base_currency",
            response.converted_amount
          );
        }
      }
    }
  };
  const getBankAccountCategoryDetails = async () => {
    const responseAction = await dispatch(
      bankAccountCategoryDetails(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      return response;
    }
  };
  const handleSubmit = async (
    value: FormValues,
    dispatch: (data: any) => any,
    currentUserInfo: any,
    exchangeRateValues: any,
    onCloseModal: () => void,
    refreshList: () => Promise<void>,
    editId?: number
  ) => {
    dispatch(setLoaderState(true));
    const { ledger_code } = value;

    const updatedData: any = ledger_code
      ? {
          account_name: value.account_name,
          description: value.description,
          opening_balance: value.opening_balance_bank_currency || 0,
          gl_start_code: value.ledger_code,
          currency_id: Number(value.currency),
          is_category: false,
          conversion_id: exchangeRateValues.id,
        }
      : {
          account_name: value.account_name,
          description: value.description,
          opening_balance: value.opening_balance_bank_currency || 0,
          currency_id: Number(value.currency),
          is_category: false,
          conversion_id: exchangeRateValues.id,
        };

    const accountCategoryDetails = await getBankAccountCategoryDetails();

    const data: AccountData = {
      parentId: accountCategoryDetails.id,
      value: updatedData,
      orgId: currentUserInfo.organization_id,
    };

    const responseAction = editId
      ? await dispatch(update({ ...data, accountId: Number(editId) }))
      : await dispatch(create(data));
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        toast.success(
          editId
            ? "Account updated successfully!"
            : "Account created successfully!",
          {
            toastId: editId ? "account-edit-success" : "account-create-success",
            closeButton: false,
            position: "top-center",
          }
        );
        await refreshList();
        onCloseModal();
        dispatch(setLoaderState(false));
      } else if ("error" in response) {
        if (
          response.error?.detail?.[0]?.msg === "GL Code already exists" ||
          response.error?.detail?.[0]?.msg === "Invalid GL Code"
        ) {
          $("#add-account-button").removeClass(
            "add-account-form-section-disable"
          );
        } else {
        }
        dispatch(setLoaderState(false));
      }
    }
  };
  const deleteBankAccount = async (id: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      deleteById({
        accountId: id,
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (response) {
      setLoaderState(false);
      SuccessToaster(
        "Account deleted successfully!",
        "bank-account-delete-success"
      );
    } else {
      setLoaderState(false);
    }
  };
  return {
    formik,
    isAccountNameExist,
    getOpeningBalanceConversion,
    deleteBankAccount,
    glCodeExist,
  };
};
