import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { SubscriptionSmallIcon } from "../../../../../../assets/images";
import { StatusColor } from "../../../../../../helpers/helper";
import {
  subscriptionColumnValues,
  subscriptionDefaultValues,
  subscriptionMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  getTableCustomizationFields,
} from "../../../../../common/commonSlice";

import EmptyList from "../../../../../common/components/EmptyList";
import Pagination from "../../../../../common/components/table/Pagination";
import Loader from "../../../../components/Loader";
import { subscriptionList } from "../../../../subscription/subscriptionSlice";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import TableContainer from "../../../../../common/components/table/TableContainer";
import { listFilterSelector } from "../../../../../../appSlice";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import { coustomCaseWithRemoveUnderscore } from "../../../../../../helpers/planHelper";

type Props = {
  isSameOrg: boolean;
};
function SubscriptionTransactions(props: Props) {
  const selectedFilterData = useAppSelector(listFilterSelector);
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [subscriptionData, setSubscriptionData] = useState<any[]>([]);
  const [toatalSubscriptions, setToatalSubscriptions] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "actions",
    "Terms",
  ]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const customerId = editId ? editId : "";

  let invoicesRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Subscriptions"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    fetchTableCustomizationFields();
    fetchSubscriptionList();
  }, [page, itemsPerPage, dateSortOrder, currentUserInfo.organization_id]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(toatalSubscriptions / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount != 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, toatalSubscriptions, page]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  useEffect(() => {
    let hiddenItems: string[] = [];
    subscriptionColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems, "actions"]);
  }, [subscriptionData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  /**
   * Fetch customized table columns
   */
  const frequencyFilters = [
    { id: 1, name: "Daily" },
    { id: 2, name: "Weekly" },
    { id: 3, name: "Monthly" },
    { id: 4, name: "Yearly" },
  ];
  const getFrequencyFilterString = (frequencyIds: number[]) => {
    const numberSet = new Set(frequencyIds);
    const names: string[] = [];

    for (const object of frequencyFilters) {
      if (numberSet.has(object.id)) {
        names.push(object.name);
      }
    }
    return names;
  };
  const fetchTableCustomizationFields = async () => {
    const responseAction = await dispatch(
      getTableCustomizationFields({
        tableName: "Invoices",
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      let selectedFields: string[] = [];
      if (response.list_of_column.length > 0) {
        selectedFields = response.list_of_column;
      } else {
        selectedFields = subscriptionDefaultValues;
      }
      subscriptionMandatoryValues.map((column) => {
        if (!selectedFields.includes(column)) {
          selectedFields.push(column);
        }
      });
      setSelectedColumns([...selectedFields]);
    }
  };
  /**
   * Fetch all invoices
   */
  const fetchSubscriptionList = async () => {
    setIsLoading(true);
    if (customerId) {
      const responseAction = await dispatch(
        subscriptionList({
          page,
          itemsPerPage,
          dateSortOrder,
          orgId: currentUserInfo.organization_id,
          orgIds: selectedFilterData.Organizations
            ? selectedFilterData.Organizations
            : [],
          statusList: selectedFilterData.Status
            ? getStatusFilterString(selectedFilterData.Status).map(
                (eachStatus: string) =>
                  eachStatus.toUpperCase().replace(" ", "_")
              )
            : [],

          CustomerList: selectedFilterData.Customers
            ? selectedFilterData.Customers
            : [Number(customerId)],

          frequency: selectedFilterData.Frequency
            ? getFrequencyFilterString(selectedFilterData.Frequency).map(
                (eachFrequency: string) => eachFrequency.toUpperCase()
              )
            : [],
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;

        if (Object.keys(response).length && !("error" in response)) {
          setSubscriptionData(response.subscriptions);
          setIsLoading(false);
          let length = response.subscriptions.length;
          setToatalSubscriptions(response.total);
          if (response.total !== 0)
            setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else if ("error" in response) {
        }
      }
    }
  };
  /**
   * Date sort handler
   */
  const sortHandler = (column: string) => {
    if (column === "Subscription Creation Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Subscription Creation Date",
        accessor: "show.subscription_created_at",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "subscription_created_at",
      },
      {
        Header: "Subscription Number",
        accessor: "show.subscription_number",
        sort: false,
        sortOrder: "",
        className: "subscription_number",
      },
      {
        Header: "Status",
        accessor: "show.subscription_status",
        sort: false,
        sortOrder: "",
        className: "subscription_status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "SUBSCRIPTION PERIOD",
        accessor: "show.frequency",
        sort: false,
        sortOrder: "",
        className: "frequency",
      },
      {
        Header: "Start Date",
        accessor: "show.start_date",
        sort: false,
        sortOrder: "",
        className: "start_date",
      },
      {
        Header: "Next Renewal",
        accessor: "show.next_renewal",
        sort: false,
        sortOrder: "",
        className: "next_renewal",
      },
      {
        Header: "MRR",
        accessor: "show.mrr",
        sort: false,
        sortOrder: "",
        className: "mrr",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return <></>;
        },
      },
    ],
    [dateSortOrder, currentUserInfo.organization_id, hiddenColumns]
  );

  const dateFormat = (timestamp: any) => {
    if (!timestamp) return;
    var parts = timestamp.split("-");
    return parts[0] + "/" + parts[1] + "/" + parts[2];
  };
  /**
   * set table row data
   */
  function toTitleCase(str: string) {
    return str.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
  }
  const data = subscriptionData
    ? subscriptionData.map((subscription) => ({
        show: {
          subscription_created_at: dateFormat(
            subscription.subscription_created_at
          ),
          subscription_number: subscription.subscription_number
            .replace(/^~|\s/g, "")
            .replace("~", "-"),
          subscription_status: coustomCaseWithRemoveUnderscore(
            subscription.subscription_status
          ),
          frequency:
            subscription.custom_repeat_count > 0
              ? "Every " +
                subscription.custom_repeat_count +
                " " +
                subscription.frequency.toLowerCase()
              : toTitleCase(subscription.frequency),
          start_date: dateFormat(subscription.start_date),
          next_renewal: dateFormat(subscription.next_renewal),
          mrr:
            subscription.currency_code +
            " " +
            NumberFormat(
              subscription.monthly_recurring_revenue,
              subscription.currency_code,
              orgCurrencyList
            ),
          actions: {
            id: subscription.id,
          },
        },
      }))
    : [];
  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % toatalSubscriptions;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  return (
    <>
      <TableContainer
        className="list-table table table-striped w-100"
        columns={columns}
        data={data}
        url={"/subscriptions"}
        sortHandler={sortHandler}
        isLoading={isLoading}
        editPermission={invoicesRolePermission.includes("Update")}
        isDetailViewExist={true}
        hiddenColumns={hiddenColumns}
      >
        <EmptyList
          heading={
            invoicesRolePermission.includes("Create")
              ? "Create new subscriptions!"
              : "No Invoices found!"
          }
          subheading={
            invoicesRolePermission.includes("Create")
              ? "Get paid faster by creating subscriptions."
              : "Oops, There is no subscriptions data available right now"
          }
          list="subscriptions"
          alt="create subscriptions preview"
          buttonText="Create subscriptions"
          navigateLink={`/subscriptions/create/${editId}`}
          navigateState={{
            state: {
              redirectTo: `/customers/detail/${editId}`,
              consumerId: editId,
            },
          }}
          isCrateAccess={
            invoicesRolePermission.includes("Create") && props.isSameOrg
          }
          icon={SubscriptionSmallIcon}
        />
      </TableContainer>
      {isLoading ? <Loader /> : null}
      <div className="table-footer pagination-container w-100 clear-both">
        {toatalSubscriptions > itemsPerPage ? (
          <Pagination
            pageCount={pageCount}
            page={page}
            handlePageClick={handlePageClick}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default SubscriptionTransactions;
