import { ObjectType } from "../../../../types";

const moduleLookup: ObjectType = {
  invoices: "Sales",
  customers: "Sales",
  credit_notes: "Sales",
  payment_received: "Sales",
  proforma_invoices:"Sales",
  items: "Sales",
  bills: "Purchase",
  vendors: "Purchase",
  debit_notes: "Purchase",
  payment_made: "Purchase",
  journal_voucher: "Account",
  budget:"Account",
  chart_of_accounts: "Account",
  recurring_invoices: "Sales",
  plans: "Subscription",
  addons: "Subscription",
  charges:"Subscription",
  coupons: "Subscription",
  subscription: "Subscription"
};

export const getModuleName = (subModule: string) => {
  const moduleType: string = moduleLookup[subModule];
  if (moduleType) {
    return moduleType;
  } else {
    throw new Error("Invalid subModule");
  }
};
