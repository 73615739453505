import { SvgIconProp } from "../types";
/**
 * EditIcon Component
 *
 * A reusable SVG edit icon that can be customized via props.
 *
 * @component
 * @param {SvgIconProp} props - The properties for the EditIcon component.
 * @param {string} [props.width="16px"] - Width of the icon.
 * @param {string} [props.height="16px"] - Height of the icon.
 * @param {string} [props.rotate] - Rotation transformation (e.g., "rotate(45deg)").
 * @param {string} [props.color="currentColor"] - Fill color of the icon.
 * @returns {JSX.Element} The rendered EditIcon component.
 */
export const PlusIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "16px"}
      height={props.height ? props.height : "16px"}
      viewBox={`0 0 ${
        props.width ? props.width.substring(0, props.width.length - 2) : "16"
      } ${
        props.height ? props.height.substring(0, props.height.length - 2) : "16"
      }`}
      style={{ transform: props.rotate, margin: props.margin || "" }}
    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 0h20v20H0z" />
        <path
          fill={props.color}
          fillRule={"nonzero"}
          d="M9.167 9.167v-5h1.666v5h5v1.666h-5v5H9.167v-5h-5V9.167z"
        />
      </g>
    </svg>
  );
};
