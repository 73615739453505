import {
  statusFilters,
  itemType,
  transactionCategory,
  tagFilters,
  invoiceType,
} from "../features/constants/constants";

export const getStatusFilterString = (statusIds: number[]) => {
  const numberSet = new Set(statusIds);
  const names: string[] = [];

  for (const object of statusFilters) {
    if (numberSet.has(object.id)) {
      names.push(object.name);
    }
  }

  return names;
};

export const getInvoiceTypeFilterString = (invoiceTypeId: number[]) => {
  const numberSet = new Set(invoiceTypeId);
  const names: string[] = [];

  for (const object of invoiceType) {
    if (numberSet.has(object.id)) {
      names.push(object.name);
    }
  }

  return names;
};

export const getTagFilterString = (tagIds: number[]) => {
  const numberSet = new Set(tagIds);
  const names: string[] = [];

  for (const object of tagFilters) {
    if (numberSet.has(object.id)) {
      names.push(object.name);
    }
  }

  return names;
};

export const getItemTypeFilterString = (itemTypeIds: number[]) => {
  const numberSet = new Set(itemTypeIds);
  const names: string[] = [];

  for (const object of itemType) {
    if (numberSet.has(object.id)) {
      names.push(object.name);
    }
  }

  return names;
};
export const getTransactionCategoryFilterString = (
  transactionCategoryIds: number[]
) => {
  const numberSet = new Set(transactionCategoryIds);
  const names: string[] = [];

  for (const object of transactionCategory) {
    if (numberSet.has(object.id)) {
      names.push(object.name);
    }
  }

  return names;
};

export const getModuleStatusFilter = (module: string) => {
  function filterObjects(
    idArray: number[],
    objectArray: { id: number; name: string }[]
  ): { id: number; name: string }[] {
    return objectArray.filter((obj) => idArray.includes(obj.id));
  }
  switch (module) {
    case "Invoices":
      return filterObjects([1, 2, 3, 4, 5, 9, 10, 20], statusFilters);
    case "CN":
      return filterObjects([1, 2, 5, 20], statusFilters);
    case "PR":
      return filterObjects([5, 6, 7, 19], statusFilters);
    case "RI":
      return filterObjects([11, 12, 13, 14], statusFilters);
    case "Bills":
      return filterObjects([1, 2, 3, 4, 5, 9, 10, 20], statusFilters);
    case "Purchase Orders":
      return filterObjects([1, 5, 14, 25], statusFilters);
    case "DN":
      return filterObjects([1, 2, 5, 20], statusFilters);
    case "PM":
      return filterObjects([5, 6, 7, 19], statusFilters);
    case "JV":
      return filterObjects([5, 8], statusFilters);
    case "ADDON":
    case "Plan":
      return filterObjects([11, 14], statusFilters);
      break;
    case "Coupon":
      return filterObjects([11, 14, 21], statusFilters);
      break;
    case "Subscription":
      return filterObjects([16, 17, 18, 11, 13, 14, 26], statusFilters);
    case "Items":
      return filterObjects([11, 15], statusFilters);
      break;
    case "ProformaInvoice":
      return filterObjects([5, 1, 24, 14], statusFilters);
    case "SV":
      return filterObjects([5, 8], statusFilters);
    default:
      return filterObjects([1, 2, 3, 4, 5], statusFilters);
  }
};

export const getStatusIds = (status: string[]) => {
  let statusIds: number[] = [];
  statusFilters.map((row) => {
    if (status.includes(row.name)) statusIds.push(row.id);
  });
  return statusIds;
};
