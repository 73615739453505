import { Link } from "react-router-dom";
import "./void.css";
import { EditNextInvoiceDateIcon } from "./customSvgIcons/editNextInvoiceDateIcon";

type Props = {
  className: string;
  reactivateIcon: boolean;
  isMutable: boolean;
  onClick: () => void;
  isLocked: boolean;
  lockDate: string;
  name: string;
  assignedUserCount?: number;
  iconColor?: string;
  status: string;
  isButtonClick?: boolean;
};
export default function EditNextInvoicingDateButton(props: Props) {
  return (
    <Link
      className={`${props.className}`}
      style={{ textDecoration: "none" }}
      to="#"
      data-bs-toggle={props.isButtonClick ? "" : "modal"}
      data-bs-target={
        props.isMutable && !props.isLocked && !props.assignedUserCount
          ? "#reactivateMessageModal"
          : !props.isMutable && !props.isLocked
          ? "#notificationPopupModal"
          : props.assignedUserCount
          ? "#voidConfMessageModal"
          : ""
      }
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <span className="d-flex align-items-center">
        {props.reactivateIcon ? (
          <EditNextInvoiceDateIcon color={props.iconColor} />
        ) : null}
        Edit Next Invoicing Date
      </span>
    </Link>
  );
}
