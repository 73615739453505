import { useAppSelector } from "../../../../app/hooks";
import { ErrorToaster } from "../../../../helpers/toastHelper";
import { ReportTagFormValues } from "../../../../types";
import { currentUserSelector } from "../../../common/commonSlice";
import { EditIcon, PlusIcon } from "../../../common/components";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { colors } from "../../../constants/colors";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { CloneIcon } from "../../../common/components/customSvgIcons/cloneIcon";
import { ReminderEmailFrequencyForm } from "./ReminderEmailFrequecnyForm";
import "./ReminderEmailFrequencyForm.css";

type Props = {
  editId?: number;
  reminderDetails?: any;
  wrapperClass?: string;
  buttonClass?: string;
  onOpenFunction?: () => void;
  onCloseFunction?: () => void;
  setReminderEmailFrequencyList: React.SetStateAction<any>;
};
export const ReminderEmailFrequencyFormModal = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const buttonSelect = () => {
    if (props.editId) {
      return (
        <span>
          <EditIcon color={colors.battleshipGrey} margin="0px 5px 0px 0px" />
          Edit
        </span>
      );
    } else {
      return (
        <span>
          <PlusIcon color={colors.white} margin="0px 5px 0px 0px" />
          Add New Reminder Email Frequency
        </span>
      );
    }
  };
  return (
    <SideModal
      modalTitle={props.editId ? "Edit Reminder Email Frequency" : "Create Reminder Email Frequency"}
      drawerWrapperClass={"reminder-email-frequency-modal button-bottom"}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <ReminderEmailFrequencyForm
                onClose={onCloseModal}
                isEdit={props.editId ? true : false}
                editId={props.editId}
                reminderDetails={props.reminderDetails}
                setReminderEmailFrequencyList={props.setReminderEmailFrequencyList}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={buttonSelect()}
      buttonClass={
        props.buttonClass
          ? props.buttonClass
          : "no-appearance primary-btn w-100 m-0 "
      }
      shouldGreyOut={false}
      tooltipPlacement="bottom-end"
      wrapperClass={props.wrapperClass ? props.wrapperClass : "w-100"}
      onOpenFunction={props.onOpenFunction}
      onCloseFunction={props.onCloseFunction}
      id="reminder-email-frequency-create-modal"
    />
  );
};
