import React, {
  Dispatch,
  ForwardRefRenderFunction,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormSelectField } from "../../../../common/components/formSelectField/FormSelectField";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  BillDefaultRef,
  BillDefaultValues,
  BillValues,
  FormSelectOption,
  ObjectType,
  PaymentTerms,
} from "../../../../../types";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { useParams } from "react-router-dom";
import { vendorListSelector } from "../../../vendorManagement/vendorSlice";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import PrefixInput from "../../../../common/components/PrefixInput/PrefixInput";
import { PaymentTermsModal } from "../../../../common/components/paymentTerms/paymentTermsModal";
import { TagsModal } from "../../../../common/components/tags/TagsModal";
import TagInput from "../../../../common/components/TagInput";
import { initialDefaultValues } from "./StateInitialization";
import PaymentTermSelect from "../../../../common/components/PaymentTermSelect";
import {
  joinForPayload,
  splitTransactionNumber,
} from "../../../../../helpers/prefixHelper";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import {
  currentUserSelector,
  fiscalTearFilterForValidationSelector,
  paymentTermsSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { getFormatedDate } from "../../../../../helpers/helper";
import moment from "moment";
import { getNewBillNumber } from "../../billsSlice";
import { getGstTaxList, getStateList } from "../../../gst/gstSlice";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { AddVendorModal } from "../../../vendorManagement/components/addVendorModal/AddVendorModal";
import { FormInputFieldsInRows } from "../../../../common/components/formInputFieldInRows/FormInputFieldInRows";
import { useModuleCustomFieldList } from "../../../../hooks/useModuleCustomFieldList";
import useGst from "../../../../hooks/useGst";
import _, { values } from "lodash";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";
import {
  billStatus,
  gstRegistrationTypes,
  TAX_SYSTEM,
} from "../../../../constants/constants";
import NotificationModal from "../../../../common/components/NotificationModal";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import CustomCheckbox from "../../../../common/components/CustomCheckbox/CustomCheckbox";
import { getVendorDetails } from "../../../vendorManagement/vendorAPI";
import { ConfirmationPopup } from "../../../../common/components/confirmationPopup/ConfirmationPopup";
import { CustomFieldInTransactions } from "../../../customFields/components/customFieldInTransactions/CustomFieldInTransactions";
import VendorSelect from "../../../../common/components/VendorSelect/VendorSelect";
import { SingleValue } from "react-select";

type Props = {
  defaultFormValues: BillDefaultValues;
  billFormValues: BillValues;
  purchaseDefault?: ObjectType;
  formErrors: any;
  handleVendorChange: (e: any) => void;
  checkBillNumberExist: (billNumber: string) => void;
  handleNewConversionDate: (value: string) => void;
  customFieldValue: ObjectType;
  customFieldRef: any;
  getCustomFieldData: (data: ObjectType) => void;
  setStateType?: any;
  fetchGstTaxList: (state_type: string, gst_registration_type: string) => void;
  setSupplyState?: any;
  initialStateType: string;
  gstRegistrationType: string;
  setIsStateChanged: Dispatch<SetStateAction<boolean>>;
  setRCMEnable: (rcmEnabled: boolean) => void;
};

const DefaultDetails: ForwardRefRenderFunction<BillDefaultRef, Props> = (
  props,
  ref
) => {
  type LocationProps = {
    consumerId?: string;
  };
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const { vendorId, editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const vendorList = useAppSelector(vendorListSelector);
  const locationState = location?.state as LocationProps;
  const consumerId = vendorId; //locationState?.consumerId;
  const subModulePermission = useSubModulePermission();
  const paymentTerms = useAppSelector(paymentTermsSelector);

  const [defaultFormValues, setDefaultFormValues] =
    useState<BillDefaultValues>(initialDefaultValues);
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [billNumberObj, setBillNumberObj] = useState<ObjectType>({});
  const [isTermsChange, setIsTermsChange] = useState<boolean>(true);
  const [dueDate, setDueDate] = useState<Date | null>(
    editId ? new Date(props.defaultFormValues.due_date) : new Date()
  );
  const defaultPaymentTermId = paymentTerms?.find(
    (term) => term.term_name === "Due on receipt"
  )?.id;
  const [billDate, setBillDate] = useState(new Date());
  const [stateOptions, setStateOptions] = useState<
    { label: string; value: string | number }[]
  >([]);
  const [
    sourceDestinationUpdateModalTriggered,
    setSourceDestinationUpdateModalTriggered,
  ] = useState<number>();
  const [taxAppliedFieldName, setTaxAppliedFieldName] = useState("");
  const billsCustomFields = useModuleCustomFieldList("Bills");
  const { isGstOrg } = useGst();
  const sourceDestinationUpdateModalRef = useRef<any>();
  const billDateTempRef = useRef<Date | null>(null);
  const fiscalYearFilter = useAppSelector(
    fiscalTearFilterForValidationSelector
  );
  const confirmationOpenRef = useRef<any>();

  useImperativeHandle(
    ref,
    () => ({
      defaultData: defaultFormValues,
      calculateDueDate: calculateDueDate,
      billNumber: joinForPayload(billNumberObj),
    }),
    [defaultFormValues, billNumberObj]
  );
  useMemo(() => {
    setDefaultFormValues({
      ...defaultFormValues,
      ...props.defaultFormValues,
    });
    setDueDate(new Date(props.defaultFormValues.due_date));
    if (editId) {
      if (props.defaultFormValues.payment_term_id === null) setIsCustom(true);
      const transaction_obj = splitTransactionNumber(
        props.defaultFormValues.bill_number
      );
      const billNum_obj = {
        bill_prefix: transaction_obj["prefix"],
        bill_number: transaction_obj["number"],
      };
      setBillNumberObj(billNum_obj);
      // fetchGstTaxList(defaultFormValues.source_of_supply, defaultFormValues.destination_of_supply )
    }
  }, [props.defaultFormValues]);

  // useEffect(() => {
  //   if (props.defaultFormValues.payment_term_id)
  //     calculateDueDate(
  //       props.defaultFormValues.payment_term_id,
  //       new Date(props.defaultFormValues.bill_date)
  //     );
  // }, [props.defaultFormValues.payment_term_id]);

  useEffect(() => {
    if (consumerId) {
      defaultFormValues.vendor_id = consumerId;
      let vendor = vendorList
        .filter((vendor) => vendor.id === Number(consumerId))
        .map((vendor) => ({
          label: vendor.vendor_display_name,
          value: vendor.id,
        }))[0];
      if (vendor) handleVendorChange(vendor);
    }
  }, [consumerId, vendorList]);

  useEffect(() => {
    if (!editId) billNumber();

    // fetch states list dropdown options
    if (isGstOrg) getStateListOptions();
  }, []);

  // useEffect(() => {
  //   handleDate(dueDate, "dueDate");
  // }, [dueDate]);
  // useEffect(() => {
  //   if (!editId) {
  //     handleBillDate(billDate, "bill");
  //   }
  // }, [billDate]);

  // useEffect(() => {
  //   if (!editId) {
  //     paymentTerms.map((term: PaymentTerms, index: number) => {
  //       if (term.term_name == "Due on receipt") {
  //         setDefaultFormValues((values) => ({
  //           ...values,
  //           payment_term_id: term.id,
  //         }));
  //         // setCustomerPaymentTerm(term.id);
  //       }
  //     });
  //   }
  // }, [paymentTerms]);

  useEffect(() => {
    sourceDestinationUpdateModalTriggered &&
      sourceDestinationUpdateModalRef.current?.openAlertModal &&
      props.gstRegistrationType !== gstRegistrationTypes.overseas &&
      props.gstRegistrationType !== gstRegistrationTypes.sez &&
      props.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
      sourceDestinationUpdateModalRef.current?.openAlertModal();
  }, [sourceDestinationUpdateModalTriggered]);

  $(function () {
    setTimeout(() => {
      $(document).on("focus", ".multiselect-container .searchBox", function () {
        $(this).parent().addClass("focused");
      });
      $(document).on("blur", ".multiselect-container .searchBox", function () {
        $(this).parent().removeClass("focused");
      });
    }, 50);
  });

  /**
   * Fetch state dropdwon options
   */
  const getStateListOptions = async () => {
    const result = await dispatch(
      getStateList({ orgId: currentUserInfo.organization_id })
    );
    const states = result.payload as string[];
    let statesArray = _.values(states);
    let stateOptionsTmp = statesArray.map((state) => {
      return {
        label: state,
        value: state,
      };
    });
    setStateOptions(stateOptionsTmp);
  };

  /**
   * Get bill number
   */
  const billNumber = async () => {
    const responseAction = await dispatch(
      getNewBillNumber(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBillNumberObj(response);
        let bill_number = joinForPayload(response);
        setDefaultFormValues((values) => ({
          ...values,
          bill_number: bill_number ? bill_number : "",
        }));
      }
    }
    dispatch(setLoaderState(false));
  };

  const getPrefix = (value: string) => {
    setBillNumberObj({
      ...billNumberObj,
      ["bill_prefix"]: value.toUpperCase(),
    });
  };

  const handleVendorChange = (e: { label: string; value: string | number }) => {
    if (props.purchaseDefault?.payment_term_id) {
      setDefaultFormValues((values) => ({
        ...values,
        payment_term_id: Number(props.purchaseDefault?.payment_term_id),
        due_date: getFormatedDate(
          getDueDate(
            Number(props.purchaseDefault?.payment_term_id),
            new Date(props.defaultFormValues.bill_date)
          )
        ),
        vendor_id: e.value.toString(),
        vendor_name: e.label.trim(),
      }));
    } else {
      setDefaultFormValues((values) => ({
        ...values,
        vendor_id: e.value.toString(),
        vendor_name: e.label.trim(),
        payment_term_id: Number(defaultPaymentTermId),
        due_date: defaultFormValues.bill_date,
      }));
    }
    props.handleVendorChange(e);
  };

  const handleTagChange = (selectedTag: never[]) => {
    setDefaultFormValues((values) => ({ ...values, tag_ids: selectedTag }));
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "bill_number") {
      props.checkBillNumberExist(
        billNumberObj["bill_prefix"] +
          "~" +
          value.replace("~", "").toUpperCase()
      );
      setDefaultFormValues({
        ...defaultFormValues,
        [name]:
          billNumberObj["bill_prefix"] +
          "~" +
          value.replace("~", "").toUpperCase(),
      });
      setBillNumberObj({
        ...billNumberObj,
        [name]: value.replace("~", "").toUpperCase(),
      });
    } else if (name === "payment_term_id" && value === "Custom") {
      setDefaultFormValues({ ...defaultFormValues, [name]: null });
    } else if (name === "reference_number") {
      if (value.length < 17) {
        setDefaultFormValues({ ...defaultFormValues, [name]: value });
      }
    } else {
      setDefaultFormValues({ ...defaultFormValues, [name]: value });
    }
  };

  const onStateChanged = async (
    name: string,
    option: { label: string; value: string }
  ) => {
    let newDefaultFormValues = { ...defaultFormValues, [name]: option };
    setDefaultFormValues(newDefaultFormValues);
    name === "destination_of_supply"
      ? setTaxAppliedFieldName("Destination")
      : name === "source_of_supply"
      ? setTaxAppliedFieldName("Source")
      : setTaxAppliedFieldName("");
    props.setSupplyState(option.value);
    if (
      newDefaultFormValues?.source_of_supply &&
      newDefaultFormValues?.destination_of_supply
    ) {
      if (
        props.initialStateType === "Inter State" &&
        newDefaultFormValues?.source_of_supply.value ===
          newDefaultFormValues?.destination_of_supply.value
      ) {
        setSourceDestinationUpdateModalTriggered(
          Math.floor(Math.random() * 1000)
        );
      }
      if (
        props.initialStateType === "Intra State" &&
        newDefaultFormValues?.source_of_supply.value !==
          newDefaultFormValues?.destination_of_supply.value
      ) {
        setSourceDestinationUpdateModalTriggered(
          Math.floor(Math.random() * 1000)
        );
      }

      let state_type =
        !newDefaultFormValues?.source_of_supply ||
        !newDefaultFormValues?.destination_of_supply
          ? ""
          : newDefaultFormValues?.source_of_supply.value ===
            newDefaultFormValues?.destination_of_supply.value
          ? "Intra State"
          : "Inter State";
      props.setIsStateChanged(true);
      props.setStateType(state_type);
      props.fetchGstTaxList(state_type, props.gstRegistrationType);
    } else {
      props.setStateType("");
    }
  };

  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        if (type === "bill") {
          setDefaultFormValues((values) => ({
            ...values,
            bill_date: "Invalid date",
          }));
        } else if (type === "dueDate") {
          setDefaultFormValues((values) => ({
            ...values,
            due_date: "Invalid date",
          }));
        }
      } else if (new Date(date) > new Date() && type !== "dueDate") {
        setDefaultFormValues((values) => ({
          ...values,
          bill_date: "Future date",
        }));
      } else {
        const dateFormated = getFormatedDate(date);
        setIsCustom(false);
        if (type === "bill") {
          if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
            if (defaultFormValues.payment_term_id !== "") {
              if (defaultFormValues.payment_term_id !== null) {
                calculateDueDate(defaultFormValues.payment_term_id, date);
              }
            }
            if (defaultFormValues.payment_term_id === null) {
              setIsCustom(true);
            }
            setDefaultFormValues((values) => ({
              ...values,
              bill_date: dateFormated,
            }));
            props.handleNewConversionDate(dateFormated);
          } else {
            setDefaultFormValues((values) => ({
              ...values,
              bill_date: "Invalid date",
            }));
          }
        } else if (type === "dueDate") {
          // if (
          //   new Date(fiscalYearFilter.startDate)
          //     .setHours(5, 30)
          //     .toLocaleString() >
          //   new Date(date).setHours(5, 30).toLocaleString()
          // ) {
          //   setDefaultFormValues((values) => ({
          //     ...values,
          //     due_date: "Old date",
          //   }));
          // } else
          if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
            // if (!isTermsChange && dateFormated !== defaultFormValues.due_date) {
            setIsCustom(true);
            setDefaultFormValues((values) => ({
              ...values,
              payment_term_id: null,
            }));
            // }
            setIsTermsChange(false);
            setDefaultFormValues((values) => ({
              ...values,
              due_date: dateFormated,
            }));
          } else {
            setDefaultFormValues((values) => ({
              ...values,
              due_date: "Invalid date",
            }));
          }
        }
      }
    }
  };

  const changeBillDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        setDefaultFormValues((values) => ({
          ...values,
          bill_date: "Invalid date",
          recalculate_exchange_rate: false,
        }));
        // } else if (
        //   new Date(fiscalYearFilter.startDate).setHours(5, 30).toLocaleString() >
        //   new Date(date).setHours(5, 30).toLocaleString()
        // ) {
        //   setDefaultFormValues((values) => ({
        //     ...values,
        //     bill_date: "Old date",
        //   }));
      } else {
        const dateFormated = getFormatedDate(date);
        setIsCustom(false);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          if (defaultFormValues.payment_term_id !== "") {
            if (defaultFormValues.payment_term_id !== null) {
              calculateDueDate(defaultFormValues.payment_term_id, date);
            }
          }
          if (defaultFormValues.payment_term_id === null) {
            setIsCustom(true);
          }
          setDefaultFormValues((values) => ({
            ...values,
            bill_date: dateFormated,
            recalculate_exchange_rate: true,
          }));
          let conversionDate =
            new Date(dateFormated) > new Date()
              ? getFormatedDate(new Date())
              : dateFormated;
          props.handleNewConversionDate(conversionDate);
        } else {
          setDefaultFormValues((values) => ({
            ...values,
            bill_date: "Invalid date",
            recalculate_exchange_rate: false,
          }));
        }
      }
    }
  };

  const handleBillDate = (date: Date | null, type: string) => {
    if (
      ((editId && defaultFormValues.bill_status === billStatus.partiallyPaid) ||
        defaultFormValues.bill_status === billStatus.closed ||
        defaultFormValues.bill_status.includes(billStatus.overdue)) &&
      date
    ) {
      if (fiscalYearFilter.startDate > getFormatedDate(date || undefined)) {
        ErrorToaster(
          "Invalid Transaction Date: Transaction date must be within the current fiscal year"
        );
        setDefaultFormValues((prevValue) => {
          return {
            ...prevValue,
            bill_date: defaultFormValues.bill_date,
          };
        });
        return;
      } else {
        setDefaultFormValues((prevValue) => {
          return {
            ...prevValue,
            bill_date: defaultFormValues.bill_date,
          };
        });
        billDateTempRef.current = date;
        confirmationOpenRef.current &&
          confirmationOpenRef.current.openAlertModal();
        return;
      }
    } else {
      changeBillDate(date, type);
    }
  };
  const handleExchangeRateCancel = (date: Date | null, type: string) => {
    if (
      ((editId && defaultFormValues.bill_status === billStatus.partiallyPaid) ||
        defaultFormValues.bill_status === billStatus.closed ||
        defaultFormValues.bill_status.includes(billStatus.overdue)) &&
      date
    ) {
      setDefaultFormValues((prevValue) => {
        return {
          ...prevValue,
          bill_date: getFormatedDate(date),
        };
      });
      if (moment(getFormatedDate(date), "YYYY-MM-DD", true).isValid()) {
        if (defaultFormValues.payment_term_id !== "") {
          if (defaultFormValues.payment_term_id !== null) {
            calculateDueDate(defaultFormValues.payment_term_id, date);
          }
        }
      }
    }
  };
  const getDueDate = (termId: string | number, billDate: Date) => {
    let selectedTermId = Number(termId);
    let selectedPaymentTerm = paymentTerms.filter(
      (tag) => tag.id === selectedTermId
    )[0];
    if (selectedPaymentTerm) {
      if (selectedPaymentTerm.term_name === "Due on receipt") {
        return billDate;
      } else if (selectedPaymentTerm.term_name === "Due end of month") {
        return new Date(billDate.getFullYear(), billDate.getMonth() + 1, 0);
      } else if (selectedPaymentTerm.term_name === "Due end of next month") {
        return new Date(billDate.getFullYear(), billDate.getMonth() + 2, 0);
      } else {
        let termDays = selectedPaymentTerm.term_days;
        if (termDays) {
          billDate.setDate(billDate.getDate() + Number(termDays));
          return billDate;
        } else {
          return billDate;
        }
      }
    }
  };
  /**
   * Function to calculate the due date of the bill
   */
  const calculateDueDate = (termId: string | number, billDate: Date) => {
    let currentDate = new Date();
    billDate = billDate ? billDate : currentDate;
    if (termId) {
      setIsTermsChange(false);
      if (termId === "Custom") {
        setDueDate(null);
        setDefaultFormValues({
          ...defaultFormValues,
          payment_term_id: null,
          due_date: "",
        });
      } else {
        setIsTermsChange(true);
        let selectedTermId = Number(termId);
        let selectedPaymentTerm = paymentTerms.filter(
          (tag) => tag.id === selectedTermId
        )[0];
        if (selectedPaymentTerm) {
          if (selectedPaymentTerm.term_name === "Due on receipt") {
            setDueDate(billDate);
            setDefaultFormValues({
              ...defaultFormValues,
              due_date: getFormatedDate(billDate),
              payment_term_id: selectedTermId,
            });
          } else if (selectedPaymentTerm.term_name === "Due end of month") {
            setDueDate(
              new Date(billDate.getFullYear(), billDate.getMonth() + 1, 0)
            );
            setDefaultFormValues({
              ...defaultFormValues,
              due_date: getFormatedDate(
                new Date(billDate.getFullYear(), billDate.getMonth() + 1, 0)
              ),
              payment_term_id: selectedTermId,
            });
          } else if (
            selectedPaymentTerm.term_name === "Due end of next month"
          ) {
            setDueDate(
              new Date(billDate.getFullYear(), billDate.getMonth() + 2, 0)
            );
            setDefaultFormValues({
              ...defaultFormValues,
              due_date: getFormatedDate(
                new Date(billDate.getFullYear(), billDate.getMonth() + 2, 0)
              ),
              payment_term_id: selectedTermId,
            });
          } else {
            let termDays = selectedPaymentTerm.term_days;
            if (termDays) {
              billDate.setDate(billDate.getDate() + Number(termDays));
              setDueDate(billDate);
              setDefaultFormValues({
                ...defaultFormValues,
                due_date: getFormatedDate(billDate),
                payment_term_id: selectedTermId,
              });
            } else {
              setDueDate(billDate);
            }
          }
        }
      }
    } else {
      setDueDate(currentDate);
    }
  };

  // const handlePaymentTermChange = (e: any) => {
  //   if (e.value) {
  //     let value = e.value;
  //     if (e.value === "Custom") {
  //       value = null;
  //     }
  //     setDefaultFormValues((values) => ({ ...values, payment_term_id: value }));
  //   }
  // };
  // useEffect(() => {
  //   if (editId) {
  //     if (props.defaultFormValues.payment_term_id === null) {
  //       setDefaultFormValues((values) => ({
  //         ...values,
  //         payment_term_id: null,
  //       }));
  //       setIsCustom(true);
  //     } else {
  //       setDefaultFormValues((values) => ({
  //         ...values,
  //         payment_term_id: props.defaultFormValues.payment_term_id,
  //       }));
  //       setIsCustom(false);
  //     }
  //   }
  // }, [props.defaultFormValues.payment_term_id]);
  const checkVendorSelectIsDisabled = () => {
    if (editId) {
      return !props.defaultFormValues.is_mutable;
    }
    return false;
  };
  const notificationModalMessage =
    billDateTempRef.current &&
    `If you select a ${
      getFormatedDate(billDateTempRef.current) > getFormatedDate(new Date())
        ? "future"
        : getFormatedDate(billDateTempRef.current) < getFormatedDate(new Date())
        ? "past"
        : "current"
    } date, the exchange rate as of ${
      getFormatedDate(billDateTempRef.current) < getFormatedDate(new Date())
        ? "that day"
        : "today"
    } will be applied to this transaction. Would you like to recalculate the item rates based on the ${
      getFormatedDate(billDateTempRef.current) < getFormatedDate(new Date())
        ? "historical"
        : "current"
    } exchange rate?`;
  const rcmEnableChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let rcmEnabled: boolean = e.target.checked;
    setDefaultFormValues((prevValue) => {
      return {
        ...prevValue,
        rcm_enabled: rcmEnabled,
      };
    });
    props.setRCMEnable(rcmEnabled);
  };
  return (
    <section className="form-top-section">
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="customer" className="required">
              Vendor Name
            </label>
            <AddVendorModal />
          </div>
          <div>
            <VendorSelect
              vendorId={defaultFormValues.vendor_id}
              vendorName={defaultFormValues.vendor_name}
              error={props.formErrors.vendor_name}
              onChange={(option: any) => {
                handleVendorChange(option)
              }}
              isDisabled={checkVendorSelectIsDisabled()}
            />
            <span className="error">{props.formErrors.vendor_name}</span>
          </div>
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="bill_number" className="required">
            Bill Number
          </label>
          <br />
          <PrefixInput
            prefix={defaultFormValues.bill_number.split("~")[0]}
            getPrefix={getPrefix}
            name="bill_number"
            id="bill_number"
            error={
              props.formErrors.bill_number ? props.formErrors.bill_number : ""
            }
            className="form-control border-end-0"
            placeholder="Enter bill number"
            value={defaultFormValues.bill_number.split("~")[1]}
            disabled={false}
            maxLength={11}
            onChange={handleChange}
          />
          <span className="error">{props.formErrors.bill_number}</span>
        </div>
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="bill_date">Bill Date</label>
          <br />
          <div className="date-selector-wrapper">
            <CustomDatepicker
              date={new Date(defaultFormValues.bill_date)}
              handleDate={handleBillDate}
              type="dueDate"
              error={props.formErrors.bill_date ? "error" : ""}
              id="bill_date"
              zIndex={0}
              enableFutureDate={true}
              module="Bills"
            />
          </div>
          <span className="error">{props.formErrors.bill_date}</span>
        </div>
        <FormInputField
          type="text"
          name="reference_number"
          id="ref_no"
          className={
            props.formErrors.reference_number
              ? "form-control error"
              : "form-control"
          }
          label="Reference Number"
          placeholder="Enter reference number"
          maxLength={16}
          value={defaultFormValues.reference_number}
          onChange={handleChange}
          error={props.formErrors.reference_number}
        />
      </div>
      <div className="row custom-row">
        {/* <div className="col-12 col-lg-4 mw-325">
          <span className="align-wrapper">
            <div className="link-label-grouped">
              <label htmlFor="tags">Tags</label>
              {subModulePermission.includes("Tags") ? <TagsModal /> : null}
            </div>
          </span>
          <TagInput
            tagIds={defaultFormValues.tag_ids}
            handleChange={handleTagChange}
          />
        </div> */}
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="terms">Terms</label>
            {subModulePermission.includes("Payments Terms") ? (
              <PaymentTermsModal />
            ) : null}
          </div>
          <PaymentTermSelect
            paymentTermId={defaultFormValues.payment_term_id}
            haveCustom={true}
            isCustom={defaultFormValues.payment_term_id === null}
            onChange={(e: any) => {
              // handlePaymentTermChange(e);
              calculateDueDate(e.value, new Date(defaultFormValues.bill_date));
            }}
          />
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="due_date">Due Date</label>
          <div id="date-two" className="date-selector-wrapper">
            <CustomDatepicker
              date={dueDate}
              handleDate={handleDate}
              type="dueDate"
              error={props.formErrors.due_date ? "error" : ""}
              id="due_date"
              zIndex={0}
              enableFutureDate={true}
              minDate={new Date(defaultFormValues.bill_date)}
            />
          </div>
          <span className="error">{props.formErrors.due_date}</span>
        </div>
      </div>

      <div
        className={`row custom-row  ${
          billsCustomFields.length ? "mb-0" : "mb-4"
        }`}
      >
        {isGstOrg &&
          props.gstRegistrationType !== gstRegistrationTypes.overseas && (
            <FormSelectField
              key={0}
              name="source_of_supply"
              id="source_of_supply"
              label="Source of Supply"
              options={stateOptions}
              wrapperClass="mw-325"
              className="state-select form-select custom-select"
              value={
                defaultFormValues?.source_of_supply
                  ? defaultFormValues?.source_of_supply
                  : ""
              }
              onChange={(option: any) => {
                onStateChanged("source_of_supply", option);
              }}
              styles={customSelectStyle}
              placeholder={"Choose State"}
              isSearchable={true}
              isDisabled={false}
              error={props.formErrors.source_of_supply}
              isRequired={true}
              isOnlyInRow={false}
            />
          )}
        {isGstOrg && (
          <>
            <FormSelectField
              key={1}
              name="destination_of_supply"
              id="destination_of_supply"
              label="Destination of Supply"
              options={stateOptions}
              wrapperClass="mw-325"
              className="state-select form-select custom-select"
              value={
                defaultFormValues?.destination_of_supply
                  ? defaultFormValues?.destination_of_supply
                  : ""
              }
              onChange={(option: any) => {
                onStateChanged("destination_of_supply", option);
              }}
              styles={customSelectStyle}
              placeholder={"Choose State"}
              isSearchable={true}
              isDisabled={false}
              error={props.formErrors.destination_of_supply}
              isRequired={true}
              isOnlyInRow={false}
            />
          </>
        )}
      </div>
      <div className={`${isGstOrg ? "" : "mb-4"}`}>
        <CustomFieldInTransactions
          getFieldValues={props.getCustomFieldData}
          customFieldsArray={billsCustomFields}
          initialValues={props.customFieldValue}
          ref={props.customFieldRef}
          editId={editId ? Number(editId) : null}
          marginTopClass="mt-0"
        />
      </div>
      {currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
      isGstOrg &&
      props.gstRegistrationType !== gstRegistrationTypes.composition &&
      props.gstRegistrationType !== gstRegistrationTypes.deemedExp ? (
        <div className="row custom-row mt-3">
          <div className="col-12 ps-3 pe-3">
            <CustomCheckbox
              checkboxName="rcm_enabled"
              checkboxId="rcm_enabled"
              isDisabled={false}
              isChecked={
                defaultFormValues.rcm_enabled
                  ? defaultFormValues.rcm_enabled
                  : false
              }
              changeHandler={rcmEnableChangeHandler}
            >
              <label
                className="form-check-label fw-normal"
                htmlFor="rcm_enabled"
              >
                This transaction is applicable for reverse charge
              </label>
            </CustomCheckbox>
          </div>
        </div>
      ) : (
        ""
      )}
      <AlertModalPopup
        message={`We will update the taxes applied to the items in this transaction as you've changed the ${taxAppliedFieldName} Of Supply`}
        modalTitle="Alert!"
        isCloseButtonEnabled={true}
        isSubmitEnabled={false}
        openRef={sourceDestinationUpdateModalRef}
      />
      <ConfirmationPopup
        popupButtonContent={<></>}
        buttonText={{
          submit: "Yes",
          cancel: "No",
        }}
        popupTitle={""}
        confirmationOpenRef={confirmationOpenRef}
        bodyWrapperClass={""}
        onSubmit={() => changeBillDate(billDateTempRef.current, "bill")}
        onCancel={() =>
          handleExchangeRateCancel(billDateTempRef.current, "bill")
        }
      >
        {notificationModalMessage}
      </ConfirmationPopup>
    </section>
  );
};

export default React.memo(forwardRef(DefaultDetails));
