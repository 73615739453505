import { useMemo } from "react";
import useGst from "../../../../hooks/useGst";
import { useState } from "react";
import { useCommonData } from "../../../../home/paymentsReceived/components/paymentReceivedForm/imports";
import DetailTotalSection from "../../detailsPage/DeatailsTotalSection";
import DetailsTableContainerSubscription from "../../detailsPage/DetailsTableContainerSubscription";
import { Container } from "../../htmlTags/Container";
import { Span } from "../../htmlTags/Span";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { TAX_SYSTEM, tdsApplyLevel } from "../../../../constants/constants";
import TaxColumn from "../../detailsPage/TaxColumn";
import { SubscriptionInvoiceBreakdownSkeletonLoader } from "../../../../home/subscription/components/view/SubscriptionInvoiceBreakdownSkeletonLoader";
import { coustomCase } from "../../../../../helpers/planHelper";
import { SaveButton, SecondaryButton } from "../../button";
import { DateFormatHandler } from "../../../../../helpers/helper";

function FutureRenewalPreviewContent({
  invoiceBreakdown,
  ...props
}: {
  invoiceBreakdown: any;
  currencyCode: string;
  tdsLevel: string;
  isPlanOnly: boolean;
  isDetailPage?: boolean;
}) {
  const { currentUserInfo, orgCurrencyList, dispatch } = useCommonData();
  const [isLoading, setIsLoading] = useState(true);
  const { isGstOrg } = useGst();
  const isUAEOrg =
    currentUserInfo.organization_tax_system === TAX_SYSTEM.UAE_TAX_SYSTEM;
  const [isEasterEggClicked, setIsEasterEggClicked] = useState(false);

  function convertToTimeUnit(unit: string): string {
    switch (unit) {
      case "DAILY":
        return "days";
      case "WEEKLY":
        return "weeks";
      case "MONTHLY":
        return "months";
      case "YEARLY":
        return "years";
      default:
        return unit;
    }
  }
  function formatFrequency(frequency: string) {
    if (!frequency) return "";
    let formattedStr = frequency
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return formattedStr;
  }
  function convertToNormalText(str: string) {
    if (!str) return "";
    let words = str.toLowerCase().split("_");
    return words.join(" ");
  }
  const columnsForPlanAddon = useMemo(() => {
    const columns = [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <Span className="item-bold">{value}</Span>;
        },
      },
      // Conditional HSN/SAC Code column
      ...(isGstOrg && !isUAEOrg
        ? [
            {
              Header: "Hsn/sac code",
              accessor: "show.hsnCode",
              className: "hsncode text-nowrap",
            },
          ]
        : []),
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap left-align",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <Span>{value.discount}</Span>
              <Span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </Span>
            </>
          );
        },
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return <TaxColumn values={value} currencyCode={props.currencyCode} />;
        },
      },
      ...(!isUAEOrg
        ? [
            {
              Header: "TDS",
              accessor:
                props.tdsLevel === tdsApplyLevel.LINE_ITEM_LEVEL
                  ? "show.item"
                  : "TDS",
              className: "tds text-nowrap",
              Cell: ({ cell: { value } }: any) => {
                return (
                  <>
                    <Span>
                      {props.currencyCode}{" "}
                      {NumberFormat(
                        Number(value.tds_total),
                        props.currencyCode,
                        orgCurrencyList
                      )}
                    </Span>
                    <Span className="actual-value d-block w-100 clear-both">
                      {value.tds_name}{" "}
                      {value.default_tds_percentage
                        ? "(" + value.default_tds_percentage + "%)"
                        : ""}
                    </Span>
                    {value.tds_percentage !== 0 &&
                    value.tds_percentage !== value.default_tds_percentage ? (
                      <Span className="current-value  d-block w-100 clear-both">
                        Updated Rate {`(${value.tds_percentage}%)`}
                      </Span>
                    ) : null}
                  </>
                );
              },
            },
          ]
        : []),
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ];

    return columns;
  }, [props.currencyCode, isGstOrg]);
  return (
    <Container
      className={`subscription-invoice-preview-body-wrapper invoice-preview-body-wrapper ${
        props.isDetailPage
          ? "subscription-invoice-preview-body-wrapper-detail-page"
          : ""
      }`}
    >
      {false ? (
        <SubscriptionInvoiceBreakdownSkeletonLoader
          itemCount={props.isPlanOnly ? 1 : 2}
        />
      ) : (
        <Container className="">
          <Container className="table-wrap subscription-invoice-details">
            {invoiceBreakdown.plan_details &&
              invoiceBreakdown.plan_details.length > 0 && (
                <Container
                  className={`subscription-items-name-wrappper ${
                    props.isDetailPage
                      ? "future-renewal-detail-page-margin-left"
                      : ""
                  }`}
                >
                  Plans
                </Container>
              )}
            {invoiceBreakdown.plan_details &&
              invoiceBreakdown.plan_details.length > 0 &&
              invoiceBreakdown?.plan_details?.map((plan: any) => {
                return (
                  <>
                    <Container className="subscription-items-description-table-wrappper">
                      <Container
                        className={`plan-description subscription-items-description-wrappper ${
                          props.isDetailPage
                            ? "future-renewal-detail-page-margin-left"
                            : ""
                        }`}
                      >
                        <h6 className="item-bold">{plan.plan_name}</h6>

                        <Container className="plan-tag">
                          <Span>
                            Frequency:{" "}
                            {plan.custom_repeat_count &&
                            plan.custom_repeat_count > 0
                              ? ` Every ${
                                  plan.custom_repeat_count
                                }  ${convertToTimeUnit(plan.frequency)}`
                              : coustomCase(plan.frequency)}{" "}
                            | Pricing model:{" "}
                            {formatFrequency(plan.pricing_model)}
                          </Span>
                        </Container>
                        <Container>
                          <Span>
                            Invoicing period:{" "}
                            {DateFormatHandler(
                              invoiceBreakdown?.invoicing_period.split(" - ")[0]
                            )}{" "}
                            -{" "}
                            {DateFormatHandler(
                              invoiceBreakdown?.invoicing_period.split(" - ")[1]
                            )}
                          </Span>
                        </Container>
                        {!!plan.description ? (
                          <Container>
                            <Span>Description: {plan.description}</Span>
                          </Container>
                        ) : null}
                      </Container>

                      <DetailsTableContainerSubscription
                        columns={columnsForPlanAddon}
                        data={plan.items}
                        currency_code={props.currencyCode}
                        haveColumnClassName={true}
                        className="table inv-preview-table subscription-data-view-table table-with-border td-with-word-break future-renewal-detail-page-item-border"
                        hiddenColumns={["TDS"]}
                        pricingModel={plan.pricing_model}
                        component="invoice"
                      />
                    </Container>
                  </>
                );
              })}
            {invoiceBreakdown.addon_details &&
              invoiceBreakdown.addon_details.length > 0 && (
                <Container
                  className={`subscription-items-name-wrappper ${
                    props.isDetailPage
                      ? "future-renewal-detail-page-margin-left"
                      : ""
                  }`}
                >
                  Addons
                </Container>
              )}
            {invoiceBreakdown.addon_details &&
              invoiceBreakdown.addon_details.length > 0 &&
              invoiceBreakdown?.addon_details?.map((addon: any) => {
                return (
                  <>
                    <Container className="subscription-items-description-table-wrappper ">
                      <Container
                        className={`plan-description subscription-items-description-wrappper ${
                          props.isDetailPage
                            ? "future-renewal-detail-page-margin-left"
                            : ""
                        }`}
                      >
                        <h6 className="item-bold">{addon.addon_name}</h6>

                        <Container className="plan-tag">
                          <Span>
                            Frequency:{" "}
                            {addon.custom_repeat_count &&
                            addon.custom_repeat_count > 0
                              ? ` Every ${
                                  addon.custom_repeat_count
                                }  ${convertToTimeUnit(addon.frequency)}`
                              : coustomCase(addon.frequency)}{" "}
                            | Pricing model:{" "}
                            {formatFrequency(addon.pricing_model)}
                          </Span>
                        </Container>
                        <Container>
                          <Span>
                            Invoicing period:{" "}
                            {DateFormatHandler(
                              invoiceBreakdown?.invoicing_period.split(" - ")[0]
                            )}{" "}
                            -{" "}
                            {DateFormatHandler(
                              invoiceBreakdown?.invoicing_period.split(" - ")[1]
                            )}
                          </Span>
                        </Container>
                        {!!addon.description ? (
                          <Container>
                            <Span>Description: {addon.description}</Span>
                          </Container>
                        ) : null}
                      </Container>

                      <DetailsTableContainerSubscription
                        columns={columnsForPlanAddon}
                        data={addon.items}
                        currency_code={props.currencyCode}
                        haveColumnClassName={true}
                        className="table inv-preview-table subscription-data-view-table table-with-border td-with-word-break "
                        hiddenColumns={["TDS"]}
                        pricingModel={addon.pricing_model}
                        component="invoice"
                      />
                    </Container>
                  </>
                );
              })}
            {invoiceBreakdown.charge_details &&
              invoiceBreakdown.charge_details.length > 0 && (
                <Container
                  className={`subscription-items-name-wrappper ${
                    props.isDetailPage
                      ? "future-renewal-detail-page-margin-left"
                      : ""
                  }`}
                >
                  Charges
                </Container>
              )}
            {invoiceBreakdown.charge_details &&
              invoiceBreakdown.charge_details.length > 0 &&
              invoiceBreakdown?.charge_details?.map((charge: any) => {
                return (
                  <>
                    {" "}
                    <Container className="subscription-items-description-table-wrappper">
                      <Container
                        className={`plan-description subscription-items-description-wrappper ${
                          props.isDetailPage
                            ? "future-renewal-detail-page-margin-left"
                            : ""
                        }`}
                      >
                        <h6 className="item-bold">{charge.charge_name}</h6>

                        <Container className="plan-tag">
                          <Span>
                            Pricing model:{" "}
                            {formatFrequency(charge.pricing_model)}
                          </Span>
                        </Container>
                        <Container>
                          <Span>
                            Invoicing period:{" "}
                            {DateFormatHandler(
                              invoiceBreakdown?.invoicing_period.split(" - ")[0]
                            )}{" "}
                            -{" "}
                            {DateFormatHandler(
                              invoiceBreakdown?.invoicing_period.split(" - ")[1]
                            )}
                          </Span>
                        </Container>
                        {!!charge.description ? (
                          <Container>
                            <Span>Description: {charge.description}</Span>
                          </Container>
                        ) : null}
                      </Container>

                      <DetailsTableContainerSubscription
                        columns={columnsForPlanAddon}
                        data={charge.items}
                        currency_code={props.currencyCode}
                        haveColumnClassName={true}
                        className="table inv-preview-table subscription-data-view-table table-with-border td-with-word-break "
                        hiddenColumns={["TDS"]}
                        pricingModel={charge.pricing_model}
                        component="invoice"
                      />
                    </Container>
                  </>
                );
              })}
            {invoiceBreakdown.coupons && invoiceBreakdown.coupons.length > 0 && (
              <div
                style={{
                  fontSize: "larger",
                  fontWeight: "bold",
                  paddingTop: "19px",
                  color: "var(--charcoal-grey)",
                }}
                className={`${
                  props.isDetailPage
                    ? "future-renewal-detail-page-margin-left"
                    : ""
                }`}
              >
                Coupons
              </div>
            )}
            {invoiceBreakdown.coupons &&
              invoiceBreakdown.coupons.length > 0 &&
              invoiceBreakdown?.coupons?.map((coupon: any) => {
                return (
                  <>
                    <div className="plan-description coupon-description-container coupons-content-wrapper">
                      <div
                        className={`${
                          props.isDetailPage
                            ? "future-renewal-detail-page-margin-left"
                            : ""
                        }`}
                      >
                        <h6 className="item-bold">{coupon.name}</h6>
                        <div className="plan-tag">
                          <span>
                            Applicable {convertToNormalText(coupon.duration)}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`coupon-discount-amount-container ${
                          props.isDetailPage
                            ? "future-renewal-detail-page-margin-right"
                            : ""
                        }`}
                      >
                        {coupon.discount}
                      </div>
                    </div>
                  </>
                );
              })}
          </Container>
          <Container className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
            <Container className="thankyou-note w-50 d-flex align-items-end">
              <Container className="note-container">
                <></>
              </Container>
            </Container>
          </Container>
        </Container>
      )}
    </Container>
  );
}
export default FutureRenewalPreviewContent;
