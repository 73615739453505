import { useEffect, useRef, useState } from "react";
import ListHeader from "../../../../common/components/list/ListHeader";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import PlanTableContainer from "./planTable/PlanTableContainer";
import useListData from "../../../../hooks/useListData";
import Pagination from "../../../../common/components/table/Pagination";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import PlanDelete from "../PlanDelete";
import PlanStopResume from "../PlanStopResume";
import PlanVoid from "../PlanVoid";
import { DeleteInitialData, PlanStopResumeAction } from "../../planType";
import { deleteInitialData } from "../../PlanStateInitialization";
import { getPlanTransactionLimitStatus } from "../../planSlice";
import { Transactionlimit } from "../../../../../types";
import TransactionLimitModal from "../../../../common/components/TransactionLimitModal";

export default function PlanList() {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { planRolePermission } = usePermissionHandler();
  const { page, pageCount, itemsPerPage, totalList } = useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const filterRef = useRef<any>([]);
  const listRef = useRef<any>([]);
  const [deleteAction, setDeleteAction] =
    useState<DeleteInitialData>(deleteInitialData);
  const [voidAction, setVoidAction] = useState<any>({ id: -1, planName: "" });
  const [statusChangeOnAction, setStatusChangeOnAction] =
    useState<PlanStopResumeAction>({ id: -1, planName: "", triggerAction: "" });
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  useEffect(() => {
    fetchTransactionLimit();
    setIsFilterApplied(false);
  }, [currentUserInfo?.organization_id]);

  const handleApplyFilter = (value: boolean) => {
    setIsFilterApplied(value);
  };

  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getPlanTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };

  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const refreshList = () => {
    //  listRef.current.fetchRecurringInvoiceList();
    filterRef.current.refreshFilter();
    if (listRef.current.fetchRecurringInvoiceList)
      listRef.current.fetchRecurringInvoiceList();
  };
  const deleteListData = (
    actionType: string,
    id: number,
    planName: string,
    planExistsSubscription: boolean
  ) =>
    setDeleteAction({
      actionType: actionType,
      id: id,
      planName: planName,
      planExistsSubscription: planExistsSubscription,
    });

  const voidListData = (id: number, planName: string) => {
    $(".dropdown-ctrl").click();
    setVoidAction({ id, planName });
  };

  const stopResumeListData = (
    id: number,
    planName: string,
    triggerAction: boolean
  ) => {
    $(".dropdown-ctrl").click();
    setStatusChangeOnAction({
      id: id,
      planName: planName,
      triggerAction: triggerAction ? "Stop" : "Resume",
    });
  };
  return (
    <>
      <div className="plan-list card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Plans"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="Create Plan"
          isFiscalYearFilterRequired={false}
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/plan/create");
          }}
          haveFilter={true}
          filterType="Plan"
          applyFilter={handleApplyFilter}
          roles={planRolePermission}
          ref={filterRef}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
        <PlanTableContainer
            transactionLimit={transactionLimit}
            deleteListData={deleteListData}
            voidListData={voidListData}
            stopResumeListData={stopResumeListData}
            ref={listRef}
            isFilterApplied={isFilterApplied}
            initializeFilter={refreshList}
          />
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <PlanDelete
        deletePlanId={deleteAction.id}
        deletePlanName={deleteAction.planName}
        deleteActionType={deleteAction.actionType}
        planExistsSubscription={deleteAction.planExistsSubscription}
        refreshPlan={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <PlanStopResume
        planId={statusChangeOnAction.id}
        planName={statusChangeOnAction.planName}
        triggerAction={
          statusChangeOnAction.triggerAction.toLowerCase() === "stop"
            ? "Resume"
            : "Stop"
        }
        refreshPlan={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <PlanVoid
        planId={voidAction.id}
        planName={voidAction.planName}
        refreshPlan={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <TransactionLimitModal limit={transactionLimit.limit_count} />
    </>
  );
}
