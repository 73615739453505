import React from "react";
import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import useListData from "../../../hooks/useListData";
import { usePaginationDispatch } from "../../../hooks/usePaginationDispatch";
import { getReminderEmailFrequencyList } from "../reminderEmailSlice";

export const useFetchEmailFrequencyList = () => {
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const { currentUserInfo, dispatch } = useCommonData();
  const getEmailFrequency = async (
    setEmailFrequencyList: React.SetStateAction<any>
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getReminderEmailFrequencyList({
        orgId: currentUserInfo.organization_id,
        page: page,
        itemsPerPage: itemsPerPage
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      setEmailFrequencyList(response.reminder_rules || []);
      setTotalList(response.total || 0);
      if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
      setEndCount(
        itemsPerPage * page < response.total
          ? itemsPerPage * page
          : response.total
      );
      dispatch(setLoaderState(false));
    } else {
      setEmailFrequencyList([]);
      setTotalList(0);
    }
  };
  return { getEmailFrequency };
};
