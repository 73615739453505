import {
  CurrentUserValues,
  OrganizationTreeValues,
} from "../../../../../types";
import { DeleteIcon } from "../../../../common/components/customSvgIcons/deleteIcon";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { colors } from "../../../../constants/colors";
import { SubOrgAddModal } from "../subOrgForm/subOrgAddModal";

type ListActionType = {
  organization: OrganizationTreeValues;
  currentUserInfo: CurrentUserValues;
  deleteSubOrg: (id: number, name: string) => void;
  fetchHierarchyList: () => Promise<any>;
};
export const ListAction = ({
  organization,
  currentUserInfo,
  deleteSubOrg,
  fetchHierarchyList,
}: ListActionType) => {
  return (
    <KebabMenu>
      <li>
        <SubOrgAddModal
          isListButton={false}
          currentOrgId={currentUserInfo.organization_id}
          parentOrgId={organization.organization_details.id}
          values={{
            country_id: null,
            state_id: null,
            currency: "",
            currency_id: "",
            name: "",
            transaction_exist: false,
            gst: "",
            fiscal_year: "",
            timezone:""
          }}
          fetchHierarchyList={fetchHierarchyList}
        />
      </li>
      <li>
        <SubOrgAddModal
          isListButton={false}
          currentOrgId={organization.organization_details.id}
          parentOrgId={organization.parent_id}
          values={organization.organization_details}
          editId={organization.organization_details.id}
          fetchHierarchyList={fetchHierarchyList}
        />
      </li>
      <li>
        <a
          className={`dropdown-item delete
                                  ${
                                    currentUserInfo.organization_id ===
                                      organization.organization_details.id ||
                                    organization.organization_details
                                      .transaction_exist
                                      ? "delete-disabled"
                                      : ""
                                  }`}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (
              currentUserInfo.organization_id !==
                organization.organization_details.id &&
              !organization.organization_details.transaction_exist
            ) {
              deleteSubOrg(
                organization.organization_details.id,
                organization.organization_details.name
              );
            }
          }}
        >
          <DeleteIcon color={colors.tomato} height="18px" width="18px" />
          <span>Delete</span>
        </a>
      </li>
    </KebabMenu>
  );
};
