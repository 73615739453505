import React from "react";
import { ObjectType } from "../../../../types";
import { useAppSelector } from "../../../../app/hooks";
import { orgCurrencyListSelector } from "../../commonSlice";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";

type Props = {
  values: ObjectType;
  currencyCode: string;
};

export default function TaxColumn(props: Props) {
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const isArray = Array.isArray(props.values);
  return (
    <>
      {props?.values?.gst_tax_name ? (
        <div className="tax-groups">
          <div>
            {props?.values?.gst_tax_name ? props?.values?.gst_tax_name : "-"}
            {props?.values?.tax_percentage
              ? "[" + props?.values?.tax_percentage + "%]"
              : ""}
          </div>
        </div>
      ) : !isArray || props?.values?.length === 0 ? (
        <div className="tax-groups">
          <div>{props?.currencyCode} 0 (0%)</div>
        </div>
      ) : props?.values?.length > 1 ? (
        <div className="tax-groups">
          {props?.values?.map((tax: any, index: number) => {
            return (
              <div key={index}>
                {props?.currencyCode}{" "}
                {tax?.item_tax_amount
                  ? NumberFormat(
                      Number(tax?.item_tax_amount),
                      props.currencyCode,
                      orgCurrencyList
                    )
                  : 0}{" "}
                {tax?.tax_code ? tax?.tax_code : ""} (
                {tax?.tax_percentage ? tax?.tax_percentage : 0}%)
              </div>
            );
          })}
        </div>
      ) : (
        props?.values?.map((tax: any, index: number) => {
          return (
            <div key={index}>
              {props?.currencyCode}{" "}
              {tax?.item_tax_amount
                ? NumberFormat(
                    Number(tax?.item_tax_amount),
                    props?.currencyCode,
                    orgCurrencyList
                  )
                : 0}{" "}
              {tax?.tax_code ? tax?.tax_code : ""} (
              {tax?.tax_percentage ? tax?.tax_percentage : 0}%)
            </div>
          );
        })
      )}
    </>
  );
}
