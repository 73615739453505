import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select, { SingleValue, components } from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useInView } from "react-intersection-observer";
import { currentUserSelector } from "../../commonSlice";
import { customSelectStyle } from "../SelectCustomStyle";
import "./VendorSelect.css";
import { colors } from "../../../constants/colors";
import { vendorsDropdownList } from "../../../home/vendorManagement/vendorSlice";

type Props = {
  vendorId: string | number;
  vendorName: string;
  error: string;
  onChange: (
    newValue: SingleValue<{ label: string; value: string | number }>
  ) => void;
  isDisabled: boolean;
  onBlur?: (e: any) => void;
  isModal?:boolean;

};

const VendorSelect = ({
  vendorId,
  vendorName,
  error,
  onChange,
  isDisabled,
  onBlur,
  isModal
}: Props) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [vendorListOptions, setVendorListOptions] = useState<
    { label: string; value: string | number }[]
  >([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    if (inView) {
      handleMenuScrollToBottom();
    }
  }, [inView]);
  const getSearchVendorsList = useCallback(
    async (query: string, pageNum: number, signal?: AbortSignal) => {
      try {
        const responseAction = await dispatch(
          vendorsDropdownList({
            orgId: currentUserInfo.organization_id,
            searchQuery: query,
            pageNum: pageNum,
            pageSize: 10,
            vendorId: vendorId ? Number(vendorId) : null,
            signal: signal,
          })
        );

        const response = responseAction.payload;
        if (response && response.vendors) {
          const newVendors = Array.isArray(response.vendors)
            ? response.vendors.map((vendor: any) => ({
                label: vendor.vendor_display_name,
                value: vendor.id,
                organization_name: vendor.organization_name,
                vendor_identification: vendor.import_id
              }))
            : [];
          let selectedVendor = {};
          setVendorListOptions((prevOptions) => {
            // Combine previous and new options
            if (vendorId) {
              let combined = [...prevOptions, ...newVendors];
              selectedVendor = combined.find(
                (vendor: any) => Number(vendor.value) === Number(vendorId)
              );
            }
            const combinedOptions = Object.keys(selectedVendor || {}).length
              ? [selectedVendor, ...prevOptions, ...newVendors]
              : [...prevOptions, ...newVendors];

            // Remove duplicates using a Set
            const uniqueOptions = combinedOptions.reduce((acc, current) => {
              const isDuplicate = acc.some(
                (item: any) =>
                  item.value === current.value && item.label === current.label
              );
              if (!isDuplicate) {
                acc.push(current);
              }
              return acc;
            }, [] as { label: string; value: string | number }[]);
            return uniqueOptions;
          });

          setHasMore(response.pagination.next);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to fetch vendors:", error);
      }
    },
    [
      currentUserInfo.organization_id,
      dispatch,
      vendorId,
    ]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getSearchVendorsList(searchQuery, 1, signal);
    return () => {
      controller.abort();
    };
  }, [searchQuery]);

  const handleVendorSearch = useCallback((query: string) => {
    setIsLoading(true);
    setSearchQuery(query);
    setPageNum(1);
    setVendorListOptions([]); // Reset options only when search query changes
  }, []);

  const handleMenuScrollToBottom = useCallback(async () => {
    if (hasMore && !isLoading) {
      await getSearchVendorsList(searchQuery, pageNum + 1);
      setPageNum((prevPageNum) => prevPageNum + 1);
    }
  }, [hasMore, pageNum, getSearchVendorsList, isLoading]);

  const LabelWithRoot = useMemo(() => {
    return (data: any) => {
      return (
        <div
          {...data.innerProps}
          className="custom-select-option-wrapper"
          style={{
            backgroundColor:
              Number(data.data.value) === Number(vendorId)
                ? colors.whisper
                : "white",
          }}
        >
          <span className="custom-select-option-account-name">
            {data.data.label}
          </span>
          {data.data.vendor_identification && (
            <div className="custom-select-option-account-sub-root">
              {data.data.vendor_identification}
            </div>
          )}
          {data.data.organization_name && (
            <div className="custom-select-option-account-root">
              {data.data.organization_name}
            </div>
          )}
        </div>
      );
    };
  }, [ vendorId]);
  const CustomMenuList = useMemo(() => {
    return (props: any) => {
      return (
        <components.MenuList {...props}>
          {props.children}
          <div className="temp-div" style={{ height: "10px" }} ref={ref}></div>
        </components.MenuList>
      );
    };
  }, [ vendorId]);

  return (
    <>
      <Select
        name="vendor"
        value={vendorId ? { label: vendorName, value: vendorId } : null}
        options={vendorListOptions}
        aria-label="Vendor Select"
        inputValue={searchQuery}
        className="custom-select vendor-select vendor_name"
        isDisabled={isDisabled}
        isSearchable={true}
        maxMenuHeight={200}
        placeholder="Choose vendor"
        onChange={(e) => {
          onChange(e);
        }}
        styles={customSelectStyle}
        menuPortalTarget={
          isModal === true
            ? undefined
            : document.body
        }
        onInputChange={handleVendorSearch}
        onMenuOpen={() => {
          setPageNum(1);
          setSearchQuery("");
          setHasMore(true);
          setMenuIsOpen(true);
          getSearchVendorsList("", 1);
        }}
        components={{ MenuList: CustomMenuList, Option: LabelWithRoot }}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
      />
    </>
  );
};

export default VendorSelect;
