import {
  useCommonData,
  useEffect,
  useState,
} from "../../../home/paymentsReceived/components/paymentReceivedForm/imports";
import {
  couponDetailsList,
  couponList,
} from "../../../home/coupon/couponSlice";
import InegratedDropdownComponent from "../integratedDropdownComponent/InegratedDropdownComponent";
import { ObjectType } from "../../../home/systemVoucher/components/types";
import { AddTagIcon, ErrorImg } from "../../../../assets/images";
import { colors } from "../../../constants/colors";
import { RemoveIcon } from "..";
import { toast } from "react-toastify";
import { subscriptionCouponSearchList } from "../../../home/subscription/subscriptionSlice";

function CouponDropdown(props: any) {
  const { currentUserInfo, dispatch } = useCommonData();
  const [couponsList, setCouponsList] = useState<ObjectType[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<any>([
    {
      label: null,
      value: null,
    },
  ]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  useEffect(() => {
    if (props.couponValueList.length > 0) {
      getCouponDetails(props.couponValueList);
      props.handleLineItemCouponIds(
        props.couponValueList.map((coupon: any) => coupon.coupon_id),
        "initial"
      );
    }
    getCouponList();
  }, []);

  const getCouponDetails = async (coupon: any[]) => {
    const selectedCouponList = coupon.map((coupon: any) => ({
      label: `${coupon.name} (${coupon.discount})`,
      value: coupon.coupon_id,
    }));
    setSelectedCoupon(selectedCouponList);
  };

  const getCouponList = async () => {
    if (total > couponsList.length) {
      const responseAction = await dispatch(
        subscriptionCouponSearchList({
          page: page,
          itemsPerPage: 10,
          orgId: currentUserInfo.organization_id,
          couponIds: [],
          searchTerm: "",
          currencyId: props.currencyId ? props.currencyId : 0,
          discountType: "",
        })
      );
      if (responseAction.payload) {
        if (responseAction.payload.coupons.length > 0) {
          setCouponsList((prev) => [
            ...prev,
            ...responseAction.payload.coupons,
          ]);
          setPage(page + 1);
          setTotal(responseAction.payload.total);
        }
      }
    }
  };
  const handleDropdownChange = (option: any, index: number) => {
    if (!props.couponConfig.multiple_coupons_enabled) {
      if (option.label.includes("%")) {
        if (
          selectedCoupon.some(
            (coupon: any, key: number) =>
              coupon.label !== null &&
              coupon.label.includes("%") &&
              key !== index
          )
        ) {
          toast.error(`Cannot assign another coupon with percentage value`, {
            icon: ({ theme, type }) => (
              <img src={ErrorImg} alt="error-img" width="16" height="14" />
            ),
            toastId: `coupon-line-item-error`,
            closeButton: false,
            className: "toast-error",
            position: "top-center",
          });
          return;
        }
      }
      if (!option.label.includes("%")) {
        if (
          selectedCoupon.some(
            (coupon: any, key: number) =>
              coupon.label !== null &&
              !coupon.label.includes("%") &&
              key !== index
          )
        ) {
          toast.error(`Cannot assign another coupon with fixed value`, {
            icon: ({ theme, type }) => (
              <img src={ErrorImg} alt="error-img" width="16" height="14" />
            ),
            toastId: `coupon-line-item-error`,
            closeButton: false,
            className: "toast-error",
            position: "top-center",
          });
          return;
        }
      }
    }
    setSelectedCoupon((prev: any) => {
      const newCoupons = [...prev];
      newCoupons[index] = option;
      return newCoupons;
    });
    const updatedCouponIds = selectedCoupon
      .filter((coupon: any) => coupon?.value !== null)
      .map((coupon: any) => coupon?.value);
    updatedCouponIds[index] = option.value;
    props.handleLineItemCouponIds([option.value], "add");
    props.handlePlanItemCouponChange(updatedCouponIds);
  };
  const handleAddCoupon = () => {
    setSelectedCoupon([
      ...selectedCoupon,
      {
        label: null,
        value: null,
      },
    ]);
  };
  const handleRemoveCoupon = (index: number) => {
    const updatedSelectedCoupon = selectedCoupon.filter(
      (coupon: any, i: any) => i !== index
    );
    props.handlePlanItemCouponChange(
      updatedSelectedCoupon
        .filter((coupon: any) => coupon.value !== null)
        .map((coupon: any) => coupon.value)
    );
    props.handleLineItemCouponIds([selectedCoupon[index].value], "remove");
    setSelectedCoupon((prev: any) => {
      const newCoupons = [...prev];
      newCoupons.splice(index, 1);
      return newCoupons;
    });
  };
  return (
    <div className="coupon-dropdwon-line-item">
      {selectedCoupon.map((coupons: any, index: number) => (
        <div key={index} className="coupon-dropdown-wrapper">
          <InegratedDropdownComponent
            optionList={couponsList
              ?.filter(
                (coupon: any) =>
                  !selectedCoupon?.some((sc: any) => sc?.value === coupon?.id)
              )
              .filter(
                (coupon: any) =>
                  !props.selectedTransactionLevelCoupons?.includes(coupon.id)
              )
              .map((coupon: any) => ({
                label: `${coupon.name} (${coupon.discount} OFF)`,
                value: coupon.id,
              }))}
            placeholderForInputField={"0.00"}
            placeholderForDropdown={"Select coupon"}
            selectedOptionId={coupons?.value ? coupons?.value : null}
            selectedOptionName={coupons?.label ? coupons?.label : null}
            inputValue={""}
            handleDropdownChange={(e) => handleDropdownChange(e, index)}
            handleInputChange={() => {}}
            // flexRatio={{ dropdown: 3, input: 1 }}
            nextSetOfData={getCouponList}
            isPagnated={true}
            removeInputField={true}
            filterOptions={(options, state) => options}
            key={index}
          />
          <button
            type="button"
            className="remove-item"
            onClick={(e) => handleRemoveCoupon(index)}
          >
            <RemoveIcon color={colors.battleshipGrey} />
          </button>
        </div>
      ))}
      {!props.couponConfig?.multiple_coupons_enabled &&
      selectedCoupon.length === 2 ? null : (
        <div onClick={handleAddCoupon} className="add-coupon-button">
          <img className="add-tax-button" src={AddTagIcon} alt="" />
          <span>Add Coupon</span>
        </div>
      )}
    </div>
  );
}

export default CouponDropdown;
