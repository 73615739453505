import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { ReportTagFormValues } from "../../../../../types";
import { IconButton } from "../../../../common/components/button/IconButton";
import { DeleteAlertModal } from "../../../../common/components/deleteModal/DeleteAlertModal";
import { Container } from "../../../../common/components/htmlTags/Container";
import Li from "../../../../common/components/ui/formElements/Li/Li";
import List from "../../../../common/components/ui/formElements/Ul/Ul";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import "../../ReminderEmail.css";
import { useDeleteTag } from "../../hooks/useDeleteEmailFrequency";
import { ReminderEmailConfigForm } from "../../reminderEmailSettings/ReminderEmailConfigForm";
import { ReminderEmailFrequencyForm } from "../../reminderEmailForm/ReminderEmailFrequecnyForm";
import { ReminderEmailFrequencyFormModal } from "../../reminderEmailForm/ReminderEmailFrequencyFormModal";
import DeleteReminderEmail from "../../reminderEmailForm/DeleteReminderEmail";
import { DeleteIcon } from "../../../../common/components";
import { colors } from "../../../../constants/colors";
import Delete from "../../../../common/components/Delete";
import useCommonData from "../../../../hooks/useCommon";
type ListActionProps = {
  id: number;
  setReminderEmailFrequency: React.SetStateAction<any>;
  reminderDetails: any;
  refreshInvoices: () => void;
};
export const ListActions = (props: ListActionProps) => {
  const { deleteEmailFrequency } = useDeleteTag();
  const { reportinTagsPermission } = usePermissionHandler();
  const { currentUserInfo } = useCommonData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteReminderEmailId, setDeleteReminderEmailId] = useState<number>(0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return reportinTagsPermission.includes("Update") ||
    reportinTagsPermission.includes("Delete") ? (
    <>
      <IconButton
        type="button"
        wrapperClass="p-0"
        isDisabled={false}
        fontSize="18px"
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <MoreVertIcon sx={{ color: "#6c757d", fontSize: "20px" }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "4px",
            border: "1px solid #e9ecef",
          },
        }}
      >
        <Container style={{ width: "fit-content" }}>
          <List style={{ listStyle: "none" }}>
            <>
              {reportinTagsPermission.includes("Update") ? (
                <Li
                  key={` ${props.id}-edit`}
                  tabIndex={0}
                  wrapperClass="dropdown-item list-action-item  d-flex w-100 pr-4"
                >
                  <ReminderEmailFrequencyFormModal
                    editId={props.id}
                    buttonClass="reporting-tag-edit-modal-btn no-appearance p-0 w-100 d-flex"
                    onCloseFunction={handleClose}
                    reminderDetails={props.reminderDetails}
                    setReminderEmailFrequencyList={
                      props.setReminderEmailFrequency
                    }
                  />
                </Li>
              ) : null}
              <Li
                key={`${props.id}-delete`}
                tabIndex={0}
                wrapperClass="dropdown-item list-action-item d-flex w-100"
              >
                <Delete
                  className="text-decoration-none delete-item"
                  deleteIcon={true}
                  isMutable={true}
                  isLocked={false}
                  lockDate={""}
                  name="Reminder Email Frequency"
                  onClick={() => {
                    setDeleteReminderEmailId(props.id);
                  }}
                />
              </Li>
            </>
          </List>
        </Container>
        <DeleteReminderEmail
          deleteReminderEmailId={deleteReminderEmailId}
          deleteReminderEmailItemId={""}
          organizationId={currentUserInfo.organization_id}
          refreshInvoices={() => {props.refreshInvoices()}}
        />
      </Popover>
    </>
  ) : null;
};
