import { useContext } from "react";
import { PrimaryButton } from "../../../../../../../components/buttons/primaryButton/PrimaryButton";
import { SecondaryButton } from "../../../../../../../components/buttons/secondaryButton/SecondaryButton";
import { Container } from "../../../../../../../components/htmlTags/container/Container";
import { Span } from "../../../../../../../components/htmlTags/span/Span";
import { ModalContext } from "../../../../../../../components/modals/ModalContext";
import { useAddAccount } from "../../hooks/useAddAccount";
import "./DeleteBankAccount.css";
export const DeleteBankAccount = (props: {
  bankAccountId: number;
  bankAccountName: string;
  refreshList: () => Promise<void>;
}) => {
  const { closeModal } = useContext(ModalContext);
  const { deleteBankAccount } = useAddAccount(
    props.refreshList,
    props.bankAccountId
  );
  return (
    <Container className="delete-bank-account-alert">
      <Span>
        Are you sure you want to delete the account <b>{props.bankAccountName}</b>?
      </Span>
      <Container className="delete-bank-account-button-wrapper">
        <PrimaryButton buttonVariant="normal" onClick={closeModal}>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await deleteBankAccount(props.bankAccountId);
            await props.refreshList();
            closeModal();
          }}
          
          buttonVariant="danger"
        >
          Delete
        </PrimaryButton>
      </Container>
    </Container>
  );
};
