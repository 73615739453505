import AddAccountModal from "./modals/addBankAccountModal/AddAccountModal";

function BankingForm() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
      }}
    >
      BankingForm
      {/* <AddAccountModal /> */}
    </div>
  );
}

export default BankingForm;
