import { toast } from "react-toastify";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { tdsApplyLevel } from "../../../../constants/constants";
import {
  setLoaderState,
  useCommonData,
} from "../../../../home/paymentsReceived/components/paymentReceivedForm/imports";
import { SaveButton, SecondaryButton } from "../../button";
import DetailTotalSection from "../../detailsPage/DeatailsTotalSection";
import { generateFutureInvoices } from "../FutureRenewalSlice";
import FutureRenewalPreviewContent from "./FutureRenewalPreviewContent";

const ModalPreviewContent = (props: any) => {
  const { currentUserInfo, dispatch } = useCommonData();
  const handleSubmit = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      generateFutureInvoices({
        subscriptionId: props.subscriptionId,
        numberOfRenewals: props.numberOfRenewals,
        isUnbilled: props.isUnbilled,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (!("error" in responseAction)) {
      toast.success("Future invoices generated successfully", {
        toastId: "generate-future-invoice-success",
        closeButton: false,
        position: "top-center",
      });
      props.onCloseModal();
      props.fetchChildInvoiceList();
    }
    dispatch(setLoaderState(false));
  };
  return (
    <div
      className={`future-renewal-modal-preview-content ${props.wrapperClass}`}
    >
      <div className="future-renewal-modal-preview-content-item">
        {!props.isDetailPage ? (
          <div className="future-renewal-modal-preview-content-item-label">
            Next invoicing date will be on{" "}
            {DateFormatHandler(props.invoicePreviewData?.next_invoice_date)}.
          </div>
        ) : null}
        {props.isUnbilled && !props.isDetailPage ? (
          <div className="future-renewal-modal-preview-content-item-label">
            This advance charge will be added as an unbilled charge to this
            subscription and can be invoiced later.
          </div>
        ) : null}
        {props.invoicePreviewData.invoices.map((invoiceData: any) => (
          <div>
            <div
              className={`future-renewal-modal-preview-content-heading ${
                props.isDetailPage
                  ? "future-renewal-detail-page-margin-left"
                  : ""
              }`}
            >
              {props.invoicePreviewData.invoices.length === 1
                ? props.isDetailPage
                  ? ""
                  : "Plan, Addon & Coupon Details"
                : props.isDetailPage
                ? `Invoicing Period: ${DateFormatHandler(
                    invoiceData.invoicing_period.split(" - ")[0]
                  )} to ${DateFormatHandler(
                    invoiceData.invoicing_period.split(" - ")[1]
                  )}`
                : `Invoicing Period:${" "}
              ${DateFormatHandler(invoiceData.period_start_date)} to ${" "}
              ${DateFormatHandler(invoiceData.period_end_date)}`}
            </div>
            <FutureRenewalPreviewContent
              invoiceBreakdown={invoiceData}
              currencyCode={
                props.isDetailPage
                  ? props.invoiceData?.currency_code
                  : props.invoicePreviewData.currency_code
              }
              tdsLevel={tdsApplyLevel.LINE_ITEM_LEVEL}
              isPlanOnly={
                invoiceData.addon_details.length > 0 ||
                invoiceData.coupons.length > 0 ||
                invoiceData.charge_details?.length > 0
                  ? false
                  : true
              }
              isDetailPage={props.isDetailPage}
            />
          </div>
        ))}
        <div className="future-renewal-modal-preview-content-item-total-section">
          <div className="thankyou-note w-50 d-flex align-items-end">
            <div className="note-container">
              <></>
            </div>
          </div>
          <DetailTotalSection
            className="subscription-preview-total-section"
            currencyCode={
              props.isDetailPage
                ? props.invoiceData?.currency_code
                : props.invoicePreviewData.currency_code
            }
            totalTax={
              props.isDetailPage
                ? props.invoiceData?.total_details?.tax_amount
                : props.invoicePreviewData?.total_details?.tax_amount
            }
            taxTypes={Object.entries(
              props.isDetailPage
                ? props.invoiceData?.total_details?.taxes
                : props.invoicePreviewData?.total_details?.tax_summary
            )}
            subTotal={
              props.isDetailPage
                ? props.invoiceData?.total_details?.total_taxable_amount
                : props.invoicePreviewData?.total_details?.total_taxable_amount
            }
            total={
              props.isDetailPage
                ? props.invoiceData?.total_details?.total
                : props.invoicePreviewData?.total_details?.total_amount
            }
            balanceLabel={props.isDetailPage ? "Balance Due" : ""}
            balanceAmount={
              props.isDetailPage
                ? props.invoiceData?.total_details?.balance_due
                : 0
            }
            balanceDueRequired={props.isDetailPage ? false : true}
            tdsItems={{
              tdsAmount: props.isDetailPage
                ? props.invoiceData?.total_details?.transaction_tds_amount
                : props.invoicePreviewData?.total_details?.tds_amount,
              tdsName: null,
              tdsPercentage: null,
            }}
            payment={
              props.isDetailPage
                ? props.invoiceData?.total_details?.payment_made
                : undefined
            }
            paymentLabel={props.isDetailPage ? "Payment Received" : ""}
            tcsItems={{
              tcsAmount: props.isDetailPage
                ? props.invoiceData?.tcs_amount
                : props.invoicePreviewData?.total_details?.tcs_amount,
              tcsName: null,
              tcsPercentage: null,
            }}
            roundOffValue={
              props.isDetailPage
                ? props.invoiceData?.total_details?.roundoff_amount
                : props.invoicePreviewData?.total_details?.roundoff_amount
            }
            roundoffEnabled={
              props.isDetailPage ? props.invoiceData?.roundoff_enabled : true
            }
            couponRequired={true}
            coupon_amount={
              props.isDetailPage
                ? props.invoiceData?.total_details?.coupon_amount
                : props.invoicePreviewData?.total_details?.coupon_amount
            }
          >
            <></>
          </DetailTotalSection>
        </div>
        {!props.isDetailPage ? (
          <div className="future-renewal-modal-content-item-footer-wrapper preview-footer-wrapper">
            <SaveButton
              buttonContent="Generate"
              isDisabled={false}
              type="submit"
              buttonClassName="future-renewal-modal-button"
              onClick={handleSubmit}
            />
            <SecondaryButton
              buttonContent="Dismiss"
              isDisabled={false}
              onClick={props.onCloseModal}
              type="submit"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModalPreviewContent;
