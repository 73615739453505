import { Api } from "../../../../constants";
import { get } from "../../../../helpers/apiHelper";

export async function getBankAccountCategoryDetails(orgId: number) {
  const result = await get(
    Api.bankReconciliation +
      "get-bank-account-category-details" +
      `?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting actuals list in Add Budget Screen
 */
export async function getBankAccounts(data: {
  pageNum: number;
  pageSize: number;
  orgId: number;
}) {
  const result = await get(
    `${Api.bankReconciliation}banking-details?current_organization=${
      data.orgId
    }&page_num=${data.pageNum || 1}&page_size=${data.pageSize}`
  );
  return result;
}
