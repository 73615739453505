import React from "react";

type ModalContextType = {
  closeModal: () => void;
};

export const ModalContext = React.createContext<ModalContextType>({
  closeModal: () => {}
});

export const useModalContext = () => React.useContext(ModalContext);
