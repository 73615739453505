import { Modal } from "../../../../../components/modals/Modal";
import { PlusIcon } from "../../../../../components/svgIcons/plusIcon/PlusIcon";
import { Span } from "../../../../common/components/htmlTags/Span";
import { colors } from "../../../../constants/colors";
import AddAccountForm from "../../components/addAccountForm/AddAccountForm";

function AddAccountModal(props: {
  editId?: number;
  modalRef?: any;
  refreshList: () => Promise<void>;
}) {
  return (
    <>
      <Modal
        variant="center-modal"
        buttonContent={
          props.editId ? (
            <></>
          ) : (
            <Span className="display-flex-align-center">
              {" "}
              <PlusIcon margin="0px 5px 0px 0px" height="18px" width="18px" color={colors.white}/> <Span>Add Account</Span>
            </Span>
          )
        }
        buttonClass="primary-button-normal-class"
        isCloseButtonEnabled
        modalTitle={props.editId ? "Edit Account" : "Add Account"}
        ref={props.modalRef}
      >
        <AddAccountForm editId={props.editId} refreshList={props.refreshList} />
      </Modal>
    </>
  );
}

export default AddAccountModal;
