import { get, post, remove } from "../../../../helpers/apiHelper";

import { Api } from "../../../../constants";

export async function futureInvoicePreview(data: {
  subscriptionId: number;
  numberOfRenewals: number;
  isUnbilled: boolean;
  orgId: number;
}) {
  const invoicingOption = data.isUnbilled ? "UNBILLED" : "IMMEDIATELY";
  const result = await get(
    Api.subscription +
      `future-invoice-preview?subscription_id=${data.subscriptionId}&invoicing_cycle=${data.numberOfRenewals}&invoicing_option=${invoicingOption}&current_organization=${data.orgId}`
  );
  return result;
}
export async function generateFutureInvoice(data: {
  subscriptionId: number;
  numberOfRenewals: number;
  isUnbilled: boolean;
  orgId: number;
}) {
  const invoicingOption = data.isUnbilled ? "UNBILLED" : "IMMEDIATELY";
  const payload = {
    subscription_id: Number(data.subscriptionId),
    invoicing_cycle: data.numberOfRenewals,
    invoicing_option: invoicingOption,
  };
  const result = await post(
    Api.subscription +
      `generate-future-invoices?current_organization=${data.orgId}`,
    payload
  );
  return result;
}
