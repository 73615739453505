import {
  PlanItemDetails,
  PlanDefaultValues,
  ObjectType,
} from "../../../../../types";

/**
 * Validate form values
 */
export const validate = (
  planFormValues: PlanDefaultValues,
  items: PlanItemDetails[],
  isInternalNameExist: boolean,
  editId?: string
) => {
  const values = planFormValues.currency?.map(
    (item: { value: any }) => item.value
  );
  planFormValues.currencies = values;
  const errors: ObjectType = {};

  if (editId) {
    if (planFormValues.used_currencies) {
      const allValuesExist = planFormValues.used_currencies.every(
        (value) =>
          planFormValues.currencies && planFormValues.currencies.includes(value)
      );
      if (!allValuesExist) {
        errors.currencies = "Cannot delete currencies that are already in use.";
      }
    }
  }

  if (!planFormValues.internal_name) {
    errors.internal_name = "Please enter Internal name";
  }
  if (items.length <= 0) {
    errors.planItems = "* Please add at least one item";
  }
  if (isInternalNameExist) {
    errors.internal_name =
      "Internal Name already exists. Please choose a different one";
  }
  return errors;
};
