import { useEffect, useState } from "react";
import useCommonData from "../../../hooks/useCommon";
import { getReminderEmailFrequencyConfig } from "../reminderEmailSlice";

export const useInitializeForm = (reminderDetails?: any) => {
  const [initialValues, setInitialValues] = useState<any>({
    days_offset: 1,
    direction: "AFTER",
    invoice_type: "RECURRING",
  });
  useEffect(() => {
    if (reminderDetails) {
      setInitialValues({
        days_offset: reminderDetails.days_offset,
        direction: reminderDetails.direction,
        invoice_type: reminderDetails.invoice_type,
      });
    } else {
      setInitialValues(
        reminderDetails || {
          days_offset: 1,
          direction: "AFTER",
          invoice_type: "RECURRING",
        }
      );
    }
  }, [reminderDetails]);

  return { initialValues };
};

export const useInitializeConfigForm = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const invoiceActionOptions = [
    { label: "Retain Overdue Status", value: "MARK_NOT_PAID" },
    { label: "Write Off", value: "WRITE_OFF" },
    { label: "Mark as Void", value: "MARK_VOID" },
    { label: "Reverse the Invoice", value: "REVERSE_INVOICE" },
  ];
  const subscriptionActionOptions = [
    { label: "Retain as Active", value: "RETAIN_AS_ACTIVE" },
    { label: "Cancel Subscription", value: "CANCEL_SUBSCRIPTION" },
  ]
  const [initialValues, setInitialValues] = useState<any>({
    subscription_action: "RETAIN_AS_ACTIVE",
    invoice_action: "MARK_NOT_PAID",
    write_off_account_id: 0,
    subscription_action_name: "Retain as Active",
    invoice_action_name: "Retain Overdue Status",
    write_off_account_name: "",
  });
  const getData = async () => {
    const responseActions = await dispatch(
      getReminderEmailFrequencyConfig({
        orgId: currentUserInfo.organization_id,
      })
    );
    if(responseActions.payload){
      const response = responseActions.payload;
      if(!("error" in response)){
        setInitialValues({
          subscription_action: response.subscription_action,
          invoice_action: response.invoice_action,
          write_off_account_id: response.write_off_account_id,
          subscription_action_name: subscriptionActionOptions.find(
            (option) => option.value === response.subscription_action
          )?.label,
          invoice_action_name: invoiceActionOptions.find(
            (option) => option.value === response.invoice_action
          )?.label,
          write_off_account_name: response.write_off_account_name,
        });
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return { initialValues };
};
